<template>
    <PegasusHeading>A megadott oldal nem található</PegasusHeading>

    <hr>

    <p>
        Ezen a címen nincsen semmi, ha úgy érzed, hogy ez egy hiba miatt lehet akkor írj
        a lenti elérhetőgeinkre.
    </p>
</template>

<title>Oldal nem található</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
</script>
