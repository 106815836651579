<template>
    <PegasusHeading>Új altétel</PegasusHeading>

    <PegasusPanel header="Altétel">
        <PegasusForm ref="form">
            <DataForm :spending-item="spendingItem"></DataForm>

            <PegasusButton variant="success" @click="save">
                <i class="fa fa-save"></i> Létrehozás
            </PegasusButton>
        </PegasusForm>
    </PegasusPanel>

    <PegasusButton :params="{item}" to="money.spending.item" variant="primary">
        <i class="fa fa-step-backward"></i> Vissza a költségvetési tételhez
    </PegasusButton>
</template>

<title>Új altétel</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {SpendingItem} from '@Models/money/SpendingItem';
import {ref} from 'vue';
import {Item} from '@Models/money/Item';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {redirect} from '@/router';
import DataForm from '@Components/money/spendingItem/DataForm.vue';

const {itemId} = defineProps<{
    itemId: number
}>();

const item = await Item.getById(itemId);

const spendingItem = ref(new SpendingItem());

const form = ref<FormRef>(null);

async function save() {
    if (!await toast.confirm('Biztosan létre akarod hozni az altételt?')) {
        return;
    }

    const request = spendingItem.value.store(item.id, form.value).then(() => {
        redirect({
            name: 'money.spendingItem.show',
            params: {
                item: item.id,
                spendingItem: spendingItem.value.id
            }
        });
    });

    toast.loading(request);
}
</script>
