import {BaseEntity} from "@Models/BaseEntity";
import {File} from "@Models/File";
import {MoneyApi} from "@/api/apis";
import {RuntimeError} from "@Utils/errors/RuntimeError";
import type {FormRef} from '@/vue';
import {type MoneyItemAssetResponse, MoneyItemAssetType} from '@/api/models';

export class ItemAsset extends BaseEntity {
    name: string;

    description: string;

    type: MoneyItemAssetType;

    static parseResponse(asset: ItemAsset, data: MoneyItemAssetResponse): ItemAsset {
        asset.id = data.id;
        asset.name = data.name;
        asset.description = data.description;
        asset.type = data.type;

        return asset;
    }

    public store(itemId: number, file: File | null, form: FormRef): Promise<ItemAsset> {
        if (file === null) {
            throw new RuntimeError('Fájl kiválasztása kötelező');
        }

        return MoneyApi.itemAssetsStore(itemId, file.getAsDomFile(), this.name, this.description, this.type, {form}).then(response => {
            return ItemAsset.parseResponse(this, response.data);
        });
    }

    public update(file: File | null, form: FormRef): Promise<void> {
        return MoneyApi.itemAssetsUpdate(this.id, file?.getAsDomFile() ?? null, this.name, this.description, this.type, {form}).then(response => {
            ItemAsset.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return MoneyApi.itemAssetsDelete(this.id).then();
    }

    public download(): Promise<File> {
        return MoneyApi.itemAssetsDownload(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public getFileType(): 'image' | 'pdf' | 'xls' {
        if (this.name.endsWith('.jpg') || this.name.endsWith('.jpeg') || this.name.endsWith('.png')) {
            return 'image';
        }
        if (this.name.endsWith('.xls') || this.name.endsWith('.xlsx')) {
            return 'xls';
        }
        if (this.name.endsWith('.pdf')) {
            return 'pdf';
        }

        throw new Error('Unrecognised file type: ' + this.name);
    }
}
