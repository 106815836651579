/* tslint:disable */
/* eslint-disable */

export enum BaseBmeUnitScope {
    BME = 'BME',
    BME_ACTIVE = 'BME_ACTIVE',
    BME_NEWBIE = 'BME_NEWBIE',
    BME_VIK = 'BME_VIK',
    BME_VIK_ACTIVE = 'BME_VIK_ACTIVE',
    BME_VIK_NEWBIE = 'BME_VIK_NEWBIE'
}

export const BaseBmeUnitScopeValues: Record<BaseBmeUnitScope, string> = {
    'BME': 'BME hallgató',
    'BME_ACTIVE': 'BME aktív hallgató',
    'BME_NEWBIE': 'BME gólya',
    'BME_VIK': 'BME-VIK hallgató',
    'BME_VIK_ACTIVE': 'BME-VIK aktív hallgató',
    'BME_VIK_NEWBIE': 'BME-VIK gólya'
}


export function BaseBmeUnitScopeFromJSON(json: any): BaseBmeUnitScope {
    return BaseBmeUnitScopeFromJSONTyped(json, false);
}

export function BaseBmeUnitScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseBmeUnitScope {
    return json as BaseBmeUnitScope;
}

export function BaseBmeUnitScopeToJSON(value?: BaseBmeUnitScope | null): any {
    return value as any;
}

export function BaseBmeUnitScopeCreateEmpty(): BaseBmeUnitScope {
    return BaseBmeUnitScope.BME;
}

