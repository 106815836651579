import * as Papa from 'papaparse';
import type {FieldSchema} from '@Components/base/form/FieldProperties';
import {File} from '@Models/File';
import {type CommonPersonData} from '@/api/models';
import toast from '@Utils/toast';
import {PersonCsv} from '@Models/PersonCsv';

export class GuestList {
    public static async parseCsv(file: File): Promise<CommonPersonData[]> {
        if (file.data.size > 50 * 1024) {
            toast.error('50kb-nál nagyobb fájl nem engedélyezett');
            return [];
        }

        let guests: CommonPersonData[];

        try {
            guests = await new PersonCsv().parseCsv(await file.data.text());
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
            }
            return [];
        }

        if (guests.length > 50) {
            toast.error('50-nél több vendég esetén kötelező lezáratást igényelni');
            return [];
        }

        return guests;
    }

    public static createCsv(guests: CommonPersonData[]): File {
        const string = Papa.unparse(guests, {header: false});

        return File.createFromBlob(new Blob([string]), 'guests.csv');
    }

    public static fileSchema(): FieldSchema {
        return {
            name: 'guestListFile',
            label: 'Külsős vendég csv',
            labelHelp: '3 oszlopos CSV fájl, ahol az oszlopok a fenti sorren szerint.\n'
                + 'A rendelkezik kártyánál igen/Igen érték jelenti az igent, bármi más a nemet.',
            required: true,
            nullable: false,
            type: 'file',
            extensions: ['csv', 'tsv']
        };
    }
}
