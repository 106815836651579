/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyItemAssetType } from './MoneyItemAssetType';
    import {
    MoneyItemAssetTypeFromJSON,
    MoneyItemAssetTypeFromJSONTyped,
    MoneyItemAssetTypeToJSON,
    MoneyItemAssetTypeCreateEmpty,
    } from './MoneyItemAssetType';
import {DateTime} from '@Utils/DateTime';
import { MoneyItemAssetTypeValues } from './MoneyItemAssetType';

export interface MoneyItemAssetResponse {
  id: number;
  name: string;
  description: string;
  type: MoneyItemAssetType;
}

export const MoneyItemAssetResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: MoneyItemAssetTypeValues,
  },
}
export function instanceOfMoneyItemAssetResponse(value: object): value is MoneyItemAssetResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;

return isInstance;
}

export function MoneyItemAssetResponseFromJSON(json: any): MoneyItemAssetResponse {
return MoneyItemAssetResponseFromJSONTyped(json, false);
}

export function MoneyItemAssetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemAssetResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
              'type': MoneyItemAssetTypeFromJSON(json['type']),
    };
  }

  export function MoneyItemAssetResponseToJSON(value?: MoneyItemAssetResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'type': MoneyItemAssetTypeToJSON(value.type),
    };
  }

  export function MoneyItemAssetResponseCreateEmpty(): MoneyItemAssetResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
          'type': MoneyItemAssetTypeCreateEmpty(),
  };
  }

