/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface AuthAuthschEntitlement {
  groupId: number;
  groupName: string;
  posts: Array<string>;
  start: DateTime | null;
  end: DateTime | null;
}

export const AuthAuthschEntitlementSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: 'Kör azonosítója',
    type: 'number',
      minimum: 1,
  },
  'groupName': {
    name: 'groupName',
    required: true,
    nullable: false,
    label: 'Kör neve',
    type: 'string',
      minLength: 1,
      maxLength: 250,
  },
  'posts': {
    name: 'posts',
    required: true,
    nullable: false,
    label: 'Kör pozíciók',
    type: 'array',
  },
  'start': {
    name: 'start',
    required: true,
    nullable: true,
    label: 'Tagság kezdete',
    type: 'date',
  },
  'end': {
    name: 'end',
    required: true,
    nullable: true,
    label: 'Tagság vége',
    type: 'date',
  },
}
export function instanceOfAuthAuthschEntitlement(value: object): value is AuthAuthschEntitlement {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "groupName" in value;
    isInstance = isInstance && "posts" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;

return isInstance;
}

export function AuthAuthschEntitlementFromJSON(json: any): AuthAuthschEntitlement {
return AuthAuthschEntitlementFromJSONTyped(json, false);
}

export function AuthAuthschEntitlementFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthAuthschEntitlement {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['group_id'],
          'groupName': json['group_name'],
          'posts': json['posts'],
        'start': (json['start'] === null ? null : DateTime.fromISO(json['start'])),
        'end': (json['end'] === null ? null : DateTime.fromISO(json['end'])),
    };
  }

  export function AuthAuthschEntitlementToJSON(value?: AuthAuthschEntitlement | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'group_id': value.groupId,
                'group_name': value.groupName,
            'posts': value.posts,
            'start': (
              value.start === null ? null : value.start.toISODate()),
            'end': (
              value.end === null ? null : value.end.toISODate()),
    };
  }

  export function AuthAuthschEntitlementCreateEmpty(): AuthAuthschEntitlement {
  return {
      'groupId': 0,
      'groupName': '',
      'posts': [],
      'start': DateTime.now(),
      'end': DateTime.now(),
  };
  }

