import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import {Group} from "@Models/base/Group";
import type {
    InventoryItemUpdate,
    InventoryTransferDecision,
    InventoryUpdateResponse,
    InventoryUpdateTransfer
} from "@/api/api";
import {InventoryApi, InventoryUpdateType} from "@/api/api";
import {Approval} from "@Models/inventory/updates/Approval";
import type {FormRef} from '@/vue';

export class Transfer extends ItemUpdate {
    type = InventoryUpdateType.TRANSFER;

    from: Group;

    to: Group;

    approval: Approval;

    static parseResponse(transfer: Transfer, data: InventoryUpdateResponse): Transfer {
        ItemUpdate.parseResponse(transfer, data);

        if (data.approval === null) {
            throw new Error('Transfer must have approval');
        }

        transfer.approval = Approval.newSingle(data.approval, Approval.parseResponse);

        const specific = data.data as InventoryUpdateTransfer;

        transfer.from = Group.getBySingleId(specific.from);
        transfer.to = Group.getBySingleId(specific.to);

        return transfer;
    }

    public store(itemId: number, form: FormRef): Promise<Transfer> {
        const data: InventoryItemUpdate = {
            type: InventoryUpdateType.TRANSFER,
            data: {
                type: InventoryUpdateType.TRANSFER,
                from: this.from.id,
                to: this.to.id,
            },
            comment: this.comment,
        };

        return InventoryApi.itemUpdatesAdd(itemId, data, {form}).then(response => {
            return Transfer.parseResponse(this, response.data);
        });
    }

    public decide(decision: boolean, comment: string, form: FormRef): Promise<Transfer> {
        const data: InventoryTransferDecision = {
            transfer: this.id,
            decision: decision,
            comment: comment,
        };

        return InventoryApi.itemUpdatesDecideTransfer(data, {form}).then(response => {
            return Transfer.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        let result = [
            {key: 'Kezdeményező kör', value: this.from.shortName ?? this.from.name},
            {key: 'Cél kör', value: this.to.shortName ?? this.to.name},
        ];

        result = result.concat(this.approval.getText());

        return result;
    }
}
