/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsEventStore {
  name: string;
  locations: Array<number>;
  group: number | null;
}

export const EventsEventStoreSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Rendezvény neve',
    type: 'string',
      minLength: 3,
      maxLength: 200,
  },
  'locations': {
    name: 'locations',
    required: true,
    nullable: false,
    label: 'Helyszínek',
    type: 'array',
  },
  'group': {
    name: 'group',
    required: true,
    nullable: true,
    label: 'Rendező kör',
      labelHelp: `Azok a körök jelennek meg a listában amiknek PéK szerint tagja vagy és engedélyezve van számukra a rendezvény bejelentés. Amennyiben szerinted hiányzik kör aminek itt kellene lennie írj a support@rvt.sch.bme.hu címre.`,
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfEventsEventStore(value: object): value is EventsEventStore {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "locations" in value;
    isInstance = isInstance && "group" in value;

return isInstance;
}

export function EventsEventStoreFromJSON(json: any): EventsEventStore {
return EventsEventStoreFromJSONTyped(json, false);
}

export function EventsEventStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsEventStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'locations': json['locations'],
          'group': json['group'],
    };
  }

  export function EventsEventStoreToJSON(value?: EventsEventStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
            'locations': value.locations,
                'group': value.group,
    };
  }

  export function EventsEventStoreCreateEmpty(): EventsEventStore {
  return {
      'name': '',
      'locations': [],
      'group': 0,
  };
  }

