<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div v-for="option in options" :key="option.label" class="row my-2">
                <div class="col-lg-1">
                    <Checkbox
                        v-model="modelValue"

                        :class="{'p-invalid': isValid === false}"
                        :disabled="disabled"
                        :input-id="id + option.label"
                        :pt="{root: {'data-input-name': schema.name}}"
                        :required="schema.required"
                        :value="option.value"
                    ></Checkbox>
                </div>

                <label :for="id + option.label" class="col-lg-8 p-text-secondary">{{ option.label }}</label>
            </div>
        </template>
    </SmartField>
</template>

<script generic="T" lang="ts" setup>
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import SmartField from "@Components/base/form/SmartField.vue";
import Checkbox from "primevue/checkbox";

const modelValue = defineModel<T[]>({required: true});

const {options, disabled, withoutGroup, schema, inline} = defineProps<{
    options: {label: string, value: T}[]
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();
</script>
