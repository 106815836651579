import toast from "@Utils/toast";
import {User} from "@Models/base/User";
import * as Sentry from "@sentry/vue";
import {BaseAuthResponseFromJSON, BaseGlobalPermission, MainApi} from "@/api/api";
import {currentSite} from "@/router/domains";
import {defineStore} from "pinia";
import {useConstantsStore} from "@/stores/constants";
import type {RouteLocationNamedRaw} from "vue-router";
import {type Ref, ref} from "vue";
import {AuthenticationRequiredError} from "@Utils/errors/AuthenticationRequiredError";
import {useLocalStorage} from '@vueuse/core';

type AuthStorage = {state: string | null, target: RouteLocationNamedRaw | null};

export const useAuthStore = defineStore('auth', () => {
    const user: Ref<User | null> = ref(null);
    const impersonating: Ref<boolean> = ref(false);

    const storage: Ref<AuthStorage> = useLocalStorage<AuthStorage>('pegasus-auth', {state: null, target: null});

    function setAuth(newUser: User | null, newImpersonating: boolean) {
        user.value = newUser;

        impersonating.value = newImpersonating;

        if (newUser) {
            Sentry.setUser({
                id: newUser.id.toString(),
                email: newUser.email,
            });
        } else {
            Sentry.setUser(null);
        }
    }

    function setTarget(target: RouteLocationNamedRaw | null) {
        storage.value.target = target;
    }

    function init() {
        const jsonText = document.getElementById('preload')?.textContent ?? '';

        const preload = JSON.parse(atob(jsonText));

        const auth = BaseAuthResponseFromJSON(preload.auth);

        const user = User.newSingleNullable(auth.user, User.parseExtendedResponse);

        setAuth(user, auth.impersonating);
    }

    function redirect(): Promise<void> {
        storage.value.state = window.crypto.randomUUID();

        let url = useConstantsStore().constants.auth.authschRedirectUrl;

        url += '&state=' + storage.value.state + '&redirect_uri=' + window.location.origin + '/auth/callback';

        const request = new Promise<void>((resolve, reject) => {
            window.location.assign(url);

            setTimeout(() => reject(), 20000);
        });

        toast.loading(request, 'Bejelentkezés');

        return request;
    }

    function callback(state: string | null, code: string | null) {
        if (state !== storage.value.state) {
            throw new Error('State mismatch, supplied:' + state + ', stored: ' + storage.value.state);
        }

        if (code === null || state === null) {
            throw new Error('No code or state supplied on callback');
        }

        storage.value.state = null;

        const redirectTarget = storage.value.target ?? {name: currentSite() + '.index'};

        storage.value.target = null;

        redirectTarget.replace = true;

        const request = MainApi.authenticationCallback({
            code: code,
            redirectUrl: window.location.origin + '/auth/callback',
        }).then(response => {
            const user = User.newSingleNullable(response.data.user, User.parseExtendedResponse);

            setAuth(user, false);

            return redirectTarget;
        });

        toast.loading(request, 'Bejelentkezés');

        return request;
    }

    async function refresh() {
        const response = await MainApi.authenticationRefresh();
        user.value = User.newSingleNullable(response.data, User.parseExtendedResponse);
    }

    async function impersonate(user: User) {
        return MainApi.authenticationImpersonate(user.id).then(response => {
            const user = User.newSingleNullable(response.data.user, User.parseExtendedResponse);

            setAuth(user, response.data.impersonating);
        });
    }

    async function stopImpersonating() {
        return MainApi.authenticationStopImpersonating().then(response => {
            const user = User.newSingleNullable(response.data.user, User.parseExtendedResponse);

            setAuth(user, response.data.impersonating);
        });
    }

    function logout() {
        const request = MainApi.authenticationLogout().then(response => {
            const user = User.newSingleNullable(response.data.user, User.parseExtendedResponse);

            setAuth(user, response.data.impersonating);
        });

        toast.loading(request, 'Kijelentkezés');
    }

    function hasPermission(permissions: BaseGlobalPermission | BaseGlobalPermission[]) {
        return user.value?.hasPermission(permissions) === true;
    }

    function requireUser(): Ref<User> {
        if (!user.value) {
            redirect();
            throw new AuthenticationRequiredError('Bejelentkezés szükséges');
        }

        return user as Ref<User>;
    }

    return {
        user,
        setTarget,
        impersonate,
        impersonating,
        init,
        redirect,
        callback,
        refresh,
        stopImpersonating,
        logout,
        hasPermission,
        requireUser
    };
});
