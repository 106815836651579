import {Base} from "@Models/Base";
import {MentorsApi} from "@/api/apis";
import type {MentorsAdminResponse} from "@/api/models";
import {AssignmentMonth} from "@Models/mentors/AssignmentMonth";
import {Mentor} from "@Models/mentors/Mentor";

export class Admin extends Base {
    mentors: Record<number, Mentor>;

    previous: AssignmentMonth;

    current: AssignmentMonth;

    next: AssignmentMonth;

    static get(): Promise<Admin> {
        return MentorsApi.availabilityAdminIndex().then(response => {
            return Admin.newSingle(response.data, Admin.parseResponse);
        });
    }

    static parseResponse(admin: Admin, data: MentorsAdminResponse): Admin {
        admin.mentors = Mentor.newRecords(data.mentors, Mentor.parseResponse);
        admin.previous = AssignmentMonth.newSingle(data.previous, AssignmentMonth.parseResponse);
        admin.current = AssignmentMonth.newSingle(data.current, AssignmentMonth.parseResponse);
        admin.next = AssignmentMonth.newSingle(data.next, AssignmentMonth.parseResponse);

        return admin;
    }
}
