/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryUpdateResponseData } from './InventoryUpdateResponseData';
    import {
    InventoryUpdateResponseDataFromJSON,
    InventoryUpdateResponseDataFromJSONTyped,
    InventoryUpdateResponseDataToJSON,
    InventoryUpdateResponseDataCreateEmpty,
    } from './InventoryUpdateResponseData';
    import type { InventoryUpdateType } from './InventoryUpdateType';
    import {
    InventoryUpdateTypeFromJSON,
    InventoryUpdateTypeFromJSONTyped,
    InventoryUpdateTypeToJSON,
    InventoryUpdateTypeCreateEmpty,
    } from './InventoryUpdateType';
import {DateTime} from '@Utils/DateTime';
import { InventoryUpdateTypeValues } from './InventoryUpdateType';

export interface InventoryItemUpdate {
  type: InventoryUpdateType;
  data: InventoryUpdateResponseData;
  comment: string;
}

export const InventoryItemUpdateSchema = {
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: InventoryUpdateTypeValues,
  },
  'data': {
    name: 'data',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfInventoryItemUpdate(value: object): value is InventoryItemUpdate {
let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function InventoryItemUpdateFromJSON(json: any): InventoryItemUpdate {
return InventoryItemUpdateFromJSONTyped(json, false);
}

export function InventoryItemUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'type': InventoryUpdateTypeFromJSON(json['type']),
              'data': InventoryUpdateResponseDataFromJSON(json['data']),
          'comment': json['comment'],
    };
  }

  export function InventoryItemUpdateToJSON(value?: InventoryItemUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'type': InventoryUpdateTypeToJSON(value.type),
                'data': InventoryUpdateResponseDataToJSON(value.data),
                'comment': value.comment,
    };
  }

  export function InventoryItemUpdateCreateEmpty(): InventoryItemUpdate {
  return {
          'type': InventoryUpdateTypeCreateEmpty(),
          'data': InventoryUpdateResponseDataCreateEmpty(),
      'comment': '',
  };
  }

