<template>
    <PegasusModal header="Új beszámoló létrehozása">
        <SelectField
            v-model="report.group"
            :options="dictArray(reportableGroups, group => group.name)"
            :schema="VikoReportStoreSchema.group"
        ></SelectField>

        <hr>
        <i class="fas fa-question-circle text-primary"></i>
        Amennyiben a kör amihez beszámolót szeretnél leadni nem jelenik meg a listában
        akkor írj a <a href="mailto:support@rvt.sch.bme.hu">support@rvt.sch.bme.hu</a> címre
        <hr>

        <PegasusButton variant="success" @click="save">
            Beszámoló létrehozása
        </PegasusButton>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {dictArray} from '@Models/Utils';
import {VikoReportStoreSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {computed} from 'vue';
import {Group} from '@Models/base/Group';
import {reject} from "lodash-es";
import {VikoIndex} from '@Models/viko/VikoIndex';
import toast from '@Utils/toast';
import {Report} from '@Models/viko/Report';
import {useReturn} from '@Utils/dialog';

const {report, summary, reportIds} = defineProps<{
    report: Report,
    summary: VikoIndex,
    reportIds: number[]
}>();

const returnValue = useReturn();

const reportableGroups = computed((): Group[] => {
    return reject(summary.reportableGroups, group => {
        return reportIds.includes(group.id);
    });
});

function save() {
    if (summary.application === null) {
        throw new Error('Must not happen');
    }

    let request = report.store(summary.application.id, report.group.id).then(() => {
        returnValue(true);
    });

    toast.loading(request, 'Létrehozás');
}
</script>
