import type {App} from "vue";
import type {PrimeVueConfiguration} from "primevue/config";
import PrimeVue from "primevue/config";

export class Prime {
    static install(app: App<Element>) {
        const config: PrimeVueConfiguration = {
            ripple: true,
            inputStyle: 'filled',
            unstyled: false,
        };

        app.use(PrimeVue, config);
    }
}
