<template>
    <PegasusModal :header="header">
        <PegasusForm ref="form">
            <TextField
                v-model="asset.name"
                :disabled="!canEdit"
                :schema="MoneyAssetStoreSchema.name"
            ></TextField>

            <TextareaField
                v-model="asset.description"
                :disabled="!canEdit"
                :schema="MoneyAssetStoreSchema.description"
            ></TextareaField>

            <FileField
                v-if="canEdit"
                v-model="file"
                :schema="MoneyAssetStoreSchema.file"
            ></FileField>

            <PegasusButton v-if="canEdit" class="float-end" variant="success" @click="upload">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>

        <template v-if="asset.id">
            <div v-if="asset.getFileType() === 'pdf'" style="height: 400px">
                <embed :src="link" class="w-100" style="height: 100%"/>
            </div>

            <PegasusImage v-if="asset.getFileType() === 'image'" :src="link" image-class="img-fluid" preview></PegasusImage>

            <span v-if="asset.getFileType() === 'xls'">
                <PegasusButton @click="download">
                    <i class="fa fa-download"></i> Letöltés
                </PegasusButton>
            </span>
        </template>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {ItemAsset} from '@Models/money/ItemAsset';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import FileField from '@Components/base/form/types/FileField.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import toast from '@Utils/toast';
import type {Item} from '@Models/money/Item';
import {computed, ref} from 'vue';
import type {File} from '@Models/File';
import type {FormRef} from '@/vue';
import PegasusImage from '@Components/base/PegasusImage.vue';
import {currentDomain} from '@/router/domains';
import {useReturn} from '@Utils/dialog';
import {MoneyAssetStoreSchema} from '@/api/models';

const {asset, item, canEdit} = defineProps<{
    asset: ItemAsset,
    item: Item,
    canEdit: boolean
}>();

const form = ref<FormRef>(null);

const file = ref<File | null>(null);

const link = computed(() => 'https://' + currentDomain() + '/api/kpr/itemsAssets/' + asset.id);

const returnValue = useReturn();

function download() {
    let request = asset.download().then(file => file.download());

    toast.loading(request, "Letöltés");
}

const header = computed(() => {
    if (asset.id) {
        return canEdit ? 'Csatolmány szerkesztése' : 'Csatolmány megtekintése';
    }

    return 'Csatolmány feltöltése';
});

function upload() {
    let request = asset.id
        ? asset.update(file.value, form.value)
        : asset.store(item.id, file.value, form.value);

    request.then(() => {
        file.value = null;

        returnValue(true);
    });

    toast.loading(request);
}
</script>
