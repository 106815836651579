/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
    import type { EventsClosureResponse } from './EventsClosureResponse';
    import {
    EventsClosureResponseFromJSON,
    EventsClosureResponseFromJSONTyped,
    EventsClosureResponseToJSON,
    EventsClosureResponseCreateEmpty,
    } from './EventsClosureResponse';
    import type { EventsMilestoneResponse } from './EventsMilestoneResponse';
    import {
    EventsMilestoneResponseFromJSON,
    EventsMilestoneResponseFromJSONTyped,
    EventsMilestoneResponseToJSON,
    EventsMilestoneResponseCreateEmpty,
    } from './EventsMilestoneResponse';
    import type { EventsServiceResponse } from './EventsServiceResponse';
    import {
    EventsServiceResponseFromJSON,
    EventsServiceResponseFromJSONTyped,
    EventsServiceResponseToJSON,
    EventsServiceResponseCreateEmpty,
    } from './EventsServiceResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsExtendedEventResponse {
  outsiders: number;
  externalWorkers: number;
  closures: Array<EventsClosureResponse>;
  services: Array<EventsServiceResponse>;
  milestones: Array<EventsMilestoneResponse>;
  permitNeeded: boolean;
  roomStart: DateTime;
  roomFinish: DateTime;
  canEditServices: boolean;
  id: number;
  name: string;
  description: string;
  decision: boolean | null;
  participants: number;
  locations: Array<number>;
  groupId: number | null;
  responsible: BaseUserResponse;
  start: DateTime;
  finish: DateTime;
  finalized: DateTime | null;
  cancelled: DateTime | null;
  canRead: boolean;
  canEdit: boolean;
}

export const EventsExtendedEventResponseSchema = {
  'outsiders': {
    name: 'outsiders',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'externalWorkers': {
    name: 'externalWorkers',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'closures': {
    name: 'closures',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'services': {
    name: 'services',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'milestones': {
    name: 'milestones',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'permitNeeded': {
    name: 'permitNeeded',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'roomStart': {
    name: 'roomStart',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'roomFinish': {
    name: 'roomFinish',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'canEditServices': {
    name: 'canEditServices',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'decision': {
    name: 'decision',
    required: true,
    nullable: true,
    label: '',
    type: 'boolean',
  },
  'participants': {
    name: 'participants',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'locations': {
    name: 'locations',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'start': {
    name: 'start',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'finish': {
    name: 'finish',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'finalized': {
    name: 'finalized',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'cancelled': {
    name: 'cancelled',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'canRead': {
    name: 'canRead',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfEventsExtendedEventResponse(value: object): value is EventsExtendedEventResponse {
let isInstance = true;
    isInstance = isInstance && "outsiders" in value;
    isInstance = isInstance && "externalWorkers" in value;
    isInstance = isInstance && "closures" in value;
    isInstance = isInstance && "services" in value;
    isInstance = isInstance && "milestones" in value;
    isInstance = isInstance && "permitNeeded" in value;
    isInstance = isInstance && "roomStart" in value;
    isInstance = isInstance && "roomFinish" in value;
    isInstance = isInstance && "canEditServices" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "participants" in value;
    isInstance = isInstance && "locations" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "finish" in value;
    isInstance = isInstance && "finalized" in value;
    isInstance = isInstance && "cancelled" in value;
    isInstance = isInstance && "canRead" in value;
    isInstance = isInstance && "canEdit" in value;

return isInstance;
}

export function EventsExtendedEventResponseFromJSON(json: any): EventsExtendedEventResponse {
return EventsExtendedEventResponseFromJSONTyped(json, false);
}

export function EventsExtendedEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsExtendedEventResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'outsiders': json['outsiders'],
          'externalWorkers': json['externalWorkers'],
            'closures': ((json['closures'] as Array<any>).map(EventsClosureResponseFromJSON)),
            'services': ((json['services'] as Array<any>).map(EventsServiceResponseFromJSON)),
            'milestones': ((json['milestones'] as Array<any>).map(EventsMilestoneResponseFromJSON)),
          'permitNeeded': json['permitNeeded'],
        'roomStart': (DateTime.fromISO(json['roomStart'])),
        'roomFinish': (DateTime.fromISO(json['roomFinish'])),
          'canEditServices': json['canEditServices'],
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'decision': json['decision'],
          'participants': json['participants'],
          'locations': json['locations'],
          'groupId': json['groupId'],
              'responsible': BaseUserResponseFromJSON(json['responsible']),
        'start': (DateTime.fromISO(json['start'])),
        'finish': (DateTime.fromISO(json['finish'])),
        'finalized': (json['finalized'] === null ? null : DateTime.fromISO(json['finalized'])),
        'cancelled': (json['cancelled'] === null ? null : DateTime.fromISO(json['cancelled'])),
          'canRead': json['canRead'],
          'canEdit': json['canEdit'],
    };
  }

  export function EventsExtendedEventResponseToJSON(value?: EventsExtendedEventResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'outsiders': value.outsiders,
                'externalWorkers': value.externalWorkers,
              'closures': ((value.closures as Array<any>).map(EventsClosureResponseToJSON)),
              'services': ((value.services as Array<any>).map(EventsServiceResponseToJSON)),
              'milestones': ((value.milestones as Array<any>).map(EventsMilestoneResponseToJSON)),
                'permitNeeded': value.permitNeeded,
            'roomStart': (value.roomStart.toISO()),
            'roomFinish': (value.roomFinish.toISO()),
                'canEditServices': value.canEditServices,
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'decision': value.decision,
                'participants': value.participants,
            'locations': value.locations,
                'groupId': value.groupId,
                'responsible': BaseUserResponseToJSON(value.responsible),
            'start': (value.start.toISO()),
            'finish': (value.finish.toISO()),
            'finalized': (
              value.finalized === null ? null : value.finalized.toISO()),
            'cancelled': (
              value.cancelled === null ? null : value.cancelled.toISO()),
                'canRead': value.canRead,
                'canEdit': value.canEdit,
    };
  }

  export function EventsExtendedEventResponseCreateEmpty(): EventsExtendedEventResponse {
  return {
      'outsiders': 0,
      'externalWorkers': 0,
      'closures': [],
      'services': [],
      'milestones': [],
      'permitNeeded': false,
      'roomStart': DateTime.now(),
      'roomFinish': DateTime.now(),
      'canEditServices': false,
      'id': 0,
      'name': '',
      'description': '',
      'decision': false,
      'participants': 0,
      'locations': [],
      'groupId': 0,
          'responsible': BaseUserResponseCreateEmpty(),
      'start': DateTime.now(),
      'finish': DateTime.now(),
      'finalized': DateTime.now(),
      'cancelled': DateTime.now(),
      'canRead': false,
      'canEdit': false,
  };
  }

