/* tslint:disable */
/* eslint-disable */

export enum MoneyItemAssetType {
    OFFER = 1
}

export const MoneyItemAssetTypeValues: Record<MoneyItemAssetType, string> = {
    1: 'Árajánlat'
}


export function MoneyItemAssetTypeFromJSON(json: any): MoneyItemAssetType {
    return MoneyItemAssetTypeFromJSONTyped(json, false);
}

export function MoneyItemAssetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemAssetType {
    return json as MoneyItemAssetType;
}

export function MoneyItemAssetTypeToJSON(value?: MoneyItemAssetType | null): any {
    return value as any;
}

export function MoneyItemAssetTypeCreateEmpty(): MoneyItemAssetType {
    return MoneyItemAssetType.OFFER;
}

