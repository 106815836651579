/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsServiceResponseData } from './EventsServiceResponseData';
    import {
    EventsServiceResponseDataFromJSON,
    EventsServiceResponseDataFromJSONTyped,
    EventsServiceResponseDataToJSON,
    EventsServiceResponseDataCreateEmpty,
    } from './EventsServiceResponseData';
    import type { EventsServiceType } from './EventsServiceType';
    import {
    EventsServiceTypeFromJSON,
    EventsServiceTypeFromJSONTyped,
    EventsServiceTypeToJSON,
    EventsServiceTypeCreateEmpty,
    } from './EventsServiceType';
import {DateTime} from '@Utils/DateTime';
import { EventsServiceTypeValues } from './EventsServiceType';

export interface EventsServiceStore {
  event: number;
  type: EventsServiceType;
  data: EventsServiceResponseData;
  comment: string;
}

export const EventsServiceStoreSchema = {
  'event': {
    name: 'event',
    required: true,
    nullable: false,
    label: 'Esemény',
    type: 'number',
      minimum: 0,
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Típus",
        enumValues: EventsServiceTypeValues,
  },
  'data': {
    name: 'data',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 5000,
  },
}
export function instanceOfEventsServiceStore(value: object): value is EventsServiceStore {
let isInstance = true;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function EventsServiceStoreFromJSON(json: any): EventsServiceStore {
return EventsServiceStoreFromJSONTyped(json, false);
}

export function EventsServiceStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'event': json['event'],
              'type': EventsServiceTypeFromJSON(json['type']),
              'data': EventsServiceResponseDataFromJSON(json['data']),
          'comment': json['comment'],
    };
  }

  export function EventsServiceStoreToJSON(value?: EventsServiceStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'event': value.event,
                'type': EventsServiceTypeToJSON(value.type),
                'data': EventsServiceResponseDataToJSON(value.data),
                'comment': value.comment,
    };
  }

  export function EventsServiceStoreCreateEmpty(): EventsServiceStore {
  return {
      'event': 0,
          'type': EventsServiceTypeCreateEmpty(),
          'data': EventsServiceResponseDataCreateEmpty(),
      'comment': '',
  };
  }

