<template>
    <PegasusModal :header="local.name" width="500px">
        <PegasusForm ref="form">
            <TextField
                v-model="local.name"
                :schema="InventoryItemGroupUpdateSchema.name"
            ></TextField>

            <TextField
                v-model="local.description"
                :schema="InventoryItemGroupUpdateSchema.description"
            ></TextField>

            <PegasusButton variant="success" @click="save">
                <i class="fas fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {ItemGroup} from "@Models/inventory/ItemGroup";
import TextField from "@Components/base/form/types/TextField.vue";
import {InventoryItemGroupUpdateSchema} from "@/api/models";
import {clone} from "lodash-es";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import {useReturn} from '@Utils/dialog';

const {itemGroup, parent} = defineProps<{
    itemGroup: ItemGroup,
    parent?: ItemGroup
}>();

const local = clone(itemGroup);

const form = ref<FormRef>(null);

const returnValue = useReturn();

function save() {
    if (!itemGroup.id) {
        itemGroup.name = local.name;
        itemGroup.description = local.description;
    }

    const request = itemGroup.id
        ? itemGroup.update(local.name, local.description, form.value).then(() => returnValue(false))
        : itemGroup.store(parent, form.value).then(() => returnValue(true));

    toast.loading(request);
}
</script>
