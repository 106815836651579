<template>
    <PegasusHeading>Költési időszak - {{ period.year }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Időszak adatai">
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel>
                <template #header>
                    <PegasusLink to="money.budget.year.show" :params="{year}">
                        Házszintű költségvetés
                    </PegasusLink>
                </template>

                <PegasusTable :data="budget">
                    <Column field="name" header="Név"></Column>

                    <Column field="contentsText" header="Részletek"></Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Költségvetési tétel</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {SpendingPeriod} from '@Models/money/SpendingPeriod';
import {NotFoundError} from '@Utils/errors/NotFoundError';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import PegasusLink from '@Components/base/PegasusLink.vue';

const {year} = defineProps<{
    year: number,
}>();

useAuthStore().requireUser();

const period = SpendingPeriod.getByYear(year);

if (!period) {
    throw new NotFoundError('Nincs ilyen költési időszak');
}

const budget = await period.getBudget();
</script>
