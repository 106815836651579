<template>
    <PegasusHeading>E-mail admin</PegasusHeading>

    <PegasusTable :data="emails">
        <Column field="template" header="Sablon"></Column>

        <Column :field="(data) => data.to.join(', ')" header="Címzett"></Column>

        <Column :field="(data) => data.cc.join(', ')" header="Másolat"></Column>

        <Column :field="(data) => data.replyTo.join(', ')" header="Reply-To"></Column>

        <Column field="from" header="Feladó"></Column>

        <Column :field="(data) => data.sent.datetime()" header="Elfogadva"></Column>

        <Column :field="(data) => data.delivered.datetime()" header="Kézbesítve"></Column>

        <Column header="Műveletek">
            <template #body="{data}: {data: Email}">
                <PegasusButton @click="preview(data)">
                    <i class="fa fa-eye"></i> Előnézet
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<title>E-mail admin</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ref} from 'vue';
import {Email} from '@Models/Email';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {Dialog} from '@Utils/dialog';
import EmailRenderDialog from '@Components/system/emails/EmailRenderDialog.vue';
import toast from '@Utils/toast';

const emails = ref(await Email.getRecent());

async function preview(email: Email) {
    const request = email.render().then(preview => {
        Dialog.open(EmailRenderDialog, {preview});
    });

    toast.loading(request, 'Generálás');
}
</script>
