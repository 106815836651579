/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsLocationResponse {
  id: number;
  name: string;
  longName: string;
  enabled: boolean;
  calendar: string | null;
  roomId: number | null;
}

export const EventsLocationResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'longName': {
    name: 'longName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'enabled': {
    name: 'enabled',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'calendar': {
    name: 'calendar',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'roomId': {
    name: 'roomId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfEventsLocationResponse(value: object): value is EventsLocationResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "longName" in value;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "calendar" in value;
    isInstance = isInstance && "roomId" in value;

return isInstance;
}

export function EventsLocationResponseFromJSON(json: any): EventsLocationResponse {
return EventsLocationResponseFromJSONTyped(json, false);
}

export function EventsLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsLocationResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'longName': json['longName'],
          'enabled': json['enabled'],
          'calendar': json['calendar'],
          'roomId': json['roomId'],
    };
  }

  export function EventsLocationResponseToJSON(value?: EventsLocationResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'longName': value.longName,
                'enabled': value.enabled,
                'calendar': value.calendar,
                'roomId': value.roomId,
    };
  }

  export function EventsLocationResponseCreateEmpty(): EventsLocationResponse {
  return {
      'id': 0,
      'name': '',
      'longName': '',
      'enabled': false,
      'calendar': '',
      'roomId': 0,
  };
  }

