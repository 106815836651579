<template>
    <PegasusModal header="Kivezetés bejegyzés">
        <PegasusForm ref="form">
            <PegasusForm part="data">
                <SelectField
                    v-model="termination.reason"
                    :options="dictNumberEnum(InventoryTerminationReasonValues)"
                    :schema="InventoryUpdateTerminationSchema.reason"
                ></SelectField>
            </PegasusForm>

            <TextareaField
                v-model="termination.comment"
                :schema="InventoryItemUpdateSchema.comment"
            ></TextareaField>

            <PegasusButton type="submit" variant="primary" @click="save">
                Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {Termination} from "@Models/inventory/updates/Termination";
import {ref} from "vue";
import toast from "@Utils/toast";
import {
    InventoryItemUpdateSchema,
    InventoryTerminationReasonValues,
    InventoryUpdateTerminationSchema
} from "@/api/models";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import {dictNumberEnum} from "@Models/Utils";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';

const {item} = defineProps<{
    item: Item
}>();

const termination = ref(new Termination());

const form = ref<FormRef>(null);

const returnValue = useReturn();

async function save() {
    if (!await toast.confirm('Biztos vagy benne, hogy ki akarod vezetni ezt a tételt?')) {
        return;
    }

    let request = termination.value.store(item.id, form.value).then(() => {
        item.updates.push(termination.value);

        returnValue(true);
    });

    toast.loading(request);
}
</script>
