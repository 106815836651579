<template>
    <PegasusHeading>Bejelentkezés folyamatban</PegasusHeading>
</template>

<title>Bejelentkezés folyamatban</title>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import {redirect} from '@/router';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const query = new URLSearchParams(window.location.search);

history.pushState({}, '', 'https://' + window.location.hostname + window.location.pathname);

useAuthStore()
    .callback(query.get('state'), query.get('code'))
    .then(redirectTarget => {
        redirect(redirectTarget);
    });
</script>
