import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import type {InventoryCreationSource, InventoryUpdateCreation, InventoryUpdateResponse} from "@/api/api";
import {InventoryCreationSourceValues, InventoryUpdateType} from "@/api/api";

export class Creation extends ItemUpdate {
    type = InventoryUpdateType.CREATION;

    source: InventoryCreationSource;

    static parseResponse(creation: Creation, data: InventoryUpdateResponse): Creation {
        ItemUpdate.parseResponse(creation, data);

        const specific = data.data as InventoryUpdateCreation;
        creation.source = specific.source;

        return creation;
    }

    get text(): {key: string, value: string}[] {
        return [
            {key: 'Forrás', value: InventoryCreationSourceValues[this.source]},
        ];
    }
}
