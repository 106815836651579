/* tslint:disable */
/* eslint-disable */

export enum EventsServiceCategory {
    FACILITIES = 'FACILITIES',
    GROUPS = 'GROUPS',
    ASSETS = 'ASSETS'
}

export const EventsServiceCategoryValues: Record<EventsServiceCategory, string> = {
    'FACILITIES': 'Üzemeltetési igények',
    'GROUPS': 'Körös szolgáltatások',
    'ASSETS': 'Kollégiumi eszközök'
}


export function EventsServiceCategoryFromJSON(json: any): EventsServiceCategory {
    return EventsServiceCategoryFromJSONTyped(json, false);
}

export function EventsServiceCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceCategory {
    return json as EventsServiceCategory;
}

export function EventsServiceCategoryToJSON(value?: EventsServiceCategory | null): any {
    return value as any;
}

export function EventsServiceCategoryCreateEmpty(): EventsServiceCategory {
    return EventsServiceCategory.FACILITIES;
}

