<template>
    <PegasusModal header="Beszámoló szerkesztése">
        <PegasusForm ref="form">
            <NumberField
                v-model="number"
                :schema="KprApplicationUpdateSchema.number"
            ></NumberField>

            <TextareaField
                v-model="statement"
                :schema="KprApplicationUpdateSchema.statement"
            ></TextareaField>

            <PegasusButton class="m-2" variant="success" @click="update">
                Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {KprApplicationUpdateSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import toast from '@Utils/toast';
import type {Application} from '@Models/rvt/kpr/Application';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import {useReturn} from '@Utils/dialog';

const {application} = defineProps<{
    application: Application
}>();

const form = ref<FormRef>(null);

const number = ref(application.number);
const statement = ref(application.statement);

const returnValue = useReturn();

async function update() {
    if (!await toast.confirm('Biztosan frissíteni szeretnéd a beszámolót?')) {
        return;
    }

    const request = application.update(form.value, number.value, statement.value).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}
</script>
