/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAssignment {
  day: number;
  mentor: number | null;
}

export const MentorsAssignmentSchema = {
  'day': {
    name: 'day',
    required: true,
    nullable: false,
    label: 'Nap a hónapban',
    type: 'number',
      minimum: 1,
      maximum: 31,
  },
  'mentor': {
    name: 'mentor',
    required: true,
    nullable: true,
    label: 'Mentor',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfMentorsAssignment(value: object): value is MentorsAssignment {
let isInstance = true;
    isInstance = isInstance && "day" in value;
    isInstance = isInstance && "mentor" in value;

return isInstance;
}

export function MentorsAssignmentFromJSON(json: any): MentorsAssignment {
return MentorsAssignmentFromJSONTyped(json, false);
}

export function MentorsAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAssignment {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'day': json['day'],
          'mentor': json['mentor'],
    };
  }

  export function MentorsAssignmentToJSON(value?: MentorsAssignment | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'day': value.day,
                'mentor': value.mentor,
    };
  }

  export function MentorsAssignmentCreateEmpty(): MentorsAssignment {
  return {
      'day': 0,
      'mentor': 0,
  };
  }

