<template>
    <PegasusHeading>VIKÖ KSZK pályázatok</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel>
                <template #header>
                    <b>Általános pályázatok</b> ({{ applications[1].length }})
                </template>
                <PegasusTable :data="applications[1]">
                    <Column field="user.name" header="Név" sortable></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: Application}">
                            <PegasusButton size="sm" variant="primary" @click="change(data, true)">
                                <i class="fas fa-arrow-right"></i> KSZK
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="KSZK pályázatok">
                <template #header>
                    <b>KSZK pályázatok</b> ({{ applications[0].length }})
                </template>
                <PegasusTable :data="applications[0]">
                    <Column field="user.name" header="Név" sortable></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: Application}">
                            <PegasusButton size="sm" variant="primary" @click="change(data, false)">
                                <i class="fas fa-arrow-left"></i> Általános
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>

    <PegasusButton :params="{tender: tenderId}" to="viko.admin.tender" variant="primary">
        <i class="fas fa-fast-backward"></i> Vissza
    </PegasusButton>
</template>

<title>VIKÖ KSZK pályázatok</title>

<script lang="ts" setup>
import PegasusButton from "@Components/base/PegasusButton.vue";
import {useAuthStore} from '@/stores/auth';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {Application} from '@Models/viko/Application';
import {partition} from 'lodash-es';
import {computed, ref} from 'vue';
import toast from '@Utils/toast';

const {tenderId} = defineProps<{
    tenderId: number
}>();

useAuthStore().requireUser();

const fetched = ref(await Application.getByTender(tenderId));

const applications = computed(() => partition(fetched.value, application => application.ignoreFromCalculations));

function change(application: Application, ignore: boolean): void {
    const request = application.setIgnore(ignore);

    toast.loading(request);
}
</script>
