import type {Site} from "../domains";
import type {RouteRecordName, RouteRecordRaw} from "vue-router";
import AuthCallback from "@Pages/AuthCallback.vue";
import NotFound from "@Pages/errors/NotFound.vue";
import Kb from "@/router/routes/kb";
import RvtLayout from "@Pages/rvt/RvtLayout.vue";
import KbLayout from "@Pages/kb/KbLayout.vue";
import EventsLayout from "@Pages/events/EventsLayout.vue";
import MentorsLayout from "@Pages/mentors/MentorsLayout.vue";
import VikoLayout from "@Pages/viko/VikoLayout.vue";
import CentralLayout from "@Pages/central/CentralLayout.vue";
import InventoryLayout from "@Pages/inventory/InventoryLayout.vue";
import events from "@/router/routes/events";
import mentors from "@/router/routes/mentors";
import kpr from "@/router/routes/kpr";
import viko from "@/router/routes/viko";
import central from "@/router/routes/central";
import inventory from "@/router/routes/inventory";
import rvt from "@/router/routes/rvt";
import money from '@/router/routes/money';
import MoneyLayout from '@Pages/money/MoneyLayout.vue';

export function globalRoutes(site: Site): RouteRecordRaw[] {
    return [
        {
            path: '/auth/callback',
            name: site + '.auth.callback',
            component: AuthCallback,
            meta: {
                cannotBeAuthRedirectTarget: true,
            },
        },
        {
            path: '/error/404',
            name: 'errors.404',
            component: NotFound,
        },
        {
            path: '/:catchAll(.*)',
            component: NotFound,
        },
    ];
}

export const rawRoutes: Record<string, RouteRecordRaw> = {
    rvt: {
        path: '/',
        component: RvtLayout,
        children: rvt,
    },
    kb: {
        path: '/',
        component: KbLayout,
        children: Kb,
    },
    events: {
        path: '/',
        component: EventsLayout,
        children: events,
    },
    mentors: {
        path: '/',
        component: MentorsLayout,
        children: mentors,
    },
    kpr: {
        path: '/',
        component: RvtLayout,
        children: kpr,
    },
    viko: {
        path: '/',
        component: VikoLayout,
        children: viko,
    },
    central: {
        path: '/',
        component: CentralLayout,
        children: central,
    },
    inventory: {
        path: '/',
        component: InventoryLayout,
        children: inventory,
    },
    money: {
        path: '/',
        component: MoneyLayout,
        children: money
    }
};

function doMatch(routes: RouteRecordRaw[], name: string | symbol) {
    return routes.filter(route => {
        return route.name === name;
    })[0];
}

export function matcher(site: Site, name: RouteRecordName) {
    if (!rawRoutes[site]) {
        return null;
    }

    return doMatch(globalRoutes(site).concat(rawRoutes[site].children ?? []), name);
}
