import Event from "@Models/events/Event";
import {ClosureType} from "@Models/events/ClosureType";
import {DateTime} from "@Utils/DateTime";
import {BaseEntity} from "@Models/BaseEntity";
import {File} from "@Models/File";
import type {EventsClosureResponse, EventsClosureStore, EventsClosureUpdate} from "@/api/api";
import {EventsApi} from "@/api/api";
import {useConstantsStore} from "@/stores/constants";
import {find} from "lodash-es";
import type {FormRef} from '@/vue';

export class Closure extends BaseEntity {
    begin: DateTime;

    end: DateTime;

    type: ClosureType;

    comment: string;

    event: number;

    static createEmptyFromEvent(event: Event): Closure {
        const closure = new Closure();

        closure.id = 0;
        closure.begin = event.start;
        closure.end = event.finish;
        closure.type = find(useConstantsStore().constants.events.closureTypes) as ClosureType;
        closure.comment = '';
        closure.event = event.id;

        return closure;
    }

    static parseResponse(closure: Closure, data: EventsClosureResponse): Closure {
        closure.id = data.id;
        closure.begin = data.begin;
        closure.end = data.end;
        closure.type = useConstantsStore().constants.events.closureTypes[data.typeId];
        closure.comment = data.comment;
        closure.event = data.eventId;

        return closure;
    }

    public store(form: FormRef): Promise<Closure> {
        const data: EventsClosureStore = {
            event: this.event,
            type: this.type.id,
            begin: this.begin,
            end: this.end,
            comment: this.comment,
        };

        return EventsApi.closuresStore(data, {form}).then(response => {
            return Closure.parseResponse(this, response.data);
        });
    }

    public update(form: FormRef): Promise<void> {
        const data: EventsClosureUpdate = {
            type: this.type.id,
            begin: this.begin,
            end: this.end,
            comment: this.comment,
        };

        return EventsApi.closuresUpdate(this.id, data, {form}).then(response => {
            Closure.parseResponse(this, response.data);
        });
    }

    public pr(): Promise<File> {
        return EventsApi.closuresPr(this.id).then(response => File.createFromResponse(response));
    }

    public delete(): Promise<void> {
        return EventsApi.closuresDestroy(this.id).then();
    }

    get disrupts(): boolean {
        return this.type.disrupts;
    }

    get hours(): number {
        return Math.ceil(Math.abs(this.begin.diffHours(this.end)));
    }

    get price(): number {
        return (this.hours + 2) * this.type.price;
    }
}
