import {Base} from "@Models/Base";
import {Tender} from "@Models/viko/Tender";
import type {VikoTenderStatisticsResponse} from "@/api/api";
import {VikoApi} from "@/api/api";
import type {User} from '@Models/base/User';
import {ReviewerStatistics} from '@Models/viko/statistics/ReviewerStatistics';

export class TenderStatistics extends Base {
    tender: Tender;

    applicationsCount: number;

    reviewers: {
        user: User,
        reportsReviewed: number
    }[];

    static getByTender(id: number): Promise<TenderStatistics> {
        return VikoApi.tendersStatistics(id).then(response => {
            return TenderStatistics.newSingle(response.data, TenderStatistics.parseResponse);
        });
    }

    static parseResponse(problems: TenderStatistics, data: VikoTenderStatisticsResponse): TenderStatistics {
        problems.tender = Tender.newSingle(data.tender, Tender.parseExtendedResponse);
        problems.applicationsCount = data.applicationsCount;
        problems.reviewers = ReviewerStatistics.newArray(data.reviewers, ReviewerStatistics.parseResponse);

        return problems;
    }
}
