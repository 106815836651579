/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseVikoConstantsResponse {
  activeTender: number | null;
  charLimit: number;
}

export const BaseVikoConstantsResponseSchema = {
  'activeTender': {
    name: 'activeTender',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'charLimit': {
    name: 'charLimit',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfBaseVikoConstantsResponse(value: object): value is BaseVikoConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "activeTender" in value;
    isInstance = isInstance && "charLimit" in value;

return isInstance;
}

export function BaseVikoConstantsResponseFromJSON(json: any): BaseVikoConstantsResponse {
return BaseVikoConstantsResponseFromJSONTyped(json, false);
}

export function BaseVikoConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseVikoConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'activeTender': json['activeTender'],
          'charLimit': json['charLimit'],
    };
  }

  export function BaseVikoConstantsResponseToJSON(value?: BaseVikoConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'activeTender': value.activeTender,
                'charLimit': value.charLimit,
    };
  }

  export function BaseVikoConstantsResponseCreateEmpty(): BaseVikoConstantsResponse {
  return {
      'activeTender': 0,
      'charLimit': 0,
  };
  }

