<template>
    <PegasusModal :header="closure.id ? 'Lezáratás szerkesztése' : 'Lezáratás létrehozása'" width="1000px">
        <div class="row">
            <div class="col-lg-6">
                <PegasusForm ref="form">
                    <div class="row">
                        <div class="col">
                            <DatetimeField
                                v-model="closure.begin"
                                :disabled="!event.canEdit"
                                :schema="EventsClosureStoreSchema.begin"
                            ></DatetimeField>
                        </div>

                        <div class="col">
                            <DatetimeField
                                v-model="closure.end"
                                :disabled="!event.canEdit"
                                :schema="EventsClosureStoreSchema.end"
                            ></DatetimeField>
                        </div>
                    </div>

                    <SelectField
                        v-model="closure.type"
                        :disabled="!event.canEdit"
                        :options="dictObject(closureTypes, type => type.displayName)"
                        :schema="EventsClosureUpdateSchema.type"
                    ></SelectField>

                    <TextareaField
                        v-model="closure.comment"
                        :disabled="!event.canEdit"
                        :schema="EventsClosureStoreSchema.comment"
                    ></TextareaField>

                    <PegasusButton v-if="event.canEdit" type="submit" variant="success" @click="update">
                        Mentés
                    </PegasusButton>
                </PegasusForm>
            </div>

            <div class="col-lg-6">
                <div>
                    <span v-for="(svg, id) in maps[closure.type.id]" :key="id" v-html="svg"></span>
                </div>
            </div>
        </div>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {EventsClosureStoreSchema, EventsClosureUpdateSchema} from '@/api/models';
import {dictObject} from '@Models/Utils';
import PegasusButton from '@Components/base/PegasusButton.vue';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {Closure} from '@Models/events/Closure';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import {useConstantsStore} from '@/stores/constants';
import toast from '@Utils/toast';
import Event from '@Models/events/Event';
import {useReturn} from '@Utils/dialog';

const {closure, event, maps} = defineProps<{
    closure: Closure,
    event: Event,
    maps: Record<number, string[]>
}>();

const form = ref<FormRef>(null);

const returnValue = useReturn();

const closureTypes = useConstantsStore().constants.events.closureTypes;

function update() {
    if (!closure.id) {
        return store();
    }

    const request = closure.update(form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}

function store() {
    const request = closure.store(form.value).then(closure => {
        event.closures[closure.id] = closure;

        returnValue(true);
    });

    toast.loading(request);
}
</script>
