/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { CommonPersonData } from './CommonPersonData';
    import {
    CommonPersonDataFromJSON,
    CommonPersonDataFromJSONTyped,
    CommonPersonDataToJSON,
    CommonPersonDataCreateEmpty,
    } from './CommonPersonData';
import {DateTime} from '@Utils/DateTime';

export interface EventsWorkerListData {
  workers: Array<CommonPersonData>;
}

export const EventsWorkerListDataSchema = {
  'workers': {
    name: 'workers',
    required: true,
    nullable: false,
    label: 'Külsős rendezők',
      type: 'array',
  },
}
export function instanceOfEventsWorkerListData(value: object): value is EventsWorkerListData {
let isInstance = true;
    isInstance = isInstance && "workers" in value;

return isInstance;
}

export function EventsWorkerListDataFromJSON(json: any): EventsWorkerListData {
return EventsWorkerListDataFromJSONTyped(json, false);
}

export function EventsWorkerListDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsWorkerListData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'workers': ((json['workers'] as Array<any>).map(CommonPersonDataFromJSON)),
    };
  }

  export function EventsWorkerListDataToJSON(value?: EventsWorkerListData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'workers': ((value.workers as Array<any>).map(CommonPersonDataToJSON)),
    };
  }

  export function EventsWorkerListDataCreateEmpty(): EventsWorkerListData {
  return {
      'workers': [],
  };
  }

