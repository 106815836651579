/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseExtendedGroupSummaryResponse } from './BaseExtendedGroupSummaryResponse';
    import {
    BaseExtendedGroupSummaryResponseFromJSON,
    BaseExtendedGroupSummaryResponseFromJSONTyped,
    BaseExtendedGroupSummaryResponseToJSON,
    BaseExtendedGroupSummaryResponseCreateEmpty,
    } from './BaseExtendedGroupSummaryResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseResortSummaryResponse {
  groups: Array<BaseExtendedGroupSummaryResponse>;
}

export const BaseResortSummaryResponseSchema = {
  'groups': {
    name: 'groups',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseResortSummaryResponse(value: object): value is BaseResortSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "groups" in value;

return isInstance;
}

export function BaseResortSummaryResponseFromJSON(json: any): BaseResortSummaryResponse {
return BaseResortSummaryResponseFromJSONTyped(json, false);
}

export function BaseResortSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseResortSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'groups': ((json['groups'] as Array<any>).map(BaseExtendedGroupSummaryResponseFromJSON)),
    };
  }

  export function BaseResortSummaryResponseToJSON(value?: BaseResortSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'groups': ((value.groups as Array<any>).map(BaseExtendedGroupSummaryResponseToJSON)),
    };
  }

  export function BaseResortSummaryResponseCreateEmpty(): BaseResortSummaryResponse {
  return {
      'groups': [],
  };
  }

