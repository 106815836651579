import {BaseEntity} from "@Models/BaseEntity";
import {Group} from "@Models/base/Group";
import type {BaseMembershipResponse, BaseMembershipStatus} from "@/api/api";
import {GroupPost} from "@Models/base/GroupPost";
import {BaseMembershipStatusValues} from "@/api/api";

export class Membership extends BaseEntity {
    group: Group;

    status: BaseMembershipStatus;

    posts: GroupPost[];

    static parseResponse(membership: Membership, data: BaseMembershipResponse): Membership {
        membership.id = data.id;
        membership.group = Group.getBySingleId(data.groupId);
        membership.status = data.status;
        membership.posts = GroupPost.newArray(data.posts, GroupPost.parseResponse);

        return membership;
    }

    get postsList(): string {
        return this.getPostNames().join(', ');
    }

    public getPostNames(): string[] {
        return this.posts.map(post => post.name);
    }

    get statusName(): string {
        return BaseMembershipStatusValues[this.status];
    }

    public isLeader(): boolean {
        return this.getPostNames().includes('körvezető');
    }
}
