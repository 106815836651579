/* tslint:disable */
/* eslint-disable */

import {
    type InventoryUpdateCheck,
    InventoryUpdateCheckFromJSONTyped,
    InventoryUpdateCheckToJSON,
    InventoryUpdateCheckCreateEmpty,
    instanceOfInventoryUpdateCheck,
} from './InventoryUpdateCheck';
import {
    type InventoryUpdateCreation,
    InventoryUpdateCreationFromJSONTyped,
    InventoryUpdateCreationToJSON,
    InventoryUpdateCreationCreateEmpty,
    instanceOfInventoryUpdateCreation,
} from './InventoryUpdateCreation';
import {
    type InventoryUpdateDamage,
    InventoryUpdateDamageFromJSONTyped,
    InventoryUpdateDamageToJSON,
    InventoryUpdateDamageCreateEmpty,
    instanceOfInventoryUpdateDamage,
} from './InventoryUpdateDamage';
import {
    type InventoryUpdateDataChange,
    InventoryUpdateDataChangeFromJSONTyped,
    InventoryUpdateDataChangeToJSON,
    InventoryUpdateDataChangeCreateEmpty,
    instanceOfInventoryUpdateDataChange,
} from './InventoryUpdateDataChange';
import {
    type InventoryUpdateLending,
    InventoryUpdateLendingFromJSONTyped,
    InventoryUpdateLendingToJSON,
    InventoryUpdateLendingCreateEmpty,
    instanceOfInventoryUpdateLending,
} from './InventoryUpdateLending';
import {
    type InventoryUpdateRepair,
    InventoryUpdateRepairFromJSONTyped,
    InventoryUpdateRepairToJSON,
    InventoryUpdateRepairCreateEmpty,
    instanceOfInventoryUpdateRepair,
} from './InventoryUpdateRepair';
import {
    type InventoryUpdateTermination,
    InventoryUpdateTerminationFromJSONTyped,
    InventoryUpdateTerminationToJSON,
    InventoryUpdateTerminationCreateEmpty,
    instanceOfInventoryUpdateTermination,
} from './InventoryUpdateTermination';
import {
    type InventoryUpdateTransfer,
    InventoryUpdateTransferFromJSONTyped,
    InventoryUpdateTransferToJSON,
    InventoryUpdateTransferCreateEmpty,
    instanceOfInventoryUpdateTransfer,
} from './InventoryUpdateTransfer';

export type InventoryUpdateResponseData = { type: 'check' } & InventoryUpdateCheck | { type: 'creation' } & InventoryUpdateCreation | { type: 'damage' } & InventoryUpdateDamage | { type: 'dataChange' } & InventoryUpdateDataChange | { type: 'lending' } & InventoryUpdateLending | { type: 'repair' } & InventoryUpdateRepair | { type: 'termination' } & InventoryUpdateTermination | { type: 'transfer' } & InventoryUpdateTransfer;
export type InventoryUpdateResponseDataSimple = InventoryUpdateCheck | InventoryUpdateCreation | InventoryUpdateDamage | InventoryUpdateDataChange | InventoryUpdateLending | InventoryUpdateRepair | InventoryUpdateTermination | InventoryUpdateTransfer;



export function InventoryUpdateResponseDataFromJSON(json: any): InventoryUpdateResponseData {
    return InventoryUpdateResponseDataFromJSONTyped(json, false);
}

export function InventoryUpdateResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'check':
            return {...InventoryUpdateCheckFromJSONTyped(json, true), type: 'check'};
        case 'creation':
            return {...InventoryUpdateCreationFromJSONTyped(json, true), type: 'creation'};
        case 'damage':
            return {...InventoryUpdateDamageFromJSONTyped(json, true), type: 'damage'};
        case 'dataChange':
            return {...InventoryUpdateDataChangeFromJSONTyped(json, true), type: 'dataChange'};
        case 'lending':
            return {...InventoryUpdateLendingFromJSONTyped(json, true), type: 'lending'};
        case 'repair':
            return {...InventoryUpdateRepairFromJSONTyped(json, true), type: 'repair'};
        case 'termination':
            return {...InventoryUpdateTerminationFromJSONTyped(json, true), type: 'termination'};
        case 'transfer':
            return {...InventoryUpdateTransferFromJSONTyped(json, true), type: 'transfer'};
        default:
            throw new Error(`No variant of InventoryUpdateResponseData exists with 'type=${json['type']}'`);
    }
}

export function InventoryUpdateResponseDataToJSON(value?: InventoryUpdateResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'check':
            return InventoryUpdateCheckToJSON(value);
        case 'creation':
            return InventoryUpdateCreationToJSON(value);
        case 'damage':
            return InventoryUpdateDamageToJSON(value);
        case 'dataChange':
            return InventoryUpdateDataChangeToJSON(value);
        case 'lending':
            return InventoryUpdateLendingToJSON(value);
        case 'repair':
            return InventoryUpdateRepairToJSON(value);
        case 'termination':
            return InventoryUpdateTerminationToJSON(value);
        case 'transfer':
            return InventoryUpdateTransferToJSON(value);
        default:
            throw new Error(`No variant of InventoryUpdateResponseData exists with 'type=${value['type']}'`);
    }

}
export function InventoryUpdateResponseDataCreateEmpty(): InventoryUpdateResponseData {
    const ret: InventoryUpdateResponseData = InventoryUpdateCheckCreateEmpty() as InventoryUpdateResponseData;
    ret.type = 'check'
      return ret;
}
export function InventoryUpdateResponseDataCreateEmptyByType(type: string): InventoryUpdateResponseDataSimple {
  switch (type) {
    case 'check':
      return InventoryUpdateCheckCreateEmpty();
    case 'creation':
      return InventoryUpdateCreationCreateEmpty();
    case 'damage':
      return InventoryUpdateDamageCreateEmpty();
    case 'dataChange':
      return InventoryUpdateDataChangeCreateEmpty();
    case 'lending':
      return InventoryUpdateLendingCreateEmpty();
    case 'repair':
      return InventoryUpdateRepairCreateEmpty();
    case 'termination':
      return InventoryUpdateTerminationCreateEmpty();
    case 'transfer':
      return InventoryUpdateTransferCreateEmpty();
    default:
      throw new Error(`No variant of InventoryUpdateResponseData exists with 'type=${type}'`);
  }
}

