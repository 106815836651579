<template>
    <PegasusHeading>VIKÖ egyéni bíráló felület</PegasusHeading>

    <PegasusPanel header="Pályázatok">
        <PegasusTable :data="summary.applications">
            <Column field="user.name" header="Név"></Column>
            <Column field="reportCount" header="Beszámolók száma"></Column>
            <Column :field="item => item.reviews[user.id]?.points ?? ''" header="Pontszám"></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton v-if="summary.userReviewerGroupId" variant="success" @click="open(data)">
                        <i class="fa fa-gavel"></i> Bírálás
                    </PegasusButton>

                    <PegasusButton v-else variant="primary" @click="open(data)">
                        <i class="fa fa-eye"></i> Megtekintés
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>VIKÖ Egyéni bírálás</title>

<script lang="ts" setup>
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {useAuthStore} from "@/stores/auth";
import {filter, maxBy, minBy} from "lodash-es";
import {ReviewSummary} from "@Models/viko/ReviewSummary";
import {Application} from "@Models/viko/Application";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {Dialog} from '@Utils/dialog';
import ApplicationDialog from '@Components/viko/reviewer/ApplicationDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ref} from 'vue';

const user = useAuthStore().requireUser();

const {tenderId} = defineProps<{
    tenderId: number
}>();

const summary = ref(await ReviewSummary.get(tenderId));

async function open(application: Application) {
    let current: Application | undefined = application;

    let result = null;
    do {
        result = await Dialog.open(ApplicationDialog, {
            application: current,
            reviewer: summary.value.userReviewerGroupId ? user : null,
            groupId: null,
            reviewers: [],
            onNext: () => current = minBy(filter(summary.value.applications, app => app.id > (current?.id ?? 0)), app => app.id),
            onPrev: () => current = maxBy(filter(summary.value.applications, app => app.id < (current?.id ?? 0)), app => app.id),
        });
    } while (current && result);
}
</script>
