/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsClosureTypeResponse } from './EventsClosureTypeResponse';
    import {
    EventsClosureTypeResponseFromJSON,
    EventsClosureTypeResponseFromJSONTyped,
    EventsClosureTypeResponseToJSON,
    EventsClosureTypeResponseCreateEmpty,
    } from './EventsClosureTypeResponse';
    import type { EventsLocationResponse } from './EventsLocationResponse';
    import {
    EventsLocationResponseFromJSON,
    EventsLocationResponseFromJSONTyped,
    EventsLocationResponseToJSON,
    EventsLocationResponseCreateEmpty,
    } from './EventsLocationResponse';
    import type { EventsServiceConfigResponse } from './EventsServiceConfigResponse';
    import {
    EventsServiceConfigResponseFromJSON,
    EventsServiceConfigResponseFromJSONTyped,
    EventsServiceConfigResponseToJSON,
    EventsServiceConfigResponseCreateEmpty,
    } from './EventsServiceConfigResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseEventsConstantsResponse {
  services: Array<EventsServiceConfigResponse>;
  closureTypes: Array<EventsClosureTypeResponse>;
  locations: Array<EventsLocationResponse>;
  calendar: string;
}

export const BaseEventsConstantsResponseSchema = {
  'services': {
    name: 'services',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'closureTypes': {
    name: 'closureTypes',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'locations': {
    name: 'locations',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'calendar': {
    name: 'calendar',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfBaseEventsConstantsResponse(value: object): value is BaseEventsConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "services" in value;
    isInstance = isInstance && "closureTypes" in value;
    isInstance = isInstance && "locations" in value;
    isInstance = isInstance && "calendar" in value;

return isInstance;
}

export function BaseEventsConstantsResponseFromJSON(json: any): BaseEventsConstantsResponse {
return BaseEventsConstantsResponseFromJSONTyped(json, false);
}

export function BaseEventsConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseEventsConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'services': ((json['services'] as Array<any>).map(EventsServiceConfigResponseFromJSON)),
            'closureTypes': ((json['closureTypes'] as Array<any>).map(EventsClosureTypeResponseFromJSON)),
            'locations': ((json['locations'] as Array<any>).map(EventsLocationResponseFromJSON)),
          'calendar': json['calendar'],
    };
  }

  export function BaseEventsConstantsResponseToJSON(value?: BaseEventsConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'services': ((value.services as Array<any>).map(EventsServiceConfigResponseToJSON)),
              'closureTypes': ((value.closureTypes as Array<any>).map(EventsClosureTypeResponseToJSON)),
              'locations': ((value.locations as Array<any>).map(EventsLocationResponseToJSON)),
                'calendar': value.calendar,
    };
  }

  export function BaseEventsConstantsResponseCreateEmpty(): BaseEventsConstantsResponse {
  return {
      'services': [],
      'closureTypes': [],
      'locations': [],
      'calendar': '',
  };
  }

