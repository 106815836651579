/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGlobalPermission } from './BaseGlobalPermission';
    import {
    BaseGlobalPermissionFromJSON,
    BaseGlobalPermissionFromJSONTyped,
    BaseGlobalPermissionToJSON,
    BaseGlobalPermissionCreateEmpty,
    } from './BaseGlobalPermission';
import {DateTime} from '@Utils/DateTime';
import { BaseGlobalPermissionValues } from './BaseGlobalPermission';

export interface BasePermissionResponse {
  id: BaseGlobalPermission;
  name: string;
  description: string;
}

export const BasePermissionResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: BaseGlobalPermissionValues,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfBasePermissionResponse(value: object): value is BasePermissionResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function BasePermissionResponseFromJSON(json: any): BasePermissionResponse {
return BasePermissionResponseFromJSONTyped(json, false);
}

export function BasePermissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasePermissionResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'id': BaseGlobalPermissionFromJSON(json['id']),
          'name': json['name'],
          'description': json['description'],
    };
  }

  export function BasePermissionResponseToJSON(value?: BasePermissionResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': BaseGlobalPermissionToJSON(value.id),
                'name': value.name,
                'description': value.description,
    };
  }

  export function BasePermissionResponseCreateEmpty(): BasePermissionResponse {
  return {
          'id': BaseGlobalPermissionCreateEmpty(),
      'name': '',
      'description': '',
  };
  }

