import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import type {InventoryItemUpdate, InventoryUpdateRepair, InventoryUpdateResponse} from "@/api/api";
import {InventoryApi, InventoryQuality, InventoryQualityValues, InventoryUpdateType} from "@/api/api";
import type {FormRef} from '@/vue';

export class Repair extends ItemUpdate {
    type = InventoryUpdateType.REPAIR;

    quality: InventoryQuality;

    description: string;

    price: number;

    static createNew(): Repair {
        const repair = new Repair();

        repair.description = '';
        repair.price = 0;

        return repair;
    }

    static parseResponse(repair: Repair, data: InventoryUpdateResponse): Repair {
        ItemUpdate.parseResponse(repair, data);

        const specific = data.data as InventoryUpdateRepair;

        repair.quality = specific.quality;
        repair.description = specific.description;
        repair.price = specific.price;

        return repair;
    }

    public store(itemId: number, form: FormRef): Promise<Repair> {
        const data: InventoryItemUpdate = {
            type: InventoryUpdateType.REPAIR,
            data: {
                type: InventoryUpdateType.REPAIR,
                quality: this.quality,
                description: this.description,
                price: this.price,
            },
            comment: this.comment,
        };

        return InventoryApi.itemUpdatesAdd(itemId, data, {form}).then(response => {
            return Repair.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        const level = InventoryQualityValues[this.quality];

        return [
            {key: 'Javítás utáni állapot', value: level},
            {key: 'Leírás', value: this.description},
            {key: 'Költség', value: this.price.huf()},
        ];
    }
}
