import {Check} from "@Models/events/Check";
import {BaseEntity} from "@Models/BaseEntity";
import type {EventsCheckListResponse} from "@/api/api";
import {EventsApi} from "@/api/api";

export class Checks extends BaseEntity {
    info: Check[];

    todo: Check[];

    warning: Check[];

    fatal: Check[];

    static getForEvent(eventId: number): Promise<Checks> {
        return EventsApi.eventsCheck(eventId).then(response => {
            return Checks.newSingle(response.data, Checks.parseResponse);
        });
    }

    static parseResponse(checks: Checks, data: EventsCheckListResponse): Checks {
        checks.info = Check.newArray(data.info, Check.parseResponse);
        checks.todo = Check.newArray(data.todo, Check.parseResponse);
        checks.warning = Check.newArray(data.warning, Check.parseResponse);
        checks.fatal = Check.newArray(data.fatal, Check.parseResponse);

        return checks;
    }
}
