/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyItemCommentType } from './MoneyItemCommentType';
    import {
    MoneyItemCommentTypeFromJSON,
    MoneyItemCommentTypeFromJSONTyped,
    MoneyItemCommentTypeToJSON,
    MoneyItemCommentTypeCreateEmpty,
    } from './MoneyItemCommentType';
import {DateTime} from '@Utils/DateTime';
import { MoneyItemCommentTypeValues } from './MoneyItemCommentType';

export interface MoneyItemCommentStore {
  item: number;
  type: MoneyItemCommentType;
  text: string;
}

export const MoneyItemCommentStoreSchema = {
  'item': {
    name: 'item',
    required: true,
    nullable: false,
    label: 'KPR tétel',
    type: 'number',
      minimum: 0,
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Megjegyzés típusa",
        enumValues: MoneyItemCommentTypeValues,
  },
  'text': {
    name: 'text',
    required: true,
    nullable: false,
    label: 'Megjegyzés szövege',
    type: 'string',
      maxLength: 3000,
  },
}
export function instanceOfMoneyItemCommentStore(value: object): value is MoneyItemCommentStore {
let isInstance = true;
    isInstance = isInstance && "item" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "text" in value;

return isInstance;
}

export function MoneyItemCommentStoreFromJSON(json: any): MoneyItemCommentStore {
return MoneyItemCommentStoreFromJSONTyped(json, false);
}

export function MoneyItemCommentStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemCommentStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'item': json['item'],
              'type': MoneyItemCommentTypeFromJSON(json['type']),
          'text': json['text'],
    };
  }

  export function MoneyItemCommentStoreToJSON(value?: MoneyItemCommentStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'item': value.item,
                'type': MoneyItemCommentTypeToJSON(value.type),
                'text': value.text,
    };
  }

  export function MoneyItemCommentStoreCreateEmpty(): MoneyItemCommentStore {
  return {
      'item': 0,
          'type': MoneyItemCommentTypeCreateEmpty(),
      'text': '',
  };
  }

