import {GroupStatement} from "@Models/rvt/statements/GroupStatement";
import {DateTime} from "@Utils/DateTime";
import {StatementStatus} from "@Models/rvt/statements/StatementStatus";
import {Group} from "@Models/base/Group";

export class StatementMonth {
    year: number;

    month: number;

    group: Group;

    statement: GroupStatement | null;

    constructor(year: number, month: number, group: Group, statement: GroupStatement | null) {
        this.year = year;
        this.month = month;
        this.group = group;
        this.statement = statement;
    }

    public createStatement(): GroupStatement {
        const statement = new GroupStatement();

        statement.year = this.year;
        statement.month = this.month;
        statement.group = this.group;
        statement.text = '';

        return statement;
    }

    get monthName(): string {
        return this.date.monthLong;
    }

    get id(): string {
        return 'month-' + this.year + '-' + this.month;
    }

    get date(): DateTime {
        return DateTime.from(this.year, this.month);
    }

    get deadline(): DateTime {
        return this.date.addMonth().setDay(12).endOfDay();
    }

    get isFuture(): boolean {
        return this.status === StatementStatus.FUTURE;
    }

    get status(): StatementStatus {
        const now = DateTime.now().setDay(10);

        if (this.statement !== null) {
            return StatementStatus.FILLED;
        }

        if (this.year === now.year && this.month === now.month) {
            return StatementStatus.ACTUAL;
        }

        if (this.date.isFuture()) {
            return StatementStatus.FUTURE;
        }

        if (this.deadline.isFuture()) {
            return StatementStatus.SHOULD_FILL;
        }

        return StatementStatus.EXPIRED;
    }

    get statusText(): string {
        switch (this.status) {
            case StatementStatus.ACTUAL:
                return 'Jelenlegi hónap';
            case StatementStatus.EXPIRED:
                return 'Archív';
            case StatementStatus.FILLED:
                return 'Kitöltve';
            case StatementStatus.FUTURE:
                return 'Jövőbeli hónap';
            case StatementStatus.SHOULD_FILL:
                return 'Kitöltésre vár';
        }
    }
}
