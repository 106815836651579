/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyMinimalItemResponse {
  id: number;
  name: string;
  description: string;
  price: number;
  contribution: number | null;
  approvedPrice: number | null;
  finalPrice: number | null;
  type: string | null;
  applicationId: number | null;
}

export const MoneyMinimalItemResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'price': {
    name: 'price',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'contribution': {
    name: 'contribution',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
  'approvedPrice': {
    name: 'approvedPrice',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
  'finalPrice': {
    name: 'finalPrice',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'applicationId': {
    name: 'applicationId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
}
export function instanceOfMoneyMinimalItemResponse(value: object): value is MoneyMinimalItemResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "contribution" in value;
    isInstance = isInstance && "approvedPrice" in value;
    isInstance = isInstance && "finalPrice" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "applicationId" in value;

return isInstance;
}

export function MoneyMinimalItemResponseFromJSON(json: any): MoneyMinimalItemResponse {
return MoneyMinimalItemResponseFromJSONTyped(json, false);
}

export function MoneyMinimalItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyMinimalItemResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'price': json['price'],
          'contribution': json['contribution'],
          'approvedPrice': json['approvedPrice'],
          'finalPrice': json['finalPrice'],
          'type': json['type'],
          'applicationId': json['applicationId'],
    };
  }

  export function MoneyMinimalItemResponseToJSON(value?: MoneyMinimalItemResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'price': value.price,
                'contribution': value.contribution,
                'approvedPrice': value.approvedPrice,
                'finalPrice': value.finalPrice,
                'type': value.type,
                'applicationId': value.applicationId,
    };
  }

  export function MoneyMinimalItemResponseCreateEmpty(): MoneyMinimalItemResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'price': 0,
      'contribution': 0,
      'approvedPrice': 0,
      'finalPrice': 0,
      'type': '',
      'applicationId': 0,
  };
  }

