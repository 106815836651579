/* tslint:disable */
/* eslint-disable */

export enum VikoReviewStage {
    STAGE_NONE = 0,
    STAGE_GROUP = 1,
    STAGE_ALL = 2
}

export const VikoReviewStageValues: Record<VikoReviewStage, string> = {
    0: 'Egyéni bírálás',
    1: 'Csoportos bírálás',
    2: 'Közös bírálás'
}


export function VikoReviewStageFromJSON(json: any): VikoReviewStage {
    return VikoReviewStageFromJSONTyped(json, false);
}

export function VikoReviewStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReviewStage {
    return json as VikoReviewStage;
}

export function VikoReviewStageToJSON(value?: VikoReviewStage | null): any {
    return value as any;
}

export function VikoReviewStageCreateEmpty(): VikoReviewStage {
    return VikoReviewStage.STAGE_NONE;
}

