/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { RvtGroupStatementResponse } from './RvtGroupStatementResponse';
    import {
    RvtGroupStatementResponseFromJSON,
    RvtGroupStatementResponseFromJSONTyped,
    RvtGroupStatementResponseToJSON,
    RvtGroupStatementResponseCreateEmpty,
    } from './RvtGroupStatementResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtGroupStatementListResponse {
  statements: Array<RvtGroupStatementResponse>;
}

export const RvtGroupStatementListResponseSchema = {
  'statements': {
    name: 'statements',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfRvtGroupStatementListResponse(value: object): value is RvtGroupStatementListResponse {
let isInstance = true;
    isInstance = isInstance && "statements" in value;

return isInstance;
}

export function RvtGroupStatementListResponseFromJSON(json: any): RvtGroupStatementListResponse {
return RvtGroupStatementListResponseFromJSONTyped(json, false);
}

export function RvtGroupStatementListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtGroupStatementListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'statements': ((json['statements'] as Array<any>).map(RvtGroupStatementResponseFromJSON)),
    };
  }

  export function RvtGroupStatementListResponseToJSON(value?: RvtGroupStatementListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'statements': ((value.statements as Array<any>).map(RvtGroupStatementResponseToJSON)),
    };
  }

  export function RvtGroupStatementListResponseCreateEmpty(): RvtGroupStatementListResponse {
  return {
      'statements': [],
  };
  }

