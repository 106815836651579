/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsClosureStore {
  event: number;
  type: number;
  begin: DateTime;
  end: DateTime;
  comment: string;
}

export const EventsClosureStoreSchema = {
  'event': {
    name: 'event',
    required: true,
    nullable: false,
    label: 'Rendezvény',
    type: 'number',
      minimum: 0,
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
    label: 'Lezáratás típusa',
    type: 'number',
      minimum: 0,
  },
  'begin': {
    name: 'begin',
    required: true,
    nullable: false,
    label: 'Lezáratás kezdete',
    type: 'datetime',
  },
  'end': {
    name: 'end',
    required: true,
    nullable: false,
    label: 'Lezáratás vége',
    type: 'datetime',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfEventsClosureStore(value: object): value is EventsClosureStore {
let isInstance = true;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "begin" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function EventsClosureStoreFromJSON(json: any): EventsClosureStore {
return EventsClosureStoreFromJSONTyped(json, false);
}

export function EventsClosureStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsClosureStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'event': json['event'],
          'type': json['type'],
        'begin': (DateTime.fromISO(json['begin'])),
        'end': (DateTime.fromISO(json['end'])),
          'comment': json['comment'],
    };
  }

  export function EventsClosureStoreToJSON(value?: EventsClosureStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'event': value.event,
                'type': value.type,
            'begin': (value.begin.toISO()),
            'end': (value.end.toISO()),
                'comment': value.comment,
    };
  }

  export function EventsClosureStoreCreateEmpty(): EventsClosureStore {
  return {
      'event': 0,
      'type': 0,
      'begin': DateTime.now(),
      'end': DateTime.now(),
      'comment': '',
  };
  }

