import {BaseEntity} from "@Models/BaseEntity";
import {Training} from "@Models/events/Training";
import {User} from "@Models/base/User";
import type {EventsTrainingParticipationResponse} from "@/api/api";
import {EventsApi} from "@/api/api";

export class TrainingParticipation extends BaseEntity {
    training: Training;

    user: User;

    active: boolean;

    quizResult: number | null;

    static getByTraining(id: number): Promise<Record<number, TrainingParticipation>> {
        return EventsApi.trainingParticipationsIndex(id).then(response => {
            return TrainingParticipation.newRecords(response.data.participations, TrainingParticipation.parseResponse);
        });
    }

    static get(training: Training, user: User): Promise<TrainingParticipation> {
        return EventsApi.trainingParticipationsShow(training.id, user.id).then(response => {
            return TrainingParticipation.newSingle(response.data, TrainingParticipation.parseResponse);
        });
    }

    static createNew(training: Training, user: User): TrainingParticipation {
        const trainingParticipation = new TrainingParticipation();

        trainingParticipation.training = training;
        trainingParticipation.user = user;
        trainingParticipation.active = false;
        trainingParticipation.quizResult = 0;

        return trainingParticipation;
    }

    static parseResponse(participation: TrainingParticipation, data: EventsTrainingParticipationResponse): TrainingParticipation {
        participation.id = data.id;
        participation.training = Training.newSingle(data.training, Training.parseResponse);
        participation.user = User.newSingle(data.user, User.parseMinimalResponse);
        participation.active = data.active;
        participation.quizResult = data.quizResult;

        return participation;
    }

    get status() {
        if (!this.active) {
            return 'Jelentkezett, de nem lett érvényesítve';
        }

        if (!this.training.active) {
            return 'Részt vett, de már nem jogosult';
        }

        return 'Részt vett és jogosult bejelenteni';
    }

    public store(): Promise<TrainingParticipation> {
        return EventsApi.trainingParticipationsStore(this.training.id, this.user.id).then(response => {
            return TrainingParticipation.parseResponse(this, response.data);
        });
    }

    public update(): Promise<void> {
        const data = {
            active: this.active,
            quizResult: this.quizResult,
        };

        return EventsApi.trainingParticipationsUpdate(this.training.id, this.user.id, data).then(response => {
            TrainingParticipation.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return EventsApi.trainingParticipationsDelete(this.training.id, this.user.id).then();
    }
}
