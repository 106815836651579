/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { AuthAuthschEntitlement } from './AuthAuthschEntitlement';
    import {
    AuthAuthschEntitlementFromJSON,
    AuthAuthschEntitlementFromJSONTyped,
    AuthAuthschEntitlementToJSON,
    AuthAuthschEntitlementCreateEmpty,
    } from './AuthAuthschEntitlement';
    import type { AuthAuthschEntrant } from './AuthAuthschEntrant';
    import {
    AuthAuthschEntrantFromJSON,
    AuthAuthschEntrantFromJSONTyped,
    AuthAuthschEntrantToJSON,
    AuthAuthschEntrantCreateEmpty,
    } from './AuthAuthschEntrant';
    import type { AuthAuthschLastSync } from './AuthAuthschLastSync';
    import {
    AuthAuthschLastSyncFromJSON,
    AuthAuthschLastSyncFromJSONTyped,
    AuthAuthschLastSyncToJSON,
    AuthAuthschLastSyncCreateEmpty,
    } from './AuthAuthschLastSync';
    import type { AuthAuthschLinkedAccount } from './AuthAuthschLinkedAccount';
    import {
    AuthAuthschLinkedAccountFromJSON,
    AuthAuthschLinkedAccountFromJSONTyped,
    AuthAuthschLinkedAccountToJSON,
    AuthAuthschLinkedAccountCreateEmpty,
    } from './AuthAuthschLinkedAccount';
    import type { BaseBmeUnitScope } from './BaseBmeUnitScope';
    import {
    BaseBmeUnitScopeFromJSON,
    BaseBmeUnitScopeFromJSONTyped,
    BaseBmeUnitScopeToJSON,
    BaseBmeUnitScopeCreateEmpty,
    } from './BaseBmeUnitScope';
import {DateTime} from '@Utils/DateTime';

export interface AuthAuthschProfileResponse {
  internalId: string;
  displayName: string;
  sn: string | null;
  givenName: string | null;
  mail: string;
  niifPersonOrgID: string | null;
  linkedAccounts: AuthAuthschLinkedAccount | null;
  lastSync: AuthAuthschLastSync | null;
  eduPersonEntitlement: Array<AuthAuthschEntitlement> | null;
  legacyVir: string | null;
  mobile: string | null;
  niifEduPersonAttendedCourse: string | null;
  entrants: Array<AuthAuthschEntrant> | null;
  admembership: Array<string>;
  bmeunitscope: Array<BaseBmeUnitScope>;
  birthdate: string | null;
  permanentaddress: string | null;
}

export const AuthAuthschProfileResponseSchema = {
  'internalId': {
    name: 'internalId',
    required: true,
    nullable: false,
    label: 'Authsch belső azonosító',
    type: 'string',
  },
  'displayName': {
    name: 'displayName',
    required: true,
    nullable: false,
    label: 'Teljes név',
    type: 'string',
  },
  'sn': {
    name: 'sn',
    required: true,
    nullable: true,
    label: 'Családnév',
    type: 'string',
  },
  'givenName': {
    name: 'givenName',
    required: true,
    nullable: true,
    label: 'Keresztnév',
    type: 'string',
  },
  'mail': {
    name: 'mail',
    required: true,
    nullable: false,
    label: 'E-mail cím',
    type: 'string',
  },
  'niifPersonOrgID': {
    name: 'niifPersonOrgID',
    required: true,
    nullable: true,
    label: 'Neptun kód',
    type: 'string',
  },
  'linkedAccounts': {
    name: 'linkedAccounts',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'lastSync': {
    name: 'lastSync',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'eduPersonEntitlement': {
    name: 'eduPersonEntitlement',
    required: true,
    nullable: true,
    label: 'Körtagságok',
      type: 'array',
  },
  'legacyVir': {
    name: 'legacyVir',
    required: true,
    nullable: true,
    label: 'PéK azonosító',
    type: 'string',
  },
  'mobile': {
    name: 'mobile',
    required: true,
    nullable: true,
    label: 'Telefonszám',
    type: 'string',
  },
  'niifEduPersonAttendedCourse': {
    name: 'niifEduPersonAttendedCourse',
    required: true,
    nullable: true,
    label: 'Felvett tárgyak',
    type: 'string',
  },
  'entrants': {
    name: 'entrants',
    required: true,
    nullable: true,
    label: 'Körös belépők',
      type: 'array',
  },
  'admembership': {
    name: 'admembership',
    required: true,
    nullable: false,
    label: 'AD csoporttagságok',
    type: 'array',
  },
  'bmeunitscope': {
    name: 'bmeunitscope',
    required: true,
    nullable: false,
    label: 'BME scope-ok',
      type: 'array',
  },
  'birthdate': {
    name: 'birthdate',
    required: true,
    nullable: true,
    label: 'Születési dátum',
    type: 'string',
  },
  'permanentaddress': {
    name: 'permanentaddress',
    required: true,
    nullable: true,
    label: 'Állandó lakcím',
    type: 'string',
  },
}
export function instanceOfAuthAuthschProfileResponse(value: object): value is AuthAuthschProfileResponse {
let isInstance = true;
    isInstance = isInstance && "internalId" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "sn" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "mail" in value;
    isInstance = isInstance && "niifPersonOrgID" in value;
    isInstance = isInstance && "linkedAccounts" in value;
    isInstance = isInstance && "lastSync" in value;
    isInstance = isInstance && "eduPersonEntitlement" in value;
    isInstance = isInstance && "legacyVir" in value;
    isInstance = isInstance && "mobile" in value;
    isInstance = isInstance && "niifEduPersonAttendedCourse" in value;
    isInstance = isInstance && "entrants" in value;
    isInstance = isInstance && "admembership" in value;
    isInstance = isInstance && "bmeunitscope" in value;
    isInstance = isInstance && "birthdate" in value;
    isInstance = isInstance && "permanentaddress" in value;

return isInstance;
}

export function AuthAuthschProfileResponseFromJSON(json: any): AuthAuthschProfileResponse {
return AuthAuthschProfileResponseFromJSONTyped(json, false);
}

export function AuthAuthschProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthAuthschProfileResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'internalId': json['internal_id'],
          'displayName': json['displayName'],
          'sn': json['sn'],
          'givenName': json['givenName'],
          'mail': json['mail'],
          'niifPersonOrgID': json['niifPersonOrgID'],
              'linkedAccounts': AuthAuthschLinkedAccountFromJSON(json['linkedAccounts']),
              'lastSync': AuthAuthschLastSyncFromJSON(json['lastSync']),
            'eduPersonEntitlement': (json['eduPersonEntitlement'] === null ? null : (json['eduPersonEntitlement'] as Array<any>).map(AuthAuthschEntitlementFromJSON)),
          'legacyVir': json['legacyVir'],
          'mobile': json['mobile'],
          'niifEduPersonAttendedCourse': json['niifEduPersonAttendedCourse'],
            'entrants': (json['entrants'] === null ? null : (json['entrants'] as Array<any>).map(AuthAuthschEntrantFromJSON)),
          'admembership': json['admembership'],
            'bmeunitscope': ((json['bmeunitscope'] as Array<any>).map(BaseBmeUnitScopeFromJSON)),
          'birthdate': json['birthdate'],
          'permanentaddress': json['permanentaddress'],
    };
  }

  export function AuthAuthschProfileResponseToJSON(value?: AuthAuthschProfileResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'internal_id': value.internalId,
                'displayName': value.displayName,
                'sn': value.sn,
                'givenName': value.givenName,
                'mail': value.mail,
                'niifPersonOrgID': value.niifPersonOrgID,
                'linkedAccounts': AuthAuthschLinkedAccountToJSON(value.linkedAccounts),
                'lastSync': AuthAuthschLastSyncToJSON(value.lastSync),
              'eduPersonEntitlement': (
                value.eduPersonEntitlement === null ? null : (value.eduPersonEntitlement as Array<any>).map(AuthAuthschEntitlementToJSON)),
                'legacyVir': value.legacyVir,
                'mobile': value.mobile,
                'niifEduPersonAttendedCourse': value.niifEduPersonAttendedCourse,
              'entrants': (
                value.entrants === null ? null : (value.entrants as Array<any>).map(AuthAuthschEntrantToJSON)),
            'admembership': value.admembership,
              'bmeunitscope': ((value.bmeunitscope as Array<any>).map(BaseBmeUnitScopeToJSON)),
                'birthdate': value.birthdate,
                'permanentaddress': value.permanentaddress,
    };
  }

  export function AuthAuthschProfileResponseCreateEmpty(): AuthAuthschProfileResponse {
  return {
      'internalId': '',
      'displayName': '',
      'sn': '',
      'givenName': '',
      'mail': '',
      'niifPersonOrgID': '',
          'linkedAccounts': AuthAuthschLinkedAccountCreateEmpty(),
          'lastSync': AuthAuthschLastSyncCreateEmpty(),
      'eduPersonEntitlement': [],
      'legacyVir': '',
      'mobile': '',
      'niifEduPersonAttendedCourse': '',
      'entrants': [],
      'admembership': [],
      'bmeunitscope': [],
      'birthdate': '',
      'permanentaddress': '',
  };
  }

