<template>
    <PegasusHeading>Admin</PegasusHeading>

    <PegasusPanel header="E-mail sablonok">
        <h5>Reszortvezető kapja</h5>

        <PegasusButton variant="danger" @click="generate('rvtRequestsReceived')">
            Kérvény elbírálásra vár
        </PegasusButton>
        <br/>

        <h5>Kérvény leadó kapja</h5>
        <PegasusButton variant="success" @click="generate('rvtRequestsPreApproved')">
            Reszortvezető által jóváhagyva
        </PegasusButton>
        <PegasusButton variant="danger" @click="generate('rvtRequestsPreRejected')">
            Reszortvezető által visszautasítva
        </PegasusButton>
        <br/>
        <PegasusButton variant="success" @click="generate('rvtRequestsApproved')">
            RVT által elfogadva
        </PegasusButton>
        <PegasusButton variant="danger" @click="generate('rvtRequestsRejected')">
            RVT által elutasítva
        </PegasusButton>
        <br/>

        <h5>Admin e-mailek</h5>
        <PegasusButton @click="generate('rvtRequestsProcess')">
            Kérvény jóváhagyva
        </PegasusButton>
    </PegasusPanel>
</template>

<title>Admin</title>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import {BaseGlobalPermission} from "@/api/models";
import {AccessDeniedError} from "@Utils/errors/AccessDeniedError";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {Dialog} from '@Utils/dialog';
import EmailPreviewDialog from '@Components/system/emails/EmailPreviewDialog.vue';

const user = useAuthStore().requireUser();

if (!user.value.hasPermission(BaseGlobalPermission.ADMIN_ACL)) {
    throw new AccessDeniedError();
}

async function generate(template: string) {
    return Dialog.open(EmailPreviewDialog, {template});
}
</script>
