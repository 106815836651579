/* tslint:disable */
/* eslint-disable */

import {
    type EventsAdminEventResponse,
    EventsAdminEventResponseFromJSONTyped,
    EventsAdminEventResponseToJSON,
    EventsAdminEventResponseCreateEmpty,
    instanceOfEventsAdminEventResponse,
} from './EventsAdminEventResponse';
import {
    type EventsExtendedEventResponse,
    EventsExtendedEventResponseFromJSONTyped,
    EventsExtendedEventResponseToJSON,
    EventsExtendedEventResponseCreateEmpty,
    instanceOfEventsExtendedEventResponse,
} from './EventsExtendedEventResponse';

export type EventsEventsShowDefaultResponse = EventsAdminEventResponse | EventsExtendedEventResponse;
export type EventsEventsShowDefaultResponseSimple = EventsAdminEventResponse | EventsExtendedEventResponse;



export function EventsEventsShowDefaultResponseFromJSON(json: any): EventsEventsShowDefaultResponse {
    return EventsEventsShowDefaultResponseFromJSONTyped(json, false);
}

export function EventsEventsShowDefaultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsEventsShowDefaultResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...EventsAdminEventResponseFromJSONTyped(json, true), ...EventsExtendedEventResponseFromJSONTyped(json, true) };
}

export function EventsEventsShowDefaultResponseToJSON(value?: EventsEventsShowDefaultResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfEventsAdminEventResponse(value)) {
        return EventsAdminEventResponseToJSON(value as EventsAdminEventResponse);
    }
    if (instanceOfEventsExtendedEventResponse(value)) {
        return EventsExtendedEventResponseToJSON(value as EventsExtendedEventResponse);
    }

    return {};
}
export function EventsEventsShowDefaultResponseCreateEmpty(): EventsEventsShowDefaultResponse {
      return EventsAdminEventResponseCreateEmpty();
}
export function EventsEventsShowDefaultResponseCreateEmptyByType(type: string): EventsEventsShowDefaultResponseSimple {
  switch (type) {
    default:
      throw new Error(`No variant of EventsEventsShowDefaultResponse exists with '=${type}'`);
  }
}

