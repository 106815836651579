/* tslint:disable */
/* eslint-disable */

export enum EventsMilestoneType {
    CREATE = 'create',
    SUBMIT = 'submit',
    APPROVE = 'approve',
    REJECT = 'reject',
    ADMIN_MOD = 'adminmod',
    REPORT = 'report',
    REPORT_ACK = 'reportack',
    INTERNAL = 'internal',
    CANCEL = 'cancel'
}

export const EventsMilestoneTypeValues: Record<EventsMilestoneType, string> = {
    'create': 'Létrehozva',
    'submit': 'Beküldve',
    'approve': 'Elfogadva',
    'reject': 'Visszadobva',
    'adminmod': 'Admin módosítás',
    'report': 'Üzemeltetés bejelentés',
    'reportack': 'Üzemeltetés jóváhagyás',
    'internal': 'Belső megjegyzés',
    'cancel': 'Visszamondás'
}


export function EventsMilestoneTypeFromJSON(json: any): EventsMilestoneType {
    return EventsMilestoneTypeFromJSONTyped(json, false);
}

export function EventsMilestoneTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsMilestoneType {
    return json as EventsMilestoneType;
}

export function EventsMilestoneTypeToJSON(value?: EventsMilestoneType | null): any {
    return value as any;
}

export function EventsMilestoneTypeCreateEmpty(): EventsMilestoneType {
    return EventsMilestoneType.CREATE;
}

