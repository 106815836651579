/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsManagerResponse {
  id: number;
  leader: boolean;
  active: boolean;
  user: BaseUserResponse;
}

export const EventsManagerResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'leader': {
    name: 'leader',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfEventsManagerResponse(value: object): value is EventsManagerResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "leader" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "user" in value;

return isInstance;
}

export function EventsManagerResponseFromJSON(json: any): EventsManagerResponse {
return EventsManagerResponseFromJSONTyped(json, false);
}

export function EventsManagerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsManagerResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'leader': json['leader'],
          'active': json['active'],
              'user': BaseUserResponseFromJSON(json['user']),
    };
  }

  export function EventsManagerResponseToJSON(value?: EventsManagerResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'leader': value.leader,
                'active': value.active,
                'user': BaseUserResponseToJSON(value.user),
    };
  }

  export function EventsManagerResponseCreateEmpty(): EventsManagerResponse {
  return {
      'id': 0,
      'leader': false,
      'active': false,
          'user': BaseUserResponseCreateEmpty(),
  };
  }

