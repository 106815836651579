/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneySpendingEventCashOut {
  money: number;
}

export const MoneySpendingEventCashOutSchema = {
  'money': {
    name: 'money',
    required: true,
    nullable: false,
    label: 'Összeg',
    type: 'number',
  },
}
export function instanceOfMoneySpendingEventCashOut(value: object): value is MoneySpendingEventCashOut {
let isInstance = true;
    isInstance = isInstance && "money" in value;

return isInstance;
}

export function MoneySpendingEventCashOutFromJSON(json: any): MoneySpendingEventCashOut {
return MoneySpendingEventCashOutFromJSONTyped(json, false);
}

export function MoneySpendingEventCashOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingEventCashOut {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'money': json['money'],
    };
  }

  export function MoneySpendingEventCashOutToJSON(value?: MoneySpendingEventCashOut | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'money': value.money,
    };
  }

  export function MoneySpendingEventCashOutCreateEmpty(): MoneySpendingEventCashOut {
  return {
      'money': 0,
  };
  }

