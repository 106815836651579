// @ts-nocheck
import {keyBy, mapValues} from "lodash-es";

export abstract class Base {
    static newSingle<ResponseClass>(response: ResponseClass, mapper: (entity: this, response: ResponseClass) => this): this {
        return mapper(new this(), response);
    }

    static newSingleNullable<ResponseClass>(response: ResponseClass | null, mapper: (entity: this, response: ResponseClass) => this): this | null {
        if (response === null) {
            return null;
        }

        return mapper(new this(), response);
    }

    static newRecords<ResponseClass>(responses: Record<number, ResponseClass>, mapper: (entity: this, response: ResponseClass) => this): Record<number, this> {
        const result = {};

        for (const on in responses) {
            result[responses[on].id] = this.newSingle(responses[on], mapper);
        }

        return result;
    }

    static newRecordsBy<ResponseClass, KeyType extends string>(responses: Record<number, ResponseClass>, mapper: (entity: this, response: ResponseClass) => this, keyByCallback: (entity: this) => KeyType): Record<KeyType, this> {
        const result = mapValues(responses, response => this.newSingle(response, mapper));

        return keyBy(result, result => keyByCallback(result));
    }

    static newArray<ResponseClass>(responses: Record<number, ResponseClass>, mapper: (entity: this, response: ResponseClass) => this): this[] {
        const result = [];

        for (const on in responses) {
            result.push(this.newSingle(responses[on], mapper));
        }

        return result;
    }
}
