/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoReportStore {
  application: number;
  group: number;
}

export const VikoReportStoreSchema = {
  'application': {
    name: 'application',
    required: true,
    nullable: false,
    label: 'Pályázat',
    type: 'number',
      minimum: 0,
  },
  'group': {
    name: 'group',
    required: true,
    nullable: false,
    label: 'Kör',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfVikoReportStore(value: object): value is VikoReportStore {
let isInstance = true;
    isInstance = isInstance && "application" in value;
    isInstance = isInstance && "group" in value;

return isInstance;
}

export function VikoReportStoreFromJSON(json: any): VikoReportStore {
return VikoReportStoreFromJSONTyped(json, false);
}

export function VikoReportStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReportStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'application': json['application'],
          'group': json['group'],
    };
  }

  export function VikoReportStoreToJSON(value?: VikoReportStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'application': value.application,
                'group': value.group,
    };
  }

  export function VikoReportStoreCreateEmpty(): VikoReportStore {
  return {
      'application': 0,
      'group': 0,
  };
  }

