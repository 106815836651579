<template>
    <PegasusModal :header="preview ? preview.subject : 'Generálás folyamatban'" width="900px">
        <div v-if="preview" class="embed-responsive embed-responsive-16by9">
            <iframe :srcdoc="preview.html" width="100%" height="800px">
            </iframe>
        </div>
        <div v-else>
            <i class="fa fa-spin fa-spinner"></i> Generálás folyamatban
        </div>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {MainApi} from '@/api/apis';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {ref} from 'vue';

const {template} = defineProps<{
    template: string
}>();

const preview = ref<{html: string, subject: string} | null>(null);

MainApi.emailsTemplate(template).then(result => {
    preview.value = result.data;
});
</script>
