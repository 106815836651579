/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyItemGroupUpdate {
  name: string;
  description: string;
}

export const MoneyItemGroupUpdateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Tételcsoport neve',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Tételcsoport leírása',
    type: 'string',
      maxLength: 5000,
  },
}
export function instanceOfMoneyItemGroupUpdate(value: object): value is MoneyItemGroupUpdate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function MoneyItemGroupUpdateFromJSON(json: any): MoneyItemGroupUpdate {
return MoneyItemGroupUpdateFromJSONTyped(json, false);
}

export function MoneyItemGroupUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemGroupUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
    };
  }

  export function MoneyItemGroupUpdateToJSON(value?: MoneyItemGroupUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
    };
  }

  export function MoneyItemGroupUpdateCreateEmpty(): MoneyItemGroupUpdate {
  return {
      'name': '',
      'description': '',
  };
  }

