/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoResultCategoryResponse {
  id: number;
  name: string;
  boundary: number;
  money: number;
}

export const VikoResultCategoryResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'boundary': {
    name: 'boundary',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'money': {
    name: 'money',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfVikoResultCategoryResponse(value: object): value is VikoResultCategoryResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "boundary" in value;
    isInstance = isInstance && "money" in value;

return isInstance;
}

export function VikoResultCategoryResponseFromJSON(json: any): VikoResultCategoryResponse {
return VikoResultCategoryResponseFromJSONTyped(json, false);
}

export function VikoResultCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoResultCategoryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'boundary': json['boundary'],
          'money': json['money'],
    };
  }

  export function VikoResultCategoryResponseToJSON(value?: VikoResultCategoryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'boundary': value.boundary,
                'money': value.money,
    };
  }

  export function VikoResultCategoryResponseCreateEmpty(): VikoResultCategoryResponse {
  return {
      'id': 0,
      'name': '',
      'boundary': 0,
      'money': 0,
  };
  }

