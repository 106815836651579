/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsServiceResponse } from './EventsServiceResponse';
    import {
    EventsServiceResponseFromJSON,
    EventsServiceResponseFromJSONTyped,
    EventsServiceResponseToJSON,
    EventsServiceResponseCreateEmpty,
    } from './EventsServiceResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsServiceListResponse {
  services: Array<EventsServiceResponse>;
}

export const EventsServiceListResponseSchema = {
  'services': {
    name: 'services',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsServiceListResponse(value: object): value is EventsServiceListResponse {
let isInstance = true;
    isInstance = isInstance && "services" in value;

return isInstance;
}

export function EventsServiceListResponseFromJSON(json: any): EventsServiceListResponse {
return EventsServiceListResponseFromJSONTyped(json, false);
}

export function EventsServiceListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'services': ((json['services'] as Array<any>).map(EventsServiceResponseFromJSON)),
    };
  }

  export function EventsServiceListResponseToJSON(value?: EventsServiceListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'services': ((value.services as Array<any>).map(EventsServiceResponseToJSON)),
    };
  }

  export function EventsServiceListResponseCreateEmpty(): EventsServiceListResponse {
  return {
      'services': [],
  };
  }

