/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { CommonCarNumber } from './CommonCarNumber';
    import {
    CommonCarNumberFromJSON,
    CommonCarNumberFromJSONTyped,
    CommonCarNumberToJSON,
    CommonCarNumberCreateEmpty,
    } from './CommonCarNumber';
import {DateTime} from '@Utils/DateTime';

export interface EventsCarPermitData {
  numbers: Array<CommonCarNumber>;
  reason: string;
  times: string;
}

export const EventsCarPermitDataSchema = {
  'numbers': {
    name: 'numbers',
    required: true,
    nullable: false,
    label: 'Rendszámok',
      type: 'array',
  },
  'reason': {
    name: 'reason',
    required: true,
    nullable: false,
    label: 'Indoklás',
    type: 'string',
      maxLength: 1000,
  },
  'times': {
    name: 'times',
    required: true,
    nullable: false,
    label: 'Időtartamok',
      labelHelp: `Milyen időszakokban van szükség a behajtásra`,
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfEventsCarPermitData(value: object): value is EventsCarPermitData {
let isInstance = true;
    isInstance = isInstance && "numbers" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "times" in value;

return isInstance;
}

export function EventsCarPermitDataFromJSON(json: any): EventsCarPermitData {
return EventsCarPermitDataFromJSONTyped(json, false);
}

export function EventsCarPermitDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsCarPermitData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'numbers': ((json['numbers'] as Array<any>).map(CommonCarNumberFromJSON)),
          'reason': json['reason'],
          'times': json['times'],
    };
  }

  export function EventsCarPermitDataToJSON(value?: EventsCarPermitData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'numbers': ((value.numbers as Array<any>).map(CommonCarNumberToJSON)),
                'reason': value.reason,
                'times': value.times,
    };
  }

  export function EventsCarPermitDataCreateEmpty(): EventsCarPermitData {
  return {
      'numbers': [],
      'reason': '',
      'times': '',
  };
  }

