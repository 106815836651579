/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetItemValueResponse } from './MoneyBudgetItemValueResponse';
    import {
    MoneyBudgetItemValueResponseFromJSON,
    MoneyBudgetItemValueResponseFromJSONTyped,
    MoneyBudgetItemValueResponseToJSON,
    MoneyBudgetItemValueResponseCreateEmpty,
    } from './MoneyBudgetItemValueResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetItemResponse {
  id: number;
  name: string;
  description: string;
  number: number;
  groupId: number;
  specialKpr: boolean;
  values: Array<MoneyBudgetItemValueResponse>;
}

export const MoneyBudgetItemResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Sorszám',
    type: 'number',
      minimum: 1,
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: 'Kör azonosítója',
    type: 'number',
      minimum: 1,
  },
  'specialKpr': {
    name: 'specialKpr',
    required: true,
    nullable: false,
    label: 'KPR-hez rendelt sor',
    type: 'boolean',
  },
  'values': {
    name: 'values',
    required: true,
    nullable: false,
    label: 'Lábak',
      type: 'array',
  },
}
export function instanceOfMoneyBudgetItemResponse(value: object): value is MoneyBudgetItemResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "specialKpr" in value;
    isInstance = isInstance && "values" in value;

return isInstance;
}

export function MoneyBudgetItemResponseFromJSON(json: any): MoneyBudgetItemResponse {
return MoneyBudgetItemResponseFromJSONTyped(json, false);
}

export function MoneyBudgetItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetItemResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'number': json['number'],
          'groupId': json['groupId'],
          'specialKpr': json['specialKpr'],
            'values': ((json['values'] as Array<any>).map(MoneyBudgetItemValueResponseFromJSON)),
    };
  }

  export function MoneyBudgetItemResponseToJSON(value?: MoneyBudgetItemResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'number': value.number,
                'groupId': value.groupId,
                'specialKpr': value.specialKpr,
              'values': ((value.values as Array<any>).map(MoneyBudgetItemValueResponseToJSON)),
    };
  }

  export function MoneyBudgetItemResponseCreateEmpty(): MoneyBudgetItemResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'number': 0,
      'groupId': 0,
      'specialKpr': false,
      'values': [],
  };
  }

