/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsTrainingResponse {
  id: number;
  date: DateTime;
  locationId: number;
  active: boolean;
  participantCount: number;
}

export const EventsTrainingResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'date': {
    name: 'date',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'locationId': {
    name: 'locationId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'participantCount': {
    name: 'participantCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfEventsTrainingResponse(value: object): value is EventsTrainingResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "locationId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "participantCount" in value;

return isInstance;
}

export function EventsTrainingResponseFromJSON(json: any): EventsTrainingResponse {
return EventsTrainingResponseFromJSONTyped(json, false);
}

export function EventsTrainingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsTrainingResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
        'date': (DateTime.fromISO(json['date'])),
          'locationId': json['locationId'],
          'active': json['active'],
          'participantCount': json['participantCount'],
    };
  }

  export function EventsTrainingResponseToJSON(value?: EventsTrainingResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
            'date': (value.date.toISO()),
                'locationId': value.locationId,
                'active': value.active,
                'participantCount': value.participantCount,
    };
  }

  export function EventsTrainingResponseCreateEmpty(): EventsTrainingResponse {
  return {
      'id': 0,
      'date': DateTime.now(),
      'locationId': 0,
      'active': false,
      'participantCount': 0,
  };
  }

