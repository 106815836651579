<template>
    <TextareaField
        v-model="data.description"
        :disabled="!service.canEdit"
        :schema="EventsHeatingPermitDataSchema.description"
    ></TextareaField>

    <small>
        <i class="fas fa-exclamation-circle text-danger"></i>
        A leírás közvetlenül az egyetemhez kerül jóváhagyásra, erre figyeljetek a megfogalmazásnál
    </small>
</template>

<script lang="ts" setup>
import type {Service} from "@Models/events/Service";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import {type EventsHeatingPermitData, EventsHeatingPermitDataSchema} from "@/api/models";

const data = defineModel<EventsHeatingPermitData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
