/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsElevatorPermitData {
  reason: string;
  times: string;
}

export const EventsElevatorPermitDataSchema = {
  'reason': {
    name: 'reason',
    required: true,
    nullable: false,
    label: 'Indoklás',
    type: 'string',
      maxLength: 1000,
  },
  'times': {
    name: 'times',
    required: true,
    nullable: false,
    label: 'Időtartamok',
      labelHelp: `Milyen időszakokban van szükség a liftre`,
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfEventsElevatorPermitData(value: object): value is EventsElevatorPermitData {
let isInstance = true;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "times" in value;

return isInstance;
}

export function EventsElevatorPermitDataFromJSON(json: any): EventsElevatorPermitData {
return EventsElevatorPermitDataFromJSONTyped(json, false);
}

export function EventsElevatorPermitDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsElevatorPermitData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'reason': json['reason'],
          'times': json['times'],
    };
  }

  export function EventsElevatorPermitDataToJSON(value?: EventsElevatorPermitData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'reason': value.reason,
                'times': value.times,
    };
  }

  export function EventsElevatorPermitDataCreateEmpty(): EventsElevatorPermitData {
  return {
      'reason': '',
      'times': '',
  };
  }

