<template>
    <PegasusHeading>{{ actual.displayName }}</PegasusHeading>

    <h5>Körök aminek KPR-éhez hozzáférsz</h5>

    <ul>
        <li v-for="access in groups" :key="access.group.id" style="list-style: none">
            <PegasusButton :params="{group: access.group, period: actual.spendingPeriod}" to="rvt.groups.money">
                {{ access.group.name }}
            </PegasusButton>
        </li>
    </ul>
</template>

<title>KPR</title>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import {useConstantsStore} from "@/stores/constants";
import {computed} from "vue";
import {NotFoundError} from "@Utils/errors/NotFoundError";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {filter, find} from "lodash-es";
import {BaseGroupSpecificPermission} from "@/api/models";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const user = useAuthStore().requireUser();

const tenders = useConstantsStore().constants.kpr.relevantTenders;
const actual = find(tenders, tender => tender.hasDrafting() ?? false);

if (!actual) {
    throw new NotFoundError('Jelenleg nincsen KPR pályázat leadási időszak');
}

const groups = computed(() => {
    return filter(user.value.groupAccesses, access => access.hasPermission([
        BaseGroupSpecificPermission.READ_MONEY,
        BaseGroupSpecificPermission.MANAGE_MONEY,
    ]));
});
</script>
