<template>
    <PegasusHeading>SGR Admin</PegasusHeading>

    <PegasusPanel header="Gazdasági évek">
        <PegasusTable :data="periods">
            <Column header="Év" field="year"></Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>SGR Admin</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import Column from 'primevue/column';
import {useConstantsStore} from '@/stores/constants';

const periods = useConstantsStore().constants.money.spendingPeriods;
</script>
