/* tslint:disable */
/* eslint-disable */

import {
    type MoneyConfidentialItemResponse,
    MoneyConfidentialItemResponseFromJSONTyped,
    MoneyConfidentialItemResponseToJSON,
    MoneyConfidentialItemResponseCreateEmpty,
    instanceOfMoneyConfidentialItemResponse,
} from './MoneyConfidentialItemResponse';
import {
    type MoneyItemResponse,
    MoneyItemResponseFromJSONTyped,
    MoneyItemResponseToJSON,
    MoneyItemResponseCreateEmpty,
    instanceOfMoneyItemResponse,
} from './MoneyItemResponse';

export type MoneyItemsUpdateDefaultResponse = MoneyConfidentialItemResponse | MoneyItemResponse;
export type MoneyItemsUpdateDefaultResponseSimple = MoneyConfidentialItemResponse | MoneyItemResponse;



export function MoneyItemsUpdateDefaultResponseFromJSON(json: any): MoneyItemsUpdateDefaultResponse {
    return MoneyItemsUpdateDefaultResponseFromJSONTyped(json, false);
}

export function MoneyItemsUpdateDefaultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemsUpdateDefaultResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...MoneyConfidentialItemResponseFromJSONTyped(json, true), ...MoneyItemResponseFromJSONTyped(json, true) };
}

export function MoneyItemsUpdateDefaultResponseToJSON(value?: MoneyItemsUpdateDefaultResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfMoneyConfidentialItemResponse(value)) {
        return MoneyConfidentialItemResponseToJSON(value as MoneyConfidentialItemResponse);
    }
    if (instanceOfMoneyItemResponse(value)) {
        return MoneyItemResponseToJSON(value as MoneyItemResponse);
    }

    return {};
}
export function MoneyItemsUpdateDefaultResponseCreateEmpty(): MoneyItemsUpdateDefaultResponse {
      return MoneyConfidentialItemResponseCreateEmpty();
}
export function MoneyItemsUpdateDefaultResponseCreateEmptyByType(type: string): MoneyItemsUpdateDefaultResponseSimple {
  switch (type) {
    default:
      throw new Error(`No variant of MoneyItemsUpdateDefaultResponse exists with '=${type}'`);
  }
}

