/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseUserListResponse {
  users: Array<BaseUserResponse>;
}

export const BaseUserListResponseSchema = {
  'users': {
    name: 'users',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseUserListResponse(value: object): value is BaseUserListResponse {
let isInstance = true;
    isInstance = isInstance && "users" in value;

return isInstance;
}

export function BaseUserListResponseFromJSON(json: any): BaseUserListResponse {
return BaseUserListResponseFromJSONTyped(json, false);
}

export function BaseUserListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'users': ((json['users'] as Array<any>).map(BaseUserResponseFromJSON)),
    };
  }

  export function BaseUserListResponseToJSON(value?: BaseUserListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'users': ((value.users as Array<any>).map(BaseUserResponseToJSON)),
    };
  }

  export function BaseUserListResponseCreateEmpty(): BaseUserListResponse {
  return {
      'users': [],
  };
  }

