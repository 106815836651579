/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsClosureTypeResponse } from './EventsClosureTypeResponse';
    import {
    EventsClosureTypeResponseFromJSON,
    EventsClosureTypeResponseFromJSONTyped,
    EventsClosureTypeResponseToJSON,
    EventsClosureTypeResponseCreateEmpty,
    } from './EventsClosureTypeResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsClosureTypeSummaryResponse {
  closureType: EventsClosureTypeResponse;
  closureCount: number;
}

export const EventsClosureTypeSummaryResponseSchema = {
  'closureType': {
    name: 'closureType',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'closureCount': {
    name: 'closureCount',
    required: true,
    nullable: false,
    label: 'Lezáratások száma',
    type: 'number',
  },
}
export function instanceOfEventsClosureTypeSummaryResponse(value: object): value is EventsClosureTypeSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "closureType" in value;
    isInstance = isInstance && "closureCount" in value;

return isInstance;
}

export function EventsClosureTypeSummaryResponseFromJSON(json: any): EventsClosureTypeSummaryResponse {
return EventsClosureTypeSummaryResponseFromJSONTyped(json, false);
}

export function EventsClosureTypeSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsClosureTypeSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'closureType': EventsClosureTypeResponseFromJSON(json['closureType']),
          'closureCount': json['closureCount'],
    };
  }

  export function EventsClosureTypeSummaryResponseToJSON(value?: EventsClosureTypeSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'closureType': EventsClosureTypeResponseToJSON(value.closureType),
                'closureCount': value.closureCount,
    };
  }

  export function EventsClosureTypeSummaryResponseCreateEmpty(): EventsClosureTypeSummaryResponse {
  return {
          'closureType': EventsClosureTypeResponseCreateEmpty(),
      'closureCount': 0,
  };
  }

