/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryQuality } from './InventoryQuality';
    import {
    InventoryQualityFromJSON,
    InventoryQualityFromJSONTyped,
    InventoryQualityToJSON,
    InventoryQualityCreateEmpty,
    } from './InventoryQuality';
import {DateTime} from '@Utils/DateTime';
import { InventoryQualityValues } from './InventoryQuality';

export interface InventoryUpdateCheck {
  success: boolean;
  quality: InventoryQuality | null;
}

export const InventoryUpdateCheckSchema = {
  'success': {
    name: 'success',
    required: true,
    nullable: false,
    label: 'Ellenőrzés sikeres?',
    type: 'boolean',
  },
  'quality': {
    name: 'quality',
    required: true,
    nullable: true,
      type: 'embedded',
        label: "Állapot",
        enumValues: InventoryQualityValues,
  },
}
export function instanceOfInventoryUpdateCheck(value: object): value is InventoryUpdateCheck {
let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "quality" in value;

return isInstance;
}

export function InventoryUpdateCheckFromJSON(json: any): InventoryUpdateCheck {
return InventoryUpdateCheckFromJSONTyped(json, false);
}

export function InventoryUpdateCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateCheck {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'success': json['success'],
              'quality': InventoryQualityFromJSON(json['quality']),
    };
  }

  export function InventoryUpdateCheckToJSON(value?: InventoryUpdateCheck | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'success': value.success,
                'quality': InventoryQualityToJSON(value.quality),
    };
  }

  export function InventoryUpdateCheckCreateEmpty(): InventoryUpdateCheck {
  return {
      'success': false,
          'quality': InventoryQualityCreateEmpty(),
  };
  }

