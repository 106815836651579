/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseExtendedUserResponse } from './BaseExtendedUserResponse';
    import {
    BaseExtendedUserResponseFromJSON,
    BaseExtendedUserResponseFromJSONTyped,
    BaseExtendedUserResponseToJSON,
    BaseExtendedUserResponseCreateEmpty,
    } from './BaseExtendedUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseAuthResponse {
  user: BaseExtendedUserResponse | null;
  impersonating: boolean;
}

export const BaseAuthResponseSchema = {
  'user': {
    name: 'user',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'impersonating': {
    name: 'impersonating',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfBaseAuthResponse(value: object): value is BaseAuthResponse {
let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "impersonating" in value;

return isInstance;
}

export function BaseAuthResponseFromJSON(json: any): BaseAuthResponse {
return BaseAuthResponseFromJSONTyped(json, false);
}

export function BaseAuthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAuthResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'user': BaseExtendedUserResponseFromJSON(json['user']),
          'impersonating': json['impersonating'],
    };
  }

  export function BaseAuthResponseToJSON(value?: BaseAuthResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'user': BaseExtendedUserResponseToJSON(value.user),
                'impersonating': value.impersonating,
    };
  }

  export function BaseAuthResponseCreateEmpty(): BaseAuthResponse {
  return {
          'user': BaseExtendedUserResponseCreateEmpty(),
      'impersonating': false,
  };
  }

