<template>
    <PegasusModal :header="canEdit ? 'Tételcsoport szerkesztése' : 'Tételcsoport megtekintése'">
        <PegasusForm ref="form">
            <TextField
                v-model="name"
                :disabled="!canEdit"
                :schema="MoneyItemGroupUpdateSchema.name"
            ></TextField>

            <TextareaField
                v-model="description"
                :disabled="!canEdit"
                :schema="MoneyItemGroupUpdateSchema.description"
            ></TextareaField>

            <PegasusButton v-if="canEdit" variant="success" @click="saving()">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {ItemGroup} from "@Models/money/ItemGroup";
import {ref} from "vue";
import toast from "@Utils/toast";
import TextField from "@Components/base/form/types/TextField.vue";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {useReturn} from '@Utils/dialog';
import {MoneyItemGroupUpdateSchema} from '@/api/models';

const form = ref<FormRef>(null);

const {itemGroup, canEdit} = defineProps<{
    itemGroup: ItemGroup
    canEdit?: boolean
}>();

const returnValue = useReturn();

const name = ref(itemGroup.name);
const description = ref(itemGroup.description);

function saving() {
    let request = itemGroup.update(name.value, description.value, form.value).then(() => {
        returnValue(null);
    });

    toast.loading(request);
}
</script>
