/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { RvtClaimResponse } from './RvtClaimResponse';
    import {
    RvtClaimResponseFromJSON,
    RvtClaimResponseFromJSONTyped,
    RvtClaimResponseToJSON,
    RvtClaimResponseCreateEmpty,
    } from './RvtClaimResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtClaimsResponse {
  claims: Array<RvtClaimResponse>;
}

export const RvtClaimsResponseSchema = {
  'claims': {
    name: 'claims',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfRvtClaimsResponse(value: object): value is RvtClaimsResponse {
let isInstance = true;
    isInstance = isInstance && "claims" in value;

return isInstance;
}

export function RvtClaimsResponseFromJSON(json: any): RvtClaimsResponse {
return RvtClaimsResponseFromJSONTyped(json, false);
}

export function RvtClaimsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtClaimsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'claims': ((json['claims'] as Array<any>).map(RvtClaimResponseFromJSON)),
    };
  }

  export function RvtClaimsResponseToJSON(value?: RvtClaimsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'claims': ((value.claims as Array<any>).map(RvtClaimResponseToJSON)),
    };
  }

  export function RvtClaimsResponseCreateEmpty(): RvtClaimsResponse {
  return {
      'claims': [],
  };
  }

