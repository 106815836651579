/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsCheckResponse } from './EventsCheckResponse';
    import {
    EventsCheckResponseFromJSON,
    EventsCheckResponseFromJSONTyped,
    EventsCheckResponseToJSON,
    EventsCheckResponseCreateEmpty,
    } from './EventsCheckResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsCheckListResponse {
  fatal: Array<EventsCheckResponse>;
  warning: Array<EventsCheckResponse>;
  info: Array<EventsCheckResponse>;
  todo: Array<EventsCheckResponse>;
}

export const EventsCheckListResponseSchema = {
  'fatal': {
    name: 'fatal',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'warning': {
    name: 'warning',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'info': {
    name: 'info',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'todo': {
    name: 'todo',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsCheckListResponse(value: object): value is EventsCheckListResponse {
let isInstance = true;
    isInstance = isInstance && "fatal" in value;
    isInstance = isInstance && "warning" in value;
    isInstance = isInstance && "info" in value;
    isInstance = isInstance && "todo" in value;

return isInstance;
}

export function EventsCheckListResponseFromJSON(json: any): EventsCheckListResponse {
return EventsCheckListResponseFromJSONTyped(json, false);
}

export function EventsCheckListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsCheckListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'fatal': ((json['fatal'] as Array<any>).map(EventsCheckResponseFromJSON)),
            'warning': ((json['warning'] as Array<any>).map(EventsCheckResponseFromJSON)),
            'info': ((json['info'] as Array<any>).map(EventsCheckResponseFromJSON)),
            'todo': ((json['todo'] as Array<any>).map(EventsCheckResponseFromJSON)),
    };
  }

  export function EventsCheckListResponseToJSON(value?: EventsCheckListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'fatal': ((value.fatal as Array<any>).map(EventsCheckResponseToJSON)),
              'warning': ((value.warning as Array<any>).map(EventsCheckResponseToJSON)),
              'info': ((value.info as Array<any>).map(EventsCheckResponseToJSON)),
              'todo': ((value.todo as Array<any>).map(EventsCheckResponseToJSON)),
    };
  }

  export function EventsCheckListResponseCreateEmpty(): EventsCheckListResponse {
  return {
      'fatal': [],
      'warning': [],
      'info': [],
      'todo': [],
  };
  }

