/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsAcSource } from './EventsAcSource';
    import {
    EventsAcSourceFromJSON,
    EventsAcSourceFromJSONTyped,
    EventsAcSourceToJSON,
    EventsAcSourceCreateEmpty,
    } from './EventsAcSource';
import {DateTime} from '@Utils/DateTime';
import { EventsAcSourceValues } from './EventsAcSource';

export interface EventsAcData {
  robot: boolean;
  tv: boolean;
  projector: boolean;
  mobileTV: boolean;
  source: EventsAcSource;
  mic: number;
  micWireless: number;
}

export const EventsAcDataSchema = {
  'robot': {
    name: 'robot',
    required: true,
    nullable: false,
    label: 'Fénytechnika',
    type: 'boolean',
  },
  'tv': {
    name: 'tv',
    required: true,
    nullable: false,
    label: 'TV',
    type: 'boolean',
  },
  'projector': {
    name: 'projector',
    required: true,
    nullable: false,
    label: 'Projektor',
    type: 'boolean',
  },
  'mobileTV': {
    name: 'mobileTV',
    required: true,
    nullable: false,
    label: 'Mobil TV',
    type: 'boolean',
  },
  'source': {
    name: 'source',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Hang forrása",
        enumValues: EventsAcSourceValues,
  },
  'mic': {
    name: 'mic',
    required: true,
    nullable: false,
    label: 'Vezetékes mikrofon',
    type: 'number',
      minimum: 0,
      maximum: 10,
  },
  'micWireless': {
    name: 'micWireless',
    required: true,
    nullable: false,
    label: 'Vezeték nélküli mikrofon',
    type: 'number',
      minimum: 0,
      maximum: 10,
  },
}
export function instanceOfEventsAcData(value: object): value is EventsAcData {
let isInstance = true;
    isInstance = isInstance && "robot" in value;
    isInstance = isInstance && "tv" in value;
    isInstance = isInstance && "projector" in value;
    isInstance = isInstance && "mobileTV" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "mic" in value;
    isInstance = isInstance && "micWireless" in value;

return isInstance;
}

export function EventsAcDataFromJSON(json: any): EventsAcData {
return EventsAcDataFromJSONTyped(json, false);
}

export function EventsAcDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsAcData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'robot': json['robot'],
          'tv': json['tv'],
          'projector': json['projector'],
          'mobileTV': json['mobileTV'],
              'source': EventsAcSourceFromJSON(json['source']),
          'mic': json['mic'],
          'micWireless': json['micWireless'],
    };
  }

  export function EventsAcDataToJSON(value?: EventsAcData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'robot': value.robot,
                'tv': value.tv,
                'projector': value.projector,
                'mobileTV': value.mobileTV,
                'source': EventsAcSourceToJSON(value.source),
                'mic': value.mic,
                'micWireless': value.micWireless,
    };
  }

  export function EventsAcDataCreateEmpty(): EventsAcData {
  return {
      'robot': false,
      'tv': false,
      'projector': false,
      'mobileTV': false,
          'source': EventsAcSourceCreateEmpty(),
      'mic': 0,
      'micWireless': 0,
  };
  }

