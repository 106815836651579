<template>
    <PegasusModal header="Moderátor hozzáadása">
        <PegasusForm ref="form">
            <UserSelectorField
                v-model="user"
                :schema="VikoTenderModeratorSchema.user"
            ></UserSelectorField>

            <PegasusButton variant="success" @click="save">
                Hozzáadás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {VikoTenderModeratorSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import UserSelectorField from '@Components/base/form/types/custom/UserSelectorField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import {useReturn} from '@Utils/dialog';
import {User} from '@Models/base/User';
import toast from '@Utils/toast';
import {Tender} from '@Models/viko/Tender';

const {tender} = defineProps<{
    tender: Tender
}>();

const form = ref<FormRef>(null);

const user = ref<User | null>(null);

const returnValue = useReturn();

function save() {
    if (user.value === null) {
        toast.error('Felhasználó kiválasztása kötelező');
        throw new Error('User must be set');
    }

    const request = tender.addModerator(user.value.id).then(() => {
        returnValue(true);
    });

    toast.loading(request, 'Hozzáadás');
}
</script>
