<template>
    <PegasusHeading>{{ group.name }} - {{ tag.name }}</PegasusHeading>

    <PegasusTable :data="items" :per-page="10" search>
        <Column field="idAsText" header="Szám"></Column>

        <Column field="name" header="Név"></Column>

        <Column field="count" header="Darabszám"></Column>

        <Column header="Műveletek">
            <template #body="{data}">
                <PegasusButton :params="{code: data.idAsText}" to="inventory.items.default" variant="primary">
                    Megnyitás
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>

    <PegasusButton :params="{group}" to="inventory.groups.index" variant="primary">
        Vissza a kör összefoglalóra
    </PegasusButton>
</template>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {Group} from '@Models/base/Group';
import {useAuthStore} from '@/stores/auth';
import {ref} from 'vue';
import {Item} from '@Models/inventory/Item';
import {Tag} from '@Models/inventory/Tag';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import Column from 'primevue/column';

const {groupId, tagId} = defineProps<{
    groupId: number,
    tagId: string
}>();

useAuthStore().requireUser();

const group = Group.getBySingleId(groupId);

const tag = Tag.getBySingleIdentifier(tagId);

const items = ref(await Item.getByGroupTag(groupId, tag.id));
</script>
