/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface KprApplicationUpdate {
  number: number;
  statement: string;
}

export const KprApplicationUpdateSchema = {
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Körben lévő aktív tagok száma',
    type: 'number',
      minimum: 1,
      maximum: 1000,
  },
  'statement': {
    name: 'statement',
    required: true,
    nullable: false,
    label: 'Kör előző éves beszámolója',
    type: 'string',
      maxLength: 5000,
  },
}
export function instanceOfKprApplicationUpdate(value: object): value is KprApplicationUpdate {
let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "statement" in value;

return isInstance;
}

export function KprApplicationUpdateFromJSON(json: any): KprApplicationUpdate {
return KprApplicationUpdateFromJSONTyped(json, false);
}

export function KprApplicationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprApplicationUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'number': json['number'],
          'statement': json['statement'],
    };
  }

  export function KprApplicationUpdateToJSON(value?: KprApplicationUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'number': value.number,
                'statement': value.statement,
    };
  }

  export function KprApplicationUpdateCreateEmpty(): KprApplicationUpdate {
  return {
      'number': 0,
      'statement': '',
  };
  }

