import {Base} from "@Models/Base";
import {Group} from "@Models/base/Group";
import {type BaseGroupPermissionResponse, BaseGroupSpecificPermission} from "@/api/api";

export class GroupPermission extends Base {
    id: BaseGroupSpecificPermission;

    name: string;

    description: string;

    canBeAssigned: boolean;

    static parseResponse(permission: GroupPermission, data: BaseGroupPermissionResponse): GroupPermission {
        permission.id = data.id;
        permission.name = data.name;
        permission.description = data.description;
        permission.canBeAssigned = data.canBeAssigned;

        return permission;
    }

    public isValidForGroup(group: Group): boolean {
        switch (this.id) {
            case BaseGroupSpecificPermission.MANAGE_INVENTORY:
            case BaseGroupSpecificPermission.READ_INVENTORY:
                return group.inventoryEnabled;
            case BaseGroupSpecificPermission.MANAGE_EVENTS:
            case BaseGroupSpecificPermission.READ_EVENTS:
                return group.eventEnabled;
            case BaseGroupSpecificPermission.MANAGE_SERVICES:
            case BaseGroupSpecificPermission.READ_SERVICES:
                return group.getEventService() !== null;
            case BaseGroupSpecificPermission.MANAGE_MONEY:
            case BaseGroupSpecificPermission.READ_MONEY:
                return group.kprEnabled;
            case BaseGroupSpecificPermission.MANAGE_VIKO:
            case BaseGroupSpecificPermission.READ_VIKO:
                return group.vikoEnabled;
            case BaseGroupSpecificPermission.APPROVE_REQUESTS:
                return group.isResort();
            case BaseGroupSpecificPermission.MANAGE_STATEMENT:
                return true;
        }

        throw new Error('Invalid ID: ' + this.id);
    }

    public canBeInherited(): boolean {
        switch (this.id) {
            case BaseGroupSpecificPermission.MANAGE_INVENTORY:
            case BaseGroupSpecificPermission.READ_INVENTORY:
            case BaseGroupSpecificPermission.MANAGE_EVENTS:
            case BaseGroupSpecificPermission.READ_EVENTS:
            case BaseGroupSpecificPermission.MANAGE_MONEY:
            case BaseGroupSpecificPermission.READ_MONEY:
            case BaseGroupSpecificPermission.MANAGE_SERVICES:
            case BaseGroupSpecificPermission.READ_SERVICES:
                return true;
            case BaseGroupSpecificPermission.MANAGE_VIKO:
            case BaseGroupSpecificPermission.READ_VIKO:
            case BaseGroupSpecificPermission.APPROVE_REQUESTS:
            case BaseGroupSpecificPermission.MANAGE_STATEMENT:
                return false;
        }

        throw new Error('Invalid ID: ' + this.id);
    }
}
