/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
    import type { VikoReviewResponse } from './VikoReviewResponse';
    import {
    VikoReviewResponseFromJSON,
    VikoReviewResponseFromJSONTyped,
    VikoReviewResponseToJSON,
    VikoReviewResponseCreateEmpty,
    } from './VikoReviewResponse';
    import type { VikoTenderResponse } from './VikoTenderResponse';
    import {
    VikoTenderResponseFromJSON,
    VikoTenderResponseFromJSONTyped,
    VikoTenderResponseToJSON,
    VikoTenderResponseCreateEmpty,
    } from './VikoTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoApplicationWithReviewsResponse {
  reviews: Array<VikoReviewResponse>;
  done: boolean;
  groupDones: Array<number>;
  id: number;
  tender: VikoTenderResponse;
  user: BaseUserResponse;
  reportCount: number;
  result: string | null;
  canEdit: boolean;
}

export const VikoApplicationWithReviewsResponseSchema = {
  'reviews': {
    name: 'reviews',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'done': {
    name: 'done',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'groupDones': {
    name: 'groupDones',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'reportCount': {
    name: 'reportCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'result': {
    name: 'result',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfVikoApplicationWithReviewsResponse(value: object): value is VikoApplicationWithReviewsResponse {
let isInstance = true;
    isInstance = isInstance && "reviews" in value;
    isInstance = isInstance && "done" in value;
    isInstance = isInstance && "groupDones" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "reportCount" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "canEdit" in value;

return isInstance;
}

export function VikoApplicationWithReviewsResponseFromJSON(json: any): VikoApplicationWithReviewsResponse {
return VikoApplicationWithReviewsResponseFromJSONTyped(json, false);
}

export function VikoApplicationWithReviewsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoApplicationWithReviewsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'reviews': ((json['reviews'] as Array<any>).map(VikoReviewResponseFromJSON)),
          'done': json['done'],
          'groupDones': json['groupDones'],
          'id': json['id'],
              'tender': VikoTenderResponseFromJSON(json['tender']),
              'user': BaseUserResponseFromJSON(json['user']),
          'reportCount': json['reportCount'],
          'result': json['result'],
          'canEdit': json['canEdit'],
    };
  }

  export function VikoApplicationWithReviewsResponseToJSON(value?: VikoApplicationWithReviewsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'reviews': ((value.reviews as Array<any>).map(VikoReviewResponseToJSON)),
                'done': value.done,
            'groupDones': value.groupDones,
                'id': value.id,
                'tender': VikoTenderResponseToJSON(value.tender),
                'user': BaseUserResponseToJSON(value.user),
                'reportCount': value.reportCount,
                'result': value.result,
                'canEdit': value.canEdit,
    };
  }

  export function VikoApplicationWithReviewsResponseCreateEmpty(): VikoApplicationWithReviewsResponse {
  return {
      'reviews': [],
      'done': false,
      'groupDones': [],
      'id': 0,
          'tender': VikoTenderResponseCreateEmpty(),
          'user': BaseUserResponseCreateEmpty(),
      'reportCount': 0,
      'result': '',
      'canEdit': false,
  };
  }

