<template>
    <h4 class="p-2">Körös rendezvény archívum - {{ group.name }}</h4>

    <PegasusPanel header="Körös rendezvények">
        <PegasusTable :data="events" empty-text="Az évben nem volt egy rendezvény sem" search>
            <Column field="name" header="Név"></Column>

            <Column field="date" header="Dátum"></Column>

            <Column field="responsible.name" header="Főrendező"></Column>

            <Column field="status" header="Státusz"></Column>

            <Column field="status" header="Műveletek">
                <template #body="{data}">
                    <PegasusButton variant="primary" @click="Dialog.open(ShortEventDialog, {eventData: data})">
                        <i class="fa fa-eye"></i> Összefoglaló
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>Rendezvény adminisztráció</title>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import {Group} from "@Models/base/Group";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import {Dialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';

const {groupId, year} = defineProps<{
    groupId: number,
    year: number
}>();

useAuthStore().requireUser();

const group = Group.getBySingleId(groupId);

const events = await Event.getArchiveByGroup(groupId, year);
</script>
