/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsReservationResponse {
  id: number;
  name: string;
  start: DateTime;
  finish: DateTime;
  locations: Array<number>;
  priority: boolean;
  groupId: number;
  responsible: BaseUserResponse;
}

export const EventsReservationResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'start': {
    name: 'start',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'finish': {
    name: 'finish',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'locations': {
    name: 'locations',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'priority': {
    name: 'priority',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfEventsReservationResponse(value: object): value is EventsReservationResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "finish" in value;
    isInstance = isInstance && "locations" in value;
    isInstance = isInstance && "priority" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "responsible" in value;

return isInstance;
}

export function EventsReservationResponseFromJSON(json: any): EventsReservationResponse {
return EventsReservationResponseFromJSONTyped(json, false);
}

export function EventsReservationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsReservationResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
        'start': (DateTime.fromISO(json['start'])),
        'finish': (DateTime.fromISO(json['finish'])),
          'locations': json['locations'],
          'priority': json['priority'],
          'groupId': json['groupId'],
              'responsible': BaseUserResponseFromJSON(json['responsible']),
    };
  }

  export function EventsReservationResponseToJSON(value?: EventsReservationResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
            'start': (value.start.toISO()),
            'finish': (value.finish.toISO()),
            'locations': value.locations,
                'priority': value.priority,
                'groupId': value.groupId,
                'responsible': BaseUserResponseToJSON(value.responsible),
    };
  }

  export function EventsReservationResponseCreateEmpty(): EventsReservationResponse {
  return {
      'id': 0,
      'name': '',
      'start': DateTime.now(),
      'finish': DateTime.now(),
      'locations': [],
      'priority': false,
      'groupId': 0,
          'responsible': BaseUserResponseCreateEmpty(),
  };
  }

