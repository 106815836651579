<template>
    <table class="table table-hover table-bordered">
        <thead>
            <tr>
                <th>Kör / Szolgáltatás</th>
                <th>Állapot</th>
            </tr>
        </thead>

        <tbody>
            <template v-for="(services, id) in categorizedServices()" :key="id">
                <tr class="table-sm table-borderless">
                    <td class="text-center text-secondary" colspan="2">{{ EventsServiceCategoryValues[id] }}</td>
                </tr>

                <tr v-for="service in services" :key="service.id" class="align-middle">
                    <td>
                        <a href="javascript:void(0)" @click="open(service)">
                            {{ service.type.name }}
                        </a>
                    </td>
                    <td>{{ service.status }}</td>
                </tr>
            </template>
        </tbody>
    </table>

    <PegasusButton v-if="event.canServices" variant="success" @click="newModal">
        Új hozzáadása
    </PegasusButton>

    <hr>

    <h5>Tudnivalók</h5>
    <p>
        <i class="fa fa-warning text-red"></i> A körös felkérések csak Elfogadva státuszba kerülve jelentik azt,
        hogy a kör meg tud jelenni a rendezvényen.
    </p>
    <p>A fenti szolgáltatások/felkérések csak akkor érvényesek amennyiben a
        <PegasusButton size="sm" title="Véglegesítés" variant="success">
            <i class="fa fa-paper-plane"></i>
        </PegasusButton>
        gombbal véglegesítve lettek.
        Ennek elmaradása esetén olyan mintha meg se történt volna az igény/felkérés.
    </p>
    <p>A rendezvény bejelentése után is még van lehetőség a módosításukra.</p>
    <p>Határidő utáni véglegesítés esetén nem garantált a szolgáltatás teljesítése,
        vagy a körös felkérés megvalósíthatósága.</p>
</template>

<script lang="ts" setup>
import {Service} from "@Models/events/Service";
import Event from "@Models/events/Event";
import NewServiceModal from "@Components/events/services/NewServiceModal.vue";
import type {EventsServiceCategory, EventsServiceType} from "@/api/models";
import {EventsServiceCategoryValues} from "@/api/models";
import {groupBy} from "@Models/Utils";
import PegasusButton from "@Components/base/PegasusButton.vue";
import ServiceModal from '@Components/events/services/ServiceModal.vue';
import type {DateTime} from '@Utils/DateTime';
import {Dialog} from '@Utils/dialog';

const {event, deadlines} = defineProps<{
    event: Event,
    deadlines: Record<EventsServiceType, DateTime>
}>();

const emit = defineEmits<{
    check: []
}>();

function categorizedServices(): Record<EventsServiceCategory, Service[]> {
    return groupBy(event.services, service => service.type.category);
}

function newModal() {
    Dialog.open(NewServiceModal, {
        event,
        deadlines,
        onCreate: createService
    });
}

function createService(type: EventsServiceType) {
    if (event.services[type]) {
        return;
    }

    open(Service.createEmptyForType(type, event));
}

function open(service: Service) {
    Dialog.open(ServiceModal, {
        service: service,
        event: event,
        deadlines: deadlines,
        onCheck: () => emit('check')
    });
}
</script>
