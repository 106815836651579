import type {RouteRecordRaw} from "vue-router";

const central: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'central.index',
        component: () => import('@Pages/central/Index.vue'),
    },
];

export default central;
