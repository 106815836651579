/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyInventoryType } from './MoneyInventoryType';
    import {
    MoneyInventoryTypeFromJSON,
    MoneyInventoryTypeFromJSONTyped,
    MoneyInventoryTypeToJSON,
    MoneyInventoryTypeCreateEmpty,
    } from './MoneyInventoryType';
    import type { MoneyPriority } from './MoneyPriority';
    import {
    MoneyPriorityFromJSON,
    MoneyPriorityFromJSONTyped,
    MoneyPriorityToJSON,
    MoneyPriorityCreateEmpty,
    } from './MoneyPriority';
import {DateTime} from '@Utils/DateTime';
import { MoneyInventoryTypeValues } from './MoneyInventoryType';

export interface MoneyItemStore {
  itemGroup: number;
  name: string;
  description: string;
  price: number;
  contribution: number | null;
  inventoryType: MoneyInventoryType;
  comment: string | null;
  priority: MoneyPriority | null;
  campNumber: number | null;
  flags: Array<number>;
}

export const MoneyItemStoreSchema = {
  'itemGroup': {
    name: 'itemGroup',
    required: true,
    nullable: false,
    label: 'KPR tételcsoport',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
      labelHelp: `Egy tömör, rövid, de önmagában is értelmes neve a tételnek
Jó példa: Tisztasági verseny díj, Atmega2560 mikrokontroller 3db, Tavaszi körvacsora
Rossz példa: Ősz, Új tétel, 3.`,
    type: 'string',
      maxLength: 250,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
      labelHelp: `Részletes leírása a tétel céljának, használati módjának, a rendezvény leírásának, stb.`,
    type: 'string',
      maxLength: 5000,
  },
  'price': {
    name: 'price',
    required: true,
    nullable: false,
    label: 'Támogatás összege',
      labelHelp: `1000-re kerekített számot adj meg! (Kiírás 3.4.)
A tétel áránál mindig a listaárat (nem pedig kedvezményes árat) kell feltüntetni. (Kiírás 8.1.1.)
Mindig az ÁFA-t tartalmazó bruttó árat kell megadni, erre fokozottan figyelj!`,
    type: 'number',
      maximum: 10000000,
      multipleOf: 1000,
  },
  'contribution': {
    name: 'contribution',
    required: true,
    nullable: true,
    label: 'Önrész összege',
      labelHelp: `A teljes bekerülési költségből ki kell vonni a Kiírás 1. függelék alapján kiszámított támogatás mértékét (Kiírás 3.3)`,
    type: 'number',
      maximum: 10000000,
      multipleOf: 100,
  },
  'inventoryType': {
    name: 'inventoryType',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: MoneyInventoryTypeValues,
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: true,
    label: 'Megjegyzés reszortvezető részére',
      labelHelp: `Amit a piros kereten belülre írsz csak a saját reszortvezetőd és reszortgazdaságisod látja`,
    type: 'string',
      maxLength: 3000,
  },
  'priority': {
    name: 'priority',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'campNumber': {
    name: 'campNumber',
    required: true,
    nullable: true,
    label: 'Táborban résztvevők száma',
      labelHelp: `Meg kell adni, hogy hányan fognak részt venni a táborban`,
    type: 'number',
      minimum: 0,
      maximum: 1000,
  },
  'flags': {
    name: 'flags',
    required: true,
    nullable: false,
    label: 'Cimkék',
    type: 'array',
  },
}
export function instanceOfMoneyItemStore(value: object): value is MoneyItemStore {
let isInstance = true;
    isInstance = isInstance && "itemGroup" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "contribution" in value;
    isInstance = isInstance && "inventoryType" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "priority" in value;
    isInstance = isInstance && "campNumber" in value;
    isInstance = isInstance && "flags" in value;

return isInstance;
}

export function MoneyItemStoreFromJSON(json: any): MoneyItemStore {
return MoneyItemStoreFromJSONTyped(json, false);
}

export function MoneyItemStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'itemGroup': json['itemGroup'],
          'name': json['name'],
          'description': json['description'],
          'price': json['price'],
          'contribution': json['contribution'],
              'inventoryType': MoneyInventoryTypeFromJSON(json['inventoryType']),
          'comment': json['comment'],
              'priority': MoneyPriorityFromJSON(json['priority']),
          'campNumber': json['campNumber'],
          'flags': json['flags'],
    };
  }

  export function MoneyItemStoreToJSON(value?: MoneyItemStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'itemGroup': value.itemGroup,
                'name': value.name,
                'description': value.description,
                'price': value.price,
                'contribution': value.contribution,
                'inventoryType': MoneyInventoryTypeToJSON(value.inventoryType),
                'comment': value.comment,
                'priority': MoneyPriorityToJSON(value.priority),
                'campNumber': value.campNumber,
            'flags': value.flags,
    };
  }

  export function MoneyItemStoreCreateEmpty(): MoneyItemStore {
  return {
      'itemGroup': 0,
      'name': '',
      'description': '',
      'price': 0,
      'contribution': 0,
          'inventoryType': MoneyInventoryTypeCreateEmpty(),
      'comment': '',
          'priority': MoneyPriorityCreateEmpty(),
      'campNumber': 0,
      'flags': [],
  };
  }

