/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsLocationUpdate {
  enabled: boolean;
}

export const EventsLocationUpdateSchema = {
  'enabled': {
    name: 'enabled',
    required: true,
    nullable: false,
    label: 'Engedélyezve',
    type: 'boolean',
  },
}
export function instanceOfEventsLocationUpdate(value: object): value is EventsLocationUpdate {
let isInstance = true;
    isInstance = isInstance && "enabled" in value;

return isInstance;
}

export function EventsLocationUpdateFromJSON(json: any): EventsLocationUpdate {
return EventsLocationUpdateFromJSONTyped(json, false);
}

export function EventsLocationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsLocationUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'enabled': json['enabled'],
    };
  }

  export function EventsLocationUpdateToJSON(value?: EventsLocationUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'enabled': value.enabled,
    };
  }

  export function EventsLocationUpdateCreateEmpty(): EventsLocationUpdate {
  return {
      'enabled': false,
  };
  }

