/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoExtendedTenderResponse } from './VikoExtendedTenderResponse';
    import {
    VikoExtendedTenderResponseFromJSON,
    VikoExtendedTenderResponseFromJSONTyped,
    VikoExtendedTenderResponseToJSON,
    VikoExtendedTenderResponseCreateEmpty,
    } from './VikoExtendedTenderResponse';
    import type { VikoPointResponse } from './VikoPointResponse';
    import {
    VikoPointResponseFromJSON,
    VikoPointResponseFromJSONTyped,
    VikoPointResponseToJSON,
    VikoPointResponseCreateEmpty,
    } from './VikoPointResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoCalcResponse {
  tender: VikoExtendedTenderResponse;
  points: Array<VikoPointResponse>;
  canEdit: boolean;
}

export const VikoCalcResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'points': {
    name: 'points',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfVikoCalcResponse(value: object): value is VikoCalcResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "points" in value;
    isInstance = isInstance && "canEdit" in value;

return isInstance;
}

export function VikoCalcResponseFromJSON(json: any): VikoCalcResponse {
return VikoCalcResponseFromJSONTyped(json, false);
}

export function VikoCalcResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoCalcResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': VikoExtendedTenderResponseFromJSON(json['tender']),
            'points': ((json['points'] as Array<any>).map(VikoPointResponseFromJSON)),
          'canEdit': json['canEdit'],
    };
  }

  export function VikoCalcResponseToJSON(value?: VikoCalcResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': VikoExtendedTenderResponseToJSON(value.tender),
              'points': ((value.points as Array<any>).map(VikoPointResponseToJSON)),
                'canEdit': value.canEdit,
    };
  }

  export function VikoCalcResponseCreateEmpty(): VikoCalcResponse {
  return {
          'tender': VikoExtendedTenderResponseCreateEmpty(),
      'points': [],
      'canEdit': false,
  };
  }

