<template>
    <span :class="classObject">
        <slot></slot>
    </span>
</template>

<script lang="ts" setup>
import type {Variants} from "@/vue";
import {computed} from "vue";

const {variant = 'primary'} = defineProps<{
    variant?: Variants
}>();

const classObject = computed(() => {
    return 'mx-1 badge bg-' + variant;
});
</script>
