import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import type {InventoryUpdateDataChange, InventoryUpdateResponse} from "@/api/api";
import {InventoryUpdateType} from "@/api/api";

export class DataChange extends ItemUpdate {
    type = InventoryUpdateType.DATA_CHANGE;

    changes: Record<string, string>;

    static parseResponse(dataChange: DataChange, data: InventoryUpdateResponse): DataChange {
        ItemUpdate.parseResponse(dataChange, data);

        const specific = data.data as InventoryUpdateDataChange;
        dataChange.changes = specific.changes;

        return dataChange;
    }
}
