/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetProgramUpdate {
  name: string;
  description: string;
}

export const MoneyBudgetProgramUpdateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 5,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
      minLength: 0,
      maxLength: 3000,
  },
}
export function instanceOfMoneyBudgetProgramUpdate(value: object): value is MoneyBudgetProgramUpdate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function MoneyBudgetProgramUpdateFromJSON(json: any): MoneyBudgetProgramUpdate {
return MoneyBudgetProgramUpdateFromJSONTyped(json, false);
}

export function MoneyBudgetProgramUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
    };
  }

  export function MoneyBudgetProgramUpdateToJSON(value?: MoneyBudgetProgramUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
    };
  }

  export function MoneyBudgetProgramUpdateCreateEmpty(): MoneyBudgetProgramUpdate {
  return {
      'name': '',
      'description': '',
  };
  }

