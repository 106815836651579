<template>
    <PegasusModal :header="groupPost.name + ' poszt jogosultságai'">
        Jelenlegi jogosultságok
        <table class="table">
            <tr>
                <th>Név</th>
                <th v-if="group.isResort()">Öröklődés</th>
                <th>Műveletek</th>
            </tr>
            <tr v-for="groupPermission in groupPost.getAssignablePermissions()" :key="groupPermission.id">
                <td>
                    {{ groupPermission.permissionName }}
                    <PegasusHelp :title="groupPermission.permissionName">
                        {{ groupPermission.permissionDescription }}
                    </PegasusHelp>
                </td>

                <td v-if="group.isResort()">
                    <PegasusButton
                        v-if="group.isResort() && groupPermission.permissionObject.canBeInherited()"
                        :variant="groupPermission.inheriting ? 'warning' : 'primary'"
                        size="xs"
                        @click="toggleInherit(groupPermission)"
                    >
                        <i class=""></i>
                        {{ groupPermission.inheriting ? 'Öröklődés tiltása' : 'Öröklődés engedélyezése' }}
                    </PegasusButton>
                </td>

                <td>
                    <PegasusButton v-if="groupPost.name !== 'körvezető'" size="xs" variant="danger" @click="remove(groupPost, groupPermission)">
                        <i class="fa fa-trash"></i> Eltávolítás
                    </PegasusButton>
                </td>
            </tr>
        </table>

        <table class="table">
            <thead>
                <tr>
                    <th>Név</th>
                    <th>Műveletek</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="permission in assignablePermissionsToPost" :key="permission.name">
                    <td>
                        {{ permission.name }}
                        <PegasusHelp :title="permission.name">
                            {{ permission.description }}
                        </PegasusHelp>
                    </td>

                    <td>
                        <PegasusButton size="sm" variant="success" @click="add(groupPost, permission.id)">
                            Hozzáadás
                        </PegasusButton>
                    </td>
                </tr>
            </tbody>
        </table>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {GroupPost} from '@Models/base/GroupPost';
import {computed} from 'vue';
import {filter} from "lodash-es";
import {useConstantsStore} from '@/stores/constants';
import type {Group} from '@Models/base/Group';
import {BaseGroupSpecificPermission} from '@/api/models';
import {AssignedGroupPermission} from '@Models/base/AssignedGroupPermission';
import toast from '@Utils/toast';
import {useAuthStore} from '@/stores/auth';

const {groupPost, group} = defineProps<{
    groupPost: GroupPost,
    group: Group
}>();

const constants = useConstantsStore().constants;
const auth = useAuthStore();

const assignablePermissionsToPost = computed(() => {
    return filter(constants.auth.groupPermissions, permission => {
        return permission.canBeAssigned && permission.isValidForGroup(group) && !groupPost.hasPermission(permission);
    });
});

function add(post: GroupPost, perm: BaseGroupSpecificPermission) {
    let request = AssignedGroupPermission.store(group, post, perm, false).then(assigned => {
        post.groupPermissions[assigned.id] = assigned;

        auth.refresh();
    });

    auth.refresh();

    toast.loading(request, 'Hozzáadás');
}


function toggleInherit(groupPermission: AssignedGroupPermission) {
    groupPermission.inheriting = !groupPermission.inheriting;

    let request = groupPermission.update().then(() => {
        auth.refresh();
    });

    toast.loading(request);
}

function remove(post: GroupPost, groupPermission: AssignedGroupPermission) {
    let request = groupPermission.delete().then(() => {
        delete post.groupPermissions[groupPermission.id];

        auth.refresh();
    });

    toast.loading(request, 'Törlés');
}
</script>
