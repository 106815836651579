/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtGroupStatementResponse {
  id: number;
  groupId: number;
  year: number;
  month: number;
  text: string;
}

export const RvtGroupStatementResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'year': {
    name: 'year',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1962,
      maximum: 2062,
  },
  'month': {
    name: 'month',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
      maximum: 12,
  },
  'text': {
    name: 'text',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
      minLength: 3,
      maxLength: 10000,
  },
}
export function instanceOfRvtGroupStatementResponse(value: object): value is RvtGroupStatementResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "text" in value;

return isInstance;
}

export function RvtGroupStatementResponseFromJSON(json: any): RvtGroupStatementResponse {
return RvtGroupStatementResponseFromJSONTyped(json, false);
}

export function RvtGroupStatementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtGroupStatementResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'groupId': json['groupId'],
          'year': json['year'],
          'month': json['month'],
          'text': json['text'],
    };
  }

  export function RvtGroupStatementResponseToJSON(value?: RvtGroupStatementResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'groupId': value.groupId,
                'year': value.year,
                'month': value.month,
                'text': value.text,
    };
  }

  export function RvtGroupStatementResponseCreateEmpty(): RvtGroupStatementResponse {
  return {
      'id': 0,
      'groupId': 0,
      'year': 0,
      'month': 0,
      'text': '',
  };
  }

