/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsMinimalEventResponse } from './EventsMinimalEventResponse';
    import {
    EventsMinimalEventResponseFromJSON,
    EventsMinimalEventResponseFromJSONTyped,
    EventsMinimalEventResponseToJSON,
    EventsMinimalEventResponseCreateEmpty,
    } from './EventsMinimalEventResponse';
    import type { EventsServiceResponseData } from './EventsServiceResponseData';
    import {
    EventsServiceResponseDataFromJSON,
    EventsServiceResponseDataFromJSONTyped,
    EventsServiceResponseDataToJSON,
    EventsServiceResponseDataCreateEmpty,
    } from './EventsServiceResponseData';
    import type { EventsServiceType } from './EventsServiceType';
    import {
    EventsServiceTypeFromJSON,
    EventsServiceTypeFromJSONTyped,
    EventsServiceTypeToJSON,
    EventsServiceTypeCreateEmpty,
    } from './EventsServiceType';
import {DateTime} from '@Utils/DateTime';
import { EventsServiceTypeValues } from './EventsServiceType';

export interface EventsServiceResponse {
  id: number;
  type: EventsServiceType;
  data: EventsServiceResponseData;
  comment: string;
  content: string;
  finalized: DateTime | null;
  notified: boolean;
  decisionTime: DateTime | null;
  decision: boolean | null;
  remoteId: number | null;
  event: EventsMinimalEventResponse;
  canEdit: boolean;
  createdAt: DateTime;
}

export const EventsServiceResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'Azonosító',
    type: 'number',
      minimum: 1,
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Típus",
        enumValues: EventsServiceTypeValues,
  },
  'data': {
    name: 'data',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
  },
  'content': {
    name: 'content',
    required: true,
    nullable: false,
    label: 'Tartalmi összefoglaló',
    type: 'string',
  },
  'finalized': {
    name: 'finalized',
    required: true,
    nullable: true,
    label: 'Véglegesítés ideje',
    type: 'datetime',
  },
  'notified': {
    name: 'notified',
    required: true,
    nullable: false,
    label: 'Kör értesítve',
    type: 'boolean',
  },
  'decisionTime': {
    name: 'decisionTime',
    required: true,
    nullable: true,
    label: 'Döntés ideje',
    type: 'datetime',
  },
  'decision': {
    name: 'decision',
    required: true,
    nullable: true,
    label: '',
    type: 'boolean',
  },
  'remoteId': {
    name: 'remoteId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
  'event': {
    name: 'event',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: 'Létrehozás ideje',
    type: 'datetime',
  },
}
export function instanceOfEventsServiceResponse(value: object): value is EventsServiceResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "finalized" in value;
    isInstance = isInstance && "notified" in value;
    isInstance = isInstance && "decisionTime" in value;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "remoteId" in value;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "canEdit" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function EventsServiceResponseFromJSON(json: any): EventsServiceResponse {
return EventsServiceResponseFromJSONTyped(json, false);
}

export function EventsServiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'type': EventsServiceTypeFromJSON(json['type']),
              'data': EventsServiceResponseDataFromJSON(json['data']),
          'comment': json['comment'],
          'content': json['content'],
        'finalized': (json['finalized'] === null ? null : DateTime.fromISO(json['finalized'])),
          'notified': json['notified'],
        'decisionTime': (json['decisionTime'] === null ? null : DateTime.fromISO(json['decisionTime'])),
          'decision': json['decision'],
          'remoteId': json['remoteId'],
              'event': EventsMinimalEventResponseFromJSON(json['event']),
          'canEdit': json['canEdit'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function EventsServiceResponseToJSON(value?: EventsServiceResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'type': EventsServiceTypeToJSON(value.type),
                'data': EventsServiceResponseDataToJSON(value.data),
                'comment': value.comment,
                'content': value.content,
            'finalized': (
              value.finalized === null ? null : value.finalized.toISO()),
                'notified': value.notified,
            'decisionTime': (
              value.decisionTime === null ? null : value.decisionTime.toISO()),
                'decision': value.decision,
                'remoteId': value.remoteId,
                'event': EventsMinimalEventResponseToJSON(value.event),
                'canEdit': value.canEdit,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function EventsServiceResponseCreateEmpty(): EventsServiceResponse {
  return {
      'id': 0,
          'type': EventsServiceTypeCreateEmpty(),
          'data': EventsServiceResponseDataCreateEmpty(),
      'comment': '',
      'content': '',
      'finalized': DateTime.now(),
      'notified': false,
      'decisionTime': DateTime.now(),
      'decision': false,
      'remoteId': 0,
          'event': EventsMinimalEventResponseCreateEmpty(),
      'canEdit': false,
      'createdAt': DateTime.now(),
  };
  }

