import {ServiceConfig} from "./events/ServiceConfig";
import {ClosureType} from "./events/ClosureType";
import {Location} from "./events/Location";
import {Flag} from "@Models/money/Flag";
import {Group} from "@Models/base/Group";
import {Owner} from "@Models/inventory/Owner";
import {Permission} from "@Models/base/Permission";
import {GroupPermission} from "@Models/base/GroupPermission";
import {filter, first, map, sortBy} from "lodash-es";
import type {BaseConstantsResponse} from "@/api/api";
import {SpendingPeriod} from '@Models/money/SpendingPeriod';
import {DateTime} from '@Utils/DateTime';
import type {Tender} from '@Models/rvt/kpr/Tender';
import {Tag} from '@Models/inventory/Tag';
import {BudgetLocation} from '@Models/money/BudgetLocation';

export class Constants {
    kpr: {
        relevantTenders: Tender[],
        permissionMatrix: Record<number, Record<number, Record<number, number>>>
    };

    groups: {
        all: Record<number, Group>,
        resorts: Record<number, Group>,
        calculated: Group[]
    };

    events: {
        calendar: string,
        services: Record<string, ServiceConfig>,
        closureTypes: Record<number, ClosureType>,
        locations: Record<number, Location>,
    };

    viko: {
        charLimit: number,
    };

    auth: {
        globalPermissions: Record<string, Permission>,
        groupPermissions: Record<string, GroupPermission>,
        authschRedirectUrl: string,
    };

    central: {
        schBuildingId: number,
    };

    inventory: {
        defaults: {
            owner: number,
        },
        owners: Record<number, Owner>,
        tags: Record<number, Tag>
    };

    money: {
        spendingPeriods: Record<number, SpendingPeriod>,
        relevantPeriods: Record<number, SpendingPeriod>,
        currentPeriod: SpendingPeriod | null,
        budgetLocations: Record<number, BudgetLocation>
        flags: Record<number, Flag>,
    };

    constructor(data: BaseConstantsResponse) {
        const periods: Record<number, SpendingPeriod> = SpendingPeriod.newRecords(data.money.spendingPeriods, SpendingPeriod.parseResponse);
        const relevantPeriods = filter(periods, period => period.year >= DateTime.now().year);

        this.kpr = {
            relevantTenders: filter(map(relevantPeriods, period => period.tender), tender => tender !== null) as Tender[],
            permissionMatrix: data.kpr.permissionMatrix,
        };

        this.groups = {
            all: Group.newRecords(data.groups.all, Group.parseResponse),
            resorts: {},
            calculated: [],
        };
        this.groups.resorts = filter(this.groups.all, group => group.isResort() && group.active);
        this.groups.calculated = sortBy(this.groups.resorts, resort => resort.name);

        const others = new Group();
        others.id = 15;
        others.name = 'Egyéb körök';
        others.shortName = 'Egyéb';
        others.active = true;
        others.resort = null;
        this.groups.calculated.push(others);

        this.events = {
            calendar: data.events.calendar,
            services: ServiceConfig.newRecords(data.events.services, ServiceConfig.parseResponse),
            closureTypes: ClosureType.newRecords(data.events.closureTypes, ClosureType.parseResponse),
            locations: Location.newRecords(data.events.locations, Location.parseResponse),
        };

        this.viko = {
            charLimit: data.viko.charLimit,
        };

        this.auth = {
            globalPermissions: Permission.newRecords(data.auth.globalPermissions, Permission.parseResponse),
            groupPermissions: GroupPermission.newRecords(data.auth.groupPermissions, GroupPermission.parseResponse),
            authschRedirectUrl: data.auth.authschRedirectUrl,
        };

        this.central = {
            schBuildingId: data.central.schBuildingId,
        };

        this.inventory = {
            defaults: {
                owner: data.inventory.defaultOwner,
            },
            owners: Owner.newRecords(data.inventory.owners, Owner.parseResponse),
            tags: Tag.newRecords(data.inventory.tags, Tag.parseResponse)
        };

        this.money = {
            spendingPeriods: periods,
            relevantPeriods: relevantPeriods,
            currentPeriod: first(filter(periods, period => period.year === DateTime.now().year)) ?? null,
            budgetLocations: BudgetLocation.newRecords(data.money.budgetLocations, BudgetLocation.parseResponse),
            flags: Flag.newRecords(data.money.flags, Flag.parseResponse),
        };
    }
}
