<template>
    <PegasusHeading>Házszintű sor - {{ item.name }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Lábakra oszlás">
                <PegasusTable
                    :data="item.values"
                >
                    <Column field="location.name" header="Láb"></Column>

                    <Column :field="(value: BudgetItemValue) => value.value.huf()" header="Összeg"></Column>
                </PegasusTable>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Költségvetések">
                <PegasusTable
                    :data="programs"
                >
                    <Column field="name" header="Név"></Column>

                    <Column field="statusText" header="Állapot"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}">
                            <PegasusButton :params="{program: data}" to="money.budget.program.show">
                                <i class="fa fa-external-link"></i> Megnyitás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Házszintű sor</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ref} from 'vue';
import {BudgetItem} from '@Models/money/BudgetItem';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import promiseAll from '@Utils/promise';
import {BudgetProgram} from '@Models/money/BudgetProgram';
import Column from 'primevue/column';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import type {BudgetItemValue} from '@Models/money/BudgetItemValue';

const {itemId} = defineProps<{
    itemId: number
}>();

const fetched = await promiseAll({
    item: BudgetItem.getById(itemId),
    programs: BudgetProgram.getAllByItem(itemId)
});

const item = ref(fetched.item);

const programs = ref(fetched.programs);
</script>
