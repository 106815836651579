import {BaseEntity} from "@Models/BaseEntity";
import {Group} from "@Models/base/Group";
import {GroupPermission} from "@Models/base/GroupPermission";
import {GroupPost} from "@Models/base/GroupPost";
import {useConstantsStore} from "@/stores/constants";
import type {
    BaseAssignedGroupPermissionResponse,
    BaseGroupPermissionStore,
    BaseGroupSpecificPermission
} from "@/api/api";
import {MainApi} from "@/api/api";

export class AssignedGroupPermission extends BaseEntity {
    group: Group;

    permission: BaseGroupSpecificPermission;

    inheriting: boolean;

    permissionObject: GroupPermission;

    static store(group: Group, groupPost: GroupPost, permission: BaseGroupSpecificPermission, inherit: boolean): Promise<AssignedGroupPermission> {
        const data: BaseGroupPermissionStore = {
            groupPost: groupPost.id,
            group: group.id,
            groupSpecificPermission: permission,
            inherit: inherit,
        };

        return MainApi.groupPermissionsStore(data).then(response => {
            return AssignedGroupPermission.newSingle(response.data, AssignedGroupPermission.parseResponse);
        });
    }

    static parseResponse(permission: AssignedGroupPermission, data: BaseAssignedGroupPermissionResponse): AssignedGroupPermission {
        permission.id = data.id;
        permission.group = useConstantsStore().getGroup(data.groupId);
        permission.permission = data.permission;
        permission.inheriting = data.inheriting;
        permission.permissionObject = useConstantsStore().constants.auth.groupPermissions[data.permission];

        return permission;
    }

    public update(): Promise<void> {
        return MainApi.groupPermissionsUpdate(this.id, {inherit: this.inheriting}).then(response => {
            AssignedGroupPermission.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return MainApi.groupPermissionsDelete(this.id).then();
    }

    get permissionName() {
        return this.permissionObject.name;
    }

    get permissionDescription() {
        return this.permissionObject.description;
    }

    get canBeAssigned() {
        return this.permissionObject.canBeAssigned;
    }
}
