/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtAmendDecide {
  decision: boolean;
}

export const RvtAmendDecideSchema = {
  'decision': {
    name: 'decision',
    required: true,
    nullable: false,
    label: 'Döntés eredménye',
    type: 'boolean',
  },
}
export function instanceOfRvtAmendDecide(value: object): value is RvtAmendDecide {
let isInstance = true;
    isInstance = isInstance && "decision" in value;

return isInstance;
}

export function RvtAmendDecideFromJSON(json: any): RvtAmendDecide {
return RvtAmendDecideFromJSONTyped(json, false);
}

export function RvtAmendDecideFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtAmendDecide {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'decision': json['decision'],
    };
  }

  export function RvtAmendDecideToJSON(value?: RvtAmendDecide | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'decision': value.decision,
    };
  }

  export function RvtAmendDecideCreateEmpty(): RvtAmendDecide {
  return {
      'decision': false,
  };
  }

