<template>
    <PegasusModal v-if="training" header="Főrendezői tanfolyam szerkesztése" width="500px">
        <TrainingComponent :training="training" @stored="stored"></TrainingComponent>
    </PegasusModal>
</template>

<script lang="ts" setup>
import TrainingComponent from '@Components/events/training/Training.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {Training} from '@Models/events/Training';
import {useReturn} from '@Utils/dialog';

const {training} = defineProps<{
    training: Training
}>();

const returnValue = useReturn();

function stored() {
    returnValue(true);
}
</script>
