/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MapClosureTypeMapsResponse {
  maps: Array<string>;
}

export const MapClosureTypeMapsResponseSchema = {
  'maps': {
    name: 'maps',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
}
export function instanceOfMapClosureTypeMapsResponse(value: object): value is MapClosureTypeMapsResponse {
let isInstance = true;
    isInstance = isInstance && "maps" in value;

return isInstance;
}

export function MapClosureTypeMapsResponseFromJSON(json: any): MapClosureTypeMapsResponse {
return MapClosureTypeMapsResponseFromJSONTyped(json, false);
}

export function MapClosureTypeMapsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapClosureTypeMapsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'maps': json['maps'],
    };
  }

  export function MapClosureTypeMapsResponseToJSON(value?: MapClosureTypeMapsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'maps': value.maps,
    };
  }

  export function MapClosureTypeMapsResponseCreateEmpty(): MapClosureTypeMapsResponse {
  return {
      'maps': [],
  };
  }

