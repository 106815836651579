<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <Calendar
                v-model="value"

                :class="{'p-invalid': isValid === false}"
                :disabled="disabled"
                :input-id="id"
                :pt="{input: {'data-input-name': schema.name}}"
                :show-time="false"
                class="w-100"

                date-format="yy-mm-dd"
            ></Calendar>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import {DateTime} from "@Utils/DateTime";
import Calendar from "primevue/calendar";
import {type FieldSchema} from "@Components/base/form/FieldProperties";
import SmartField from "@Components/base/form/SmartField.vue";
import {computed} from "vue";

const modelValue = defineModel<InstanceType<typeof DateTime> | null>({required: true});

const {disabled, withoutGroup, schema, inline} = defineProps<{
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();

const emit = defineEmits<{
    change: []
}>();

const value = computed({
    get: () => modelValue.value?.toJSDate(),
    set: (value: string | string[] | Date | Date[] | undefined) => {
        if (value === undefined) {
            modelValue.value = null;
            emit('change');
            return;
        }

        if (typeof value === 'string' || Array.isArray(value)) {
            throw new Error('Not handled'); // TODO handle
        }

        modelValue.value = DateTime.fromJSDate(value);
        emit('change');
    },
});
</script>
