/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryCreateItemAdministrator {
  id: number;
  officialName: string;
  responsible: number;
  material: boolean;
}

export const InventoryCreateItemAdministratorSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'Tétel azonosító',
    type: 'number',
      minimum: 1,
  },
  'officialName': {
    name: 'officialName',
    required: true,
    nullable: false,
    label: 'Tétel hivatalos megnevezése',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
    label: 'Felelős kör',
    type: 'number',
      minimum: 0,
  },
  'material': {
    name: 'material',
    required: true,
    nullable: false,
    label: 'Materiális',
    type: 'boolean',
  },
}
export function instanceOfInventoryCreateItemAdministrator(value: object): value is InventoryCreateItemAdministrator {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "officialName" in value;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "material" in value;

return isInstance;
}

export function InventoryCreateItemAdministratorFromJSON(json: any): InventoryCreateItemAdministrator {
return InventoryCreateItemAdministratorFromJSONTyped(json, false);
}

export function InventoryCreateItemAdministratorFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryCreateItemAdministrator {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'officialName': json['officialName'],
          'responsible': json['responsible'],
          'material': json['material'],
    };
  }

  export function InventoryCreateItemAdministratorToJSON(value?: InventoryCreateItemAdministrator | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'officialName': value.officialName,
                'responsible': value.responsible,
                'material': value.material,
    };
  }

  export function InventoryCreateItemAdministratorCreateEmpty(): InventoryCreateItemAdministrator {
  return {
      'id': 0,
      'officialName': '',
      'responsible': 0,
      'material': false,
  };
  }

