import {BaseEntity} from '@Models/BaseEntity';
import {
    type MoneyBudgetProgramResponse,
    MoneyBudgetProgramStatus,
    MoneyBudgetProgramStatusValues,
    type MoneyBudgetProgramStore,
    type MoneyBudgetProgramUpdate
} from '@/api/models';
import type {FormRef} from '@/vue';
import {MoneyApi} from '@/api/apis';
import {ItemGroup} from '@Models/money/ItemGroup';
import {Group} from '@Models/base/Group';
import type {DateTime} from '@Utils/DateTime';

export class BudgetProgram extends BaseEntity {
    name: string;

    description: string;

    group: Group;

    itemGroups: Record<number, ItemGroup>;

    finalized: DateTime | null;

    approved: DateTime | null;

    status: MoneyBudgetProgramStatus;

    static getAllByItem(itemId: number): Promise<Record<number, BudgetProgram>> {
        return MoneyApi.budgetItemsGetPrograms(itemId).then(response => {
            return BudgetProgram.newRecords(response.data.programs, BudgetProgram.parseResponse);
        });
    }

    static getById(id: number): Promise<BudgetProgram> {
        return MoneyApi.budgetProgramsShow(id).then(response => {
            return BudgetProgram.newSingle(response.data, this.parseResponse);
        });
    }

    static parseResponse(program: BudgetProgram, data: MoneyBudgetProgramResponse): BudgetProgram {
        program.id = data.id;
        program.name = data.name;
        program.description = data.description;
        program.group = Group.getBySingleId(data.groupId);
        program.finalized = data.finalized;
        program.approved = data.approved;
        program.status = data.status;
        program.itemGroups = ItemGroup.newRecords(data.itemGroups, ItemGroup.parseWithItems);

        return program;
    }

    get statusText(): string {
        return MoneyBudgetProgramStatusValues[this.status];
    }

    public store(budgetItemId: number, form: FormRef): Promise<BudgetProgram> {
        const data: MoneyBudgetProgramStore = {
            name: this.name,
            budgetItem: budgetItemId
        };

        return MoneyApi.budgetProgramsStore(data, {form}).then(response => {
            return BudgetProgram.parseResponse(this, response.data);
        });
    }

    public update(form: FormRef): Promise<void> {
        const data: MoneyBudgetProgramUpdate = {
            name: this.name,
            description: this.description
        };

        return MoneyApi.budgetProgramsUpdate(this.id, data, {form}).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }

    public finalize(): Promise<void> {
        return MoneyApi.budgetProgramsFinalize(this.id).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }

    public approve(): Promise<void> {
        return MoneyApi.budgetProgramsApprove(this.id).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }
}
