<template>
    <PegasusHeading>{{ group.name }}</PegasusHeading>

    <h3 class="text-center p-1">Jogosultság kezelés</h3>

    <div class="row">
        <div class="col-md-6 mb-3">
            <PegasusPanel header="Posztok">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Poszt neve</th>
                            <th>Jogosultságok</th>
                            <th v-if="group.isResort()">Öröklődő jogosultságok</th>
                            <th>Műveletek</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="post in posts" :key="post.name">
                            <td class="align-middle">
                                {{ post.name }}
                            </td>

                            <td class="align-middle whitespace">
                                {{ post.getNotInheritingPermissionNames().join("\n") }}
                            </td>

                            <td v-if="group.isResort()" class="align-middle whitespace">
                                {{ post.getInheritingPermissionNames().join("\n") }}
                            </td>

                            <td>
                                <PegasusButton size="sm" variant="primary" @click="Dialog.open(GroupPostDialog, {groupPost: post, group})">
                                    <i class="fa fa-edit"></i> Szerkesztés
                                </PegasusButton>
                            </td>
                        </tr>
                    </tbody>
                </table>

                Hiányzó poszt?
                <PegasusHelp title="PéK szinkronizáció">
                    <p>A régi jogosultsági rendszer nem tárolta a PéK-en lévő posztokat minden felhasználónál, hanem
                        csak az azok alapján kiszámolt jogosultságokat. Így a fenti felsorolásban csak azok a
                        posztok szerepelnek amik az új rendszer bevezetése óta bejelentkezett felhasználóknál
                        szerepeltek.</p>

                    <p>Tehát egy hiányzó poszt akkor fog megjelenni amikor egy azt birtokló felhasználó
                        bejelentkezik vagy rányom a főoldalon található frissítés gombra.</p>

                    <p>Amennyiben ennél hamarabb igény lenne erre akkor a lenti segítségkérés menüpontban lévő
                        e-mail címre írva van lehetőség manuálisan felvenni posztokat.</p>
                </PegasusHelp>
            </PegasusPanel>
        </div>

        <div class="col-md-6 mb-3">
            <PegasusPanel header="Jogosultságok">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>Leírás</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="permission in assignablePermissions" :key="permission.name">
                            <td>
                                {{ permission.name }}
                            </td>

                            <td>
                                {{ permission.description }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <hr>

                Tudnivalók:
                <ul>
                    <li>A jogosultságok additívak: Ha egy felhasználó rendelkezik a Leltár szerkesztése és a Leltár
                        olvasása jogkörrel is egyszerre akkor szerkeszteni is tudja a Leltárat
                    </li>
                    <li>A jogosultságok az öregtagokra és újoncokra nem vonatkoznak. Tehát ha a 'leltár felelős'
                        poszthoz tartozik a 'Leltár szerkesztése' jogkör, akkor egy felhasználó aki rendelkezik a
                        'leltár felelős' poszttal PéK-en, de öregtag vagy újonc akkor nem fog hozzáférni a leltárhoz
                    </li>
                </ul>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Kör adatlap</title>

<script lang="ts" setup>
import {Group} from "@Models/base/Group";
import {computed, ref} from "vue";
import {useAuthStore} from "@/stores/auth";
import {useConstantsStore} from "@/stores/constants";
import {filter} from "lodash-es";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusHelp from "@Components/base/PegasusHelp.vue";
import {Dialog} from '@Utils/dialog';
import GroupPostDialog from '@Components/rvt/settings/GroupPostDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {groupId} = defineProps<{
    groupId: number
}>();

useAuthStore().requireUser();

const group = Group.getBySingleId(groupId);

const posts = ref(await group.getPosts());

const constants = useConstantsStore().constants;

const assignablePermissions = computed(() => {
    return filter(constants.auth.groupPermissions, permission => permission.canBeAssigned && permission.isValidForGroup(group));
});
</script>
