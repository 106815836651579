/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyFlagResponse {
  id: number;
  name: string;
  description: string;
  cssClass: string;
}

export const MoneyFlagResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'cssClass': {
    name: 'cssClass',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfMoneyFlagResponse(value: object): value is MoneyFlagResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "cssClass" in value;

return isInstance;
}

export function MoneyFlagResponseFromJSON(json: any): MoneyFlagResponse {
return MoneyFlagResponseFromJSONTyped(json, false);
}

export function MoneyFlagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyFlagResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'cssClass': json['cssClass'],
    };
  }

  export function MoneyFlagResponseToJSON(value?: MoneyFlagResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'cssClass': value.cssClass,
    };
  }

  export function MoneyFlagResponseCreateEmpty(): MoneyFlagResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'cssClass': '',
  };
  }

