<template>
    <NumberField
        :id="'review-input-' + review.id + random(1, 1000)"
        v-model="points"
        :class="classObject"
        :disabled="!canEdit"
        :schema="VikoReviewStoreSchema.point"
        without-group
        @update:model-value="pointUpdate"
    />
</template>

<script lang="ts" setup>
import type {Review} from "@Models/viko/Review";
import {computed, ref, watch} from "vue";
import toast from "@Utils/toast";
import {VikoReviewStoreSchema} from "@/api/models";
import {random} from "lodash-es";
import NumberField from "@Components/base/form/types/NumberField.vue";

const {review, groupId, canEdit, dark} = defineProps<{
    review: Review,
    groupId: number | null,
    canEdit: boolean,
    dark: boolean
}>();

const points = ref(review.calculatedPoint);

const isModified = computed(() => {
    if (groupId === null) {
        return review.finalPoints !== review.groupPoints;
    }

    return review.groupPoints !== review.points;
});

const classObject = computed(() => {
    return {
        'form-control form-control-sm review-input': true,
        'review-modified': isModified.value,
        'review-dark': dark,
    };
});

watch(() => review.calculatedPoint, (newValue, oldValue) => {
    if (newValue === oldValue) {
        return;
    }

    points.value = newValue;
});

function pointUpdate() {
    let request = review.summaryUpdate(groupId, points.value);

    toast.loading(request);
}
</script>
