/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetProgramStatus } from './MoneyBudgetProgramStatus';
    import {
    MoneyBudgetProgramStatusFromJSON,
    MoneyBudgetProgramStatusFromJSONTyped,
    MoneyBudgetProgramStatusToJSON,
    MoneyBudgetProgramStatusCreateEmpty,
    } from './MoneyBudgetProgramStatus';
    import type { MoneyItemGroupWithItemsResponse } from './MoneyItemGroupWithItemsResponse';
    import {
    MoneyItemGroupWithItemsResponseFromJSON,
    MoneyItemGroupWithItemsResponseFromJSONTyped,
    MoneyItemGroupWithItemsResponseToJSON,
    MoneyItemGroupWithItemsResponseCreateEmpty,
    } from './MoneyItemGroupWithItemsResponse';
import {DateTime} from '@Utils/DateTime';
import { MoneyBudgetProgramStatusValues } from './MoneyBudgetProgramStatus';

export interface MoneyBudgetProgramResponse {
  id: number;
  name: string;
  description: string;
  groupId: number;
  finalized: DateTime | null;
  approved: DateTime | null;
  status: MoneyBudgetProgramStatus;
  itemGroups: Array<MoneyItemGroupWithItemsResponse>;
}

export const MoneyBudgetProgramResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: 'Kör azonosítója',
    type: 'number',
      minimum: 1,
  },
  'finalized': {
    name: 'finalized',
    required: true,
    nullable: true,
    label: 'Véglegesítés időpontja',
    type: 'datetime',
  },
  'approved': {
    name: 'approved',
    required: true,
    nullable: true,
    label: 'Jóváhagyás időpontja',
    type: 'datetime',
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Állapot",
        enumValues: MoneyBudgetProgramStatusValues,
  },
  'itemGroups': {
    name: 'itemGroups',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfMoneyBudgetProgramResponse(value: object): value is MoneyBudgetProgramResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "finalized" in value;
    isInstance = isInstance && "approved" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "itemGroups" in value;

return isInstance;
}

export function MoneyBudgetProgramResponseFromJSON(json: any): MoneyBudgetProgramResponse {
return MoneyBudgetProgramResponseFromJSONTyped(json, false);
}

export function MoneyBudgetProgramResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'groupId': json['groupId'],
        'finalized': (json['finalized'] === null ? null : DateTime.fromISO(json['finalized'])),
        'approved': (json['approved'] === null ? null : DateTime.fromISO(json['approved'])),
              'status': MoneyBudgetProgramStatusFromJSON(json['status']),
            'itemGroups': ((json['itemGroups'] as Array<any>).map(MoneyItemGroupWithItemsResponseFromJSON)),
    };
  }

  export function MoneyBudgetProgramResponseToJSON(value?: MoneyBudgetProgramResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'groupId': value.groupId,
            'finalized': (
              value.finalized === null ? null : value.finalized.toISO()),
            'approved': (
              value.approved === null ? null : value.approved.toISO()),
                'status': MoneyBudgetProgramStatusToJSON(value.status),
              'itemGroups': ((value.itemGroups as Array<any>).map(MoneyItemGroupWithItemsResponseToJSON)),
    };
  }

  export function MoneyBudgetProgramResponseCreateEmpty(): MoneyBudgetProgramResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'groupId': 0,
      'finalized': DateTime.now(),
      'approved': DateTime.now(),
          'status': MoneyBudgetProgramStatusCreateEmpty(),
      'itemGroups': [],
  };
  }

