/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsSpotData {
  from: DateTime;
  to: DateTime;
  schedule: string;
}

export const EventsSpotDataSchema = {
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: 'Időtartam kezdete',
    type: 'datetime',
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: 'Időtartam vége',
    type: 'datetime',
  },
  'schedule': {
    name: 'schedule',
    required: true,
    nullable: false,
    label: 'Menetrend',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfEventsSpotData(value: object): value is EventsSpotData {
let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "schedule" in value;

return isInstance;
}

export function EventsSpotDataFromJSON(json: any): EventsSpotData {
return EventsSpotDataFromJSONTyped(json, false);
}

export function EventsSpotDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsSpotData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'from': (DateTime.fromISO(json['from'])),
        'to': (DateTime.fromISO(json['to'])),
          'schedule': json['schedule'],
    };
  }

  export function EventsSpotDataToJSON(value?: EventsSpotData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'from': (value.from.toISO()),
            'to': (value.to.toISO()),
                'schedule': value.schedule,
    };
  }

  export function EventsSpotDataCreateEmpty(): EventsSpotData {
  return {
      'from': DateTime.now(),
      'to': DateTime.now(),
      'schedule': '',
  };
  }

