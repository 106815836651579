<template>
    <PegasusModal :header="application.user.name" width="1000px">
        <PegasusForm v-if="reviewer" ref="form">
            <NumberField
                v-model="point"
                :schema="VikoReviewStoreSchema.point"

                autofocus
            ></NumberField>

            <PegasusButton variant="success" @click="save(true)">
                <i class="fas fa-save"></i> Mentés és tovább <i class="fas fa-arrow-right"></i>
            </PegasusButton>
            <PegasusButton variant="success" @click="save(false)">
                <i class="fas fa-save"></i> Mentés
            </PegasusButton>

            <PegasusButton variant="primary" @click="prev">
                <i class="fas fa-arrow-left"></i> Előző
            </PegasusButton>
            <PegasusButton variant="primary" @click="next">
                Következő <i class="fas fa-arrow-right"></i>
            </PegasusButton>
        </PegasusForm>

        <div v-if="reviewers.length > 0" class="top-bar">
            <h2 class="w-100 text-center pb-1">
                {{ application.user.name }} ({{ application.user.nick }})
            </h2>

            <div class="row">
                <div v-for="displayedReviewer in reviewers" :key="displayedReviewer.id" class="col text-lg">
                    <div class="text-center w-100 z-top text-lg fw-bold">
                        {{ displayedReviewer.nick }}
                    </div>

                    <ReviewComponent
                        v-if="application.reviews[displayedReviewer.id]"
                        :can-edit="true"
                        :dark="false"
                        :group-id="groupId"
                        :review="application.reviews[displayedReviewer.id]"
                    ></ReviewComponent>

                    <span v-else class="text-center">
                        <i class="fas fa-times text-red" @click="createReview(displayedReviewer)"></i>
                    </span>
                </div>
            </div>
        </div>

        <span v-if="reports === null">
            <i class="fa fa-spin fa-spinner"></i>
        </span>

        <div class="my-4 py-4"></div>

        <PegasusPanel v-for="report in reports" :key="report.id" :header-bg-variant="headerBgVariant(report)">
            <template #header>
                {{ report.group.name }}
                <span v-if="report.accepted === null" class="badge bg-primary float-end">
                    Elbírálatlan
                </span>

                <span v-if="report.accepted === false" class="badge bg-primary float-end">
                    Elutasított
                </span>
            </template>

            <span class="whitespace">{{ report.text }}</span>
        </PegasusPanel>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Report} from "@Models/viko/Report";
import ReviewComponent from "@Components/viko/reviewer/Review.vue";
import {Review} from "@Models/viko/Review";
import {User} from "@Models/base/User";
import {ref} from "vue";
import type {Application} from "@Models/viko/Application";
import toast from "@Utils/toast";
import NumberField from "@Components/base/form/types/NumberField.vue";
import {VikoReviewStoreSchema} from "@/api/models";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import type {FormRef, Variants} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';

const {application, reviewer, groupId} = defineProps<{
    application: Application,
    reviewer: User | null,
    reviewers: User[],
    groupId: number | null
}>();

const emit = defineEmits<{
    next: [],
    prev: []
}>();

const reports = ref<Record<number, Report> | null>(null);

application.getReports().then(response => {
    reports.value = response;
});

const review = reviewer ? application.reviews[reviewer.id] : null;

const point = ref(review ? review.points : VikoReviewStoreSchema.point.minimum);
const comment = ref(review ? review.comment : '');

const form = ref<FormRef>(null);

const returnValue = useReturn();

function createReview(reviewer: User) {
    Review.create(application.id, reviewer.id, 1).then(review => {
        application.reviews[reviewer.id] = review;
    });
}

function save(isNext = false) {
    if (reviewer === null) {
        return;
    }

    let request = application.review(point.value, comment.value, form.value).then(review => {
        application.reviews[reviewer.id] = review;

        if (isNext) {
            next();
        }
    });

    toast.loading(request);
}

function prev() {
    emit('prev');
    returnValue(true);
}

function next() {
    emit('next');
    returnValue(true);
}

function headerBgVariant(report: Report): Variants {
    if (report.accepted === true) {
        return 'success';
    }

    if (report.accepted === false) {
        return 'danger';
    }

    if (report.accepted === null) {
        return 'warning';
    }

    throw new Error('Invalid value: ' + report.accepted);
}
</script>
