/* tslint:disable */
/* eslint-disable */

export enum MoneyItemCommentType {
    DRAFTING = 'DRAFTING',
    REVIEW_RVT = 'REVIEW_RVT'
}

export const MoneyItemCommentTypeValues: Record<MoneyItemCommentType, string> = {
    'DRAFTING': 'DRAFTING',
    'REVIEW_RVT': 'REVIEW_RVT'
}


export function MoneyItemCommentTypeFromJSON(json: any): MoneyItemCommentType {
    return MoneyItemCommentTypeFromJSONTyped(json, false);
}

export function MoneyItemCommentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemCommentType {
    return json as MoneyItemCommentType;
}

export function MoneyItemCommentTypeToJSON(value?: MoneyItemCommentType | null): any {
    return value as any;
}

export function MoneyItemCommentTypeCreateEmpty(): MoneyItemCommentType {
    return MoneyItemCommentType.DRAFTING;
}

