/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    BaseSuccessResponse,
    BaseValidationBagResponse,
    RvtAmendApprove,
    RvtAmendDecide,
    RvtAmendResponse,
    RvtAmendStore,
    RvtAmendUpdate,
    RvtClaimApprove,
    RvtClaimDecide,
    RvtClaimResponse,
    RvtClaimStore,
    RvtClaimUpdate,
    RvtClaimsResponse,
    RvtGroupStatementListResponse,
    RvtGroupStatementResponse,
    RvtRvtSummaryResponse,
    RvtStatementStore,
    RvtStatementUpdate,
  } from '../models/index';
    import {
      BaseSuccessResponseFromJSON,
      BaseSuccessResponseToJSON,
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      RvtAmendApproveFromJSON,
      RvtAmendApproveToJSON,
      RvtAmendDecideFromJSON,
      RvtAmendDecideToJSON,
      RvtAmendResponseFromJSON,
      RvtAmendResponseToJSON,
      RvtAmendStoreFromJSON,
      RvtAmendStoreToJSON,
      RvtAmendUpdateFromJSON,
      RvtAmendUpdateToJSON,
      RvtClaimApproveFromJSON,
      RvtClaimApproveToJSON,
      RvtClaimDecideFromJSON,
      RvtClaimDecideToJSON,
      RvtClaimResponseFromJSON,
      RvtClaimResponseToJSON,
      RvtClaimStoreFromJSON,
      RvtClaimStoreToJSON,
      RvtClaimUpdateFromJSON,
      RvtClaimUpdateToJSON,
      RvtClaimsResponseFromJSON,
      RvtClaimsResponseToJSON,
      RvtGroupStatementListResponseFromJSON,
      RvtGroupStatementListResponseToJSON,
      RvtGroupStatementResponseFromJSON,
      RvtGroupStatementResponseToJSON,
      RvtRvtSummaryResponseFromJSON,
      RvtRvtSummaryResponseToJSON,
      RvtStatementStoreFromJSON,
      RvtStatementStoreToJSON,
      RvtStatementUpdateFromJSON,
      RvtStatementUpdateToJSON,
    } from '../models/index';

      export interface RvtAmendsAddOldItemRequest {
        amend: number;
        item: number;
      }

      export interface RvtAmendsApproveRequest {
        amend: number;
        rvtAmendApprove: RvtAmendApprove;
      }

      export interface RvtAmendsDecideRequest {
        amend: number;
        rvtAmendDecide: RvtAmendDecide;
      }

      export interface RvtAmendsDestroyRequest {
        amend: number;
      }

      export interface RvtAmendsFinalizeRequest {
        amend: number;
      }

      export interface RvtAmendsRemoveOldItemRequest {
        amend: number;
        item: number;
      }

      export interface RvtAmendsShowRequest {
        amend: number;
      }

      export interface RvtAmendsStoreRequest {
        rvtAmendStore: RvtAmendStore;
      }

      export interface RvtAmendsUpdateRequest {
        amend: number;
        rvtAmendUpdate: RvtAmendUpdate;
      }

      export interface RvtClaimsApproveRequest {
        claim: number;
        rvtClaimApprove: RvtClaimApprove;
      }

      export interface RvtClaimsDecideRequest {
        claim: number;
        rvtClaimDecide: RvtClaimDecide;
      }

      export interface RvtClaimsDestroyRequest {
        claim: number;
      }

      export interface RvtClaimsFinalizeRequest {
        claim: number;
      }

      export interface RvtClaimsPendingRequest {
        period: number;
        group: number;
      }

      export interface RvtClaimsShowRequest {
        claim: number;
      }

      export interface RvtClaimsStoreRequest {
        rvtClaimStore: RvtClaimStore;
      }

      export interface RvtClaimsUpdateRequest {
        claim: number;
        rvtClaimUpdate: RvtClaimUpdate;
      }

      export interface RvtClaimsWaitingRequest {
        period: number;
        group: number;
      }

      export interface RvtGroupStatementDeleteRequest {
        statement: number;
      }

      export interface RvtGroupStatementIndexRequest {
        group: number;
        year: string;
      }

      export interface RvtGroupStatementStoreRequest {
        rvtStatementStore: RvtStatementStore;
      }

      export interface RvtGroupStatementUpdateRequest {
        statement: number;
        rvtStatementUpdate: RvtStatementUpdate;
      }

      export class RvtApi extends runtime.BaseAPI {

      private async amendsAddOldItemRaw(requestParameters: RvtAmendsAddOldItemRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendResponse>> {
          if (requestParameters.amend === null || requestParameters.amend === undefined) {
          throw new runtime.RequiredError('amend','Required parameter requestParameters.amend was null or undefined when calling amendsAddOldItem.');
          }

          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling amendsAddOldItem.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/amends/{amend}/old/{item}`.replace(`{${"amend"}}`, encodeURIComponent(String(requestParameters.amend))).replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendResponseFromJSON(jsonValue));
      }

        static async amendsAddOldItem(amend: number, item: number, options?: {form?: FormRef}): Promise<{data: RvtAmendResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsAddOldItemRaw({ amend: amend, item: item }, options);
          return await response.value();
        }

      private async amendsApproveRaw(requestParameters: RvtAmendsApproveRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendResponse>> {
          if (requestParameters.amend === null || requestParameters.amend === undefined) {
          throw new runtime.RequiredError('amend','Required parameter requestParameters.amend was null or undefined when calling amendsApprove.');
          }

          if (requestParameters.rvtAmendApprove === null || requestParameters.rvtAmendApprove === undefined) {
          throw new runtime.RequiredError('rvtAmendApprove','Required parameter requestParameters.rvtAmendApprove was null or undefined when calling amendsApprove.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/requests/amends/{amend}/approve`.replace(`{${"amend"}}`, encodeURIComponent(String(requestParameters.amend))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: RvtAmendApproveToJSON(requestParameters.rvtAmendApprove),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendResponseFromJSON(jsonValue));
      }

        static async amendsApprove(amend: number, rvtAmendApprove: RvtAmendApprove, options?: {form?: FormRef}): Promise<{data: RvtAmendResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsApproveRaw({ amend: amend, rvtAmendApprove: rvtAmendApprove }, options);
          return await response.value();
        }

      private async amendsDecideRaw(requestParameters: RvtAmendsDecideRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendResponse>> {
          if (requestParameters.amend === null || requestParameters.amend === undefined) {
          throw new runtime.RequiredError('amend','Required parameter requestParameters.amend was null or undefined when calling amendsDecide.');
          }

          if (requestParameters.rvtAmendDecide === null || requestParameters.rvtAmendDecide === undefined) {
          throw new runtime.RequiredError('rvtAmendDecide','Required parameter requestParameters.rvtAmendDecide was null or undefined when calling amendsDecide.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/requests/amends/{amend}/decide`.replace(`{${"amend"}}`, encodeURIComponent(String(requestParameters.amend))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: RvtAmendDecideToJSON(requestParameters.rvtAmendDecide),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendResponseFromJSON(jsonValue));
      }

        static async amendsDecide(amend: number, rvtAmendDecide: RvtAmendDecide, options?: {form?: FormRef}): Promise<{data: RvtAmendResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsDecideRaw({ amend: amend, rvtAmendDecide: rvtAmendDecide }, options);
          return await response.value();
        }

      private async amendsDestroyRaw(requestParameters: RvtAmendsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.amend === null || requestParameters.amend === undefined) {
          throw new runtime.RequiredError('amend','Required parameter requestParameters.amend was null or undefined when calling amendsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/amends/{amend}`.replace(`{${"amend"}}`, encodeURIComponent(String(requestParameters.amend))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async amendsDestroy(amend: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsDestroyRaw({ amend: amend }, options);
          return await response.value();
        }

      private async amendsFinalizeRaw(requestParameters: RvtAmendsFinalizeRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendResponse>> {
          if (requestParameters.amend === null || requestParameters.amend === undefined) {
          throw new runtime.RequiredError('amend','Required parameter requestParameters.amend was null or undefined when calling amendsFinalize.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/amends/{amend}/finalize`.replace(`{${"amend"}}`, encodeURIComponent(String(requestParameters.amend))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendResponseFromJSON(jsonValue));
      }

        static async amendsFinalize(amend: number, options?: {form?: FormRef}): Promise<{data: RvtAmendResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsFinalizeRaw({ amend: amend }, options);
          return await response.value();
        }

      private async amendsRemoveOldItemRaw(requestParameters: RvtAmendsRemoveOldItemRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendResponse>> {
          if (requestParameters.amend === null || requestParameters.amend === undefined) {
          throw new runtime.RequiredError('amend','Required parameter requestParameters.amend was null or undefined when calling amendsRemoveOldItem.');
          }

          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling amendsRemoveOldItem.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/amends/{amend}/old/{item}`.replace(`{${"amend"}}`, encodeURIComponent(String(requestParameters.amend))).replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendResponseFromJSON(jsonValue));
      }

        static async amendsRemoveOldItem(amend: number, item: number, options?: {form?: FormRef}): Promise<{data: RvtAmendResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsRemoveOldItemRaw({ amend: amend, item: item }, options);
          return await response.value();
        }

      private async amendsShowRaw(requestParameters: RvtAmendsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendResponse>> {
          if (requestParameters.amend === null || requestParameters.amend === undefined) {
          throw new runtime.RequiredError('amend','Required parameter requestParameters.amend was null or undefined when calling amendsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/amends/{amend}`.replace(`{${"amend"}}`, encodeURIComponent(String(requestParameters.amend))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendResponseFromJSON(jsonValue));
      }

        static async amendsShow(amend: number, options?: {form?: FormRef}): Promise<{data: RvtAmendResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsShowRaw({ amend: amend }, options);
          return await response.value();
        }

      private async amendsStoreRaw(requestParameters: RvtAmendsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendResponse>> {
          if (requestParameters.rvtAmendStore === null || requestParameters.rvtAmendStore === undefined) {
          throw new runtime.RequiredError('rvtAmendStore','Required parameter requestParameters.rvtAmendStore was null or undefined when calling amendsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/requests/amends`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: RvtAmendStoreToJSON(requestParameters.rvtAmendStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendResponseFromJSON(jsonValue));
      }

        static async amendsStore(rvtAmendStore: RvtAmendStore, options?: {form?: FormRef}): Promise<{data: RvtAmendResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsStoreRaw({ rvtAmendStore: rvtAmendStore }, options);
          return await response.value();
        }

      private async amendsUpdateRaw(requestParameters: RvtAmendsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendResponse>> {
          if (requestParameters.amend === null || requestParameters.amend === undefined) {
          throw new runtime.RequiredError('amend','Required parameter requestParameters.amend was null or undefined when calling amendsUpdate.');
          }

          if (requestParameters.rvtAmendUpdate === null || requestParameters.rvtAmendUpdate === undefined) {
          throw new runtime.RequiredError('rvtAmendUpdate','Required parameter requestParameters.rvtAmendUpdate was null or undefined when calling amendsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/requests/amends/{amend}`.replace(`{${"amend"}}`, encodeURIComponent(String(requestParameters.amend))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: RvtAmendUpdateToJSON(requestParameters.rvtAmendUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendResponseFromJSON(jsonValue));
      }

        static async amendsUpdate(amend: number, rvtAmendUpdate: RvtAmendUpdate, options?: {form?: FormRef}): Promise<{data: RvtAmendResponse, raw: Response}> {
          const api = new this();
          const response = await api.amendsUpdateRaw({ amend: amend, rvtAmendUpdate: rvtAmendUpdate }, options);
          return await response.value();
        }

      private async claimsApproveRaw(requestParameters: RvtClaimsApproveRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimResponse>> {
          if (requestParameters.claim === null || requestParameters.claim === undefined) {
          throw new runtime.RequiredError('claim','Required parameter requestParameters.claim was null or undefined when calling claimsApprove.');
          }

          if (requestParameters.rvtClaimApprove === null || requestParameters.rvtClaimApprove === undefined) {
          throw new runtime.RequiredError('rvtClaimApprove','Required parameter requestParameters.rvtClaimApprove was null or undefined when calling claimsApprove.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/requests/claims/{claim}/approve`.replace(`{${"claim"}}`, encodeURIComponent(String(requestParameters.claim))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: RvtClaimApproveToJSON(requestParameters.rvtClaimApprove),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimResponseFromJSON(jsonValue));
      }

        static async claimsApprove(claim: number, rvtClaimApprove: RvtClaimApprove, options?: {form?: FormRef}): Promise<{data: RvtClaimResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsApproveRaw({ claim: claim, rvtClaimApprove: rvtClaimApprove }, options);
          return await response.value();
        }

      private async claimsDecideRaw(requestParameters: RvtClaimsDecideRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimResponse>> {
          if (requestParameters.claim === null || requestParameters.claim === undefined) {
          throw new runtime.RequiredError('claim','Required parameter requestParameters.claim was null or undefined when calling claimsDecide.');
          }

          if (requestParameters.rvtClaimDecide === null || requestParameters.rvtClaimDecide === undefined) {
          throw new runtime.RequiredError('rvtClaimDecide','Required parameter requestParameters.rvtClaimDecide was null or undefined when calling claimsDecide.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/requests/claims/{claim}/decide`.replace(`{${"claim"}}`, encodeURIComponent(String(requestParameters.claim))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: RvtClaimDecideToJSON(requestParameters.rvtClaimDecide),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimResponseFromJSON(jsonValue));
      }

        static async claimsDecide(claim: number, rvtClaimDecide: RvtClaimDecide, options?: {form?: FormRef}): Promise<{data: RvtClaimResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsDecideRaw({ claim: claim, rvtClaimDecide: rvtClaimDecide }, options);
          return await response.value();
        }

      private async claimsDestroyRaw(requestParameters: RvtClaimsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.claim === null || requestParameters.claim === undefined) {
          throw new runtime.RequiredError('claim','Required parameter requestParameters.claim was null or undefined when calling claimsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/claims/{claim}`.replace(`{${"claim"}}`, encodeURIComponent(String(requestParameters.claim))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async claimsDestroy(claim: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsDestroyRaw({ claim: claim }, options);
          return await response.value();
        }

      private async claimsFinalizeRaw(requestParameters: RvtClaimsFinalizeRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimResponse>> {
          if (requestParameters.claim === null || requestParameters.claim === undefined) {
          throw new runtime.RequiredError('claim','Required parameter requestParameters.claim was null or undefined when calling claimsFinalize.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/claims/{claim}/finalize`.replace(`{${"claim"}}`, encodeURIComponent(String(requestParameters.claim))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimResponseFromJSON(jsonValue));
      }

        static async claimsFinalize(claim: number, options?: {form?: FormRef}): Promise<{data: RvtClaimResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsFinalizeRaw({ claim: claim }, options);
          return await response.value();
        }

      private async claimsPendingRaw(requestParameters: RvtClaimsPendingRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimsResponse>> {
          if (requestParameters.period === null || requestParameters.period === undefined) {
          throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling claimsPending.');
          }

          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling claimsPending.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/claims/{period}/{group}/pending`.replace(`{${"period"}}`, encodeURIComponent(String(requestParameters.period))).replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimsResponseFromJSON(jsonValue));
      }

        static async claimsPending(period: number, group: number, options?: {form?: FormRef}): Promise<{data: RvtClaimsResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsPendingRaw({ period: period, group: group }, options);
          return await response.value();
        }

      private async claimsShowRaw(requestParameters: RvtClaimsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimResponse>> {
          if (requestParameters.claim === null || requestParameters.claim === undefined) {
          throw new runtime.RequiredError('claim','Required parameter requestParameters.claim was null or undefined when calling claimsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/claims/{claim}`.replace(`{${"claim"}}`, encodeURIComponent(String(requestParameters.claim))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimResponseFromJSON(jsonValue));
      }

        static async claimsShow(claim: number, options?: {form?: FormRef}): Promise<{data: RvtClaimResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsShowRaw({ claim: claim }, options);
          return await response.value();
        }

      private async claimsStoreRaw(requestParameters: RvtClaimsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimResponse>> {
          if (requestParameters.rvtClaimStore === null || requestParameters.rvtClaimStore === undefined) {
          throw new runtime.RequiredError('rvtClaimStore','Required parameter requestParameters.rvtClaimStore was null or undefined when calling claimsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/requests/claims`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: RvtClaimStoreToJSON(requestParameters.rvtClaimStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimResponseFromJSON(jsonValue));
      }

        static async claimsStore(rvtClaimStore: RvtClaimStore, options?: {form?: FormRef}): Promise<{data: RvtClaimResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsStoreRaw({ rvtClaimStore: rvtClaimStore }, options);
          return await response.value();
        }

      private async claimsUpdateRaw(requestParameters: RvtClaimsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimResponse>> {
          if (requestParameters.claim === null || requestParameters.claim === undefined) {
          throw new runtime.RequiredError('claim','Required parameter requestParameters.claim was null or undefined when calling claimsUpdate.');
          }

          if (requestParameters.rvtClaimUpdate === null || requestParameters.rvtClaimUpdate === undefined) {
          throw new runtime.RequiredError('rvtClaimUpdate','Required parameter requestParameters.rvtClaimUpdate was null or undefined when calling claimsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/requests/claims/{claim}`.replace(`{${"claim"}}`, encodeURIComponent(String(requestParameters.claim))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: RvtClaimUpdateToJSON(requestParameters.rvtClaimUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimResponseFromJSON(jsonValue));
      }

        static async claimsUpdate(claim: number, rvtClaimUpdate: RvtClaimUpdate, options?: {form?: FormRef}): Promise<{data: RvtClaimResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsUpdateRaw({ claim: claim, rvtClaimUpdate: rvtClaimUpdate }, options);
          return await response.value();
        }

      private async claimsWaitingRaw(requestParameters: RvtClaimsWaitingRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimsResponse>> {
          if (requestParameters.period === null || requestParameters.period === undefined) {
          throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling claimsWaiting.');
          }

          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling claimsWaiting.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/requests/claims/{period}/{group}/waiting`.replace(`{${"period"}}`, encodeURIComponent(String(requestParameters.period))).replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimsResponseFromJSON(jsonValue));
      }

        static async claimsWaiting(period: number, group: number, options?: {form?: FormRef}): Promise<{data: RvtClaimsResponse, raw: Response}> {
          const api = new this();
          const response = await api.claimsWaitingRaw({ period: period, group: group }, options);
          return await response.value();
        }

      private async groupStatementDeleteRaw(requestParameters: RvtGroupStatementDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.statement === null || requestParameters.statement === undefined) {
          throw new runtime.RequiredError('statement','Required parameter requestParameters.statement was null or undefined when calling groupStatementDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/rvt/groupStatements/{statement}`.replace(`{${"statement"}}`, encodeURIComponent(String(requestParameters.statement))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async groupStatementDelete(statement: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupStatementDeleteRaw({ statement: statement }, options);
          return await response.value();
        }

      private async groupStatementIndexRaw(requestParameters: RvtGroupStatementIndexRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtGroupStatementListResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupStatementIndex.');
          }

          if (requestParameters.year === null || requestParameters.year === undefined) {
          throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling groupStatementIndex.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/rvt/groupStatements/group/{group}/{year}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtGroupStatementListResponseFromJSON(jsonValue));
      }

        static async groupStatementIndex(group: number, year: string, options?: {form?: FormRef}): Promise<{data: RvtGroupStatementListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupStatementIndexRaw({ group: group, year: year }, options);
          return await response.value();
        }

      private async groupStatementStoreRaw(requestParameters: RvtGroupStatementStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtGroupStatementResponse>> {
          if (requestParameters.rvtStatementStore === null || requestParameters.rvtStatementStore === undefined) {
          throw new runtime.RequiredError('rvtStatementStore','Required parameter requestParameters.rvtStatementStore was null or undefined when calling groupStatementStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/rvt/groupStatements`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: RvtStatementStoreToJSON(requestParameters.rvtStatementStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtGroupStatementResponseFromJSON(jsonValue));
      }

        static async groupStatementStore(rvtStatementStore: RvtStatementStore, options?: {form?: FormRef}): Promise<{data: RvtGroupStatementResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupStatementStoreRaw({ rvtStatementStore: rvtStatementStore }, options);
          return await response.value();
        }

      private async groupStatementUpdateRaw(requestParameters: RvtGroupStatementUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtGroupStatementResponse>> {
          if (requestParameters.statement === null || requestParameters.statement === undefined) {
          throw new runtime.RequiredError('statement','Required parameter requestParameters.statement was null or undefined when calling groupStatementUpdate.');
          }

          if (requestParameters.rvtStatementUpdate === null || requestParameters.rvtStatementUpdate === undefined) {
          throw new runtime.RequiredError('rvtStatementUpdate','Required parameter requestParameters.rvtStatementUpdate was null or undefined when calling groupStatementUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/rvt/groupStatements/{statement}`.replace(`{${"statement"}}`, encodeURIComponent(String(requestParameters.statement))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: RvtStatementUpdateToJSON(requestParameters.rvtStatementUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtGroupStatementResponseFromJSON(jsonValue));
      }

        static async groupStatementUpdate(statement: number, rvtStatementUpdate: RvtStatementUpdate, options?: {form?: FormRef}): Promise<{data: RvtGroupStatementResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupStatementUpdateRaw({ statement: statement, rvtStatementUpdate: rvtStatementUpdate }, options);
          return await response.value();
        }

      private async rvtSummaryRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtRvtSummaryResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/rvt/summary`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtRvtSummaryResponseFromJSON(jsonValue));
      }

        static async rvtSummary(options?: {form?: FormRef}): Promise<{data: RvtRvtSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.rvtSummaryRaw(options);
          return await response.value();
        }

    }
