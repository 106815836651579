/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyAccountCheck {
  accountName: string;
  accountNumber: string;
}

export const MoneyAccountCheckSchema = {
  'accountName': {
    name: 'accountName',
    required: true,
    nullable: false,
    label: 'Kedvezményezett neve',
    type: 'string',
      minLength: 0,
      maxLength: 150,
  },
  'accountNumber': {
    name: 'accountNumber',
    required: true,
    nullable: false,
    label: 'Számlaszám',
    type: 'string',
      minLength: 8,
      maxLength: 30,
  },
}
export function instanceOfMoneyAccountCheck(value: object): value is MoneyAccountCheck {
let isInstance = true;
    isInstance = isInstance && "accountName" in value;
    isInstance = isInstance && "accountNumber" in value;

return isInstance;
}

export function MoneyAccountCheckFromJSON(json: any): MoneyAccountCheck {
return MoneyAccountCheckFromJSONTyped(json, false);
}

export function MoneyAccountCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyAccountCheck {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'accountName': json['accountName'],
          'accountNumber': json['accountNumber'],
    };
  }

  export function MoneyAccountCheckToJSON(value?: MoneyAccountCheck | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'accountName': value.accountName,
                'accountNumber': value.accountNumber,
    };
  }

  export function MoneyAccountCheckCreateEmpty(): MoneyAccountCheck {
  return {
      'accountName': '',
      'accountNumber': '',
  };
  }

