/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface SystemEmailResponse {
  id: number;
  to: Array<string>;
  template: string;
  cc: Array<string>;
  replyTo: Array<string>;
  from: string;
  sent: DateTime | null;
  delivered: DateTime | null;
  messageId: string | null;
}

export const SystemEmailResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: 'Címzettek',
    type: 'array',
  },
  'template': {
    name: 'template',
    required: true,
    nullable: false,
    label: 'Sablon',
    type: 'string',
  },
  'cc': {
    name: 'cc',
    required: true,
    nullable: false,
    label: 'Címzettek',
    type: 'array',
  },
  'replyTo': {
    name: 'replyTo',
    required: true,
    nullable: false,
    label: 'Címzettek',
    type: 'array',
  },
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: 'Feladó',
    type: 'string',
  },
  'sent': {
    name: 'sent',
    required: true,
    nullable: true,
    label: 'Átadva',
    type: 'datetime',
  },
  'delivered': {
    name: 'delivered',
    required: true,
    nullable: true,
    label: 'Kézbesítve',
    type: 'datetime',
  },
  'messageId': {
    name: 'messageId',
    required: true,
    nullable: true,
    label: 'Üzenet azonosító',
    type: 'string',
  },
}
export function instanceOfSystemEmailResponse(value: object): value is SystemEmailResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "template" in value;
    isInstance = isInstance && "cc" in value;
    isInstance = isInstance && "replyTo" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "sent" in value;
    isInstance = isInstance && "delivered" in value;
    isInstance = isInstance && "messageId" in value;

return isInstance;
}

export function SystemEmailResponseFromJSON(json: any): SystemEmailResponse {
return SystemEmailResponseFromJSONTyped(json, false);
}

export function SystemEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemEmailResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'to': json['to'],
          'template': json['template'],
          'cc': json['cc'],
          'replyTo': json['replyTo'],
          'from': json['from'],
        'sent': (json['sent'] === null ? null : DateTime.fromISO(json['sent'])),
        'delivered': (json['delivered'] === null ? null : DateTime.fromISO(json['delivered'])),
          'messageId': json['messageId'],
    };
  }

  export function SystemEmailResponseToJSON(value?: SystemEmailResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
            'to': value.to,
                'template': value.template,
            'cc': value.cc,
            'replyTo': value.replyTo,
                'from': value.from,
            'sent': (
              value.sent === null ? null : value.sent.toISO()),
            'delivered': (
              value.delivered === null ? null : value.delivered.toISO()),
                'messageId': value.messageId,
    };
  }

  export function SystemEmailResponseCreateEmpty(): SystemEmailResponse {
  return {
      'id': 0,
      'to': [],
      'template': '',
      'cc': [],
      'replyTo': [],
      'from': '',
      'sent': DateTime.now(),
      'delivered': DateTime.now(),
      'messageId': '',
  };
  }

