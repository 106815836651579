<template>
    <div class="content-header clearfix">
        <h2 class="d-inline-block p-1">
            {{ cappRef.category.name }} KPR - {{ application.group.name }}
        </h2>
        <span class="d-inline-block float-end">
            <PegasusButton :params="{application}" to="rvt.kpr.application" variant="primary">
                Vissza a pályázathoz
            </PegasusButton>
        </span>
    </div>

    <component
        :is="components[cappRef.category.shortName]"
        :capp="cappRef"
    ></component>
</template>

<title>KPR szerkesztő</title>

<script lang="ts" setup>
import m from "@Components/kpr/m/Category.vue";
import k from "@Components/kpr/k/Category.vue";
import f from "@Components/kpr/f/Category.vue";
import {Application} from "@Models/rvt/kpr/Application";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {NotFoundError} from "@Utils/errors/NotFoundError";
import {useAuthStore} from "@/stores/auth";
import {ref} from 'vue';

const components = {
    m, k, f,
};

const {applicationId, categoryName} = defineProps<{
    applicationId: number,
    categoryName: string
}>();

useAuthStore().requireUser();

const application = await Application.getById(applicationId);
const capp = application.getCappByShortName(categoryName);
if (capp === null) {
    throw new NotFoundError('Nem létező KPR kategória');
}

const cappRef = ref(capp);
</script>
