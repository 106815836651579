/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseAuthschAccountResponse {
  id: number;
  providerId: string;
  name: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  schacc: string | null;
  bmeid: number | null;
  virId: number | null;
  virUid: string | null;
  neptun: string | null;
  updatedAt: DateTime;
  lastLogin: DateTime;
}

export const BaseAuthschAccountResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'providerId': {
    name: 'providerId',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'email': {
    name: 'email',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'firstName': {
    name: 'firstName',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'lastName': {
    name: 'lastName',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'schacc': {
    name: 'schacc',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'bmeid': {
    name: 'bmeid',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'virId': {
    name: 'virId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'virUid': {
    name: 'virUid',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'neptun': {
    name: 'neptun',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'updatedAt': {
    name: 'updatedAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'lastLogin': {
    name: 'lastLogin',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfBaseAuthschAccountResponse(value: object): value is BaseAuthschAccountResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "providerId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "schacc" in value;
    isInstance = isInstance && "bmeid" in value;
    isInstance = isInstance && "virId" in value;
    isInstance = isInstance && "virUid" in value;
    isInstance = isInstance && "neptun" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "lastLogin" in value;

return isInstance;
}

export function BaseAuthschAccountResponseFromJSON(json: any): BaseAuthschAccountResponse {
return BaseAuthschAccountResponseFromJSONTyped(json, false);
}

export function BaseAuthschAccountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAuthschAccountResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'providerId': json['providerId'],
          'name': json['name'],
          'email': json['email'],
          'firstName': json['firstName'],
          'lastName': json['lastName'],
          'schacc': json['schacc'],
          'bmeid': json['bmeid'],
          'virId': json['virId'],
          'virUid': json['virUid'],
          'neptun': json['neptun'],
        'updatedAt': (DateTime.fromISO(json['updatedAt'])),
        'lastLogin': (DateTime.fromISO(json['lastLogin'])),
    };
  }

  export function BaseAuthschAccountResponseToJSON(value?: BaseAuthschAccountResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'providerId': value.providerId,
                'name': value.name,
                'email': value.email,
                'firstName': value.firstName,
                'lastName': value.lastName,
                'schacc': value.schacc,
                'bmeid': value.bmeid,
                'virId': value.virId,
                'virUid': value.virUid,
                'neptun': value.neptun,
            'updatedAt': (value.updatedAt.toISO()),
            'lastLogin': (value.lastLogin.toISO()),
    };
  }

  export function BaseAuthschAccountResponseCreateEmpty(): BaseAuthschAccountResponse {
  return {
      'id': 0,
      'providerId': '',
      'name': '',
      'email': '',
      'firstName': '',
      'lastName': '',
      'schacc': '',
      'bmeid': 0,
      'virId': 0,
      'virUid': '',
      'neptun': '',
      'updatedAt': DateTime.now(),
      'lastLogin': DateTime.now(),
  };
  }

