/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface SystemEmailTemplateResponse {
  html: string;
  subject: string;
}

export const SystemEmailTemplateResponseSchema = {
  'html': {
    name: 'html',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'subject': {
    name: 'subject',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfSystemEmailTemplateResponse(value: object): value is SystemEmailTemplateResponse {
let isInstance = true;
    isInstance = isInstance && "html" in value;
    isInstance = isInstance && "subject" in value;

return isInstance;
}

export function SystemEmailTemplateResponseFromJSON(json: any): SystemEmailTemplateResponse {
return SystemEmailTemplateResponseFromJSONTyped(json, false);
}

export function SystemEmailTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemEmailTemplateResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'html': json['html'],
          'subject': json['subject'],
    };
  }

  export function SystemEmailTemplateResponseToJSON(value?: SystemEmailTemplateResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'html': value.html,
                'subject': value.subject,
    };
  }

  export function SystemEmailTemplateResponseCreateEmpty(): SystemEmailTemplateResponse {
  return {
      'html': '',
      'subject': '',
  };
  }

