/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryOwnerSummaryResponse {
  ownerId: number;
  itemCount: number;
  priceTotal: number;
}

export const InventoryOwnerSummaryResponseSchema = {
  'ownerId': {
    name: 'ownerId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'itemCount': {
    name: 'itemCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'priceTotal': {
    name: 'priceTotal',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfInventoryOwnerSummaryResponse(value: object): value is InventoryOwnerSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "ownerId" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "priceTotal" in value;

return isInstance;
}

export function InventoryOwnerSummaryResponseFromJSON(json: any): InventoryOwnerSummaryResponse {
return InventoryOwnerSummaryResponseFromJSONTyped(json, false);
}

export function InventoryOwnerSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryOwnerSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'ownerId': json['ownerId'],
          'itemCount': json['itemCount'],
          'priceTotal': json['priceTotal'],
    };
  }

  export function InventoryOwnerSummaryResponseToJSON(value?: InventoryOwnerSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'ownerId': value.ownerId,
                'itemCount': value.itemCount,
                'priceTotal': value.priceTotal,
    };
  }

  export function InventoryOwnerSummaryResponseCreateEmpty(): InventoryOwnerSummaryResponse {
  return {
      'ownerId': 0,
      'itemCount': 0,
      'priceTotal': 0,
  };
  }

