<template>
    <PegasusModal header="VIKÖ pályázat létrehozása">
        <PegasusForm ref="form">
            <div class="row">
                <div class="col-lg-6">
                    <NumberField
                        v-model="tender.year"
                        :schema="VikoTenderStoreSchema.year"
                        :grouping="false"
                    ></NumberField>
                </div>

                <div class="col-lg-6">
                    <SelectField
                        v-model="tender.semester"
                        :options="[{value: 0, label: 'Tavaszi félév'}, {value: 1, label: 'Őszi félév'}]"
                        :schema="VikoTenderStoreSchema.semester"
                    ></SelectField>
                </div>
            </div>

            <DateField
                v-model="tender.begin"
                :schema="VikoTenderStoreSchema.begin"
            ></DateField>

            <DateField
                v-model="tender.end"
                :schema="VikoTenderStoreSchema.end"
            ></DateField>

            <DatetimeField
                v-model="tender.beginDrafting"
                :schema="VikoTenderStoreSchema.beginDrafting"
            ></DatetimeField>

            <DatetimeField
                v-model="tender.endDrafting"
                :schema="VikoTenderStoreSchema.endDrafting"
            ></DatetimeField>

            <PegasusButton variant="success" @click="save">
                Létrehozás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {VikoTenderStoreSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import DateField from '@Components/base/form/types/DateField.vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {Tender} from '@Models/viko/Tender';
import {ref} from 'vue';
import toast from '@Utils/toast';
import {redirect} from '@/router';
import type {FormRef} from '@/vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {useReturn} from '@Utils/dialog';

const tender = ref(Tender.createNew());

const form = ref<FormRef>(null);

const returnValue = useReturn();

function save() {
    toast.info('Létrehozás folyamatban');

    tender.value.store(form.value).then(response => {
        toast.success('Létrehozás sikeres', 'Átirányítás folyamatban');

        setTimeout(() => {
            redirect({
                name: 'viko.admin.tender',
                params: {
                    tender: response.id,
                },
            });
            returnValue(true);
        }, 1000);
    });
}
</script>
