<template>
    <PegasusModal header="Adat változtatás">
        <PegasusForm ref="form">
            <DataForm :item="local"></DataForm>

            <SelectField
                v-if="summary"
                v-model="editingItemGroup"
                :options="dictObject(summary.allItemGroups(), itemGroup => itemGroup.name)"
                :schema="InventoryItemDataUpdateSchema.itemGroup"
            ></SelectField>

            <TextField
                v-model="comment"
                :schema="InventoryItemDataUpdateSchema.comment"
            ></TextField>

            <PegasusButton type="submit" variant="primary" @click="save(false)">
                Mentés
            </PegasusButton>

            <PegasusButton v-if="hasNext" type="submit" variant="primary" @click="save(true)">
                Mentés és következő
            </PegasusButton>

            <PegasusButton v-if="hasNext" type="submit" variant="primary" @click="returnValue(null)">
                Mégse
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import DataForm from "@Components/inventory/item/DataForm.vue";
import {ref} from "vue";
import {assign, clone} from "lodash-es";
import toast from "@Utils/toast";
import {InventoryItemDataUpdateSchema} from "@/api/models";
import TextField from "@Components/base/form/types/TextField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import type {InventoryGroupSummary} from "@Models/inventory/InventoryGroupSummary";
import {dictObject} from "@Models/Utils";
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';

const {item, hasNext, summary} = defineProps<{
    item: Item,
    hasNext?: boolean,
    summary?: InventoryGroupSummary
}>();

const comment = ref('');
const local = ref(clone(item));

const form = ref<FormRef>(null);

const editingItemGroup = ref(item.itemGroupId ? summary?.allItemGroups()[item.itemGroupId] : null);

const returnValue = useReturn();

function save(next: boolean = false) {
    local.value.itemGroupId = editingItemGroup.value?.id ?? null;

    let request = local.value.save(comment.value, form.value).then(() => {
        assign(item, local.value);

        returnValue(next);
    });

    toast.loading(request);
}
</script>
