<template>
    <PegasusPanel :header="'RVT keretigény #' + claim.id" :header-bg-variant="dirty ? 'warning' : 'success'">
        <dd>
            <TextField
                v-model="claim.objective"
                :disabled="!claim.canEdit"
                :schema="RvtClaimUpdateSchema.objective"
                @update:model-value="dirty = true"
            ></TextField>
        </dd>

        <dd>
            <TextareaField
                v-model="claim.reasoning"
                :disabled="!claim.canEdit"
                :schema="RvtClaimUpdateSchema.reasoning"
                @update:model-value="dirty = true"
            ></TextareaField>
        </dd>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import {Claim} from "@Models/rvt/requests/Claim";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import TextField from "@Components/base/form/types/TextField.vue";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import {RvtClaimUpdateSchema} from "@/api/models";

const {claim} = defineProps<{
    claim: Claim
}>();

const dirty = defineModel<boolean>('dirty');
</script>
