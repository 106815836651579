<template>
    <TextareaField
        v-model="data.reason"
        :disabled="!service.canEdit"
        :schema="EventsElevatorPermitDataSchema.reason"
    ></TextareaField>

    <TextareaField
        v-model="data.times"
        :disabled="!service.canEdit"
        :schema="EventsElevatorPermitDataSchema.times"
    ></TextareaField>

    <small>
        <i class="fas fa-exclamation-circle text-danger"></i>
        A kikulcsolást a kezdő időpontkor a portán kell majd kérni!
    </small>
</template>

<script lang="ts" setup>
import type {Service} from "@Models/events/Service";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import {type EventsElevatorPermitData, EventsElevatorPermitDataSchema} from "@/api/models";

const data = defineModel<EventsElevatorPermitData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
