<template>
    <div>
        <i :class="icons[event.type][0] + ' text-white ' + icons[event.type][1]"></i>
        <div class="timeline-item">
            <span class="time">
                <i class="fas fa-user"></i> {{ event.user.name }}
                <i class="fas fa-clock"></i> {{ event.createdAt.datetime() }}
            </span>
            <h3 class="timeline-header">{{ event.getName() }}</h3>

            <PegasusOverlay
                v-for="text in event.text"
                :key="text.key"
                :content="text.value"
                :header="text.key"
            >
                <div class="timeline-body">
                    <i class="fas fa-circle-info"></i> {{ text.key }}: {{ text.value.limit(30) }}
                </div>
            </PegasusOverlay>

            <PegasusOverlay
                v-if="event.comment"
                :content="event.comment"
                header="Megjegyzés"
            >
                <div class="timeline-body">
                    <i class="fas fa-comment"></i> {{ event.comment.limit(40) }}
                </div>
            </PegasusOverlay>
        </div>
    </div>
</template>

<script lang="ts" setup>
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';

const {event} = defineProps<{
    event: SpendingItemEvent
}>();

const icons = SpendingItemEvent.getIcons();
</script>
