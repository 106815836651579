/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { ExternalMentorResponse } from './ExternalMentorResponse';
    import {
    ExternalMentorResponseFromJSON,
    ExternalMentorResponseFromJSONTyped,
    ExternalMentorResponseToJSON,
    ExternalMentorResponseCreateEmpty,
    } from './ExternalMentorResponse';
import {DateTime} from '@Utils/DateTime';

export interface ExternalMentorListResponse {
  mentors: Array<ExternalMentorResponse>;
}

export const ExternalMentorListResponseSchema = {
  'mentors': {
    name: 'mentors',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfExternalMentorListResponse(value: object): value is ExternalMentorListResponse {
let isInstance = true;
    isInstance = isInstance && "mentors" in value;

return isInstance;
}

export function ExternalMentorListResponseFromJSON(json: any): ExternalMentorListResponse {
return ExternalMentorListResponseFromJSONTyped(json, false);
}

export function ExternalMentorListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalMentorListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'mentors': ((json['mentors'] as Array<any>).map(ExternalMentorResponseFromJSON)),
    };
  }

  export function ExternalMentorListResponseToJSON(value?: ExternalMentorListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'mentors': ((value.mentors as Array<any>).map(ExternalMentorResponseToJSON)),
    };
  }

  export function ExternalMentorListResponseCreateEmpty(): ExternalMentorListResponse {
  return {
      'mentors': [],
  };
  }

