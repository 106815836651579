import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {
    type MoneySpendingEventTransferSent,
    MoneySpendingEventTransferSentSchema,
    MoneySpendingEventType,
    type MoneySpendingItemEvent,
    type MoneySpendingItemEventResponse
} from '@/api/models';
import type {FormRef} from '@/vue';
import {MoneyApi} from '@/api/apis';
import {DateTime} from '@Utils/DateTime';

export class TransferSent extends SpendingItemEvent {
    transactionTime: DateTime;

    transactionId: string;

    constructor() {
        super();

        this.transactionTime = DateTime.now().resetSeconds();
    }

    static parseResponse(transferSent: TransferSent, data: MoneySpendingItemEventResponse): TransferSent {
        SpendingItemEvent.parseResponse(transferSent, data);

        const specific = data.data as MoneySpendingEventTransferSent;
        transferSent.transactionTime = specific.transactionTime;
        transferSent.transactionId = specific.transactionId;

        return transferSent;
    }

    public store(itemId: number, form: FormRef): Promise<TransferSent> {
        const data: MoneySpendingItemEvent = {
            data: {
                type: MoneySpendingEventType.TRANSFER_SENT,
                transactionTime: this.transactionTime,
                transactionId: this.transactionId
            },
            comment: this.comment
        };

        return MoneyApi.spendingEventsTransferSent(itemId, data, {form}).then(response => {
            return TransferSent.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        return [
            {
                key: MoneySpendingEventTransferSentSchema.transactionTime.label,
                value: this.transactionTime.datetime()
            },
            {
                key: MoneySpendingEventTransferSentSchema.transactionId.label,
                value: this.transactionId
            },
        ];
    }
}
