<template>
    <PegasusHeading>Nagyteremosztó adminisztráció</PegasusHeading>

    <PegasusTable :data="periods" :per-page="10" :row-variant="row">
        <Column field="displayName" header="Időszak"></Column>

        <Column field="responsible.name" header="Admin"></Column>

        <Column header="Műveletek">
            <template #body="{data}: {data: ReservationPeriod}">
                <PegasusButton :params="{period: data}" to="events.admin.planning.period">
                    <i class="fa fa-edit"></i> Szerkesztés
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<title>Főrendezői tanfolyam</title>

<script lang="ts" setup>
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {ref} from "vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ReservationPeriod} from '@Models/events/ReservationPeriod';

useAuthStore().requireUser();

const periods = ref(await ReservationPeriod.getAll());

function row(item: ReservationPeriod) {
    if (item.active) {
        return 'success';
    } else {
        return null;
    }
}
</script>
