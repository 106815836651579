<template>
    <PegasusPanel :header="'KPR módosító kérvény #' + amend.id" :header-bg-variant="dirty ? 'warning' : 'success'">
        <dd>
            <TextField
                v-model="amend.objective"
                :disabled="!amend.canEdit"
                :schema="RvtAmendUpdateSchema.objective"
                @update:model-value="dirty = true"
            ></TextField>
        </dd>

        <dd>
            <TextareaField
                v-model="amend.reasoning"
                :disabled="!amend.canEdit"
                :schema="RvtAmendUpdateSchema.reasoning"
                @update:model-value="dirty = true"
            ></TextareaField>
        </dd>

        <b>Forrás tételek
            <PegasusHelp title="Forrás tételek">Amelyik tételeket módosítani akarod</PegasusHelp>
        </b>

        <PegasusTable :data="amend.oldItems">
            <Column :field="(item: Item) => item.type?.toUpperCase() ?? 'X'" header=""></Column>

            <Column field="name" header="Tétel"></Column>
            <Column :field="(item: DecidedItem) => item.totalDecided.huf()" body-class="text-nowrap" header="Költség"></Column>
            <Column :field="(item: DecidedItem) => item.contribution?.huf() ?? '-'" body-class="text-nowrap" header="Önrész"></Column>
            <Column :field="(item: DecidedItem) => item.decidedPrice.huf()" body-class="text-nowrap" header="Támogatás"></Column>

            <Column>
                <template #body="{data}: {data: DecidedItem}">
                    <i class="fa fa-times text-red" @click="removeOldItem(data)"></i>
                </template>
            </Column>

            <template #footer>
                <tr>
                    <td colspan="4">Összesen</td>
                    <td>{{ amend.sumOld.huf() }}</td>
                </tr>
            </template>
        </PegasusTable>

        <PegasusButton v-if="amend.canEdit" :disabled="!applicationData" variant="success" @click="addOldItem">
            Új forrás tétel
        </PegasusButton>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import {Amend} from "@Models/rvt/requests/Amend";
import {Application} from "@Models/rvt/kpr/Application";
import {type DecidedItem, Item} from "@Models/money/Item";
import {ref} from "vue";
import PegasusHelp from "@Components/base/PegasusHelp.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import TextField from "@Components/base/form/types/TextField.vue";
import {RvtAmendUpdateSchema} from "@/api/models";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import {Dialog} from '@Utils/dialog';
import SelectItemDialog from '@Components/rvt/requests/amends/SelectItemDialog.vue';
import toast from '@Utils/toast';

const {amend} = defineProps<{
    amend: Amend,
}>();

const dirty = defineModel<boolean>('dirty');

let applicationData = ref<Application | null>(null);

if (amend.canEdit) {
    Application.getById(amend.applicationId).then(application => {
        applicationData.value = application;
    });
}

function addOldItem() {
    Dialog.open(SelectItemDialog, {amend, applicationData});
}

function removeOldItem(item: Item) {
    const request = amend.removeOldItem(item);

    toast.loading(request);
}
</script>
