<template>
    <table class="table table-striped table-condensed table-bordered text-nowrap">
        <thead>
            <tr>
                <th>Poszt</th>
                <th class="text-center">Olvas</th>
                <th class="text-center">Szerkeszt</th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="(permissions, post) in data" :key="post">
                <td>
                    {{ names[post] }}
                    <PegasusHelp :title="names[post]">{{ help[post] }}</PegasusHelp>
                </td>

                <td v-for="permission in permissions" :key="post + permission" class="text-center">
                    <i v-if="permission" class="fas text-green fa-fw fa-check"></i>
                    <i v-else class="fas text-red fa-fw fa-times"></i>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts" setup>
import PegasusHelp from "@Components/base/PegasusHelp.vue";

const names: Record<number, string> = {
    0: 'Bárki',
    1: 'Körvez',
    2: 'Reszortvez',
    3: 'RVT',
    4: 'RVT elnök',
    5: 'KB gazdaságis',
};

const help: Record<number, string> = {
    0: 'AZ egyesület bármelyik tagja',
    1: 'A pályázatot leadó kör vezetője és gazdaságisa',
    2: 'A pályázatot leadó kör reszortjának a vezetője és gazdaságisa',
    3: 'Az öszes reszortvezető és az RVT-s nevtanárok',
    4: 'Az RVT elnöke',
    5: 'A KB gazdaságisa',
};

const {data} = defineProps<{
    data: Record<number, Record<number, number>>
}>();
</script>
