/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoPointResponse {
  point: number;
  numberOfApplications: number;
}

export const VikoPointResponseSchema = {
  'point': {
    name: 'point',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'numberOfApplications': {
    name: 'numberOfApplications',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfVikoPointResponse(value: object): value is VikoPointResponse {
let isInstance = true;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "numberOfApplications" in value;

return isInstance;
}

export function VikoPointResponseFromJSON(json: any): VikoPointResponse {
return VikoPointResponseFromJSONTyped(json, false);
}

export function VikoPointResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoPointResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'point': json['point'],
          'numberOfApplications': json['numberOfApplications'],
    };
  }

  export function VikoPointResponseToJSON(value?: VikoPointResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'point': value.point,
                'numberOfApplications': value.numberOfApplications,
    };
  }

  export function VikoPointResponseCreateEmpty(): VikoPointResponse {
  return {
      'point': 0,
      'numberOfApplications': 0,
  };
  }

