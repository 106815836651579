<template>
    <PegasusModal header="Átadás bejegyzés">
        <PegasusForm ref="form">
            <PegasusForm part="data">
                <GroupSelectorField
                    v-model="transfer.to"
                    :schema="InventoryUpdateTransferSchema.to"
                ></GroupSelectorField>
            </PegasusForm>

            <TextareaField
                v-model="transfer.comment"
                :schema="InventoryItemUpdateSchema.comment"
            ></TextareaField>

            <small class="d-block my-3">
                <i class="fas fa-info-circle text-primary"></i>
                Az átadást a fogadó kör leltár felelősének jóvá kell hagynia mielőtt végbe megy.
            </small>

            <PegasusButton type="submit" variant="primary" @click="save">
                Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {ref} from "vue";
import toast from "@Utils/toast";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import GroupSelectorField from "@Components/base/form/types/custom/GroupSelectorField.vue";
import {Transfer} from "@Models/inventory/updates/Transfer";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import type {FormRef} from '@/vue';
import {InventoryItemUpdateSchema, InventoryUpdateTransferSchema} from '@/api/models';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';

const {item} = defineProps<{
    item: Item
}>();

const transfer = ref(new Transfer());
transfer.value.from = item.responsible;

const form = ref<FormRef>(null);

const returnValue = useReturn();

function save() {
    let request = transfer.value.store(item.id, form.value).then(() => {
        item.updates.push(transfer.value);

        returnValue(true);
    });

    toast.loading(request);
}
</script>
