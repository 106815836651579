import {Base} from "@Models/Base";
import {Tender} from "@Models/viko/Tender";
import {VikoApi} from "@/api/apis";
import type {VikoCalcResponse, VikoCalcUpdate, VikoPointResponse} from "@/api/models";
import {map} from "lodash-es";

export class CalcSummary extends Base {
    tender: Tender;

    points: VikoPointResponse[];

    canEdit: boolean;

    static get(tenderId: number): Promise<CalcSummary> {
        return VikoApi.summaryCalc(tenderId).then(response => {
            return CalcSummary.newSingle(response.data, CalcSummary.parseResponse);
        });
    }

    static parseResponse(summary: CalcSummary, data: VikoCalcResponse): CalcSummary {
        summary.tender = Tender.newSingle(data.tender, Tender.parseExtendedResponse);
        summary.points = data.points;
        summary.canEdit = data.canEdit;

        return summary;
    }

    public update(): Promise<CalcSummary> {
        const data: VikoCalcUpdate = {
            budget: this.tender.budget ?? 0,
            categories: map(this.tender.resultCategories, category => {
                return {
                    boundary: category.boundary,
                    money: category.money,
                    resultCategory: category.id,
                };
            }),
        };

        return VikoApi.summaryCalcUpdate(this.tender.id, data).then(response => {
            return CalcSummary.parseResponse(this, response.data);
        });
    }
}
