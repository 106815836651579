/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseAuthschEntrantType } from './BaseAuthschEntrantType';
    import {
    BaseAuthschEntrantTypeFromJSON,
    BaseAuthschEntrantTypeFromJSONTyped,
    BaseAuthschEntrantTypeToJSON,
    BaseAuthschEntrantTypeCreateEmpty,
    } from './BaseAuthschEntrantType';
import {DateTime} from '@Utils/DateTime';
import { BaseAuthschEntrantTypeValues } from './BaseAuthschEntrantType';

export interface AuthAuthschEntrant {
  groupId: number;
  groupName: string;
  entrantType: BaseAuthschEntrantType;
}

export const AuthAuthschEntrantSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: 'Kör azonosítója',
    type: 'number',
      minimum: 1,
  },
  'groupName': {
    name: 'groupName',
    required: true,
    nullable: false,
    label: 'Kör neve',
    type: 'string',
      minLength: 2,
      maxLength: 250,
  },
  'entrantType': {
    name: 'entrantType',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Belépő típusa",
        enumValues: BaseAuthschEntrantTypeValues,
  },
}
export function instanceOfAuthAuthschEntrant(value: object): value is AuthAuthschEntrant {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "groupName" in value;
    isInstance = isInstance && "entrantType" in value;

return isInstance;
}

export function AuthAuthschEntrantFromJSON(json: any): AuthAuthschEntrant {
return AuthAuthschEntrantFromJSONTyped(json, false);
}

export function AuthAuthschEntrantFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthAuthschEntrant {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
          'groupName': json['groupName'],
              'entrantType': BaseAuthschEntrantTypeFromJSON(json['entrantType']),
    };
  }

  export function AuthAuthschEntrantToJSON(value?: AuthAuthschEntrant | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
                'groupName': value.groupName,
                'entrantType': BaseAuthschEntrantTypeToJSON(value.entrantType),
    };
  }

  export function AuthAuthschEntrantCreateEmpty(): AuthAuthschEntrant {
  return {
      'groupId': 0,
      'groupName': '',
          'entrantType': BaseAuthschEntrantTypeCreateEmpty(),
  };
  }

