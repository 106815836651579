/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoReviewStore {
  application: number;
  point: number;
  comment: string;
}

export const VikoReviewStoreSchema = {
  'application': {
    name: 'application',
    required: true,
    nullable: false,
    label: 'Pályázat',
    type: 'number',
      minimum: 0,
  },
  'point': {
    name: 'point',
    required: true,
    nullable: false,
    label: 'Pontszám',
    type: 'number',
      minimum: 1,
      maximum: 5,
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Komment',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfVikoReviewStore(value: object): value is VikoReviewStore {
let isInstance = true;
    isInstance = isInstance && "application" in value;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function VikoReviewStoreFromJSON(json: any): VikoReviewStore {
return VikoReviewStoreFromJSONTyped(json, false);
}

export function VikoReviewStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReviewStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'application': json['application'],
          'point': json['point'],
          'comment': json['comment'],
    };
  }

  export function VikoReviewStoreToJSON(value?: VikoReviewStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'application': value.application,
                'point': value.point,
                'comment': value.comment,
    };
  }

  export function VikoReviewStoreCreateEmpty(): VikoReviewStore {
  return {
      'application': 0,
      'point': 0,
      'comment': '',
  };
  }

