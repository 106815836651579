/* tslint:disable */
/* eslint-disable */

export enum BaseMembershipStatus {
    NEWBIE = 'newbie',
    MEMBER = 'member',
    OLD = 'old'
}

export const BaseMembershipStatusValues: Record<BaseMembershipStatus, string> = {
    'newbie': 'Újonc',
    'member': 'Tag',
    'old': 'Öregtag'
}


export function BaseMembershipStatusFromJSON(json: any): BaseMembershipStatus {
    return BaseMembershipStatusFromJSONTyped(json, false);
}

export function BaseMembershipStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseMembershipStatus {
    return json as BaseMembershipStatus;
}

export function BaseMembershipStatusToJSON(value?: BaseMembershipStatus | null): any {
    return value as any;
}

export function BaseMembershipStatusCreateEmpty(): BaseMembershipStatus {
    return BaseMembershipStatus.NEWBIE;
}

