/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseUserPaginationResponse {
  data: Array<BaseUserResponse>;
  total: number;
}

export const BaseUserPaginationResponseSchema = {
  'data': {
    name: 'data',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'total': {
    name: 'total',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfBaseUserPaginationResponse(value: object): value is BaseUserPaginationResponse {
let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "total" in value;

return isInstance;
}

export function BaseUserPaginationResponseFromJSON(json: any): BaseUserPaginationResponse {
return BaseUserPaginationResponseFromJSONTyped(json, false);
}

export function BaseUserPaginationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserPaginationResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'data': ((json['data'] as Array<any>).map(BaseUserResponseFromJSON)),
          'total': json['total'],
    };
  }

  export function BaseUserPaginationResponseToJSON(value?: BaseUserPaginationResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'data': ((value.data as Array<any>).map(BaseUserResponseToJSON)),
                'total': value.total,
    };
  }

  export function BaseUserPaginationResponseCreateEmpty(): BaseUserPaginationResponse {
  return {
      'data': [],
      'total': 0,
  };
  }

