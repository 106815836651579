/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoApplicationWithReviewsResponse } from './VikoApplicationWithReviewsResponse';
    import {
    VikoApplicationWithReviewsResponseFromJSON,
    VikoApplicationWithReviewsResponseFromJSONTyped,
    VikoApplicationWithReviewsResponseToJSON,
    VikoApplicationWithReviewsResponseCreateEmpty,
    } from './VikoApplicationWithReviewsResponse';
    import type { VikoExtendedTenderResponse } from './VikoExtendedTenderResponse';
    import {
    VikoExtendedTenderResponseFromJSON,
    VikoExtendedTenderResponseFromJSONTyped,
    VikoExtendedTenderResponseToJSON,
    VikoExtendedTenderResponseCreateEmpty,
    } from './VikoExtendedTenderResponse';
    import type { VikoReviewerGroupResponse } from './VikoReviewerGroupResponse';
    import {
    VikoReviewerGroupResponseFromJSON,
    VikoReviewerGroupResponseFromJSONTyped,
    VikoReviewerGroupResponseToJSON,
    VikoReviewerGroupResponseCreateEmpty,
    } from './VikoReviewerGroupResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoSummaryResponse {
  tender: VikoExtendedTenderResponse;
  userReviewerGroupId: number | null;
  moderator: boolean;
  reviewerGroups: Array<VikoReviewerGroupResponse>;
  applications: Array<VikoApplicationWithReviewsResponse>;
  canEdit: boolean;
}

export const VikoSummaryResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'userReviewerGroupId': {
    name: 'userReviewerGroupId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'moderator': {
    name: 'moderator',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'reviewerGroups': {
    name: 'reviewerGroups',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'applications': {
    name: 'applications',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfVikoSummaryResponse(value: object): value is VikoSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "userReviewerGroupId" in value;
    isInstance = isInstance && "moderator" in value;
    isInstance = isInstance && "reviewerGroups" in value;
    isInstance = isInstance && "applications" in value;
    isInstance = isInstance && "canEdit" in value;

return isInstance;
}

export function VikoSummaryResponseFromJSON(json: any): VikoSummaryResponse {
return VikoSummaryResponseFromJSONTyped(json, false);
}

export function VikoSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': VikoExtendedTenderResponseFromJSON(json['tender']),
          'userReviewerGroupId': json['userReviewerGroupId'],
          'moderator': json['moderator'],
            'reviewerGroups': ((json['reviewerGroups'] as Array<any>).map(VikoReviewerGroupResponseFromJSON)),
            'applications': ((json['applications'] as Array<any>).map(VikoApplicationWithReviewsResponseFromJSON)),
          'canEdit': json['canEdit'],
    };
  }

  export function VikoSummaryResponseToJSON(value?: VikoSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': VikoExtendedTenderResponseToJSON(value.tender),
                'userReviewerGroupId': value.userReviewerGroupId,
                'moderator': value.moderator,
              'reviewerGroups': ((value.reviewerGroups as Array<any>).map(VikoReviewerGroupResponseToJSON)),
              'applications': ((value.applications as Array<any>).map(VikoApplicationWithReviewsResponseToJSON)),
                'canEdit': value.canEdit,
    };
  }

  export function VikoSummaryResponseCreateEmpty(): VikoSummaryResponse {
  return {
          'tender': VikoExtendedTenderResponseCreateEmpty(),
      'userReviewerGroupId': 0,
      'moderator': false,
      'reviewerGroups': [],
      'applications': [],
      'canEdit': false,
  };
  }

