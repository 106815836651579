/* tslint:disable */
/* eslint-disable */

export enum BaseGlobalPermission {
    USERS = 'USERS',
    USERS_IMPERSONATE = 'USERS_IMPERSONATE',
    GROUPS = 'GROUPS',
    GROUPS_CAPABILITIES = 'GROUPS_CAPABILITIES',
    KPR = 'KPR',
    KPR_READ = 'KPR_READ',
    KPR_DECIDE = 'KPR_DECIDE',
    KPR_TENDER = 'KPR_TENDER',
    BUDGET_READ = 'BUDGET_READ',
    BUDGET_EDIT = 'BUDGET_EDIT',
    MONEY = 'MONEY',
    REQUESTS = 'REQUESTS',
    REQUESTS_DECIDE = 'REQUESTS_DECIDE',
    RVT = 'RVT',
    EVENTS_SUBMIT = 'EVENTS_SUBMIT',
    EVENTS_VIEW = 'EVENTS_VIEW',
    EVENTS_APPROVE = 'EVENTS_APPROVE',
    EVENTS_MODIFY = 'EVENTS_MODIFY',
    EVENTS_CONFIG = 'EVENTS_CONFIG',
    EVENTS_MANAGERS = 'EVENTS_MANAGERS',
    EVENTS_PLANNING = 'EVENTS_PLANNING',
    INVENTORY_PUBLIC = 'INVENTORY_PUBLIC',
    INVENTORY_READ = 'INVENTORY_READ',
    INVENTORY_EDIT = 'INVENTORY_EDIT',
    INVENTORY_ADMIN = 'INVENTORY_ADMIN',
    VIKO = 'VIKO',
    VIKO_TENDER = 'VIKO_TENDER',
    MENTORS = 'MENTORS',
    MENTORS_EDIT = 'MENTORS_EDIT',
    ADMIN_ACL = 'ADMIN_ACL'
}

export const BaseGlobalPermissionValues: Record<BaseGlobalPermission, string> = {
    'USERS': 'Felhasználók adatait tudja megtekinteni',
    'USERS_IMPERSONATE': 'Felhasználók nevében tud bejelentkezni',
    'GROUPS': 'Körök általános információnak megtekintése',
    'GROUPS_CAPABILITIES': 'Körök jogosultságainak szerkesztése',
    'KPR': 'KPR pályázatok olvasása',
    'KPR_READ': 'KPR pályázatok publikus olvasása',
    'KPR_DECIDE': 'KPR pályázatok bírálása',
    'KPR_TENDER': 'KPR pályázati kiírások menedzselése',
    'BUDGET_READ': 'Házszintű költségvetés olvasása',
    'BUDGET_EDIT': 'Házszintű költségvetés szerkesztése',
    'MONEY': 'Körök gazdalkodásának követése',
    'REQUESTS': 'RVT-hez benyújtott kérvények olvasása',
    'REQUESTS_DECIDE': 'RVT-hez benyújtott kérvények eldöntése',
    'RVT': 'Általános RVT-s dolgok',
    'EVENTS_SUBMIT': 'Rendezvénybejelentésre jogosult',
    'EVENTS_VIEW': 'Rendezvények részletes adatinak megtekintése',
    'EVENTS_APPROVE': 'Rendezvények jóváhagyása',
    'EVENTS_MODIFY': 'Rendezvények módosítása',
    'EVENTS_CONFIG': 'Rendezvényszerevezési beállítások módosítása',
    'EVENTS_MANAGERS': 'Hetifőnöki beosztás menedzselése',
    'EVENTS_PLANNING': 'Nagyteremosztóban részt vehet',
    'INVENTORY_PUBLIC': 'A leltár publikus részeit látja',
    'INVENTORY_READ': 'A leltárban lévő elemeket látja',
    'INVENTORY_EDIT': 'A leltárban lévő elemeket tudja szerkeszteni',
    'INVENTORY_ADMIN': 'A leltár teljes adminisztrációját végzi',
    'VIKO': 'VIKÖ pályázás és korábbi évek pályázatainak böngészése',
    'VIKO_TENDER': 'VIKÖ pályázati kiírások menedzselése',
    'MENTORS': 'Mentori általános dolgok',
    'MENTORS_EDIT': 'Mentorok és beosztásaik szerkesztése',
    'ADMIN_ACL': 'A felhasználók és jogosultságaik menedzselése'
}


export function BaseGlobalPermissionFromJSON(json: any): BaseGlobalPermission {
    return BaseGlobalPermissionFromJSONTyped(json, false);
}

export function BaseGlobalPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGlobalPermission {
    return json as BaseGlobalPermission;
}

export function BaseGlobalPermissionToJSON(value?: BaseGlobalPermission | null): any {
    return value as any;
}

export function BaseGlobalPermissionCreateEmpty(): BaseGlobalPermission {
    return BaseGlobalPermission.USERS;
}

