/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupSummaryPeriodResponse } from './BaseGroupSummaryPeriodResponse';
    import {
    BaseGroupSummaryPeriodResponseFromJSON,
    BaseGroupSummaryPeriodResponseFromJSONTyped,
    BaseGroupSummaryPeriodResponseToJSON,
    BaseGroupSummaryPeriodResponseCreateEmpty,
    } from './BaseGroupSummaryPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupArchiveResponse {
  periods: Array<BaseGroupSummaryPeriodResponse>;
}

export const BaseGroupArchiveResponseSchema = {
  'periods': {
    name: 'periods',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseGroupArchiveResponse(value: object): value is BaseGroupArchiveResponse {
let isInstance = true;
    isInstance = isInstance && "periods" in value;

return isInstance;
}

export function BaseGroupArchiveResponseFromJSON(json: any): BaseGroupArchiveResponse {
return BaseGroupArchiveResponseFromJSONTyped(json, false);
}

export function BaseGroupArchiveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupArchiveResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'periods': ((json['periods'] as Array<any>).map(BaseGroupSummaryPeriodResponseFromJSON)),
    };
  }

  export function BaseGroupArchiveResponseToJSON(value?: BaseGroupArchiveResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'periods': ((value.periods as Array<any>).map(BaseGroupSummaryPeriodResponseToJSON)),
    };
  }

  export function BaseGroupArchiveResponseCreateEmpty(): BaseGroupArchiveResponse {
  return {
      'periods': [],
  };
  }

