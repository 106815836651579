<template>
    <PegasusButton
        v-if="event.finalized && event.decision !== true && user.hasPermission(BaseGlobalPermission.EVENTS_APPROVE)"
        class="m-1"
        variant="success"
        @click="Dialog.open(ApproveDialog, {event})"
    >
        <i class="fas fa-gavel"></i> Jóváhagyás
    </PegasusButton>

    <PegasusButton
        v-if="event.finalized && user.hasPermission(BaseGlobalPermission.EVENTS_APPROVE) && event.decision !== false"
        class="m-1"
        variant="danger"
        @click="Dialog.open(RejectDialog, {event})"
    >
        <i class="fas fa-gavel"></i> Visszadobás
    </PegasusButton>

    <PegasusButton class="m-1" variant="primary" @click="Dialog.open(InternalCommentDialog, {event})">
        <i class="fas fa-comment"></i> Belső megjegyzés
    </PegasusButton>

    <hr v-if="event.decision || !isEmpty(downloadableServices) || !isEmpty(getDisruptingClosures())">

    <PegasusButton v-if="event.decision" class="m-1" variant="primary" @click="permit">
        <i class="fas fa-download"></i> Bejelentő letöltése
    </PegasusButton>

    <PegasusButton v-for="service in downloadableServices" :key="service.id" class="m-1" variant="primary" @click="exportServiceAsset(service)">
        <i class="fas fa-download"></i> {{ service.name }} letöltése
    </PegasusButton>

    <PegasusButton
        v-for="closure in getDisruptingClosures()"
        :key="closure.id"
        class="m-1"
        variant="primary"
        @click="closurePr(closure)">
        <i class="fas fa-download"></i>
        Lezáratás PR letöltése ({{ closure.type.name }})
    </PegasusButton>

    <dl v-if="event.responsibleNeptun">
        <dt>Főrendező neptun kód</dt>
        <dd>{{ event.responsibleNeptun }}</dd>
    </dl>

    <hr>

    <EventHistoryTab :event="event"></EventHistoryTab>
</template>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import {Closure} from "@Models/events/Closure";
import {computed} from "vue";
import toast from "@Utils/toast";
import {BaseGlobalPermission} from "@/api/models";
import {filter, isEmpty} from "lodash-es";
import type {Service} from "@Models/events/Service";
import EventHistoryTab from "@Components/events/tabs/EventHistoryTab.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {useAuthStore} from '@/stores/auth';
import {Dialog} from '@Utils/dialog';
import InternalCommentDialog from '@Components/events/dialogs/InternalCommentDialog.vue';
import ApproveDialog from '@Components/events/dialogs/ApproveDialog.vue';
import RejectDialog from '@Components/events/dialogs/RejectDialog.vue';

const {event} = defineProps<{
    event: Event,
}>();

const user = useAuthStore().requireUser();

const downloadableServices = computed(() => {
    return filter(event.services, service => service.type.hasAssets);
});

function getDisruptingClosures() {
    return filter(event.closures, closure => closure.disrupts);
}

function permit() {
    let request = event.getPermit().then(file => file.download());

    toast.loading(request, 'Generálás');
}

function closurePr(closure: Closure) {
    let request = closure.pr().then(file => file.download());

    toast.loading(request, 'Generálás');
}

function exportServiceAsset(service: Service) {
    let request = service.exportAsset().then(file => file.download());

    toast.loading(request, 'Generálás');
}
</script>
