/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsUpdateClosureType {
  name: string;
  description: string;
  price: number;
  isPublic: boolean;
}

export const EventsUpdateClosureTypeSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Megnevezés',
    type: 'string',
      maxLength: 200,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
      maxLength: 2000,
  },
  'price': {
    name: 'price',
    required: true,
    nullable: false,
    label: 'Óradíj',
    type: 'number',
      minimum: 0,
      maximum: 100000,
  },
  'isPublic': {
    name: 'isPublic',
    required: true,
    nullable: false,
    label: 'Publikus',
    type: 'boolean',
  },
}
export function instanceOfEventsUpdateClosureType(value: object): value is EventsUpdateClosureType {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "isPublic" in value;

return isInstance;
}

export function EventsUpdateClosureTypeFromJSON(json: any): EventsUpdateClosureType {
return EventsUpdateClosureTypeFromJSONTyped(json, false);
}

export function EventsUpdateClosureTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsUpdateClosureType {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
          'price': json['price'],
          'isPublic': json['isPublic'],
    };
  }

  export function EventsUpdateClosureTypeToJSON(value?: EventsUpdateClosureType | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
                'price': value.price,
                'isPublic': value.isPublic,
    };
  }

  export function EventsUpdateClosureTypeCreateEmpty(): EventsUpdateClosureType {
  return {
      'name': '',
      'description': '',
      'price': 0,
      'isPublic': false,
  };
  }

