/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsBilling } from './EventsBilling';
    import {
    EventsBillingFromJSON,
    EventsBillingFromJSONTyped,
    EventsBillingToJSON,
    EventsBillingCreateEmpty,
    } from './EventsBilling';
import {DateTime} from '@Utils/DateTime';

export interface EventsDownloadReport {
  events: { [key: string]: EventsBilling; };
}

export const EventsDownloadReportSchema = {
  'events': {
    name: 'events',
    required: true,
    nullable: false,
    label: 'Esemény',
  },
}
export function instanceOfEventsDownloadReport(value: object): value is EventsDownloadReport {
let isInstance = true;
    isInstance = isInstance && "events" in value;

return isInstance;
}

export function EventsDownloadReportFromJSON(json: any): EventsDownloadReport {
return EventsDownloadReportFromJSONTyped(json, false);
}

export function EventsDownloadReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsDownloadReport {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'events': (mapValues(json['events'], EventsBillingFromJSON)),
    };
  }

  export function EventsDownloadReportToJSON(value?: EventsDownloadReport | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'events': (mapValues(value.events, EventsBillingToJSON)),
    };
  }

  export function EventsDownloadReportCreateEmpty(): EventsDownloadReport {
  return {
      'events': {},
  };
  }

