<template>
    <PegasusModal :header="document.name">
        <TextField
            v-model="document.name"
            :schema="InventoryDocumentStoreRequestSchema.name"
            disabled
        />

        <div v-if="document.fileType === 'pdf'" style="height: 400px">
            <embed :src="link" class="w-100" style="height: 100%"/>
        </div>

        <PegasusImage v-if="document.fileType === 'image'" :src="link" image-class="img-fluid" preview></PegasusImage>

        <span v-if="document.fileType === 'xls'">
            <PegasusButton @click="download">
                <i class="fa fa-download"></i> Letöltés
            </PegasusButton>
        </span>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {InventoryDocumentStoreRequestSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusImage from '@Components/base/PegasusImage.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import type {Document} from '@Models/inventory/Document';
import toast from '@Utils/toast';
import {currentDomain} from '@/router/domains';
import {computed} from 'vue';

const {document} = defineProps<{
    document: Document
}>();

const link = computed(() => 'https://' + currentDomain() + '/api/inventory/documents/' + document.id + '/download');

function download() {
    const request = document.download().then(file => file.download());

    toast.loading(request, 'Letöltés');
}
</script>
