import type {EventsReservationPeriodSummaryResponse} from "@/api/api";
import {EventsApi} from "@/api/api";
import {ReservationPeriod} from '@Models/events/ReservationPeriod';
import {Base} from '@Models/Base';

export class ReservationPeriodSummary extends Base {
    period: ReservationPeriod;

    reservationCount: number;

    static getById(id: number): Promise<ReservationPeriodSummary> {
        return EventsApi.reservationPeriodsShow(id).then(response => {
            return ReservationPeriodSummary.newSingle(response.data, ReservationPeriodSummary.parseResponse);
        });
    }

    static parseResponse(summary: ReservationPeriodSummary, data: EventsReservationPeriodSummaryResponse): ReservationPeriodSummary {
        summary.period = ReservationPeriod.newSingle(data.period, ReservationPeriod.parseResponse);
        summary.reservationCount = data.reservationCount;

        return summary;
    }
}
