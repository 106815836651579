/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetItemUpdate {
  name: string;
  description: string;
  number: number;
  group: number;
}

export const MoneyBudgetItemUpdateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 5,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
      minLength: 0,
      maxLength: 3000,
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Sorszám',
    type: 'number',
      minimum: 1,
      maximum: 100,
  },
  'group': {
    name: 'group',
    required: true,
    nullable: false,
    label: 'Kör',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfMoneyBudgetItemUpdate(value: object): value is MoneyBudgetItemUpdate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "group" in value;

return isInstance;
}

export function MoneyBudgetItemUpdateFromJSON(json: any): MoneyBudgetItemUpdate {
return MoneyBudgetItemUpdateFromJSONTyped(json, false);
}

export function MoneyBudgetItemUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetItemUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
          'number': json['number'],
          'group': json['group'],
    };
  }

  export function MoneyBudgetItemUpdateToJSON(value?: MoneyBudgetItemUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
                'number': value.number,
                'group': value.group,
    };
  }

  export function MoneyBudgetItemUpdateCreateEmpty(): MoneyBudgetItemUpdate {
  return {
      'name': '',
      'description': '',
      'number': 0,
      'group': 0,
  };
  }

