<template>
    <PegasusHeading>Schönherz Leltár - {{ tag.name }}</PegasusHeading>

    <PegasusTable :data="items" :per-page="10" search>
        <Column field="idAsText" header="Szám"></Column>

        <Column field="name" header="Név"></Column>

        <Column field="count" header="Darabszám"></Column>

        <Column header="Műveletek">
            <template #body="{data}">
                <PegasusButton :params="{code: data.idAsText}" to="inventory.items.default" variant="primary">
                    Megnyitás
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>

    <PegasusButton to="inventory.admin.index" variant="primary">
        Vissza az admin oldalra
    </PegasusButton>
</template>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import {ref} from 'vue';
import {Item} from '@Models/inventory/Item';
import {Tag} from '@Models/inventory/Tag';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import Column from 'primevue/column';

const {tagId} = defineProps<{
    tagId: string
}>();

useAuthStore().requireUser();

const tag = Tag.getBySingleIdentifier(tagId);

const items = ref(await Item.getByTag(tag.id));
</script>
