/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyInventoryType } from './MoneyInventoryType';
    import {
    MoneyInventoryTypeFromJSON,
    MoneyInventoryTypeFromJSONTyped,
    MoneyInventoryTypeToJSON,
    MoneyInventoryTypeCreateEmpty,
    } from './MoneyInventoryType';
    import type { MoneyItemAssetResponse } from './MoneyItemAssetResponse';
    import {
    MoneyItemAssetResponseFromJSON,
    MoneyItemAssetResponseFromJSONTyped,
    MoneyItemAssetResponseToJSON,
    MoneyItemAssetResponseCreateEmpty,
    } from './MoneyItemAssetResponse';
    import type { MoneyItemCommentResponse } from './MoneyItemCommentResponse';
    import {
    MoneyItemCommentResponseFromJSON,
    MoneyItemCommentResponseFromJSONTyped,
    MoneyItemCommentResponseToJSON,
    MoneyItemCommentResponseCreateEmpty,
    } from './MoneyItemCommentResponse';
import {DateTime} from '@Utils/DateTime';
import { MoneyInventoryTypeValues } from './MoneyInventoryType';

export interface MoneyItemResponse {
  inventoryType: MoneyInventoryType | null;
  offer: string;
  amended: boolean;
  flags: Array<number>;
  campNumber: number | null;
  assets: Array<MoneyItemAssetResponse>;
  comments: Array<MoneyItemCommentResponse>;
  id: number;
  name: string;
  description: string;
  price: number;
  contribution: number | null;
  approvedPrice: number | null;
  finalPrice: number | null;
  type: string | null;
  applicationId: number | null;
}

export const MoneyItemResponseSchema = {
  'inventoryType': {
    name: 'inventoryType',
    required: true,
    nullable: true,
      type: 'embedded',
        label: "",
        enumValues: MoneyInventoryTypeValues,
  },
  'offer': {
    name: 'offer',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'amended': {
    name: 'amended',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'flags': {
    name: 'flags',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'campNumber': {
    name: 'campNumber',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
      maximum: 1000,
  },
  'assets': {
    name: 'assets',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'comments': {
    name: 'comments',
    required: true,
    nullable: false,
    label: 'Megjegyzések',
      type: 'array',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'price': {
    name: 'price',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'contribution': {
    name: 'contribution',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
  'approvedPrice': {
    name: 'approvedPrice',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
  'finalPrice': {
    name: 'finalPrice',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'applicationId': {
    name: 'applicationId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
}
export function instanceOfMoneyItemResponse(value: object): value is MoneyItemResponse {
let isInstance = true;
    isInstance = isInstance && "inventoryType" in value;
    isInstance = isInstance && "offer" in value;
    isInstance = isInstance && "amended" in value;
    isInstance = isInstance && "flags" in value;
    isInstance = isInstance && "campNumber" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "comments" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "contribution" in value;
    isInstance = isInstance && "approvedPrice" in value;
    isInstance = isInstance && "finalPrice" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "applicationId" in value;

return isInstance;
}

export function MoneyItemResponseFromJSON(json: any): MoneyItemResponse {
return MoneyItemResponseFromJSONTyped(json, false);
}

export function MoneyItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'inventoryType': MoneyInventoryTypeFromJSON(json['inventoryType']),
          'offer': json['offer'],
          'amended': json['amended'],
          'flags': json['flags'],
          'campNumber': json['campNumber'],
            'assets': ((json['assets'] as Array<any>).map(MoneyItemAssetResponseFromJSON)),
            'comments': ((json['comments'] as Array<any>).map(MoneyItemCommentResponseFromJSON)),
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'price': json['price'],
          'contribution': json['contribution'],
          'approvedPrice': json['approvedPrice'],
          'finalPrice': json['finalPrice'],
          'type': json['type'],
          'applicationId': json['applicationId'],
    };
  }

  export function MoneyItemResponseToJSON(value?: MoneyItemResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'inventoryType': MoneyInventoryTypeToJSON(value.inventoryType),
                'offer': value.offer,
                'amended': value.amended,
            'flags': value.flags,
                'campNumber': value.campNumber,
              'assets': ((value.assets as Array<any>).map(MoneyItemAssetResponseToJSON)),
              'comments': ((value.comments as Array<any>).map(MoneyItemCommentResponseToJSON)),
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'price': value.price,
                'contribution': value.contribution,
                'approvedPrice': value.approvedPrice,
                'finalPrice': value.finalPrice,
                'type': value.type,
                'applicationId': value.applicationId,
    };
  }

  export function MoneyItemResponseCreateEmpty(): MoneyItemResponse {
  return {
          'inventoryType': MoneyInventoryTypeCreateEmpty(),
      'offer': '',
      'amended': false,
      'flags': [],
      'campNumber': 0,
      'assets': [],
      'comments': [],
      'id': 0,
      'name': '',
      'description': '',
      'price': 0,
      'contribution': 0,
      'approvedPrice': 0,
      'finalPrice': 0,
      'type': '',
      'applicationId': 0,
  };
  }

