/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupSpecificPermission } from './BaseGroupSpecificPermission';
    import {
    BaseGroupSpecificPermissionFromJSON,
    BaseGroupSpecificPermissionFromJSONTyped,
    BaseGroupSpecificPermissionToJSON,
    BaseGroupSpecificPermissionCreateEmpty,
    } from './BaseGroupSpecificPermission';
import {DateTime} from '@Utils/DateTime';
import { BaseGroupSpecificPermissionValues } from './BaseGroupSpecificPermission';

export interface BaseGroupPermissionResponse {
  id: BaseGroupSpecificPermission;
  name: string;
  description: string;
  canBeAssigned: boolean;
}

export const BaseGroupPermissionResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: BaseGroupSpecificPermissionValues,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'canBeAssigned': {
    name: 'canBeAssigned',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfBaseGroupPermissionResponse(value: object): value is BaseGroupPermissionResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "canBeAssigned" in value;

return isInstance;
}

export function BaseGroupPermissionResponseFromJSON(json: any): BaseGroupPermissionResponse {
return BaseGroupPermissionResponseFromJSONTyped(json, false);
}

export function BaseGroupPermissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupPermissionResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'id': BaseGroupSpecificPermissionFromJSON(json['id']),
          'name': json['name'],
          'description': json['description'],
          'canBeAssigned': json['canBeAssigned'],
    };
  }

  export function BaseGroupPermissionResponseToJSON(value?: BaseGroupPermissionResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': BaseGroupSpecificPermissionToJSON(value.id),
                'name': value.name,
                'description': value.description,
                'canBeAssigned': value.canBeAssigned,
    };
  }

  export function BaseGroupPermissionResponseCreateEmpty(): BaseGroupPermissionResponse {
  return {
          'id': BaseGroupSpecificPermissionCreateEmpty(),
      'name': '',
      'description': '',
      'canBeAssigned': false,
  };
  }

