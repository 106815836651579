/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyItemCommentUpdate {
  text: string;
}

export const MoneyItemCommentUpdateSchema = {
  'text': {
    name: 'text',
    required: true,
    nullable: false,
    label: 'Megjegyzés szövege',
    type: 'string',
      maxLength: 3000,
  },
}
export function instanceOfMoneyItemCommentUpdate(value: object): value is MoneyItemCommentUpdate {
let isInstance = true;
    isInstance = isInstance && "text" in value;

return isInstance;
}

export function MoneyItemCommentUpdateFromJSON(json: any): MoneyItemCommentUpdate {
return MoneyItemCommentUpdateFromJSONTyped(json, false);
}

export function MoneyItemCommentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemCommentUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'text': json['text'],
    };
  }

  export function MoneyItemCommentUpdateToJSON(value?: MoneyItemCommentUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'text': value.text,
    };
  }

  export function MoneyItemCommentUpdateCreateEmpty(): MoneyItemCommentUpdate {
  return {
      'text': '',
  };
  }

