<template>
    <PegasusHeading>{{ summary.closureType.name }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Adatok">
                <PegasusForm ref="form">
                    <TextField
                        v-model="summary.closureType.name"
                        :schema="EventsUpdateClosureTypeSchema.name"
                    ></TextField>

                    <TextareaField
                        v-model="summary.closureType.description"
                        :schema="EventsUpdateClosureTypeSchema.description"
                    ></TextareaField>

                    <NumberField
                        v-model="summary.closureType.price"
                        :schema="EventsUpdateClosureTypeSchema.price"
                        number-suffix="HUF"
                    ></NumberField>

                    <SwitchField
                        v-model="summary.closureType.public"
                        :schema="EventsUpdateClosureTypeSchema.isPublic"
                        inline
                    ></SwitchField>

                    <PegasusButton variant="success" @click="update">
                        <i class="fa fa-save"></i> Mentés
                    </PegasusButton>
                </PegasusForm>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Információk">
                <dl>
                    <dt>Lezáratások száma</dt>
                    <dd>{{ summary.closuresCount }}</dd>
                </dl>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Rendezvények admin</title>

<script lang="ts" setup>
import {ref} from "vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ClosureTypeSummary} from '@Models/events/ClosureTypeSummary';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';
import TextField from '@Components/base/form/types/TextField.vue';
import {EventsUpdateClosureTypeSchema} from '@/api/models';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import NumberField from '@Components/base/form/types/NumberField.vue';
import SwitchField from '@Components/base/form/types/SwitchField.vue';

useAuthStore().requireUser();

const {closureTypeId} = defineProps<{
    closureTypeId: number
}>();

const summary = ref(await ClosureTypeSummary.getById(closureTypeId));

const form = ref<FormRef>(null);

async function update() {
    if (!await toast.confirm('Biztos vagy benne, hogy módosítod a lezáratást?')) {
        return;
    }

    const request = summary.value.closureType.update(form.value);

    toast.loading(request);
}
</script>
