import {BaseFile} from "@Models/BaseFile";

export class File {
    data: Blob;

    name: string;

    static createFromDomFile(original: any) {
        const file = new File();

        file.data = original;
        file.name = original.name;

        return file;
    }

    static createFromResponse(response: {data: Blob, raw: Response}): File {
        const file = new File();

        file.data = response.data;
        file.name = response.raw.headers.get('x-download-file') ?? 'unknown';

        return file;
    }

    static createFromBlob(blob: Blob, name: string): File {
        const file = new File();

        file.data = blob;
        file.name = name;

        return file;
    }

    static createFromDataUri(data: string, name?: string): File {
        const [head, base64] = data.split(',');

        const mime = head.substring(5).split(';')[0];

        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const file = new File();

        file.data = new Blob(byteArrays, {type: mime});
        file.name = name ?? 'unknown';

        return file;
    }

    public getAsDataUrl(): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result === null) {
                    return resolve('');
                }
                if (typeof reader.result === 'string') {
                    return resolve(reader.result);
                }
                if (typeof reader.result === 'object') {
                    return resolve(new TextDecoder().decode(reader.result));
                }
            };

            reader.readAsDataURL(this.data);
        });
    }

    public getAsDomFile(): BaseFile {
        return new BaseFile([this.data], this.name);
    }

    public download() {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(this.data);
        link.download = this.name;
        link.click();
        URL.revokeObjectURL(link.href);
    }
}
