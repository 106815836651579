/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoReportUpdate {
  text: string;
}

export const VikoReportUpdateSchema = {
  'text': {
    name: 'text',
    required: true,
    nullable: false,
    label: 'Beszámoló szövege',
    type: 'string',
      maxLength: 3000,
  },
}
export function instanceOfVikoReportUpdate(value: object): value is VikoReportUpdate {
let isInstance = true;
    isInstance = isInstance && "text" in value;

return isInstance;
}

export function VikoReportUpdateFromJSON(json: any): VikoReportUpdate {
return VikoReportUpdateFromJSONTyped(json, false);
}

export function VikoReportUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReportUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'text': json['text'],
    };
  }

  export function VikoReportUpdateToJSON(value?: VikoReportUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'text': value.text,
    };
  }

  export function VikoReportUpdateCreateEmpty(): VikoReportUpdate {
  return {
      'text': '',
  };
  }

