/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { VikoResultCategoryResponse } from './VikoResultCategoryResponse';
    import {
    VikoResultCategoryResponseFromJSON,
    VikoResultCategoryResponseFromJSONTyped,
    VikoResultCategoryResponseToJSON,
    VikoResultCategoryResponseCreateEmpty,
    } from './VikoResultCategoryResponse';
    import type { VikoReviewerGroupResponse } from './VikoReviewerGroupResponse';
    import {
    VikoReviewerGroupResponseFromJSON,
    VikoReviewerGroupResponseFromJSONTyped,
    VikoReviewerGroupResponseToJSON,
    VikoReviewerGroupResponseCreateEmpty,
    } from './VikoReviewerGroupResponse';
    import type { VikoTenderStatus } from './VikoTenderStatus';
    import {
    VikoTenderStatusFromJSON,
    VikoTenderStatusFromJSONTyped,
    VikoTenderStatusToJSON,
    VikoTenderStatusCreateEmpty,
    } from './VikoTenderStatus';
import {DateTime} from '@Utils/DateTime';
import { VikoTenderStatusValues } from './VikoTenderStatus';

export interface VikoExtendedTenderResponse {
  budget: number | null;
  reviewerGroups: Array<VikoReviewerGroupResponse>;
  moderators: Array<BaseMinimalUserResponse>;
  resultCategories: Array<VikoResultCategoryResponse>;
  id: number;
  year: number;
  semester: number;
  begin: DateTime;
  end: DateTime;
  beginDrafting: DateTime;
  endDrafting: DateTime;
  endAccepting: DateTime;
  status: VikoTenderStatus;
  isPublic: boolean;
  displayName: string;
}

export const VikoExtendedTenderResponseSchema = {
  'budget': {
    name: 'budget',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'reviewerGroups': {
    name: 'reviewerGroups',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'moderators': {
    name: 'moderators',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'resultCategories': {
    name: 'resultCategories',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'year': {
    name: 'year',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'semester': {
    name: 'semester',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'begin': {
    name: 'begin',
    required: true,
    nullable: false,
    label: '',
    type: 'date',
  },
  'end': {
    name: 'end',
    required: true,
    nullable: false,
    label: '',
    type: 'date',
  },
  'beginDrafting': {
    name: 'beginDrafting',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'endDrafting': {
    name: 'endDrafting',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'endAccepting': {
    name: 'endAccepting',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: VikoTenderStatusValues,
  },
  'isPublic': {
    name: 'isPublic',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'displayName': {
    name: 'displayName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfVikoExtendedTenderResponse(value: object): value is VikoExtendedTenderResponse {
let isInstance = true;
    isInstance = isInstance && "budget" in value;
    isInstance = isInstance && "reviewerGroups" in value;
    isInstance = isInstance && "moderators" in value;
    isInstance = isInstance && "resultCategories" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "semester" in value;
    isInstance = isInstance && "begin" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "beginDrafting" in value;
    isInstance = isInstance && "endDrafting" in value;
    isInstance = isInstance && "endAccepting" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "isPublic" in value;
    isInstance = isInstance && "displayName" in value;

return isInstance;
}

export function VikoExtendedTenderResponseFromJSON(json: any): VikoExtendedTenderResponse {
return VikoExtendedTenderResponseFromJSONTyped(json, false);
}

export function VikoExtendedTenderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoExtendedTenderResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'budget': json['budget'],
            'reviewerGroups': ((json['reviewerGroups'] as Array<any>).map(VikoReviewerGroupResponseFromJSON)),
            'moderators': ((json['moderators'] as Array<any>).map(BaseMinimalUserResponseFromJSON)),
            'resultCategories': ((json['resultCategories'] as Array<any>).map(VikoResultCategoryResponseFromJSON)),
          'id': json['id'],
          'year': json['year'],
          'semester': json['semester'],
        'begin': (DateTime.fromISO(json['begin'])),
        'end': (DateTime.fromISO(json['end'])),
        'beginDrafting': (DateTime.fromISO(json['beginDrafting'])),
        'endDrafting': (DateTime.fromISO(json['endDrafting'])),
        'endAccepting': (DateTime.fromISO(json['endAccepting'])),
              'status': VikoTenderStatusFromJSON(json['status']),
          'isPublic': json['isPublic'],
          'displayName': json['displayName'],
    };
  }

  export function VikoExtendedTenderResponseToJSON(value?: VikoExtendedTenderResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'budget': value.budget,
              'reviewerGroups': ((value.reviewerGroups as Array<any>).map(VikoReviewerGroupResponseToJSON)),
              'moderators': ((value.moderators as Array<any>).map(BaseMinimalUserResponseToJSON)),
              'resultCategories': ((value.resultCategories as Array<any>).map(VikoResultCategoryResponseToJSON)),
                'id': value.id,
                'year': value.year,
                'semester': value.semester,
            'begin': (value.begin.toISODate()),
            'end': (value.end.toISODate()),
            'beginDrafting': (value.beginDrafting.toISO()),
            'endDrafting': (value.endDrafting.toISO()),
            'endAccepting': (value.endAccepting.toISO()),
                'status': VikoTenderStatusToJSON(value.status),
                'isPublic': value.isPublic,
                'displayName': value.displayName,
    };
  }

  export function VikoExtendedTenderResponseCreateEmpty(): VikoExtendedTenderResponse {
  return {
      'budget': 0,
      'reviewerGroups': [],
      'moderators': [],
      'resultCategories': [],
      'id': 0,
      'year': 0,
      'semester': 0,
      'begin': DateTime.now(),
      'end': DateTime.now(),
      'beginDrafting': DateTime.now(),
      'endDrafting': DateTime.now(),
      'endAccepting': DateTime.now(),
          'status': VikoTenderStatusCreateEmpty(),
      'isPublic': false,
      'displayName': '',
  };
  }

