/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsReservationUpdate {
  name: string;
  locations: Array<number>;
  group: number;
  start: DateTime;
  finish: DateTime;
  priority: boolean;
}

export const EventsReservationUpdateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 3,
      maxLength: 200,
  },
  'locations': {
    name: 'locations',
    required: true,
    nullable: false,
    label: 'Helyszínek',
    type: 'array',
  },
  'group': {
    name: 'group',
    required: true,
    nullable: false,
    label: 'Rendező kör',
      labelHelp: `Azok a körök jelennek meg a listában amiknek PéK szerint tagja vagy és engedélyezve van számukra a rendezvény bejelentés. Amennyiben szerinted hiányzik kör aminek itt kellene lennie írj a support@rvt.sch.bme.hu címre.`,
    type: 'number',
      minimum: 0,
  },
  'start': {
    name: 'start',
    required: true,
    nullable: false,
    label: 'Foglalás kezdete',
    type: 'datetime',
  },
  'finish': {
    name: 'finish',
    required: true,
    nullable: false,
    label: 'Foglalás vége',
    type: 'datetime',
  },
  'priority': {
    name: 'priority',
    required: true,
    nullable: false,
    label: 'Kiemelt rendezvény',
    type: 'boolean',
  },
}
export function instanceOfEventsReservationUpdate(value: object): value is EventsReservationUpdate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "locations" in value;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "finish" in value;
    isInstance = isInstance && "priority" in value;

return isInstance;
}

export function EventsReservationUpdateFromJSON(json: any): EventsReservationUpdate {
return EventsReservationUpdateFromJSONTyped(json, false);
}

export function EventsReservationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsReservationUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'locations': json['locations'],
          'group': json['group'],
        'start': (DateTime.fromISO(json['start'])),
        'finish': (DateTime.fromISO(json['finish'])),
          'priority': json['priority'],
    };
  }

  export function EventsReservationUpdateToJSON(value?: EventsReservationUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
            'locations': value.locations,
                'group': value.group,
            'start': (value.start.toISO()),
            'finish': (value.finish.toISO()),
                'priority': value.priority,
    };
  }

  export function EventsReservationUpdateCreateEmpty(): EventsReservationUpdate {
  return {
      'name': '',
      'locations': [],
      'group': 0,
      'start': DateTime.now(),
      'finish': DateTime.now(),
      'priority': false,
  };
  }

