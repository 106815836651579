import {Base} from "@Models/Base";
import {SpendingPeriod} from "@Models/money/SpendingPeriod";
import {MoneyApi, type RvtSpendingPeriodSummaryResponse} from "@/api/api";

export class SpendingPeriodSummary extends Base {
    period: SpendingPeriod;

    tenderId: number | null;

    claimsSum: number;

    claimsBudget: number;

    claimsBalance: number;

    declinedClaims: number;

    successfulClaims: number;

    static getById(id: number): Promise<SpendingPeriodSummary> {
        return MoneyApi.spendingPeriodsSummary(id).then(response => {
            return SpendingPeriodSummary.newSingle(response.data, SpendingPeriodSummary.parseResponse);
        });
    }

    static parseResponse(summary: SpendingPeriodSummary, data: RvtSpendingPeriodSummaryResponse): SpendingPeriodSummary {
        summary.period = SpendingPeriod.newSingle(data.period, SpendingPeriod.parseResponse);
        summary.tenderId = data.tenderId;
        summary.claimsSum = data.claimsSum;
        summary.claimsBudget = data.claimsBudget;
        summary.claimsBalance = data.claimsBalance;
        summary.declinedClaims = data.declinedClaims;
        summary.successfulClaims = data.successfulClaims;

        return summary;
    }
}
