import Swal, {type SweetAlertIcon} from "sweetalert2";

export default {
    fire(title: string, description: string, type: SweetAlertIcon) {
        // noinspection JSIgnoredPromiseFromCall
        Swal.fire({
            title: title,
            text: description,
            icon: type,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        });
    },

    info(title: string, description: string = '') {
        this.fire(title, description, 'info');
    },
    success(title: string, description: string = '') {
        this.fire(title, description, 'success');
    },
    warning(title: string, description: string = '') {
        this.fire(title, description, 'warning');
    },
    error(title: string, description: string = '') {
        this.fire(title, description, 'error');
    },

    loading(promise: Promise<any>, subject: string = "Mentés") {
        // noinspection JSIgnoredPromiseFromCall
        Swal.fire({
            title: subject + ' folyamatban',
            toast: true,
            icon: 'success',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            willOpen: () => {
                Swal.showLoading();
            },
            didOpen: () => {
                Swal.stopTimer();
            },
        });

        promise.then(() => {
            Swal.hideLoading();
            Swal.update({
                title: subject + ' sikeres',
            });
            Swal.resumeTimer();
        });

        promise.catch(() => {
            Swal.hideLoading();
            Swal.update({
                title: subject + ' sikertelen',
                icon: 'error',
            });
            Swal.resumeTimer();
        });
    },

    confirm(text: string, description: string | null = null) {
        return Swal.fire({
            text: text,
            footer: description ?? undefined,
            showDenyButton: true,
            confirmButtonText: `Igen`,
            denyButtonText: `Nem`,
        }).then(result => result.isConfirmed);
    },
};
