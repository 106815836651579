import {BaseEntity} from '@Models/BaseEntity';
import {User} from '@Models/base/User';
import type {Item} from '@Models/money/Item';
import {MoneyApi} from '@/api/apis';
import type {FormRef} from '@/vue';
import type {DateTime} from '@Utils/DateTime';
import {type MoneyItemCommentResponse, type MoneyItemCommentStore, MoneyItemCommentType} from '@/api/models';

export class ItemComment extends BaseEntity {
    type: MoneyItemCommentType;

    creator: User;

    text: string;

    createdAt: DateTime;

    static createNew(user: User): ItemComment {
        const comment = new ItemComment();

        comment.type = MoneyItemCommentType.REVIEW_RVT;
        comment.creator = user;
        comment.text = '';

        return comment;
    }

    static parseResponse(comment: ItemComment, data: MoneyItemCommentResponse): ItemComment {
        comment.id = data.id;
        comment.creator = User.newSingle(data.creator, User.parseMinimalResponse);
        comment.type = data.type;
        comment.text = data.text;
        comment.createdAt = data.createdAt;

        return comment;
    }

    public store(item: Item, form: FormRef): Promise<ItemComment> {
        const data: MoneyItemCommentStore = {
            item: item.id,
            type: this.type,
            text: this.text,
        };

        return MoneyApi.itemCommentsStore(data, {form}).then(response => {
            return ItemComment.parseResponse(this, response.data);
        });
    }

    public remove(): Promise<void> {
        return MoneyApi.itemCommentsDelete(this.id).then();
    }
}
