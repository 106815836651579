/* tslint:disable */
/* eslint-disable */

export enum KprCategoryStatus {
    DRAFTING = 0,
    REVIEWING = 1,
    DECIDING = 2,
    FINALIZING = 3,
    SPENDING = 4,
    RESULTING = 5,
    ARCHIVED = 6
}

export const KprCategoryStatusValues: Record<KprCategoryStatus, string> = {
    0: 'Pályázási időszak',
    1: 'Átnézési időszak',
    2: 'Bírálási időszak',
    3: 'Dobálási időszak',
    4: 'Költési időszak',
    5: 'Beszámoltatási időszak',
    6: 'Archívált'
}


export function KprCategoryStatusFromJSON(json: any): KprCategoryStatus {
    return KprCategoryStatusFromJSONTyped(json, false);
}

export function KprCategoryStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprCategoryStatus {
    return json as KprCategoryStatus;
}

export function KprCategoryStatusToJSON(value?: KprCategoryStatus | null): any {
    return value as any;
}

export function KprCategoryStatusCreateEmpty(): KprCategoryStatus {
    return KprCategoryStatus.DRAFTING;
}

