/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsMentorResponse {
  id: number;
  user: BaseUserResponse;
  active: boolean;
  leader: boolean;
}

export const MentorsMentorResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'leader': {
    name: 'leader',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfMentorsMentorResponse(value: object): value is MentorsMentorResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "leader" in value;

return isInstance;
}

export function MentorsMentorResponseFromJSON(json: any): MentorsMentorResponse {
return MentorsMentorResponseFromJSONTyped(json, false);
}

export function MentorsMentorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsMentorResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'user': BaseUserResponseFromJSON(json['user']),
          'active': json['active'],
          'leader': json['leader'],
    };
  }

  export function MentorsMentorResponseToJSON(value?: MentorsMentorResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'user': BaseUserResponseToJSON(value.user),
                'active': value.active,
                'leader': value.leader,
    };
  }

  export function MentorsMentorResponseCreateEmpty(): MentorsMentorResponse {
  return {
      'id': 0,
          'user': BaseUserResponseCreateEmpty(),
      'active': false,
      'leader': false,
  };
  }

