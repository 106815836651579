/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryCreationSource } from './InventoryCreationSource';
    import {
    InventoryCreationSourceFromJSON,
    InventoryCreationSourceFromJSONTyped,
    InventoryCreationSourceToJSON,
    InventoryCreationSourceCreateEmpty,
    } from './InventoryCreationSource';
import {DateTime} from '@Utils/DateTime';
import { InventoryCreationSourceValues } from './InventoryCreationSource';

export interface InventoryCreateExistingItem {
  id: number;
  creationSource: InventoryCreationSource;
  kprItem: number | null;
  owner: number;
  comment: string;
  responsible: number;
  itemGroup: number | null;
  location: number | null;
  name: string;
  officialName: string;
  description: string | null;
  uniqueIdentifier: string | null;
  count: number;
  price: number;
  material: boolean;
}

export const InventoryCreateExistingItemSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'Leltár azonosító',
    type: 'number',
      minimum: 1,
      maximum: 1000000,
  },
  'creationSource': {
    name: 'creationSource',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Forrás",
        enumValues: InventoryCreationSourceValues,
  },
  'kprItem': {
    name: 'kprItem',
    required: true,
    nullable: true,
    label: 'KPR tétel',
    type: 'number',
      minimum: 0,
  },
  'owner': {
    name: 'owner',
    required: true,
    nullable: false,
    label: 'Tulajdonos',
    type: 'number',
      minimum: 0,
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés a létrehozáshoz',
    type: 'string',
      maxLength: 500,
  },
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
    label: 'Felelős kör',
    type: 'number',
      minimum: 0,
  },
  'itemGroup': {
    name: 'itemGroup',
    required: true,
    nullable: true,
    label: 'Tételcsoport',
    type: 'number',
      minimum: 0,
  },
  'location': {
    name: 'location',
    required: true,
    nullable: true,
    label: 'Helyiség',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Tétel neve',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'officialName': {
    name: 'officialName',
    required: true,
    nullable: false,
    label: 'Tétel hivatalos neve',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: true,
    label: 'Tétel leírása',
    type: 'string',
      minLength: 3,
      maxLength: 1000,
  },
  'uniqueIdentifier': {
    name: 'uniqueIdentifier',
    required: true,
    nullable: true,
    label: 'Tétel egyedi azonosítója',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'count': {
    name: 'count',
    required: true,
    nullable: false,
    label: 'Tételek száma',
    type: 'number',
      minimum: 1,
      maximum: 1000,
  },
  'price': {
    name: 'price',
    required: true,
    nullable: false,
    label: 'Becsűlt érték',
    type: 'number',
      minimum: 0,
      maximum: 10000000,
  },
  'material': {
    name: 'material',
    required: true,
    nullable: false,
    label: 'Materiális',
    type: 'boolean',
  },
}
export function instanceOfInventoryCreateExistingItem(value: object): value is InventoryCreateExistingItem {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "creationSource" in value;
    isInstance = isInstance && "kprItem" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "itemGroup" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "officialName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "uniqueIdentifier" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "material" in value;

return isInstance;
}

export function InventoryCreateExistingItemFromJSON(json: any): InventoryCreateExistingItem {
return InventoryCreateExistingItemFromJSONTyped(json, false);
}

export function InventoryCreateExistingItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryCreateExistingItem {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'creationSource': InventoryCreationSourceFromJSON(json['creationSource']),
          'kprItem': json['kprItem'],
          'owner': json['owner'],
          'comment': json['comment'],
          'responsible': json['responsible'],
          'itemGroup': json['itemGroup'],
          'location': json['location'],
          'name': json['name'],
          'officialName': json['officialName'],
          'description': json['description'],
          'uniqueIdentifier': json['uniqueIdentifier'],
          'count': json['count'],
          'price': json['price'],
          'material': json['material'],
    };
  }

  export function InventoryCreateExistingItemToJSON(value?: InventoryCreateExistingItem | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'creationSource': InventoryCreationSourceToJSON(value.creationSource),
                'kprItem': value.kprItem,
                'owner': value.owner,
                'comment': value.comment,
                'responsible': value.responsible,
                'itemGroup': value.itemGroup,
                'location': value.location,
                'name': value.name,
                'officialName': value.officialName,
                'description': value.description,
                'uniqueIdentifier': value.uniqueIdentifier,
                'count': value.count,
                'price': value.price,
                'material': value.material,
    };
  }

  export function InventoryCreateExistingItemCreateEmpty(): InventoryCreateExistingItem {
  return {
      'id': 0,
          'creationSource': InventoryCreationSourceCreateEmpty(),
      'kprItem': 0,
      'owner': 0,
      'comment': '',
      'responsible': 0,
      'itemGroup': 0,
      'location': 0,
      'name': '',
      'officialName': '',
      'description': '',
      'uniqueIdentifier': '',
      'count': 0,
      'price': 0,
      'material': false,
  };
  }

