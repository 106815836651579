<template>
    <PegasusModal header="Rendezvény jóváhagyása">
        <PegasusForm ref="form">
            <TextareaField
                v-model="comment"
                :schema="EventsMilestoneAddSchema.comment"
                label="Megjegyzés a bejelentőnek"
            ></TextareaField>

            <PegasusButton variant="success" @click="approve">
                Jóváhagyás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {EventsMilestoneAddSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import Event from '@Models/events/Event';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import {useReturn} from '@Utils/dialog';
import toast from '@Utils/toast';

const {event} = defineProps<{
    event: Event
}>();

const form = ref<FormRef>(null);

const comment = ref('');

const returnValue = useReturn();

async function approve() {
    if (!await toast.confirm('Biztosan jóvá akarod hagyni ezt a rendezvényt?')) {
        return;
    }

    let request = event.approve(comment.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request, 'Jóváhagyás');
}
</script>
