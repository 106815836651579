<template>
    <PegasusHeading>Ügyeletes mentor</PegasusHeading>

    <div class="row">
        <div class="col-lg-12 text-center">
            <div v-if="current.mentor">
                <h2>{{ current.mentor.name }}</h2>
                <h3>Szoba: {{ current.mentor.room }}</h3>
                <h3>Telefonszám: <a :href="current.mentor.phoneLink">{{ current.mentor.phone }}</a></h3>
            </div>

            <div v-if="next && next.mentor" class="text-center">
                <hr>
                <h4>{{ nextTime }}</h4>
                <h4>{{ next.mentor.name }}</h4>
                <h5>Szoba: {{ next.mentor.room }}</h5>
                <h6>Telefonszám: <a :href="next.mentor.phoneLink">{{ next.mentor.phone }}</a></h6>
            </div>
        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-lg-12 text-center">
            <PegasusLink :params="{year: now.year, month: now.month}" to="mentors.month">
                Havi beosztás
            </PegasusLink>
        </div>
    </div>

    <hr>

    <Description :leader="leader"></Description>
</template>

<title>Ügyeletes mentor</title>

<script lang="ts" setup>
import {DateTime} from "@Utils/DateTime";
import Description from "@Components/mentors/Description.vue";
import {Mentor} from "@Models/mentors/Mentor";
import PegasusLink from "@Components/base/PegasusLink.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {next, leader, current} = await Mentor.getIndex();

const now = DateTime.now();

const nextTime = next?.from.diffNow() ?? null;
</script>
