/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsServiceResponseData } from './EventsServiceResponseData';
    import {
    EventsServiceResponseDataFromJSON,
    EventsServiceResponseDataFromJSONTyped,
    EventsServiceResponseDataToJSON,
    EventsServiceResponseDataCreateEmpty,
    } from './EventsServiceResponseData';
    import type { EventsServiceType } from './EventsServiceType';
    import {
    EventsServiceTypeFromJSON,
    EventsServiceTypeFromJSONTyped,
    EventsServiceTypeToJSON,
    EventsServiceTypeCreateEmpty,
    } from './EventsServiceType';
import {DateTime} from '@Utils/DateTime';
import { EventsServiceTypeValues } from './EventsServiceType';

export interface EventsServiceUpdate {
  data: EventsServiceResponseData;
  type: EventsServiceType;
  comment: string;
}

export const EventsServiceUpdateSchema = {
  'data': {
    name: 'data',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Típus",
        enumValues: EventsServiceTypeValues,
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 5000,
  },
}
export function instanceOfEventsServiceUpdate(value: object): value is EventsServiceUpdate {
let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function EventsServiceUpdateFromJSON(json: any): EventsServiceUpdate {
return EventsServiceUpdateFromJSONTyped(json, false);
}

export function EventsServiceUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'data': EventsServiceResponseDataFromJSON(json['data']),
              'type': EventsServiceTypeFromJSON(json['type']),
          'comment': json['comment'],
    };
  }

  export function EventsServiceUpdateToJSON(value?: EventsServiceUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'data': EventsServiceResponseDataToJSON(value.data),
                'type': EventsServiceTypeToJSON(value.type),
                'comment': value.comment,
    };
  }

  export function EventsServiceUpdateCreateEmpty(): EventsServiceUpdate {
  return {
          'data': EventsServiceResponseDataCreateEmpty(),
          'type': EventsServiceTypeCreateEmpty(),
      'comment': '',
  };
  }

