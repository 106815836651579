<template>
    <TextField
        v-model="spendingItem.name"
        :schema="MoneySpendingItemStoreSchema.name"
    ></TextField>

    <NumberField
        v-model="spendingItem.money"
        :schema="MoneySpendingItemStoreSchema.money"
    ></NumberField>

    <SelectField
        v-model="spendingItem.method"
        :options="methodOptions"
        :schema="MoneySpendingItemStoreSchema.method"
    ></SelectField>

    <SelectField
        v-model="spendingItem.budgetLocation"
        :options="dictObject(budgetLocations, location => location.name)"
        :schema="MoneySpendingItemStoreSchema.location"
    ></SelectField>
</template>

<script lang="ts" setup>
import {MoneySpendingItemStoreSchema, MoneySpendingMethod, MoneySpendingMethodValues} from '@/api/models';
import {dictObject} from '@Models/Utils';
import SelectField from '@Components/base/form/types/SelectField.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import type {SpendingItem} from '@Models/money/SpendingItem';
import {useConstantsStore} from '@/stores/constants';
import {map} from 'lodash-es';

const {spendingItem} = defineProps<{
    spendingItem: SpendingItem
}>();

const budgetLocations = useConstantsStore().constants.money.budgetLocations;

const methodOptions = map(MoneySpendingMethodValues, (value: string, key: MoneySpendingMethod) => {
    if (key === MoneySpendingMethod.CARD) {
        return {
            value: key,
            label: value,
            warning: 'Kivételes esetben (Gazdasági felelős engedélyével)'
        };
    }

    return {
        value: key,
        label: value,
    };
}) as unknown as {value: MoneySpendingMethod, label: string}[];
</script>
