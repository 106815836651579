import {BaseEntity} from "@Models/BaseEntity";
import {sumObject} from "@Models/Utils";
import {ItemGroup} from "@Models/money/ItemGroup";
import {Category} from "@Models/rvt/kpr/Category";
import {Application, type KprPermissions} from "@Models/rvt/kpr/Application";
import type {KprCategoryApplicationResponse, KprCategoryApplicationStore} from "@/api/api";
import {KprApi} from "@/api/api";
import type {Item} from '@Models/money/Item';
import {forEach} from "lodash-es";

export class CategoryApplication extends BaseEntity {
    category: Category;

    applicationId: number;

    itemGroups: Record<number, ItemGroup>;

    canEdit: boolean;

    canEditResult: boolean;

    canDecide: boolean;

    canFinalize: boolean;

    canConfidential: boolean;

    static create(application: Application, category: Category): CategoryApplication {
        const capp = new CategoryApplication();

        capp.applicationId = application.id;
        capp.category = category;

        return capp;
    }

    static parseResponse(categoryApplication: CategoryApplication, data: KprCategoryApplicationResponse): CategoryApplication {
        categoryApplication.id = data.id;
        categoryApplication.category = Category.newSingle(data.category, Category.parseResponse);
        categoryApplication.applicationId = data.applicationId;
        categoryApplication.itemGroups = ItemGroup.newRecords(data.itemGroups, ItemGroup.parseWithItems);
        categoryApplication.canEdit = data.canEdit;
        categoryApplication.canEditResult = data.canEditResult;
        categoryApplication.canDecide = data.canDecide;
        categoryApplication.canFinalize = data.canFinalize;
        categoryApplication.canConfidential = data.canConfidential;

        return categoryApplication;
    }

    public store(): Promise<CategoryApplication> {
        const data: KprCategoryApplicationStore = {
            application: this.applicationId,
            category: this.category.id,
        };

        return KprApi.categoryApplicationsStore(data).then(response => {
            return CategoryApplication.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return KprApi.categoryApplicationsDelete(this.id).then();
    }

    public getPermissions(): KprPermissions {
        return {
            canEdit: this.canEdit,
            canDecide: this.canDecide,
            canFinalize: this.canFinalize,
            canConfidential: this.canConfidential,
        };
    }

    public calculateItemCount(): number {
        return sumObject(this.itemGroups, itemGroup => itemGroup.itemCount);
    }

    public calculateSum(): number {
        return sumObject(this.itemGroups, itemGroup => itemGroup.calculateSum());
    }

    public calculateSumApproved(): number {
        return sumObject(this.itemGroups, itemGroup => itemGroup.calculateSumApproved());
    }

    public calculateSumDecided(): number {
        return sumObject(this.itemGroups, itemGroup => itemGroup.calculateSumDecided());
    }

    public getUnapprovedItems(): Item[] {
        let items: Item[] = [];

        forEach(this.itemGroups, itemGroup => {
            items = items.concat(itemGroup.getUnapprovedItems());
        });

        return items;
    }
}
