/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoExtendedTenderResponse } from './VikoExtendedTenderResponse';
    import {
    VikoExtendedTenderResponseFromJSON,
    VikoExtendedTenderResponseFromJSONTyped,
    VikoExtendedTenderResponseToJSON,
    VikoExtendedTenderResponseCreateEmpty,
    } from './VikoExtendedTenderResponse';
    import type { VikoReportResponse } from './VikoReportResponse';
    import {
    VikoReportResponseFromJSON,
    VikoReportResponseFromJSONTyped,
    VikoReportResponseToJSON,
    VikoReportResponseCreateEmpty,
    } from './VikoReportResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoTenderProblemsResponse {
  tender: VikoExtendedTenderResponse;
  rejectedReports: Array<VikoReportResponse>;
  unreviewedReports: Array<VikoReportResponse>;
}

export const VikoTenderProblemsResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'rejectedReports': {
    name: 'rejectedReports',
    required: true,
    nullable: false,
    label: 'Elutasított beszámolók',
      type: 'array',
  },
  'unreviewedReports': {
    name: 'unreviewedReports',
    required: true,
    nullable: false,
    label: 'Bírálásra váró beszámolók',
      type: 'array',
  },
}
export function instanceOfVikoTenderProblemsResponse(value: object): value is VikoTenderProblemsResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "rejectedReports" in value;
    isInstance = isInstance && "unreviewedReports" in value;

return isInstance;
}

export function VikoTenderProblemsResponseFromJSON(json: any): VikoTenderProblemsResponse {
return VikoTenderProblemsResponseFromJSONTyped(json, false);
}

export function VikoTenderProblemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoTenderProblemsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': VikoExtendedTenderResponseFromJSON(json['tender']),
            'rejectedReports': ((json['rejectedReports'] as Array<any>).map(VikoReportResponseFromJSON)),
            'unreviewedReports': ((json['unreviewedReports'] as Array<any>).map(VikoReportResponseFromJSON)),
    };
  }

  export function VikoTenderProblemsResponseToJSON(value?: VikoTenderProblemsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': VikoExtendedTenderResponseToJSON(value.tender),
              'rejectedReports': ((value.rejectedReports as Array<any>).map(VikoReportResponseToJSON)),
              'unreviewedReports': ((value.unreviewedReports as Array<any>).map(VikoReportResponseToJSON)),
    };
  }

  export function VikoTenderProblemsResponseCreateEmpty(): VikoTenderProblemsResponse {
  return {
          'tender': VikoExtendedTenderResponseCreateEmpty(),
      'rejectedReports': [],
      'unreviewedReports': [],
  };
  }

