/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryTransferDecision {
  transfer: number;
  decision: boolean;
  comment: string;
}

export const InventoryTransferDecisionSchema = {
  'transfer': {
    name: 'transfer',
    required: true,
    nullable: false,
    label: 'Átvezetési kérelem',
    type: 'number',
      minimum: 0,
  },
  'decision': {
    name: 'decision',
    required: true,
    nullable: false,
    label: 'Döntés',
    type: 'boolean',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfInventoryTransferDecision(value: object): value is InventoryTransferDecision {
let isInstance = true;
    isInstance = isInstance && "transfer" in value;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function InventoryTransferDecisionFromJSON(json: any): InventoryTransferDecision {
return InventoryTransferDecisionFromJSONTyped(json, false);
}

export function InventoryTransferDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryTransferDecision {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'transfer': json['transfer'],
          'decision': json['decision'],
          'comment': json['comment'],
    };
  }

  export function InventoryTransferDecisionToJSON(value?: InventoryTransferDecision | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'transfer': value.transfer,
                'decision': value.decision,
                'comment': value.comment,
    };
  }

  export function InventoryTransferDecisionCreateEmpty(): InventoryTransferDecision {
  return {
      'transfer': 0,
      'decision': false,
      'comment': '',
  };
  }

