<template>
    <div class="row">
        <div class="col">
            <DatetimeField v-model="data.from" :disabled="!service.canEdit" :schema="EventsBarDataSchema.from"></DatetimeField>
        </div>

        <div class="col">
            <DatetimeField v-model="data.to" :disabled="!service.canEdit" :schema="EventsBarDataSchema.to"></DatetimeField>
        </div>
    </div>
</template>

<script lang="ts" setup>
import DatetimeField from "@Components/base/form/types/DatetimeField.vue";
import {type EventsBarData, EventsBarDataSchema} from "@/api/models";
import {Service} from '@Models/events/Service';

const data = defineModel<EventsBarData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
