import {BaseEntity} from "@Models/BaseEntity";
import {DateTime} from "@Utils/DateTime";
import type {BaseAuthschAccountResponse} from "@/api/api";

export class AuthschAccount extends BaseEntity {
    providerId: string;

    name: string;

    email: string;

    firstName: string | null;

    lastName: string | null;

    schacc: string | null;

    bmeid: number | null;

    virId: number | null;

    virUid: string | null;

    neptun: string | null;

    updatedAt: DateTime;

    lastLogin: DateTime;

    static parseResponse(account: AuthschAccount, data: BaseAuthschAccountResponse): AuthschAccount {
        account.id = data.id;
        account.providerId = data.providerId;
        account.name = data.name;
        account.email = data.email;
        account.firstName = data.firstName;
        account.lastName = data.lastName;
        account.schacc = data.schacc;
        account.bmeid = data.bmeid;
        account.virId = data.virId;
        account.virUid = data.virUid;
        account.neptun = data.neptun;
        account.updatedAt = data.updatedAt;
        account.lastLogin = data.lastLogin;

        return account;
    }

    public getFields(): { key: string, value: string }[] {
        return [
            {key: 'ID', value: this.providerId},
            {key: 'Név', value: this.name},
            {key: 'E-mail', value: this.email},
            {key: 'Keresztnév', value: this.firstName ?? '-'},
            {key: 'Vezetéknév', value: this.lastName ?? '-'},
            {key: 'Schacc', value: this.schacc ?? '-'},
            {key: 'BME címtár ID', value: this.bmeid ? '' + this.bmeid : '-'},
            {key: 'VIR ID', value: this.virId ? '' + this.virId : '-'},
            {key: 'VIR név', value: this.virUid ?? '-'},
            {key: 'Neptun kód', value: this.neptun ?? '?'},
            {key: 'Utolsó frissítés', value: this.updatedAt.datetimeShort()},
            {key: 'Utolsó bejelentkezés', value: this.lastLogin.datetimeShort()},
        ];
    }
}
