/* tslint:disable */
/* eslint-disable */

export enum MoneySpendingEventType {
    CASH_IN = 'cashIn',
    CASH_OUT = 'cashOut',
    INVOICE_UPLOADED = 'invoiceUploaded',
    TRANSFER_REQUESTED = 'transferRequested',
    TRANSFER_REGISTERED = 'transferRegistered',
    TRANSFER_SENT = 'transferSent'
}

export const MoneySpendingEventTypeValues: Record<MoneySpendingEventType, string> = {
    'cashIn': 'Készpénz leadás',
    'cashOut': 'Készpénz kiadás',
    'invoiceUploaded': 'Számla feltöltés',
    'transferRequested': 'Utalás kérvényezve',
    'transferRegistered': 'Utalás rögzítve',
    'transferSent': 'Utalás elküldve'
}


export function MoneySpendingEventTypeFromJSON(json: any): MoneySpendingEventType {
    return MoneySpendingEventTypeFromJSONTyped(json, false);
}

export function MoneySpendingEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingEventType {
    return json as MoneySpendingEventType;
}

export function MoneySpendingEventTypeToJSON(value?: MoneySpendingEventType | null): any {
    return value as any;
}

export function MoneySpendingEventTypeCreateEmpty(): MoneySpendingEventType {
    return MoneySpendingEventType.CASH_IN;
}

