/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { SystemEmailResponse } from './SystemEmailResponse';
    import {
    SystemEmailResponseFromJSON,
    SystemEmailResponseFromJSONTyped,
    SystemEmailResponseToJSON,
    SystemEmailResponseCreateEmpty,
    } from './SystemEmailResponse';
import {DateTime} from '@Utils/DateTime';

export interface SystemEmailListResponse {
  emails: Array<SystemEmailResponse>;
}

export const SystemEmailListResponseSchema = {
  'emails': {
    name: 'emails',
    required: true,
    nullable: false,
    label: 'E-mailek',
      type: 'array',
  },
}
export function instanceOfSystemEmailListResponse(value: object): value is SystemEmailListResponse {
let isInstance = true;
    isInstance = isInstance && "emails" in value;

return isInstance;
}

export function SystemEmailListResponseFromJSON(json: any): SystemEmailListResponse {
return SystemEmailListResponseFromJSONTyped(json, false);
}

export function SystemEmailListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemEmailListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'emails': ((json['emails'] as Array<any>).map(SystemEmailResponseFromJSON)),
    };
  }

  export function SystemEmailListResponseToJSON(value?: SystemEmailListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'emails': ((value.emails as Array<any>).map(SystemEmailResponseToJSON)),
    };
  }

  export function SystemEmailListResponseCreateEmpty(): SystemEmailListResponse {
  return {
      'emails': [],
  };
  }

