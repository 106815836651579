/* tslint:disable */
/* eslint-disable */

export enum InventoryUpdateType {
    CHECK = 'check',
    CREATION = 'creation',
    DAMAGE = 'damage',
    DATA_CHANGE = 'dataChange',
    LENDING = 'lending',
    REPAIR = 'repair',
    TERMINATION = 'termination',
    TRANSFER = 'transfer'
}

export const InventoryUpdateTypeValues: Record<InventoryUpdateType, string> = {
    'check': 'Ellenőrzés',
    'creation': 'Létrehozás',
    'damage': 'Sérülés',
    'dataChange': 'Adatváltozás',
    'lending': 'Kölcsönzés',
    'repair': 'Javítás',
    'termination': 'Kivezetés',
    'transfer': 'Átadás'
}


export function InventoryUpdateTypeFromJSON(json: any): InventoryUpdateType {
    return InventoryUpdateTypeFromJSONTyped(json, false);
}

export function InventoryUpdateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateType {
    return json as InventoryUpdateType;
}

export function InventoryUpdateTypeToJSON(value?: InventoryUpdateType | null): any {
    return value as any;
}

export function InventoryUpdateTypeCreateEmpty(): InventoryUpdateType {
    return InventoryUpdateType.CHECK;
}

