import {BaseEntity} from "@Models/BaseEntity";
import type {MapRoomResponse, MapRoomType} from "@/api/api";
import {CentralApi} from "@/api/api";

export class Room extends BaseEntity {
    name: string | null;

    number: string;

    type: MapRoomType;

    description: string | null;

    displayNumber: string;

    static getRoomByNumber(number: string): Promise<Room> {
        return CentralApi.mapRoomByNumber(number).then(response => {
            return Room.newSingle(response.data, Room.parseResponse);
        });
    }

    static parseResponse(room: Room, data: MapRoomResponse): Room {
        room.id = data.id;
        room.name = data.name;
        room.description = data.description;
        room.type = data.type;
        room.displayNumber = data.displayNumber;

        return room;
    }

    get displayName(): string {
        return this.displayNumber + (this.name ? ' (' + this.name + ')' : '');
    }
}
