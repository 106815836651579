/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface CommonCarNumber {
  number: string;
}

export const CommonCarNumberSchema = {
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Rendszám',
    type: 'string',
      minLength: 6,
      maxLength: 9,
  },
}
export function instanceOfCommonCarNumber(value: object): value is CommonCarNumber {
let isInstance = true;
    isInstance = isInstance && "number" in value;

return isInstance;
}

export function CommonCarNumberFromJSON(json: any): CommonCarNumber {
return CommonCarNumberFromJSONTyped(json, false);
}

export function CommonCarNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonCarNumber {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'number': json['number'],
    };
  }

  export function CommonCarNumberToJSON(value?: CommonCarNumber | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'number': value.number,
    };
  }

  export function CommonCarNumberCreateEmpty(): CommonCarNumber {
  return {
      'number': '',
  };
  }

