<template>
    <PegasusHeading>Mentor oldal</PegasusHeading>

    <PegasusPanel header="Adatok">
        <div class="row">
            <div class="col-lg-6">
                <dl>
                    <dt>Szobaszám</dt>
                    <dd>{{ index.mentor.room }}</dd>
                    <dt>Telefonszám</dt>
                    <dd>{{ index.mentor.phone }}</dd>
                </dl>
            </div>
            <div class="col-lg-6">
                <PegasusButton variant="primary" @click="Dialog.open(MentorDataDialog, {mentor: index.mentor})">
                    <i class="fa fa-edit"></i> Szerkesztés
                </PegasusButton>
            </div>
        </div>
    </PegasusPanel>

    <h4 v-if="isEmpty(index.availabilities)" class="p-2">Nincs jelentkezési időszak</h4>

    <PegasusPanel v-for="month in index.availabilities" :key="month.assignmentMonth.id" class="pt-2" header="Mentor ráérések">
        <PegasusForm ref="form">
            <div class="row">
                <div class="col-lg-6">
                    <Calendar
                        v-model="month.daysAsDate"
                        :manual-input="false"
                        :max-date="month.assignmentMonth.getLastDay().toJSDate()"
                        :min-date="month.assignmentMonth.getFirstDay().toJSDate()"
                        :show-other-months="false"
                        inline
                        selection-mode="multiple"
                    ></Calendar>
                </div>

                <div class="col-lg-6">
                    Kiválasztott napok:
                    <ul>
                        <li v-for="date in month.daysAsLuxon" :key="date.day">
                            {{ date.dateShort() }}
                        </li>
                    </ul>

                    <PegasusButton block variant="success" @click="save(month)">
                        <i class="fa fa-save"></i> Ráérés mentése
                    </PegasusButton>

                    <PegasusButton
                        :params="{year: month.assignmentMonth.year, month: month.assignmentMonth.month}"
                        block
                        to="mentors.mentor.everyone"
                        variant="primary">
                        <i class="fa fa-external-link"></i> Összes ráérés megnyitása
                    </PegasusButton>
                </div>
            </div>
        </PegasusForm>
    </PegasusPanel>
</template>

<title>Mentor ráérések</title>

<script lang="ts" setup>
import {AvailabilityIndex} from "@Models/mentors/AvailabilityIndex";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import Calendar from "primevue/calendar";
import type {Availability} from "@Models/mentors/Availability";
import PegasusButton from "@Components/base/PegasusButton.vue";
import toast from "@Utils/toast";
import {ref} from "vue";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';
import {isEmpty} from "lodash-es";
import {Dialog} from '@Utils/dialog';
import MentorDataDialog from '@Components/mentors/dialogs/MentorDataDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const index = ref(await AvailabilityIndex.get());

const form = ref<FormRef>(null);

function save(availability: Availability) {
    let request = availability.update();

    toast.loading(request);
}
</script>
