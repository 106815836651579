/* tslint:disable */
/* eslint-disable */

export enum EventsBssVideoType {
    ZENE = 'zene',
    ZENERIPORT = 'zeneriport',
    LIVE = 'live',
    DOC = 'doc',
    OTHER = 'other'
}

export const EventsBssVideoTypeValues: Record<EventsBssVideoType, string> = {
    'zene': 'Zenés hangulatvideó',
    'zeneriport': 'Zenés hangulatvideó riportokkal',
    'live': 'Élő közvetítés',
    'doc': 'Előadás/rendezvény dokumentálás jellegű rögzítése',
    'other': 'Egyéb (pl. PR videó egy körnek/eseménynek)'
}


export function EventsBssVideoTypeFromJSON(json: any): EventsBssVideoType {
    return EventsBssVideoTypeFromJSONTyped(json, false);
}

export function EventsBssVideoTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsBssVideoType {
    return json as EventsBssVideoType;
}

export function EventsBssVideoTypeToJSON(value?: EventsBssVideoType | null): any {
    return value as any;
}

export function EventsBssVideoTypeCreateEmpty(): EventsBssVideoType {
    return EventsBssVideoType.ZENE;
}

