<template>
    <PegasusModal :header="mentor.name">
        <PegasusForm ref="form">
            <RoomSelectorField
                v-model="room"
                :allowed-types="[MapRoomType.LIVING]"
                :schema="BaseUserUpdateSchema.room"
                inline
            ></RoomSelectorField>

            <PhoneNumberField
                v-model="phone"
                :schema="BaseUserUpdateSchema.phone"
                inline
            ></PhoneNumberField>

            <PegasusButton variant="success" @click="save">
                <i class="fa fa-save"></i> Mentése
            </PegasusButton>

            <PegasusButton v-if="mentor.active && isAdmin" class="float-end" variant="danger" @click="inactive(mentor)">
                <i class="fas fa-hourglass-end"></i> Inaktiválás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {BaseGlobalPermission, BaseUserUpdateSchema, MapRoomType} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PhoneNumberField from '@Components/base/form/types/custom/PhoneNumberField.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import RoomSelectorField from '@Components/base/form/types/custom/RoomSelectorField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {Mentor} from '@Models/mentors/Mentor';
import {computed, ref} from 'vue';
import type {FormRef} from '@/vue';
import toast from '@Utils/toast';
import {useReturn} from '@Utils/dialog';
import {useAuthStore} from '@/stores/auth';

const {mentor} = defineProps<{
    mentor: Mentor
}>();

const room = ref(mentor.user.room);
const phone = ref(mentor.user.phone);

const form = ref<FormRef>(null);

const auth = useAuthStore();

const returnValue = useReturn();

const isAdmin = computed(() => auth.hasPermission(BaseGlobalPermission.MENTORS_EDIT));

async function save() {
    if (!await toast.confirm('Biztos vagy benne hogy módosítod ezeket az adatokat?')) {
        return;
    }

    const request = mentor.user.update(form.value, room.value, phone.value).then(() => returnValue(true));

    toast.loading(request);
}

async function inactive(mentor: Mentor) {
    if (!await toast.confirm('Biztos vagy benne hogy inaktíválod ezt a mentort?')) {
        return;
    }

    const request = mentor.makeInactive().then(() => returnValue(false));

    toast.loading(request);
}
</script>
