/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface AuthAuthschLinkedAccount {
  bme: string | null;
  schacc: string | null;
  vir: number | null;
  virUid: string | null;
}

export const AuthAuthschLinkedAccountSchema = {
  'bme': {
    name: 'bme',
    required: true,
    nullable: true,
    label: 'BME címtár azonosító',
    type: 'string',
      maxLength: 100,
  },
  'schacc': {
    name: 'schacc',
    required: true,
    nullable: true,
    label: 'SCH Account név',
    type: 'string',
  },
  'vir': {
    name: 'vir',
    required: true,
    nullable: true,
    label: 'PéK név',
    type: 'number',
      minimum: 1,
  },
  'virUid': {
    name: 'virUid',
    required: true,
    nullable: true,
    label: 'PéK azonosító',
    type: 'string',
  },
}
export function instanceOfAuthAuthschLinkedAccount(value: object): value is AuthAuthschLinkedAccount {
let isInstance = true;
    isInstance = isInstance && "bme" in value;
    isInstance = isInstance && "schacc" in value;
    isInstance = isInstance && "vir" in value;
    isInstance = isInstance && "virUid" in value;

return isInstance;
}

export function AuthAuthschLinkedAccountFromJSON(json: any): AuthAuthschLinkedAccount {
return AuthAuthschLinkedAccountFromJSONTyped(json, false);
}

export function AuthAuthschLinkedAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthAuthschLinkedAccount {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'bme': json['bme'],
          'schacc': json['schacc'],
          'vir': json['vir'],
          'virUid': json['virUid'],
    };
  }

  export function AuthAuthschLinkedAccountToJSON(value?: AuthAuthschLinkedAccount | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'bme': value.bme,
                'schacc': value.schacc,
                'vir': value.vir,
                'virUid': value.virUid,
    };
  }

  export function AuthAuthschLinkedAccountCreateEmpty(): AuthAuthschLinkedAccount {
  return {
      'bme': '',
      'schacc': '',
      'vir': 0,
      'virUid': '',
  };
  }

