/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoReportResponse } from './VikoReportResponse';
    import {
    VikoReportResponseFromJSON,
    VikoReportResponseFromJSONTyped,
    VikoReportResponseToJSON,
    VikoReportResponseCreateEmpty,
    } from './VikoReportResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoLeaderResponse {
  groupId: number;
  waitingReports: Array<VikoReportResponse>;
  doneReports: Array<VikoReportResponse>;
}

export const VikoLeaderResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'waitingReports': {
    name: 'waitingReports',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'doneReports': {
    name: 'doneReports',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfVikoLeaderResponse(value: object): value is VikoLeaderResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "waitingReports" in value;
    isInstance = isInstance && "doneReports" in value;

return isInstance;
}

export function VikoLeaderResponseFromJSON(json: any): VikoLeaderResponse {
return VikoLeaderResponseFromJSONTyped(json, false);
}

export function VikoLeaderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoLeaderResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
            'waitingReports': ((json['waitingReports'] as Array<any>).map(VikoReportResponseFromJSON)),
            'doneReports': ((json['doneReports'] as Array<any>).map(VikoReportResponseFromJSON)),
    };
  }

  export function VikoLeaderResponseToJSON(value?: VikoLeaderResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
              'waitingReports': ((value.waitingReports as Array<any>).map(VikoReportResponseToJSON)),
              'doneReports': ((value.doneReports as Array<any>).map(VikoReportResponseToJSON)),
    };
  }

  export function VikoLeaderResponseCreateEmpty(): VikoLeaderResponse {
  return {
      'groupId': 0,
      'waitingReports': [],
      'doneReports': [],
  };
  }

