/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryItemGroupCreate {
  name: string;
  description: string;
  parentGroup: number | null;
  group: number;
}

export const InventoryItemGroupCreateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Tételcsoport neve',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Tételcsoport leírása',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'parentGroup': {
    name: 'parentGroup',
    required: true,
    nullable: true,
    label: 'Tételcsoport szülője',
    type: 'number',
      minimum: 0,
  },
  'group': {
    name: 'group',
    required: true,
    nullable: false,
    label: 'Tételcsoport befogadó köre',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfInventoryItemGroupCreate(value: object): value is InventoryItemGroupCreate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "parentGroup" in value;
    isInstance = isInstance && "group" in value;

return isInstance;
}

export function InventoryItemGroupCreateFromJSON(json: any): InventoryItemGroupCreate {
return InventoryItemGroupCreateFromJSONTyped(json, false);
}

export function InventoryItemGroupCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemGroupCreate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
          'parentGroup': json['parentGroup'],
          'group': json['group'],
    };
  }

  export function InventoryItemGroupCreateToJSON(value?: InventoryItemGroupCreate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
                'parentGroup': value.parentGroup,
                'group': value.group,
    };
  }

  export function InventoryItemGroupCreateCreateEmpty(): InventoryItemGroupCreate {
  return {
      'name': '',
      'description': '',
      'parentGroup': 0,
      'group': 0,
  };
  }

