/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsManagerAssignmentsResponse {
  assignments: { [key: string]: number; };
}

export const EventsManagerAssignmentsResponseSchema = {
  'assignments': {
    name: 'assignments',
    required: true,
    nullable: false,
    label: 'Beosztás',
  },
}
export function instanceOfEventsManagerAssignmentsResponse(value: object): value is EventsManagerAssignmentsResponse {
let isInstance = true;
    isInstance = isInstance && "assignments" in value;

return isInstance;
}

export function EventsManagerAssignmentsResponseFromJSON(json: any): EventsManagerAssignmentsResponse {
return EventsManagerAssignmentsResponseFromJSONTyped(json, false);
}

export function EventsManagerAssignmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsManagerAssignmentsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'assignments': json['assignments'],
    };
  }

  export function EventsManagerAssignmentsResponseToJSON(value?: EventsManagerAssignmentsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'assignments': value.assignments,
    };
  }

  export function EventsManagerAssignmentsResponseCreateEmpty(): EventsManagerAssignmentsResponse {
  return {
      'assignments': {},
  };
  }

