import {BaseEntity} from '@Models/BaseEntity';
import {BudgetLocation} from '@Models/money/BudgetLocation';
import {
    type MoneySpendingItemEventResponse,
    type MoneySpendingItemResponse,
    MoneySpendingItemStatus,
    MoneySpendingItemStatusValues,
    type MoneySpendingItemStore,
    type MoneySpendingItemUpdate,
    MoneySpendingMethod
} from '@/api/models';
import type {DateTime} from '@Utils/DateTime';
import {MoneyApi} from '@/api/apis';
import type {FormRef} from '@/vue';
import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {keyBy, map, some} from 'lodash-es';
import {CashIn} from '@Models/money/spendingItemEvents/CashIn';
import {CashOut} from '@Models/money/spendingItemEvents/CashOut';
import {InvoiceUploaded} from '@Models/money/spendingItemEvents/InvoiceUploaded';
import {TransferRegistered} from '@Models/money/spendingItemEvents/TransferRegistered';
import {TransferSent} from '@Models/money/spendingItemEvents/TransferSent';
import {TransferRequested} from '@Models/money/spendingItemEvents/TransferRequested';

export class SpendingItem extends BaseEntity {
    budgetLocation: BudgetLocation;

    itemId: number;

    name: string;

    money: number;

    method: MoneySpendingMethod;

    status: MoneySpendingItemStatus;

    createdAt: DateTime;

    events: Record<number, SpendingItemEvent>;

    static getById(id: number): Promise<SpendingItem> {
        return MoneyApi.spendingItemsShow(id).then(response => {
            return SpendingItem.newSingle(response.data, SpendingItem.parseResponse);
        });
    }

    static parseResponse(item: SpendingItem, data: MoneySpendingItemResponse): SpendingItem {
        item.id = data.id;
        item.budgetLocation = BudgetLocation.getById(data.locationId);
        item.itemId = data.itemId;
        item.name = data.name;
        item.money = data.money;
        item.method = data.method;
        item.status = data.status;
        item.createdAt = data.createdAt;
        item.events = SpendingItem.parseEvents(data.events);

        return item;
    }

    private static parseEvents(events: MoneySpendingItemEventResponse[]) {
        const entries = map(events, event => {
            switch (event.type) {
                case 'cashIn':
                    return CashIn.newSingle(event, CashIn.parseResponse);
                case 'cashOut':
                    return CashOut.newSingle(event, CashOut.parseResponse);
                case 'invoiceUploaded':
                    return InvoiceUploaded.newSingle(event, InvoiceUploaded.parseResponse);
                case 'transferRequested':
                    return TransferRequested.newSingle(event, TransferRequested.parseResponse);
                case 'transferRegistered':
                    return TransferRegistered.newSingle(event, TransferRegistered.parseResponse);
                case 'transferSent':
                    return TransferSent.newSingle(event, TransferSent.parseResponse);
                default:
                    throw new Error('Invalid event type');
            }
        });

        return keyBy(entries, (entry: SpendingItemEvent) => entry.id);
    }

    get statusText(): string {
        return MoneySpendingItemStatusValues[this.status];
    }

    public isTransferRequested(): boolean {
        return some(this.events, event => event instanceof TransferRequested);
    }

    public isTransferRegistered(): boolean {
        return some(this.events, event => event instanceof TransferRegistered);
    }

    public isTransferSent(): boolean {
        return some(this.events, event => event instanceof TransferSent);
    }

    public store(itemId: number, form: FormRef): Promise<SpendingItem> {
        const data: MoneySpendingItemStore = {
            item: itemId,
            name: this.name,
            location: this.budgetLocation.id,
            money: this.money,
            method: this.method
        };

        return MoneyApi.spendingItemsStore(data, {form}).then(response => {
            return SpendingItem.parseResponse(this, response.data);
        });
    }

    public update(form: FormRef): Promise<SpendingItem> {
        const data: MoneySpendingItemUpdate = {
            name: this.name,
            location: this.budgetLocation.id,
            money: this.money
        };

        return MoneyApi.spendingItemsUpdate(this.id, data, {form}).then(response => {
            return SpendingItem.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<SpendingItem> {
        return MoneyApi.spendingItemsDelete(this.id).then();
    }
}
