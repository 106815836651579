<template>
    <TextField
        v-model="value"
        :disabled="disabled"
        :inline="inline"
        :schema="schema"
        :without-group="withoutGroup"
        mask="99/999-99-99"
        prefix="+36"
    ></TextField>
</template>

<script lang="ts" setup>
import TextField from "@Components/base/form/types/TextField.vue";
import {computed} from "vue";
import {type FieldSchema} from '@Components/base/form/FieldProperties';

const modelValue = defineModel<string | null>({required: true});

const value = computed({
    get() {
        if (modelValue.value === null) {
            return '';
        }

        if (modelValue.value?.startsWith('+36')) {
            return modelValue.value?.substring(4);
        }

        return modelValue.value;
    },
    set(value: string | null) {
        modelValue.value = value;
    },
});

const {inline, disabled, schema, withoutGroup} = defineProps<{
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();
</script>
