<template>
    <PegasusHeading>{{ group.name }} leltár - {{ itemGroup.name }}</PegasusHeading>

    <h4 v-if="itemGroup.id" class="text-center">{{ itemGroup.description }}</h4>

    <PegasusTable :data="itemGroup.itemsWithDescendants" :per-page="10" search>
        <Column field="idAsText" header="Azonosító"></Column>

        <Column field="name" header="Név"></Column>

        <Column field="count" header="Darabszám"></Column>

        <Column header="Műveletek">
            <template #body="{data}">
                <PegasusButton :params="{code: data.idAsText}" to="inventory.items.default" variant="primary">
                    Megnyitás
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>

    <PegasusButton :params="{group}" to="inventory.groups.index" variant="primary">
        Vissza a kör összefoglalóra
    </PegasusButton>
</template>

<title>Leltár kör adminisztráció</title>

<script lang="ts" setup>
import {ItemGroup} from "@Models/inventory/ItemGroup";
import {Group} from "@Models/base/Group";
import {Item} from "@Models/inventory/Item";
import {size} from "lodash-es";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {groupId, itemGroupId} = defineProps<{
    groupId: number,
    itemGroupId: number
}>();

const group = Group.getBySingleId(groupId);

useAuthStore().requireUser();

const itemGroup = itemGroupId === 0
    ? await Item.getAllByGroup(group.id).then(items => {
        return ItemGroup.createDummyRoot(group, {}, size(items), items);
    })
    : await ItemGroup.getById(itemGroupId);
</script>
