<template>
    <PegasusModal header="Belső megjegyzés hozzáadása">
        <PegasusForm ref="form">
            <TextareaField
                v-model="comment"
                :schema="EventsMilestoneAddSchema.comment"
                label="Belső megjegyzés"
            ></TextareaField>

            <PegasusButton variant="success" @click="addInternal">
                Hozzáadás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script setup lang="ts">
import {EventsMilestoneAddSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import toast from '@Utils/toast';
import type Event from '@Models/events/Event';
import {useReturn} from '@Utils/dialog';

const {event} = defineProps<{
    event: Event
}>();

const form = ref<FormRef>(null);

const comment = ref('');

const returnValue = useReturn();

function addInternal() {
    let request = event.addInternal(comment.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}
</script>
