/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MapFloorResponse } from './MapFloorResponse';
    import {
    MapFloorResponseFromJSON,
    MapFloorResponseFromJSONTyped,
    MapFloorResponseToJSON,
    MapFloorResponseCreateEmpty,
    } from './MapFloorResponse';
import {DateTime} from '@Utils/DateTime';

export interface MapBuildingResponse {
  id: number;
  name: string;
  floors: Array<MapFloorResponse>;
}

export const MapBuildingResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'floors': {
    name: 'floors',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfMapBuildingResponse(value: object): value is MapBuildingResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "floors" in value;

return isInstance;
}

export function MapBuildingResponseFromJSON(json: any): MapBuildingResponse {
return MapBuildingResponseFromJSONTyped(json, false);
}

export function MapBuildingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapBuildingResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
            'floors': ((json['floors'] as Array<any>).map(MapFloorResponseFromJSON)),
    };
  }

  export function MapBuildingResponseToJSON(value?: MapBuildingResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
              'floors': ((value.floors as Array<any>).map(MapFloorResponseToJSON)),
    };
  }

  export function MapBuildingResponseCreateEmpty(): MapBuildingResponse {
  return {
      'id': 0,
      'name': '',
      'floors': [],
  };
  }

