import {Group} from "../base/Group";
import {Closure} from "./Closure";
import {Service} from "./Service";
import {Milestone} from "./Milestone";
import PublicEvent from "@Models/events/PublicEvent";
import {File} from "@Models/File";
import type {
    EventsAdminEventResponse,
    EventsDeadlinesResponse,
    EventsEventStore,
    EventsEventUpdate,
    EventsExtendedEventResponse
} from "@/api/api";
import {EventsApi, EventsBilling, EventsMilestoneType, EventsServiceType} from "@/api/api";
import {isEmpty, map} from "lodash-es";
import type {FormRef} from '@/vue';

export default class Event extends PublicEvent {
    outsiders: number;

    externalWorkers: number;

    milestones: Milestone[];

    closures: Record<number, Closure>;

    services: Record<EventsServiceType, Service>;

    permitNeeded: boolean;

    canServices: boolean;

    billing: EventsBilling | null;

    responsibleNeptun: string | null;

    static getById(id: number): Promise<Event> {
        return EventsApi.eventsShow(id).then(response => {
            return Event.newSingle(response.data, Event.parseExtendedResponse);
        });
    }

    static getByMonth(year: number, month: number): Promise<Record<number, Event>> {
        return EventsApi.eventsByMonth(year, month).then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static getWaitingApprove(): Promise<Record<number, Event>> {
        return EventsApi.eventsWaitingApprove().then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static getWaitingReport(): Promise<Record<number, Event>> {
        return EventsApi.eventsWaitingReport().then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static getWaitingReportAck(): Promise<Record<number, Event>> {
        return EventsApi.eventsWaitingReportAck().then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static getNext(): Promise<Record<number, Event>> {
        return EventsApi.eventsNext().then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static getRelevantUserEvents(userId: number): Promise<Record<number, Event>> {
        return EventsApi.usersRelevantEvents(userId).then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static getAllUserEvents(userId: number): Promise<Record<number, Event>> {
        return EventsApi.usersAllEvents(userId).then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static getRelevantByGroup(groupId: number): Promise<Record<number, Event>> {
        return EventsApi.groupsEvents(groupId).then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static getArchiveByGroup(groupId: number, year: number): Promise<Record<number, Event>> {
        return EventsApi.groupsArchive(groupId, year).then(response => {
            return Event.newRecords(response.data.events, Event.parseExtendedResponse);
        });
    }

    static createNew(): Event {
        const event = new Event();

        event.name = '';
        event.locations = [];

        return event;
    }

    static parseExtendedResponse(event: Event, data: EventsExtendedEventResponse | EventsAdminEventResponse): Event {
        PublicEvent.parseResponse(event, data);

        event.group = data.groupId ? Group.getBySingleId(data.groupId) : null;
        event.roomStart = data.roomStart;
        event.roomFinish = data.roomFinish;
        event.outsiders = data.outsiders;
        event.externalWorkers = data.externalWorkers;
        event.milestones = Milestone.newArray(data.milestones, Milestone.parseResponse);
        event.closures = Closure.newRecords(data.closures, Closure.parseResponse);
        event.services = Service.createServicesArray(data.services);
        event.permitNeeded = data.permitNeeded;
        event.canServices = data.canEditServices;

        if ("billing" in data) {
            event.billing = data.billing;
            event.responsibleNeptun = data.responsibleNeptun;
        } else {
            event.billing = null;
        }

        return event;
    }

    get displayClosures(): string {
        if (isEmpty(this.closures)) {
            return '-';
        }

        return map(this.closures, closure => closure.type.name).join(',');
    }

    public hasBar(): boolean {
        return EventsServiceType.BAR in this.services;
    }

    public isActive(): boolean {
        if (this.finish.isPast()) {
            return false;
        }

        if (this.cancelled) {
            return false;
        }

        return true;
    }

    public store(form: FormRef): Promise<Event> {
        let groupId = this.group?.id ?? null;
        if (groupId === 0) {
            groupId = null;
        }

        const data: EventsEventStore = {
            name: this.name,
            group: groupId,
            locations: Event.entitiesToArray(this.locations),
        };

        return EventsApi.eventsStore(data, {form}).then(response => {
            return Event.newSingle(response.data, Event.parseExtendedResponse);
        });
    }

    public update(form: FormRef): Promise<void> {
        const data: EventsEventUpdate = {
            name: this.name,
            description: this.description ?? '',
            locations: Event.entitiesToArray(this.locations),
            start: this.start,
            finish: this.finish,
            roomStart: this.roomStart,
            roomFinish: this.roomFinish,
            participants: this.participants,
            outsiders: this.outsiders,
            externalWorkers: this.externalWorkers,
            billing: this.billing,
        };

        return EventsApi.eventsUpdate(this.id, data, {form}).then(response => {
            Event.newSingle(response.data, Event.parseExtendedResponse);
        });
    }

    public getDeadlines(): Promise<EventsDeadlinesResponse> {
        return EventsApi.eventsDeadlines(this.id).then(response => {
            return response.data;
        });
    }

    public submit(comment: string | null, form: FormRef): Promise<void> {
        return EventsApi.milestonesAdd(this.id, EventsMilestoneType.SUBMIT, {comment: comment}, {form}).then(response => {
            Event.parseExtendedResponse(this, response.data);
        });
    }

    public reject(comment: string | null, form: FormRef): Promise<void> {
        return EventsApi.milestonesAdd(this.id, EventsMilestoneType.REJECT, {comment: comment}, {form}).then(response => {
            Event.parseExtendedResponse(this, response.data);
        });
    }

    public approve(comment: string | null, form: FormRef): Promise<void> {
        return EventsApi.milestonesAdd(this.id, EventsMilestoneType.APPROVE, {comment: comment}, {form}).then(response => {
            Event.parseExtendedResponse(this, response.data);
        });
    }

    public addInternal(comment: string | null, form: FormRef): Promise<void> {
        return EventsApi.milestonesAdd(this.id, EventsMilestoneType.INTERNAL, {comment: comment}, {form}).then(response => {
            Event.parseExtendedResponse(this, response.data);
        });
    }

    public report(comment: string | null): Promise<void> {
        return EventsApi.milestonesAdd(this.id, EventsMilestoneType.REPORT, {comment: comment}).then(response => {
            Event.parseExtendedResponse(this, response.data);
        });
    }

    public reportAck(comment: string | null = null): Promise<void> {
        return EventsApi.milestonesAdd(this.id, EventsMilestoneType.REPORT_ACK, {comment: comment}).then(response => {
            Event.parseExtendedResponse(this, response.data);
        });
    }

    public cancel(comment: string | null, form: FormRef): Promise<void> {
        return EventsApi.milestonesAdd(this.id, EventsMilestoneType.CANCEL, {comment: comment}, {form}).then(response => {
            Event.parseExtendedResponse(this, response.data);
        });
    }

    public getPermit(): Promise<File> {
        return EventsApi.exportsPermit(this.id).then(response => File.createFromResponse(response));
    }
}
