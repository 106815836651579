/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryApprovalResponse {
  decision: boolean | null;
  decisionTime: DateTime | null;
  comment: string | null;
  decider: BaseMinimalUserResponse | null;
}

export const InventoryApprovalResponseSchema = {
  'decision': {
    name: 'decision',
    required: true,
    nullable: true,
    label: '',
    type: 'boolean',
  },
  'decisionTime': {
    name: 'decisionTime',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
      maxLength: 1000,
  },
  'decider': {
    name: 'decider',
    required: true,
    nullable: true,
      type: 'embedded',
  },
}
export function instanceOfInventoryApprovalResponse(value: object): value is InventoryApprovalResponse {
let isInstance = true;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "decisionTime" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "decider" in value;

return isInstance;
}

export function InventoryApprovalResponseFromJSON(json: any): InventoryApprovalResponse {
return InventoryApprovalResponseFromJSONTyped(json, false);
}

export function InventoryApprovalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryApprovalResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'decision': json['decision'],
        'decisionTime': (json['decisionTime'] === null ? null : DateTime.fromISO(json['decisionTime'])),
          'comment': json['comment'],
              'decider': BaseMinimalUserResponseFromJSON(json['decider']),
    };
  }

  export function InventoryApprovalResponseToJSON(value?: InventoryApprovalResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'decision': value.decision,
            'decisionTime': (
              value.decisionTime === null ? null : value.decisionTime.toISO()),
                'comment': value.comment,
                'decider': BaseMinimalUserResponseToJSON(value.decider),
    };
  }

  export function InventoryApprovalResponseCreateEmpty(): InventoryApprovalResponse {
  return {
      'decision': false,
      'decisionTime': DateTime.now(),
      'comment': '',
          'decider': BaseMinimalUserResponseCreateEmpty(),
  };
  }

