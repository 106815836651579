/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsManagerResponse } from './EventsManagerResponse';
    import {
    EventsManagerResponseFromJSON,
    EventsManagerResponseFromJSONTyped,
    EventsManagerResponseToJSON,
    EventsManagerResponseCreateEmpty,
    } from './EventsManagerResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsManagerSummaryResponse {
  managers: Array<EventsManagerResponse>;
  currentId: number;
  leaderId: number;
}

export const EventsManagerSummaryResponseSchema = {
  'managers': {
    name: 'managers',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'currentId': {
    name: 'currentId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'leaderId': {
    name: 'leaderId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfEventsManagerSummaryResponse(value: object): value is EventsManagerSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "managers" in value;
    isInstance = isInstance && "currentId" in value;
    isInstance = isInstance && "leaderId" in value;

return isInstance;
}

export function EventsManagerSummaryResponseFromJSON(json: any): EventsManagerSummaryResponse {
return EventsManagerSummaryResponseFromJSONTyped(json, false);
}

export function EventsManagerSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsManagerSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'managers': ((json['managers'] as Array<any>).map(EventsManagerResponseFromJSON)),
          'currentId': json['currentId'],
          'leaderId': json['leaderId'],
    };
  }

  export function EventsManagerSummaryResponseToJSON(value?: EventsManagerSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'managers': ((value.managers as Array<any>).map(EventsManagerResponseToJSON)),
                'currentId': value.currentId,
                'leaderId': value.leaderId,
    };
  }

  export function EventsManagerSummaryResponseCreateEmpty(): EventsManagerSummaryResponse {
  return {
      'managers': [],
      'currentId': 0,
      'leaderId': 0,
  };
  }

