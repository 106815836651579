/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoDone {
  reviewerGroup: number | null;
  done: boolean;
}

export const VikoDoneSchema = {
  'reviewerGroup': {
    name: 'reviewerGroup',
    required: true,
    nullable: true,
    label: 'Bírálói csoport',
    type: 'number',
      minimum: 0,
  },
  'done': {
    name: 'done',
    required: true,
    nullable: false,
    label: 'Kész',
    type: 'boolean',
  },
}
export function instanceOfVikoDone(value: object): value is VikoDone {
let isInstance = true;
    isInstance = isInstance && "reviewerGroup" in value;
    isInstance = isInstance && "done" in value;

return isInstance;
}

export function VikoDoneFromJSON(json: any): VikoDone {
return VikoDoneFromJSONTyped(json, false);
}

export function VikoDoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoDone {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'reviewerGroup': json['reviewerGroup'],
          'done': json['done'],
    };
  }

  export function VikoDoneToJSON(value?: VikoDone | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'reviewerGroup': value.reviewerGroup,
                'done': value.done,
    };
  }

  export function VikoDoneCreateEmpty(): VikoDone {
  return {
      'reviewerGroup': 0,
      'done': false,
  };
  }

