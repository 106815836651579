import {Base} from "@Models/Base";
import type {DateTime} from '@Utils/DateTime';
import {User} from "@Models/base/User";
import type {InventoryApprovalResponse} from "@/api/models";

export class Approval extends Base {
    decision: boolean | null;

    decisionTime: DateTime | null;

    comment: string | null;

    decider: User | null;

    static parseResponse(approval: Approval, data: InventoryApprovalResponse): Approval {
        approval.decision = data.decision;
        approval.decisionTime = data.decisionTime;
        approval.decider = User.newSingleNullable(data.decider, User.parseMinimalResponse);
        approval.comment = data.comment;

        return approval;
    }

    get status(): string {
        if (this.decision === null) {
            return 'Döntésre vár';
        }

        return this.decision
            ? 'Elfogadva'
            : 'Visszautasítva';
    }

    public getText(): { key: string, value: string }[] {
        const result = [
            {key: 'Állapot', value: this.status},
        ];

        if (this.decider) {
            result.push({key: 'Döntést meghozta', value: this.decider.name});
        }

        if (this.decisionTime) {
            result.push({key: 'Döntés ideje', value: this.decisionTime.datetime()});
        }

        if (this.comment) {
            result.push({key: 'Döntési megjegyzés', value: this.comment});
        }

        return result;
    }
}
