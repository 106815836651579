import type {App} from "vue";
import toast from "@Utils/toast";
import type {FormRef} from "@/vue";
import {ValidationError} from '@Utils/errors/ValidationError';
import {ResponseError} from '@/api/runtime';
import type {BaseValidationBagResponse} from '@/api/models';

export class ErrorHandler {
    public static installVue(vue: App) {
        vue.config.errorHandler = this.handleVueError;
    }

    public static async handleApiError(response: Response, json: any | null = null, form?: FormRef) {
        if (json === null) {
            json = await response.json();
        }

        const message = json?.message ?? "";

        switch (response.status) {
            case 401:
                ErrorHandler.displayMessage("Bejelentkezés szükséges", "");
                break;
            case 403:
                ErrorHandler.displayMessage("Hozzáférés megtagadva", message);
                break;
            case 404:
                ErrorHandler.displayMessage("Nem található", "");
                break;
            case 422:
                ErrorHandler.handleValidationError(json, form);
                break;
            case 500:
                ErrorHandler.displayMessage("Váratlan hiba történt", "");
        }

        throw new ResponseError(response, 'Response returned an error code');
    }

    public static handleVueError(err: any) {
        if (typeof err !== "object") {
            throw err;
        }

        if (!("name" in err)) {
            throw err;
        }

        switch (err.name) {
            case "AccessDeniedError":
                return ErrorHandler.displayMessage("Hozzáférés megtagadva", err.message);
            case "AuthenticationRequiredError":
                return ErrorHandler.displayMessage("Bejelentkezés szükséges", "");
            case "NotFoundError":
                return ErrorHandler.displayMessage("Nem található", err.message);
            case "RuntimeError":
                return ErrorHandler.displayMessage("Hiba történt", err.message);
        }

        throw err;
    }

    public static handleValidationError(errors: BaseValidationBagResponse, form?: FormRef) {
        if (!form) {
            throw new ValidationError(errors);
        }

        form.handle(errors);
    }

    private static displayMessage(title: string, message: string) {
        document.body.classList.remove("loading-body");
        document.getElementById("main-content")?.classList.remove("loading-body");

        toast.error(title, message);
    }
}
