<template>
    <PegasusModal header="Új bírálói csoport">
        <PegasusForm ref="form">
            <TextField
                v-model="name"
                :schema="VikoReviewerGroupStoreSchema.name"
            ></TextField>

            <PegasusButton variant="success" @click="save">
                Létrehozás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {VikoReviewerGroupStoreSchema, VikoReviewStage} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {ReviewerGroup} from '@Models/viko/ReviewerGroup';
import toast from '@Utils/toast';
import type {Tender} from '@Models/viko/Tender';
import {useReturn} from '@Utils/dialog';

const {tender} = defineProps<{
    tender: Tender
}>();

const name = ref('');

const form = ref<FormRef>(null);

const returnValue = useReturn();

function save() {
    let group = new ReviewerGroup();

    group.name = name.value;
    group.reviewStage = VikoReviewStage.STAGE_NONE;

    let request = group.store(tender.id, form.value).then(reviewerGroup => {
        if (tender.reviewerGroups === null) {
            throw new Error('Something is wrong');
        }

        tender.reviewerGroups[reviewerGroup.id] = reviewerGroup;

        returnValue(true);
    });

    toast.loading(request, 'Létrehozás');
}
</script>
