/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoCalcUpdateCategory } from './VikoCalcUpdateCategory';
    import {
    VikoCalcUpdateCategoryFromJSON,
    VikoCalcUpdateCategoryFromJSONTyped,
    VikoCalcUpdateCategoryToJSON,
    VikoCalcUpdateCategoryCreateEmpty,
    } from './VikoCalcUpdateCategory';
import {DateTime} from '@Utils/DateTime';

export interface VikoCalcUpdate {
  budget: number;
  categories: Array<VikoCalcUpdateCategory>;
}

export const VikoCalcUpdateSchema = {
  'budget': {
    name: 'budget',
    required: true,
    nullable: false,
    label: 'Teljes költségkeret',
    type: 'number',
      minimum: 0,
      maximum: 50000000,
  },
  'categories': {
    name: 'categories',
    required: true,
    nullable: false,
    label: 'Kategóriák számai',
      type: 'array',
  },
}
export function instanceOfVikoCalcUpdate(value: object): value is VikoCalcUpdate {
let isInstance = true;
    isInstance = isInstance && "budget" in value;
    isInstance = isInstance && "categories" in value;

return isInstance;
}

export function VikoCalcUpdateFromJSON(json: any): VikoCalcUpdate {
return VikoCalcUpdateFromJSONTyped(json, false);
}

export function VikoCalcUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoCalcUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'budget': json['budget'],
            'categories': ((json['categories'] as Array<any>).map(VikoCalcUpdateCategoryFromJSON)),
    };
  }

  export function VikoCalcUpdateToJSON(value?: VikoCalcUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'budget': value.budget,
              'categories': ((value.categories as Array<any>).map(VikoCalcUpdateCategoryToJSON)),
    };
  }

  export function VikoCalcUpdateCreateEmpty(): VikoCalcUpdate {
  return {
      'budget': 0,
      'categories': [],
  };
  }

