/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtAmendStore {
  application: number;
}

export const RvtAmendStoreSchema = {
  'application': {
    name: 'application',
    required: true,
    nullable: false,
    label: 'Kapcsolódó KPR pályázat',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfRvtAmendStore(value: object): value is RvtAmendStore {
let isInstance = true;
    isInstance = isInstance && "application" in value;

return isInstance;
}

export function RvtAmendStoreFromJSON(json: any): RvtAmendStore {
return RvtAmendStoreFromJSONTyped(json, false);
}

export function RvtAmendStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtAmendStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'application': json['application'],
    };
  }

  export function RvtAmendStoreToJSON(value?: RvtAmendStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'application': value.application,
    };
  }

  export function RvtAmendStoreCreateEmpty(): RvtAmendStore {
  return {
      'application': 0,
  };
  }

