/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsMinimalEventResponse {
  id: number;
  name: string;
  description: string;
  decision: boolean | null;
  participants: number;
  locations: Array<number>;
  groupId: number | null;
  responsible: BaseUserResponse;
  start: DateTime;
  finish: DateTime;
  finalized: DateTime | null;
  cancelled: DateTime | null;
  canRead: boolean;
  canEdit: boolean;
}

export const EventsMinimalEventResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'decision': {
    name: 'decision',
    required: true,
    nullable: true,
    label: '',
    type: 'boolean',
  },
  'participants': {
    name: 'participants',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'locations': {
    name: 'locations',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'start': {
    name: 'start',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'finish': {
    name: 'finish',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'finalized': {
    name: 'finalized',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'cancelled': {
    name: 'cancelled',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'canRead': {
    name: 'canRead',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfEventsMinimalEventResponse(value: object): value is EventsMinimalEventResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "participants" in value;
    isInstance = isInstance && "locations" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "finish" in value;
    isInstance = isInstance && "finalized" in value;
    isInstance = isInstance && "cancelled" in value;
    isInstance = isInstance && "canRead" in value;
    isInstance = isInstance && "canEdit" in value;

return isInstance;
}

export function EventsMinimalEventResponseFromJSON(json: any): EventsMinimalEventResponse {
return EventsMinimalEventResponseFromJSONTyped(json, false);
}

export function EventsMinimalEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsMinimalEventResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'decision': json['decision'],
          'participants': json['participants'],
          'locations': json['locations'],
          'groupId': json['groupId'],
              'responsible': BaseUserResponseFromJSON(json['responsible']),
        'start': (DateTime.fromISO(json['start'])),
        'finish': (DateTime.fromISO(json['finish'])),
        'finalized': (json['finalized'] === null ? null : DateTime.fromISO(json['finalized'])),
        'cancelled': (json['cancelled'] === null ? null : DateTime.fromISO(json['cancelled'])),
          'canRead': json['canRead'],
          'canEdit': json['canEdit'],
    };
  }

  export function EventsMinimalEventResponseToJSON(value?: EventsMinimalEventResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'decision': value.decision,
                'participants': value.participants,
            'locations': value.locations,
                'groupId': value.groupId,
                'responsible': BaseUserResponseToJSON(value.responsible),
            'start': (value.start.toISO()),
            'finish': (value.finish.toISO()),
            'finalized': (
              value.finalized === null ? null : value.finalized.toISO()),
            'cancelled': (
              value.cancelled === null ? null : value.cancelled.toISO()),
                'canRead': value.canRead,
                'canEdit': value.canEdit,
    };
  }

  export function EventsMinimalEventResponseCreateEmpty(): EventsMinimalEventResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'decision': false,
      'participants': 0,
      'locations': [],
      'groupId': 0,
          'responsible': BaseUserResponseCreateEmpty(),
      'start': DateTime.now(),
      'finish': DateTime.now(),
      'finalized': DateTime.now(),
      'cancelled': DateTime.now(),
      'canRead': false,
      'canEdit': false,
  };
  }

