/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsManagersAssignmentUpdate {
  from: DateTime;
  to: DateTime;
  manager: number;
}

export const EventsManagersAssignmentUpdateSchema = {
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: 'Eleje',
    type: 'datetime',
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: 'Vége',
    type: 'datetime',
  },
  'manager': {
    name: 'manager',
    required: true,
    nullable: false,
    label: 'Hetifőnök',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfEventsManagersAssignmentUpdate(value: object): value is EventsManagersAssignmentUpdate {
let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "manager" in value;

return isInstance;
}

export function EventsManagersAssignmentUpdateFromJSON(json: any): EventsManagersAssignmentUpdate {
return EventsManagersAssignmentUpdateFromJSONTyped(json, false);
}

export function EventsManagersAssignmentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsManagersAssignmentUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'from': (DateTime.fromISO(json['from'])),
        'to': (DateTime.fromISO(json['to'])),
          'manager': json['manager'],
    };
  }

  export function EventsManagersAssignmentUpdateToJSON(value?: EventsManagersAssignmentUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'from': (value.from.toISO()),
            'to': (value.to.toISO()),
                'manager': value.manager,
    };
  }

  export function EventsManagersAssignmentUpdateCreateEmpty(): EventsManagersAssignmentUpdate {
  return {
      'from': DateTime.now(),
      'to': DateTime.now(),
      'manager': 0,
  };
  }

