/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsServiceRemote {
  remoteId: number;
}

export const EventsServiceRemoteSchema = {
  'remoteId': {
    name: 'remoteId',
    required: true,
    nullable: false,
    label: 'Azonosító',
    type: 'number',
  },
}
export function instanceOfEventsServiceRemote(value: object): value is EventsServiceRemote {
let isInstance = true;
    isInstance = isInstance && "remoteId" in value;

return isInstance;
}

export function EventsServiceRemoteFromJSON(json: any): EventsServiceRemote {
return EventsServiceRemoteFromJSONTyped(json, false);
}

export function EventsServiceRemoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceRemote {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'remoteId': json['remoteId'],
    };
  }

  export function EventsServiceRemoteToJSON(value?: EventsServiceRemote | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'remoteId': value.remoteId,
    };
  }

  export function EventsServiceRemoteCreateEmpty(): EventsServiceRemote {
  return {
      'remoteId': 0,
  };
  }

