/* tslint:disable */
/* eslint-disable */

export enum EventsServiceType {
    AC = 'ac',
    BSS = 'bss',
    SPOT = 'spot',
    BAR = 'bar',
    CALENDAR_EVENT = 'calendarevent',
    EXTRA_TABLE = 'extratable',
    TABLE_SET = 'tableset',
    CAR_PERMIT = 'carpermit',
    ELECTRIC_PERMIT = 'electricpermit',
    ELEVATOR_PERMIT = 'elevatorpermit',
    ENTRANCE_PERMIT = 'entrancepermit',
    GUEST_LIST = 'guestlist',
    HEATING_PERMIT = 'heatingpermit',
    WORKER_LIST = 'workerlist'
}

export const EventsServiceTypeValues: Record<EventsServiceType, string> = {
    'ac': 'AC',
    'bss': 'BSS',
    'spot': 'SPOT',
    'bar': 'Homár',
    'calendarevent': 'Rendezvénynaptárban megjelenés',
    'extratable': 'Első emeleti asztal/szék igény',
    'tableset': 'Sörpad igény',
    'carpermit': 'Gépjármű behajtási engedély',
    'electricpermit': 'Kültéri áram igény',
    'elevatorpermit': 'Lift kikulcsolási igény',
    'entrancepermit': 'Gazdasági bejárat használati igény',
    'guestlist': 'Külsős vendéglista',
    'heatingpermit': 'Hőfejlesztési engedély',
    'workerlist': 'Külsős rendezői lista'
}


export function EventsServiceTypeFromJSON(json: any): EventsServiceType {
    return EventsServiceTypeFromJSONTyped(json, false);
}

export function EventsServiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceType {
    return json as EventsServiceType;
}

export function EventsServiceTypeToJSON(value?: EventsServiceType | null): any {
    return value as any;
}

export function EventsServiceTypeCreateEmpty(): EventsServiceType {
    return EventsServiceType.AC;
}

