/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneySpendingEventInvoiceUploaded {
  invoiceId: number;
}

export const MoneySpendingEventInvoiceUploadedSchema = {
  'invoiceId': {
    name: 'invoiceId',
    required: true,
    nullable: false,
    label: 'Összeg',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfMoneySpendingEventInvoiceUploaded(value: object): value is MoneySpendingEventInvoiceUploaded {
let isInstance = true;
    isInstance = isInstance && "invoiceId" in value;

return isInstance;
}

export function MoneySpendingEventInvoiceUploadedFromJSON(json: any): MoneySpendingEventInvoiceUploaded {
return MoneySpendingEventInvoiceUploadedFromJSONTyped(json, false);
}

export function MoneySpendingEventInvoiceUploadedFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingEventInvoiceUploaded {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'invoiceId': json['invoiceId'],
    };
  }

  export function MoneySpendingEventInvoiceUploadedToJSON(value?: MoneySpendingEventInvoiceUploaded | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'invoiceId': value.invoiceId,
    };
  }

  export function MoneySpendingEventInvoiceUploadedCreateEmpty(): MoneySpendingEventInvoiceUploaded {
  return {
      'invoiceId': 0,
  };
  }

