import {Base} from "@Models/Base";
import type {BaseResortSummaryResponse} from "@/api/api";
import {MainApi} from "@/api/api";
import {ExtendedGroupSummary} from '@Models/rvt/ExtendedGroupSummary';

export class ResortSummary extends Base {
    groups: Record<number, ExtendedGroupSummary>;

    static getById(id: number): Promise<ResortSummary> {
        return MainApi.groupsResortSummary(id).then(response => {
            return ResortSummary.newSingle(response.data, ResortSummary.parseResponse);
        });
    }

    static parseResponse(summary: ResortSummary, data: BaseResortSummaryResponse): ResortSummary {
        summary.groups = ExtendedGroupSummary.newRecordsBy(
            data.groups,
            ExtendedGroupSummary.parseResponse,
            groupSummary => groupSummary.group.id
        );

        return summary;
    }
}
