/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAssignmentMonthUpdate {
  year: number;
  month: number;
  canEditAvailability: boolean;
}

export const MentorsAssignmentMonthUpdateSchema = {
  'year': {
    name: 'year',
    required: true,
    nullable: false,
    label: 'Év',
    type: 'number',
      minimum: 2000,
  },
  'month': {
    name: 'month',
    required: true,
    nullable: false,
    label: 'Hónap',
    type: 'number',
      minimum: 1,
      maximum: 12,
  },
  'canEditAvailability': {
    name: 'canEditAvailability',
    required: true,
    nullable: false,
    label: 'Ráérési poll',
    type: 'boolean',
  },
}
export function instanceOfMentorsAssignmentMonthUpdate(value: object): value is MentorsAssignmentMonthUpdate {
let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "canEditAvailability" in value;

return isInstance;
}

export function MentorsAssignmentMonthUpdateFromJSON(json: any): MentorsAssignmentMonthUpdate {
return MentorsAssignmentMonthUpdateFromJSONTyped(json, false);
}

export function MentorsAssignmentMonthUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAssignmentMonthUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'year': json['year'],
          'month': json['month'],
          'canEditAvailability': json['canEditAvailability'],
    };
  }

  export function MentorsAssignmentMonthUpdateToJSON(value?: MentorsAssignmentMonthUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'year': value.year,
                'month': value.month,
                'canEditAvailability': value.canEditAvailability,
    };
  }

  export function MentorsAssignmentMonthUpdateCreateEmpty(): MentorsAssignmentMonthUpdate {
  return {
      'year': 0,
      'month': 0,
      'canEditAvailability': false,
  };
  }

