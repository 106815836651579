/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetLocationStore {
  name: string;
  cardAllowed: boolean;
}

export const MoneyBudgetLocationStoreSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
  },
  'cardAllowed': {
    name: 'cardAllowed',
    required: true,
    nullable: false,
    label: 'Bankkártyával rendelkezik',
    type: 'boolean',
  },
}
export function instanceOfMoneyBudgetLocationStore(value: object): value is MoneyBudgetLocationStore {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "cardAllowed" in value;

return isInstance;
}

export function MoneyBudgetLocationStoreFromJSON(json: any): MoneyBudgetLocationStore {
return MoneyBudgetLocationStoreFromJSONTyped(json, false);
}

export function MoneyBudgetLocationStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetLocationStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'cardAllowed': json['cardAllowed'],
    };
  }

  export function MoneyBudgetLocationStoreToJSON(value?: MoneyBudgetLocationStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'cardAllowed': value.cardAllowed,
    };
  }

  export function MoneyBudgetLocationStoreCreateEmpty(): MoneyBudgetLocationStore {
  return {
      'name': '',
      'cardAllowed': false,
  };
  }

