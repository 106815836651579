import {onMounted, onUnmounted} from "vue";
import {onBeforeRouteLeave} from "vue-router";

export function usePreventExit(condition: () => boolean) {
    function check(event: BeforeUnloadEvent) {
        if (condition()) {
            event.preventDefault();

            event.returnValue = "false"; // Legacy support
        }
    }

    onMounted(() => window.addEventListener("beforeunload", check));

    onUnmounted(() => window.removeEventListener("beforeunload", check));
}

export function useFullWidth() {
    onMounted(() => document.getElementById("main-container-md")?.classList.remove("container-md"));

    onBeforeRouteLeave(() => document.getElementById("main-container-md")?.classList.add("container-md"));
}
