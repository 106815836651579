<template>
    <PegasusHeading>Felhasználók adminisztrációja</PegasusHeading>

    <PegasusTable :data="load" :per-page="10" search>
        <Column field="name" header="Név"></Column>
        <Column field="email" header="E-mail"></Column>
        <Column header="Műveletek">
            <template #body="{data}">
                <PegasusButton :params="{user: data}" to="rvt.admin.users.show" variant="primary">
                    Megtekintés
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<title>Felhasználó adminisztráció</title>

<script lang="ts" setup>
import {User} from "@Models/base/User";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

useAuthStore().requireUser();

function load(page: number, perPage: number, filter: string | undefined) {
    return User.searchUsers(page, perPage, filter ?? '').then(response => {
        return {
            data: response.data,
            total: response.total,
        };
    });
}
</script>
