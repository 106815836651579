/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoReportDecide {
  decision: boolean;
  comment: string | null;
}

export const VikoReportDecideSchema = {
  'decision': {
    name: 'decision',
    required: true,
    nullable: false,
    label: 'Döntés',
    type: 'boolean',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: true,
    label: 'Indoklás',
      labelHelp: `Ezt az indoklást a pályázó meg fogja kapni.`,
    type: 'string',
      maxLength: 500,
  },
}
export function instanceOfVikoReportDecide(value: object): value is VikoReportDecide {
let isInstance = true;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function VikoReportDecideFromJSON(json: any): VikoReportDecide {
return VikoReportDecideFromJSONTyped(json, false);
}

export function VikoReportDecideFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReportDecide {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'decision': json['decision'],
          'comment': json['comment'],
    };
  }

  export function VikoReportDecideToJSON(value?: VikoReportDecide | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'decision': value.decision,
                'comment': value.comment,
    };
  }

  export function VikoReportDecideCreateEmpty(): VikoReportDecide {
  return {
      'decision': false,
      'comment': '',
  };
  }

