import {Base} from "@Models/Base";
import {Tender} from "@Models/viko/Tender";
import type {VikoTenderProblemsResponse} from "@/api/api";
import {VikoApi} from "@/api/api";
import {Report} from '@Models/viko/Report';

export class TenderProblems extends Base {
    tender: Tender;

    rejectedReports: Record<number, Report>;

    unreviewedReports: Record<number, Report>;

    static getByTender(id: number): Promise<TenderProblems> {
        return VikoApi.tendersProblems(id).then(response => {
            return TenderProblems.newSingle(response.data, TenderProblems.parseResponse);
        });
    }

    static parseResponse(problems: TenderProblems, data: VikoTenderProblemsResponse): TenderProblems {
        problems.tender = Tender.newSingle(data.tender, Tender.parseExtendedResponse);
        problems.rejectedReports = Report.newRecords(data.rejectedReports, Report.parseResponse);
        problems.unreviewedReports = Report.newRecords(data.unreviewedReports, Report.parseResponse);

        return problems;
    }
}
