/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoReviewUpdate {
  finalPoints: number;
}

export const VikoReviewUpdateSchema = {
  'finalPoints': {
    name: 'finalPoints',
    required: true,
    nullable: false,
    label: 'Végleges pontszám',
    type: 'number',
      minimum: 1,
      maximum: 5,
  },
}
export function instanceOfVikoReviewUpdate(value: object): value is VikoReviewUpdate {
let isInstance = true;
    isInstance = isInstance && "finalPoints" in value;

return isInstance;
}

export function VikoReviewUpdateFromJSON(json: any): VikoReviewUpdate {
return VikoReviewUpdateFromJSONTyped(json, false);
}

export function VikoReviewUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReviewUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'finalPoints': json['finalPoints'],
    };
  }

  export function VikoReviewUpdateToJSON(value?: VikoReviewUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'finalPoints': value.finalPoints,
    };
  }

  export function VikoReviewUpdateCreateEmpty(): VikoReviewUpdate {
  return {
      'finalPoints': 0,
  };
  }

