import {BaseEntity} from "@Models/BaseEntity";
import {User} from "@Models/base/User";
import {DateTime} from "@Utils/DateTime";
import type {InventoryUpdateResponse} from "@/api/api";
import {InventoryUpdateType, InventoryUpdateTypeValues} from "@/api/api";

export abstract class ItemUpdate extends BaseEntity {
    type: InventoryUpdateType;

    comment: string = '';

    creator: User;

    createdAt: DateTime;

    get text(): {key: string, value: string}[] {
        return [];
    }

    static parseResponse(update: ItemUpdate, data: InventoryUpdateResponse): ItemUpdate {
        update.id = data.id;
        update.creator = User.newSingle(data.creator, User.parseMinimalResponse);
        update.comment = data.comment;
        update.createdAt = data.createdAt;

        return update;
    }

    static getIcons(): Record<InventoryUpdateType, [string, string]> {
        return {
            [InventoryUpdateType.CREATION]: ['fa fa-plus', 'bg-success'],
            [InventoryUpdateType.REPAIR]: ['fa fa-screwdriver-wrench', 'bg-success'],
            [InventoryUpdateType.DAMAGE]: ['fa fa-heart-circle-minus', 'bg-warning'],
            [InventoryUpdateType.TERMINATION]: ['fa fa-recycle', 'bg-danger'],
            [InventoryUpdateType.CHECK]: ['fa fa-check', 'bg-success'],
            [InventoryUpdateType.TRANSFER]: ['fa fa-right-left', 'bg-warning'],
            [InventoryUpdateType.DATA_CHANGE]: ['fa fa-user-pen', 'bg-primary'],
            [InventoryUpdateType.LENDING]: ['fa fa-calendar', 'bg-primary'],
        };
    }

    public getName(): string {
        return InventoryUpdateTypeValues[this.type];
    }
}
