/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsAssignment } from './MentorsAssignment';
    import {
    MentorsAssignmentFromJSON,
    MentorsAssignmentFromJSONTyped,
    MentorsAssignmentToJSON,
    MentorsAssignmentCreateEmpty,
    } from './MentorsAssignment';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAssignmentUpdate {
  year: number;
  month: number;
  assignments: Array<MentorsAssignment>;
}

export const MentorsAssignmentUpdateSchema = {
  'year': {
    name: 'year',
    required: true,
    nullable: false,
    label: 'Év',
    type: 'number',
      minimum: 2000,
  },
  'month': {
    name: 'month',
    required: true,
    nullable: false,
    label: 'Hónap',
    type: 'number',
      minimum: 1,
      maximum: 12,
  },
  'assignments': {
    name: 'assignments',
    required: true,
    nullable: false,
    label: 'Beosztás',
      type: 'array',
  },
}
export function instanceOfMentorsAssignmentUpdate(value: object): value is MentorsAssignmentUpdate {
let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "assignments" in value;

return isInstance;
}

export function MentorsAssignmentUpdateFromJSON(json: any): MentorsAssignmentUpdate {
return MentorsAssignmentUpdateFromJSONTyped(json, false);
}

export function MentorsAssignmentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAssignmentUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'year': json['year'],
          'month': json['month'],
            'assignments': ((json['assignments'] as Array<any>).map(MentorsAssignmentFromJSON)),
    };
  }

  export function MentorsAssignmentUpdateToJSON(value?: MentorsAssignmentUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'year': value.year,
                'month': value.month,
              'assignments': ((value.assignments as Array<any>).map(MentorsAssignmentToJSON)),
    };
  }

  export function MentorsAssignmentUpdateCreateEmpty(): MentorsAssignmentUpdate {
  return {
      'year': 0,
      'month': 0,
      'assignments': [],
  };
  }

