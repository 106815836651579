import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {
    type MoneySpendingEventCashIn,
    MoneySpendingEventType,
    type MoneySpendingItemEvent,
    type MoneySpendingItemEventResponse
} from '@/api/models';
import {MoneyApi} from '@/api/apis';
import type {FormRef} from '@/vue';

export class CashIn extends SpendingItemEvent {
    money: number;

    static parseResponse(cashIn: CashIn, data: MoneySpendingItemEventResponse): CashIn {
        SpendingItemEvent.parseResponse(cashIn, data);

        const specific = data.data as MoneySpendingEventCashIn;
        cashIn.money = specific.money;

        return cashIn;
    }

    public store(itemId: number, form: FormRef): Promise<CashIn> {
        const data: MoneySpendingItemEvent = {
            data: {
                type: MoneySpendingEventType.CASH_IN,
                money: this.money
            },
            comment: this.comment
        };

        return MoneyApi.spendingEventsCashIn(itemId, data, {form}).then(response => {
            return CashIn.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        return [
            {key: 'Összeg', value: this.money.huf()},
        ];
    }
}
