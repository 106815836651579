import * as Papa from 'papaparse';
import {type CommonPersonData, CommonPersonDataSchema} from '@/api/models';

export class PersonCsv {
    public parseCsv(csvText: string): Promise<{
        name: string,
        id: string,
        card: boolean
    }[]> {
        return new Promise((resolve, reject) => {
            Papa.parse<string[]>(csvText.trimEnd(), {
                complete: results => {
                    if (results.errors.length > 0) {
                        reject('Hibás CSV fájl');
                    }

                    const data = results.data;

                    const guests = [];
                    for (const number in data) {
                        const line = data[number];

                        try {
                            guests.push(this.processLine(line));
                        } catch (error) {
                            if (error instanceof Error) {
                                throw new Error('Feldolgozási hiba, sor: ' + (number + 1) + ', hiba: ' + error.message);
                            }
                        }
                    }

                    resolve(guests);
                },
                error: () => {
                    throw new Error('Feldolgozási hiba, érvénytelen fájl');
                }
            });
        });
    }

    private processLine(line: any[]): CommonPersonData {
        if (line.length !== 3) {
            throw new Error('Oszlopok száma hibás');
        }

        if (line[0].length < CommonPersonDataSchema.name.minLength) {
            throw new Error('Túl rövid név');
        }

        if (line[0].length > CommonPersonDataSchema.name.maxLength) {
            throw new Error('Túl hosszú név');
        }

        if (line[1].length > CommonPersonDataSchema.id.maxLength) {
            throw new Error('Túl hosszú személyi igazolvány szám');
        }

        if (line[1].length < CommonPersonDataSchema.id.minLength) {
            throw new Error('Túl rövid személyi igazolvány szám');
        }

        const card = ((): boolean => {
            const value = line[2];
            if (!value) {
                return false;
            }

            if (value.toLowerCase() === 'igen') {
                return true;
            }

            if (value.toLowerCase() === 'true') {
                return true;
            }

            return false;
        })();

        return {
            name: line[0],
            id: line[1],
            card: card
        };
    }
}
