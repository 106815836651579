/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseKprConstantsResponse {
  permissionMatrix: { [key: string]: { [key: string]: Array<number>; }; };
}

export const BaseKprConstantsResponseSchema = {
  'permissionMatrix': {
    name: 'permissionMatrix',
    required: true,
    nullable: false,
    label: '',
  },
}
export function instanceOfBaseKprConstantsResponse(value: object): value is BaseKprConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "permissionMatrix" in value;

return isInstance;
}

export function BaseKprConstantsResponseFromJSON(json: any): BaseKprConstantsResponse {
return BaseKprConstantsResponseFromJSONTyped(json, false);
}

export function BaseKprConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseKprConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'permissionMatrix': json['permissionMatrix'],
    };
  }

  export function BaseKprConstantsResponseToJSON(value?: BaseKprConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'permissionMatrix': value.permissionMatrix,
    };
  }

  export function BaseKprConstantsResponseCreateEmpty(): BaseKprConstantsResponse {
  return {
      'permissionMatrix': {},
  };
  }

