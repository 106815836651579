/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsBarData {
  from: DateTime;
  to: DateTime;
}

export const EventsBarDataSchema = {
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: 'Nyitvatartás kezdete',
    type: 'datetime',
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: 'Nyitvatartás vége',
    type: 'datetime',
  },
}
export function instanceOfEventsBarData(value: object): value is EventsBarData {
let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;

return isInstance;
}

export function EventsBarDataFromJSON(json: any): EventsBarData {
return EventsBarDataFromJSONTyped(json, false);
}

export function EventsBarDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsBarData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'from': (DateTime.fromISO(json['from'])),
        'to': (DateTime.fromISO(json['to'])),
    };
  }

  export function EventsBarDataToJSON(value?: EventsBarData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'from': (value.from.toISO()),
            'to': (value.to.toISO()),
    };
  }

  export function EventsBarDataCreateEmpty(): EventsBarData {
  return {
      'from': DateTime.now(),
      'to': DateTime.now(),
  };
  }

