/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryQuality } from './InventoryQuality';
    import {
    InventoryQualityFromJSON,
    InventoryQualityFromJSONTyped,
    InventoryQualityToJSON,
    InventoryQualityCreateEmpty,
    } from './InventoryQuality';
import {DateTime} from '@Utils/DateTime';
import { InventoryQualityValues } from './InventoryQuality';

export interface InventoryUpdateRepair {
  quality: InventoryQuality;
  description: string;
  price: number;
}

export const InventoryUpdateRepairSchema = {
  'quality': {
    name: 'quality',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Állapot",
        enumValues: InventoryQualityValues,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Javítás leírása',
    type: 'string',
      maxLength: 1000,
  },
  'price': {
    name: 'price',
    required: true,
    nullable: false,
    label: 'Javítás költsége',
    type: 'number',
      minimum: 0,
      maximum: 10000000,
  },
}
export function instanceOfInventoryUpdateRepair(value: object): value is InventoryUpdateRepair {
let isInstance = true;
    isInstance = isInstance && "quality" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;

return isInstance;
}

export function InventoryUpdateRepairFromJSON(json: any): InventoryUpdateRepair {
return InventoryUpdateRepairFromJSONTyped(json, false);
}

export function InventoryUpdateRepairFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateRepair {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'quality': InventoryQualityFromJSON(json['quality']),
          'description': json['description'],
          'price': json['price'],
    };
  }

  export function InventoryUpdateRepairToJSON(value?: InventoryUpdateRepair | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'quality': InventoryQualityToJSON(value.quality),
                'description': value.description,
                'price': value.price,
    };
  }

  export function InventoryUpdateRepairCreateEmpty(): InventoryUpdateRepair {
  return {
          'quality': InventoryQualityCreateEmpty(),
      'description': '',
      'price': 0,
  };
  }

