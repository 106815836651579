/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsClosureResponse {
  id: number;
  begin: DateTime;
  end: DateTime;
  typeId: number;
  eventId: number;
  comment: string;
  disrupts: boolean;
}

export const EventsClosureResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'begin': {
    name: 'begin',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'end': {
    name: 'end',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'typeId': {
    name: 'typeId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'eventId': {
    name: 'eventId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'disrupts': {
    name: 'disrupts',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfEventsClosureResponse(value: object): value is EventsClosureResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "begin" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "typeId" in value;
    isInstance = isInstance && "eventId" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "disrupts" in value;

return isInstance;
}

export function EventsClosureResponseFromJSON(json: any): EventsClosureResponse {
return EventsClosureResponseFromJSONTyped(json, false);
}

export function EventsClosureResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsClosureResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
        'begin': (DateTime.fromISO(json['begin'])),
        'end': (DateTime.fromISO(json['end'])),
          'typeId': json['typeId'],
          'eventId': json['eventId'],
          'comment': json['comment'],
          'disrupts': json['disrupts'],
    };
  }

  export function EventsClosureResponseToJSON(value?: EventsClosureResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
            'begin': (value.begin.toISO()),
            'end': (value.end.toISO()),
                'typeId': value.typeId,
                'eventId': value.eventId,
                'comment': value.comment,
                'disrupts': value.disrupts,
    };
  }

  export function EventsClosureResponseCreateEmpty(): EventsClosureResponse {
  return {
      'id': 0,
      'begin': DateTime.now(),
      'end': DateTime.now(),
      'typeId': 0,
      'eventId': 0,
      'comment': '',
      'disrupts': false,
  };
  }

