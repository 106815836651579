/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupPermissionUpdate {
  inherit: boolean;
}

export const BaseGroupPermissionUpdateSchema = {
  'inherit': {
    name: 'inherit',
    required: true,
    nullable: false,
    label: 'Öröklődő',
    type: 'boolean',
  },
}
export function instanceOfBaseGroupPermissionUpdate(value: object): value is BaseGroupPermissionUpdate {
let isInstance = true;
    isInstance = isInstance && "inherit" in value;

return isInstance;
}

export function BaseGroupPermissionUpdateFromJSON(json: any): BaseGroupPermissionUpdate {
return BaseGroupPermissionUpdateFromJSONTyped(json, false);
}

export function BaseGroupPermissionUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupPermissionUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'inherit': json['inherit'],
    };
  }

  export function BaseGroupPermissionUpdateToJSON(value?: BaseGroupPermissionUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'inherit': value.inherit,
    };
  }

  export function BaseGroupPermissionUpdateCreateEmpty(): BaseGroupPermissionUpdate {
  return {
      'inherit': false,
  };
  }

