<template>
    <PegasusHeading>Lezáratás adminisztráció</PegasusHeading>

    <PegasusPanel header="Lezáratás típusok">
        <PegasusTable :data="types">
            <Column field="name" header="Név"></Column>

            <Column :field="item => item.price.huf()" header="Óradíj"></Column>

            <Column :field="item => item.public ? 'Igen' : 'Nem'" header="Publikus"></Column>

            <Column header="Műveletek">
                <template #body="{data}: {data: ClosureType}">
                    <PegasusButton :params="{type: data}" to="events.admin.closureTypes.edit" variant="primary">
                        <i class="fa fa-edit"></i> Szerkesztés
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>Rendezvények admin</title>

<script lang="ts" setup>
import {ref} from "vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ClosureType} from '@Models/events/ClosureType';

useAuthStore().requireUser();

const types = ref(await ClosureType.getAll());
</script>
