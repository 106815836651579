import * as Papa from 'papaparse';
import type {FieldSchema} from '@Components/base/form/FieldProperties';
import {File} from '@Models/File';
import {GuestList} from '@Models/events/services/GuestList';
import type {CommonPersonData} from '@/api/models';
import toast from '@Utils/toast';
import {PersonCsv} from '@Models/PersonCsv';

export class WorkerList extends GuestList {
    public static async parseCsv(file: File): Promise<CommonPersonData[]> {
        if (file.data.size > 50 * 1024) {
            toast.error('50kb-nál nagyobb fájl nem engedélyezett');
            return [];
        }

        let workers: CommonPersonData[];

        try {
            workers = await new PersonCsv().parseCsv(await file.data.text());
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
            }
            return [];
        }

        if (workers.length > 50) {
            toast.error('50-nél több dolgozó felvitele nem lehetséges');
            return [];
        }

        return workers;
    }

    public static createCsv(workers: CommonPersonData[]): File {
        const string = Papa.unparse(workers, {header: false});

        return File.createFromBlob(new Blob([string]), 'workers.csv');
    }

    public static fileSchema(): FieldSchema {
        return {
            name: 'workerListFile',
            label: 'Külsős dolgozói csv',
            labelHelp: '3 oszlopos CSV fájl, ahol az oszlopok a fenti sorren szerint.\n'
                + 'A rendelkezik kártyánál igen/Igen érték jelenti az igent, bármi más a nemet.',
            required: true,
            nullable: false,
            type: 'file',
            extensions: ['csv', 'tsv']
        };
    }
}
