/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryUpdateTransfer {
  from: number;
  to: number;
}

export const InventoryUpdateTransferSchema = {
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: 'Átadó kör',
    type: 'number',
      minimum: 0,
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: 'Cél kör',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfInventoryUpdateTransfer(value: object): value is InventoryUpdateTransfer {
let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;

return isInstance;
}

export function InventoryUpdateTransferFromJSON(json: any): InventoryUpdateTransfer {
return InventoryUpdateTransferFromJSONTyped(json, false);
}

export function InventoryUpdateTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateTransfer {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'from': json['from'],
          'to': json['to'],
    };
  }

  export function InventoryUpdateTransferToJSON(value?: InventoryUpdateTransfer | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'from': value.from,
                'to': value.to,
    };
  }

  export function InventoryUpdateTransferCreateEmpty(): InventoryUpdateTransfer {
  return {
      'from': 0,
      'to': 0,
  };
  }

