<template>
    <PegasusHeading>{{ tender.displayName }}</PegasusHeading>

    <div class="mt-2">
        <div class="row">
            <div class="col-lg-6">
                <PegasusPanel header="Statisztikák" header-bg-variant="info">
                    <PegasusButton @click="Dialog.open(UnapprovedItems, {tenderId: tenderId})">
                        Elbírálatlan tételek
                    </PegasusButton>
                </PegasusPanel>
            </div>

            <div class="col-lg-6">
                <PegasusPanel :header-bg-variant="dirty ? 'warning' : 'primary'" header="Műveletek">
                    <PegasusButton variant="success" @click="save">
                        <i class="fa fa-save"></i> Mentés
                    </PegasusButton>

                    <PegasusButton
                        :href="'/api/kpr/tenders/' + tender.id + '/rules'"
                        target="_blank"
                        variant="primary"
                    >
                        <i class="fa fa-external-link"></i> Pályázati kiírás
                    </PegasusButton>

                    <PegasusButton variant="warning" @click="sgrExport">
                        <i class="fa fa-download"></i> SGR export
                    </PegasusButton>

                    <PegasusButton variant="danger" @click="deleteTender">
                        <i class="fa fa-trash"></i> Pályázat törlése
                    </PegasusButton>
                </PegasusPanel>
            </div>
        </div>

        <div class="row">
            <div v-for="category in tender.categories" :key="category.id" class="col-sm-4">
                <PegasusPanel :header="category.name">
                    <DatetimeField
                        v-model="category.endDrafting"
                        :schema="KprCategoryUpdateSchema.endDrafting"
                        @update:model-value="dirty = true"
                    ></DatetimeField>

                    <SelectField
                        v-model="category.status"
                        :options="dictNumberEnum(KprCategoryStatusValues)"
                        :schema="KprCategoryUpdateSchema.status"
                        @update:model-value="dirty = true"
                    ></SelectField>

                    <NumberField
                        v-model="category.budget"
                        :schema="KprCategoryUpdateSchema.budget"
                        @update:model-value="dirty = true"
                    ></NumberField>

                    <PermissionMatrix :data="matrix[category.status]"></PermissionMatrix>
                </PegasusPanel>
            </div>
        </div>
    </div>
</template>

<title>KPR admin</title>

<script lang="ts" setup>
import {Tender} from "@Models/rvt/kpr/Tender";
import {ref} from "vue";
import toast from "@Utils/toast";
import {dictNumberEnum} from "@Models/Utils";
import {KprCategoryStatusValues, KprCategoryUpdateSchema} from "@/api/models";
import DatetimeField from "@Components/base/form/types/DatetimeField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import NumberField from "@Components/base/form/types/NumberField.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {usePreventExit} from "@Utils/composables";
import {useAuthStore} from "@/stores/auth";
import {useConstantsStore} from "@/stores/constants";
import PermissionMatrix from '@Components/kpr/PermissionMatrix.vue';
import {Dialog} from '@Utils/dialog';
import UnapprovedItems from '@Components/kpr/statistics/UnapprovedItems.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {tenderId} = defineProps<{
    tenderId: number;
}>();

useAuthStore().requireUser();

const tender = ref(Tender.getById(tenderId));

const matrix = useConstantsStore().constants.kpr.permissionMatrix;

const dirty = ref(false);

usePreventExit(() => dirty.value);

function save() {
    let request = tender.value.update().then(() => {
        dirty.value = false;
    });

    toast.loading(request);
}

function sgrExport() {
    let request = tender.value.sgrExport().then(file => {
        file.download();
    });

    toast.loading(request, 'Exportálás');
}

async function deleteTender() {
    if (!await toast.confirm("Biztos vagy benne? Az összes kapcsolódó tételt, módosítókat, MINDENT IS LETÖRÖL!!!")) {
        return;
    }

    if (!await toast.confirm("Tényleg nagyon biztos vagy benne? VISSZAVONHATATLAN!!!")) {
        return;
    }

    toast.info("Törlés nem lehetséges");
    throw new Error("Tried to delete tender");
}
</script>
