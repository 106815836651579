<template>
    <PegasusOverlay :content="tag.description" :header="tag.name">
        <div :class="classObject">
            <i :class="tag.icon"></i>

            <div class="ms-1">{{ tag.name }}</div>

            <PegasusBadge variant="secondary">{{ count }} tétel</PegasusBadge>

            <div style="margin-left: auto">
                <PegasusButton v-if="group" :params="{group, tag: tag.identifier}" popover="Tételek" size="sm" to="inventory.groups.itemsByTag" variant="info">
                    <i class="fa fa-list"></i>
                </PegasusButton>

                <PegasusButton v-else :params="{tag: tag.identifier}" popover="Tételek" size="sm" to="inventory.admin.itemsByTag" variant="info">
                    <i class="fa fa-list"></i>
                </PegasusButton>
            </div>
        </div>
    </PegasusOverlay>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import PegasusOverlay from "@Components/base/PegasusOverlay.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import type {Group} from "@Models/base/Group";
import PegasusBadge from '@Components/base/PegasusBadge.vue';
import type {Tag} from '@Models/inventory/Tag';

const {group, tag, count} = defineProps<{
    group?: Group
    tag: Tag,
    count: number
}>();

const classObject = computed(() => {
    return 'inventory-itemGroup alert alert-' + buttonVariant.value;
});

const buttonVariant = computed(() => {
    switch (tag.severity) {
        case "primary":
        case "secondary":
        case "info":
            return 'info';
        case "success":
            return 'success';
        case "warning":
            return 'warning';
        case "danger":
            return 'danger';
        case "light":
        case "dark":
            return 'info';
        default:
            throw new Error('Invalid severity: ' + tag.severity);
    }
});
</script>
