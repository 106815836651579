/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseAssignedGroupPermissionResponse } from './BaseAssignedGroupPermissionResponse';
    import {
    BaseAssignedGroupPermissionResponseFromJSON,
    BaseAssignedGroupPermissionResponseFromJSONTyped,
    BaseAssignedGroupPermissionResponseToJSON,
    BaseAssignedGroupPermissionResponseCreateEmpty,
    } from './BaseAssignedGroupPermissionResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupPostResponse {
  id: number;
  name: string;
  groupPermissions: Array<BaseAssignedGroupPermissionResponse>;
}

export const BaseGroupPostResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'groupPermissions': {
    name: 'groupPermissions',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseGroupPostResponse(value: object): value is BaseGroupPostResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "groupPermissions" in value;

return isInstance;
}

export function BaseGroupPostResponseFromJSON(json: any): BaseGroupPostResponse {
return BaseGroupPostResponseFromJSONTyped(json, false);
}

export function BaseGroupPostResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupPostResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
            'groupPermissions': ((json['groupPermissions'] as Array<any>).map(BaseAssignedGroupPermissionResponseFromJSON)),
    };
  }

  export function BaseGroupPostResponseToJSON(value?: BaseGroupPostResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
              'groupPermissions': ((value.groupPermissions as Array<any>).map(BaseAssignedGroupPermissionResponseToJSON)),
    };
  }

  export function BaseGroupPostResponseCreateEmpty(): BaseGroupPostResponse {
  return {
      'id': 0,
      'name': '',
      'groupPermissions': [],
  };
  }

