import {BaseEntity} from '@Models/BaseEntity';
import type {MoneyBudgetLocationResponse} from '@/api/models';
import {useConstantsStore} from '@/stores/constants';

export class BudgetLocation extends BaseEntity {
    name: string;

    static getById(id: number): BudgetLocation {
        return useConstantsStore().constants.money.budgetLocations[id];
    }

    static parseResponse(location: BudgetLocation, data: MoneyBudgetLocationResponse): BudgetLocation {
        location.id = data.id;
        location.name = data.name;

        return location;
    }
}
