<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <Dropdown
                v-model="modelValue"

                :class="{'p-invalid': isValid === false}"
                :disabled="disabled"
                :input-id="id"
                :loading="loading"
                :options="options ?? []"
                class="w-100"

                empty-filter-message="Nem található ilyen felhasználó"
                empty-message="Nincs felhasználó kiválasztva"
                filter
                option-label="label"
                option-value="value"
                placeholder="Nincs felhasználó kiválasztva"

                @filter="filter"
            >
            </Dropdown>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import SmartField from "@Components/base/form/SmartField.vue";
import {type FieldSchema} from "@Components/base/form/FieldProperties";
import {ref} from "vue";
import {map} from "lodash-es";
import {User} from "@Models/base/User";
import type {DropdownFilterEvent} from "primevue/dropdown";
import Dropdown from "primevue/dropdown";

const modelValue = defineModel<User | null>({required: true});

const {disabled, withoutGroup, schema, inline} = defineProps<{
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();

const options = ref<{value: User, label: string}[] | null>([]);
const loading = ref(false);

function filter(event: DropdownFilterEvent) {
    loading.value = true;
    User.searchUsers(1, 10, event.value as string).then(response => {
        options.value = map(response.data, user => {
            return {
                value: user,
                label: user.name + ' (' + user.email + ')',
            };
        });
        loading.value = false;
    });
}
</script>
