import {Settings} from "luxon";

import {createApp} from 'vue';
import {createPinia} from 'pinia';
import {component, router} from './router';
import plugin from "@Utils/plugin";
import {Prime} from "@Utils/prime";

import './assets/app.scss';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/collapse.js';
import {ErrorHandler} from "@Utils/errorHandler";
import {initSentry} from '@Utils/sentry';

Settings.defaultLocale = 'hu';
Settings.throwOnInvalid = true;

const app = createApp(component);

ErrorHandler.installVue(app);

if (process.env.NODE_ENV === 'production') {
    initSentry(app);
}

const pinia = createPinia();
app.use(pinia);

plugin.install(app, router);

Prime.install(app);

plugin.init().then(() => {
    app.use(router);

    app.mount('#application');
});
