/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsServiceCategory } from './EventsServiceCategory';
    import {
    EventsServiceCategoryFromJSON,
    EventsServiceCategoryFromJSONTyped,
    EventsServiceCategoryToJSON,
    EventsServiceCategoryCreateEmpty,
    } from './EventsServiceCategory';
    import type { EventsServiceType } from './EventsServiceType';
    import {
    EventsServiceTypeFromJSON,
    EventsServiceTypeFromJSONTyped,
    EventsServiceTypeToJSON,
    EventsServiceTypeCreateEmpty,
    } from './EventsServiceType';
import {DateTime} from '@Utils/DateTime';
import { EventsServiceTypeValues } from './EventsServiceType';
import { EventsServiceCategoryValues } from './EventsServiceCategory';

export interface EventsServiceConfigResponse {
  id: EventsServiceType;
  name: string;
  groupId: number | null;
  category: EventsServiceCategory;
  hasAssets: boolean;
  clearanceRequired: boolean;
}

export const EventsServiceConfigResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Típus",
        enumValues: EventsServiceTypeValues,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'category': {
    name: 'category',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: EventsServiceCategoryValues,
  },
  'hasAssets': {
    name: 'hasAssets',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'clearanceRequired': {
    name: 'clearanceRequired',
    required: true,
    nullable: false,
    label: 'Jóváhagyás szükséges',
    type: 'boolean',
  },
}
export function instanceOfEventsServiceConfigResponse(value: object): value is EventsServiceConfigResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "hasAssets" in value;
    isInstance = isInstance && "clearanceRequired" in value;

return isInstance;
}

export function EventsServiceConfigResponseFromJSON(json: any): EventsServiceConfigResponse {
return EventsServiceConfigResponseFromJSONTyped(json, false);
}

export function EventsServiceConfigResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceConfigResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'id': EventsServiceTypeFromJSON(json['id']),
          'name': json['name'],
          'groupId': json['groupId'],
              'category': EventsServiceCategoryFromJSON(json['category']),
          'hasAssets': json['hasAssets'],
          'clearanceRequired': json['clearanceRequired'],
    };
  }

  export function EventsServiceConfigResponseToJSON(value?: EventsServiceConfigResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': EventsServiceTypeToJSON(value.id),
                'name': value.name,
                'groupId': value.groupId,
                'category': EventsServiceCategoryToJSON(value.category),
                'hasAssets': value.hasAssets,
                'clearanceRequired': value.clearanceRequired,
    };
  }

  export function EventsServiceConfigResponseCreateEmpty(): EventsServiceConfigResponse {
  return {
          'id': EventsServiceTypeCreateEmpty(),
      'name': '',
      'groupId': 0,
          'category': EventsServiceCategoryCreateEmpty(),
      'hasAssets': false,
      'clearanceRequired': false,
  };
  }

