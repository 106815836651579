<template>
    <ItemGroupComponent
        v-for="group in capp.itemGroups"
        :key="group.id"
        :group="group"
        :permissions="capp.getPermissions()"
        @delete-group="deleteGroup(group)"
    >
    </ItemGroupComponent>

    <PegasusButton v-if="capp.canEdit" variant="primary" @click="newGroup">
        <i class="fas fa-plus-square-o"></i>
        Új tételcsoport hozzáadása
    </PegasusButton>
</template>

<script lang="ts" setup>
import {CategoryApplication} from "@Models/rvt/kpr/CategoryApplication";
import {ItemGroup} from "@Models/money/ItemGroup";
import ItemGroupComponent from "@Components/money/ItemGroup.vue";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";

const capp = defineModel<CategoryApplication>('capp', {required: true});

function deleteGroup(group: ItemGroup) {
    let request = group.delete().then(() => {
        delete capp.value.itemGroups[group.id];
    });

    toast.loading(request, 'Törlés');
}

function newGroup() {
    const group = ItemGroup.createNew('Névtelen tételcsoport');
    let request = group.storeForApplication(capp.value.id).then(() => {
        capp.value.itemGroups[group.id] = group;
    });

    toast.loading(request, 'Létrehozás');
}
</script>
