/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneySpendingEventTransferSent {
  transactionTime: DateTime;
  transactionId: string;
}

export const MoneySpendingEventTransferSentSchema = {
  'transactionTime': {
    name: 'transactionTime',
    required: true,
    nullable: false,
    label: 'Tranzakció időpontja',
    type: 'datetime',
  },
  'transactionId': {
    name: 'transactionId',
    required: true,
    nullable: false,
    label: 'Tranzakció azonosítója',
    type: 'string',
  },
}
export function instanceOfMoneySpendingEventTransferSent(value: object): value is MoneySpendingEventTransferSent {
let isInstance = true;
    isInstance = isInstance && "transactionTime" in value;
    isInstance = isInstance && "transactionId" in value;

return isInstance;
}

export function MoneySpendingEventTransferSentFromJSON(json: any): MoneySpendingEventTransferSent {
return MoneySpendingEventTransferSentFromJSONTyped(json, false);
}

export function MoneySpendingEventTransferSentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingEventTransferSent {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'transactionTime': (DateTime.fromISO(json['transactionTime'])),
          'transactionId': json['transactionId'],
    };
  }

  export function MoneySpendingEventTransferSentToJSON(value?: MoneySpendingEventTransferSent | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'transactionTime': (value.transactionTime.toISO()),
                'transactionId': value.transactionId,
    };
  }

  export function MoneySpendingEventTransferSentCreateEmpty(): MoneySpendingEventTransferSent {
  return {
      'transactionTime': DateTime.now(),
      'transactionId': '',
  };
  }

