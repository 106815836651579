/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { RvtClaimResponse } from './RvtClaimResponse';
    import {
    RvtClaimResponseFromJSON,
    RvtClaimResponseFromJSONTyped,
    RvtClaimResponseToJSON,
    RvtClaimResponseCreateEmpty,
    } from './RvtClaimResponse';
    import type { RvtRvtSummaryTenderResponse } from './RvtRvtSummaryTenderResponse';
    import {
    RvtRvtSummaryTenderResponseFromJSON,
    RvtRvtSummaryTenderResponseFromJSONTyped,
    RvtRvtSummaryTenderResponseToJSON,
    RvtRvtSummaryTenderResponseCreateEmpty,
    } from './RvtRvtSummaryTenderResponse';
    import type { RvtSpendingPeriodSummaryResponse } from './RvtSpendingPeriodSummaryResponse';
    import {
    RvtSpendingPeriodSummaryResponseFromJSON,
    RvtSpendingPeriodSummaryResponseFromJSONTyped,
    RvtSpendingPeriodSummaryResponseToJSON,
    RvtSpendingPeriodSummaryResponseCreateEmpty,
    } from './RvtSpendingPeriodSummaryResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtRvtSummaryResponse {
  period: RvtSpendingPeriodSummaryResponse;
  waitingClaims: Array<RvtClaimResponse>;
  tenders: Array<RvtRvtSummaryTenderResponse>;
}

export const RvtRvtSummaryResponseSchema = {
  'period': {
    name: 'period',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'waitingClaims': {
    name: 'waitingClaims',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'tenders': {
    name: 'tenders',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfRvtRvtSummaryResponse(value: object): value is RvtRvtSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "waitingClaims" in value;
    isInstance = isInstance && "tenders" in value;

return isInstance;
}

export function RvtRvtSummaryResponseFromJSON(json: any): RvtRvtSummaryResponse {
return RvtRvtSummaryResponseFromJSONTyped(json, false);
}

export function RvtRvtSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtRvtSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'period': RvtSpendingPeriodSummaryResponseFromJSON(json['period']),
            'waitingClaims': ((json['waitingClaims'] as Array<any>).map(RvtClaimResponseFromJSON)),
            'tenders': ((json['tenders'] as Array<any>).map(RvtRvtSummaryTenderResponseFromJSON)),
    };
  }

  export function RvtRvtSummaryResponseToJSON(value?: RvtRvtSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'period': RvtSpendingPeriodSummaryResponseToJSON(value.period),
              'waitingClaims': ((value.waitingClaims as Array<any>).map(RvtClaimResponseToJSON)),
              'tenders': ((value.tenders as Array<any>).map(RvtRvtSummaryTenderResponseToJSON)),
    };
  }

  export function RvtRvtSummaryResponseCreateEmpty(): RvtRvtSummaryResponse {
  return {
          'period': RvtSpendingPeriodSummaryResponseCreateEmpty(),
      'waitingClaims': [],
      'tenders': [],
  };
  }

