<template>
    <PegasusPanel>
        <template #header>
            <b>Csatolmányok
                <PegasusHelp title="Csatolmányok">
                    Ide lehet csatolni árajánlatot tartalmazó pdf-et, részletező excel fájlokat, vagy képernyőképeket
                </PegasusHelp>
            </b>
        </template>

        <div class="row">
            <div class="col-lg-6">
                <ul class="list-group">
                    <li v-for="asset in item.assets" :key="asset.id" class="list-group-item d-flex justify-content-between align-items-center">
                        <i v-if="asset.name.endsWith('pdf')" class="fa fa-file-pdf"></i>
                        <i v-else-if="asset.name.includes('.xls')" class="fa fa-file-excel"></i>
                        <i v-else class="fa fa-image"></i>

                        <span style="margin-right: auto; margin-left: 10px">{{ asset.name }}</span>

                        <PegasusButton v-if="canEdit" size="sm" @click="Dialog.open(ItemAssetDialog, {asset, item, canEdit})">
                            <i class="fa fa-edit"></i> Szerkesztés
                        </PegasusButton>

                        <PegasusButton size="sm" @click="Dialog.open(ItemAssetDialog, {asset, item, canEdit: false})">
                            <i class="fa fa-info-circle"></i>
                        </PegasusButton>

                        <PegasusButton size="sm" variant="success" @click="download(asset)">
                            <i class="fa fa-download"></i>
                        </PegasusButton>

                        <PegasusButton size="sm" variant="danger" @click="remove(asset)">
                            <i class="fa fa-times"></i>
                        </PegasusButton>
                    </li>
                </ul>

                <span v-if="isEmpty(item.assets)">
                    Nincs csatolmány
                </span>
            </div>

            <div class="col-lg-6">
                <PegasusButton v-if="canEdit" @click="newAsset">
                    <i class="fa fa-upload"></i> Feltöltés
                </PegasusButton>
            </div>
        </div>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import type {Item} from "@Models/money/Item";
import {ItemAsset} from "@Models/money/ItemAsset";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import toast from "@Utils/toast";
import {isEmpty} from "lodash-es";
import PegasusHelp from "@Components/base/PegasusHelp.vue";
import {Dialog} from '@Utils/dialog';
import ItemAssetDialog from '@Components/money/item/ItemAssetDialog.vue';
import {MoneyItemAssetType} from '@/api/models';

const {item, canEdit} = defineProps<{
    item: Item,
    canEdit: boolean
}>();

function newAsset() {
    const asset = new ItemAsset();
    asset.name = '';
    asset.description = '';
    asset.type = MoneyItemAssetType.OFFER;

    Dialog.open(ItemAssetDialog, {item, asset, canEdit}).then(value => {
        if (value && asset.id) {
            item.assets[asset.id] = asset;
        }
    });
}

async function remove(asset: ItemAsset) {
    if (!await toast.confirm('Biztos vagy benne, hogy törölni szeretnéd ezt a csatolmányt?', asset.name)) {
        return;
    }

    const request = asset.delete().then(() => {
        delete item.assets[asset.id];
    });

    toast.loading(request, 'Törlés');
}

function download(asset: ItemAsset) {
    const request = asset.download().then(file => file.download());

    toast.loading(request, 'Letöltés');
}
</script>
