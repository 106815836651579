<template>
    <PegasusPanel header="Költési időszakok">
        <ul>
            <PegasusButton
                v-for="period in ordered"
                :key="period.id"
                :params="{period: period}"
                class="my-1"
                to="rvt.spendingPeriod.show"
            >
                {{ period.name }}
            </PegasusButton>
        </ul>
    </PegasusPanel>
</template>

<title>Költési időszakok</title>

<script lang="ts" setup>
import {orderBy} from "lodash-es";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {useAuthStore} from "@/stores/auth";
import {SpendingPeriod} from '@Models/money/SpendingPeriod';

useAuthStore().requireUser();

const periods = SpendingPeriod.getAll();

const ordered = orderBy(periods, period => period.from.year, 'desc');
</script>
