<template>
    <PegasusModal header="Utalási kérelem">
        <PegasusForm ref="form">
            <PegasusForm part="data">
                <TextField
                    v-model="event.accountName"
                    :schema="MoneySpendingEventTransferRequestedSchema.accountName"
                ></TextField>

                <TextField
                    v-model="event.accountNumber"
                    :schema="MoneySpendingEventTransferRequestedSchema.accountNumber"
                    mask="99999999-99999999-99999999"
                    @update:model-value="check"
                ></TextField>
                <small class="whitespace">{{ branchInfo }}</small>

                <TextField
                    v-model="event.transferComment"
                    :schema="MoneySpendingEventTransferRequestedSchema.transferComment"
                ></TextField>
            </PegasusForm>

            <hr>

            <TextField
                v-model="event.comment"
                :schema="MoneySpendingItemEventSchema.comment"
            ></TextField>

            <PegasusButton variant="success" @click="save">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import {
    type MoneyAccountCheck,
    MoneySpendingEventTransferRequestedSchema,
    MoneySpendingItemEventSchema
} from '@/api/models';
import {ref} from 'vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';
import type {SpendingItem} from '@Models/money/SpendingItem';
import {TransferRequested} from '@Models/money/spendingItemEvents/TransferRequested';
import {MoneyApi} from '@/api/apis';
import {checkBankAccountNumber} from '@Models/Utils';

const {spendingItem} = defineProps<{
    spendingItem: SpendingItem
}>();

const event = ref(new TransferRequested());

const form = ref<FormRef>(null);

const returnValue = useReturn();

const latestNumber = ref('');
const branchInfo = ref('');

async function save() {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    const request = event.value.store(spendingItem.id, form.value).then(() => {
        spendingItem.events[event.value.id] = event.value;
        returnValue(true);
    });

    toast.loading(request);
}

function accountNumberValidation(errors: string[]): void {
    form.value?.handle({
        errors: [],
        fields: {
            data: {
                errors: [],
                fields: {
                    accountNumber: {
                        errors: errors,
                        fields: {}
                    }
                }
            }
        }
    });
}

function check() {
    if (event.value.accountNumber.length < 8) {
        branchInfo.value = '';
        accountNumberValidation([]);
        return;
    }

    if (event.value.accountNumber.length >= 16) {
        if (checkBankAccountNumber(event.value.accountNumber)) {
            accountNumberValidation([]);
        } else {
            accountNumberValidation(['Hibás számlaszám']);
        }
    }

    if (event.value.accountNumber.substring(0, 8) === latestNumber.value.substring(0, 8)) {
        return;
    }

    latestNumber.value = event.value.accountNumber;

    const data: MoneyAccountCheck = {
        accountName: event.value.accountName,
        accountNumber: latestNumber.value
    };

    MoneyApi.utilitiesAccountNumber(data).then(response => {
        branchInfo.value = 'Bank fiók azonosító: ' + response.data.bic + '\nBank fiók neve: ' + response.data.name;
        accountNumberValidation([]);
    }).catch(() => {
        accountNumberValidation(['Érvénytelen számlaszám']);
    });
}
</script>
