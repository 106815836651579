import {BaseEntity} from '@Models/BaseEntity';
import type {MoneyBudgetItemValueResponse} from '@/api/models';
import {BudgetLocation} from '@Models/money/BudgetLocation';

export class BudgetItemValue extends BaseEntity {
    location: BudgetLocation;

    value: number;

    static parseResponse(value: BudgetItemValue, data: MoneyBudgetItemValueResponse): BudgetItemValue {
        value.location = BudgetLocation.getById(data.locationId);
        value.value = data.value;

        return value;
    }
}
