/* tslint:disable */
/* eslint-disable */

export enum BaseGroupSpecificPermission {
    MANAGE_INVENTORY = 'MANAGE_INVENTORY',
    READ_INVENTORY = 'READ_INVENTORY',
    MANAGE_MONEY = 'MANAGE_MONEY',
    READ_MONEY = 'READ_MONEY',
    APPROVE_REQUESTS = 'APPROVE_REQUESTS',
    MANAGE_VIKO = 'MANAGE_VIKO',
    READ_VIKO = 'READ_VIKO',
    MANAGE_EVENTS = 'MANAGE_EVENTS',
    READ_EVENTS = 'READ_EVENTS',
    MANAGE_SERVICES = 'MANAGE_SERVICES',
    READ_SERVICES = 'READ_SERVICES',
    MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
    MANAGE_STATEMENT = 'MANAGE_STATEMENT'
}

export const BaseGroupSpecificPermissionValues: Record<BaseGroupSpecificPermission, string> = {
    'MANAGE_INVENTORY': 'Leltár szerkesztése',
    'READ_INVENTORY': 'Leltár olvasása',
    'MANAGE_MONEY': 'Pénzügyek szerkesztése',
    'READ_MONEY': 'Pénzügyek olvasása',
    'APPROVE_REQUESTS': 'Körök kérvényeinek jóváhagyása',
    'MANAGE_VIKO': 'VIKÖ előbírálás',
    'READ_VIKO': 'VIKÖ beszámolók olvasása',
    'MANAGE_EVENTS': 'Rendezvények kezelése',
    'READ_EVENTS': 'Rendezvények megtekintése',
    'MANAGE_SERVICES': 'Rendezvényfelkérések bírálása',
    'READ_SERVICES': 'Rendezvényfelkérések olvasása',
    'MANAGE_PERMISSIONS': 'Jogosultságok kezelése',
    'MANAGE_STATEMENT': 'Körös beszámolók szerkesztése'
}


export function BaseGroupSpecificPermissionFromJSON(json: any): BaseGroupSpecificPermission {
    return BaseGroupSpecificPermissionFromJSONTyped(json, false);
}

export function BaseGroupSpecificPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupSpecificPermission {
    return json as BaseGroupSpecificPermission;
}

export function BaseGroupSpecificPermissionToJSON(value?: BaseGroupSpecificPermission | null): any {
    return value as any;
}

export function BaseGroupSpecificPermissionCreateEmpty(): BaseGroupSpecificPermission {
    return BaseGroupSpecificPermission.MANAGE_INVENTORY;
}

