import {Base} from "@Models/Base";
import {Tender} from "@Models/viko/Tender";
import {Application} from "@Models/viko/Application";
import {ReviewerGroup} from "@Models/viko/ReviewerGroup";
import {Group} from "@Models/base/Group";
import type {VikoIndexResponse} from "@/api/api";
import {BaseGlobalPermission, VikoApi} from "@/api/api";
import type {User} from '@Models/base/User';

export class VikoIndex extends Base {
    tender: Tender;

    application: Application | null;

    reviewerGroup: ReviewerGroup | null;

    moderator: boolean | null;

    ledGroups: Record<number, Group>;

    reportableGroups: Record<number, Group>;

    static getById(): Promise<VikoIndex> {
        return VikoApi.indexIndex().then(response => {
            return VikoIndex.newSingle(response.data, VikoIndex.parseResponse);
        });
    }

    static parseResponse(index: VikoIndex, data: VikoIndexResponse): VikoIndex {
        index.tender = Tender.newSingle(data.tender, Tender.parseResponse);
        index.application = Application.newSingleNullable(data.application, Application.parseResponse);
        index.reviewerGroup = ReviewerGroup.newSingleNullable(data.reviewerGroup, ReviewerGroup.parseResponse);
        index.moderator = data.moderator;
        index.ledGroups = Group.getByMultipleId(data.ledGroups);
        index.reportableGroups = Group.getByMultipleId(data.reportableGroups);

        return index;
    }

    public canCreateApplication(): boolean {
        return this.application === null && this.tender.inDrafting;
    }

    public canAccessReviewerSpace(user: User): boolean {
        return !!this.reviewerGroup || this.moderator || user.hasPermission(BaseGlobalPermission.VIKO_TENDER);
    }
}
