/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsReservationResponse } from './EventsReservationResponse';
    import {
    EventsReservationResponseFromJSON,
    EventsReservationResponseFromJSONTyped,
    EventsReservationResponseToJSON,
    EventsReservationResponseCreateEmpty,
    } from './EventsReservationResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsReservationListResponse {
  reservations: Array<EventsReservationResponse>;
}

export const EventsReservationListResponseSchema = {
  'reservations': {
    name: 'reservations',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsReservationListResponse(value: object): value is EventsReservationListResponse {
let isInstance = true;
    isInstance = isInstance && "reservations" in value;

return isInstance;
}

export function EventsReservationListResponseFromJSON(json: any): EventsReservationListResponse {
return EventsReservationListResponseFromJSONTyped(json, false);
}

export function EventsReservationListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsReservationListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'reservations': ((json['reservations'] as Array<any>).map(EventsReservationResponseFromJSON)),
    };
  }

  export function EventsReservationListResponseToJSON(value?: EventsReservationListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'reservations': ((value.reservations as Array<any>).map(EventsReservationResponseToJSON)),
    };
  }

  export function EventsReservationListResponseCreateEmpty(): EventsReservationListResponse {
  return {
      'reservations': [],
  };
  }

