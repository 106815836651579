<template>
    <div class="wrapper">
        <Navbar>
            <template #button>
                <PegasusLink class="navbar-brand" to="events.index">
                    <img src="/src/assets/images/logo/navbar/events.svg" height="30px" style="color: white" alt="bejelentes.sch"/>
                </PegasusLink>
            </template>

            <template v-if="auth.user" #navbar-extra>
                <li v-if="auth.hasPermission(BaseGlobalPermission.EVENTS_PLANNING)" class="nav-item">
                    <PegasusLink class="nav-link" to="events.planning.index">Nagyteremosztó</PegasusLink>
                </li>

                <li v-if="!isEmpty(editableServices)" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        Felkéréskezelő
                    </a>

                    <ul class="dropdown-menu border-0 shadow">
                        <li v-for="service in editableServices" :key="service.id">
                            <PegasusLink :params="{service: service.id}" class="dropdown-item" to="events.services.index">
                                {{ service.name }}
                            </PegasusLink>
                        </li>
                    </ul>
                </li>
            </template>
        </Navbar>

        <MainContent></MainContent>

        <CommonFooter></CommonFooter>
    </div>
</template>

<script lang="ts" setup>
import Navbar from "@Pages/Navbar.vue";
import CommonFooter from "@Pages/CommonFooter.vue";
import {BaseGlobalPermission} from "@/api/models";
import {filter, isEmpty} from "lodash-es";
import {computed} from "vue";
import {useAuthStore} from "@/stores/auth";
import {useConstantsStore} from "@/stores/constants";
import PegasusLink from "@Components/base/PegasusLink.vue";
import MainContent from "@Components/MainContent.vue";

const auth = useAuthStore();
const constants = useConstantsStore();

const editableServices = computed(() => {
    if (auth.user === null) {
        return [];
    }

    return filter(
        constants.constants.events.services,
        service => auth.user !== null && service.canRead(auth.user) && service.groupId !== null
    );
});
</script>
