import {Base} from "@Models/Base";
import {AssignmentMonth} from "@Models/mentors/AssignmentMonth";
import type {MentorsAvailabilityResponse, MentorsAvailabilityUpdate} from "@/api/models";
import {MentorsApi} from "@/api/apis";
import {map, sortBy} from "lodash-es";
import {DateTime} from "@Utils/DateTime";

export class Availability extends Base {
    assignmentMonth: AssignmentMonth;

    mentorId: number;

    days: number[];

    static parseResponse(availability: Availability, data: MentorsAvailabilityResponse): Availability {
        availability.assignmentMonth = AssignmentMonth.newSingle(data.assignmentMonth, AssignmentMonth.parseResponse);
        availability.mentorId = data.mentorId;
        availability.days = data.days;

        return availability;
    }

    public update(): Promise<void> {
        const data: MentorsAvailabilityUpdate = {
            days: this.days,
        };

        return MentorsApi.availabilityUpdateAvailability(this.assignmentMonth.id, data).then(response => {
            Availability.parseResponse(this, response.data);
        });
    }

    get daysAsDate(): Date[] {
        return map(this.days, day => new Date(this.assignmentMonth.year, this.assignmentMonth.month - 1, day));
    }

    set daysAsDate(days: Date[]) {
        this.days = sortBy(map(days, day => day.getDate()), day => day);
    }

    get daysAsLuxon(): DateTime[] {
        return map(this.daysAsDate, day => DateTime.fromJSDate(day));
    }
}
