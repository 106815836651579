/* tslint:disable */
/* eslint-disable */

export enum InventoryCreationSource {
    SGR = 0,
    EXISTING = 1,
    BUILT = 2,
    FOUND = 3,
    LOANED = 4,
    GIFTED = 5,
    ACQUIRED = 6
}

export const InventoryCreationSourceValues: Record<InventoryCreationSource, string> = {
    0: 'SGR',
    1: 'Korábban vett',
    2: 'Épített',
    3: 'Fellelt',
    4: 'Kölcsönben hosszútávon',
    5: 'Ajándékozva',
    6: 'Egyéb (lízing, licensz, stb.)'
}


export function InventoryCreationSourceFromJSON(json: any): InventoryCreationSource {
    return InventoryCreationSourceFromJSONTyped(json, false);
}

export function InventoryCreationSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryCreationSource {
    return json as InventoryCreationSource;
}

export function InventoryCreationSourceToJSON(value?: InventoryCreationSource | null): any {
    return value as any;
}

export function InventoryCreationSourceCreateEmpty(): InventoryCreationSource {
    return InventoryCreationSource.SGR;
}

