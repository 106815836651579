<template>
    <PegasusHeading>Inaktív mentorok</PegasusHeading>

    <div class="row">
        <div class="col-lg-12">
            <PegasusTable :data="mentors" :per-page="10" search>
                <Column field="name" header="Név"></Column>

                <Column field="phone" header="Telefonszám"></Column>

                <Column field="room" header="Lakóhely"></Column>

                <Column header="Műveletek">
                    <template #body="{data}">
                        <PegasusButton size="sm" variant="primary" @click="activate(data)">
                            <i class="fas fa-check"></i> Akiválás
                        </PegasusButton>
                    </template>
                </Column>
            </PegasusTable>

            <PegasusButton size="sm" to="mentors.admin.index">
                <i class="fas fa-backward fa-fw"></i> Vissza
            </PegasusButton>
        </div>
    </div>
</template>

<title>Mentor archívum</title>

<script lang="ts" setup>
import {Mentor} from "@Models/mentors/Mentor";
import {ref} from "vue";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

useAuthStore().requireUser();

const mentors = ref(await Mentor.getInactivated());

async function activate(mentor: Mentor) {
    if (!await toast.confirm('Biztos vagy benne hogy aktíválod újra ezt a mentort?')) {
        return;
    }

    let request = mentor.makeActive().then(() => {
        delete mentors.value[mentor.id];
    });

    toast.loading(request);
}
</script>
