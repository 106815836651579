/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryPublicItemResponse {
  id: number;
  responsibleId: number;
  leaderName: string;
  leaderEmail: string;
  canRead: boolean;
}

export const InventoryPublicItemResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'responsibleId': {
    name: 'responsibleId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'leaderName': {
    name: 'leaderName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'leaderEmail': {
    name: 'leaderEmail',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'canRead': {
    name: 'canRead',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfInventoryPublicItemResponse(value: object): value is InventoryPublicItemResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "responsibleId" in value;
    isInstance = isInstance && "leaderName" in value;
    isInstance = isInstance && "leaderEmail" in value;
    isInstance = isInstance && "canRead" in value;

return isInstance;
}

export function InventoryPublicItemResponseFromJSON(json: any): InventoryPublicItemResponse {
return InventoryPublicItemResponseFromJSONTyped(json, false);
}

export function InventoryPublicItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryPublicItemResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'responsibleId': json['responsibleId'],
          'leaderName': json['leaderName'],
          'leaderEmail': json['leaderEmail'],
          'canRead': json['canRead'],
    };
  }

  export function InventoryPublicItemResponseToJSON(value?: InventoryPublicItemResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'responsibleId': value.responsibleId,
                'leaderName': value.leaderName,
                'leaderEmail': value.leaderEmail,
                'canRead': value.canRead,
    };
  }

  export function InventoryPublicItemResponseCreateEmpty(): InventoryPublicItemResponse {
  return {
      'id': 0,
      'responsibleId': 0,
      'leaderName': '',
      'leaderEmail': '',
      'canRead': false,
  };
  }

