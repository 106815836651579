/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetCategoryStore {
  spendingPeriod: number;
  parent: number | null;
  name: string;
  description: string;
  number: number;
}

export const MoneyBudgetCategoryStoreSchema = {
  'spendingPeriod': {
    name: 'spendingPeriod',
    required: true,
    nullable: false,
    label: 'Költségvetési év',
    type: 'number',
      minimum: 0,
  },
  'parent': {
    name: 'parent',
    required: true,
    nullable: true,
    label: 'Szülő kategória',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 2,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
      minLength: 0,
      maxLength: 3000,
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Sorszám',
    type: 'number',
      minimum: 1,
      maximum: 100,
  },
}
export function instanceOfMoneyBudgetCategoryStore(value: object): value is MoneyBudgetCategoryStore {
let isInstance = true;
    isInstance = isInstance && "spendingPeriod" in value;
    isInstance = isInstance && "parent" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "number" in value;

return isInstance;
}

export function MoneyBudgetCategoryStoreFromJSON(json: any): MoneyBudgetCategoryStore {
return MoneyBudgetCategoryStoreFromJSONTyped(json, false);
}

export function MoneyBudgetCategoryStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetCategoryStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'spendingPeriod': json['spendingPeriod'],
          'parent': json['parent'],
          'name': json['name'],
          'description': json['description'],
          'number': json['number'],
    };
  }

  export function MoneyBudgetCategoryStoreToJSON(value?: MoneyBudgetCategoryStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'spendingPeriod': value.spendingPeriod,
                'parent': value.parent,
                'name': value.name,
                'description': value.description,
                'number': value.number,
    };
  }

  export function MoneyBudgetCategoryStoreCreateEmpty(): MoneyBudgetCategoryStore {
  return {
      'spendingPeriod': 0,
      'parent': 0,
      'name': '',
      'description': '',
      'number': 0,
  };
  }

