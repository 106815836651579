/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprTenderResponse } from './KprTenderResponse';
    import {
    KprTenderResponseFromJSON,
    KprTenderResponseFromJSONTyped,
    KprTenderResponseToJSON,
    KprTenderResponseCreateEmpty,
    } from './KprTenderResponse';
    import type { RvtAmendResponse } from './RvtAmendResponse';
    import {
    RvtAmendResponseFromJSON,
    RvtAmendResponseFromJSONTyped,
    RvtAmendResponseToJSON,
    RvtAmendResponseCreateEmpty,
    } from './RvtAmendResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtRvtSummaryTenderResponse {
  tender: KprTenderResponse;
  waitingAmends: Array<RvtAmendResponse>;
}

export const RvtRvtSummaryTenderResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'waitingAmends': {
    name: 'waitingAmends',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfRvtRvtSummaryTenderResponse(value: object): value is RvtRvtSummaryTenderResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "waitingAmends" in value;

return isInstance;
}

export function RvtRvtSummaryTenderResponseFromJSON(json: any): RvtRvtSummaryTenderResponse {
return RvtRvtSummaryTenderResponseFromJSONTyped(json, false);
}

export function RvtRvtSummaryTenderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtRvtSummaryTenderResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': KprTenderResponseFromJSON(json['tender']),
            'waitingAmends': ((json['waitingAmends'] as Array<any>).map(RvtAmendResponseFromJSON)),
    };
  }

  export function RvtRvtSummaryTenderResponseToJSON(value?: RvtRvtSummaryTenderResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': KprTenderResponseToJSON(value.tender),
              'waitingAmends': ((value.waitingAmends as Array<any>).map(RvtAmendResponseToJSON)),
    };
  }

  export function RvtRvtSummaryTenderResponseCreateEmpty(): RvtRvtSummaryTenderResponse {
  return {
          'tender': KprTenderResponseCreateEmpty(),
      'waitingAmends': [],
  };
  }

