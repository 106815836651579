<template>
    <MultiSelect
        :disabled="disabled"
        :model-value="toArray(modelValue)"
        :options="toArray(flags)"
        class="my-2 w-100"
        data-key="id"
        display="chip"
        option-label="name"
        @change="update"
    ></MultiSelect>
</template>

<script lang="ts" setup>
import type {Flag} from "@Models/money/Flag";
import {keyBy, toArray} from "lodash-es";
import type {MultiSelectChangeEvent} from "primevue/multiselect";
import MultiSelect from "primevue/multiselect";
import {useConstantsStore} from "@/stores/constants";

const {modelValue, disabled = false} = defineProps<{
    modelValue: Record<number, Flag>;
    disabled?: boolean;
}>();

const emit = defineEmits<{
    "update:modelValue": [value: Record<number, Flag>];
}>();

function update(value: MultiSelectChangeEvent) {
    emit(
        "update:modelValue",
        keyBy(value.value as Flag[], flag => flag.id),
    );
}

const flags = useConstantsStore().constants.money.flags;
</script>
