import {User} from "@Models/base/User";
import {BaseEntity} from "@Models/BaseEntity";
import type {EventsManagerResponse, EventsManagerStore, EventsManagerUpdate} from "@/api/api";
import {EventsApi} from "@/api/api";
import {File} from '@Models/File';
import type {FormRef} from '@/vue';

export class Manager extends BaseEntity {
    leader: boolean;

    active: boolean;

    user: User;

    static getAll(): Promise<Record<number, Manager>> {
        return EventsApi.managersIndex().then(response => {
            return Manager.newRecords(response.data.managers, Manager.parseResponse);
        });
    }

    static parseResponse(manager: Manager, data: EventsManagerResponse): Manager {
        manager.id = data.id;
        manager.leader = data.leader;
        manager.active = data.active;
        manager.user = User.newSingle(data.user, User.parseBaseResponse);

        return manager;
    }

    public store(): Promise<Manager> {
        const data: EventsManagerStore = {
            user: this.user.id,
            leader: this.leader,
            active: this.active,
        };

        return EventsApi.managersStore(data).then(response => {
            return Manager.newSingle(response.data, Manager.parseResponse);
        });
    }

    public update(form: FormRef): Promise<void> {
        const data: EventsManagerUpdate = {
            leader: this.leader,
            active: this.active,
        };

        return EventsApi.managersUpdate(this.id, data, {form}).then(response => {
            Manager.parseResponse(this, response.data);
        });
    }

    public downloadImage(): Promise<File> {
        return EventsApi.managersImage(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public updateImage(file: File, form: FormRef): Promise<void> {
        return EventsApi.managersUpdateImage(this.id, file.getAsDomFile(), {form}).then();
    }

    get roomNumber(): string {
        if (this.user.room === null) {
            throw new Error('Manager without roomNumber');
        }

        return this.user.room.displayNumber;
    }
}
