import {BaseEntity} from "@Models/BaseEntity";
import {useConstantsStore} from "@/stores/constants";
import type {MoneyFlagResponse} from '@/api/models';

export class Flag extends BaseEntity {
    name: string;

    description: string;

    class: string;

    static getSingleById(id: number): Flag {
        return useConstantsStore().constants.money.flags[id];
    }

    static getByMultipleId(ids: number[]): Record<number, Flag> {
        const result: Record<number, Flag> = {};

        for (const id in ids) {
            result[ids[id]] = Flag.getSingleById(ids[id]);
        }

        return result;
    }

    static parseResponse(flag: Flag, data: MoneyFlagResponse): Flag {
        flag.id = data.id;
        flag.name = data.name;
        flag.description = data.description;
        flag.class = data.cssClass;

        return flag;
    }
}
