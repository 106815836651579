import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import type {InventoryItemUpdate, InventoryUpdateResponse, InventoryUpdateTermination} from "@/api/api";
import {
    InventoryApi,
    InventoryTerminationReason,
    InventoryTerminationReasonValues,
    InventoryUpdateType
} from "@/api/api";
import {Approval} from "@Models/inventory/updates/Approval";
import type {FormRef} from '@/vue';

export class Termination extends ItemUpdate {
    type = InventoryUpdateType.TERMINATION;

    reason: InventoryTerminationReason;

    approval: Approval;

    static parseResponse(termination: Termination, data: InventoryUpdateResponse): Termination {
        ItemUpdate.parseResponse(termination, data);

        if (data.approval === null) {
            throw new Error('Termination must have approval');
        }

        termination.approval = Approval.newSingle(data.approval, Approval.parseResponse);

        const specific = data.data as InventoryUpdateTermination;
        termination.reason = specific.reason;

        return termination;
    }

    public store(itemId: number, form: FormRef): Promise<Termination> {
        const data: InventoryItemUpdate = {
            type: InventoryUpdateType.TERMINATION,
            data: {
                type: InventoryUpdateType.TERMINATION,
                reason: this.reason,
            },
            comment: this.comment,
        };

        return InventoryApi.itemUpdatesAdd(itemId, data, {form}).then(response => {
            return Termination.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        const reason = InventoryTerminationReasonValues[this.reason];

        return [
            {key: 'Kivezetés oka', value: reason},
        ];
    }
}
