import {BaseEntity} from "@Models/BaseEntity";
import {Item} from "@Models/inventory/Item";
import {Group} from "@Models/base/Group";
import type {
    InventoryItemGroupCreate,
    InventoryItemGroupUpdate,
    InventoryItemGroupWithHierarchyResponse,
    InventoryItemGroupWithItemsResponse,
    InventoryMinimalItemGroupResponse
} from "@/api/api";
import {InventoryApi} from "@/api/api";
import {forEach, merge, reduce} from "lodash-es";
import type {FormRef} from '@/vue';

export class ItemGroup extends BaseEntity {
    name: string;

    description: string;

    children: Record<number, ItemGroup>;

    responsibleGroup: Group;

    itemCount: number;

    items: Record<number, Item> | null;

    static getById(id: number): Promise<ItemGroup> {
        return InventoryApi.itemGroupsShow(id).then(response => {
            return ItemGroup.newSingle(response.data, this.parseWithItemsResponse);
        });
    }

    static create(responsible: Group): ItemGroup {
        const instance = new ItemGroup();

        instance.name = 'Új tételcsoport';
        instance.description = 'Leírás';
        instance.children = {};
        instance.responsibleGroup = responsible;
        instance.itemCount = 0;

        return instance;
    }

    static createDummyRoot(parentGroup: Group, children: Record<number, ItemGroup>, itemCount: number, items: Record<number, Item> | null = null) {
        const group = new ItemGroup();

        group.id = 0;
        group.name = parentGroup.name;
        group.children = children;
        group.itemCount = itemCount;
        group.responsibleGroup = parentGroup;
        group.items = items;

        return group;
    }

    static parseMinimalResponse(itemGroup: ItemGroup, response: InventoryMinimalItemGroupResponse): ItemGroup {
        itemGroup.id = response.id;
        itemGroup.name = response.name;
        itemGroup.description = response.description;
        itemGroup.responsibleGroup = Group.getBySingleId(response.responsibleGroupId);
        itemGroup.itemCount = response.itemCount;

        return itemGroup;
    }

    static parseHierarchyResponse(itemGroup: ItemGroup, response: InventoryItemGroupWithHierarchyResponse): ItemGroup {
        itemGroup = ItemGroup.parseMinimalResponse(itemGroup, response);

        itemGroup.children = ItemGroup.newRecords(response.children, ItemGroup.parseHierarchyResponse);

        return itemGroup;
    }

    static parseWithItemsResponse(itemGroup: ItemGroup, response: InventoryItemGroupWithItemsResponse): ItemGroup {
        itemGroup = ItemGroup.parseMinimalResponse(itemGroup, response);

        itemGroup.items = Item.newRecords(response.items, Item.parseItemResponse);

        return itemGroup;
    }

    static walk(itemGroups: Record<number, ItemGroup>, callback: (itemGroup: ItemGroup) => void) {
        forEach(itemGroups, itemGroup => {
            callback(itemGroup);
            ItemGroup.walk(itemGroup.children, callback);
        });
    }

    public store(parentGroup: ItemGroup | null = null, form: FormRef): Promise<void> {
        const data: InventoryItemGroupCreate = {
            name: this.name,
            description: this.description,
            group: this.responsibleGroup.id,
            parentGroup: parentGroup?.id ? parentGroup.id : null,
        };

        return InventoryApi.itemGroupsStore(data, {form}).then(response => {
            ItemGroup.parseMinimalResponse(this, response.data);
        });
    }

    public update(name: string, description: string, form: FormRef): Promise<ItemGroup> {
        const data: InventoryItemGroupUpdate = {
            name: name,
            description: description,
        };

        return InventoryApi.itemGroupsUpdate(this.id, data, {form}).then(response => {
            return ItemGroup.parseMinimalResponse(this, response.data);
        });
    }

    public destroy(): Promise<void> {
        return InventoryApi.itemGroupsDelete(this.id).then();
    }

    get itemCountWithDescendants(): number {
        return reduce(
            this.children,
            (count, itemGroup) => count + itemGroup.itemCountWithDescendants,
            this.itemCount
        );
    }

    get itemCountDescendants(): number {
        return reduce(
            this.children,
            (count, itemGroup) => count + itemGroup.itemCountWithDescendants,
            0
        );
    }

    get itemsWithDescendants(): Record<number, Item> {
        if (this.items === null) {
            return {};
        }

        return reduce(
            this.children,
            (items, itemGroup) => merge(items, itemGroup.itemsWithDescendants),
            this.items
        );
    }
}
