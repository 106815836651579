<template>
    <PegasusModal :header="service.type.name" width="1100px">
        <div class="row">
            <div class="col-lg-6">
                <Card>
                    <template #title>Adatok</template>

                    <template #content>
                        <component
                            :is="serviceComponents[service.type.id]"
                            v-if="service"
                            v-model:data="local"
                            :service="service"
                        ></component>

                        <TextareaField
                            v-model="service.comment"
                            :disabled="!service.canEdit"
                            :schema="EventsServiceStoreSchema.comment"
                        ></TextareaField>

                        <div class="d-flex align-content-center">
                            <PegasusButton v-if="canApprove" variant="success" @click="approve">
                                <i class="fa fa-check"></i> Elfogadás
                            </PegasusButton>

                            <PegasusButton v-if="canApprove" variant="danger" @click="disapprove">
                                <i class="fa fa-times"></i> Visszautasítás
                            </PegasusButton>
                        </div>
                    </template>
                </Card>
            </div>

            <div class="col-lg-6">
                <Card>
                    <template #title>Rendezvény</template>

                    <template #content>
                        <ShortEvent :event="service.event"></ShortEvent>
                    </template>
                </Card>
            </div>
        </div>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import {Service} from '@Models/events/Service';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {EventsServiceStoreSchema} from '@/api/models';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import serviceComponents from "@Components/events/services";
import Card from "primevue/card";
import ShortEvent from '@Components/events/ShortEvent.vue';
import {computed} from 'vue';
import toast from '@Utils/toast';
import {useReturn} from '@Utils/dialog';
import {useAuthStore} from '@/stores/auth';

const {service} = defineProps<{
    service: Service
}>();

const user = useAuthStore().requireUser();

const local = computed((): any => service.data);

const returnValue = useReturn();

const canApprove = computed((): boolean => service.type.canEdit(user.value));

async function approve() {
    if (!await toast.confirm('Biztosan el szeretnéd fogadni ezt a felkérést?')) {
        return;
    }

    let request = service.decide(true).then(() => {
        returnValue(true);
    });

    toast.loading(request, 'Felkérés elfogadás');
}

async function disapprove() {
    if (!await toast.confirm('Biztosan vissza szeretnéd utasítani ezt a felkérést?')) {
        return;
    }

    let request = service.decide(false).then(() => {
        returnValue(false);
    });

    toast.loading(request, 'Felkérés elutasítás');
}
</script>
