/* tslint:disable */
/* eslint-disable */

export enum MapRoomType {
    CORRIDOR = 0,
    LIVING = 1,
    COMMON = 2,
    BME = 3,
    HEROSZ = 4,
    SPECIAL = 5,
    LIFT = 6
}

export const MapRoomTypeValues: Record<MapRoomType, string> = {
    0: 'Folyosó',
    1: 'Lakószoba',
    2: 'Közössségi helyiség',
    3: 'Egyetemi helyiség',
    4: 'Üzemeltetési helyiség',
    5: 'Különleges helyiség',
    6: 'Lift'
}


export function MapRoomTypeFromJSON(json: any): MapRoomType {
    return MapRoomTypeFromJSONTyped(json, false);
}

export function MapRoomTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapRoomType {
    return json as MapRoomType;
}

export function MapRoomTypeToJSON(value?: MapRoomType | null): any {
    return value as any;
}

export function MapRoomTypeCreateEmpty(): MapRoomType {
    return MapRoomType.CORRIDOR;
}

