<template>
    <NumberField
        v-model="data.tables"
        :disabled="!service.canEdit"
        :schema="EventsTableSetDataSchema.tables"
        :step="1"
        inline
    ></NumberField>

    <NumberField
        v-model="data.chairs"
        :disabled="!service.canEdit"
        :schema="EventsTableSetDataSchema.chairs"
        inline
    ></NumberField>

    <small>
        <i class="fas fa-exclamation-circle text-danger"></i>
        Azt kérnénk tőletek, hogy a sörpadok állapotára fokozott figyelmet fordítsatok.
        Visszapakolás előtt mindig tisztítsátok le, hogy a következők is tiszta padot kaphassanak.
    </small>
</template>

<script lang="ts" setup>
import type {Service} from "@Models/events/Service";
import NumberField from "@Components/base/form/types/NumberField.vue";
import {type EventsTableSetData, EventsTableSetDataSchema} from "@/api/models";

const data = defineModel<EventsTableSetData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
