<template>
    <PegasusOverlay :header="title" :wide="wide">
        <template #default>
            <i :class="classObject" @mouseleave="overlay?.hide()" @mouseover="overlay?.show($event)"></i>
        </template>
        <template #overlay>
            <slot></slot>
        </template>
    </PegasusOverlay>
</template>

<script lang="ts" setup>
import {computed, ref} from "vue";
import type OverlayPanel from "primevue/overlaypanel";
import PegasusOverlay from "@Components/base/PegasusOverlay.vue";

const {title, align, wide, variant = 'help'} = defineProps<{
    title?: string,
    align?: string,
    wide?: boolean,
    variant?: 'help' | 'warning' | 'info'
}>();

const overlay = ref<OverlayPanel | null>(null);

const classObject = computed(() => {
    let classes = 'fas text-md';
    switch (variant) {
        case 'help':
            classes += ' fa-question-circle';
            break;
        case 'warning':
            classes += ' fa-exclamation-circle text-danger';
            break;
        case 'info':
            classes += ' fa-info-circle text-info';
            break;
    }

    if (align) {
        classes += ' align-' + align;
    }

    return classes;
});
</script>
