import {createApp, h, inject, type InjectionKey, reactive} from 'vue';
import {Prime} from '@Utils/prime';

export const PegasusDialogPromiseSymbol = Symbol() as InjectionKey<(value: any) => void>;

export function useReturn(): (value: boolean | null) => void {
    const PegasusPromise = inject(PegasusDialogPromiseSymbol);
    if (!PegasusPromise) {
        throw new Error('Cannot be called outside of setup or lifecycle hooks');
    }

    return PegasusPromise;
}

export class Dialog {
    public static open(component: any, props: any = {}): Promise<boolean | null> {
        const app = createApp({
            render: () => h(component, reactive(props)),
            expose: [],
        });

        Prime.install(app);

        const promise = new Promise<boolean | null>(resolve => {
            app.provide(PegasusDialogPromiseSymbol, resolve);

            app.mount(document.createElement('div'));
        });

        promise.then(value => {
            app.unmount();

            return value;
        });

        return promise;
    }
}
