<template>
    <PegasusModal header="Esemény részletei">
        <template v-if="event">
            <ShortEvent :event="event"></ShortEvent>
        </template>

        <template v-else>
            <i class="fa fa-spinner fa-spin"></i>
        </template>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PublicEvent from "@Models/events/PublicEvent";
import {ref} from "vue";
import PegasusModal from '@Components/base/PegasusModal.vue';
import ShortEvent from '@Components/events/ShortEvent.vue';

const {eventData, id} = defineProps<{
    eventData?: PublicEvent,
    id?: number
}>();

const event = ref<PublicEvent | null>(null);

function getEvent() {
    if (eventData) {
        event.value = eventData;
        return;
    }

    if (id) {
        PublicEvent.getById(id).then(response => {
            event.value = response;
        });
        return;
    }

    throw new Error('Either data or id must be supplied');
}

getEvent();
</script>
