<template>
    <span @click="clickEvent" @mouseleave="hoverEvent(false, $event)" @mouseover="hoverEvent(true, $event)">
        <slot></slot>
    </span>

    <OverlayPanel v-if="enabled" ref="overlay">
        <div v-if="simple" class="m-2">
            <slot name="overlay">{{ content }}</slot>
        </div>

        <PegasusPanel v-else :header="header" :pt="custom">
            <slot name="overlay">
                <span class="whitespace">{{ content }}</span>
            </slot>
        </PegasusPanel>
    </OverlayPanel>
</template>

<script lang="ts" setup>
import {computed, ref} from "vue";
import OverlayPanel from "primevue/overlaypanel";
import PegasusPanel from "@Components/base/PegasusPanel.vue";

const overlay = ref<OverlayPanel | null>(null);

const {header = '', content = '', enabled = true, simple = false, wide = false, hover = true} = defineProps<{
    header?: string,
    content?: string | null,
    enabled?: boolean,
    simple?: boolean,
    wide?: boolean,
    hover?: boolean,
}>();

const width = wide ? '600px' : '300px';

const custom = computed(() => {
    return {
        root: {
            style: 'width: ' + width,
        },
    };
});

function hoverEvent(enter: boolean, event: any): void {
    if (!hover) {
        return;
    }

    if (enter) {
        overlay.value?.show(event);
    } else {
        overlay.value?.hide();
    }
}

function clickEvent(event: any): void {
    if (hover) {
        return;
    }

    overlay.value?.toggle(event);
}
</script>
