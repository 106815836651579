/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyItemGroupStore {
  categoryApplication: number | null;
  parentAlternative: number | null;
  parentBonus: number | null;
  name: string;
  description: string;
}

export const MoneyItemGroupStoreSchema = {
  'categoryApplication': {
    name: 'categoryApplication',
    required: true,
    nullable: true,
    label: 'KPR pályázat',
    type: 'number',
      minimum: 0,
  },
  'parentAlternative': {
    name: 'parentAlternative',
    required: true,
    nullable: true,
    label: 'KPR tételcsoport',
    type: 'number',
      minimum: 0,
  },
  'parentBonus': {
    name: 'parentBonus',
    required: true,
    nullable: true,
    label: 'KPR tételcsoport',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Tételcsoport neve',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Tételcsoport leírása',
    type: 'string',
  },
}
export function instanceOfMoneyItemGroupStore(value: object): value is MoneyItemGroupStore {
let isInstance = true;
    isInstance = isInstance && "categoryApplication" in value;
    isInstance = isInstance && "parentAlternative" in value;
    isInstance = isInstance && "parentBonus" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function MoneyItemGroupStoreFromJSON(json: any): MoneyItemGroupStore {
return MoneyItemGroupStoreFromJSONTyped(json, false);
}

export function MoneyItemGroupStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemGroupStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'categoryApplication': json['categoryApplication'],
          'parentAlternative': json['parentAlternative'],
          'parentBonus': json['parentBonus'],
          'name': json['name'],
          'description': json['description'],
    };
  }

  export function MoneyItemGroupStoreToJSON(value?: MoneyItemGroupStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'categoryApplication': value.categoryApplication,
                'parentAlternative': value.parentAlternative,
                'parentBonus': value.parentBonus,
                'name': value.name,
                'description': value.description,
    };
  }

  export function MoneyItemGroupStoreCreateEmpty(): MoneyItemGroupStore {
  return {
      'categoryApplication': 0,
      'parentAlternative': 0,
      'parentBonus': 0,
      'name': '',
      'description': '',
  };
  }

