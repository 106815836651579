<template>
    <PegasusModal header="Bíráló hozzáadása">
        <PegasusForm ref="form">
            <UserSelectorField
                v-model="user"
                :schema="VikoReviewerGroupMemberSchema.user"
            ></UserSelectorField>

            <PegasusButton variant="success" @click="add">
                Hozzáadás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {VikoReviewerGroupMemberSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import UserSelectorField from '@Components/base/form/types/custom/UserSelectorField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {RuntimeError} from '@Utils/errors/RuntimeError';
import toast from '@Utils/toast';
import type {ReviewerGroup} from '@Models/viko/ReviewerGroup';
import {ref} from 'vue';
import type {User} from '@Models/base/User';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';

const {group} = defineProps<{
    group: ReviewerGroup,
}>();

const user = ref<User | null>(null);

const form = ref<FormRef>(null);

function add() {
    if (user.value === null) {
        throw new RuntimeError('Felhasználó kiválasztása kötelező');
    }

    const request = group.addUser(user.value.id, form.value);

    toast.loading(request);
}
</script>
