/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoTenderStore {
  year: number;
  semester: number;
  begin: DateTime;
  end: DateTime;
  beginDrafting: DateTime;
  endDrafting: DateTime;
  endAccepting: DateTime;
}

export const VikoTenderStoreSchema = {
  'year': {
    name: 'year',
    required: true,
    nullable: false,
    label: 'Év',
    type: 'number',
      minimum: 2020,
      maximum: 2050,
  },
  'semester': {
    name: 'semester',
    required: true,
    nullable: false,
    label: 'Félév',
    type: 'number',
      minimum: 1,
      maximum: 3,
  },
  'begin': {
    name: 'begin',
    required: true,
    nullable: false,
    label: 'Pályázati időszak kezdete',
    type: 'date',
  },
  'end': {
    name: 'end',
    required: true,
    nullable: false,
    label: 'Pályázati időszak vége',
    type: 'date',
  },
  'beginDrafting': {
    name: 'beginDrafting',
    required: true,
    nullable: false,
    label: 'Pályázás kezdete',
    type: 'datetime',
  },
  'endDrafting': {
    name: 'endDrafting',
    required: true,
    nullable: false,
    label: 'Pályázás határideje',
    type: 'datetime',
  },
  'endAccepting': {
    name: 'endAccepting',
    required: true,
    nullable: false,
    label: 'Körvezetői elfogadás határideje',
    type: 'datetime',
  },
}
export function instanceOfVikoTenderStore(value: object): value is VikoTenderStore {
let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "semester" in value;
    isInstance = isInstance && "begin" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "beginDrafting" in value;
    isInstance = isInstance && "endDrafting" in value;
    isInstance = isInstance && "endAccepting" in value;

return isInstance;
}

export function VikoTenderStoreFromJSON(json: any): VikoTenderStore {
return VikoTenderStoreFromJSONTyped(json, false);
}

export function VikoTenderStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoTenderStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'year': json['year'],
          'semester': json['semester'],
        'begin': (DateTime.fromISO(json['begin'])),
        'end': (DateTime.fromISO(json['end'])),
        'beginDrafting': (DateTime.fromISO(json['beginDrafting'])),
        'endDrafting': (DateTime.fromISO(json['endDrafting'])),
        'endAccepting': (DateTime.fromISO(json['endAccepting'])),
    };
  }

  export function VikoTenderStoreToJSON(value?: VikoTenderStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'year': value.year,
                'semester': value.semester,
            'begin': (value.begin.toISODate()),
            'end': (value.end.toISODate()),
            'beginDrafting': (value.beginDrafting.toISO()),
            'endDrafting': (value.endDrafting.toISO()),
            'endAccepting': (value.endAccepting.toISO()),
    };
  }

  export function VikoTenderStoreCreateEmpty(): VikoTenderStore {
  return {
      'year': 0,
      'semester': 0,
      'begin': DateTime.now(),
      'end': DateTime.now(),
      'beginDrafting': DateTime.now(),
      'endDrafting': DateTime.now(),
      'endAccepting': DateTime.now(),
  };
  }

