/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsEventsShowDefaultResponse } from './EventsEventsShowDefaultResponse';
    import {
    EventsEventsShowDefaultResponseFromJSON,
    EventsEventsShowDefaultResponseFromJSONTyped,
    EventsEventsShowDefaultResponseToJSON,
    EventsEventsShowDefaultResponseCreateEmpty,
    } from './EventsEventsShowDefaultResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsEventListResponse {
  events: Array<EventsEventsShowDefaultResponse>;
}

export const EventsEventListResponseSchema = {
  'events': {
    name: 'events',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsEventListResponse(value: object): value is EventsEventListResponse {
let isInstance = true;
    isInstance = isInstance && "events" in value;

return isInstance;
}

export function EventsEventListResponseFromJSON(json: any): EventsEventListResponse {
return EventsEventListResponseFromJSONTyped(json, false);
}

export function EventsEventListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsEventListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'events': ((json['events'] as Array<any>).map(EventsEventsShowDefaultResponseFromJSON)),
    };
  }

  export function EventsEventListResponseToJSON(value?: EventsEventListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'events': ((value.events as Array<any>).map(EventsEventsShowDefaultResponseToJSON)),
    };
  }

  export function EventsEventListResponseCreateEmpty(): EventsEventListResponse {
  return {
      'events': [],
  };
  }

