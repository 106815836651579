/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetLocationResponse } from './MoneyBudgetLocationResponse';
    import {
    MoneyBudgetLocationResponseFromJSON,
    MoneyBudgetLocationResponseFromJSONTyped,
    MoneyBudgetLocationResponseToJSON,
    MoneyBudgetLocationResponseCreateEmpty,
    } from './MoneyBudgetLocationResponse';
    import type { MoneyFlagResponse } from './MoneyFlagResponse';
    import {
    MoneyFlagResponseFromJSON,
    MoneyFlagResponseFromJSONTyped,
    MoneyFlagResponseToJSON,
    MoneyFlagResponseCreateEmpty,
    } from './MoneyFlagResponse';
    import type { RvtSpendingPeriodResponse } from './RvtSpendingPeriodResponse';
    import {
    RvtSpendingPeriodResponseFromJSON,
    RvtSpendingPeriodResponseFromJSONTyped,
    RvtSpendingPeriodResponseToJSON,
    RvtSpendingPeriodResponseCreateEmpty,
    } from './RvtSpendingPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseMoneyConstantsResponse {
  spendingPeriods: Array<RvtSpendingPeriodResponse>;
  budgetLocations: Array<MoneyBudgetLocationResponse>;
  flags: Array<MoneyFlagResponse>;
}

export const BaseMoneyConstantsResponseSchema = {
  'spendingPeriods': {
    name: 'spendingPeriods',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'budgetLocations': {
    name: 'budgetLocations',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'flags': {
    name: 'flags',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseMoneyConstantsResponse(value: object): value is BaseMoneyConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "spendingPeriods" in value;
    isInstance = isInstance && "budgetLocations" in value;
    isInstance = isInstance && "flags" in value;

return isInstance;
}

export function BaseMoneyConstantsResponseFromJSON(json: any): BaseMoneyConstantsResponse {
return BaseMoneyConstantsResponseFromJSONTyped(json, false);
}

export function BaseMoneyConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseMoneyConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'spendingPeriods': ((json['spendingPeriods'] as Array<any>).map(RvtSpendingPeriodResponseFromJSON)),
            'budgetLocations': ((json['budgetLocations'] as Array<any>).map(MoneyBudgetLocationResponseFromJSON)),
            'flags': ((json['flags'] as Array<any>).map(MoneyFlagResponseFromJSON)),
    };
  }

  export function BaseMoneyConstantsResponseToJSON(value?: BaseMoneyConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'spendingPeriods': ((value.spendingPeriods as Array<any>).map(RvtSpendingPeriodResponseToJSON)),
              'budgetLocations': ((value.budgetLocations as Array<any>).map(MoneyBudgetLocationResponseToJSON)),
              'flags': ((value.flags as Array<any>).map(MoneyFlagResponseToJSON)),
    };
  }

  export function BaseMoneyConstantsResponseCreateEmpty(): BaseMoneyConstantsResponse {
  return {
      'spendingPeriods': [],
      'budgetLocations': [],
      'flags': [],
  };
  }

