/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsClosureUpdate {
  type: number;
  begin: DateTime;
  end: DateTime;
  comment: string;
}

export const EventsClosureUpdateSchema = {
  'type': {
    name: 'type',
    required: true,
    nullable: false,
    label: 'Lezáratás típusa',
    type: 'number',
      minimum: 0,
  },
  'begin': {
    name: 'begin',
    required: true,
    nullable: false,
    label: 'Lezáratás kezdete',
    type: 'datetime',
  },
  'end': {
    name: 'end',
    required: true,
    nullable: false,
    label: 'Lezáratás vége',
    type: 'datetime',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfEventsClosureUpdate(value: object): value is EventsClosureUpdate {
let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "begin" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function EventsClosureUpdateFromJSON(json: any): EventsClosureUpdate {
return EventsClosureUpdateFromJSONTyped(json, false);
}

export function EventsClosureUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsClosureUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'type': json['type'],
        'begin': (DateTime.fromISO(json['begin'])),
        'end': (DateTime.fromISO(json['end'])),
          'comment': json['comment'],
    };
  }

  export function EventsClosureUpdateToJSON(value?: EventsClosureUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'type': value.type,
            'begin': (value.begin.toISO()),
            'end': (value.end.toISO()),
                'comment': value.comment,
    };
  }

  export function EventsClosureUpdateCreateEmpty(): EventsClosureUpdate {
  return {
      'type': 0,
      'begin': DateTime.now(),
      'end': DateTime.now(),
      'comment': '',
  };
  }

