import {BaseEntity} from "@Models/BaseEntity";
import type {VikoResultCategoryResponse, VikoResultCategoryStore, VikoResultCategoryUpdate} from "@/api/models";
import {VikoApi} from "@/api/apis";

export class ResultCategory extends BaseEntity {
    name: string;

    boundary: number;

    money: number;

    static parseResponse(resultCategory: ResultCategory, data: VikoResultCategoryResponse): ResultCategory {
        resultCategory.id = data.id;
        resultCategory.name = data.name;
        resultCategory.boundary = data.boundary;
        resultCategory.money = data.money;

        return resultCategory;
    }

    store(tenderId: number): Promise<ResultCategory> {
        const data: VikoResultCategoryStore = {
            tender: tenderId,
            name: this.name,
            boundary: this.boundary,
            money: this.money,
        };

        return VikoApi.resultCategoriesStore(data).then(response => {
            return ResultCategory.parseResponse(this, response.data);
        });
    }

    update(): Promise<void> {
        const data: VikoResultCategoryUpdate = {
            name: this.name,
            boundary: this.boundary,
            money: this.money,
        };

        return VikoApi.resultCategoriesUpdate(this.id, data).then(response => {
            ResultCategory.parseResponse(this, response.data);
        });
    }

    delete(): Promise<void> {
        return VikoApi.resultCategoriesDelete(this.id).then();
    }
}
