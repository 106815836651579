<template>
    <PegasusHeading>Schönherz Leltár lekérdezés</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Leltár azonosító megadása">
                <IdFinder @found="check($event)"></IdFinder>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Információ">
                <dl v-if="item">
                    <dt>Kör neve</dt>
                    <dd>{{ item.responsible.name }}</dd>

                    <dt>Körvezető neve</dt>
                    <dd>{{ item.leaderName }}</dd>

                    <dt>Körvezető e-mail címe</dt>
                    <dd>{{ item.leaderEmail }}</dd>

                    <div v-if="item.canRead">
                        <hr>

                        <dd>A megadott számú tételhez van hozzáférésed</dd>
                        <PegasusLink :params="{code: item.idAsText}" to="inventory.items.default">
                            Tétel megnyitása
                        </PegasusLink>
                    </div>
                </dl>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Schönherz Leltár lekérdezés</title>

<script lang="ts" setup>
import {PublicItem} from "@Models/inventory/PublicItem";
import IdFinder from "@Components/inventory/item/IdFinder.vue";
import {ref} from "vue";
import toast from "@Utils/toast";
import {ResponseError} from "@/api/runtime";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

function check(id: number) {
    PublicItem.getByCode(id).then(response => {
        item.value = response;
        toast.success('Információk betöltve');
    }).catch((error: ResponseError) => {
        if (error.response.status === 404) {
            toast.warning('A megadott számmal tétel nem található');
        }
    });
}

const item = ref<PublicItem | null>(null);
</script>
