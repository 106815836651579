<template>
    <PegasusOverlay :header="tag.name">
        <template #default>
            <Chip
                :icon="tag.icon + ' text-' + tag.severity"
                :label="tag.name"
                :removable="!tag.system"
                class="m-1"
                @remove="emit('remove')"
            />
        </template>

        <template #overlay>
            {{ tag.description }}
            <figcaption v-if="tag.system" class="blockquote-footer mt-2 text-end">
                <cite title="Source Title">Rendszer cimke</cite>
            </figcaption>
        </template>
    </PegasusOverlay>
</template>

<script lang="ts" setup>
import Chip from 'primevue/chip';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import type {Tag} from '@Models/inventory/Tag';

const {tag} = defineProps<{
    tag: Tag
}>();

const emit = defineEmits<{
    remove: [],
}>();
</script>
