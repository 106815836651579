/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MapRoomType } from './MapRoomType';
    import {
    MapRoomTypeFromJSON,
    MapRoomTypeFromJSONTyped,
    MapRoomTypeToJSON,
    MapRoomTypeCreateEmpty,
    } from './MapRoomType';
import {DateTime} from '@Utils/DateTime';
import { MapRoomTypeValues } from './MapRoomType';

export interface MapRoomResponse {
  id: number;
  name: string | null;
  number: string;
  type: MapRoomType;
  description: string | null;
  displayNumber: string;
}

export const MapRoomResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: MapRoomTypeValues,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'displayNumber': {
    name: 'displayNumber',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfMapRoomResponse(value: object): value is MapRoomResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "displayNumber" in value;

return isInstance;
}

export function MapRoomResponseFromJSON(json: any): MapRoomResponse {
return MapRoomResponseFromJSONTyped(json, false);
}

export function MapRoomResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapRoomResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'number': json['number'],
              'type': MapRoomTypeFromJSON(json['type']),
          'description': json['description'],
          'displayNumber': json['displayNumber'],
    };
  }

  export function MapRoomResponseToJSON(value?: MapRoomResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'number': value.number,
                'type': MapRoomTypeToJSON(value.type),
                'description': value.description,
                'displayNumber': value.displayNumber,
    };
  }

  export function MapRoomResponseCreateEmpty(): MapRoomResponse {
  return {
      'id': 0,
      'name': '',
      'number': '',
          'type': MapRoomTypeCreateEmpty(),
      'description': '',
      'displayNumber': '',
  };
  }

