<template>
    <PegasusHeading>VIKÖ pályázati rendszer</PegasusHeading>

    <p>Ezen az oldalon van lehetőség leadni a Villanykari Közösségi Ösztöndíj pályázatot.</p>

    <PegasusPanel v-if="summary" header="Aktuális pályázat">
        <div class="row">
            <div class="col-lg-4">
                <dl>
                    <dt>Pályázatod állapota</dt>
                    <dd v-if="summary.application">
                        <PegasusButton :params="{application: summary.application}" to="viko.application" variant="success">
                            Megnyitás
                        </PegasusButton>
                    </dd>
                    <dd v-else>Még nem adtál le pályázatot</dd>
                    <dd v-if="summary.canCreateApplication()">
                        <PegasusButton variant="success" @click="createApplication">
                            Pályázás megkezdése
                        </PegasusButton>
                        <br/>

                        <small v-if="summary.tender.isPublic">
                            <i class="fa fa-exclamation-triangle text-danger"></i>
                            A pályázás megkezdésével hozzájárulsz, hogy amennyiben elnyersz ösztondíjat akkor a
                            beszámolóid
                            publikálásra fognak kerülni
                        </small>
                    </dd>
                    <dd v-if="summary.reviewerGroup">
                        Mivel bírálónak vagy kijelölve, nem adhatsz le pályázatot
                    </dd>

                    <template v-if="!isEmpty(summary.ledGroups)">
                        <dt>Körvezetői adminisztráció</dt>
                        <dd v-for="group in summary.ledGroups" :key="group.id">
                            <PegasusButton :params="{group}" to="viko.leader" variant="primary">
                                {{ group.name }}
                            </PegasusButton>
                        </dd>
                    </template>

                    <template v-if="summary.canAccessReviewerSpace(user)">
                        <dt>Bírálói adminisztráció</dt>
                        <dd>
                            <PegasusButton :params="{tender: summary.tender}" to="viko.reviewer.index" variant="success">
                                Bírálói adminisztrációs oldal
                            </PegasusButton>
                        </dd>
                    </template>
                </dl>
            </div>

            <div class="col-lg-4">
                <dl>
                    <dt>Leadási időszak kezdete</dt>
                    <dd>{{ summary.tender.beginDrafting.datetime() }}</dd>
                    <dt>Leadási időszak vége</dt>
                    <dd>{{ summary.tender.endDrafting.datetime() }}</dd>
                    <dt>Körvezetői jóváhagyás határideje</dt>
                    <dd>{{ summary.tender.endAccepting.datetime() }}</dd>
                </dl>
            </div>

            <div class="col-lg-4">
                <dl>
                    <dt>Pályázatírási howto</dt>
                    <dd>
                        <a href="https://docs.google.com/document/d/1goydo4iKO1JOIGZ2dYNOLYQ9vzwKx_cv40LsvQax9mE">Megnyitás</a>
                    </dd>
                </dl>
            </div>
        </div>
    </PegasusPanel>

    <div v-else>Jelenleg nincsen pályázat kiírva</div>
</template>

<title>VIKÖ pályázati rendszer</title>

<script lang="ts" setup>
import {Application} from "@Models/viko/Application";
import {VikoIndex} from "@Models/viko/VikoIndex";
import toast from "@Utils/toast";
import {isEmpty} from "lodash-es";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {useAuthStore} from '@/stores/auth';
import {redirect} from '@/router';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

async function createApplication() {
    if (!summary) {
        toast.error('Jelenleg nincsen pályázati időszak');
        return;
    }

    if (!summary.canCreateApplication()) {
        toast.error('Nem lehetséges pályázat létrehozása');
        return;
    }

    if (!await toast.confirm('Biztosan el akarod kezdeni a pályázást?')) {
        return;
    }

    toast.info('Pályázat létrehozása folyamatban');

    Application.createNew().store().then(application => {
        toast.success('Pályázat létrehozása sikeres', 'Átirányítás folyamatban');

        redirect({
            name: 'viko.application',
            params: {application: '' + application.id},
        });
    });
}

const user = useAuthStore().requireUser();

const summary = await VikoIndex.getById().catch(() => {
    return null;
});
</script>
