<template>
    <PegasusHeading>Schönherz Leltár Tulajdonos admin</PegasusHeading>

    <h4 class="text-center p-2">{{ ownerSummary.owner.name }}</h4>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Információk">
                <dl>
                    <dt>Tételek száma</dt>
                    <dd>{{ ownerSummary.itemCount.thousands() }}</dd>
                    <dt>Össz érték</dt>
                    <dd>{{ ownerSummary.priceTotal.huf() }}</dd>
                </dl>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Schönherz Leltár adminisztrátor</title>

<script lang="ts" setup>
import {OwnerSummary} from "@Models/inventory/OwnerSummary";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {ownerId} = defineProps<{
    ownerId: number
}>();

useAuthStore().requireUser();

const ownerSummary = await OwnerSummary.getById(ownerId);
</script>
