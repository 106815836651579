import {BaseEntity} from '@Models/BaseEntity';
import {MainApi} from '@/api/apis';
import type {SystemEmailResponse} from '@/api/models';
import type {DateTime} from '@Utils/DateTime';

export class Email extends BaseEntity {
    to: string[];

    template: string;

    cc: string[];

    replyTo: string[];

    from: string;

    sent: DateTime | null;

    delivered: DateTime | null;

    messageId: string | null;

    static getRecent(): Promise<Record<number, Email>> {
        return MainApi.emailsGetRecent().then(response => {
            return Email.newRecords(response.data.emails, Email.parseResponse);
        });
    }

    static parseResponse(email: Email, data: SystemEmailResponse): Email {
        email.id = data.id;
        email.to = data.to;
        email.template = data.template;
        email.cc = data.cc;
        email.replyTo = data.replyTo;
        email.from = data.from;
        email.sent = data.sent;
        email.delivered = data.delivered;
        email.messageId = data.messageId;

        return email;
    }

    public render(): Promise<{html: string, subject: string}> {
        return MainApi.emailsRender(this.id).then(response => {
            return response.data;
        });
    }
}
