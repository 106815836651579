/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAvailabilityUpdate {
  days: Array<number>;
}

export const MentorsAvailabilityUpdateSchema = {
  'days': {
    name: 'days',
    required: true,
    nullable: false,
    label: 'Ráérések',
    type: 'array',
  },
}
export function instanceOfMentorsAvailabilityUpdate(value: object): value is MentorsAvailabilityUpdate {
let isInstance = true;
    isInstance = isInstance && "days" in value;

return isInstance;
}

export function MentorsAvailabilityUpdateFromJSON(json: any): MentorsAvailabilityUpdate {
return MentorsAvailabilityUpdateFromJSONTyped(json, false);
}

export function MentorsAvailabilityUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAvailabilityUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'days': json['days'],
    };
  }

  export function MentorsAvailabilityUpdateToJSON(value?: MentorsAvailabilityUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'days': value.days,
    };
  }

  export function MentorsAvailabilityUpdateCreateEmpty(): MentorsAvailabilityUpdate {
  return {
      'days': [],
  };
  }

