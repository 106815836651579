/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprTenderResponse } from './KprTenderResponse';
    import {
    KprTenderResponseFromJSON,
    KprTenderResponseFromJSONTyped,
    KprTenderResponseToJSON,
    KprTenderResponseCreateEmpty,
    } from './KprTenderResponse';
    import type { RvtAmendResponse } from './RvtAmendResponse';
    import {
    RvtAmendResponseFromJSON,
    RvtAmendResponseFromJSONTyped,
    RvtAmendResponseToJSON,
    RvtAmendResponseCreateEmpty,
    } from './RvtAmendResponse';
    import type { RvtClaimResponse } from './RvtClaimResponse';
    import {
    RvtClaimResponseFromJSON,
    RvtClaimResponseFromJSONTyped,
    RvtClaimResponseToJSON,
    RvtClaimResponseCreateEmpty,
    } from './RvtClaimResponse';
    import type { RvtSpendingPeriodResponse } from './RvtSpendingPeriodResponse';
    import {
    RvtSpendingPeriodResponseFromJSON,
    RvtSpendingPeriodResponseFromJSONTyped,
    RvtSpendingPeriodResponseToJSON,
    RvtSpendingPeriodResponseCreateEmpty,
    } from './RvtSpendingPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseExtendedGroupSummaryPeriodResponse {
  period: RvtSpendingPeriodResponse;
  waitingClaims: Array<RvtClaimResponse>;
  pendingClaims: Array<RvtClaimResponse>;
  tender: KprTenderResponse | null;
  waitingAmends: Array<RvtAmendResponse>;
  pendingAmends: Array<RvtAmendResponse>;
}

export const BaseExtendedGroupSummaryPeriodResponseSchema = {
  'period': {
    name: 'period',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'waitingClaims': {
    name: 'waitingClaims',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'pendingClaims': {
    name: 'pendingClaims',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'tender': {
    name: 'tender',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'waitingAmends': {
    name: 'waitingAmends',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'pendingAmends': {
    name: 'pendingAmends',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseExtendedGroupSummaryPeriodResponse(value: object): value is BaseExtendedGroupSummaryPeriodResponse {
let isInstance = true;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "waitingClaims" in value;
    isInstance = isInstance && "pendingClaims" in value;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "waitingAmends" in value;
    isInstance = isInstance && "pendingAmends" in value;

return isInstance;
}

export function BaseExtendedGroupSummaryPeriodResponseFromJSON(json: any): BaseExtendedGroupSummaryPeriodResponse {
return BaseExtendedGroupSummaryPeriodResponseFromJSONTyped(json, false);
}

export function BaseExtendedGroupSummaryPeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseExtendedGroupSummaryPeriodResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'period': RvtSpendingPeriodResponseFromJSON(json['period']),
            'waitingClaims': ((json['waitingClaims'] as Array<any>).map(RvtClaimResponseFromJSON)),
            'pendingClaims': ((json['pendingClaims'] as Array<any>).map(RvtClaimResponseFromJSON)),
              'tender': KprTenderResponseFromJSON(json['tender']),
            'waitingAmends': ((json['waitingAmends'] as Array<any>).map(RvtAmendResponseFromJSON)),
            'pendingAmends': ((json['pendingAmends'] as Array<any>).map(RvtAmendResponseFromJSON)),
    };
  }

  export function BaseExtendedGroupSummaryPeriodResponseToJSON(value?: BaseExtendedGroupSummaryPeriodResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'period': RvtSpendingPeriodResponseToJSON(value.period),
              'waitingClaims': ((value.waitingClaims as Array<any>).map(RvtClaimResponseToJSON)),
              'pendingClaims': ((value.pendingClaims as Array<any>).map(RvtClaimResponseToJSON)),
                'tender': KprTenderResponseToJSON(value.tender),
              'waitingAmends': ((value.waitingAmends as Array<any>).map(RvtAmendResponseToJSON)),
              'pendingAmends': ((value.pendingAmends as Array<any>).map(RvtAmendResponseToJSON)),
    };
  }

  export function BaseExtendedGroupSummaryPeriodResponseCreateEmpty(): BaseExtendedGroupSummaryPeriodResponse {
  return {
          'period': RvtSpendingPeriodResponseCreateEmpty(),
      'waitingClaims': [],
      'pendingClaims': [],
          'tender': KprTenderResponseCreateEmpty(),
      'waitingAmends': [],
      'pendingAmends': [],
  };
  }

