<template>
    <h4 class="p-2">Körös rendezvény adminisztráció - {{ group.name }}</h4>

    <div class="row">
        <div class="col-lg-8">
            <PegasusPanel header="Leadott körös rendezvények">
                <PegasusTable :data="events" empty-text="Nincs még leadva rendezvény a következő fél évre." search>
                    <Column field="name" header="Név"></Column>

                    <Column body-class="text-nowrap" field="date" header="Dátum"></Column>

                    <Column field="responsible.name" header="Főrendező"></Column>

                    <Column field="status" header="Státusz"></Column>

                    <Column field="status" header="Műveletek">
                        <template #body="{data}">
                            <PegasusButton variant="primary" @click="Dialog.open(ShortEventDialog, {eventData: data})">
                                <i class="fa fa-eye"></i> Összefoglaló
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>

            <PegasusPanel class="mt-2" header="Körös nagyteremosztó foglalások a következő 30 napban">
                <PegasusTable :data="reservations" empty-text="Nincsen foglalás." search>
                    <Column field="name" header="Név"></Column>

                    <Column field="dateText" header="Dátum"></Column>

                    <Column field="responsible.name" header="Foglaló"></Column>

                    <Column field="status" header="Műveletek">
                        <template #body="{data}">
                            <PegasusButton variant="info" @click="reassign(data)">
                                <i class="fa fa-exchange"></i> Átadás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>

        <div class="col-lg-4">
            <PegasusPanel header="Főrendezőivel rendelkező körtagok">
                <PegasusTable :data="members" empty-text="Egyetlen körtag se volt a jelenlegi időszakban Főrendezői tanfolyamon." search>
                    <Column field="name" header="Tag neve"></Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Rendezvény adminisztráció</title>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import {Training} from "@Models/events/Training";
import {Group} from "@Models/base/Group";
import promiseAll from "@Utils/promise";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import {Dialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';
import {Reservation} from '@Models/events/Reservation';
import ReservationReassignDialog from '@Components/events/reservations/ReservationReassignDialog.vue';

const {groupId} = defineProps<{
    groupId: number
}>();

useAuthStore().requireUser();

const group = Group.getBySingleId(groupId);

const {events, reservations, members} = await promiseAll({
    events: Event.getRelevantByGroup(groupId),
    reservations: Reservation.getRelevantByGroup(groupId),
    members: Training.getTrainingMembersForGroup(groupId),
});

function reassign(reservation: Reservation) {
    Dialog.open(ReservationReassignDialog, {reservation, members});
}
</script>
