<template>
    <PegasusTable :data="local.workers" :data-key-function="null" :per-page="5" empty-text="Üres táblázat">
        <Column :header="CommonPersonDataSchema.name.label">
            <template #body="{data}: {data: CommonPersonData}">
                <TextField
                    v-if="service.canEdit"
                    v-model="data.name"
                    :schema="CommonPersonDataSchema.name"
                    without-group
                />
                <span v-else>{{ data.name }}</span>
            </template>
        </Column>

        <Column :header="CommonPersonDataSchema.id.label">
            <template #body="{data}: {data: CommonPersonData}">
                <TextField
                    v-if="service.canEdit"
                    v-model="data.id"
                    :schema="CommonPersonDataSchema.id"
                    mask="999999aa"
                    placeholder="123456AB"
                    without-group
                />
                <span v-else>{{ data.id }}</span>
            </template>
        </Column>

        <Column :header="CommonPersonDataSchema.card.label">
            <template #body="{data}: {data: CommonPersonData}">
                <CheckboxField
                    v-if="service.canEdit"
                    v-model="data.card"
                    :schema="CommonPersonDataSchema.card"
                    without-group
                    without-label
                />

                <span v-else>{{ data.card }}</span>
            </template>
        </Column>

        <Column header="">
            <template #body="{index}">
                <PegasusButton v-if="service.canEdit" variant="danger" @click="local.workers.splice(index, 1)">
                    <i class="fa fa-times"></i>
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>

    <div class="row">
        <div class="col-4 text-center">
            <span class="align-middle">{{ local.workers.length }} dolgozó</span>
        </div>

        <div class="col-4 text-end">
            <PegasusButton v-if="service.canEdit" variant="success" @click="local.workers.push(CommonPersonDataCreateEmpty())">
                <i class="fa fa-plus"></i>
            </PegasusButton>
        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-lg-8">
            <FileField
                v-model="csv"
                :disabled="!service.canEdit"
                :schema="WorkerList.fileSchema()"
            ></FileField>
        </div>

        <div class="col-lg-4">
            <PegasusButton v-if="service.canEdit" variant="success" @click="upload">
                <i class="fa fa-file-upload"></i> Importálás
            </PegasusButton>
        </div>
    </div>

    <hr>

    <small>
        <i class="fas fa-exclamation-circle text-danger"></i>
        Nagyon fontos, hogy ellenőrizve legyen, hogy ezen a listán lévők közül senkinek sincsen kollégiumi
        jogviszonya a rendezvény idején!
    </small>

    <br>

    <small>
        <i class="fas fa-info text-primary"></i>
        Ezen a listán lévő rendezők a rendezvény idejére érvényes dolgozói kártyát
        tudnak kérni a portán. Ezzel a kártyával csippantás nélkül tudnak a
        kollégiumba ki-be menni 1 óra után is egészen amíg a rendezvény tart, ami
        után le kell adniuk a kártyát a portán!
    </small>
    <br>

    <PegasusButton variant="primary" @click="download">
        <i class="fas fa-download"></i> Exportálás CSV-be
    </PegasusButton>
</template>

<script lang="ts" setup>
import toast from "@Utils/toast";
import type {Service} from "@Models/events/Service";
import {ref} from "vue";
import FileField from "@Components/base/form/types/FileField.vue";
import {
    type CommonPersonData,
    CommonPersonDataCreateEmpty,
    CommonPersonDataSchema,
    type EventsWorkerListData
} from "@/api/models";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import TextField from "@Components/base/form/types/TextField.vue";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import {File} from "@Models/File";
import {WorkerList} from '@Models/events/services/WorkerList';

const local = defineModel<EventsWorkerListData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();

const csv = ref<File | null>(null);

function upload() {
    if (csv.value === null) {
        return;
    }

    const request = WorkerList.parseCsv(csv.value).then(workers => {
        local.value.workers = workers;
    });

    toast.loading(request, 'Feldolgozás');
}

function download() {
    WorkerList.createCsv(local.value.workers).download();
}
</script>
