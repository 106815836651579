<template>
    <PegasusHeading>Költségvetés - {{ program.name }}</PegasusHeading>

    <PegasusPanel header="Állapot">
        <PegasusSteps :steps="dictEnum(MoneyBudgetProgramStatusValues)" :current="program.status"></PegasusSteps>
    </PegasusPanel>

    <PegasusPanel class="mt-3" header="Tételek">
        <ItemGroupComponent
            v-for="itemGroup in program.itemGroups"
            :key="itemGroup.id"
            :group="itemGroup"
            :permissions="permissions"
            @delete-group="deleteItemGroup(itemGroup)"
        >
        </ItemGroupComponent>

        <PegasusButton v-if="canEdit" variant="primary" @click="newItemGroup">
            <i class="fa fa-plus-square"></i>
            Új tételcsoport hozzáadása
        </PegasusButton>
    </PegasusPanel>
</template>

<title>Költségvetés részletek</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import {computed, ref} from 'vue';
import {BudgetProgram} from '@Models/money/BudgetProgram';
import ItemGroupComponent from "@Components/money/ItemGroup.vue";
import PegasusButton from '@Components/base/PegasusButton.vue';
import type {KprPermissions} from '@Models/rvt/kpr/Application';
import {BaseGroupSpecificPermission, MoneyBudgetProgramStatusValues} from '@/api/models';
import {ItemGroup} from '@Models/money/ItemGroup';
import toast from '@Utils/toast';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {dictEnum} from '@Models/Utils';
import PegasusSteps from '@Components/base/PegasusSteps.vue';

const {programId} = defineProps<{
    programId: number
}>();

const user = useAuthStore().requireUser();

const program = ref(await BudgetProgram.getById(programId));

const canEdit = computed(() => user.value.hasGroupPermission(program.value.group, BaseGroupSpecificPermission.MANAGE_MONEY));

const permissions = computed((): KprPermissions => {
    return {
        canEdit: canEdit.value,
        canDecide: false,
        canFinalize: false,
        canConfidential: false
    };
});

function newItemGroup(): void {
    const group = ItemGroup.createNew('Névtelen tételcsoport');

    let request = group.storeForBudgetProgram(program.value.id).then(() => {
        program.value.itemGroups[group.id] = group;
    });

    toast.loading(request, 'Létrehozás');
}

async function deleteItemGroup(itemGroup: ItemGroup) {
    if (!await toast.confirm('Biztosan ki szeretnéd törölni ezt a tételcsoportot?')) {
        return;
    }

    const request = itemGroup.delete().then(() => {
        delete program.value.itemGroups[itemGroup.id];
    });

    toast.loading(request, 'Törlés');
}
</script>
