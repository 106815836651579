<template>
    <PegasusModal :header="item.displayName + ' - Megjegyzések'">
        <div class="timeline">
            <div v-for="comment in item.comments" :key="comment.id">
                <i class="fa fa-comment text-white bg-primary"></i>
                <div class="timeline-item">
                    <span class="time">
                        <i class="fas fa-clock"></i> {{ comment.createdAt.datetime() }}

                        <PegasusButton v-if="comment.creator.id === user.id" size="xs" variant="danger" @click="deleteComment(comment)">
                            <i class="fa fa-xs fa-times"></i>
                        </PegasusButton>
                    </span>
                    <h3 class="timeline-header">{{ comment.creator.name }}</h3>

                    <div class="timeline-body">{{ comment.text }}</div>
                </div>
            </div>
        </div>
        <span v-if="isEmpty(item.comments)">Még nincs megjegyzés</span>

        <hr>

        <PegasusForm ref="newForm">
            <h4>Új megjegyzés</h4>

            <TextField
                v-model="newComment.text"
                :schema="MoneyItemCommentStoreSchema.text"
            />

            <PegasusButton @click="store">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import type {Item} from '@Models/money/Item';
import {ref} from 'vue';
import TextField from '@Components/base/form/types/TextField.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {ItemComment} from '@Models/money/ItemComment';
import {useAuthStore} from '@/stores/auth';
import {isEmpty} from "lodash-es";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';
import toast from '@Utils/toast';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {MoneyItemCommentStoreSchema} from '@/api/models';

const {item} = defineProps<{
    item: Item
}>();

const newForm = ref<FormRef>(null);

const user = useAuthStore().requireUser();

const newComment = ref(ItemComment.createNew(user.value));

function store() {
    const request = newComment.value.store(item, newForm.value).then(comment => {
        item.comments[comment.id] = comment;
    });

    toast.loading(request);
}

async function deleteComment(comment: ItemComment) {
    if (!await toast.confirm('Biztos vagy benne, hogy ki szeretnéd törölni a megjegyzésedet?')) {
        return;
    }

    const request = comment.remove().then(() => {
        delete item.comments[comment.id];
    });

    toast.loading(request, 'Törlés');
}
</script>
