/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryMinimalItemGroupResponse {
  id: number;
  name: string;
  description: string;
  itemCount: number;
  responsibleGroupId: number;
}

export const InventoryMinimalItemGroupResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'itemCount': {
    name: 'itemCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'responsibleGroupId': {
    name: 'responsibleGroupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfInventoryMinimalItemGroupResponse(value: object): value is InventoryMinimalItemGroupResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "responsibleGroupId" in value;

return isInstance;
}

export function InventoryMinimalItemGroupResponseFromJSON(json: any): InventoryMinimalItemGroupResponse {
return InventoryMinimalItemGroupResponseFromJSONTyped(json, false);
}

export function InventoryMinimalItemGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryMinimalItemGroupResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'itemCount': json['itemCount'],
          'responsibleGroupId': json['responsibleGroupId'],
    };
  }

  export function InventoryMinimalItemGroupResponseToJSON(value?: InventoryMinimalItemGroupResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'itemCount': value.itemCount,
                'responsibleGroupId': value.responsibleGroupId,
    };
  }

  export function InventoryMinimalItemGroupResponseCreateEmpty(): InventoryMinimalItemGroupResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'itemCount': 0,
      'responsibleGroupId': 0,
  };
  }

