/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoTenderResponse } from './VikoTenderResponse';
    import {
    VikoTenderResponseFromJSON,
    VikoTenderResponseFromJSONTyped,
    VikoTenderResponseToJSON,
    VikoTenderResponseCreateEmpty,
    } from './VikoTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoTenderListResponse {
  tenders: Array<VikoTenderResponse>;
}

export const VikoTenderListResponseSchema = {
  'tenders': {
    name: 'tenders',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfVikoTenderListResponse(value: object): value is VikoTenderListResponse {
let isInstance = true;
    isInstance = isInstance && "tenders" in value;

return isInstance;
}

export function VikoTenderListResponseFromJSON(json: any): VikoTenderListResponse {
return VikoTenderListResponseFromJSONTyped(json, false);
}

export function VikoTenderListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoTenderListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'tenders': ((json['tenders'] as Array<any>).map(VikoTenderResponseFromJSON)),
    };
  }

  export function VikoTenderListResponseToJSON(value?: VikoTenderListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'tenders': ((value.tenders as Array<any>).map(VikoTenderResponseToJSON)),
    };
  }

  export function VikoTenderListResponseCreateEmpty(): VikoTenderListResponse {
  return {
      'tenders': [],
  };
  }

