/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsTrainingParticipationUpdate {
  active: boolean;
  quizResult: number | null;
}

export const EventsTrainingParticipationUpdateSchema = {
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: 'Részt vett',
    type: 'boolean',
  },
  'quizResult': {
    name: 'quizResult',
    required: true,
    nullable: true,
    label: 'Eredmény',
    type: 'number',
  },
}
export function instanceOfEventsTrainingParticipationUpdate(value: object): value is EventsTrainingParticipationUpdate {
let isInstance = true;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "quizResult" in value;

return isInstance;
}

export function EventsTrainingParticipationUpdateFromJSON(json: any): EventsTrainingParticipationUpdate {
return EventsTrainingParticipationUpdateFromJSONTyped(json, false);
}

export function EventsTrainingParticipationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsTrainingParticipationUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'active': json['active'],
          'quizResult': json['quizResult'],
    };
  }

  export function EventsTrainingParticipationUpdateToJSON(value?: EventsTrainingParticipationUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'active': value.active,
                'quizResult': value.quizResult,
    };
  }

  export function EventsTrainingParticipationUpdateCreateEmpty(): EventsTrainingParticipationUpdate {
  return {
      'active': false,
      'quizResult': 0,
  };
  }

