/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetLocationResponse {
  id: number;
  name: string;
}

export const MoneyBudgetLocationResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
  },
}
export function instanceOfMoneyBudgetLocationResponse(value: object): value is MoneyBudgetLocationResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

return isInstance;
}

export function MoneyBudgetLocationResponseFromJSON(json: any): MoneyBudgetLocationResponse {
return MoneyBudgetLocationResponseFromJSONTyped(json, false);
}

export function MoneyBudgetLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetLocationResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
    };
  }

  export function MoneyBudgetLocationResponseToJSON(value?: MoneyBudgetLocationResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
    };
  }

  export function MoneyBudgetLocationResponseCreateEmpty(): MoneyBudgetLocationResponse {
  return {
      'id': 0,
      'name': '',
  };
  }

