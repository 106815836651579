/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtGroupUpdate {
  kprEnabled: boolean;
  vikoEnabled: boolean;
  eventEnabled: boolean;
  inventoryEnabled: boolean;
  active: boolean;
}

export const RvtGroupUpdateSchema = {
  'kprEnabled': {
    name: 'kprEnabled',
    required: true,
    nullable: false,
    label: 'KPR engedélyezve',
    type: 'boolean',
  },
  'vikoEnabled': {
    name: 'vikoEnabled',
    required: true,
    nullable: false,
    label: 'VIKÖ engedélyezve',
    type: 'boolean',
  },
  'eventEnabled': {
    name: 'eventEnabled',
    required: true,
    nullable: false,
    label: 'Bejelentés engedélyezve',
    type: 'boolean',
  },
  'inventoryEnabled': {
    name: 'inventoryEnabled',
    required: true,
    nullable: false,
    label: 'Leltár engedélyezve',
    type: 'boolean',
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: 'Aktív',
    type: 'boolean',
  },
}
export function instanceOfRvtGroupUpdate(value: object): value is RvtGroupUpdate {
let isInstance = true;
    isInstance = isInstance && "kprEnabled" in value;
    isInstance = isInstance && "vikoEnabled" in value;
    isInstance = isInstance && "eventEnabled" in value;
    isInstance = isInstance && "inventoryEnabled" in value;
    isInstance = isInstance && "active" in value;

return isInstance;
}

export function RvtGroupUpdateFromJSON(json: any): RvtGroupUpdate {
return RvtGroupUpdateFromJSONTyped(json, false);
}

export function RvtGroupUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtGroupUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'kprEnabled': json['kprEnabled'],
          'vikoEnabled': json['vikoEnabled'],
          'eventEnabled': json['eventEnabled'],
          'inventoryEnabled': json['inventoryEnabled'],
          'active': json['active'],
    };
  }

  export function RvtGroupUpdateToJSON(value?: RvtGroupUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'kprEnabled': value.kprEnabled,
                'vikoEnabled': value.vikoEnabled,
                'eventEnabled': value.eventEnabled,
                'inventoryEnabled': value.inventoryEnabled,
                'active': value.active,
    };
  }

  export function RvtGroupUpdateCreateEmpty(): RvtGroupUpdate {
  return {
      'kprEnabled': false,
      'vikoEnabled': false,
      'eventEnabled': false,
      'inventoryEnabled': false,
      'active': false,
  };
  }

