import {BaseEntity} from "@Models/BaseEntity";
import {useConstantsStore} from "@/stores/constants";
import type {EventsLocationResponse} from "@/api/api";
import {EventsApi} from "@/api/api";

export class Location extends BaseEntity {
    name: string;

    longName: string;

    enabled: boolean;

    calendar: string | null;

    room: number | null;

    static getSingleById(id: number): Location {
        return useConstantsStore().constants.events.locations[id];
    }

    static getRecordsById(locationIds: number[]): Record<number, Location> {
        return locationIds.map(id => Location.getSingleById(id));
    }

    static parseResponse(location: Location, data: EventsLocationResponse): Location {
        location.id = data.id;
        location.name = data.name;
        location.longName = data.longName;
        location.enabled = data.enabled;
        location.calendar = data.calendar;
        location.room = data.roomId;

        return location;
    }

    update(): Promise<void> {
        const data = {
            enabled: this.enabled,
        };

        return EventsApi.locationsUpdate(this.id, data).then(response => {
            Location.parseResponse(this, response.data);
        });
    }
}
