<template>
    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel :pt="{header: {style: 'display: none'}}" class="mt-2" header-bg-variant="primary" header-text-variant="white">
                <TabView card>
                    <TabPanel>
                        <template #header>Alap</template>

                        <EventBaseTab
                            :checks="checks"
                            :event="event"
                            @dirty="dirty = true"
                            @save="saved"
                        ></EventBaseTab>
                    </TabPanel>

                    <TabPanel>
                        <template #header>
                            <span class="text-nowrap">
                                Felkérések, Extrák
                                <PegasusBadge class="ms-1" severity="info">
                                    {{ size(omitBy(event.services, isNil)) }}
                                </PegasusBadge>
                            </span>
                        </template>

                        <EventServicesTab :deadlines="deadlines" :event="event" @check="check"></EventServicesTab>
                    </TabPanel>

                    <TabPanel>
                        <template #header>
                            Lezáratások
                            <PegasusBadge class="ms-1" severity="info">
                                {{ size(event.closures) }}
                            </PegasusBadge>
                        </template>

                        <EventClosuresTab :event="event" @check="check"></EventClosuresTab>
                    </TabPanel>

                    <TabPanel v-if="user.hasPermission(BaseGlobalPermission.EVENTS_VIEW)">
                        <template #header>Admin</template>

                        <EventAdminTab :event="event"></EventAdminTab>
                    </TabPanel>

                    <TabPanel v-else>
                        <template #header>Történet</template>

                        <EventHistoryTab :event="event"></EventHistoryTab>
                    </TabPanel>
                </TabView>
            </PegasusPanel>
        </div>

        <div class="col-lg-6 mt-2">
            <EventStatusTab id="tab-status" :checks="checks" :dirty="dirty" :event="event"></EventStatusTab>
        </div>
    </div>
</template>

<title>Rendezvénybejelentő</title>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import {Checks} from "@Models/events/Checks";
import {isNil, omitBy, size} from "lodash-es";
import {onMounted, ref} from "vue";
import promiseAll from "@Utils/promise";
import EventBaseTab from "@Components/events/tabs/EventBaseTab.vue";
import EventServicesTab from "@Components/events/tabs/EventServicesTab.vue";
import EventClosuresTab from "@Components/events/tabs/EventClosuresTab.vue";
import EventHistoryTab from "@Components/events/tabs/EventHistoryTab.vue";
import EventAdminTab from "@Components/events/tabs/EventAdminTab.vue";
import EventStatusTab from "@Components/events/tabs/EventStatusTab.vue";
import {BaseGlobalPermission} from "@/api/models";
import PegasusBadge from "@Components/base/PegasusBadge.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {usePreventExit} from "@Utils/composables";
import {useAuthStore} from "@/stores/auth";
import type {DateTime} from '@Utils/DateTime';

const {eventId} = defineProps<{
    eventId: number
}>();

const user = useAuthStore().requireUser();

const dirty = ref(false);
const data = await promiseAll({
    event: Event.getById(eventId),
    checks: Checks.getForEvent(eventId),
});

const event = ref(data.event);
const checks = ref(data.checks);
const deadlines = ref<Record<string, DateTime>>({});

usePreventExit(() => dirty.value);

onMounted(() => {
    event.value.getDeadlines().then(response => {
        response.deadlines.forEach(deadline => {
            deadlines.value[deadline.serviceType] = deadline.deadline;
        });
    });
});

function saved() {
    dirty.value = false;

    check();
}

function check() {
    Checks.getForEvent(event.value.id).then(response => {
        checks.value = response;
    });
}
</script>
