/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupSummaryVikoResponse {
  tenderName: string;
  waitingReports: number;
  doneReports: number;
}

export const BaseGroupSummaryVikoResponseSchema = {
  'tenderName': {
    name: 'tenderName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'waitingReports': {
    name: 'waitingReports',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'doneReports': {
    name: 'doneReports',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfBaseGroupSummaryVikoResponse(value: object): value is BaseGroupSummaryVikoResponse {
let isInstance = true;
    isInstance = isInstance && "tenderName" in value;
    isInstance = isInstance && "waitingReports" in value;
    isInstance = isInstance && "doneReports" in value;

return isInstance;
}

export function BaseGroupSummaryVikoResponseFromJSON(json: any): BaseGroupSummaryVikoResponse {
return BaseGroupSummaryVikoResponseFromJSONTyped(json, false);
}

export function BaseGroupSummaryVikoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupSummaryVikoResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'tenderName': json['tenderName'],
          'waitingReports': json['waitingReports'],
          'doneReports': json['doneReports'],
    };
  }

  export function BaseGroupSummaryVikoResponseToJSON(value?: BaseGroupSummaryVikoResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tenderName': value.tenderName,
                'waitingReports': value.waitingReports,
                'doneReports': value.doneReports,
    };
  }

  export function BaseGroupSummaryVikoResponseCreateEmpty(): BaseGroupSummaryVikoResponse {
  return {
      'tenderName': '',
      'waitingReports': 0,
      'doneReports': 0,
  };
  }

