<template>
    <div class="row" style="margin-left: 30px">
        <div class="col-lg-6">
            <NumberField
                v-model="input"
                :schema="MoneyItemDecideSchema.price"
                inline
                number-suffix="HUF"
            />
        </div>

        <div class="col-lg-6 text-nowrap">
            <PegasusButton title="Módosítás" variant="warning" @click="emit('decide', input)">
                <i class="fa fa-edit"></i> Módosítás
            </PegasusButton>

            <PegasusButton title="Viszadobás" variant="danger" @click="emit('decide', 0)">
                <i class="fa fa-trash"></i> Nulla
            </PegasusButton>

            <PegasusButton title="Elfogadás" variant="success" @click="emit('decide', item.price)">
                <i class="fa fa-check"></i> Elfogadás
            </PegasusButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {MoneyItemDecideSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import {ref} from 'vue';
import type {Item} from '@Models/money/Item';

const {item} = defineProps<{
    item: Item
}>();

const emit = defineEmits<{
    decide: [price: number]
}>();

const input = ref(0);
</script>
