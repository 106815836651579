<template>
    <PegasusHeading>Új rendezvény bejelentése</PegasusHeading>

    <PegasusPanel header="Alapvető információk">
        <PegasusForm ref="form">
            <div class="row">
                <div class="col-lg-6">
                    <TextField
                        v-model="event.name"
                        :schema="EventsEventStoreSchema.name"
                        inline
                    ></TextField>

                    <CheckboxMultipleField
                        v-model="event.locations"
                        :options="dictArray(constants.getEnabledLocations(), loc => loc.longName)"
                        :schema="EventsEventStoreSchema.locations"
                        inline
                    ></CheckboxMultipleField>

                    <SelectField
                        v-model="event.group"
                        :options="dictArray(user.getEventableGroups(), group => group.name)"
                        :schema="EventsEventStoreSchema.group"
                        inline
                    ></SelectField>
                </div>

                <div class="col-lg-6">
                    <a class="fw-bold" href="javascript:void(0)" role="button" @click="Dialog.open(UserDialog)">
                        Főrendező:
                    </a>

                    <div class="p-2">
                        <StaticField
                            :value="user.name"
                            inline
                            label="Név"
                        ></StaticField>

                        <StaticField
                            :value="user.email"
                            inline
                            label="E-mail cím"
                        ></StaticField>

                        <StaticField
                            :value="user.room?.displayNumber ?? 'N/A'"
                            inline
                            label="Szobaszám"
                        ></StaticField>

                        <StaticField
                            :value="user.phone ?? 'N/A'"
                            inline
                            label="Telefonszám"
                        ></StaticField>
                    </div>

                    <CheckboxField
                        v-model="compliance"
                        :schema="complianceSchema"
                        inline
                    ></CheckboxField>

                    <PegasusButton variant="primary" @click="submit">
                        Rendezvény létrehozása
                    </PegasusButton>
                </div>
            </div>
        </PegasusForm>
    </PegasusPanel>
</template>

<title>Új rendezvény</title>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import TextField from "@Components/base/form/types/TextField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import {ref} from "vue";
import {useAuthStore} from "@/stores/auth";
import {isEmpty} from "lodash-es";
import toast from "@Utils/toast";
import {redirect} from "@/router";
import {useConstantsStore} from "@/stores/constants";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import CheckboxMultipleField from "@Components/base/form/types/CheckboxMultipleField.vue";
import {dictArray} from "@Models/Utils";
import {BaseGlobalPermission, EventsEventStoreSchema} from "@/api/models";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {AccessDeniedError} from "@Utils/errors/AccessDeniedError";
import type {FieldSchema} from '@Components/base/form/FieldProperties';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import StaticField from '@Components/base/form/types/StaticField.vue';
import {Dialog} from '@Utils/dialog';
import UserDialog from '@Components/dialogs/UserDialog.vue';

const user = useAuthStore().requireUser();
const constants = useConstantsStore();

if (!user.value.hasPermission(BaseGlobalPermission.EVENTS_SUBMIT)) {
    throw new AccessDeniedError('Rendezvénybejelentésre nem jogosult');
}

const event = ref(Event.createNew());
const compliance = ref(false);

const form = ref<FormRef>(null);

const complianceSchema: FieldSchema = {
    name: 'compliance',
    required: true,
    nullable: false,
    type: 'boolean',
    label: 'Kijelentem, hogy a fenti adatok megfelelnek a valóságnak'
};

async function submit() {
    if (!user.value.phone) {
        toast.error('Telefonszám hiányzik!');
        return;
    }

    if (!user.value.room) {
        toast.error('Szobaszám hiányzik!');
        return;
    }

    if (event.value.group === null) {
        toast.error('Rendező kör megadása kötelező!');
        return;
    }

    if (isEmpty(event.value.locations)) {
        toast.error('Helyszín megadása kötelező!');
        return;
    }

    if (!compliance.value) {
        toast.error('Valóságnak megfelelés tanúsítása kötelező');
        return;
    }

    if (!await toast.confirm('Biztosan minden adatot helyesen adtál meg?')) {
        return;
    }

    toast.info('Létrehozás folyamatban');

    event.value.store(form.value).then(event => {
        toast.success('Létrehozás sikeres', 'átirányítás folyamatban');

        redirect({name: 'events.edit', params: {event: '' + event.id}});
    });
}
</script>
