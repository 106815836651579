<template>
    <PegasusModal :header="manager.user.name" width="700px">
        <div class="row">
            <div class="col-lg-8">
                <PegasusForm ref="form">
                    <FileField
                        v-model="file"
                        :schema="EventsManagerImageUpdateSchema.file"
                        inline
                    ></FileField>

                    <hr>

                    <SwitchField
                        v-model="manager.active"
                        :schema="EventsManagerUpdateSchema.active"
                        inline
                    ></SwitchField>

                    <PegasusButton @click="save">
                        <i class="fa fa-save"></i> Mentés
                    </PegasusButton>
                    <br/>
                </PegasusForm>
            </div>

            <div class="col-lg-4">
                <PegasusImage v-if="image" :src="image" image-class="img-fluid" preview></PegasusImage>
            </div>
        </div>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import {ref} from 'vue';
import {EventsManagerImageUpdateSchema, EventsManagerUpdateSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import toast from '@Utils/toast';
import {useReturn} from '@Utils/dialog';
import type {Manager} from '@Models/events/Manager';
import FileField from '@Components/base/form/types/FileField.vue';
import {File} from "@Models/File";
import PegasusImage from '@Components/base/PegasusImage.vue';
import SwitchField from '@Components/base/form/types/SwitchField.vue';

const {manager} = defineProps<{
    manager: Manager,
}>();

const returnValue = useReturn();

const form = ref<FormRef>(null);

const file = ref<File | null>(null);

const image = ref<string | null>(null);

manager.downloadImage()
    .then(file => file.getAsDataUrl())
    .then(file => {
        image.value = file;
    });

async function save() {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    const requests = [];

    requests.push(manager.update(form.value));

    if (file.value) {
        requests.push(manager.updateImage(file.value, form.value));
    }

    const request = Promise.all(requests).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}
</script>
