/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryItemResponse } from './InventoryItemResponse';
    import {
    InventoryItemResponseFromJSON,
    InventoryItemResponseFromJSONTyped,
    InventoryItemResponseToJSON,
    InventoryItemResponseCreateEmpty,
    } from './InventoryItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryItemListResponse {
  items: Array<InventoryItemResponse>;
}

export const InventoryItemListResponseSchema = {
  'items': {
    name: 'items',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfInventoryItemListResponse(value: object): value is InventoryItemListResponse {
let isInstance = true;
    isInstance = isInstance && "items" in value;

return isInstance;
}

export function InventoryItemListResponseFromJSON(json: any): InventoryItemListResponse {
return InventoryItemListResponseFromJSONTyped(json, false);
}

export function InventoryItemListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'items': ((json['items'] as Array<any>).map(InventoryItemResponseFromJSON)),
    };
  }

  export function InventoryItemListResponseToJSON(value?: InventoryItemListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'items': ((value.items as Array<any>).map(InventoryItemResponseToJSON)),
    };
  }

  export function InventoryItemListResponseCreateEmpty(): InventoryItemListResponse {
  return {
      'items': [],
  };
  }

