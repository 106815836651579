/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryOwnerResponse {
  id: number;
  name: string;
  description: string;
}

export const InventoryOwnerResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfInventoryOwnerResponse(value: object): value is InventoryOwnerResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function InventoryOwnerResponseFromJSON(json: any): InventoryOwnerResponse {
return InventoryOwnerResponseFromJSONTyped(json, false);
}

export function InventoryOwnerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryOwnerResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
    };
  }

  export function InventoryOwnerResponseToJSON(value?: InventoryOwnerResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
    };
  }

  export function InventoryOwnerResponseCreateEmpty(): InventoryOwnerResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
  };
  }

