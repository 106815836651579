export type Site = 'rvt' | 'kb' | 'events' | 'mentors' | 'kpr' | 'viko' | 'central' | 'inventory';

export const currentSite = (): Site => {
    const currentSubdomain = window.location.hostname.split('.')[0];

    let site: Site;

    for (site in domains) {
        if (domains[site] === currentSubdomain) {
            return site;
        }
    }

    throw new Error('Invalid domain: ' + window.location.hostname);
};

export function upperDomain(): string {
    return window.location.hostname.substring(domains[currentSite()].length + 1);
}

export function domainForSite(site: Site): string {
    return domains[site] + '.' + upperDomain();
}

export function currentDomain(): string {
    return domainForSite(currentSite());
}

export const domains: Record<Site, string> = {
    rvt: 'rvt',
    kb: 'kb',
    events: 'bejelentes',
    mentors: 'ugyeletes',
    kpr: 'kpr',
    viko: 'viko',
    central: 'life',
    inventory: 'leltar',
};
