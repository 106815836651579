/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseUserUpdate {
  nickName: string | null;
  phone: string | null;
  room: number | null;
}

export const BaseUserUpdateSchema = {
  'nickName': {
    name: 'nickName',
    required: true,
    nullable: true,
    label: 'Becenév',
    type: 'string',
  },
  'phone': {
    name: 'phone',
    required: true,
    nullable: true,
    label: 'Telefonszám',
    type: 'string',
  },
  'room': {
    name: 'room',
    required: true,
    nullable: true,
    label: 'Szobaszám',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfBaseUserUpdate(value: object): value is BaseUserUpdate {
let isInstance = true;
    isInstance = isInstance && "nickName" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "room" in value;

return isInstance;
}

export function BaseUserUpdateFromJSON(json: any): BaseUserUpdate {
return BaseUserUpdateFromJSONTyped(json, false);
}

export function BaseUserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'nickName': json['nickName'],
          'phone': json['phone'],
          'room': json['room'],
    };
  }

  export function BaseUserUpdateToJSON(value?: BaseUserUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'nickName': value.nickName,
                'phone': value.phone,
                'room': value.room,
    };
  }

  export function BaseUserUpdateCreateEmpty(): BaseUserUpdate {
  return {
      'nickName': '',
      'phone': '',
      'room': 0,
  };
  }

