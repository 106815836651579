<template>
    <PegasusHeading>Új rendezvény foglalás</PegasusHeading>

    <PegasusPanel header="Alapvető információk">
        <PegasusForm ref="form" @submit.prevent="submit">
            <div class="row">
                <div class="col-lg-6">
                    <TextField
                        v-model="event.name"
                        :schema="EventsReservationStoreSchema.name"
                        inline
                    ></TextField>

                    <CheckboxMultipleField
                        v-model="event.locations"
                        :options="dictArray(constants.getEnabledLocations(), loc => loc.longName)"
                        :schema="EventsReservationStoreSchema.locations"
                        inline
                    ></CheckboxMultipleField>

                    <SelectField
                        v-model="event.group"
                        :options="dictArray(user.getEventableGroups(), group => group.name)"
                        :schema="EventsReservationStoreSchema.group"
                        inline
                    ></SelectField>

                    <DatetimeField
                        v-model="event.start"
                        :schema="EventsReservationStoreSchema.start"
                        inline
                    ></DatetimeField>

                    <DatetimeField
                        v-model="event.finish"
                        :schema="EventsReservationStoreSchema.finish"
                        inline
                    ></DatetimeField>
                </div>
                <div class="col-lg-6">
                    <a class="fw-bold" href="javascript:void(0)" role="button" @click="Dialog.open(UserDialog)">
                        Főrendező:
                    </a>

                    <div class="p-2">
                        <StaticField
                            :value="user.name"
                            inline
                            label="Név"
                        ></StaticField>

                        <StaticField
                            :value="user.email"
                            inline
                            label="E-mail cím"
                        ></StaticField>

                        <StaticField
                            :value="user.room?.displayNumber ?? 'N/A'"
                            inline
                            label="Szobaszám"
                        ></StaticField>

                        <StaticField
                            :value="user.phone ?? 'N/A'"
                            inline
                            label="Telefonszám"
                        ></StaticField>
                    </div>

                    <hr>

                    <CheckboxField
                        v-model="event.priority"
                        :schema="EventsReservationStoreSchema.priority"
                        inline
                    ></CheckboxField>

                    <CheckboxField
                        v-model="compliance"
                        :schema="complianceSchema"
                        inline
                    ></CheckboxField>

                    <PegasusButton variant="success" @click="submit">
                        <i class="fa fa-save"></i> Foglalás létrehozása
                    </PegasusButton>
                </div>
            </div>
        </PegasusForm>

        <PegasusButton to="events.planning.index">
            <i class="fa fa-backward"></i> Vissza a nagyteremosztóra
        </PegasusButton>
    </PegasusPanel>
</template>

<title>Új rendezvény foglalás</title>

<script lang="ts" setup>
import {Reservation} from "@Models/events/Reservation";
import {useAuthStore} from "@/stores/auth";
import {useConstantsStore} from "@/stores/constants";
import {ref} from "vue";
import toast from "@Utils/toast";
import {redirect} from "@/router";
import {dictArray} from "@Models/Utils";
import CheckboxMultipleField from "@Components/base/form/types/CheckboxMultipleField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import TextField from "@Components/base/form/types/TextField.vue";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import StaticField from "@Components/base/form/types/StaticField.vue";
import DatetimeField from "@Components/base/form/types/DatetimeField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {EventsReservationStoreSchema} from "@/api/models";
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {Dialog} from '@Utils/dialog';
import UserDialog from '@Components/dialogs/UserDialog.vue';

const user = useAuthStore().requireUser();
const constants = useConstantsStore();

const event = ref(Reservation.createNew());

const compliance = ref(false);

const form = ref<FormRef>(null);

const complianceSchema: FieldSchema = {
    name: 'compliance',
    required: true,
    nullable: false,
    type: 'boolean',
    label: 'Kijelentem, hogy a fenti adatok megfelelnek a valóságnak'
};

async function submit() {
    if (!compliance.value) {
        toast.error('Hibás kitöltés', 'Valóság igazolása kötelező');
        return;
    }

    if (!user.value.phone) {
        toast.error('Telefonszám hiányzik!');
        return;
    }

    if (!user.value.room) {
        toast.error('Szobaszám hiányzik!');
        return;
    }

    if (event.value.start.isAfter(event.value.finish)) {
        toast.error('Hibás kitöltés', 'A foglalás vége nem lehet előbb mint a kezdete');
        return;
    }

    if (!await toast.confirm('Biztosan minden adatot helyesen adtál meg?')) {
        return;
    }

    toast.info('Létrehozás folyamatban');

    event.value.store(form.value).then(() => {
        toast.success('Létrehozás sikeres', 'átirányítás folyamatban');

        redirect({name: 'events.planning.mine'});
    });
}
</script>
