/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsReservationPeriodResponse } from './EventsReservationPeriodResponse';
    import {
    EventsReservationPeriodResponseFromJSON,
    EventsReservationPeriodResponseFromJSONTyped,
    EventsReservationPeriodResponseToJSON,
    EventsReservationPeriodResponseCreateEmpty,
    } from './EventsReservationPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsReservationPeriodSummaryResponse {
  period: EventsReservationPeriodResponse;
  reservationCount: number;
}

export const EventsReservationPeriodSummaryResponseSchema = {
  'period': {
    name: 'period',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'reservationCount': {
    name: 'reservationCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfEventsReservationPeriodSummaryResponse(value: object): value is EventsReservationPeriodSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "reservationCount" in value;

return isInstance;
}

export function EventsReservationPeriodSummaryResponseFromJSON(json: any): EventsReservationPeriodSummaryResponse {
return EventsReservationPeriodSummaryResponseFromJSONTyped(json, false);
}

export function EventsReservationPeriodSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsReservationPeriodSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'period': EventsReservationPeriodResponseFromJSON(json['period']),
          'reservationCount': json['reservationCount'],
    };
  }

  export function EventsReservationPeriodSummaryResponseToJSON(value?: EventsReservationPeriodSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'period': EventsReservationPeriodResponseToJSON(value.period),
                'reservationCount': value.reservationCount,
    };
  }

  export function EventsReservationPeriodSummaryResponseCreateEmpty(): EventsReservationPeriodSummaryResponse {
  return {
          'period': EventsReservationPeriodResponseCreateEmpty(),
      'reservationCount': 0,
  };
  }

