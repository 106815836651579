/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtStatementUpdate {
  text: string;
}

export const RvtStatementUpdateSchema = {
  'text': {
    name: 'text',
    required: true,
    nullable: false,
    label: 'Beszámoló',
    type: 'string',
      minLength: 3,
      maxLength: 10000,
  },
}
export function instanceOfRvtStatementUpdate(value: object): value is RvtStatementUpdate {
let isInstance = true;
    isInstance = isInstance && "text" in value;

return isInstance;
}

export function RvtStatementUpdateFromJSON(json: any): RvtStatementUpdate {
return RvtStatementUpdateFromJSONTyped(json, false);
}

export function RvtStatementUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtStatementUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'text': json['text'],
    };
  }

  export function RvtStatementUpdateToJSON(value?: RvtStatementUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'text': value.text,
    };
  }

  export function RvtStatementUpdateCreateEmpty(): RvtStatementUpdate {
  return {
      'text': '',
  };
  }

