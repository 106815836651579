<template>
    <PegasusHeading>Felkérés kezelő - {{ config.name }}</PegasusHeading>

    <span v-if="config.canAdmin(user) && config.groupId" class="float-end">
        <PegasusButton :params="{group: config.groupId}" to="rvt.groups.settings" variant="primary">
            <i class="fas fa-users-cog"></i> Jogosultságok
        </PegasusButton>
    </span>

    <PegasusPanel class="mb-2" header="Elfogadásra váró felkérések">
        <PegasusTable :data="pending">
            <Column field="event.name" header="Rendezvény neve" sortable></Column>

            <Column field="event.date" header="Rendezvény ideje" sortable></Column>

            <Column :field="item => item.finalized.datetime()" header="Felkérés elküldve" sortable></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton variant="primary" @click="open(data)">
                        <i class="fa fa-eye"></i> Megnyitás
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>

    <PegasusPanel header="Következő 30 nap elfogadott felkérései">
        <PegasusTable :data="upcoming">
            <Column field="event.name" header="Rendezvény neve" sortable></Column>

            <Column field="event.date" header="Rendezvény ideje" sortable></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton variant="primary" @click="open(data)">
                        <i class="fa fa-eye"></i> Megnyitás
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>Felkéréskezelő</title>

<script lang="ts" setup>
import {Service} from "@Models/events/Service";
import {EventsServiceType} from "@/api/models";
import {useConstantsStore} from "@/stores/constants";
import promiseAll from "@Utils/promise";
import {ref} from "vue";
import {useAuthStore} from "@/stores/auth";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {Dialog} from '@Utils/dialog';
import ServiceManagementService from '@Components/events/services/ServiceManagementService.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {serviceType} = defineProps<{
    serviceType: EventsServiceType
}>();

const config = useConstantsStore().constants.events.services[serviceType];

const user = useAuthStore().requireUser();

const fetched = await promiseAll({
    upcoming: Service.upcoming(serviceType),
    pending: Service.pending(serviceType),
});

const upcoming = ref(fetched.upcoming);
const pending = ref(fetched.pending);

function open(service: Service) {
    Dialog.open(ServiceManagementService, {service}).then(decision => {
        if (decision === null) {
            return;
        }

        delete pending.value[service.id];

        if (decision) {
            upcoming.value[service.id] = service;
        }
    });
}
</script>
