/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
    import type { VikoTenderResponse } from './VikoTenderResponse';
    import {
    VikoTenderResponseFromJSON,
    VikoTenderResponseFromJSONTyped,
    VikoTenderResponseToJSON,
    VikoTenderResponseCreateEmpty,
    } from './VikoTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoApplicationAdminResponse {
  ignoreFromCalculations: boolean;
  id: number;
  tender: VikoTenderResponse;
  user: BaseUserResponse;
  reportCount: number;
  result: string | null;
  canEdit: boolean;
}

export const VikoApplicationAdminResponseSchema = {
  'ignoreFromCalculations': {
    name: 'ignoreFromCalculations',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'reportCount': {
    name: 'reportCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'result': {
    name: 'result',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfVikoApplicationAdminResponse(value: object): value is VikoApplicationAdminResponse {
let isInstance = true;
    isInstance = isInstance && "ignoreFromCalculations" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "reportCount" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "canEdit" in value;

return isInstance;
}

export function VikoApplicationAdminResponseFromJSON(json: any): VikoApplicationAdminResponse {
return VikoApplicationAdminResponseFromJSONTyped(json, false);
}

export function VikoApplicationAdminResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoApplicationAdminResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'ignoreFromCalculations': json['ignoreFromCalculations'],
          'id': json['id'],
              'tender': VikoTenderResponseFromJSON(json['tender']),
              'user': BaseUserResponseFromJSON(json['user']),
          'reportCount': json['reportCount'],
          'result': json['result'],
          'canEdit': json['canEdit'],
    };
  }

  export function VikoApplicationAdminResponseToJSON(value?: VikoApplicationAdminResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'ignoreFromCalculations': value.ignoreFromCalculations,
                'id': value.id,
                'tender': VikoTenderResponseToJSON(value.tender),
                'user': BaseUserResponseToJSON(value.user),
                'reportCount': value.reportCount,
                'result': value.result,
                'canEdit': value.canEdit,
    };
  }

  export function VikoApplicationAdminResponseCreateEmpty(): VikoApplicationAdminResponse {
  return {
      'ignoreFromCalculations': false,
      'id': 0,
          'tender': VikoTenderResponseCreateEmpty(),
          'user': BaseUserResponseCreateEmpty(),
      'reportCount': 0,
      'result': '',
      'canEdit': false,
  };
  }

