/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryUpdate } from './KprCategoryUpdate';
    import {
    KprCategoryUpdateFromJSON,
    KprCategoryUpdateFromJSONTyped,
    KprCategoryUpdateToJSON,
    KprCategoryUpdateCreateEmpty,
    } from './KprCategoryUpdate';
import {DateTime} from '@Utils/DateTime';

export interface KprTenderUpdate {
  categories: Array<KprCategoryUpdate>;
}

export const KprTenderUpdateSchema = {
  'categories': {
    name: 'categories',
    required: true,
    nullable: false,
    label: 'KPR pályázat kategóriái',
      type: 'array',
  },
}
export function instanceOfKprTenderUpdate(value: object): value is KprTenderUpdate {
let isInstance = true;
    isInstance = isInstance && "categories" in value;

return isInstance;
}

export function KprTenderUpdateFromJSON(json: any): KprTenderUpdate {
return KprTenderUpdateFromJSONTyped(json, false);
}

export function KprTenderUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprTenderUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'categories': ((json['categories'] as Array<any>).map(KprCategoryUpdateFromJSON)),
    };
  }

  export function KprTenderUpdateToJSON(value?: KprTenderUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'categories': ((value.categories as Array<any>).map(KprCategoryUpdateToJSON)),
    };
  }

  export function KprTenderUpdateCreateEmpty(): KprTenderUpdate {
  return {
      'categories': [],
  };
  }

