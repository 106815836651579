<template>
    <TextField
        v-model="item.name"
        :schema="InventoryCreateExistingItemSchema.name"
        description="pl: Irodai nyomtató"
    ></TextField>

    <TextField
        v-model="item.officialName"
        :schema="InventoryCreateExistingItemSchema.officialName"
        description="Számlán szereplő név pl: Canon PIXMA MG3650S"
    ></TextField>

    <TextareaField
        v-model="item.description"
        :schema="InventoryCreateExistingItemSchema.description"
        description="pl: A nyomtató amit irodában használunk nyomtatásra"
    ></TextareaField>

    <TextField
        v-model="item.uniqueIdentifier"
        :schema="InventoryCreateExistingItemSchema.uniqueIdentifier"
        description="pl: S/N: 581181867878"
    >
    </TextField>

    <NumberField
        v-model="item.count"
        :schema="InventoryCreateExistingItemSchema.count"
        description="Gyüjtőeszköz esetén"
    ></NumberField>

    <NumberField
        v-model="item.price"
        :schema="InventoryCreateExistingItemSchema.price"
        description="Új eszköz esetén az ára"
        number-suffix="HUF"
    >
    </NumberField>

    <CheckboxField
        v-model="item.material"
        :schema="InventoryCreateExistingItemSchema.material"
        description="Minden ami kézzel fogható (ami NEM pl: licensz, szoftver, előfizetés, stb.)"
    ></CheckboxField>

    <RoomSelectorField
        v-model="item.location"
        :allowed-types="[MapRoomType.BME, MapRoomType.COMMON, MapRoomType.SPECIAL]"
        :schema="InventoryCreateExistingItemSchema.location"
        description="Az a hely ahol az eszköz általában megtalálható"
    ></RoomSelectorField>

    <SelectField
        v-model="item.owner"
        :options="dictObject(owners, owner => owner.name)"
        :schema="InventoryCreateExistingItemSchema.owner"
    ></SelectField>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import TextField from "@Components/base/form/types/TextField.vue";
import {InventoryCreateExistingItemSchema, MapRoomType} from "@/api/models";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import NumberField from "@Components/base/form/types/NumberField.vue";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import RoomSelectorField from "@Components/base/form/types/custom/RoomSelectorField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import {dictObject} from "@Models/Utils";
import {useConstantsStore} from "@/stores/constants";

const {item} = defineProps<{
    item: Item,
}>();

const owners = useConstantsStore().constants.inventory.owners;
</script>
