/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsManagerImageUpdate {
  file: Blob;
}

export const EventsManagerImageUpdateSchema = {
  'file': {
    name: 'file',
    required: true,
    nullable: false,
    label: 'Fénykép',
      labelHelp: `Támogatott formátumok: jpg, jpeg`,
    type: 'file',
        extensions: ["jpg","jpeg"]
  },
}
export function instanceOfEventsManagerImageUpdate(value: object): value is EventsManagerImageUpdate {
let isInstance = true;
    isInstance = isInstance && "file" in value;

return isInstance;
}

export function EventsManagerImageUpdateFromJSON(json: any): EventsManagerImageUpdate {
return EventsManagerImageUpdateFromJSONTyped(json, false);
}

export function EventsManagerImageUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsManagerImageUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'file': json['file'],
    };
  }

  export function EventsManagerImageUpdateToJSON(value?: EventsManagerImageUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'file': value.file,
    };
  }

  export function EventsManagerImageUpdateCreateEmpty(): EventsManagerImageUpdate {
  return {
      'file': new Blob(),
  };
  }

