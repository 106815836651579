/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    BaseGroupSummaryInventoryResponse,
    BaseSuccessResponse,
    BaseValidationBagResponse,
    InventoryAdminSummaryResponse,
    InventoryCreateExistingItem,
    InventoryCreateItemAdministrator,
    InventoryDocumentResponse,
    InventoryDocumentType,
    InventoryItemDataUpdate,
    InventoryItemGroupCreate,
    InventoryItemGroupUpdate,
    InventoryItemGroupWithItemsResponse,
    InventoryItemListResponse,
    InventoryItemResponse,
    InventoryItemUpdate,
    InventoryItemWithSummaryResponse,
    InventoryMinimalItemGroupResponse,
    InventoryOwnerResponse,
    InventoryOwnerStore,
    InventoryOwnerSummaryResponse,
    InventoryOwnerUpdate,
    InventoryPublicItemResponse,
    InventoryTodayResponse,
    InventoryTransferDecision,
    InventoryUpdateItemAdministrator,
    InventoryUpdateResponse,
  } from '../models/index';
    import {
      BaseGroupSummaryInventoryResponseFromJSON,
      BaseGroupSummaryInventoryResponseToJSON,
      BaseSuccessResponseFromJSON,
      BaseSuccessResponseToJSON,
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      InventoryAdminSummaryResponseFromJSON,
      InventoryAdminSummaryResponseToJSON,
      InventoryCreateExistingItemFromJSON,
      InventoryCreateExistingItemToJSON,
      InventoryCreateItemAdministratorFromJSON,
      InventoryCreateItemAdministratorToJSON,
      InventoryDocumentResponseFromJSON,
      InventoryDocumentResponseToJSON,
      InventoryDocumentTypeFromJSON,
      InventoryDocumentTypeToJSON,
      InventoryItemDataUpdateFromJSON,
      InventoryItemDataUpdateToJSON,
      InventoryItemGroupCreateFromJSON,
      InventoryItemGroupCreateToJSON,
      InventoryItemGroupUpdateFromJSON,
      InventoryItemGroupUpdateToJSON,
      InventoryItemGroupWithItemsResponseFromJSON,
      InventoryItemGroupWithItemsResponseToJSON,
      InventoryItemListResponseFromJSON,
      InventoryItemListResponseToJSON,
      InventoryItemResponseFromJSON,
      InventoryItemResponseToJSON,
      InventoryItemUpdateFromJSON,
      InventoryItemUpdateToJSON,
      InventoryItemWithSummaryResponseFromJSON,
      InventoryItemWithSummaryResponseToJSON,
      InventoryMinimalItemGroupResponseFromJSON,
      InventoryMinimalItemGroupResponseToJSON,
      InventoryOwnerResponseFromJSON,
      InventoryOwnerResponseToJSON,
      InventoryOwnerStoreFromJSON,
      InventoryOwnerStoreToJSON,
      InventoryOwnerSummaryResponseFromJSON,
      InventoryOwnerSummaryResponseToJSON,
      InventoryOwnerUpdateFromJSON,
      InventoryOwnerUpdateToJSON,
      InventoryPublicItemResponseFromJSON,
      InventoryPublicItemResponseToJSON,
      InventoryTodayResponseFromJSON,
      InventoryTodayResponseToJSON,
      InventoryTransferDecisionFromJSON,
      InventoryTransferDecisionToJSON,
      InventoryUpdateItemAdministratorFromJSON,
      InventoryUpdateItemAdministratorToJSON,
      InventoryUpdateResponseFromJSON,
      InventoryUpdateResponseToJSON,
    } from '../models/index';

      export interface InventoryAdminItemsByTagRequest {
        tag: number;
      }

      export interface InventoryAdministratorStoreRequest {
        inventoryCreateItemAdministrator: InventoryCreateItemAdministrator;
      }

      export interface InventoryAdministratorUpdateRequest {
        item: number;
        inventoryUpdateItemAdministrator: InventoryUpdateItemAdministrator;
      }

      export interface InventoryDocumentsDeleteRequest {
        document: number;
      }

      export interface InventoryDocumentsDownloadRequest {
        document: number;
      }

      export interface InventoryDocumentsStoreRequest {
        item: number;
        name: string;
        documentType: InventoryDocumentType;
        file: Blob;
        comment: string;
      }

      export interface InventoryGroupsAllItemsRequest {
        group: number;
      }

      export interface InventoryGroupsItemsByTagRequest {
        group: number;
        tag: number;
      }

      export interface InventoryGroupsSummaryRequest {
        group: number;
      }

      export interface InventoryItemCreationExistingRequest {
        inventoryCreateExistingItem: InventoryCreateExistingItem;
      }

      export interface InventoryItemGroupsDeleteRequest {
        itemGroup: number;
      }

      export interface InventoryItemGroupsShowRequest {
        itemGroup: number;
      }

      export interface InventoryItemGroupsStoreRequest {
        inventoryItemGroupCreate: InventoryItemGroupCreate;
      }

      export interface InventoryItemGroupsUpdateRequest {
        itemGroup: number;
        inventoryItemGroupUpdate: InventoryItemGroupUpdate;
      }

      export interface InventoryItemUpdatesAddRequest {
        item: number;
        inventoryItemUpdate: InventoryItemUpdate;
      }

      export interface InventoryItemUpdatesDecideTransferRequest {
        inventoryTransferDecision: InventoryTransferDecision;
      }

      export interface InventoryItemsCheckRequest {
        item: number;
      }

      export interface InventoryItemsDestroyRequest {
        item: number;
      }

      export interface InventoryItemsShowRequest {
        item: number;
      }

      export interface InventoryItemsTagAddRequest {
        item: number;
        tag: number;
      }

      export interface InventoryItemsTagRemoveRequest {
        item: number;
        tag: number;
      }

      export interface InventoryItemsUpdateRequest {
        item: number;
        inventoryItemDataUpdate: InventoryItemDataUpdate;
      }

      export interface InventoryOwnersDeleteRequest {
        owner: number;
      }

      export interface InventoryOwnersItemsRequest {
        owner: number;
      }

      export interface InventoryOwnersStoreRequest {
        inventoryOwnerStore: InventoryOwnerStore;
      }

      export interface InventoryOwnersSummaryRequest {
        owner: number;
      }

      export interface InventoryOwnersUpdateRequest {
        owner: number;
        inventoryOwnerUpdate: InventoryOwnerUpdate;
      }

      export class InventoryApi extends runtime.BaseAPI {

      private async adminItemsByTagRaw(requestParameters: InventoryAdminItemsByTagRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemListResponse>> {
          if (requestParameters.tag === null || requestParameters.tag === undefined) {
          throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling adminItemsByTag.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/admin/itemsByTag/{tag}`.replace(`{${"tag"}}`, encodeURIComponent(String(requestParameters.tag))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemListResponseFromJSON(jsonValue));
      }

        static async adminItemsByTag(tag: number, options?: {form?: FormRef}): Promise<{data: InventoryItemListResponse, raw: Response}> {
          const api = new this();
          const response = await api.adminItemsByTagRaw({ tag: tag }, options);
          return await response.value();
        }

      private async adminSummaryRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryAdminSummaryResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/money/admin/summary`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryAdminSummaryResponseFromJSON(jsonValue));
      }

        static async adminSummary(options?: {form?: FormRef}): Promise<{data: InventoryAdminSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.adminSummaryRaw(options);
          return await response.value();
        }

      private async administratorStoreRaw(requestParameters: InventoryAdministratorStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemResponse>> {
          if (requestParameters.inventoryCreateItemAdministrator === null || requestParameters.inventoryCreateItemAdministrator === undefined) {
          throw new runtime.RequiredError('inventoryCreateItemAdministrator','Required parameter requestParameters.inventoryCreateItemAdministrator was null or undefined when calling administratorStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/administrator/store`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryCreateItemAdministratorToJSON(requestParameters.inventoryCreateItemAdministrator),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemResponseFromJSON(jsonValue));
      }

        static async administratorStore(inventoryCreateItemAdministrator: InventoryCreateItemAdministrator, options?: {form?: FormRef}): Promise<{data: InventoryItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.administratorStoreRaw({ inventoryCreateItemAdministrator: inventoryCreateItemAdministrator }, options);
          return await response.value();
        }

      private async administratorTodayRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryTodayResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/administrator/today`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryTodayResponseFromJSON(jsonValue));
      }

        static async administratorToday(options?: {form?: FormRef}): Promise<{data: InventoryTodayResponse, raw: Response}> {
          const api = new this();
          const response = await api.administratorTodayRaw(options);
          return await response.value();
        }

      private async administratorUpdateRaw(requestParameters: InventoryAdministratorUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling administratorUpdate.');
          }

          if (requestParameters.inventoryUpdateItemAdministrator === null || requestParameters.inventoryUpdateItemAdministrator === undefined) {
          throw new runtime.RequiredError('inventoryUpdateItemAdministrator','Required parameter requestParameters.inventoryUpdateItemAdministrator was null or undefined when calling administratorUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/administrator/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryUpdateItemAdministratorToJSON(requestParameters.inventoryUpdateItemAdministrator),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemResponseFromJSON(jsonValue));
      }

        static async administratorUpdate(item: number, inventoryUpdateItemAdministrator: InventoryUpdateItemAdministrator, options?: {form?: FormRef}): Promise<{data: InventoryItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.administratorUpdateRaw({ item: item, inventoryUpdateItemAdministrator: inventoryUpdateItemAdministrator }, options);
          return await response.value();
        }

      private async documentsDeleteRaw(requestParameters: InventoryDocumentsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.document === null || requestParameters.document === undefined) {
          throw new runtime.RequiredError('document','Required parameter requestParameters.document was null or undefined when calling documentsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/documents/{document}`.replace(`{${"document"}}`, encodeURIComponent(String(requestParameters.document))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async documentsDelete(document: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.documentsDeleteRaw({ document: document }, options);
          return await response.value();
        }

      private async documentsDownloadRaw(requestParameters: InventoryDocumentsDownloadRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.document === null || requestParameters.document === undefined) {
          throw new runtime.RequiredError('document','Required parameter requestParameters.document was null or undefined when calling documentsDownload.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/documents/{document}/download`.replace(`{${"document"}}`, encodeURIComponent(String(requestParameters.document))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async documentsDownload(document: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.documentsDownloadRaw({ document: document }, options);
          return await response.value();
        }

      private async documentsStoreRaw(requestParameters: InventoryDocumentsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryDocumentResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling documentsStore.');
          }

          if (requestParameters.name === null || requestParameters.name === undefined) {
          throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling documentsStore.');
          }

          if (requestParameters.documentType === null || requestParameters.documentType === undefined) {
          throw new runtime.RequiredError('documentType','Required parameter requestParameters.documentType was null or undefined when calling documentsStore.');
          }

          if (requestParameters.file === null || requestParameters.file === undefined) {
          throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling documentsStore.');
          }

          if (requestParameters.comment === null || requestParameters.comment === undefined) {
          throw new runtime.RequiredError('comment','Required parameter requestParameters.comment was null or undefined when calling documentsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
          { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
        if (useForm) {
        formParams = new FormData();
        } else {
        formParams = new URLSearchParams();
        }

            if (requestParameters.item !== undefined) {
              formParams.append('item', requestParameters.item as any);
            }

            if (requestParameters.name !== undefined) {
              formParams.append('name', requestParameters.name as any);
            }

            if (requestParameters.documentType !== undefined) {
              formParams.append('documentType', requestParameters.documentType as any);
            }

            if (requestParameters.file !== undefined) {
              formParams.append('file', requestParameters.file as any);
            }

            if (requestParameters.comment !== undefined) {
              formParams.append('comment', requestParameters.comment as any);
            }

      const response = await this.request({
      path: `/api/inventory/documents`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
        body: formParams,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryDocumentResponseFromJSON(jsonValue));
      }

        static async documentsStore(item: number, name: string, documentType: InventoryDocumentType, file: Blob, comment: string, options?: {form?: FormRef}): Promise<{data: InventoryDocumentResponse, raw: Response}> {
          const api = new this();
          const response = await api.documentsStoreRaw({ item: item, name: name, documentType: documentType, file: file, comment: comment }, options);
          return await response.value();
        }

      private async groupsAllItemsRaw(requestParameters: InventoryGroupsAllItemsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemListResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsAllItems.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/groups/{group}/allItems`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemListResponseFromJSON(jsonValue));
      }

        static async groupsAllItems(group: number, options?: {form?: FormRef}): Promise<{data: InventoryItemListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsAllItemsRaw({ group: group }, options);
          return await response.value();
        }

      private async groupsItemsByTagRaw(requestParameters: InventoryGroupsItemsByTagRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemListResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsItemsByTag.');
          }

          if (requestParameters.tag === null || requestParameters.tag === undefined) {
          throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling groupsItemsByTag.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/groups/{group}/byTags/{tag}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))).replace(`{${"tag"}}`, encodeURIComponent(String(requestParameters.tag))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemListResponseFromJSON(jsonValue));
      }

        static async groupsItemsByTag(group: number, tag: number, options?: {form?: FormRef}): Promise<{data: InventoryItemListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsItemsByTagRaw({ group: group, tag: tag }, options);
          return await response.value();
        }

      private async groupsSummaryRaw(requestParameters: InventoryGroupsSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGroupSummaryInventoryResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/groups/{group}/summary`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGroupSummaryInventoryResponseFromJSON(jsonValue));
      }

        static async groupsSummary(group: number, options?: {form?: FormRef}): Promise<{data: BaseGroupSummaryInventoryResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsSummaryRaw({ group: group }, options);
          return await response.value();
        }

      private async itemCreationExistingRaw(requestParameters: InventoryItemCreationExistingRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemResponse>> {
          if (requestParameters.inventoryCreateExistingItem === null || requestParameters.inventoryCreateExistingItem === undefined) {
          throw new runtime.RequiredError('inventoryCreateExistingItem','Required parameter requestParameters.inventoryCreateExistingItem was null or undefined when calling itemCreationExisting.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/items/create/existing`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryCreateExistingItemToJSON(requestParameters.inventoryCreateExistingItem),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemResponseFromJSON(jsonValue));
      }

        static async itemCreationExisting(inventoryCreateExistingItem: InventoryCreateExistingItem, options?: {form?: FormRef}): Promise<{data: InventoryItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemCreationExistingRaw({ inventoryCreateExistingItem: inventoryCreateExistingItem }, options);
          return await response.value();
        }

      private async itemGroupsDeleteRaw(requestParameters: InventoryItemGroupsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.itemGroup === null || requestParameters.itemGroup === undefined) {
          throw new runtime.RequiredError('itemGroup','Required parameter requestParameters.itemGroup was null or undefined when calling itemGroupsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/itemGroups/{itemGroup}`.replace(`{${"itemGroup"}}`, encodeURIComponent(String(requestParameters.itemGroup))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async itemGroupsDelete(itemGroup: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemGroupsDeleteRaw({ itemGroup: itemGroup }, options);
          return await response.value();
        }

      private async itemGroupsShowRaw(requestParameters: InventoryItemGroupsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemGroupWithItemsResponse>> {
          if (requestParameters.itemGroup === null || requestParameters.itemGroup === undefined) {
          throw new runtime.RequiredError('itemGroup','Required parameter requestParameters.itemGroup was null or undefined when calling itemGroupsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/itemGroups/{itemGroup}`.replace(`{${"itemGroup"}}`, encodeURIComponent(String(requestParameters.itemGroup))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemGroupWithItemsResponseFromJSON(jsonValue));
      }

        static async itemGroupsShow(itemGroup: number, options?: {form?: FormRef}): Promise<{data: InventoryItemGroupWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemGroupsShowRaw({ itemGroup: itemGroup }, options);
          return await response.value();
        }

      private async itemGroupsStoreRaw(requestParameters: InventoryItemGroupsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryMinimalItemGroupResponse>> {
          if (requestParameters.inventoryItemGroupCreate === null || requestParameters.inventoryItemGroupCreate === undefined) {
          throw new runtime.RequiredError('inventoryItemGroupCreate','Required parameter requestParameters.inventoryItemGroupCreate was null or undefined when calling itemGroupsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/itemGroups`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryItemGroupCreateToJSON(requestParameters.inventoryItemGroupCreate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryMinimalItemGroupResponseFromJSON(jsonValue));
      }

        static async itemGroupsStore(inventoryItemGroupCreate: InventoryItemGroupCreate, options?: {form?: FormRef}): Promise<{data: InventoryMinimalItemGroupResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemGroupsStoreRaw({ inventoryItemGroupCreate: inventoryItemGroupCreate }, options);
          return await response.value();
        }

      private async itemGroupsUpdateRaw(requestParameters: InventoryItemGroupsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryMinimalItemGroupResponse>> {
          if (requestParameters.itemGroup === null || requestParameters.itemGroup === undefined) {
          throw new runtime.RequiredError('itemGroup','Required parameter requestParameters.itemGroup was null or undefined when calling itemGroupsUpdate.');
          }

          if (requestParameters.inventoryItemGroupUpdate === null || requestParameters.inventoryItemGroupUpdate === undefined) {
          throw new runtime.RequiredError('inventoryItemGroupUpdate','Required parameter requestParameters.inventoryItemGroupUpdate was null or undefined when calling itemGroupsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/itemGroups/{itemGroup}`.replace(`{${"itemGroup"}}`, encodeURIComponent(String(requestParameters.itemGroup))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryItemGroupUpdateToJSON(requestParameters.inventoryItemGroupUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryMinimalItemGroupResponseFromJSON(jsonValue));
      }

        static async itemGroupsUpdate(itemGroup: number, inventoryItemGroupUpdate: InventoryItemGroupUpdate, options?: {form?: FormRef}): Promise<{data: InventoryMinimalItemGroupResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemGroupsUpdateRaw({ itemGroup: itemGroup, inventoryItemGroupUpdate: inventoryItemGroupUpdate }, options);
          return await response.value();
        }

      private async itemUpdatesAddRaw(requestParameters: InventoryItemUpdatesAddRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryUpdateResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemUpdatesAdd.');
          }

          if (requestParameters.inventoryItemUpdate === null || requestParameters.inventoryItemUpdate === undefined) {
          throw new runtime.RequiredError('inventoryItemUpdate','Required parameter requestParameters.inventoryItemUpdate was null or undefined when calling itemUpdatesAdd.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/items/{item}/update`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryItemUpdateToJSON(requestParameters.inventoryItemUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryUpdateResponseFromJSON(jsonValue));
      }

        static async itemUpdatesAdd(item: number, inventoryItemUpdate: InventoryItemUpdate, options?: {form?: FormRef}): Promise<{data: InventoryUpdateResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemUpdatesAddRaw({ item: item, inventoryItemUpdate: inventoryItemUpdate }, options);
          return await response.value();
        }

      private async itemUpdatesDecideTransferRaw(requestParameters: InventoryItemUpdatesDecideTransferRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryUpdateResponse>> {
          if (requestParameters.inventoryTransferDecision === null || requestParameters.inventoryTransferDecision === undefined) {
          throw new runtime.RequiredError('inventoryTransferDecision','Required parameter requestParameters.inventoryTransferDecision was null or undefined when calling itemUpdatesDecideTransfer.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/transferDecide`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryTransferDecisionToJSON(requestParameters.inventoryTransferDecision),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryUpdateResponseFromJSON(jsonValue));
      }

        static async itemUpdatesDecideTransfer(inventoryTransferDecision: InventoryTransferDecision, options?: {form?: FormRef}): Promise<{data: InventoryUpdateResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemUpdatesDecideTransferRaw({ inventoryTransferDecision: inventoryTransferDecision }, options);
          return await response.value();
        }

      private async itemsCheckRaw(requestParameters: InventoryItemsCheckRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryPublicItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsCheck.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/items/{item}/check`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryPublicItemResponseFromJSON(jsonValue));
      }

        static async itemsCheck(item: number, options?: {form?: FormRef}): Promise<{data: InventoryPublicItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsCheckRaw({ item: item }, options);
          return await response.value();
        }

      private async itemsDestroyRaw(requestParameters: InventoryItemsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async itemsDestroy(item: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsDestroyRaw({ item: item }, options);
          return await response.value();
        }

      private async itemsShowRaw(requestParameters: InventoryItemsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemWithSummaryResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemWithSummaryResponseFromJSON(jsonValue));
      }

        static async itemsShow(item: number, options?: {form?: FormRef}): Promise<{data: InventoryItemWithSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsShowRaw({ item: item }, options);
          return await response.value();
        }

      private async itemsTagAddRaw(requestParameters: InventoryItemsTagAddRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsTagAdd.');
          }

          if (requestParameters.tag === null || requestParameters.tag === undefined) {
          throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling itemsTagAdd.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/items/{item}/tags/{tag}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))).replace(`{${"tag"}}`, encodeURIComponent(String(requestParameters.tag))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemResponseFromJSON(jsonValue));
      }

        static async itemsTagAdd(item: number, tag: number, options?: {form?: FormRef}): Promise<{data: InventoryItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsTagAddRaw({ item: item, tag: tag }, options);
          return await response.value();
        }

      private async itemsTagRemoveRaw(requestParameters: InventoryItemsTagRemoveRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsTagRemove.');
          }

          if (requestParameters.tag === null || requestParameters.tag === undefined) {
          throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling itemsTagRemove.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/items/{item}/tags/{tag}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))).replace(`{${"tag"}}`, encodeURIComponent(String(requestParameters.tag))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemResponseFromJSON(jsonValue));
      }

        static async itemsTagRemove(item: number, tag: number, options?: {form?: FormRef}): Promise<{data: InventoryItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsTagRemoveRaw({ item: item, tag: tag }, options);
          return await response.value();
        }

      private async itemsUpdateRaw(requestParameters: InventoryItemsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsUpdate.');
          }

          if (requestParameters.inventoryItemDataUpdate === null || requestParameters.inventoryItemDataUpdate === undefined) {
          throw new runtime.RequiredError('inventoryItemDataUpdate','Required parameter requestParameters.inventoryItemDataUpdate was null or undefined when calling itemsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryItemDataUpdateToJSON(requestParameters.inventoryItemDataUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemResponseFromJSON(jsonValue));
      }

        static async itemsUpdate(item: number, inventoryItemDataUpdate: InventoryItemDataUpdate, options?: {form?: FormRef}): Promise<{data: InventoryItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsUpdateRaw({ item: item, inventoryItemDataUpdate: inventoryItemDataUpdate }, options);
          return await response.value();
        }

      private async ownersDeleteRaw(requestParameters: InventoryOwnersDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.owner === null || requestParameters.owner === undefined) {
          throw new runtime.RequiredError('owner','Required parameter requestParameters.owner was null or undefined when calling ownersDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/owners/{owner}`.replace(`{${"owner"}}`, encodeURIComponent(String(requestParameters.owner))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async ownersDelete(owner: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.ownersDeleteRaw({ owner: owner }, options);
          return await response.value();
        }

      private async ownersItemsRaw(requestParameters: InventoryOwnersItemsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryItemListResponse>> {
          if (requestParameters.owner === null || requestParameters.owner === undefined) {
          throw new runtime.RequiredError('owner','Required parameter requestParameters.owner was null or undefined when calling ownersItems.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/owners/{owner}/items`.replace(`{${"owner"}}`, encodeURIComponent(String(requestParameters.owner))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryItemListResponseFromJSON(jsonValue));
      }

        static async ownersItems(owner: number, options?: {form?: FormRef}): Promise<{data: InventoryItemListResponse, raw: Response}> {
          const api = new this();
          const response = await api.ownersItemsRaw({ owner: owner }, options);
          return await response.value();
        }

      private async ownersStoreRaw(requestParameters: InventoryOwnersStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryOwnerResponse>> {
          if (requestParameters.inventoryOwnerStore === null || requestParameters.inventoryOwnerStore === undefined) {
          throw new runtime.RequiredError('inventoryOwnerStore','Required parameter requestParameters.inventoryOwnerStore was null or undefined when calling ownersStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/owners`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryOwnerStoreToJSON(requestParameters.inventoryOwnerStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryOwnerResponseFromJSON(jsonValue));
      }

        static async ownersStore(inventoryOwnerStore: InventoryOwnerStore, options?: {form?: FormRef}): Promise<{data: InventoryOwnerResponse, raw: Response}> {
          const api = new this();
          const response = await api.ownersStoreRaw({ inventoryOwnerStore: inventoryOwnerStore }, options);
          return await response.value();
        }

      private async ownersSummaryRaw(requestParameters: InventoryOwnersSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryOwnerSummaryResponse>> {
          if (requestParameters.owner === null || requestParameters.owner === undefined) {
          throw new runtime.RequiredError('owner','Required parameter requestParameters.owner was null or undefined when calling ownersSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/inventory/owners/{owner}/summary`.replace(`{${"owner"}}`, encodeURIComponent(String(requestParameters.owner))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryOwnerSummaryResponseFromJSON(jsonValue));
      }

        static async ownersSummary(owner: number, options?: {form?: FormRef}): Promise<{data: InventoryOwnerSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.ownersSummaryRaw({ owner: owner }, options);
          return await response.value();
        }

      private async ownersUpdateRaw(requestParameters: InventoryOwnersUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<InventoryOwnerResponse>> {
          if (requestParameters.owner === null || requestParameters.owner === undefined) {
          throw new runtime.RequiredError('owner','Required parameter requestParameters.owner was null or undefined when calling ownersUpdate.');
          }

          if (requestParameters.inventoryOwnerUpdate === null || requestParameters.inventoryOwnerUpdate === undefined) {
          throw new runtime.RequiredError('inventoryOwnerUpdate','Required parameter requestParameters.inventoryOwnerUpdate was null or undefined when calling ownersUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/inventory/owners/{owner}`.replace(`{${"owner"}}`, encodeURIComponent(String(requestParameters.owner))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: InventoryOwnerUpdateToJSON(requestParameters.inventoryOwnerUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => InventoryOwnerResponseFromJSON(jsonValue));
      }

        static async ownersUpdate(owner: number, inventoryOwnerUpdate: InventoryOwnerUpdate, options?: {form?: FormRef}): Promise<{data: InventoryOwnerResponse, raw: Response}> {
          const api = new this();
          const response = await api.ownersUpdateRaw({ owner: owner, inventoryOwnerUpdate: inventoryOwnerUpdate }, options);
          return await response.value();
        }

    }
