/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtAmendApprove {
  result: boolean;
}

export const RvtAmendApproveSchema = {
  'result': {
    name: 'result',
    required: true,
    nullable: false,
    label: 'Jóváhagyás eredménye',
    type: 'boolean',
  },
}
export function instanceOfRvtAmendApprove(value: object): value is RvtAmendApprove {
let isInstance = true;
    isInstance = isInstance && "result" in value;

return isInstance;
}

export function RvtAmendApproveFromJSON(json: any): RvtAmendApprove {
return RvtAmendApproveFromJSONTyped(json, false);
}

export function RvtAmendApproveFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtAmendApprove {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'result': json['result'],
    };
  }

  export function RvtAmendApproveToJSON(value?: RvtAmendApprove | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'result': value.result,
    };
  }

  export function RvtAmendApproveCreateEmpty(): RvtAmendApprove {
  return {
      'result': false,
  };
  }

