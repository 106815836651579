/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupResponse } from './BaseGroupResponse';
    import {
    BaseGroupResponseFromJSON,
    BaseGroupResponseFromJSONTyped,
    BaseGroupResponseToJSON,
    BaseGroupResponseCreateEmpty,
    } from './BaseGroupResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupsConstantsResponse {
  all: Array<BaseGroupResponse>;
  capabilities: { [key: string]: string; };
}

export const BaseGroupsConstantsResponseSchema = {
  'all': {
    name: 'all',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'capabilities': {
    name: 'capabilities',
    required: true,
    nullable: false,
    label: '',
  },
}
export function instanceOfBaseGroupsConstantsResponse(value: object): value is BaseGroupsConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "all" in value;
    isInstance = isInstance && "capabilities" in value;

return isInstance;
}

export function BaseGroupsConstantsResponseFromJSON(json: any): BaseGroupsConstantsResponse {
return BaseGroupsConstantsResponseFromJSONTyped(json, false);
}

export function BaseGroupsConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupsConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'all': ((json['all'] as Array<any>).map(BaseGroupResponseFromJSON)),
          'capabilities': json['capabilities'],
    };
  }

  export function BaseGroupsConstantsResponseToJSON(value?: BaseGroupsConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'all': ((value.all as Array<any>).map(BaseGroupResponseToJSON)),
                'capabilities': value.capabilities,
    };
  }

  export function BaseGroupsConstantsResponseCreateEmpty(): BaseGroupsConstantsResponse {
  return {
      'all': [],
      'capabilities': {},
  };
  }

