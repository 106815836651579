/* tslint:disable */
/* eslint-disable */

export enum InventoryDocumentType {
    IMAGE = 1,
    INVOICE = 2,
    WARRANTY = 3,
    CONTRACT = 4
}

export const InventoryDocumentTypeValues: Record<InventoryDocumentType, string> = {
    1: 'Fénykép',
    2: 'Számla',
    3: 'Garancia',
    4: 'Szerződés'
}


export function InventoryDocumentTypeFromJSON(json: any): InventoryDocumentType {
    return InventoryDocumentTypeFromJSONTyped(json, false);
}

export function InventoryDocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryDocumentType {
    return json as InventoryDocumentType;
}

export function InventoryDocumentTypeToJSON(value?: InventoryDocumentType | null): any {
    return value as any;
}

export function InventoryDocumentTypeCreateEmpty(): InventoryDocumentType {
    return InventoryDocumentType.IMAGE;
}

