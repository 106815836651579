<template>
    <div class="row">
        <div class="col">
            <DatetimeField v-model="data.from" :disabled="!service.canEdit" :schema="EventsSpotDataSchema.from"></DatetimeField>
        </div>
        <div class="col">
            <DatetimeField v-model="data.to" :disabled="!service.canEdit" :schema="EventsSpotDataSchema.to"></DatetimeField>
        </div>
    </div>

    <TextareaField
        v-model="data.schedule"
        :disabled="!service.canEdit"
        :schema="EventsSpotDataSchema.schedule"
    ></TextareaField>

    <br/>

    <small class="d-block">
        <i class="fas fa-exclamation-circle text-danger"></i>
        Különleges igények esetén a <a href="mailto:spot@simonyi.bme.hu">spot@simonyi.bme.hu</a> e-mail címen
        kell egyeztetni.
    </small>
</template>

<script lang="ts" setup>
import type {Service} from "@Models/events/Service";
import DatetimeField from "@Components/base/form/types/DatetimeField.vue";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import type {EventsSpotData} from "@/api/models";
import {EventsSpotDataSchema} from "@/api/models";

const data = defineModel<EventsSpotData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
