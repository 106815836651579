<template>
    <PegasusHeading>VIKÖ körvezetői felület</PegasusHeading>

    <h4 class="text-center">{{ leader.group.name }}</h4>

    <PegasusPanel header="Bírálásra váró beszámolók">
        <PegasusTable :data="leader.waiting">
            <Column field="user.name" header="Név"></Column>

            <Column field="text.length" header="Karakterszám"></Column>

            <Column field="status" header="Állapot"></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton variant="primary" @click="open(data)">
                        <i class="fas fa-eye"></i> Megtekintés
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>

    <PegasusPanel header="Elfogadott beszámolók">
        <PegasusTable :data="leader.done">
            <Column field="user.name" header="Név"></Column>

            <Column field="text.length" header="Karakterszám"></Column>

            <Column field="status" header="Állapot"></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton variant="primary" @click="open(data)">
                        <i class="fas fa-eye"></i> Megtekintés
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>

    <PegasusButton to="viko.index" variant="primary">
        <i class="fas fa-backward"></i> Vissza a főoldalra
    </PegasusButton>
</template>

<title>VIKÖ körvezetői adminisztráció</title>

<script lang="ts" setup>
import {LeaderSummary} from "@Models/viko/LeaderSummary";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {Dialog} from '@Utils/dialog';
import ReportDialog from '@Components/viko/application/ReportDialog.vue';
import type {Report} from '@Models/viko/Report';
import {ref} from 'vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {groupId} = defineProps<{
    groupId: number
}>();

const leader = ref(await LeaderSummary.getByGroupId(groupId));

function open(report: Report) {
    Dialog.open(ReportDialog, {report}).then(result => {
        if (result !== null) {
            delete leader.value.waiting[report.id];

            if (result) {
                leader.value.done[report.id] = report;
            }
        }
    });
}
</script>
