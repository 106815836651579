<template>
    <PegasusHeading>Foglalás szerkesztése</PegasusHeading>

    <PegasusPanel header="Alapvető információk">
        <PegasusForm ref="form" @submit.prevent="submit">
            <div class="row">
                <div class="col-lg-6">
                    <TextField
                        v-model="event.name"
                        :disabled="admin"
                        :schema="EventsReservationUpdateSchema.name"
                        inline
                    ></TextField>

                    <CheckboxMultipleField
                        v-model="event.locations"
                        :options="dictArray(constants.getEnabledLocations(), loc => loc.longName)"
                        :schema="EventsReservationUpdateSchema.locations"
                        inline
                    ></CheckboxMultipleField>

                    <SelectField
                        v-if="!admin"
                        v-model="event.group"
                        :options="dictArray(user.getEventableGroups(), group => group.name)"
                        :schema="EventsReservationUpdateSchema.group"
                        inline
                    ></SelectField>

                    <StaticField
                        v-else
                        :schema="EventsReservationUpdateSchema.group"
                        :value="event.group.name"
                        inline
                    ></StaticField>

                    <DatetimeField
                        v-model="event.start"
                        :schema="EventsReservationUpdateSchema.start"
                        inline
                    ></DatetimeField>

                    <DatetimeField
                        v-model="event.finish"
                        :schema="EventsReservationUpdateSchema.finish"
                        inline
                    ></DatetimeField>
                </div>
                <div class="col-lg-6">
                    <template v-if="!admin">
                        <a class="fw-bold" href="javascript:void(0)" role="button" @click="Dialog.open(UserDialog)">
                            Főrendező:
                        </a>

                        <div class="p-2">
                            <StaticField
                                :value="user.name"
                                inline
                                label="Név"
                            ></StaticField>

                            <StaticField
                                :value="user.email"
                                inline
                                label="E-mail cím"
                            ></StaticField>

                            <StaticField
                                :value="user.room?.displayNumber ?? 'N/A'"
                                inline
                                label="Szobaszám"
                            ></StaticField>

                            <StaticField
                                :value="user.phone ?? 'N/A'"
                                inline
                                label="Telefonszám"
                            ></StaticField>
                        </div>
                    </template>

                    <hr>

                    <CheckboxField
                        v-model="event.priority"
                        :schema="EventsReservationUpdateSchema.priority"
                        inline
                    ></CheckboxField>

                    <CheckboxField
                        v-if="!admin"
                        v-model="compliance"
                        :schema="complianceSchema"
                        inline
                    ></CheckboxField>

                    <PegasusButton variant="success" @click="submit">
                        <i class="fa fa-save"></i> Foglalás mentése
                    </PegasusButton>
                </div>
            </div>
        </PegasusForm>

        <PegasusButton to="events.planning.mine">
            <i class="fa fa-backward"></i> Vissza a foglalásaimhoz
        </PegasusButton>
    </PegasusPanel>
</template>

<title>Foglalás szerkesztése</title>

<script lang="ts" setup>
import {Reservation} from "@Models/events/Reservation";
import TextField from "@Components/base/form/types/TextField.vue";
import DatetimeField from "@Components/base/form/types/DatetimeField.vue";
import CheckboxMultipleField from "@Components/base/form/types/CheckboxMultipleField.vue";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import {dictArray} from "@Models/Utils";
import {computed, ref} from "vue";
import {useAuthStore} from "@/stores/auth";
import {useConstantsStore} from "@/stores/constants";
import toast from "@Utils/toast";
import {redirect} from "@/router";
import StaticField from "@Components/base/form/types/StaticField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {EventsReservationUpdateSchema} from "@/api/models";
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {Dialog} from '@Utils/dialog';
import UserDialog from '@Components/dialogs/UserDialog.vue';

const user = useAuthStore().requireUser();
const constants = useConstantsStore();

const compliance = ref(false);

const form = ref<FormRef>(null);

const complianceSchema: FieldSchema = {
    name: 'compliance',
    required: true,
    nullable: false,
    type: 'boolean',
    label: 'Kijelentem, hogy a fenti adatok megfelelnek a valóságnak'
};

const {reservationId} = defineProps<{
    reservationId: number
}>();

const event = ref(await Reservation.getById(reservationId));

const admin = computed(() => event.value.responsible.id !== user.value.id);

async function submit() {
    if (!compliance.value && !admin.value) {
        toast.error('Hibás kitöltés', 'Valóság igazolása kötelező');
        return;
    }

    if (!user.value.phone) {
        toast.error('Telefonszám hiányzik!');
        return;
    }

    if (!user.value.room) {
        toast.error('Szobaszám hiányzik!');
        return;
    }

    if (event.value.start.isAfter(event.value.finish)) {
        toast.error('Hibás kitöltés', 'A foglalás vége nem lehet előbb mint a kezdete');
        return;
    }

    if (!await toast.confirm('Biztosan minden adatot helyesen adtál meg?')) {
        return;
    }

    toast.info('Mentés folyamatban');

    event.value.update(form.value).then(() => {
        toast.success('Mentés sikeres', 'átirányítás folyamatban');

        if (admin.value) {
            redirect({name: 'events.planning.index'});
        } else {
            redirect({name: 'events.planning.mine'});
        }
    });
}
</script>
