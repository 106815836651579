/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryItemDataUpdate {
  itemGroup: number | null;
  owner: number | null;
  kprItem: number | null;
  location: number | null;
  name: string;
  officialName: string;
  description: string | null;
  uniqueIdentifier: string | null;
  count: number;
  price: number;
  material: boolean;
  comment: string;
}

export const InventoryItemDataUpdateSchema = {
  'itemGroup': {
    name: 'itemGroup',
    required: true,
    nullable: true,
    label: 'Tételcsoport',
    type: 'number',
      minimum: 0,
  },
  'owner': {
    name: 'owner',
    required: true,
    nullable: true,
    label: 'Tulajdonos',
      labelHelp: `Amennyiben a listában nem szerepel a tétel PONTOS tulajdonosa, írj a leltar-support-ra`,
    type: 'number',
      minimum: 0,
  },
  'kprItem': {
    name: 'kprItem',
    required: true,
    nullable: true,
    label: 'KPR tétel',
    type: 'number',
      minimum: 0,
  },
  'location': {
    name: 'location',
    required: true,
    nullable: true,
    label: 'Helyiség',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Tétel neve',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'officialName': {
    name: 'officialName',
    required: true,
    nullable: false,
    label: 'Tétel hivatalos neve',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: true,
    label: 'Tétel leírása',
    type: 'string',
      maxLength: 1000,
  },
  'uniqueIdentifier': {
    name: 'uniqueIdentifier',
    required: true,
    nullable: true,
    label: 'Tétel egyedi azonosítója',
    type: 'string',
      maxLength: 100,
  },
  'count': {
    name: 'count',
    required: true,
    nullable: false,
    label: 'Tételek száma',
    type: 'number',
      minimum: 1,
      maximum: 1000,
  },
  'price': {
    name: 'price',
    required: true,
    nullable: false,
    label: 'Becsűlt érték',
    type: 'number',
      minimum: 0,
      maximum: 10000000,
  },
  'material': {
    name: 'material',
    required: true,
    nullable: false,
    label: 'Materiális',
    type: 'boolean',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Módosítás megjegyzése',
    type: 'string',
      maxLength: 500,
  },
}
export function instanceOfInventoryItemDataUpdate(value: object): value is InventoryItemDataUpdate {
let isInstance = true;
    isInstance = isInstance && "itemGroup" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "kprItem" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "officialName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "uniqueIdentifier" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "material" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function InventoryItemDataUpdateFromJSON(json: any): InventoryItemDataUpdate {
return InventoryItemDataUpdateFromJSONTyped(json, false);
}

export function InventoryItemDataUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemDataUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'itemGroup': json['itemGroup'],
          'owner': json['owner'],
          'kprItem': json['kprItem'],
          'location': json['location'],
          'name': json['name'],
          'officialName': json['officialName'],
          'description': json['description'],
          'uniqueIdentifier': json['uniqueIdentifier'],
          'count': json['count'],
          'price': json['price'],
          'material': json['material'],
          'comment': json['comment'],
    };
  }

  export function InventoryItemDataUpdateToJSON(value?: InventoryItemDataUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'itemGroup': value.itemGroup,
                'owner': value.owner,
                'kprItem': value.kprItem,
                'location': value.location,
                'name': value.name,
                'officialName': value.officialName,
                'description': value.description,
                'uniqueIdentifier': value.uniqueIdentifier,
                'count': value.count,
                'price': value.price,
                'material': value.material,
                'comment': value.comment,
    };
  }

  export function InventoryItemDataUpdateCreateEmpty(): InventoryItemDataUpdate {
  return {
      'itemGroup': 0,
      'owner': 0,
      'kprItem': 0,
      'location': 0,
      'name': '',
      'officialName': '',
      'description': '',
      'uniqueIdentifier': '',
      'count': 0,
      'price': 0,
      'material': false,
      'comment': '',
  };
  }

