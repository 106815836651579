/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsElectricPermitData {
  reason: string;
  times: string;
}

export const EventsElectricPermitDataSchema = {
  'reason': {
    name: 'reason',
    required: true,
    nullable: false,
    label: 'Indoklás',
    type: 'string',
      maxLength: 1000,
  },
  'times': {
    name: 'times',
    required: true,
    nullable: false,
    label: 'Időtartamok',
      labelHelp: `Milyen időszakokban van szükség az áramra`,
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfEventsElectricPermitData(value: object): value is EventsElectricPermitData {
let isInstance = true;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "times" in value;

return isInstance;
}

export function EventsElectricPermitDataFromJSON(json: any): EventsElectricPermitData {
return EventsElectricPermitDataFromJSONTyped(json, false);
}

export function EventsElectricPermitDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsElectricPermitData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'reason': json['reason'],
          'times': json['times'],
    };
  }

  export function EventsElectricPermitDataToJSON(value?: EventsElectricPermitData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'reason': value.reason,
                'times': value.times,
    };
  }

  export function EventsElectricPermitDataCreateEmpty(): EventsElectricPermitData {
  return {
      'reason': '',
      'times': '',
  };
  }

