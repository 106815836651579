<template>
    <div class="row" style="margin-left: 30px">
        <div class="col-lg-6">
            <NumberField
                v-model="input"
                :schema="MoneyItemFinalizeSchema.price"
                inline
                number-suffix="HUF"
            />
        </div>

        <div class="col-lg-6 text-nowrap">
            <PegasusButton title="Módosítás" variant="warning" @click="emit('finalize', input)">
                <i class="fa fa-edit"></i> Dobálás
            </PegasusButton>

            <PegasusButton title="Viszadobás" variant="danger" @click="emit('finalize', 0)">
                <i class="fa fa-trash"></i> Nullára dobás
            </PegasusButton>

            <PegasusButton title="Elfogadás" variant="success" @click="emit('finalize', item.price)">
                <i class="fa fa-check"></i> Meghagyás
            </PegasusButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import PegasusButton from '@Components/base/PegasusButton.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import {ref} from 'vue';
import {Item} from '@Models/money/Item';
import {MoneyItemFinalizeSchema} from '@/api/models';

const {item} = defineProps<{
    item: Item
}>();

const emit = defineEmits<{
    finalize: [price: number]
}>();

const input = ref(0);
</script>
