<template>
    <div id="main-content" class="content-wrapper">
        <RouterView v-slot="{Component, route}">
            <template v-if="Component">
                <Suspense :key="route.fullPath" @pending="pending" @resolve="resolve">
                    <div id="main-container-md" class="container-md">
                        <component :is="Component"/>
                    </div>
                    <template #fallback>
                        <span class="text-xl-center"></span>
                    </template>
                </Suspense>
            </template>
        </RouterView>

        <div id="content-loading" class="boxes">
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {RouterView} from "vue-router";

function resolve() {
    document.getElementById('main-content')?.classList.remove('loading-body');
}

function pending() {
    document.getElementById('main-content')?.classList.add('loading-body');
}
</script>
