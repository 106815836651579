/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryTerminationReason } from './InventoryTerminationReason';
    import {
    InventoryTerminationReasonFromJSON,
    InventoryTerminationReasonFromJSONTyped,
    InventoryTerminationReasonToJSON,
    InventoryTerminationReasonCreateEmpty,
    } from './InventoryTerminationReason';
import {DateTime} from '@Utils/DateTime';
import { InventoryTerminationReasonValues } from './InventoryTerminationReason';

export interface InventoryUpdateTermination {
  reason: InventoryTerminationReason;
}

export const InventoryUpdateTerminationSchema = {
  'reason': {
    name: 'reason',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Kivezetés oka",
        enumValues: InventoryTerminationReasonValues,
  },
}
export function instanceOfInventoryUpdateTermination(value: object): value is InventoryUpdateTermination {
let isInstance = true;
    isInstance = isInstance && "reason" in value;

return isInstance;
}

export function InventoryUpdateTerminationFromJSON(json: any): InventoryUpdateTermination {
return InventoryUpdateTerminationFromJSONTyped(json, false);
}

export function InventoryUpdateTerminationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateTermination {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'reason': InventoryTerminationReasonFromJSON(json['reason']),
    };
  }

  export function InventoryUpdateTerminationToJSON(value?: InventoryUpdateTermination | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'reason': InventoryTerminationReasonToJSON(value.reason),
    };
  }

  export function InventoryUpdateTerminationCreateEmpty(): InventoryUpdateTermination {
  return {
          'reason': InventoryTerminationReasonCreateEmpty(),
  };
  }

