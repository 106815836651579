/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsReservationPeriodResponse } from './EventsReservationPeriodResponse';
    import {
    EventsReservationPeriodResponseFromJSON,
    EventsReservationPeriodResponseFromJSONTyped,
    EventsReservationPeriodResponseToJSON,
    EventsReservationPeriodResponseCreateEmpty,
    } from './EventsReservationPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsReservationPeriodListResponse {
  periods: Array<EventsReservationPeriodResponse>;
}

export const EventsReservationPeriodListResponseSchema = {
  'periods': {
    name: 'periods',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsReservationPeriodListResponse(value: object): value is EventsReservationPeriodListResponse {
let isInstance = true;
    isInstance = isInstance && "periods" in value;

return isInstance;
}

export function EventsReservationPeriodListResponseFromJSON(json: any): EventsReservationPeriodListResponse {
return EventsReservationPeriodListResponseFromJSONTyped(json, false);
}

export function EventsReservationPeriodListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsReservationPeriodListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'periods': ((json['periods'] as Array<any>).map(EventsReservationPeriodResponseFromJSON)),
    };
  }

  export function EventsReservationPeriodListResponseToJSON(value?: EventsReservationPeriodListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'periods': ((value.periods as Array<any>).map(EventsReservationPeriodResponseToJSON)),
    };
  }

  export function EventsReservationPeriodListResponseCreateEmpty(): EventsReservationPeriodListResponse {
  return {
      'periods': [],
  };
  }

