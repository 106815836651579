/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface KprCategoryApplicationStore {
  application: number;
  category: number;
}

export const KprCategoryApplicationStoreSchema = {
  'application': {
    name: 'application',
    required: true,
    nullable: false,
    label: 'KPR pályázat',
    type: 'number',
      minimum: 0,
  },
  'category': {
    name: 'category',
    required: true,
    nullable: false,
    label: 'KPR kategória',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfKprCategoryApplicationStore(value: object): value is KprCategoryApplicationStore {
let isInstance = true;
    isInstance = isInstance && "application" in value;
    isInstance = isInstance && "category" in value;

return isInstance;
}

export function KprCategoryApplicationStoreFromJSON(json: any): KprCategoryApplicationStore {
return KprCategoryApplicationStoreFromJSONTyped(json, false);
}

export function KprCategoryApplicationStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprCategoryApplicationStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'application': json['application'],
          'category': json['category'],
    };
  }

  export function KprCategoryApplicationStoreToJSON(value?: KprCategoryApplicationStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'application': value.application,
                'category': value.category,
    };
  }

  export function KprCategoryApplicationStoreCreateEmpty(): KprCategoryApplicationStore {
  return {
      'application': 0,
      'category': 0,
  };
  }

