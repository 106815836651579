/* tslint:disable */
/* eslint-disable */

export enum MoneyInventoryType {
    NOT_DEVICE = 0,
    NEW = 1,
    REPLACEMENT = 2,
    PART = 3,
    REPAIR = 4,
    OTHER = 5
}

export const MoneyInventoryTypeValues: Record<MoneyInventoryType, string> = {
    0: 'Nem eszköz',
    1: 'Új eszköz',
    2: 'Alkatrész',
    3: 'Javítás',
    4: 'Csere eszköz',
    5: 'Egyéb'
}


export function MoneyInventoryTypeFromJSON(json: any): MoneyInventoryType {
    return MoneyInventoryTypeFromJSONTyped(json, false);
}

export function MoneyInventoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyInventoryType {
    return json as MoneyInventoryType;
}

export function MoneyInventoryTypeToJSON(value?: MoneyInventoryType | null): any {
    return value as any;
}

export function MoneyInventoryTypeCreateEmpty(): MoneyInventoryType {
    return MoneyInventoryType.NOT_DEVICE;
}

