import {BaseEntity} from "@Models/BaseEntity";
import {User} from "@Models/base/User";
import type {EventsReservationPeriodResponse, EventsReservationPeriodUpdate} from "@/api/api";
import {BaseGlobalPermission, EventsApi} from "@/api/api";
import {DateTime} from '@Utils/DateTime';
import {Interval} from 'luxon';
import {File} from '@Models/File';
import type {FormRef} from '@/vue';

export class ReservationPeriod extends BaseEntity {
    start: DateTime;

    finish: DateTime;

    responsible: User;

    editable: boolean;

    active: boolean;

    static createNew(): ReservationPeriod {
        return new ReservationPeriod();
    }

    static getAll(): Promise<Record<number, ReservationPeriod>> {
        return EventsApi.reservationPeriodsIndex().then(response => {
            return ReservationPeriod.newRecords(response.data.periods, ReservationPeriod.parseResponse);
        });
    }

    static parseResponse(period: ReservationPeriod, data: EventsReservationPeriodResponse): ReservationPeriod {
        period.id = data.id;
        period.start = data.start;
        period.finish = data.finish;
        period.responsible = User.newSingle(data.responsible, User.parseMinimalResponse);
        period.editable = data.editable;
        period.active = data.active;

        return period;
    }

    public update(form: FormRef): Promise<void> {
        const data: EventsReservationPeriodUpdate = {
            responsible: this.responsible.id,
            start: this.start,
            finish: this.finish,
            editable: this.editable,
            active: this.active
        };

        return EventsApi.reservationPeriodsUpdate(this.id, data, {form}).then(response => {
            ReservationPeriod.parseResponse(this, response.data);
        });
    }

    public exportReservations(): Promise<File> {
        return EventsApi.reservationPeriodsExport(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public isCurrent(): boolean {
        return this.start.isPast() && this.finish.isFuture();
    }

    public canEdit(user: User): boolean {
        return user.hasPermission(BaseGlobalPermission.ADMIN_ACL) || user.id === this.responsible.id;
    }

    get displayName(): string {
        return this.start.date() + ' - ' + this.finish.date();
    }

    get interval(): Interval {
        return this.start.until(this.finish);
    }
}
