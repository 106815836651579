/* tslint:disable */
/* eslint-disable */

export enum BaseAuthschEntrantType {
    KDO = 'KDO',
    DO = 'DO',
    KB = 'KB',
    AB = 'ÁB'
}

export const BaseAuthschEntrantTypeValues: Record<BaseAuthschEntrantType, string> = {
    'KDO': 'KDO',
    'DO': 'DO',
    'KB': 'KB',
    'ÁB': 'AB'
}


export function BaseAuthschEntrantTypeFromJSON(json: any): BaseAuthschEntrantType {
    return BaseAuthschEntrantTypeFromJSONTyped(json, false);
}

export function BaseAuthschEntrantTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAuthschEntrantType {
    return json as BaseAuthschEntrantType;
}

export function BaseAuthschEntrantTypeToJSON(value?: BaseAuthschEntrantType | null): any {
    return value as any;
}

export function BaseAuthschEntrantTypeCreateEmpty(): BaseAuthschEntrantType {
    return BaseAuthschEntrantType.KDO;
}

