/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryUpdateItemAdministrator {
  officialName: string;
  responsible: number;
  material: boolean;
}

export const InventoryUpdateItemAdministratorSchema = {
  'officialName': {
    name: 'officialName',
    required: true,
    nullable: false,
    label: 'Tétel hivatalos megnevezése',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
    label: 'Felelős kör',
    type: 'number',
      minimum: 0,
  },
  'material': {
    name: 'material',
    required: true,
    nullable: false,
    label: 'Materiális',
    type: 'boolean',
  },
}
export function instanceOfInventoryUpdateItemAdministrator(value: object): value is InventoryUpdateItemAdministrator {
let isInstance = true;
    isInstance = isInstance && "officialName" in value;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "material" in value;

return isInstance;
}

export function InventoryUpdateItemAdministratorFromJSON(json: any): InventoryUpdateItemAdministrator {
return InventoryUpdateItemAdministratorFromJSONTyped(json, false);
}

export function InventoryUpdateItemAdministratorFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateItemAdministrator {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'officialName': json['officialName'],
          'responsible': json['responsible'],
          'material': json['material'],
    };
  }

  export function InventoryUpdateItemAdministratorToJSON(value?: InventoryUpdateItemAdministrator | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'officialName': value.officialName,
                'responsible': value.responsible,
                'material': value.material,
    };
  }

  export function InventoryUpdateItemAdministratorCreateEmpty(): InventoryUpdateItemAdministrator {
  return {
      'officialName': '',
      'responsible': 0,
      'material': false,
  };
  }

