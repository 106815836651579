import {BaseEntity} from "@Models/BaseEntity";
import type {BaseGroupPostResponse} from "@/api/api";
import {filter, map, some} from "lodash-es";
import {AssignedGroupPermission} from "@Models/base/AssignedGroupPermission";
import {GroupPermission} from "@Models/base/GroupPermission";
import {useConstantsStore} from "@/stores/constants";

export class GroupPost extends BaseEntity {
    name: string;

    groupPermissions: Record<number, AssignedGroupPermission>;

    static parseResponse(post: GroupPost, data: BaseGroupPostResponse): GroupPost {
        post.id = data.id;
        post.name = data.name;
        post.groupPermissions = AssignedGroupPermission.newRecords(data.groupPermissions, AssignedGroupPermission.parseResponse);

        return post;
    }

    public getNotInheritingPermissionNames(): string[] {
        const permissions = useConstantsStore().constants.auth.groupPermissions;

        return map(
            filter(this.groupPermissions, groupPermission => !groupPermission.inheriting),
            groupPermission => permissions[groupPermission.permission].name
        );
    }

    public getInheritingPermissionNames(): string[] {
        const permissions = useConstantsStore().constants.auth.groupPermissions;

        return map(
            filter(this.groupPermissions, groupPermission => groupPermission.inheriting),
            groupPermission => permissions[groupPermission.permission].name
        );
    }

    public hasPermission(permission: GroupPermission): boolean {
        return some(this.groupPermissions, groupPermission => groupPermission.permission === permission.id);
    }

    public getAssignablePermissions(): AssignedGroupPermission[] {
        return filter(this.groupPermissions, permission => permission.canBeAssigned);
    }
}
