<template>
    <PegasusModal :header="item.name">
        <PegasusForm ref="form">
            <TextField
                v-model="name"
                :disabled="!canEdit"
                :schema="MoneyBudgetItemUpdateSchema.name"
            ></TextField>

            <TextareaField
                v-model="description"
                :disabled="!canEdit"
                :schema="MoneyBudgetItemUpdateSchema.description"
            ></TextareaField>

            <NumberField
                v-model="number"
                :disabled="!canEdit"
                :schema="MoneyBudgetItemUpdateSchema.number"
            ></NumberField>

            <GroupSelectorField
                v-model="group"
                :disabled="!canEdit"
                :schema="MoneyBudgetItemUpdateSchema.group"
            ></GroupSelectorField>

            <dt>Lábakra osztás</dt>
            <dd>
                <div v-for="value in item.values" :key="value.id">
                    {{ value.location.name }}: {{ value.value.huf() }}
                </div>
            </dd>

            <PegasusButton v-if="canEdit" variant="success" @click="save">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>

            <PegasusButton v-if="canEdit" variant="danger" @click="remove">
                <i class="fa fa-trash"></i> Sor törlése
            </PegasusButton>

            <dt>Költségvetések</dt>
            <dd>
                <PegasusTable :data="loadPrograms">
                    <Column field="name" header="Név"></Column>

                    <Column field="statusText" header="Állapot"></Column>

                    <Column>
                        <template #body="{data}: {data: BudgetProgram}">
                            <PegasusLink :params="{program: data}" new-tab to="money.budget.program.show">
                                <i class="fa fa-external-link"></i>
                            </PegasusLink>
                        </template>
                    </Column>
                </PegasusTable>
            </dd>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {BudgetItem} from '@Models/money/BudgetItem';
import type {BudgetProgram} from '@Models/money/BudgetProgram';
import PegasusLink from '@Components/base/PegasusLink.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {size} from 'lodash-es';
import type {FormRef, ServerDataResponse} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {MoneyBudgetItemUpdateSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import GroupSelectorField from '@Components/base/form/types/custom/GroupSelectorField.vue';
import {ref} from 'vue';
import {useReturn} from '@Utils/dialog';
import toast from '@Utils/toast';
import NumberField from '@Components/base/form/types/NumberField.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';

const {item, canEdit} = defineProps<{
    item: BudgetItem,
    canEdit: boolean
}>();

const emit = defineEmits<{
    delete: []
}>();

const form = ref<FormRef>(null);

const name = ref(item.name);
const description = ref(item.description);
const number = ref(item.number);
const group = ref(item.group);

const returnValue = useReturn();

function save() {
    const request = item.update(name.value, description.value, number.value, group.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}

async function remove() {
    if (!await toast.confirm('Biztos vagy benne?', 'Visszavonhatatlan művelet!')) {
        return;
    }

    const request = item.delete().then(() => {
        emit('delete');
        returnValue(false);
    });

    toast.loading(request, 'Törlés');
}

function loadPrograms(): Promise<ServerDataResponse<Record<number, BudgetProgram>>> {
    return item.getPrograms().then(response => {
        return {
            data: response,
            total: size(response)
        };
    });
}
</script>
