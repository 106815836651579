import {BaseEntity} from "@Models/BaseEntity";
import {Group} from "@Models/base/Group";
import {User} from "@Models/base/User";
import {DateTime} from "@Utils/DateTime";
import type {VikoReportDecide, VikoReportResponse, VikoReportStore, VikoReportUpdate} from "@/api/api";
import {VikoApi} from "@/api/api";

export class Report extends BaseEntity {
    text: string;

    user: User;

    group: Group;

    finalized: DateTime | null;

    accepted: boolean | null;

    leaderComment: string | null;

    canEdit: boolean;

    static getByApplication(applicationId: number): Promise<Record<number, Report>> {
        return VikoApi.applicationsReports(applicationId).then(response => {
            return Report.newRecords(response.data.reports, Report.parseResponse);
        });
    }

    static createNew(): Report {
        return new Report();
    }

    static parseResponse(report: Report, data: VikoReportResponse): Report {
        report.id = data.id;
        report.text = data.text;
        report.user = User.newSingle(data.user, User.parseMinimalResponse);
        report.group = Group.getBySingleId(data.groupId);
        report.finalized = data.finalized;
        report.accepted = data.accepted;
        report.leaderComment = data.leaderComment;
        report.canEdit = data.canEdit;

        return report;
    }

    get status() {
        if (this.accepted) {
            return 'Elfogadva';
        }

        if (this.accepted === false) {
            return 'Elutasítva';
        }

        if (this.finalized) {
            return 'Előbírálás alatt';
        }

        return 'Szerkesztés alatt';
    }

    public store(applicationId: number, groupId: number): Promise<Report> {
        const data: VikoReportStore = {
            application: applicationId,
            group: groupId,
        };

        return VikoApi.reportsStore(data).then(response => {
            return Report.parseResponse(this, response.data);
        });
    }

    public update(): Promise<void> {
        const data: VikoReportUpdate = {
            text: this.text,
        };

        return VikoApi.reportsUpdate(this.id, data).then(response => {
            Report.parseResponse(this, response.data);
        });
    }

    public send(): Promise<Report> {
        return VikoApi.reportsSend(this.id).then(response => {
            return Report.parseResponse(this, response.data);
        });
    }

    public decide(decision: boolean, comment: string): Promise<Report> {
        const data: VikoReportDecide = {
            decision: decision,
            comment: comment,
        };

        return VikoApi.reportsDecide(this.id, data).then(response => {
            return Report.parseResponse(this, response.data);
        });
    }

    public destroy(): Promise<void> {
        return VikoApi.reportsDestroy(this.id).then();
    }
}
