import {User} from "@Models/base/User";
import {Group} from "@Models/base/Group";
import {ItemGroup} from "@Models/money/ItemGroup";
import {type DecidedItem, Item} from "@Models/money/Item";
import {sumObject} from "@Models/Utils";
import {BaseRequest} from "@Models/rvt/requests/BaseRequest";
import type {RvtAmendDecide, RvtAmendResponse, RvtAmendStore, RvtAmendUpdate} from "@/api/api";
import {KprApi, RvtApi} from "@/api/api";
import type {FormRef} from '@/vue';

export class Amend extends BaseRequest {
    objective: string | null;

    reasoning: string | null;

    oldItems: Record<number, DecidedItem>;

    itemGroup: ItemGroup;

    balance: number;

    applicationId: number;

    static getById(id: number): Promise<Amend> {
        return RvtApi.amendsShow(id).then(response => {
            return Amend.newSingle(response.data, this.parseResponse);
        });
    }

    static getWaitingInTender(tenderId: number): Promise<Record<number, Amend>> {
        return KprApi.tenderWaitingAmends(tenderId).then(response => {
            return Amend.newRecords(response.data.amends, this.parseResponse);
        });
    }

    static getAllInTender(tenderId: number): Promise<Record<number, Amend>> {
        return KprApi.tenderAmends(tenderId).then(response => {
            return Amend.newRecords(response.data.amends, this.parseResponse);
        });
    }

    static create(group: Group): Amend {
        const amend = new Amend();

        amend.group = group;

        return amend;
    }

    static parseResponse(amend: Amend, data: RvtAmendResponse): Amend {
        amend.id = data.id;
        amend.requester = User.newSingle(data.requester, User.parseMinimalResponse);
        amend.requesterPost = data.requesterPost;
        amend.objective = data.objective;
        amend.reasoning = data.reasoning;
        amend.finalized = data.finalized;
        amend.approved = data.approved;
        amend.decision = data.decision;
        amend.decisionTime = data.decisionTime;
        amend.oldItems = Item.newRecords(data.oldItems, Item.parseMinimalResponse);
        amend.itemGroup = ItemGroup.newSingle(data.itemGroup, ItemGroup.parseWithItems);
        amend.group = Group.getBySingleId(data.groupId);
        amend.balance = data.balance;
        amend.createdAt = data.createdAt;
        amend.applicationId = data.applicationId;
        amend.canEdit = data.canEdit;
        amend.canFinalize = data.canFinalize;
        amend.canApprove = data.canApprove;
        amend.canDecide = data.canDecide;

        return amend;
    }

    get sumOld(): number {
        return sumObject(this.oldItems, item => item.decidedPrice ?? 0);
    }

    get displayName(): string {
        return 'KPR módosítási kérvény #' + this.id;
    }

    public store(applicationId: number): Promise<Amend> {
        const data: RvtAmendStore = {
            application: applicationId,
        };

        return RvtApi.amendsStore(data).then(response => {
            return Amend.parseResponse(this, response.data);
        });
    }

    public save(form: FormRef): Promise<void> {
        const data: RvtAmendUpdate = {
            objective: this.objective ?? '',
            reasoning: this.reasoning ?? '',
        };

        return RvtApi.amendsUpdate(this.id, data, {form}).then(response => {
            Amend.parseResponse(this, response.data);
        });
    }

    public addOldItem(item: Item): Promise<void> {
        return RvtApi.amendsAddOldItem(this.id, item.id).then(response => {
            Amend.parseResponse(this, response.data);
        });
    }

    public removeOldItem(item: Item): Promise<void> {
        return RvtApi.amendsRemoveOldItem(this.id, item.id).then(response => {
            Amend.parseResponse(this, response.data);
        });
    }

    public finalize(): Promise<void> {
        return RvtApi.amendsFinalize(this.id).then(response => {
            Amend.parseResponse(this, response.data);
        });
    }

    public approve(result: boolean): Promise<void> {
        const data = {
            result: result,
        };

        return RvtApi.amendsApprove(this.id, data).then(response => {
            Amend.parseResponse(this, response.data);
        });
    }

    public decide(decision: boolean): Promise<void> {
        const data: RvtAmendDecide = {
            decision: decision,
        };

        return RvtApi.amendsDecide(this.id, data).then(response => {
            Amend.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return RvtApi.amendsDestroy(this.id).then();
    }
}
