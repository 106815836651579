/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoGroupReviewUpdate {
  groupPoints: number;
}

export const VikoGroupReviewUpdateSchema = {
  'groupPoints': {
    name: 'groupPoints',
    required: true,
    nullable: false,
    label: 'Szervezeti bírálási pontszám',
    type: 'number',
      minimum: 1,
      maximum: 5,
  },
}
export function instanceOfVikoGroupReviewUpdate(value: object): value is VikoGroupReviewUpdate {
let isInstance = true;
    isInstance = isInstance && "groupPoints" in value;

return isInstance;
}

export function VikoGroupReviewUpdateFromJSON(json: any): VikoGroupReviewUpdate {
return VikoGroupReviewUpdateFromJSONTyped(json, false);
}

export function VikoGroupReviewUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoGroupReviewUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupPoints': json['groupPoints'],
    };
  }

  export function VikoGroupReviewUpdateToJSON(value?: VikoGroupReviewUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupPoints': value.groupPoints,
    };
  }

  export function VikoGroupReviewUpdateCreateEmpty(): VikoGroupReviewUpdate {
  return {
      'groupPoints': 0,
  };
  }

