/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseUserSearch {
  perPage: number;
  currentPage: number;
  searchTerm: string;
}

export const BaseUserSearchSchema = {
  'perPage': {
    name: 'perPage',
    required: true,
    nullable: false,
    label: 'Oldalméret',
    type: 'number',
      minimum: 1,
      maximum: 100,
  },
  'currentPage': {
    name: 'currentPage',
    required: true,
    nullable: false,
    label: 'Oldalszám',
    type: 'number',
      minimum: 1,
      maximum: 1000,
  },
  'searchTerm': {
    name: 'searchTerm',
    required: true,
    nullable: false,
    label: 'Keresés',
    type: 'string',
      maxLength: 30,
  },
}
export function instanceOfBaseUserSearch(value: object): value is BaseUserSearch {
let isInstance = true;
    isInstance = isInstance && "perPage" in value;
    isInstance = isInstance && "currentPage" in value;
    isInstance = isInstance && "searchTerm" in value;

return isInstance;
}

export function BaseUserSearchFromJSON(json: any): BaseUserSearch {
return BaseUserSearchFromJSONTyped(json, false);
}

export function BaseUserSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserSearch {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'perPage': json['perPage'],
          'currentPage': json['currentPage'],
          'searchTerm': json['searchTerm'],
    };
  }

  export function BaseUserSearchToJSON(value?: BaseUserSearch | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'perPage': value.perPage,
                'currentPage': value.currentPage,
                'searchTerm': value.searchTerm,
    };
  }

  export function BaseUserSearchCreateEmpty(): BaseUserSearch {
  return {
      'perPage': 0,
      'currentPage': 0,
      'searchTerm': '',
  };
  }

