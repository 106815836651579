/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsCalendarEventData {
  description: string;
}

export const EventsCalendarEventDataSchema = {
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
      labelHelp: `Az itt lévő szöveg a publikus rendezvénynaptárban fog megjelenni.Ennek megfelelően fogalmazott reklám szöveget, vagy plusz információt a rendezvényhez érdemes ideírni`,
    type: 'string',
      maxLength: 500,
  },
}
export function instanceOfEventsCalendarEventData(value: object): value is EventsCalendarEventData {
let isInstance = true;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function EventsCalendarEventDataFromJSON(json: any): EventsCalendarEventData {
return EventsCalendarEventDataFromJSONTyped(json, false);
}

export function EventsCalendarEventDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsCalendarEventData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'description': json['description'],
    };
  }

  export function EventsCalendarEventDataToJSON(value?: EventsCalendarEventData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'description': value.description,
    };
  }

  export function EventsCalendarEventDataCreateEmpty(): EventsCalendarEventData {
  return {
      'description': '',
  };
  }

