/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryQuality } from './InventoryQuality';
    import {
    InventoryQualityFromJSON,
    InventoryQualityFromJSONTyped,
    InventoryQualityToJSON,
    InventoryQualityCreateEmpty,
    } from './InventoryQuality';
import {DateTime} from '@Utils/DateTime';
import { InventoryQualityValues } from './InventoryQuality';

export interface InventoryUpdateDamage {
  quality: InventoryQuality;
  description: string;
}

export const InventoryUpdateDamageSchema = {
  'quality': {
    name: 'quality',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Állapot",
        enumValues: InventoryQualityValues,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Sérülés leírása',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfInventoryUpdateDamage(value: object): value is InventoryUpdateDamage {
let isInstance = true;
    isInstance = isInstance && "quality" in value;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function InventoryUpdateDamageFromJSON(json: any): InventoryUpdateDamage {
return InventoryUpdateDamageFromJSONTyped(json, false);
}

export function InventoryUpdateDamageFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateDamage {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'quality': InventoryQualityFromJSON(json['quality']),
          'description': json['description'],
    };
  }

  export function InventoryUpdateDamageToJSON(value?: InventoryUpdateDamage | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'quality': InventoryQualityToJSON(value.quality),
                'description': value.description,
    };
  }

  export function InventoryUpdateDamageCreateEmpty(): InventoryUpdateDamage {
  return {
          'quality': InventoryQualityCreateEmpty(),
      'description': '',
  };
  }

