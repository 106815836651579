<template>
    <PegasusHeading>Korábban vett eszköz felvétele</PegasusHeading>

    <PegasusForm ref="form">
        <div class="row">
            <div v-if="collapsed" class="col-lg-4">
                <PegasusPanel class="mt-2" header="Tétel azonosító" header-bg-variant="primary">
                    <IdFinder ref="finder" @found="item.id = $event"></IdFinder>
                </PegasusPanel>
            </div>

            <div class="col-lg-8">
                <PegasusPanel class="mt-2" header="Tétel adatai">
                    <TextField
                        :model-value="item.idAsText"
                        :schema="InventoryCreateExistingItemSchema.id"
                        disabled
                    ></TextField>

                    <SelectField
                        v-model="creationSource"
                        :options="sourceValues"
                        :schema="InventoryCreateExistingItemSchema.creationSource"
                    ></SelectField>

                    <DataForm :item="item"></DataForm>
                </PegasusPanel>
            </div>

            <div class="col-lg-4">
                <PegasusPanel v-if="!collapsed" class="mt-2" header="Tétel azonosító" header-bg-variant="primary">
                    <IdFinder ref="finder2" @found="item.id = $event"></IdFinder>
                </PegasusPanel>

                <PegasusPanel class="mt-3" header="Mentés" header-bg-variant="success">
                    <TextareaField
                        v-model="comment"
                        :schema="InventoryCreateExistingItemSchema.comment"
                    ></TextareaField>

                    <PegasusButton variant="success" @click="saveAndOpen">
                        <i class="fa fa-save"></i> Mentés és megnyitás
                    </PegasusButton>

                    <PegasusButton variant="success" @click="saveAndNext">
                        <i class="fa fa-forward"></i> Mentés és következő
                    </PegasusButton>
                </PegasusPanel>
            </div>
        </div>
    </PegasusForm>

    <PegasusButton :params="{group}" class="my-2" to="inventory.groups.index">
        Vissza a körhöz
    </PegasusButton>
</template>

<title>Tétel létrehozás</title>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import DataForm from "@Components/inventory/item/DataForm.vue";
import IdFinder from "@Components/inventory/item/IdFinder.vue";
import {InventoryCreateExistingItemSchema, InventoryCreationSource, InventoryCreationSourceValues} from "@/api/api";
import {computed, ref} from "vue";
import {dictNumberEnum} from "@Models/Utils";
import TextField from "@Components/base/form/types/TextField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import {redirect} from "@/router";
import toast from "@Utils/toast";
import {Group} from "@Models/base/Group";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusForm from "@Components/base/form/PegasusForm.vue";
import type {FormRef} from '@/vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {groupId} = defineProps<{
    groupId: number
}>();

const group = Group.getBySingleId(groupId);

let item = ref(Item.create());
item.value.responsible = group;

const creationSource = ref(InventoryCreationSource.EXISTING);
const comment = ref('');

const form = ref<FormRef>(null);

const finder = ref<InstanceType<typeof IdFinder> | null>(null);
const finder2 = ref<InstanceType<typeof IdFinder> | null>(null);

const sourceValues = dictNumberEnum(InventoryCreationSourceValues).filter(item => item.value !== InventoryCreationSource.SGR);

function saveAndOpen() {
    if (!form.value?.validate()) {
        return;
    }

    let request = item.value.storeByUser(creationSource.value, comment.value, form.value).then(item => {
        redirect({
            name: 'inventory.items.default',
            params: {
                code: item.idAsText,
            },
        });
    });

    toast.loading(request);
}

function saveAndNext() {
    if (!form.value?.validate()) {
        return;
    }

    let request = item.value.storeByUser(creationSource.value, comment.value, form.value).then(() => {
        let location = item.value.location;

        finder.value?.reset();
        finder2.value?.reset();

        item.value = Item.create();
        item.value.responsible = group;
        item.value.location = location;
        comment.value = '';

        if (collapsed.value) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    });

    toast.loading(request);
}

const collapsed = computed(() => {
    // noinspection JSIncompatibleTypesComparison
    if (window.visualViewport === null) {
        return false;
    }

    return window.visualViewport.width < 922;
});
</script>
