<template>
    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel :header="item.idAsText" class="mt-2">
                <TabView>
                    <TabPanel>
                        <template #header>
                            Adatok
                        </template>
                        <Base :item="item" :summary="summary"></Base>
                    </TabPanel>

                    <TabPanel>
                        <template #header>
                            Dokumentumok
                            <PegasusBadge variant="primary">{{ size(item.documents) }}</PegasusBadge>
                        </template>

                        <Documents :item="item"></Documents>
                    </TabPanel>

                    <TabPanel>
                        <template #header>
                            Történet
                            <PegasusBadge variant="primary">{{ size(item.updates) }}</PegasusBadge>
                        </template>

                        <History :item="item"></History>
                    </TabPanel>
                </TabView>
            </PegasusPanel>
        </div>

        <div class="col-lg-6 mt-2">
            <Actions :item="item" :summary="summary"></Actions>
        </div>
    </div>

    <PegasusButton v-if="item.itemGroupId" :params="{group: item.responsible, itemGroup: item.itemGroupId ?? 0}" class="my-2" to="inventory.itemGroups.show" variant="primary">
        Vissza a tételcsoporthoz
    </PegasusButton>

    <PegasusButton v-else :params="{group: item.responsible}" class="my-2" to="inventory.groups.index" variant="primary">
        Vissza a körhöz
    </PegasusButton>
</template>

<title>Eszköz</title>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import History from "@Components/inventory/item/History.vue";
import Actions from "@Components/inventory/item/Actions.vue";
import Base from "@Components/inventory/item/Base.vue";
import Documents from "@Components/inventory/item/Documents.vue";
import {size} from "lodash-es";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusBadge from "@Components/base/PegasusBadge.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {NotFoundError} from "@Utils/errors/NotFoundError";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {ref} from "vue";
import {useAuthStore} from '@/stores/auth';

const {code} = defineProps<{
    code: string
}>();

if (!code.startsWith('SCH')) {
    throw new NotFoundError('Érvénytelen leltárazonosító');
}

useAuthStore().requireUser();

const fetched = await Item.getByCode(parseInt(code.slice(3)));

const item = ref(fetched.item);
const summary = ref(fetched.summary);
</script>
