<template>
    <Panel ref="panel" v-model:collapsed="collapsed" :header="header" :pt="customization" @click="clickHandler">
        <slot name="default"></slot>
        <template #header>
            <slot name="header"></slot>
        </template>
        <template #togglericon>
            <span></span>
        </template>
    </Panel>
</template>

<script lang="ts" setup>
import Panel from "primevue/panel";
import type {Variants} from "@/vue";
import {type ComponentPublicInstance, computed, ref} from "vue";

const {header = '', headerBgVariant = 'light', toggleable = false} = defineProps<{
    header?: string,
    headerBgVariant?: Variants,
    toggleable?: boolean,
}>();

defineSlots<{
    default(props: {}): any,
    header(props: {}): any
}>();

const panel = ref<ComponentPublicInstance | null>(null);

const collapsed = defineModel<boolean>();

const customization = computed(() => {
    return {
        header: {
            class: 'bg-' + headerBgVariant,
        },
        icons: {
            style: {
                display: 'none',
            },
        },
    };
});

function clickHandler(event: any) {
    if (!toggleable) {
        return;
    }
    const inHeader = event.composedPath().some((element: HTMLElement) => element.classList?.contains('p-panel-header'));
    const onButton = event.composedPath().some((element: HTMLElement) => element.classList?.contains('btn'));

    if (inHeader && !onButton) {
        collapsed.value = !collapsed.value;
    }
}
</script>
