/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMembershipResponse } from './BaseMembershipResponse';
    import {
    BaseMembershipResponseFromJSON,
    BaseMembershipResponseFromJSONTyped,
    BaseMembershipResponseToJSON,
    BaseMembershipResponseCreateEmpty,
    } from './BaseMembershipResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseMembershipListResponse {
  memberships: Array<BaseMembershipResponse>;
}

export const BaseMembershipListResponseSchema = {
  'memberships': {
    name: 'memberships',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseMembershipListResponse(value: object): value is BaseMembershipListResponse {
let isInstance = true;
    isInstance = isInstance && "memberships" in value;

return isInstance;
}

export function BaseMembershipListResponseFromJSON(json: any): BaseMembershipListResponse {
return BaseMembershipListResponseFromJSONTyped(json, false);
}

export function BaseMembershipListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseMembershipListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'memberships': ((json['memberships'] as Array<any>).map(BaseMembershipResponseFromJSON)),
    };
  }

  export function BaseMembershipListResponseToJSON(value?: BaseMembershipListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'memberships': ((value.memberships as Array<any>).map(BaseMembershipResponseToJSON)),
    };
  }

  export function BaseMembershipListResponseCreateEmpty(): BaseMembershipListResponse {
  return {
      'memberships': [],
  };
  }

