/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsTrainingStore {
  date: DateTime;
  location: number;
  active: boolean;
}

export const EventsTrainingStoreSchema = {
  'date': {
    name: 'date',
    required: true,
    nullable: false,
    label: 'Időpont',
    type: 'datetime',
  },
  'location': {
    name: 'location',
    required: true,
    nullable: false,
    label: 'Helyszín',
    type: 'number',
      minimum: 0,
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: 'Érvényes',
    type: 'boolean',
  },
}
export function instanceOfEventsTrainingStore(value: object): value is EventsTrainingStore {
let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "active" in value;

return isInstance;
}

export function EventsTrainingStoreFromJSON(json: any): EventsTrainingStore {
return EventsTrainingStoreFromJSONTyped(json, false);
}

export function EventsTrainingStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsTrainingStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'date': (DateTime.fromISO(json['date'])),
          'location': json['location'],
          'active': json['active'],
    };
  }

  export function EventsTrainingStoreToJSON(value?: EventsTrainingStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'date': (value.date.toISO()),
                'location': value.location,
                'active': value.active,
    };
  }

  export function EventsTrainingStoreCreateEmpty(): EventsTrainingStore {
  return {
      'date': DateTime.now(),
      'location': 0,
      'active': false,
  };
  }

