/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { CommonPersonData } from './CommonPersonData';
    import {
    CommonPersonDataFromJSON,
    CommonPersonDataFromJSONTyped,
    CommonPersonDataToJSON,
    CommonPersonDataCreateEmpty,
    } from './CommonPersonData';
import {DateTime} from '@Utils/DateTime';

export interface EventsGuestListData {
  guests: Array<CommonPersonData>;
}

export const EventsGuestListDataSchema = {
  'guests': {
    name: 'guests',
    required: true,
    nullable: false,
    label: 'Vendégek',
      type: 'array',
  },
}
export function instanceOfEventsGuestListData(value: object): value is EventsGuestListData {
let isInstance = true;
    isInstance = isInstance && "guests" in value;

return isInstance;
}

export function EventsGuestListDataFromJSON(json: any): EventsGuestListData {
return EventsGuestListDataFromJSONTyped(json, false);
}

export function EventsGuestListDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsGuestListData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'guests': ((json['guests'] as Array<any>).map(CommonPersonDataFromJSON)),
    };
  }

  export function EventsGuestListDataToJSON(value?: EventsGuestListData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'guests': ((value.guests as Array<any>).map(CommonPersonDataToJSON)),
    };
  }

  export function EventsGuestListDataCreateEmpty(): EventsGuestListData {
  return {
      'guests': [],
  };
  }

