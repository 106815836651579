/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtClaimUpdate {
  objective: string;
  reasoning: string;
}

export const RvtClaimUpdateSchema = {
  'objective': {
    name: 'objective',
    required: true,
    nullable: false,
    label: 'A kérvény célja',
    type: 'string',
      maxLength: 250,
  },
  'reasoning': {
    name: 'reasoning',
    required: true,
    nullable: false,
    label: 'A kérvény indoklása',
    type: 'string',
      maxLength: 5000,
  },
}
export function instanceOfRvtClaimUpdate(value: object): value is RvtClaimUpdate {
let isInstance = true;
    isInstance = isInstance && "objective" in value;
    isInstance = isInstance && "reasoning" in value;

return isInstance;
}

export function RvtClaimUpdateFromJSON(json: any): RvtClaimUpdate {
return RvtClaimUpdateFromJSONTyped(json, false);
}

export function RvtClaimUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtClaimUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'objective': json['objective'],
          'reasoning': json['reasoning'],
    };
  }

  export function RvtClaimUpdateToJSON(value?: RvtClaimUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'objective': value.objective,
                'reasoning': value.reasoning,
    };
  }

  export function RvtClaimUpdateCreateEmpty(): RvtClaimUpdate {
  return {
      'objective': '',
      'reasoning': '',
  };
  }

