import {EventsApi, type EventsClosureTypeSummaryResponse} from "@/api/api";
import {ClosureType} from '@Models/events/ClosureType';
import {Base} from '@Models/Base';

export class ClosureTypeSummary extends Base {
    closureType: ClosureType;

    closuresCount: number;

    static getById(id: number): Promise<ClosureTypeSummary> {
        return EventsApi.closureTypesShow(id).then(response => {
            return ClosureTypeSummary.newSingle(response.data, ClosureTypeSummary.parseResponse);
        });
    }

    static parseResponse(summary: ClosureTypeSummary, data: EventsClosureTypeSummaryResponse): ClosureTypeSummary {
        summary.closureType = ClosureType.newSingle(data.closureType, ClosureType.parseResponse);
        summary.closuresCount = data.closureCount;

        return summary;
    }
}
