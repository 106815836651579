/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseAuthConstantsResponse } from './BaseAuthConstantsResponse';
    import {
    BaseAuthConstantsResponseFromJSON,
    BaseAuthConstantsResponseFromJSONTyped,
    BaseAuthConstantsResponseToJSON,
    BaseAuthConstantsResponseCreateEmpty,
    } from './BaseAuthConstantsResponse';
    import type { BaseCentralConstantsResponse } from './BaseCentralConstantsResponse';
    import {
    BaseCentralConstantsResponseFromJSON,
    BaseCentralConstantsResponseFromJSONTyped,
    BaseCentralConstantsResponseToJSON,
    BaseCentralConstantsResponseCreateEmpty,
    } from './BaseCentralConstantsResponse';
    import type { BaseEventsConstantsResponse } from './BaseEventsConstantsResponse';
    import {
    BaseEventsConstantsResponseFromJSON,
    BaseEventsConstantsResponseFromJSONTyped,
    BaseEventsConstantsResponseToJSON,
    BaseEventsConstantsResponseCreateEmpty,
    } from './BaseEventsConstantsResponse';
    import type { BaseGroupsConstantsResponse } from './BaseGroupsConstantsResponse';
    import {
    BaseGroupsConstantsResponseFromJSON,
    BaseGroupsConstantsResponseFromJSONTyped,
    BaseGroupsConstantsResponseToJSON,
    BaseGroupsConstantsResponseCreateEmpty,
    } from './BaseGroupsConstantsResponse';
    import type { BaseInventoryConstantsResponse } from './BaseInventoryConstantsResponse';
    import {
    BaseInventoryConstantsResponseFromJSON,
    BaseInventoryConstantsResponseFromJSONTyped,
    BaseInventoryConstantsResponseToJSON,
    BaseInventoryConstantsResponseCreateEmpty,
    } from './BaseInventoryConstantsResponse';
    import type { BaseKprConstantsResponse } from './BaseKprConstantsResponse';
    import {
    BaseKprConstantsResponseFromJSON,
    BaseKprConstantsResponseFromJSONTyped,
    BaseKprConstantsResponseToJSON,
    BaseKprConstantsResponseCreateEmpty,
    } from './BaseKprConstantsResponse';
    import type { BaseMoneyConstantsResponse } from './BaseMoneyConstantsResponse';
    import {
    BaseMoneyConstantsResponseFromJSON,
    BaseMoneyConstantsResponseFromJSONTyped,
    BaseMoneyConstantsResponseToJSON,
    BaseMoneyConstantsResponseCreateEmpty,
    } from './BaseMoneyConstantsResponse';
    import type { BaseVikoConstantsResponse } from './BaseVikoConstantsResponse';
    import {
    BaseVikoConstantsResponseFromJSON,
    BaseVikoConstantsResponseFromJSONTyped,
    BaseVikoConstantsResponseToJSON,
    BaseVikoConstantsResponseCreateEmpty,
    } from './BaseVikoConstantsResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseConstantsResponse {
  events: BaseEventsConstantsResponse;
  kpr: BaseKprConstantsResponse;
  viko: BaseVikoConstantsResponse;
  groups: BaseGroupsConstantsResponse;
  auth: BaseAuthConstantsResponse;
  central: BaseCentralConstantsResponse;
  inventory: BaseInventoryConstantsResponse;
  money: BaseMoneyConstantsResponse;
}

export const BaseConstantsResponseSchema = {
  'events': {
    name: 'events',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'kpr': {
    name: 'kpr',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'viko': {
    name: 'viko',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'groups': {
    name: 'groups',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'auth': {
    name: 'auth',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'central': {
    name: 'central',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'inventory': {
    name: 'inventory',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'money': {
    name: 'money',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfBaseConstantsResponse(value: object): value is BaseConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "kpr" in value;
    isInstance = isInstance && "viko" in value;
    isInstance = isInstance && "groups" in value;
    isInstance = isInstance && "auth" in value;
    isInstance = isInstance && "central" in value;
    isInstance = isInstance && "inventory" in value;
    isInstance = isInstance && "money" in value;

return isInstance;
}

export function BaseConstantsResponseFromJSON(json: any): BaseConstantsResponse {
return BaseConstantsResponseFromJSONTyped(json, false);
}

export function BaseConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'events': BaseEventsConstantsResponseFromJSON(json['events']),
              'kpr': BaseKprConstantsResponseFromJSON(json['kpr']),
              'viko': BaseVikoConstantsResponseFromJSON(json['viko']),
              'groups': BaseGroupsConstantsResponseFromJSON(json['groups']),
              'auth': BaseAuthConstantsResponseFromJSON(json['auth']),
              'central': BaseCentralConstantsResponseFromJSON(json['central']),
              'inventory': BaseInventoryConstantsResponseFromJSON(json['inventory']),
              'money': BaseMoneyConstantsResponseFromJSON(json['money']),
    };
  }

  export function BaseConstantsResponseToJSON(value?: BaseConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'events': BaseEventsConstantsResponseToJSON(value.events),
                'kpr': BaseKprConstantsResponseToJSON(value.kpr),
                'viko': BaseVikoConstantsResponseToJSON(value.viko),
                'groups': BaseGroupsConstantsResponseToJSON(value.groups),
                'auth': BaseAuthConstantsResponseToJSON(value.auth),
                'central': BaseCentralConstantsResponseToJSON(value.central),
                'inventory': BaseInventoryConstantsResponseToJSON(value.inventory),
                'money': BaseMoneyConstantsResponseToJSON(value.money),
    };
  }

  export function BaseConstantsResponseCreateEmpty(): BaseConstantsResponse {
  return {
          'events': BaseEventsConstantsResponseCreateEmpty(),
          'kpr': BaseKprConstantsResponseCreateEmpty(),
          'viko': BaseVikoConstantsResponseCreateEmpty(),
          'groups': BaseGroupsConstantsResponseCreateEmpty(),
          'auth': BaseAuthConstantsResponseCreateEmpty(),
          'central': BaseCentralConstantsResponseCreateEmpty(),
          'inventory': BaseInventoryConstantsResponseCreateEmpty(),
          'money': BaseMoneyConstantsResponseCreateEmpty(),
  };
  }

