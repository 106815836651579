<template>
    <PegasusHeading>Mentor adminisztráció</PegasusHeading>

    <div class="row">
        <div class="col-lg-7">
            <h4>Mentorok</h4>

            <PegasusTable :data="mentors">
                <Column body-class="text-nowrap" field="name" header="Név"></Column>

                <Column body-class="text-nowrap" field="phone" header="Telefonszám"></Column>

                <Column body-class="text-nowrap" field="room" header="Lakóhely"></Column>

                <Column header="Műveletek">
                    <template #body="{data}">
                        <PegasusButton size="sm" variant="primary" @click="edit(data)">
                            <i class="fas fa-edit"></i> Szerkesztés
                        </PegasusButton>
                    </template>
                </Column>
            </PegasusTable>

            <span>
                <PegasusButton size="sm" @click="create">
                    <i class="fas fa-edit fa-fw"></i> Új mentor hozzáadása
                </PegasusButton>

                <PegasusButton size="sm" to="mentors.admin.archive">
                    <i class="fas fa-broom fa-fw"></i> Inaktív mentorok
                </PegasusButton>
            </span>
        </div>

        <div class="col-lg-5">
            <h4>Beosztások</h4>

            <PegasusTable :data="months">
                <Column field="date.monthLong" header="Hónap"></Column>

                <Column header="Állapot">
                    <template #body="{data}: {data: AssignmentMonth}">
                        <span v-if="data.canEditAvailability">
                            Megnyitva
                        </span>

                        <span v-else>
                            Lezárva
                        </span>
                    </template>
                </Column>

                <Column header="Műveletek">
                    <template #body="{data}: {data: AssignmentMonth}">
                        <PegasusButton :params="{year: data.year, month: data.month}" size="sm" to="mentors.admin.assignment" variant="primary">
                            <i class="fas fa-edit"></i> Szerkesztés
                        </PegasusButton>

                        <PegasusButton size="sm" variant="primary" @click="exportAssignment(data)">
                            <i class="fas fa-download"></i> Exportálás
                        </PegasusButton>

                        <PegasusButton v-if="data.canEditAvailability" size="sm" variant="danger" @click="canEdit(data, false)">
                            <i class="fas fa-lock"></i> Zárás
                        </PegasusButton>

                        <PegasusButton v-else size="sm" variant="success" @click="canEdit(data, true)">
                            <i class="fas fa-lock-open"></i> Nyitás
                        </PegasusButton>
                    </template>
                </Column>
            </PegasusTable>
        </div>
    </div>
</template>

<title>Mentor adminisztráció</title>

<script lang="ts" setup>
import {Mentor} from "@Models/mentors/Mentor";
import {Assignment} from "@Models/mentors/Assignment";
import {ref} from "vue";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import {Admin} from "@Models/mentors/Admin";
import type {AssignmentMonth} from "@Models/mentors/AssignmentMonth";
import {Dialog} from '@Utils/dialog';
import MentorDataDialog from '@Components/mentors/dialogs/MentorDataDialog.vue';
import MentorCreateDialog from '@Components/mentors/dialogs/MentorCreateDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

useAuthStore().requireUser();

const fetched = await Admin.get();

const mentors = ref(fetched.mentors);

const months = [fetched.previous, fetched.current, fetched.next];

function create() {
    const mentor = new Mentor();

    Dialog.open(MentorCreateDialog, {mentor}).then(result => {
        if (result) {
            mentors.value[mentor.id] = mentor;
        }
    });
}

function edit(mentor: Mentor) {
    Dialog.open(MentorDataDialog, {mentor}).then(result => {
        if (result === false) {
            delete mentors.value[mentor.id];
        }
    });
}

async function canEdit(month: AssignmentMonth, canEdit: boolean) {
    if (!await toast.confirm('Biztos vagy benne hogy ' + (canEdit ? 'megnyitod' : 'lezárod') + ' a jelentkezést a mentoroknak?')) {
        return;
    }

    let request = month.update(canEdit);

    toast.loading(request);
}

function exportAssignment(assignmentMonth: AssignmentMonth) {
    let request = Assignment.exportMonth(assignmentMonth).then(file => file.download());

    toast.loading(request, 'Exportálás');
}
</script>
