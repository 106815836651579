<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div :class="prefix || suffix ? 'p-inputgroup' : ''">
                <span v-if="prefix" class="p-inputgroup-addon">{{ prefix }}</span>

                <InputMask
                    v-if="mask"

                    :id="id"

                    v-model="value"

                    :auto-clear="false"
                    :class="{'p-invalid': isValid === false, 'w-100': !wide, 'w-auto': wide}"
                    :mask="mask"
                    :placeholder="placeholder"
                    :pt="{root: {'data-input-name': schema.name}}"
                    :readonly="disabled"
                    :slot-char="slotChar"
                    unmask
                ></InputMask>

                <InputText
                    v-else

                    :id="id"
                    v-model="value"
                    :class="{'p-invalid': isValid === false, 'w-100': !wide, 'w-auto': wide}"
                    :maxlength="schema.maxLength"
                    :minlength="schema.minLength"
                    :pt="{root: {'data-input-name': schema.name}}"
                    :readonly="disabled"
                    :size="size"
                ></InputText>

                <span v-if="suffix" class="p-inputgroup-addon">{{ suffix }}</span>
            </div>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import SmartField from "@Components/base/form/SmartField.vue";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import {computed} from "vue";

const modelValue = defineModel<string | null>({required: true});

const value = computed({
    get: () => modelValue.value ?? '',
    set: (value: string) => modelValue.value = value,
});

const {
    withoutGroup,
    disabled,
    schema,
    inline,
    size = 'small',
    slotChar,
    prefix,
    suffix,
    placeholder,
    mask,
    wide
} = defineProps<{
    size?: 'small' | 'large';
    mask?: string;
    slotChar?: string;
    prefix?: string;
    suffix?: string;
    placeholder?: string;
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
    wide?: boolean;
}>();
</script>
