<template>
    <PegasusModal header="Forrás tétel kiválasztása">
        <ItemSelector
            v-if="applicationData"
            :application="applicationData"
            only-amendable only-approved
            @selected="add"
        ></ItemSelector>
        <span v-else>
            <i class="fa fa-spin fa-spinner"></i>
        </span>
    </PegasusModal>
</template>

<script lang="ts" setup>
import ItemSelector from '@Components/kpr/ItemSelector.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {Item} from '@Models/money/Item';
import type {Amend} from '@Models/rvt/requests/Amend';
import toast from '@Utils/toast';
import type {Application} from '@Models/rvt/kpr/Application';

const {amend, applicationData} = defineProps<{
    amend: Amend,
    applicationData: Application
}>();

function add(item: Item) {
    const request = amend.addOldItem(item);

    toast.loading(request, 'Mentés');
}
</script>
