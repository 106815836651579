/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupPermissionResponse } from './BaseGroupPermissionResponse';
    import {
    BaseGroupPermissionResponseFromJSON,
    BaseGroupPermissionResponseFromJSONTyped,
    BaseGroupPermissionResponseToJSON,
    BaseGroupPermissionResponseCreateEmpty,
    } from './BaseGroupPermissionResponse';
    import type { BasePermissionResponse } from './BasePermissionResponse';
    import {
    BasePermissionResponseFromJSON,
    BasePermissionResponseFromJSONTyped,
    BasePermissionResponseToJSON,
    BasePermissionResponseCreateEmpty,
    } from './BasePermissionResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseAuthConstantsResponse {
  globalPermissions: Array<BasePermissionResponse>;
  groupPermissions: Array<BaseGroupPermissionResponse>;
  authschRedirectUrl: string;
}

export const BaseAuthConstantsResponseSchema = {
  'globalPermissions': {
    name: 'globalPermissions',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'groupPermissions': {
    name: 'groupPermissions',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'authschRedirectUrl': {
    name: 'authschRedirectUrl',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfBaseAuthConstantsResponse(value: object): value is BaseAuthConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "globalPermissions" in value;
    isInstance = isInstance && "groupPermissions" in value;
    isInstance = isInstance && "authschRedirectUrl" in value;

return isInstance;
}

export function BaseAuthConstantsResponseFromJSON(json: any): BaseAuthConstantsResponse {
return BaseAuthConstantsResponseFromJSONTyped(json, false);
}

export function BaseAuthConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAuthConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'globalPermissions': ((json['globalPermissions'] as Array<any>).map(BasePermissionResponseFromJSON)),
            'groupPermissions': ((json['groupPermissions'] as Array<any>).map(BaseGroupPermissionResponseFromJSON)),
          'authschRedirectUrl': json['authschRedirectUrl'],
    };
  }

  export function BaseAuthConstantsResponseToJSON(value?: BaseAuthConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'globalPermissions': ((value.globalPermissions as Array<any>).map(BasePermissionResponseToJSON)),
              'groupPermissions': ((value.groupPermissions as Array<any>).map(BaseGroupPermissionResponseToJSON)),
                'authschRedirectUrl': value.authschRedirectUrl,
    };
  }

  export function BaseAuthConstantsResponseCreateEmpty(): BaseAuthConstantsResponse {
  return {
      'globalPermissions': [],
      'groupPermissions': [],
      'authschRedirectUrl': '',
  };
  }

