<template>
    <nav class="p-steps p-component p-readonly">
        <ol class="p-steps-list">
            <li
                v-for="(step, index) in steps"
                :key="step.label"
                :class="'p-steps-item ' + (step.value === current ? 'p-highlight p-steps-current' : 'p-disabled')"
                :data-p-disabled="step.value !== current"
                :data-p-highlight="step.value === current"
            >
                <span class="p-menuitem-link">
                    <span class="p-steps-number">
                        <i v-if="index <= currentIndex" class="fa fa-check"></i>
                        <i v-if="index > currentIndex" class="fa fa-clock"></i>
                    </span>
                    <span class="p-steps-title">{{ step.label }}</span>
                </span>
            </li>
        </ol>
    </nav>
</template>

<script generic="T" lang="ts" setup>
import {computed} from 'vue';

const {steps, current} = defineProps<{
    steps: {value: T, label: string}[],
    current: T
}>();

const currentIndex = computed(() => steps.findIndex(item => item.value === current));
</script>
