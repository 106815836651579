/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupResponse {
  id: number;
  name: string;
  shortName: string | null;
  virId: number;
  kprEnabled: boolean;
  vikoEnabled: boolean;
  eventEnabled: boolean;
  inventoryEnabled: boolean;
  active: boolean;
  resortId: number | null;
}

export const BaseGroupResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'shortName': {
    name: 'shortName',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'virId': {
    name: 'virId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'kprEnabled': {
    name: 'kprEnabled',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'vikoEnabled': {
    name: 'vikoEnabled',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'eventEnabled': {
    name: 'eventEnabled',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'inventoryEnabled': {
    name: 'inventoryEnabled',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'resortId': {
    name: 'resortId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfBaseGroupResponse(value: object): value is BaseGroupResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortName" in value;
    isInstance = isInstance && "virId" in value;
    isInstance = isInstance && "kprEnabled" in value;
    isInstance = isInstance && "vikoEnabled" in value;
    isInstance = isInstance && "eventEnabled" in value;
    isInstance = isInstance && "inventoryEnabled" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "resortId" in value;

return isInstance;
}

export function BaseGroupResponseFromJSON(json: any): BaseGroupResponse {
return BaseGroupResponseFromJSONTyped(json, false);
}

export function BaseGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'shortName': json['shortName'],
          'virId': json['virId'],
          'kprEnabled': json['kprEnabled'],
          'vikoEnabled': json['vikoEnabled'],
          'eventEnabled': json['eventEnabled'],
          'inventoryEnabled': json['inventoryEnabled'],
          'active': json['active'],
          'resortId': json['resortId'],
    };
  }

  export function BaseGroupResponseToJSON(value?: BaseGroupResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'shortName': value.shortName,
                'virId': value.virId,
                'kprEnabled': value.kprEnabled,
                'vikoEnabled': value.vikoEnabled,
                'eventEnabled': value.eventEnabled,
                'inventoryEnabled': value.inventoryEnabled,
                'active': value.active,
                'resortId': value.resortId,
    };
  }

  export function BaseGroupResponseCreateEmpty(): BaseGroupResponse {
  return {
      'id': 0,
      'name': '',
      'shortName': '',
      'virId': 0,
      'kprEnabled': false,
      'vikoEnabled': false,
      'eventEnabled': false,
      'inventoryEnabled': false,
      'active': false,
      'resortId': 0,
  };
  }

