import {BaseEntity} from "@Models/BaseEntity";
import {GroupPost} from "@Models/base/GroupPost";
import {ServiceConfig} from "@Models/events/ServiceConfig";
import {useConstantsStore} from "@/stores/constants";
import {every, filter, find, pickBy} from "lodash-es";
import type {BaseGroupResponse, RvtGroupUpdate} from "@/api/api";
import {MainApi} from "@/api/api";

export class Group extends BaseEntity {
    virId: number;

    name: string;

    shortName: string | null;

    resort: number | null;

    kprEnabled: boolean;

    vikoEnabled: boolean;

    eventEnabled: boolean;

    inventoryEnabled: boolean;

    active: boolean;

    static getById(id: number): Promise<Group> {
        return new Promise<Group>(resolve => {
            resolve(useConstantsStore().getGroup(id));
        });
    }

    static getBySingleId(id: number): Group {
        return useConstantsStore().getGroup(id);
    }

    static getByMultipleId(ids: number[]): Record<number, Group> {
        const result: Record<number, Group> = {};

        for (const id in ids) {
            result[ids[id]] = useConstantsStore().getGroup(ids[id]);
        }

        return result;
    }

    static getByResort(resort: Group): Record<number, Group> {
        return pickBy(useConstantsStore().constants.groups.all, group => group.resort === resort.id) as Record<number, Group>;
    }

    static createDummy(name: string): Group {
        const group = new Group();

        group.id = 0;
        group.name = name;
        group.virId = 0;

        return group;
    }

    static parseResponse(group: Group, data: BaseGroupResponse): Group {
        group.id = data.id;
        group.virId = data.virId;
        group.name = data.name;
        group.shortName = data.shortName;
        group.resort = data.resortId;
        group.kprEnabled = data.kprEnabled;
        group.vikoEnabled = data.vikoEnabled;
        group.eventEnabled = data.eventEnabled;
        group.inventoryEnabled = data.inventoryEnabled;
        group.active = data.active;

        return group;
    }

    get resortOrSelf(): number {
        return this.resort ?? this.id;
    }

    get resortText(): string {
        if (this.resort === null) {
            return '';
        }

        if (this.getResort().name === 'SVIE') {
            return '';
        }

        if (this.resort === this.id) {
            return 'RVT';
        }

        return this.getResort().name;
    }

    public getResort(): Group {
        return Group.getBySingleId(this.resortOrSelf);
    }

    public getActiveChildrenGroups(): Record<number, Group> {
        if (!this.isResort()) {
            return {};
        }

        return filter(Group.getByResort(this), group => group.active);
    }

    get optionGroupChildren(): Record<number, Group> {
        return this.getActiveChildrenGroupsWithResort();
    }

    public getActiveChildrenGroupsWithResort(capabilityFilter: Partial<Record<keyof typeof GroupCapabilities, boolean>> = {}): Record<number, Group> {
        if (!this.isResort()) {
            return {};
        }

        const groups = Group.getByResort(this);

        groups[this.id] = this;

        return filter(groups, group => {
            if (!group.active) {
                return false;
            }

            return every(capabilityFilter, (value, capability) => {
                if (value === undefined) {
                    return true;
                }

                return value && group[capability as CapabilityString];
            });
        });
    }

    public update(): Promise<void> {
        const data: RvtGroupUpdate = {
            kprEnabled: this.kprEnabled,
            eventEnabled: this.eventEnabled,
            vikoEnabled: this.vikoEnabled,
            inventoryEnabled: this.inventoryEnabled,
            active: this.active,
        };

        return MainApi.groupsUpdateCapabilities(this.id, data).then();
    }

    public getPosts(): Promise<Record<number, GroupPost>> {
        return MainApi.groupsPosts(this.id).then(response => {
            return GroupPost.newRecords(response.data.posts, GroupPost.parseResponse);
        });
    }

    public isResort(): boolean {
        return this.resort === null;
    }

    public getEventService(): ServiceConfig | null {
        return find(
            useConstantsStore().constants.events.services,
            service => service.groupId === this.id
        ) ?? null;
    }
}

export const GroupCapabilities = {
    kprEnabled: 'KPR',
    vikoEnabled: 'VIKÖ',
    eventEnabled: 'Rendezvények',
    inventoryEnabled: 'Leltár',
    active: 'Aktivitás',
};

type CapabilityString = keyof typeof GroupCapabilities;
