/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupSpecificPermission } from './BaseGroupSpecificPermission';
    import {
    BaseGroupSpecificPermissionFromJSON,
    BaseGroupSpecificPermissionFromJSONTyped,
    BaseGroupSpecificPermissionToJSON,
    BaseGroupSpecificPermissionCreateEmpty,
    } from './BaseGroupSpecificPermission';
    import type { BaseMembershipResponse } from './BaseMembershipResponse';
    import {
    BaseMembershipResponseFromJSON,
    BaseMembershipResponseFromJSONTyped,
    BaseMembershipResponseToJSON,
    BaseMembershipResponseCreateEmpty,
    } from './BaseMembershipResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupAccessResponse {
  groupId: number;
  membership: BaseMembershipResponse | null;
  permissions: Array<BaseGroupSpecificPermission>;
}

export const BaseGroupAccessResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'membership': {
    name: 'membership',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'permissions': {
    name: 'permissions',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseGroupAccessResponse(value: object): value is BaseGroupAccessResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "membership" in value;
    isInstance = isInstance && "permissions" in value;

return isInstance;
}

export function BaseGroupAccessResponseFromJSON(json: any): BaseGroupAccessResponse {
return BaseGroupAccessResponseFromJSONTyped(json, false);
}

export function BaseGroupAccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupAccessResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
              'membership': BaseMembershipResponseFromJSON(json['membership']),
            'permissions': ((json['permissions'] as Array<any>).map(BaseGroupSpecificPermissionFromJSON)),
    };
  }

  export function BaseGroupAccessResponseToJSON(value?: BaseGroupAccessResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
                'membership': BaseMembershipResponseToJSON(value.membership),
              'permissions': ((value.permissions as Array<any>).map(BaseGroupSpecificPermissionToJSON)),
    };
  }

  export function BaseGroupAccessResponseCreateEmpty(): BaseGroupAccessResponse {
  return {
      'groupId': 0,
          'membership': BaseMembershipResponseCreateEmpty(),
      'permissions': [],
  };
  }

