/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseSeverity } from './BaseSeverity';
    import {
    BaseSeverityFromJSON,
    BaseSeverityFromJSONTyped,
    BaseSeverityToJSON,
    BaseSeverityCreateEmpty,
    } from './BaseSeverity';
import {DateTime} from '@Utils/DateTime';
import { BaseSeverityValues } from './BaseSeverity';

export interface InventoryTagResponse {
  id: number;
  identifier: string;
  name: string;
  description: string;
  severity: BaseSeverity;
  system: boolean;
}

export const InventoryTagResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'identifier': {
    name: 'identifier',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'severity': {
    name: 'severity',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Változat",
        enumValues: BaseSeverityValues,
  },
  'system': {
    name: 'system',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfInventoryTagResponse(value: object): value is InventoryTagResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "severity" in value;
    isInstance = isInstance && "system" in value;

return isInstance;
}

export function InventoryTagResponseFromJSON(json: any): InventoryTagResponse {
return InventoryTagResponseFromJSONTyped(json, false);
}

export function InventoryTagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryTagResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'identifier': json['identifier'],
          'name': json['name'],
          'description': json['description'],
              'severity': BaseSeverityFromJSON(json['severity']),
          'system': json['system'],
    };
  }

  export function InventoryTagResponseToJSON(value?: InventoryTagResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'identifier': value.identifier,
                'name': value.name,
                'description': value.description,
                'severity': BaseSeverityToJSON(value.severity),
                'system': value.system,
    };
  }

  export function InventoryTagResponseCreateEmpty(): InventoryTagResponse {
  return {
      'id': 0,
      'identifier': '',
      'name': '',
      'description': '',
          'severity': BaseSeverityCreateEmpty(),
      'system': false,
  };
  }

