<template>
    <PegasusHeading>Költségvetési altétel - {{ spendingItem.name }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Altétel adatai">
                <DataForm :spending-item="spendingItem"></DataForm>

                <PegasusButton variant="success">
                    <i class="fa fa-save"></i> Módosítás
                </PegasusButton>
            </PegasusPanel>

            <PegasusPanel header="Műveletek">
                <template v-if="spendingItem.method === MoneySpendingMethod.CASH">
                    <PegasusButton @click="Dialog.open(CashInDialog, {spendingItem})">
                        <i :class="icons[MoneySpendingEventType.CASH_IN][0]"></i>
                        {{ MoneySpendingEventTypeValues[MoneySpendingEventType.CASH_IN] }}
                    </PegasusButton>

                    <PegasusButton @click="Dialog.open(CashOutDialog, {spendingItem})">
                        <i :class="icons[MoneySpendingEventType.CASH_OUT][0]"></i>
                        {{ MoneySpendingEventTypeValues[MoneySpendingEventType.CASH_OUT] }}
                    </PegasusButton>
                </template>

                <template v-if="spendingItem.method === MoneySpendingMethod.TRANSFER">
                    <PegasusButton
                        v-if="!spendingItem.isTransferRequested()"
                        @click="Dialog.open(TransferRequestDialog, {spendingItem})"
                    >
                        <i :class="icons[MoneySpendingEventType.TRANSFER_REQUESTED][0]"></i>
                        Utalási kérelem
                    </PegasusButton>

                    <PegasusButton
                        v-if="spendingItem.isTransferRequested() && !spendingItem.isTransferRegistered()"
                        variant="success"
                        @click="Dialog.open(TransferRegisteredDialog, {spendingItem})"
                    >
                        <i :class="icons[MoneySpendingEventType.TRANSFER_REGISTERED][0]"></i>
                        Utalás rögzítve
                    </PegasusButton>

                    <PegasusButton
                        variant="success"
                        @click="Dialog.open(TransferSentDialog, {spendingItem})"
                    >
                        <i :class="icons[MoneySpendingEventType.TRANSFER_SENT][0]"></i>
                        Utalás megtörtént
                    </PegasusButton>
                </template>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <History :spending-item="spendingItem"></History>
        </div>
    </div>

    <PegasusButton :params="{item: spendingItem.itemId}" to="money.spending.item" variant="primary">
        <i class="fa fa-step-backward"></i> Vissza a költségvetési tételhez
    </PegasusButton>
</template>

<title>Költségvetési tétel</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {SpendingItem} from '@Models/money/SpendingItem';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {MoneySpendingEventType, MoneySpendingEventTypeValues, MoneySpendingMethod} from '@/api/models';
import History from '@Components/money/spendingItem/History.vue';
import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {Dialog} from '@Utils/dialog';
import CashInDialog from '@Components/money/spendingItem/dialogs/CashInDialog.vue';
import {ref} from 'vue';
import CashOutDialog from '@Components/money/spendingItem/dialogs/CashOutDialog.vue';
import DataForm from '@Components/money/spendingItem/DataForm.vue';
import TransferRequestDialog from '@Components/money/spendingItem/dialogs/TransferRequestDialog.vue';
import TransferRegisteredDialog from '@Components/money/spendingItem/dialogs/TransferRegisteredDialog.vue';
import TransferSentDialog from '@Components/money/spendingItem/dialogs/TransferSentDialog.vue';

const {spendingItemId} = defineProps<{
    itemId: number,
    spendingItemId: number
}>();

useAuthStore().requireUser();

const spendingItem = ref(await SpendingItem.getById(spendingItemId));

const icons = SpendingItemEvent.getIcons();
</script>
