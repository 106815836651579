<template>
    <PegasusHeading>Schönherz Gazdasági rendszer</PegasusHeading>

    <h4>Körök amiknek a gazdaságához van hozzáférésed:</h4>
    <PegasusButton v-for="group in user.moneyGroups()" :key="group.id" :params="{group}" to="money.group.index">
        {{ group.name.limit(30) }}
    </PegasusButton>
</template>

<title>Schönherz Gazdasági Rendszer</title>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const user = useAuthStore().requireUser();
</script>
