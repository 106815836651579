import {Base} from "@Models/Base";
import {map} from "lodash-es";

export abstract class BaseEntity extends Base {
    id: number;

    static entitiesToArray(array: Record<number, BaseEntity>): number[] {
        return map(array, entity => entity.id);
    }
}
