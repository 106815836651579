<template>
    <PegasusHeading>Rendszer admin</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Actions">
                <PegasusButton variant="warning" @click="redisClear">
                    <i class="fa fa-trash-can"></i> Redis clear
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Jobs">
                <PegasusButton variant="success" @click="job('events:services:processor')">
                    <i class="fa fa-procedures"></i> Event services processor
                </PegasusButton>

                <PegasusButton variant="success" @click="job('email:processor')">
                    <i class="fa fa-mail-bulk"></i> E-mail processor
                </PegasusButton>

                <PegasusButton variant="success" @click="job('email:status')">
                    <i class="fa fa-spinner"></i> E-mail status
                </PegasusButton>
            </PegasusPanel>
        </div>
    </div>
    <PegasusPanel header="Pages">
        <PegasusButton variant="primary" to="rvt.admin.system.emails">
            <i class="fa fa-mail-forward"></i> E-mails
        </PegasusButton>
    </PegasusPanel>
</template>

<title>Rendszer admin</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {useAuthStore} from '@/stores/auth';
import {BaseGlobalPermission} from '@/api/models';
import {AccessDeniedError} from '@Utils/errors/AccessDeniedError';
import toast from '@Utils/toast';
import {MainApi} from '@/api/apis';

const user = useAuthStore().requireUser();

if (!user.value.hasPermission(BaseGlobalPermission.ADMIN_ACL)) {
    throw new AccessDeniedError();
}

async function redisClear() {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    const request = MainApi.adminRedisClear();

    toast.loading(request, 'Művelet');
}

async function job(job: string) {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    const request = MainApi.adminExecuteJob(job);

    toast.loading(request, 'Job');
}
</script>
