/* tslint:disable */
/* eslint-disable */

export enum MoneyBudgetProgramStatus {
    EDITING = 'EDITING',
    FINALIZED = 'FINALIZED',
    APPROVED = 'APPROVED',
    REVIEWING = 'REVIEWING',
    ARCHIVED = 'ARCHIVED'
}

export const MoneyBudgetProgramStatusValues: Record<MoneyBudgetProgramStatus, string> = {
    'EDITING': 'Szerkesztés alatt',
    'FINALIZED': 'Véglegesítve, elfogdásra vár',
    'APPROVED': 'Elfogadva, költhető',
    'REVIEWING': 'Beszámolásra vár',
    'ARCHIVED': 'Archív'
}


export function MoneyBudgetProgramStatusFromJSON(json: any): MoneyBudgetProgramStatus {
    return MoneyBudgetProgramStatusFromJSONTyped(json, false);
}

export function MoneyBudgetProgramStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramStatus {
    return json as MoneyBudgetProgramStatus;
}

export function MoneyBudgetProgramStatusToJSON(value?: MoneyBudgetProgramStatus | null): any {
    return value as any;
}

export function MoneyBudgetProgramStatusCreateEmpty(): MoneyBudgetProgramStatus {
    return MoneyBudgetProgramStatus.EDITING;
}

