/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface CommonPersonData {
  name: string;
  id: string;
  card: boolean;
}

export const CommonPersonDataSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Teljes név',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'Személyi igazolvány',
    type: 'string',
      minLength: 6,
      maxLength: 10,
  },
  'card': {
    name: 'card',
    required: true,
    nullable: false,
    label: 'Rendelkezik kártyával?',
    type: 'boolean',
  },
}
export function instanceOfCommonPersonData(value: object): value is CommonPersonData {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "card" in value;

return isInstance;
}

export function CommonPersonDataFromJSON(json: any): CommonPersonData {
return CommonPersonDataFromJSONTyped(json, false);
}

export function CommonPersonDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonPersonData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'id': json['id'],
          'card': json['card'],
    };
  }

  export function CommonPersonDataToJSON(value?: CommonPersonData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'id': value.id,
                'card': value.card,
    };
  }

  export function CommonPersonDataCreateEmpty(): CommonPersonData {
  return {
      'name': '',
      'id': '',
      'card': false,
  };
  }

