/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsMentorResponse } from './MentorsMentorResponse';
    import {
    MentorsMentorResponseFromJSON,
    MentorsMentorResponseFromJSONTyped,
    MentorsMentorResponseToJSON,
    MentorsMentorResponseCreateEmpty,
    } from './MentorsMentorResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAssignmentResponse {
  id: number;
  mentor: MentorsMentorResponse | null;
  date: DateTime;
  from: DateTime;
  to: DateTime;
}

export const MentorsAssignmentResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'mentor': {
    name: 'mentor',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'date': {
    name: 'date',
    required: true,
    nullable: false,
    label: '',
    type: 'date',
  },
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfMentorsAssignmentResponse(value: object): value is MentorsAssignmentResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "mentor" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;

return isInstance;
}

export function MentorsAssignmentResponseFromJSON(json: any): MentorsAssignmentResponse {
return MentorsAssignmentResponseFromJSONTyped(json, false);
}

export function MentorsAssignmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAssignmentResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'mentor': MentorsMentorResponseFromJSON(json['mentor']),
        'date': (DateTime.fromISO(json['date'])),
        'from': (DateTime.fromISO(json['from'])),
        'to': (DateTime.fromISO(json['to'])),
    };
  }

  export function MentorsAssignmentResponseToJSON(value?: MentorsAssignmentResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'mentor': MentorsMentorResponseToJSON(value.mentor),
            'date': (value.date.toISODate()),
            'from': (value.from.toISO()),
            'to': (value.to.toISO()),
    };
  }

  export function MentorsAssignmentResponseCreateEmpty(): MentorsAssignmentResponse {
  return {
      'id': 0,
          'mentor': MentorsMentorResponseCreateEmpty(),
      'date': DateTime.now(),
      'from': DateTime.now(),
      'to': DateTime.now(),
  };
  }

