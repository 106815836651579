import {User} from "@Models/base/User";
import {Group} from "@Models/base/Group";
import {ItemGroup} from "@Models/money/ItemGroup";
import {BaseRequest} from "@Models/rvt/requests/BaseRequest";
import {MoneyApi, RvtApi, type RvtClaimResponse, type RvtClaimStore, type RvtClaimUpdate} from "@/api/api";
import type {FormRef} from '@/vue';

export class Claim extends BaseRequest {
    objective: string | null;

    reasoning: string | null;

    itemGroup: ItemGroup;

    static getById(id: number): Promise<Claim> {
        return RvtApi.claimsShow(id).then(response => {
            return Claim.newSingle(response.data, this.parseResponse);
        });
    }

    static getWaitingInPeriod(spendingPeriodId: number): Promise<Record<number, Claim>> {
        return MoneyApi.spendingPeriodsWaitingClaims(spendingPeriodId).then(response => {
            return Claim.newRecords(response.data.claims, this.parseResponse);
        });
    }

    static getAllInPeriod(spendingPeriodId: number): Promise<Record<number, Claim>> {
        return MoneyApi.spendingPeriodsClaims(spendingPeriodId).then(response => {
            return Claim.newRecords(response.data.claims, this.parseResponse);
        });
    }

    static create(group: Group): Claim {
        const claim = new Claim();

        claim.group = group;

        return claim;
    }

    static parseResponse(claim: Claim, data: RvtClaimResponse) {
        claim.id = data.id;
        claim.requester = User.newSingle(data.requester, User.parseMinimalResponse);
        claim.requesterPost = data.requesterPost;
        claim.objective = data.objective;
        claim.reasoning = data.reasoning;
        claim.finalized = data.finalized;
        claim.approved = data.approved;
        claim.decision = data.decision;
        claim.decisionTime = data.decisionTime;
        claim.itemGroup = ItemGroup.newSingle(data.itemGroup, ItemGroup.parseWithItems);
        claim.group = Group.getBySingleId(data.groupId);
        claim.createdAt = data.createdAt;
        claim.canEdit = data.canEdit;
        claim.canFinalize = data.canFinalize;
        claim.canApprove = data.canApprove;
        claim.canDecide = data.canDecide;

        return claim;
    }

    get sum(): number {
        return this.itemGroup.calculateSum();
    }

    public store(periodId: number): Promise<Claim> {
        const data: RvtClaimStore = {
            spendingPeriod: periodId,
            group: this.group.id,
        };

        return RvtApi.claimsStore(data).then(response => {
            return Claim.parseResponse(this, response.data);
        });
    }

    public save(form: FormRef): Promise<void> {
        const data: RvtClaimUpdate = {
            objective: this.objective ?? '',
            reasoning: this.reasoning ?? '',
        };

        return RvtApi.claimsUpdate(this.id, data, {form}).then(response => {
            Claim.parseResponse(this, response.data);
        });
    }

    public finalize(): Promise<void> {
        return RvtApi.claimsFinalize(this.id).then(response => {
            Claim.parseResponse(this, response.data);
        });
    }

    public approve(result: boolean): Promise<void> {
        return RvtApi.claimsApprove(this.id, {
            result: result,
        }).then(response => {
            Claim.parseResponse(this, response.data);
        });
    }

    public decide(result: boolean): Promise<void> {
        return RvtApi.claimsDecide(this.id, {
            decision: result,
        }).then(response => {
            Claim.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return RvtApi.claimsDestroy(this.id).then();
    }
}
