<template>
    <form ref="form" :class="validationErrors.length > 0 ? 'invalid-form' : ''">
        <slot></slot>
    </form>

    <small v-for="(error, key) in validationErrors" :key="key" class="p-error text-nowrap">
        {{ error }}
    </small>
</template>

<script lang="ts" setup>
import {getCurrentInstance, isVNode, ref, type VNode, watch} from "vue";
import SmartField from "@Components/base/form/SmartField.vue";
import type {ComponentInstance} from "@/vue";
import {forEach, isArray} from "lodash-es";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {BaseValidationBagResponse} from '@/api/models';
import {BaseValidationBagResponseCreateEmpty} from '@/api/models';

const {visible = true} = defineProps<{
    part?: string,
    visible?: boolean
}>();

defineOptions({
    name: 'PegasusForm'
});

watch(() => visible, () => update());

const validationErrors = ref<string[]>([]);

const form = ref<HTMLFormElement | null>(null);

const instance = (() => {
    const instance = getCurrentInstance();

    if (instance === null) {
        throw new Error('No instance');
    }

    return instance;
})();

function validate() {
    return form.value?.reportValidity();
}

type Result = Record<string, ComponentInstance<typeof SmartField>[]>;

function update() {
    if (form.value === null) {
        return;
    }

    forEach(getAllInputChildren([instance.subTree]), fields => {
        fields.forEach(field => field.update());
    });

    forEach(getFormChildren([instance.subTree]), form => {
        form.update();
    });
}

function getAllInputChildren(nodes: any[], result: Result = {}): Result {
    for (const node of nodes) {
        if (!isVNode(node)) {
            continue;
        }

        const component = node.component;
        if (component) {
            if (component.type.name === 'PegasusForm') {
                continue;
            }

            if (component.type.name === 'SmartField') {
                const field = component.exposed as ComponentInstance<typeof SmartField>;
                const schema = field.getSchema();

                if (schema.type === 'static') {
                    continue;
                }

                result[schema.name] ??= [];
                result[schema.name].push(field);
            } else {
                getAllInputChildren([component.subTree], result);
            }
        } else if (isArray(node.children)) {
            getAllInputChildren(node.children, result);
        }
    }

    return result;
}

function getFormChildren(nodes: VNode[]): Record<string, ComponentInstance<typeof PegasusForm>> {
    let result: Record<string, ComponentInstance<typeof PegasusForm>> = {};

    for (const node of nodes) {
        if (!isVNode(node)) {
            continue;
        }
        const component = node.component;
        if (component) {
            if (component.type.name === 'PegasusForm') {
                result[component.props.part as string] = component.exposed as ComponentInstance<typeof PegasusForm>;
            } else if (component.subTree) {
                forEach(getFormChildren([component.subTree]), (child, key) => result[key] = child);
            }
        } else if (Array.isArray(node.children)) {
            forEach(getFormChildren(node.children as VNode[]), (child, key) => result[key] = child);
        }
    }

    return result;
}

function handle(errorBag: BaseValidationBagResponse) {
    if (form.value === null) {
        throw new Error('Handle before mount');
    }

    const fieldsMapping = getAllInputChildren([instance.subTree]);
    const formsMapping = getFormChildren([instance.subTree]);

    validationErrors.value = errorBag.errors;

    forEach(formsMapping, (form, name) => {
        const errors = errorBag.fields[name] ?? BaseValidationBagResponseCreateEmpty();

        form.handle(errors);
    });

    forEach(fieldsMapping, (instances, name) => {
        if (isArray(errorBag.fields)) {
            forEach(instances, (field, id) => {
                field.handleValidation(errorBag.fields[id].fields[name] ?? BaseValidationBagResponseCreateEmpty());
            });
        } else {
            forEach(instances, field => {
                field.handleValidation(errorBag.fields[name] ?? BaseValidationBagResponseCreateEmpty());
            });
        }
    });

    return;
}

defineExpose({
    validate,
    handle,
    update,
});
</script>
