import {BaseEntity} from "@Models/BaseEntity";
import {CentralApi, EventsApi, type EventsClosureTypeResponse, type EventsUpdateClosureType} from "@/api/api";
import type {FormRef} from '@/vue';

export class ClosureType extends BaseEntity {
    name: string;

    description: string;

    price: number;

    public: boolean;

    polygon: object;

    affectedFloors: number[];

    affectedItems: number[];

    disrupts: boolean;

    static getAll(): Promise<Record<number, ClosureType>> {
        return EventsApi.closureTypesIndex().then(response => {
            return ClosureType.newRecords(response.data.types, ClosureType.parseResponse);
        });
    }

    static parseResponse(type: ClosureType, data: EventsClosureTypeResponse): ClosureType {
        type.id = data.id;
        type.name = data.name;
        type.description = data.description;
        type.price = data.price;
        type.public = data.isPublic;
        type.disrupts = data.disrupts;

        return type;
    }

    public update(form: FormRef): Promise<void> {
        const data: EventsUpdateClosureType = {
            name: this.name,
            description: this.description,
            price: this.price,
            isPublic: this.public
        };

        return EventsApi.closureTypesUpdate(this.id, data, {form}).then(response => {
            ClosureType.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return EventsApi.closureTypesDelete(this.id).then();
    }

    public getMap(): Promise<string[]> {
        return CentralApi.mapClosureType(this.id).then(response => response.data.maps);
    }

    get displayName(): string {
        return this.name + ' (' + this.price.huf() + ' / óra)';
    }
}
