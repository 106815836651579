/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoReportResponse } from './VikoReportResponse';
    import {
    VikoReportResponseFromJSON,
    VikoReportResponseFromJSONTyped,
    VikoReportResponseToJSON,
    VikoReportResponseCreateEmpty,
    } from './VikoReportResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoReportListResponse {
  reports: Array<VikoReportResponse>;
}

export const VikoReportListResponseSchema = {
  'reports': {
    name: 'reports',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfVikoReportListResponse(value: object): value is VikoReportListResponse {
let isInstance = true;
    isInstance = isInstance && "reports" in value;

return isInstance;
}

export function VikoReportListResponseFromJSON(json: any): VikoReportListResponse {
return VikoReportListResponseFromJSONTyped(json, false);
}

export function VikoReportListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReportListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'reports': ((json['reports'] as Array<any>).map(VikoReportResponseFromJSON)),
    };
  }

  export function VikoReportListResponseToJSON(value?: VikoReportListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'reports': ((value.reports as Array<any>).map(VikoReportResponseToJSON)),
    };
  }

  export function VikoReportListResponseCreateEmpty(): VikoReportListResponse {
  return {
      'reports': [],
  };
  }

