/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsServiceDecide {
  decision: boolean;
}

export const EventsServiceDecideSchema = {
  'decision': {
    name: 'decision',
    required: true,
    nullable: false,
    label: 'Döntés',
    type: 'boolean',
  },
}
export function instanceOfEventsServiceDecide(value: object): value is EventsServiceDecide {
let isInstance = true;
    isInstance = isInstance && "decision" in value;

return isInstance;
}

export function EventsServiceDecideFromJSON(json: any): EventsServiceDecide {
return EventsServiceDecideFromJSONTyped(json, false);
}

export function EventsServiceDecideFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceDecide {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'decision': json['decision'],
    };
  }

  export function EventsServiceDecideToJSON(value?: EventsServiceDecide | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'decision': value.decision,
    };
  }

  export function EventsServiceDecideCreateEmpty(): EventsServiceDecide {
  return {
      'decision': false,
  };
  }

