/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupSummaryInventoryResponse } from './BaseGroupSummaryInventoryResponse';
    import {
    BaseGroupSummaryInventoryResponseFromJSON,
    BaseGroupSummaryInventoryResponseFromJSONTyped,
    BaseGroupSummaryInventoryResponseToJSON,
    BaseGroupSummaryInventoryResponseCreateEmpty,
    } from './BaseGroupSummaryInventoryResponse';
    import type { InventoryItemResponse } from './InventoryItemResponse';
    import {
    InventoryItemResponseFromJSON,
    InventoryItemResponseFromJSONTyped,
    InventoryItemResponseToJSON,
    InventoryItemResponseCreateEmpty,
    } from './InventoryItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryItemWithSummaryResponse {
  item: InventoryItemResponse;
  groupSummary: BaseGroupSummaryInventoryResponse;
}

export const InventoryItemWithSummaryResponseSchema = {
  'item': {
    name: 'item',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'groupSummary': {
    name: 'groupSummary',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfInventoryItemWithSummaryResponse(value: object): value is InventoryItemWithSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "item" in value;
    isInstance = isInstance && "groupSummary" in value;

return isInstance;
}

export function InventoryItemWithSummaryResponseFromJSON(json: any): InventoryItemWithSummaryResponse {
return InventoryItemWithSummaryResponseFromJSONTyped(json, false);
}

export function InventoryItemWithSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemWithSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'item': InventoryItemResponseFromJSON(json['item']),
              'groupSummary': BaseGroupSummaryInventoryResponseFromJSON(json['groupSummary']),
    };
  }

  export function InventoryItemWithSummaryResponseToJSON(value?: InventoryItemWithSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'item': InventoryItemResponseToJSON(value.item),
                'groupSummary': BaseGroupSummaryInventoryResponseToJSON(value.groupSummary),
    };
  }

  export function InventoryItemWithSummaryResponseCreateEmpty(): InventoryItemWithSummaryResponse {
  return {
          'item': InventoryItemResponseCreateEmpty(),
          'groupSummary': BaseGroupSummaryInventoryResponseCreateEmpty(),
  };
  }

