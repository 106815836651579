/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupSummaryEventsResponse } from './BaseGroupSummaryEventsResponse';
    import {
    BaseGroupSummaryEventsResponseFromJSON,
    BaseGroupSummaryEventsResponseFromJSONTyped,
    BaseGroupSummaryEventsResponseToJSON,
    BaseGroupSummaryEventsResponseCreateEmpty,
    } from './BaseGroupSummaryEventsResponse';
    import type { BaseGroupSummaryInventoryResponse } from './BaseGroupSummaryInventoryResponse';
    import {
    BaseGroupSummaryInventoryResponseFromJSON,
    BaseGroupSummaryInventoryResponseFromJSONTyped,
    BaseGroupSummaryInventoryResponseToJSON,
    BaseGroupSummaryInventoryResponseCreateEmpty,
    } from './BaseGroupSummaryInventoryResponse';
    import type { BaseGroupSummaryPeriodResponse } from './BaseGroupSummaryPeriodResponse';
    import {
    BaseGroupSummaryPeriodResponseFromJSON,
    BaseGroupSummaryPeriodResponseFromJSONTyped,
    BaseGroupSummaryPeriodResponseToJSON,
    BaseGroupSummaryPeriodResponseCreateEmpty,
    } from './BaseGroupSummaryPeriodResponse';
    import type { BaseGroupSummaryVikoResponse } from './BaseGroupSummaryVikoResponse';
    import {
    BaseGroupSummaryVikoResponseFromJSON,
    BaseGroupSummaryVikoResponseFromJSONTyped,
    BaseGroupSummaryVikoResponseToJSON,
    BaseGroupSummaryVikoResponseCreateEmpty,
    } from './BaseGroupSummaryVikoResponse';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupSummaryResponse {
  groupId: number;
  periods: Array<BaseGroupSummaryPeriodResponse>;
  viko: BaseGroupSummaryVikoResponse | null;
  inventory: BaseGroupSummaryInventoryResponse | null;
  events: BaseGroupSummaryEventsResponse | null;
  leader: BaseMinimalUserResponse | null;
  money: BaseMinimalUserResponse | null;
}

export const BaseGroupSummaryResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'periods': {
    name: 'periods',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'viko': {
    name: 'viko',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'inventory': {
    name: 'inventory',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'events': {
    name: 'events',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'leader': {
    name: 'leader',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'money': {
    name: 'money',
    required: true,
    nullable: true,
      type: 'embedded',
  },
}
export function instanceOfBaseGroupSummaryResponse(value: object): value is BaseGroupSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "periods" in value;
    isInstance = isInstance && "viko" in value;
    isInstance = isInstance && "inventory" in value;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "leader" in value;
    isInstance = isInstance && "money" in value;

return isInstance;
}

export function BaseGroupSummaryResponseFromJSON(json: any): BaseGroupSummaryResponse {
return BaseGroupSummaryResponseFromJSONTyped(json, false);
}

export function BaseGroupSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
            'periods': ((json['periods'] as Array<any>).map(BaseGroupSummaryPeriodResponseFromJSON)),
              'viko': BaseGroupSummaryVikoResponseFromJSON(json['viko']),
              'inventory': BaseGroupSummaryInventoryResponseFromJSON(json['inventory']),
              'events': BaseGroupSummaryEventsResponseFromJSON(json['events']),
              'leader': BaseMinimalUserResponseFromJSON(json['leader']),
              'money': BaseMinimalUserResponseFromJSON(json['money']),
    };
  }

  export function BaseGroupSummaryResponseToJSON(value?: BaseGroupSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
              'periods': ((value.periods as Array<any>).map(BaseGroupSummaryPeriodResponseToJSON)),
                'viko': BaseGroupSummaryVikoResponseToJSON(value.viko),
                'inventory': BaseGroupSummaryInventoryResponseToJSON(value.inventory),
                'events': BaseGroupSummaryEventsResponseToJSON(value.events),
                'leader': BaseMinimalUserResponseToJSON(value.leader),
                'money': BaseMinimalUserResponseToJSON(value.money),
    };
  }

  export function BaseGroupSummaryResponseCreateEmpty(): BaseGroupSummaryResponse {
  return {
      'groupId': 0,
      'periods': [],
          'viko': BaseGroupSummaryVikoResponseCreateEmpty(),
          'inventory': BaseGroupSummaryInventoryResponseCreateEmpty(),
          'events': BaseGroupSummaryEventsResponseCreateEmpty(),
          'leader': BaseMinimalUserResponseCreateEmpty(),
          'money': BaseMinimalUserResponseCreateEmpty(),
  };
  }

