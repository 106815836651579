/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetProgramResponse } from './MoneyBudgetProgramResponse';
    import {
    MoneyBudgetProgramResponseFromJSON,
    MoneyBudgetProgramResponseFromJSONTyped,
    MoneyBudgetProgramResponseToJSON,
    MoneyBudgetProgramResponseCreateEmpty,
    } from './MoneyBudgetProgramResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetProgramListResponse {
  programs: Array<MoneyBudgetProgramResponse>;
}

export const MoneyBudgetProgramListResponseSchema = {
  'programs': {
    name: 'programs',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfMoneyBudgetProgramListResponse(value: object): value is MoneyBudgetProgramListResponse {
let isInstance = true;
    isInstance = isInstance && "programs" in value;

return isInstance;
}

export function MoneyBudgetProgramListResponseFromJSON(json: any): MoneyBudgetProgramListResponse {
return MoneyBudgetProgramListResponseFromJSONTyped(json, false);
}

export function MoneyBudgetProgramListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'programs': ((json['programs'] as Array<any>).map(MoneyBudgetProgramResponseFromJSON)),
    };
  }

  export function MoneyBudgetProgramListResponseToJSON(value?: MoneyBudgetProgramListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'programs': ((value.programs as Array<any>).map(MoneyBudgetProgramResponseToJSON)),
    };
  }

  export function MoneyBudgetProgramListResponseCreateEmpty(): MoneyBudgetProgramListResponse {
  return {
      'programs': [],
  };
  }

