/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseCentralConstantsResponse {
  schBuildingId: number;
}

export const BaseCentralConstantsResponseSchema = {
  'schBuildingId': {
    name: 'schBuildingId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfBaseCentralConstantsResponse(value: object): value is BaseCentralConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "schBuildingId" in value;

return isInstance;
}

export function BaseCentralConstantsResponseFromJSON(json: any): BaseCentralConstantsResponse {
return BaseCentralConstantsResponseFromJSONTyped(json, false);
}

export function BaseCentralConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseCentralConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'schBuildingId': json['schBuildingId'],
    };
  }

  export function BaseCentralConstantsResponseToJSON(value?: BaseCentralConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'schBuildingId': value.schBuildingId,
    };
  }

  export function BaseCentralConstantsResponseCreateEmpty(): BaseCentralConstantsResponse {
  return {
      'schBuildingId': 0,
  };
  }

