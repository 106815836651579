/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupPostResponse } from './BaseGroupPostResponse';
    import {
    BaseGroupPostResponseFromJSON,
    BaseGroupPostResponseFromJSONTyped,
    BaseGroupPostResponseToJSON,
    BaseGroupPostResponseCreateEmpty,
    } from './BaseGroupPostResponse';
    import type { BaseMembershipStatus } from './BaseMembershipStatus';
    import {
    BaseMembershipStatusFromJSON,
    BaseMembershipStatusFromJSONTyped,
    BaseMembershipStatusToJSON,
    BaseMembershipStatusCreateEmpty,
    } from './BaseMembershipStatus';
import {DateTime} from '@Utils/DateTime';
import { BaseMembershipStatusValues } from './BaseMembershipStatus';

export interface BaseMembershipResponse {
  id: number;
  groupId: number;
  status: BaseMembershipStatus;
  posts: Array<BaseGroupPostResponse>;
}

export const BaseMembershipResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: BaseMembershipStatusValues,
  },
  'posts': {
    name: 'posts',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseMembershipResponse(value: object): value is BaseMembershipResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "posts" in value;

return isInstance;
}

export function BaseMembershipResponseFromJSON(json: any): BaseMembershipResponse {
return BaseMembershipResponseFromJSONTyped(json, false);
}

export function BaseMembershipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseMembershipResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'groupId': json['groupId'],
              'status': BaseMembershipStatusFromJSON(json['status']),
            'posts': ((json['posts'] as Array<any>).map(BaseGroupPostResponseFromJSON)),
    };
  }

  export function BaseMembershipResponseToJSON(value?: BaseMembershipResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'groupId': value.groupId,
                'status': BaseMembershipStatusToJSON(value.status),
              'posts': ((value.posts as Array<any>).map(BaseGroupPostResponseToJSON)),
    };
  }

  export function BaseMembershipResponseCreateEmpty(): BaseMembershipResponse {
  return {
      'id': 0,
      'groupId': 0,
          'status': BaseMembershipStatusCreateEmpty(),
      'posts': [],
  };
  }

