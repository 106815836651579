/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseJobOutputResponse {
  exitCode: number;
  output: string;
}

export const BaseJobOutputResponseSchema = {
  'exitCode': {
    name: 'exitCode',
    required: true,
    nullable: false,
    label: 'Exit code',
    type: 'number',
      minimum: 0,
  },
  'output': {
    name: 'output',
    required: true,
    nullable: false,
    label: 'Output',
    type: 'string',
  },
}
export function instanceOfBaseJobOutputResponse(value: object): value is BaseJobOutputResponse {
let isInstance = true;
    isInstance = isInstance && "exitCode" in value;
    isInstance = isInstance && "output" in value;

return isInstance;
}

export function BaseJobOutputResponseFromJSON(json: any): BaseJobOutputResponse {
return BaseJobOutputResponseFromJSONTyped(json, false);
}

export function BaseJobOutputResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseJobOutputResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'exitCode': json['exitCode'],
          'output': json['output'],
    };
  }

  export function BaseJobOutputResponseToJSON(value?: BaseJobOutputResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'exitCode': value.exitCode,
                'output': value.output,
    };
  }

  export function BaseJobOutputResponseCreateEmpty(): BaseJobOutputResponse {
  return {
      'exitCode': 0,
      'output': '',
  };
  }

