/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupPostResponse } from './BaseGroupPostResponse';
    import {
    BaseGroupPostResponseFromJSON,
    BaseGroupPostResponseFromJSONTyped,
    BaseGroupPostResponseToJSON,
    BaseGroupPostResponseCreateEmpty,
    } from './BaseGroupPostResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupPostListResponse {
  posts: Array<BaseGroupPostResponse>;
}

export const BaseGroupPostListResponseSchema = {
  'posts': {
    name: 'posts',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseGroupPostListResponse(value: object): value is BaseGroupPostListResponse {
let isInstance = true;
    isInstance = isInstance && "posts" in value;

return isInstance;
}

export function BaseGroupPostListResponseFromJSON(json: any): BaseGroupPostListResponse {
return BaseGroupPostListResponseFromJSONTyped(json, false);
}

export function BaseGroupPostListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupPostListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'posts': ((json['posts'] as Array<any>).map(BaseGroupPostResponseFromJSON)),
    };
  }

  export function BaseGroupPostListResponseToJSON(value?: BaseGroupPostListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'posts': ((value.posts as Array<any>).map(BaseGroupPostResponseToJSON)),
    };
  }

  export function BaseGroupPostListResponseCreateEmpty(): BaseGroupPostListResponse {
  return {
      'posts': [],
  };
  }

