/* tslint:disable */
/* eslint-disable */

export enum InventoryQuality {
    UNUSABLE = 1,
    SIGNIFICANTLY_DAMAGED = 2,
    MINOR_DAMAGE = 3,
    AESTHETIC_DAMAGE = 4,
    OKAY = 5,
    PERFECT = 6
}

export const InventoryQualityValues: Record<InventoryQuality, string> = {
    1: 'Működésképtelen',
    2: 'Jelentős hibával rendelkezik',
    3: 'Kisebb hibákkal rendelkezik',
    4: 'Esztétikai hibás',
    5: 'Megfelelő',
    6: 'Kíváló'
}


export function InventoryQualityFromJSON(json: any): InventoryQuality {
    return InventoryQualityFromJSONTyped(json, false);
}

export function InventoryQualityFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryQuality {
    return json as InventoryQuality;
}

export function InventoryQualityToJSON(value?: InventoryQuality | null): any {
    return value as any;
}

export function InventoryQualityCreateEmpty(): InventoryQuality {
    return InventoryQuality.UNUSABLE;
}

