/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { MoneyItemGroupWithItemsResponse } from './MoneyItemGroupWithItemsResponse';
    import {
    MoneyItemGroupWithItemsResponseFromJSON,
    MoneyItemGroupWithItemsResponseFromJSONTyped,
    MoneyItemGroupWithItemsResponseToJSON,
    MoneyItemGroupWithItemsResponseCreateEmpty,
    } from './MoneyItemGroupWithItemsResponse';
    import type { MoneyMinimalItemResponse } from './MoneyMinimalItemResponse';
    import {
    MoneyMinimalItemResponseFromJSON,
    MoneyMinimalItemResponseFromJSONTyped,
    MoneyMinimalItemResponseToJSON,
    MoneyMinimalItemResponseCreateEmpty,
    } from './MoneyMinimalItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtAmendResponse {
  groupId: number;
  applicationId: number;
  objective: string | null;
  reasoning: string | null;
  oldItems: Array<MoneyMinimalItemResponse>;
  itemGroup: MoneyItemGroupWithItemsResponse;
  balance: number;
  id: number;
  requester: BaseMinimalUserResponse;
  requesterPost: string;
  finalized: DateTime | null;
  approved: DateTime | null;
  decision: boolean | null;
  decisionTime: DateTime | null;
  createdAt: DateTime;
  canEdit: boolean;
  canFinalize: boolean;
  canApprove: boolean;
  canDecide: boolean;
}

export const RvtAmendResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'applicationId': {
    name: 'applicationId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'objective': {
    name: 'objective',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'reasoning': {
    name: 'reasoning',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'oldItems': {
    name: 'oldItems',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'itemGroup': {
    name: 'itemGroup',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'balance': {
    name: 'balance',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'requester': {
    name: 'requester',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'requesterPost': {
    name: 'requesterPost',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'finalized': {
    name: 'finalized',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'approved': {
    name: 'approved',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'decision': {
    name: 'decision',
    required: true,
    nullable: true,
    label: '',
    type: 'boolean',
  },
  'decisionTime': {
    name: 'decisionTime',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canFinalize': {
    name: 'canFinalize',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canApprove': {
    name: 'canApprove',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canDecide': {
    name: 'canDecide',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfRvtAmendResponse(value: object): value is RvtAmendResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "applicationId" in value;
    isInstance = isInstance && "objective" in value;
    isInstance = isInstance && "reasoning" in value;
    isInstance = isInstance && "oldItems" in value;
    isInstance = isInstance && "itemGroup" in value;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "requester" in value;
    isInstance = isInstance && "requesterPost" in value;
    isInstance = isInstance && "finalized" in value;
    isInstance = isInstance && "approved" in value;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "decisionTime" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "canEdit" in value;
    isInstance = isInstance && "canFinalize" in value;
    isInstance = isInstance && "canApprove" in value;
    isInstance = isInstance && "canDecide" in value;

return isInstance;
}

export function RvtAmendResponseFromJSON(json: any): RvtAmendResponse {
return RvtAmendResponseFromJSONTyped(json, false);
}

export function RvtAmendResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtAmendResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
          'applicationId': json['applicationId'],
          'objective': json['objective'],
          'reasoning': json['reasoning'],
            'oldItems': ((json['oldItems'] as Array<any>).map(MoneyMinimalItemResponseFromJSON)),
              'itemGroup': MoneyItemGroupWithItemsResponseFromJSON(json['itemGroup']),
          'balance': json['balance'],
          'id': json['id'],
              'requester': BaseMinimalUserResponseFromJSON(json['requester']),
          'requesterPost': json['requesterPost'],
        'finalized': (json['finalized'] === null ? null : DateTime.fromISO(json['finalized'])),
        'approved': (json['approved'] === null ? null : DateTime.fromISO(json['approved'])),
          'decision': json['decision'],
        'decisionTime': (json['decisionTime'] === null ? null : DateTime.fromISO(json['decisionTime'])),
        'createdAt': (DateTime.fromISO(json['createdAt'])),
          'canEdit': json['canEdit'],
          'canFinalize': json['canFinalize'],
          'canApprove': json['canApprove'],
          'canDecide': json['canDecide'],
    };
  }

  export function RvtAmendResponseToJSON(value?: RvtAmendResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
                'applicationId': value.applicationId,
                'objective': value.objective,
                'reasoning': value.reasoning,
              'oldItems': ((value.oldItems as Array<any>).map(MoneyMinimalItemResponseToJSON)),
                'itemGroup': MoneyItemGroupWithItemsResponseToJSON(value.itemGroup),
                'balance': value.balance,
                'id': value.id,
                'requester': BaseMinimalUserResponseToJSON(value.requester),
                'requesterPost': value.requesterPost,
            'finalized': (
              value.finalized === null ? null : value.finalized.toISO()),
            'approved': (
              value.approved === null ? null : value.approved.toISO()),
                'decision': value.decision,
            'decisionTime': (
              value.decisionTime === null ? null : value.decisionTime.toISO()),
            'createdAt': (value.createdAt.toISO()),
                'canEdit': value.canEdit,
                'canFinalize': value.canFinalize,
                'canApprove': value.canApprove,
                'canDecide': value.canDecide,
    };
  }

  export function RvtAmendResponseCreateEmpty(): RvtAmendResponse {
  return {
      'groupId': 0,
      'applicationId': 0,
      'objective': '',
      'reasoning': '',
      'oldItems': [],
          'itemGroup': MoneyItemGroupWithItemsResponseCreateEmpty(),
      'balance': 0,
      'id': 0,
          'requester': BaseMinimalUserResponseCreateEmpty(),
      'requesterPost': '',
      'finalized': DateTime.now(),
      'approved': DateTime.now(),
      'decision': false,
      'decisionTime': DateTime.now(),
      'createdAt': DateTime.now(),
      'canEdit': false,
      'canFinalize': false,
      'canApprove': false,
      'canDecide': false,
  };
  }

