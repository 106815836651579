import {Base} from "@Models/Base";
import {Manager} from "@Models/events/Manager";
import type {EventsManagerSummaryResponse} from "@/api/api";
import {EventsApi} from "@/api/api";

export class ManagerSummary extends Base {
    managers: Record<number, Manager>;

    currentManager: Manager;

    leaderManager: Manager;

    static get(): Promise<ManagerSummary> {
        return EventsApi.managersSummary().then(response => {
            return ManagerSummary.newSingle(response.data, ManagerSummary.parseResponse);
        });
    }

    static parseResponse(summary: ManagerSummary, data: EventsManagerSummaryResponse): ManagerSummary {
        summary.managers = Manager.newRecords(data.managers, Manager.parseResponse);
        summary.currentManager = summary.managers[data.currentId];
        summary.leaderManager = summary.managers[data.leaderId];

        return summary;
    }
}
