<template>
    <PegasusModal header="Sérülés bejegyzés">
        <PegasusForm ref="form">
            <PegasusForm part="data">
                <SelectField
                    v-model="damage.quality"
                    :options="dictNumberEnum(InventoryQualityValues)"
                    :schema="InventoryUpdateDamageSchema.quality"
                    label="Sérülés utáni állapot"
                ></SelectField>

                <TextareaField
                    v-model="damage.description"
                    :schema="InventoryUpdateDamageSchema.description"
                ></TextareaField>
            </PegasusForm>

            <TextareaField
                v-model="damage.comment"
                :schema="InventoryItemUpdateSchema.comment"
            ></TextareaField>

            <PegasusButton type="submit" variant="primary" @click="save">
                Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {Damage} from "@Models/inventory/updates/Damage";
import {InventoryItemUpdateSchema, InventoryQualityValues, InventoryUpdateDamageSchema,} from "@/api/models";
import toast from "@Utils/toast";
import {ref} from "vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import {dictNumberEnum} from "@Models/Utils";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';

const {item} = defineProps<{
    item: Item
}>();

const damage = ref(new Damage());

const form = ref<FormRef>(null);

const returnValue = useReturn();

function save() {
    let request = damage.value.store(item.id, form.value).then(() => {
        item.updates.push(damage.value);

        returnValue(true);
    });

    toast.loading(request);
}
</script>
