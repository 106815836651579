/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryOwnerResponse } from './InventoryOwnerResponse';
    import {
    InventoryOwnerResponseFromJSON,
    InventoryOwnerResponseFromJSONTyped,
    InventoryOwnerResponseToJSON,
    InventoryOwnerResponseCreateEmpty,
    } from './InventoryOwnerResponse';
    import type { InventoryTagResponse } from './InventoryTagResponse';
    import {
    InventoryTagResponseFromJSON,
    InventoryTagResponseFromJSONTyped,
    InventoryTagResponseToJSON,
    InventoryTagResponseCreateEmpty,
    } from './InventoryTagResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseInventoryConstantsResponse {
  owners: Array<InventoryOwnerResponse>;
  defaultOwner: number;
  tags: Array<InventoryTagResponse>;
}

export const BaseInventoryConstantsResponseSchema = {
  'owners': {
    name: 'owners',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'defaultOwner': {
    name: 'defaultOwner',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'tags': {
    name: 'tags',
    required: true,
    nullable: false,
    label: 'Cimkék',
      type: 'array',
  },
}
export function instanceOfBaseInventoryConstantsResponse(value: object): value is BaseInventoryConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "owners" in value;
    isInstance = isInstance && "defaultOwner" in value;
    isInstance = isInstance && "tags" in value;

return isInstance;
}

export function BaseInventoryConstantsResponseFromJSON(json: any): BaseInventoryConstantsResponse {
return BaseInventoryConstantsResponseFromJSONTyped(json, false);
}

export function BaseInventoryConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseInventoryConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'owners': ((json['owners'] as Array<any>).map(InventoryOwnerResponseFromJSON)),
          'defaultOwner': json['defaultOwner'],
            'tags': ((json['tags'] as Array<any>).map(InventoryTagResponseFromJSON)),
    };
  }

  export function BaseInventoryConstantsResponseToJSON(value?: BaseInventoryConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'owners': ((value.owners as Array<any>).map(InventoryOwnerResponseToJSON)),
                'defaultOwner': value.defaultOwner,
              'tags': ((value.tags as Array<any>).map(InventoryTagResponseToJSON)),
    };
  }

  export function BaseInventoryConstantsResponseCreateEmpty(): BaseInventoryConstantsResponse {
  return {
      'owners': [],
      'defaultOwner': 0,
      'tags': [],
  };
  }

