/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryApplicationResponse } from './KprCategoryApplicationResponse';
    import {
    KprCategoryApplicationResponseFromJSON,
    KprCategoryApplicationResponseFromJSONTyped,
    KprCategoryApplicationResponseToJSON,
    KprCategoryApplicationResponseCreateEmpty,
    } from './KprCategoryApplicationResponse';
    import type { RvtAmendResponse } from './RvtAmendResponse';
    import {
    RvtAmendResponseFromJSON,
    RvtAmendResponseFromJSONTyped,
    RvtAmendResponseToJSON,
    RvtAmendResponseCreateEmpty,
    } from './RvtAmendResponse';
import {DateTime} from '@Utils/DateTime';

export interface KprApplicationWithItemsResponse {
  id: number;
  groupId: number;
  statement: string;
  number: number;
  categories: Array<KprCategoryApplicationResponse>;
  amends: Array<RvtAmendResponse>;
  canEditStatement: boolean;
  tenderId: number;
}

export const KprApplicationWithItemsResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'statement': {
    name: 'statement',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'categories': {
    name: 'categories',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'amends': {
    name: 'amends',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'canEditStatement': {
    name: 'canEditStatement',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'tenderId': {
    name: 'tenderId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfKprApplicationWithItemsResponse(value: object): value is KprApplicationWithItemsResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "statement" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "amends" in value;
    isInstance = isInstance && "canEditStatement" in value;
    isInstance = isInstance && "tenderId" in value;

return isInstance;
}

export function KprApplicationWithItemsResponseFromJSON(json: any): KprApplicationWithItemsResponse {
return KprApplicationWithItemsResponseFromJSONTyped(json, false);
}

export function KprApplicationWithItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprApplicationWithItemsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'groupId': json['groupId'],
          'statement': json['statement'],
          'number': json['number'],
            'categories': ((json['categories'] as Array<any>).map(KprCategoryApplicationResponseFromJSON)),
            'amends': ((json['amends'] as Array<any>).map(RvtAmendResponseFromJSON)),
          'canEditStatement': json['canEditStatement'],
          'tenderId': json['tenderId'],
    };
  }

  export function KprApplicationWithItemsResponseToJSON(value?: KprApplicationWithItemsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'groupId': value.groupId,
                'statement': value.statement,
                'number': value.number,
              'categories': ((value.categories as Array<any>).map(KprCategoryApplicationResponseToJSON)),
              'amends': ((value.amends as Array<any>).map(RvtAmendResponseToJSON)),
                'canEditStatement': value.canEditStatement,
                'tenderId': value.tenderId,
    };
  }

  export function KprApplicationWithItemsResponseCreateEmpty(): KprApplicationWithItemsResponse {
  return {
      'id': 0,
      'groupId': 0,
      'statement': '',
      'number': 0,
      'categories': [],
      'amends': [],
      'canEditStatement': false,
      'tenderId': 0,
  };
  }

