/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface AuthAuthschLastSync {
  bme: DateTime | null;
  schacc: DateTime | null;
  vir: DateTime | null;
}

export const AuthAuthschLastSyncSchema = {
  'bme': {
    name: 'bme',
    required: true,
    nullable: true,
    label: 'BME címtár frissítés',
    type: 'datetime',
  },
  'schacc': {
    name: 'schacc',
    required: true,
    nullable: true,
    label: 'SCH Account frissítés',
    type: 'datetime',
  },
  'vir': {
    name: 'vir',
    required: true,
    nullable: true,
    label: 'PéK frissítés',
    type: 'datetime',
  },
}
export function instanceOfAuthAuthschLastSync(value: object): value is AuthAuthschLastSync {
let isInstance = true;
    isInstance = isInstance && "bme" in value;
    isInstance = isInstance && "schacc" in value;
    isInstance = isInstance && "vir" in value;

return isInstance;
}

export function AuthAuthschLastSyncFromJSON(json: any): AuthAuthschLastSync {
return AuthAuthschLastSyncFromJSONTyped(json, false);
}

export function AuthAuthschLastSyncFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthAuthschLastSync {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'bme': (json['bme'] === null ? null : DateTime.fromISO(json['bme'])),
        'schacc': (json['schacc'] === null ? null : DateTime.fromISO(json['schacc'])),
        'vir': (json['vir'] === null ? null : DateTime.fromISO(json['vir'])),
    };
  }

  export function AuthAuthschLastSyncToJSON(value?: AuthAuthschLastSync | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'bme': (
              value.bme === null ? null : value.bme.toISO()),
            'schacc': (
              value.schacc === null ? null : value.schacc.toISO()),
            'vir': (
              value.vir === null ? null : value.vir.toISO()),
    };
  }

  export function AuthAuthschLastSyncCreateEmpty(): AuthAuthschLastSync {
  return {
      'bme': DateTime.now(),
      'schacc': DateTime.now(),
      'vir': DateTime.now(),
  };
  }

