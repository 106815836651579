<template>
    <PegasusHeading>VIKÖ bírálási statisztika</PegasusHeading>

    <PegasusTable :data="statistics.reviewers">
        <Column field="user.name" header="Bíráló"></Column>
        <Column field="reportsReviewed" header="Elbírált pályázatok"></Column>
        <Column header="Elbírált pályázatok aránya">
            <template #body="{data}: {data: ReviewerStatistics}">
                <ProgressBar :value="data.reportsReviewed / statistics.applicationsCount * 100" show-value></ProgressBar>
            </template>
        </Column>
    </PegasusTable>

    <PegasusButton :params="{tender: statistics.tender}" to="viko.admin.tender" variant="primary">
        <i class="fas fa-fast-backward"></i> Vissza
    </PegasusButton>
</template>

<title>VIKÖ problémás pályázatok</title>

<script lang="ts" setup>
import {ref} from "vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {useAuthStore} from '@/stores/auth';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {TenderStatistics} from '@Models/viko/statistics/TenderStatistics';
import ProgressBar from 'primevue/progressbar';
import type {ReviewerStatistics} from '@Models/viko/statistics/ReviewerStatistics';

const {tenderId} = defineProps<{
    tenderId: number
}>();

useAuthStore().requireUser();

const statistics = ref(await TenderStatistics.getByTender(tenderId));
</script>
