<template>
    <PegasusHeading>Nagyteremosztó {{ summary.period.displayName }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Információ">
                <dl>
                    <dt>Felelős</dt>
                    <dd>{{ summary.period.responsible.name }} ({{ summary.period.responsible.email }})</dd>
                    <dt>Leadott foglalások száma</dt>
                    <dd>{{ summary.reservationCount }}</dd>
                    <dt>Műveletek</dt>
                    <dd>
                        <PegasusButton @click="exportReservations">
                            <i class="fa fa-download"></i> Foglalások exportálása
                        </PegasusButton>
                    </dd>
                </dl>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Beállítások">
                <PegasusForm ref="form">
                    <DateField
                        v-model="summary.period.start"
                        :schema="EventsReservationPeriodUpdateSchema.start"
                        inline
                    ></DateField>

                    <DateField
                        v-model="summary.period.finish"
                        :schema="EventsReservationPeriodUpdateSchema.finish"
                        inline
                    ></DateField>

                    <hr>

                    <SwitchField
                        v-model="summary.period.editable"
                        :schema="EventsReservationPeriodUpdateSchema.editable"
                        inline
                    ></SwitchField>

                    <SwitchField
                        v-model="summary.period.active"
                        :schema="EventsReservationPeriodUpdateSchema.active"
                        inline
                    ></SwitchField>

                    <PegasusButton variant="success" @click="save">
                        <i class="fa fa-save"></i> Mentés
                    </PegasusButton>
                </PegasusForm>
            </PegasusPanel>
        </div>
    </div>

    <PegasusButton to="events.planning.index">
        <i class="fa fa-backward-step"></i> Vissza a nagyteremosztóhoz
    </PegasusButton>
</template>

<title>Nagyteremosztó szerkesztés</title>

<script lang="ts" setup>
import {ref} from "vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ReservationPeriodSummary} from '@Models/events/ReservationPeriodSummary';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import DateField from '@Components/base/form/types/DateField.vue';
import {EventsReservationPeriodUpdateSchema} from '@/api/models';
import SwitchField from '@Components/base/form/types/SwitchField.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';

const {periodId} = defineProps<{
    periodId: number
}>();

const form = ref<FormRef>(null);

useAuthStore().requireUser();

const summary = ref(await ReservationPeriodSummary.getById(periodId));

function exportReservations() {
    const request = summary.value.period.exportReservations().then(file => file.download());

    toast.loading(request, 'Exportálás');
}

async function save() {
    if (!await toast.confirm('Biztosan módosítani szeretnéd az időszakot?')) {
        return;
    }

    const request = summary.value.period.update(form.value);

    toast.loading(request);
}
</script>
