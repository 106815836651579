import type {RouteRecordRaw} from "vue-router";
import {getNumberParam} from "@Models/Utils";

const viko: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'viko.index',
        component: () => import('@Pages/viko/Index.vue'),
    },
    {
        path: '/application/:application',
        name: 'viko.application',
        component: () => import('@Pages/viko/Application.vue'),
        props: route => ({
            applicationId: getNumberParam(route, 'application'),
        }),
    },
    {
        path: '/leader/:group',
        name: 'viko.leader',
        component: () => import('@Pages/viko/Leader.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/reviewer/:tender',
        name: 'viko.reviewer.index',
        component: () => import('@Pages/viko/reviewer/Index.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/reviewer/:tender/individual',
        name: 'viko.reviewer.individual',
        component: () => import('@Pages/viko/reviewer/Individual.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/reviewer/:tender/calc',
        name: 'viko.reviewer.calc',
        component: () => import('@Pages/viko/reviewer/Calc.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/reviewer/:tender/summary',
        name: 'viko.reviewer.summary',
        component: () => import('@Pages/viko/reviewer/Summary.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/reviewer/:tender/summary/:group',
        name: 'viko.reviewer.groupSummary',
        component: () => import('@Pages/viko/reviewer/Summary.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/admin',
        name: 'viko.admin.index',
        component: () => import('@Pages/viko/admin/Index.vue'),
    },
    {
        path: '/admin/:tender',
        name: 'viko.admin.tender',
        component: () => import('@Pages/viko/admin/Tender.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/admin/:tender/ignore',
        name: 'viko.admin.ignore',
        component: () => import('@Pages/viko/admin/IgnoreFromCalc.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/admin/:tender/problems',
        name: 'viko.admin.problems',
        component: () => import('@Pages/viko/admin/ProblemApplications.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/admin/:tender/statistics',
        name: 'viko.admin.statistics',
        component: () => import('@Pages/viko/admin/Statistics.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
];

export default viko;
