import {BaseEntity} from "@Models/BaseEntity";
import {Category} from "@Models/rvt/kpr/Category";
import {SpendingPeriod} from "@Models/money/SpendingPeriod";
import {File} from "@Models/File";
import type {KprTenderResponse, KprTenderUpdate, KprUpdateRules} from "@/api/api";
import {KprApi} from "@/api/api";
import {groupBy, join, map, some} from "lodash-es";
import {useConstantsStore} from '@/stores/constants';

export class Tender extends BaseEntity {
    spendingPeriodId: number;

    categories: Record<number, Category>;

    amendable: boolean;

    static getById(id: number): Tender {
        return useConstantsStore().getTender(id);
    }

    static parseResponse(tender: Tender, data: KprTenderResponse): Tender {
        tender.id = data.id;
        tender.spendingPeriodId = data.spendingPeriodId;
        tender.categories = Category.newRecords(data.categories, Category.parseResponse);
        tender.amendable = data.amendable;

        return tender;
    }

    public update(): Promise<void> {
        const data: KprTenderUpdate = {
            categories: map(this.categories, category => {
                return {
                    category: category.id,
                    status: category.status,
                    endDrafting: category.endDrafting,
                    budget: category.budget,
                };
            }),
        };

        return KprApi.tenderUpdate(this.id, data).then(response => {
            Tender.parseResponse(this, response.data);
        });
    }

    public sgrExport(): Promise<File> {
        return KprApi.tenderExportSgr(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public uploadRules(file: File): Promise<void> {
        const data: KprUpdateRules = {
            rules: file.getAsDomFile(),
        };

        return KprApi.tenderEditRules(this.id, data).then();
    }

    get spendingPeriod(): SpendingPeriod {
        return SpendingPeriod.getBySingleId(this.spendingPeriodId);
    }

    get displayName(): string {
        return 'KPR ' + this.spendingPeriod.from.year;
    }

    get status(): string {
        const categoriesByStatus = groupBy(this.categories, category => category.statusText);

        return join(
            map(categoriesByStatus, (categories, status) => status + ' (' + categories.map(category => category.shortName.toUpperCase()).join(', ') + ')'),
            ', '
        );
    }

    public hasDrafting(): boolean {
        return some(this.categories, (category: Category) => category.isInDrafting());
    }
}
