/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { RvtSpendingPeriodResponse } from './RvtSpendingPeriodResponse';
    import {
    RvtSpendingPeriodResponseFromJSON,
    RvtSpendingPeriodResponseFromJSONTyped,
    RvtSpendingPeriodResponseToJSON,
    RvtSpendingPeriodResponseCreateEmpty,
    } from './RvtSpendingPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtSpendingPeriodsResponse {
  periods: Array<RvtSpendingPeriodResponse>;
}

export const RvtSpendingPeriodsResponseSchema = {
  'periods': {
    name: 'periods',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfRvtSpendingPeriodsResponse(value: object): value is RvtSpendingPeriodsResponse {
let isInstance = true;
    isInstance = isInstance && "periods" in value;

return isInstance;
}

export function RvtSpendingPeriodsResponseFromJSON(json: any): RvtSpendingPeriodsResponse {
return RvtSpendingPeriodsResponseFromJSONTyped(json, false);
}

export function RvtSpendingPeriodsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtSpendingPeriodsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'periods': ((json['periods'] as Array<any>).map(RvtSpendingPeriodResponseFromJSON)),
    };
  }

  export function RvtSpendingPeriodsResponseToJSON(value?: RvtSpendingPeriodsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'periods': ((value.periods as Array<any>).map(RvtSpendingPeriodResponseToJSON)),
    };
  }

  export function RvtSpendingPeriodsResponseCreateEmpty(): RvtSpendingPeriodsResponse {
  return {
      'periods': [],
  };
  }

