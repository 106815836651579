/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoApplicationIgnoreUpdate {
  ignore: boolean;
}

export const VikoApplicationIgnoreUpdateSchema = {
  'ignore': {
    name: 'ignore',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfVikoApplicationIgnoreUpdate(value: object): value is VikoApplicationIgnoreUpdate {
let isInstance = true;
    isInstance = isInstance && "ignore" in value;

return isInstance;
}

export function VikoApplicationIgnoreUpdateFromJSON(json: any): VikoApplicationIgnoreUpdate {
return VikoApplicationIgnoreUpdateFromJSONTyped(json, false);
}

export function VikoApplicationIgnoreUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoApplicationIgnoreUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'ignore': json['ignore'],
    };
  }

  export function VikoApplicationIgnoreUpdateToJSON(value?: VikoApplicationIgnoreUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'ignore': value.ignore,
    };
  }

  export function VikoApplicationIgnoreUpdateCreateEmpty(): VikoApplicationIgnoreUpdate {
  return {
      'ignore': false,
  };
  }

