/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseValidationBagResponse {
  errors: Array<string>;
  fields: { [key: string]: BaseValidationBagResponse; };
}

export const BaseValidationBagResponseSchema = {
  'errors': {
    name: 'errors',
    required: true,
    nullable: false,
    label: 'Hibák',
    type: 'array',
  },
  'fields': {
    name: 'fields',
    required: true,
    nullable: false,
    label: 'Mezők hibái',
  },
}
export function instanceOfBaseValidationBagResponse(value: object): value is BaseValidationBagResponse {
let isInstance = true;
    isInstance = isInstance && "errors" in value;
    isInstance = isInstance && "fields" in value;

return isInstance;
}

export function BaseValidationBagResponseFromJSON(json: any): BaseValidationBagResponse {
return BaseValidationBagResponseFromJSONTyped(json, false);
}

export function BaseValidationBagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseValidationBagResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'errors': json['errors'],
          'fields': (mapValues(json['fields'], BaseValidationBagResponseFromJSON)),
    };
  }

  export function BaseValidationBagResponseToJSON(value?: BaseValidationBagResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'errors': value.errors,
            'fields': (mapValues(value.fields, BaseValidationBagResponseToJSON)),
    };
  }

  export function BaseValidationBagResponseCreateEmpty(): BaseValidationBagResponse {
  return {
      'errors': [],
      'fields': {},
  };
  }

