import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {type MoneySpendingEventInvoiceUploaded, type MoneySpendingItemEventResponse} from '@/api/models';

export class InvoiceUploaded extends SpendingItemEvent {
    invoiceId: number;

    static parseResponse(cashOut: InvoiceUploaded, data: MoneySpendingItemEventResponse): InvoiceUploaded {
        SpendingItemEvent.parseResponse(cashOut, data);

        const specific = data.data as MoneySpendingEventInvoiceUploaded;
        cashOut.invoiceId = specific.invoiceId;

        return cashOut;
    }

    get text(): {key: string, value: string}[] {
        return [
            {key: 'Számla azonosító', value: this.invoiceId.toString()},
        ];
    }
}
