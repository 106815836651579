import {Base} from "@Models/Base";
import {Owner} from "@Models/inventory/Owner";
import type {InventoryOwnerSummaryResponse} from "@/api/api";
import {InventoryApi} from "@/api/api";
import {useConstantsStore} from "@/stores/constants";

export class OwnerSummary extends Base {
    owner: Owner;

    itemCount: number;

    priceTotal: number;

    static getById(ownerId: number): Promise<OwnerSummary> {
        return InventoryApi.ownersSummary(ownerId).then(response => {
            return OwnerSummary.newSingle(response.data, OwnerSummary.parseResponse);
        });
    }

    static parseResponse(summary: OwnerSummary, data: InventoryOwnerSummaryResponse): OwnerSummary {
        summary.owner = useConstantsStore().getOwner(data.ownerId);
        summary.itemCount = data.itemCount;
        summary.priceTotal = data.priceTotal;

        return summary;
    }
}
