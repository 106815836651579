<template>
    <div class="row">
        <div class="col-lg-7">
            <PegasusForm ref="form">
                <ClaimsBase v-model:dirty="dirty" :claim="claim"></ClaimsBase>
            </PegasusForm>

            <ItemGroup
                ref="itemGroupRef"
                :group="claim.itemGroup"
                :permissions="{canEdit: claim.canEdit, canDecide: false, canFinalize: false, canConfidential: false}"
                dummy="Tételek"
            ></ItemGroup>
        </div>

        <div class="col-lg-5">
            <Status :request="claim"></Status>

            <PegasusPanel class="mt-3" header="Műveletek">
                <PegasusButton v-if="claim.canEdit" variant="success" @click="save">
                    Mentés
                </PegasusButton>
                <PegasusButton v-if="claim.canFinalize && !claim.finalized" variant="warning" @click="finalize">
                    Leadás!
                </PegasusButton>
                <PegasusButton v-if="claim.canFinalize" variant="danger" @click="deleteClaim">
                    Kérvény törlése
                </PegasusButton>

                <template v-if="claim.canApprove && !claim.approved && claim.finalized">
                    <PegasusButton variant="success" @click="approve(true)">
                        Jóváhagyás
                    </PegasusButton>

                    <PegasusButton variant="danger" @click="approve(false)">
                        Visszadobás szerkesztésre
                    </PegasusButton>
                </template>

                <template v-if="claim.canDecide && claim.approved && !claim.decisionTime">
                    <PegasusButton variant="success" @click="decide(true)">
                        Kérvény támogatva
                    </PegasusButton>

                    <PegasusButton variant="danger" @click="decide(false)">
                        Kérvény elutasítva
                    </PegasusButton>
                </template>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>RVT Támogatási kérvény</title>

<script lang="ts" setup>
import Status from "@Components/rvt/requests/Status.vue";
import ItemGroup from "@Components/money/ItemGroup.vue";
import {Claim} from "@Models/rvt/requests/Claim";
import ClaimsBase from "@Components/rvt/requests/claims/ClaimsBase.vue";
import {ref} from "vue";
import toast from "@Utils/toast";
import {useRouter} from "vue-router";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {usePreventExit} from "@Utils/composables";
import {useAuthStore} from "@/stores/auth";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';

const {claimId} = defineProps<{
    claimId: number
}>();

useAuthStore().requireUser();

const dirty = ref(false);
const claim = ref(await Claim.getById(claimId));

usePreventExit(() => dirty.value);

const itemGroupRef = ref<InstanceType<typeof ItemGroup> | null>(null);

const form = ref<FormRef>(null);

async function save() {
    await itemGroupRef.value?.saveAll();

    let request = claim.value.save(form.value)
        .then(() => dirty.value = false);

    toast.loading(request);
}

async function finalize() {
    if (!await toast.confirm('Biztosan leakarod adni a kérvényt?')) {
        return;
    }

    let request = claim.value.save(form.value)
        .then(() => dirty.value = false)
        .then(() => claim.value.finalize());

    toast.loading(request, 'Leadás');
}

async function deleteClaim() {
    if (!await toast.confirm('Biztos vagy benne? A művelet visszavonhatatlan!!!')) {
        return;
    }

    let request = claim.value.delete().then(() => {
        useRouter().push({
            name: 'rvt.groups.money',
            params: {
                group: '' + claim.value.group.id,
            },
        });
    });

    toast.loading(request, 'Törlés');
}

async function approve(result: boolean) {
    if (result) {
        if (!await toast.confirm('Ezzel a keretigény beküldésre kerül az RVT elé, biztos vagy benne?')) {
            return;
        }
    } else {
        if (!await toast.confirm('Biztos vagy benne? Mindenképpen mondd el a leadónak, hogy miért dobtad vissza!')) {
            return;
        }
    }

    let request = claim.value.approve(result);

    toast.loading(request, 'Döntés mentése');
}

async function decide(result: boolean) {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    let request = claim.value.decide(result).then(() => {
        dirty.value = false;
    });

    toast.loading(request, 'Döntés mentése');
}
</script>
