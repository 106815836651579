import type {
    MentorsAssignmentMonthExtendedResponse,
    MentorsAssignmentMonthResponse,
    MentorsAssignmentMonthUpdate,
    MentorsAssignmentUpdate
} from "@/api/models";
import {BaseEntity} from "@Models/BaseEntity";
import {DateTime} from "@Utils/DateTime";
import {map, range} from "lodash-es";
import {MentorsApi} from "@/api/apis";
import {Availability} from "@Models/mentors/Availability";
import {Assignment} from '@Models/mentors/Assignment';

export class AssignmentMonth extends BaseEntity {
    year: number;

    month: number;

    canEditAvailability: boolean;

    availabilities: Record<number, Availability>;

    static get(year: number, month: number): Promise<AssignmentMonth> {
        return MentorsApi.availabilityAssignmentMonthShow(year, month).then(response => {
            return AssignmentMonth.newSingle(response.data, AssignmentMonth.parseExtended);
        });
    }

    static parseResponse(assignmentMonth: AssignmentMonth, data: MentorsAssignmentMonthResponse): AssignmentMonth {
        assignmentMonth.id = data.id;
        assignmentMonth.year = data.year;
        assignmentMonth.month = data.month;
        assignmentMonth.canEditAvailability = data.canEditAvailability;

        return assignmentMonth;
    }

    static parseExtended(assignmentMonth: AssignmentMonth, data: MentorsAssignmentMonthExtendedResponse): AssignmentMonth {
        AssignmentMonth.parseResponse(assignmentMonth, data);

        assignmentMonth.availabilities = Availability.newRecords(data.availabilities, Availability.parseResponse);

        return assignmentMonth;
    }

    public update(canEditAvailability: boolean): Promise<AssignmentMonth> {
        const data: MentorsAssignmentMonthUpdate = {
            year: this.year,
            month: this.month,
            canEditAvailability: canEditAvailability,
        };

        return MentorsApi.availabilityAssignmentMonthUpdate(data).then(response => {
            return AssignmentMonth.parseResponse(this, response.data);
        });
    }

    public getAssignments(): Promise<Record<number, Assignment>> {
        return Assignment.getMonth(this.year, this.month);
    }

    public updateAssignments(assignments: Record<number, Assignment>): Promise<Record<number, Assignment>> {
        const data: MentorsAssignmentUpdate = {
            year: this.year,
            month: this.month,
            assignments: map(assignments, assignment => {
                return {
                    mentor: assignment.mentor?.id ?? null,
                    day: assignment.date.day
                };
            }),
        };

        return MentorsApi.assignmentsUpdate(data).then(response => {
            return Assignment.newRecords(response.data.days, Assignment.parseResponse);
        });
    }

    get date(): DateTime {
        return DateTime.from(this.year, this.month);
    }

    get displayName(): string {
        return this.date.year + ' ' + this.date.monthLong;
    }

    public getDaysArray(): number[] {
        return range(1, this.date.daysInMonth() + 1);
    }

    public getFirstDay(): DateTime {
        return this.date.startOfMonth();
    }

    public getLastDay(): DateTime {
        return this.date.endOfMonth();
    }
}
