<template>
    <div class="wrapper">
        <Navbar>
            <template #button>
                <PegasusLink class="navbar-brand" to="mentors.index">
                    <img src="/src/assets/images/logo/navbar/mentors.svg" height="30px" style="color: white" alt="ugyeletes.sch"/>
                </PegasusLink>
            </template>

            <template #navbar-extra>
                <li v-if="auth.hasPermission(BaseGlobalPermission.MENTORS)" class="nav-item">
                    <PegasusLink class="nav-link" to="mentors.mentor.index">
                        Mentor
                    </PegasusLink>
                </li>
            </template>
        </Navbar>

        <MainContent></MainContent>

        <CommonFooter></CommonFooter>
    </div>
</template>

<script lang="ts" setup>
import Navbar from "@Pages/Navbar.vue";
import CommonFooter from "@Pages/CommonFooter.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import MainContent from "@Components/MainContent.vue";
import {BaseGlobalPermission} from "@/api/models";
import {useAuthStore} from '@/stores/auth';

const auth = useAuthStore();
</script>
