/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoExtendedTenderResponse } from './VikoExtendedTenderResponse';
    import {
    VikoExtendedTenderResponseFromJSON,
    VikoExtendedTenderResponseFromJSONTyped,
    VikoExtendedTenderResponseToJSON,
    VikoExtendedTenderResponseCreateEmpty,
    } from './VikoExtendedTenderResponse';
    import type { VikoReviewerStatisticsResponse } from './VikoReviewerStatisticsResponse';
    import {
    VikoReviewerStatisticsResponseFromJSON,
    VikoReviewerStatisticsResponseFromJSONTyped,
    VikoReviewerStatisticsResponseToJSON,
    VikoReviewerStatisticsResponseCreateEmpty,
    } from './VikoReviewerStatisticsResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoTenderStatisticsResponse {
  tender: VikoExtendedTenderResponse;
  applicationsCount: number;
  reviewers: Array<VikoReviewerStatisticsResponse>;
}

export const VikoTenderStatisticsResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'applicationsCount': {
    name: 'applicationsCount',
    required: true,
    nullable: false,
    label: 'Pályázatok száma',
    type: 'number',
  },
  'reviewers': {
    name: 'reviewers',
    required: true,
    nullable: false,
    label: 'Bíráló statisztika',
      type: 'array',
  },
}
export function instanceOfVikoTenderStatisticsResponse(value: object): value is VikoTenderStatisticsResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "applicationsCount" in value;
    isInstance = isInstance && "reviewers" in value;

return isInstance;
}

export function VikoTenderStatisticsResponseFromJSON(json: any): VikoTenderStatisticsResponse {
return VikoTenderStatisticsResponseFromJSONTyped(json, false);
}

export function VikoTenderStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoTenderStatisticsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': VikoExtendedTenderResponseFromJSON(json['tender']),
          'applicationsCount': json['applicationsCount'],
            'reviewers': ((json['reviewers'] as Array<any>).map(VikoReviewerStatisticsResponseFromJSON)),
    };
  }

  export function VikoTenderStatisticsResponseToJSON(value?: VikoTenderStatisticsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': VikoExtendedTenderResponseToJSON(value.tender),
                'applicationsCount': value.applicationsCount,
              'reviewers': ((value.reviewers as Array<any>).map(VikoReviewerStatisticsResponseToJSON)),
    };
  }

  export function VikoTenderStatisticsResponseCreateEmpty(): VikoTenderStatisticsResponse {
  return {
          'tender': VikoExtendedTenderResponseCreateEmpty(),
      'applicationsCount': 0,
      'reviewers': [],
  };
  }

