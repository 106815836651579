<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <InputNumber
                :ref="inputRef"
                v-model="modelValue"
                :class="{'p-invalid': isValid === false}"
                :input-id="id"
                :max="schema.maximum"
                :min="schema.minimum"
                :prefix="numberPrefix"
                :pt="{input: {root: {'data-input-name': schema.name}}}"
                :readonly="disabled"
                :show-buttons="buttons"
                :step="schema.multipleOf || step"
                :suffix="suffix"
                :use-grouping="grouping"
                class="w-100"
                highlight-on-focus
                input-class="w-100"
                locale="hu"
            ></InputNumber>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import SmartField from "@Components/base/form/SmartField.vue";
import {ref} from "vue";
import InputNumber from "primevue/inputnumber";
import type {ComponentInstance} from "@/vue";

const modelValue = defineModel<number | null>({required: true});

const {
    numberSuffix,
    numberPrefix,
    grouping = true,
    buttons = false,
    disabled,
    withoutGroup,
    schema,
    inline,
    step = 1
} = defineProps<{
    numberPrefix?: string;
    numberSuffix?: string;
    grouping?: boolean;
    buttons?: boolean;
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
    step?: number
}>();

const suffix = numberSuffix ? " " + numberSuffix : undefined;

const inputRef = ref<ComponentInstance<InputNumber> | null>(null);

function focus() {
    inputRef.value?.$el.focus();
}

defineExpose({
    focus,
});
</script>
