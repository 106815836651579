/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsManagerResponse } from './EventsManagerResponse';
    import {
    EventsManagerResponseFromJSON,
    EventsManagerResponseFromJSONTyped,
    EventsManagerResponseToJSON,
    EventsManagerResponseCreateEmpty,
    } from './EventsManagerResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsManagerListResponse {
  managers: Array<EventsManagerResponse>;
}

export const EventsManagerListResponseSchema = {
  'managers': {
    name: 'managers',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsManagerListResponse(value: object): value is EventsManagerListResponse {
let isInstance = true;
    isInstance = isInstance && "managers" in value;

return isInstance;
}

export function EventsManagerListResponseFromJSON(json: any): EventsManagerListResponse {
return EventsManagerListResponseFromJSONTyped(json, false);
}

export function EventsManagerListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsManagerListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'managers': ((json['managers'] as Array<any>).map(EventsManagerResponseFromJSON)),
    };
  }

  export function EventsManagerListResponseToJSON(value?: EventsManagerListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'managers': ((value.managers as Array<any>).map(EventsManagerResponseToJSON)),
    };
  }

  export function EventsManagerListResponseCreateEmpty(): EventsManagerListResponse {
  return {
      'managers': [],
  };
  }

