<template>
    <PegasusHeading>Schönherz Leltár adminisztrátor</PegasusHeading>

    <PegasusPanel header="Tételek felvitele">
        <PegasusTable :data="items">
            <Column header="Leltár azonosító">
                <template #body="{data}">
                    <NumberField
                        v-model="data.id"
                        :schema="InventoryCreateItemAdministratorSchema.id"
                        number-prefix="SCH"
                        without-group
                    ></NumberField>
                </template>
            </Column>

            <Column header="Hivatalos név">
                <template #body="{data}">
                    <TextField
                        v-model="data.officialName"
                        :schema="InventoryCreateItemAdministratorSchema.officialName"
                        without-group
                    ></TextField>
                </template>
            </Column>

            <Column header="Kör">
                <template #body="{data}">
                    <GroupSelectorField
                        v-model="data.responsible"
                        :schema="InventoryCreateItemAdministratorSchema.responsible"
                        without-group
                    ></GroupSelectorField>
                </template>
            </Column>

            <Column header="Materiális">
                <template #body="{data}">
                    <CheckboxField
                        v-model="data.material"
                        :schema="InventoryCreateItemAdministratorSchema.material"
                    ></CheckboxField>
                </template>
            </Column>

            <Column header="Műveletek">
                <template #body="{data, index}">
                    <PegasusButton variant="success" @click="save(data, index)">
                        <i class="fa fa-save"></i> Mentés
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>

        <PegasusButton variant="primary" @click="add">
            <i class="fa fa-plus-circle"></i> Új tétel
        </PegasusButton>
    </PegasusPanel>

    <hr>

    <PegasusPanel header="Elmúlt nap felvitt tételei">
        <PegasusTable :data="previousItems">
            <Column field="idAsText" header="Leltár azonosító"></Column>

            <Column field="officialName" header="Név"></Column>

            <Column field="responsible.name" header="Kör"></Column>

            <Column :field="item => item.material ? 'Igen' : 'Nem'" header="Materiális"></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton variant="primary" @click="edit(data)">
                        <i class="fa fa-edit"></i> Szerkesztés
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>Schönherz Leltár adminisztrátor</title>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {InventoryApi, InventoryCreateItemAdministratorSchema} from "@/api/api";
import {ref} from "vue";
import NumberField from "@Components/base/form/types/NumberField.vue";
import TextField from "@Components/base/form/types/TextField.vue";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import GroupSelectorField from "@Components/base/form/types/custom/GroupSelectorField.vue";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import {Dialog} from '@Utils/dialog';
import ItemEditorDialog from '@Components/inventory/administrator/ItemEditorDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

useAuthStore().requireUser();

const response = await InventoryApi.administratorToday();
const previousItems = ref(Item.newRecords(response.data.items, Item.parseItemResponse));

const items = ref<Item[]>([]);

function add() {
    let newItem = new Item();
    newItem.material = true;

    items.value.push(newItem);
}

function edit(item: Item) {
    Dialog.open(ItemEditorDialog, {item});
}

function save(item: Item, key: number) {
    let request = item.storeByAdministrator().then(() => {
        previousItems.value[item.id] = item;

        items.value.splice(key, 1);
    });

    toast.loading(request);
}
</script>
