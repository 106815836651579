<template>
    <div class="row">
        <div class="col">
            <DatetimeField v-model="data.from" :disabled="!service.canEdit" :schema="EventsBssDataSchema.from"></DatetimeField>
        </div>

        <div class="col">
            <DatetimeField v-model="data.to" :disabled="!service.canEdit" :schema="EventsBssDataSchema.to"></DatetimeField>
        </div>
    </div>

    <SelectField
        v-model="data.type"
        :disabled="!service.canEdit"
        :options="dictEnum(EventsBssVideoTypeValues)"
        :schema="EventsBssDataSchema.type"
    ></SelectField>

    <small class="d-block">
        <i class="fas fa-exclamation-circle text-danger"></i>
        A felkérési szabályzat az alábbi linken tekinthető meg:
        <a href="/files/bss.pdf">LINK</a>
    </small>

    <br>

    <PegasusButton v-if="service.remoteId" :href="'https://felkeres.bsstudio.hu/my-requests/' + service.remoteId" target="_blank" variant="primary">
        Megnyitás a BSS oldalán
    </PegasusButton>
</template>

<script lang="ts" setup>
import DatetimeField from "@Components/base/form/types/DatetimeField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import {type EventsBssData, EventsBssDataSchema, EventsBssVideoTypeValues} from "@/api/models";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {dictEnum} from '@Models/Utils';
import type {Service} from '@Models/events/Service';

const data = defineModel<EventsBssData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
