<template>
    <PegasusHeading>{{ group.name }} - archívum</PegasusHeading>

    <table class="table">
        <thead>
            <tr>
                <th>Év</th>
                <th>Gazdaság</th>
                <th>Események</th>
                <th>VIKÖ</th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="period in sorted" :key="period.id">
                <td>{{ period.year }}</td>

                <td>
                    <PegasusButton v-if="canMoney" :params="{group, period}" to="rvt.groups.money">
                        <i class="fa fa-sack-dollar"></i> Gazdasági összefoglaló
                    </PegasusButton>
                </td>

                <td>
                    <PegasusButton v-if="canEvents && period.year >= 2021" :params="{group, year: period.year}" to="events.group.archive">
                        <i class="fa fa-calendar-days"></i> Rendezvény összefoglaló
                    </PegasusButton>
                </td>

                <td>
                    <PegasusButton v-if="canViko && period.year >= 2018" :params="{group, year: period.year}" to="events.group.archive">
                        <i class="fas fa-address-book"></i> VIKÖ archívum
                    </PegasusButton>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<title>Kör archívum</title>

<script lang="ts" setup>
import {Group} from "@Models/base/Group";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {SpendingPeriod} from "@Models/money/SpendingPeriod";
import {filter, sortBy} from "lodash-es";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {DateTime} from '@Utils/DateTime';
import {BaseGroupSpecificPermission} from '@/api/models';
import {computed} from 'vue';

const {groupId} = defineProps<{
    groupId: number
}>();

const group = Group.getBySingleId(groupId);

const user = useAuthStore().requireUser();

const canMoney = computed(() => user.value.hasGroupPermission(group, BaseGroupSpecificPermission.READ_MONEY));
const canEvents = computed(() => user.value.hasGroupPermission(group, BaseGroupSpecificPermission.READ_EVENTS));
const canViko = computed(() => user.value.hasGroupPermission(group, BaseGroupSpecificPermission.READ_VIKO));

const periods = SpendingPeriod.getAll();

const currentYear = DateTime.now().year;

const sorted = filter(sortBy(periods, period => -period.year), period => period.year < currentYear);
</script>
