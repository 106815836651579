/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsBssVideoType } from './EventsBssVideoType';
    import {
    EventsBssVideoTypeFromJSON,
    EventsBssVideoTypeFromJSONTyped,
    EventsBssVideoTypeToJSON,
    EventsBssVideoTypeCreateEmpty,
    } from './EventsBssVideoType';
import {DateTime} from '@Utils/DateTime';
import { EventsBssVideoTypeValues } from './EventsBssVideoType';

export interface EventsBssData {
  from: DateTime;
  to: DateTime;
  type: EventsBssVideoType;
}

export const EventsBssDataSchema = {
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: 'Felvétel kezdete',
    type: 'datetime',
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: 'Felvétel vége',
    type: 'datetime',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Videó típusa",
        enumValues: EventsBssVideoTypeValues,
  },
}
export function instanceOfEventsBssData(value: object): value is EventsBssData {
let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "type" in value;

return isInstance;
}

export function EventsBssDataFromJSON(json: any): EventsBssData {
return EventsBssDataFromJSONTyped(json, false);
}

export function EventsBssDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsBssData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'from': (DateTime.fromISO(json['from'])),
        'to': (DateTime.fromISO(json['to'])),
              'type': EventsBssVideoTypeFromJSON(json['type']),
    };
  }

  export function EventsBssDataToJSON(value?: EventsBssData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'from': (value.from.toISO()),
            'to': (value.to.toISO()),
                'type': EventsBssVideoTypeToJSON(value.type),
    };
  }

  export function EventsBssDataCreateEmpty(): EventsBssData {
  return {
      'from': DateTime.now(),
      'to': DateTime.now(),
          'type': EventsBssVideoTypeCreateEmpty(),
  };
  }

