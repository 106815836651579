/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface KprUpdateRules {
  rules: Blob;
}

export const KprUpdateRulesSchema = {
  'rules': {
    name: 'rules',
    required: true,
    nullable: false,
    label: 'Szabályzat pdf',
      labelHelp: `Támogatott formátumok: pdf`,
    type: 'file',
        extensions: ["pdf"]
  },
}
export function instanceOfKprUpdateRules(value: object): value is KprUpdateRules {
let isInstance = true;
    isInstance = isInstance && "rules" in value;

return isInstance;
}

export function KprUpdateRulesFromJSON(json: any): KprUpdateRules {
return KprUpdateRulesFromJSONTyped(json, false);
}

export function KprUpdateRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprUpdateRules {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'rules': json['rules'],
    };
  }

  export function KprUpdateRulesToJSON(value?: KprUpdateRules | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'rules': value.rules,
    };
  }

  export function KprUpdateRulesCreateEmpty(): KprUpdateRules {
  return {
      'rules': new Blob(),
  };
  }

