<template>
    <PegasusModal header="Tétel szerkesztése">
        <PegasusForm ref="form">
            <NumberField
                v-model="id"
                :schema="InventoryCreateItemAdministratorSchema.id"
                number-prefix="SCH"
            ></NumberField>

            <TextField
                v-model="officialName"
                :schema="InventoryCreateItemAdministratorSchema.officialName"
            ></TextField>

            <GroupSelectorField
                v-model="responsible"
                :schema="InventoryCreateItemAdministratorSchema.responsible"
            ></GroupSelectorField>

            <CheckboxField
                v-model="material"
                :schema="InventoryCreateItemAdministratorSchema.material"
            ></CheckboxField>

            <PegasusButton variant="success" @click="update">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {InventoryCreateItemAdministratorSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import GroupSelectorField from '@Components/base/form/types/custom/GroupSelectorField.vue';
import CheckboxField from '@Components/base/form/types/CheckboxField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {Item} from '@Models/inventory/Item';
import toast from '@Utils/toast';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import {useReturn} from '@Utils/dialog';

const {item} = defineProps<{
    item: Item
}>();

const form = ref<FormRef>(null);

const returnValue = useReturn();

const id = ref(item.id);
const officialName = ref(item.officialName);
const responsible = ref(item.responsible);
const material = ref(item.material);

function update() {
    const request = item
        .updateByAdministrator(form.value, officialName.value, responsible.value, material.value)
        .then(() => returnValue(true));

    toast.loading(request);
}
</script>
