<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <FileUpload
                :id="id"
                v-model="modelValue"

                :accept="accept"

                :class="{'p-invalid': isValid === false}"
                :disabled="disabled"
                :pt="{input: {'data-input-name': schema.name}}"
                cancel-label="Mégse"
                choose-label="Fájl kiválasztása"
                mode="basic"
                @select="selected"
            >
            </FileUpload>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import SmartField from "@Components/base/form/SmartField.vue";
import {type FieldSchema} from "@Components/base/form/FieldProperties";
import FileUpload, {type FileUploadSelectEvent} from "primevue/fileupload";
import {RuntimeError} from "@Utils/errors/RuntimeError";
import {File} from "@Models/File";
import {isEmpty} from "lodash-es";
import {computed} from 'vue';

const modelValue = defineModel<File | null>({required: true});

const {disabled, withoutGroup, schema, inline} = defineProps<{
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();

const accept = computed(() => {
    if (schema.extensions) {
        return schema.extensions.map(ext => '.' + ext).join(', ');
    }

    return undefined;
});

function selected(event: FileUploadSelectEvent) {
    if (event.files.length > 1) {
        throw new RuntimeError();
    }

    if (isEmpty(event.files)) {
        modelValue.value = null;
        return;
    }

    modelValue.value = File.createFromDomFile(event.files[0]);
}
</script>
