/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryUpdateDataChange {
  changes: { [key: string]: string; };
}

export const InventoryUpdateDataChangeSchema = {
  'changes': {
    name: 'changes',
    required: true,
    nullable: false,
    label: 'Változások',
  },
}
export function instanceOfInventoryUpdateDataChange(value: object): value is InventoryUpdateDataChange {
let isInstance = true;
    isInstance = isInstance && "changes" in value;

return isInstance;
}

export function InventoryUpdateDataChangeFromJSON(json: any): InventoryUpdateDataChange {
return InventoryUpdateDataChangeFromJSONTyped(json, false);
}

export function InventoryUpdateDataChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateDataChange {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'changes': json['changes'],
    };
  }

  export function InventoryUpdateDataChangeToJSON(value?: InventoryUpdateDataChange | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'changes': value.changes,
    };
  }

  export function InventoryUpdateDataChangeCreateEmpty(): InventoryUpdateDataChange {
  return {
      'changes': {},
  };
  }

