/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsClosureTypeResponse {
  id: number;
  name: string;
  description: string;
  price: number;
  isPublic: boolean;
  polygon: Array<string>;
  affectedFloors: Array<number>;
  affectedItems: Array<number>;
  disrupts: boolean;
}

export const EventsClosureTypeResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'price': {
    name: 'price',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'isPublic': {
    name: 'isPublic',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'polygon': {
    name: 'polygon',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'affectedFloors': {
    name: 'affectedFloors',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'affectedItems': {
    name: 'affectedItems',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'disrupts': {
    name: 'disrupts',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfEventsClosureTypeResponse(value: object): value is EventsClosureTypeResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "isPublic" in value;
    isInstance = isInstance && "polygon" in value;
    isInstance = isInstance && "affectedFloors" in value;
    isInstance = isInstance && "affectedItems" in value;
    isInstance = isInstance && "disrupts" in value;

return isInstance;
}

export function EventsClosureTypeResponseFromJSON(json: any): EventsClosureTypeResponse {
return EventsClosureTypeResponseFromJSONTyped(json, false);
}

export function EventsClosureTypeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsClosureTypeResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'price': json['price'],
          'isPublic': json['isPublic'],
          'polygon': json['polygon'],
          'affectedFloors': json['affectedFloors'],
          'affectedItems': json['affectedItems'],
          'disrupts': json['disrupts'],
    };
  }

  export function EventsClosureTypeResponseToJSON(value?: EventsClosureTypeResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'price': value.price,
                'isPublic': value.isPublic,
            'polygon': value.polygon,
            'affectedFloors': value.affectedFloors,
            'affectedItems': value.affectedItems,
                'disrupts': value.disrupts,
    };
  }

  export function EventsClosureTypeResponseCreateEmpty(): EventsClosureTypeResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'price': 0,
      'isPublic': false,
      'polygon': [],
      'affectedFloors': [],
      'affectedItems': [],
      'disrupts': false,
  };
  }

