/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsServiceType } from './EventsServiceType';
    import {
    EventsServiceTypeFromJSON,
    EventsServiceTypeFromJSONTyped,
    EventsServiceTypeToJSON,
    EventsServiceTypeCreateEmpty,
    } from './EventsServiceType';
import {DateTime} from '@Utils/DateTime';
import { EventsServiceTypeValues } from './EventsServiceType';

export interface EventsDeadlineResponse {
  serviceType: EventsServiceType;
  deadline: DateTime;
}

export const EventsDeadlineResponseSchema = {
  'serviceType': {
    name: 'serviceType',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Típus",
        enumValues: EventsServiceTypeValues,
  },
  'deadline': {
    name: 'deadline',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfEventsDeadlineResponse(value: object): value is EventsDeadlineResponse {
let isInstance = true;
    isInstance = isInstance && "serviceType" in value;
    isInstance = isInstance && "deadline" in value;

return isInstance;
}

export function EventsDeadlineResponseFromJSON(json: any): EventsDeadlineResponse {
return EventsDeadlineResponseFromJSONTyped(json, false);
}

export function EventsDeadlineResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsDeadlineResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'serviceType': EventsServiceTypeFromJSON(json['serviceType']),
        'deadline': (DateTime.fromISO(json['deadline'])),
    };
  }

  export function EventsDeadlineResponseToJSON(value?: EventsDeadlineResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'serviceType': EventsServiceTypeToJSON(value.serviceType),
            'deadline': (value.deadline.toISO()),
    };
  }

  export function EventsDeadlineResponseCreateEmpty(): EventsDeadlineResponse {
  return {
          'serviceType': EventsServiceTypeCreateEmpty(),
      'deadline': DateTime.now(),
  };
  }

