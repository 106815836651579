import {Base} from "@Models/Base";
import {Group} from "@Models/base/Group";
import {Report} from "@Models/viko/Report";
import type {VikoLeaderResponse} from "@/api/api";
import {VikoApi} from "@/api/api";

export class LeaderSummary extends Base {
    group: Group;

    done: Record<number, Report>;

    waiting: Record<number, Report>;

    static getByGroupId(groupId: number): Promise<LeaderSummary> {
        return VikoApi.indexLeader(groupId).then(response => {
            return LeaderSummary.newSingle(response.data, LeaderSummary.parseResponse);
        });
    }

    static parseResponse(summary: LeaderSummary, data: VikoLeaderResponse): LeaderSummary {
        summary.group = Group.getBySingleId(data.groupId);
        summary.done = Report.newRecords(data.doneReports, Report.parseResponse);
        summary.waiting = Report.newRecords(data.waitingReports, Report.parseResponse);

        return summary;
    }
}
