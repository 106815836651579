/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseSuccessResponse {
  success: boolean;
}

export const BaseSuccessResponseSchema = {
  'success': {
    name: 'success',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfBaseSuccessResponse(value: object): value is BaseSuccessResponse {
let isInstance = true;
    isInstance = isInstance && "success" in value;

return isInstance;
}

export function BaseSuccessResponseFromJSON(json: any): BaseSuccessResponse {
return BaseSuccessResponseFromJSONTyped(json, false);
}

export function BaseSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseSuccessResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'success': json['success'],
    };
  }

  export function BaseSuccessResponseToJSON(value?: BaseSuccessResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'success': value.success,
    };
  }

  export function BaseSuccessResponseCreateEmpty(): BaseSuccessResponse {
  return {
      'success': false,
  };
  }

