/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupSpecificPermission } from './BaseGroupSpecificPermission';
    import {
    BaseGroupSpecificPermissionFromJSON,
    BaseGroupSpecificPermissionFromJSONTyped,
    BaseGroupSpecificPermissionToJSON,
    BaseGroupSpecificPermissionCreateEmpty,
    } from './BaseGroupSpecificPermission';
import {DateTime} from '@Utils/DateTime';
import { BaseGroupSpecificPermissionValues } from './BaseGroupSpecificPermission';

export interface BaseAssignedGroupPermissionResponse {
  id: number;
  groupId: number;
  permission: BaseGroupSpecificPermission;
  inheriting: boolean;
}

export const BaseAssignedGroupPermissionResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'permission': {
    name: 'permission',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: BaseGroupSpecificPermissionValues,
  },
  'inheriting': {
    name: 'inheriting',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfBaseAssignedGroupPermissionResponse(value: object): value is BaseAssignedGroupPermissionResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "permission" in value;
    isInstance = isInstance && "inheriting" in value;

return isInstance;
}

export function BaseAssignedGroupPermissionResponseFromJSON(json: any): BaseAssignedGroupPermissionResponse {
return BaseAssignedGroupPermissionResponseFromJSONTyped(json, false);
}

export function BaseAssignedGroupPermissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAssignedGroupPermissionResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'groupId': json['groupId'],
              'permission': BaseGroupSpecificPermissionFromJSON(json['permission']),
          'inheriting': json['inheriting'],
    };
  }

  export function BaseAssignedGroupPermissionResponseToJSON(value?: BaseAssignedGroupPermissionResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'groupId': value.groupId,
                'permission': BaseGroupSpecificPermissionToJSON(value.permission),
                'inheriting': value.inheriting,
    };
  }

  export function BaseAssignedGroupPermissionResponseCreateEmpty(): BaseAssignedGroupPermissionResponse {
  return {
      'id': 0,
      'groupId': 0,
          'permission': BaseGroupSpecificPermissionCreateEmpty(),
      'inheriting': false,
  };
  }

