<template>
    <PegasusHeading>Ügyeletes mentor</PegasusHeading>

    <div class="row">
        <div class="col-lg-12">
            <h4 class="text-center">{{ year }} {{ monthName }} havi beosztás</h4>

            <PegasusTable :data="assignments" :per-page="31" :row-variant="rowClass">
                <Column :field="item => item.date.dateShort()" header="Dátum"></Column>

                <Column :field="item => item.date.weekdayLong" header="Nap"></Column>

                <Column field="mentor.name" header="Név"></Column>

                <Column field="mentor.phone" header="Telefonszám">
                    <template #body="{data}">
                        <a :href="data.mentor.phoneLink">
                            {{ data.mentor.phone }}
                        </a>
                    </template>
                </Column>

                <Column field="mentor.room" header="Szoba"></Column>
            </PegasusTable>
        </div>
    </div>

    <br/>

    <Description :leader="summary.leader"></Description>
</template>

<title>Ügyeletes mentor beosztás</title>

<script lang="ts" setup>
import Description from "@Components/mentors/Description.vue";
import {DateTime} from "@Utils/DateTime";
import {Assignment} from "@Models/mentors/Assignment";
import {Mentor} from "@Models/mentors/Mentor";
import promiseAll from "@Utils/promise";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {year, month} = defineProps<{
    year: number,
    month: number,
}>();

const {assignments, summary} = await promiseAll({
    assignments: Assignment.getMonth(year, month),
    summary: Mentor.getIndex(),
});

function rowClass(item: Assignment) {
    return DateTime.now().day === item.date.day ? 'success' : null;
}

const monthName = DateTime.from(year, month).monthLong;
</script>
