/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { EventsMilestoneType } from './EventsMilestoneType';
    import {
    EventsMilestoneTypeFromJSON,
    EventsMilestoneTypeFromJSONTyped,
    EventsMilestoneTypeToJSON,
    EventsMilestoneTypeCreateEmpty,
    } from './EventsMilestoneType';
import {DateTime} from '@Utils/DateTime';
import { EventsMilestoneTypeValues } from './EventsMilestoneType';

export interface EventsMilestoneResponse {
  id: number;
  type: EventsMilestoneType;
  user: BaseMinimalUserResponse;
  comment: string | null;
  modifyData: { [key: string]: string; } | null;
  createdAt: DateTime;
}

export const EventsMilestoneResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: EventsMilestoneTypeValues,
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'modifyData': {
    name: 'modifyData',
    required: true,
    nullable: true,
    label: '',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfEventsMilestoneResponse(value: object): value is EventsMilestoneResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "modifyData" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function EventsMilestoneResponseFromJSON(json: any): EventsMilestoneResponse {
return EventsMilestoneResponseFromJSONTyped(json, false);
}

export function EventsMilestoneResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsMilestoneResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'type': EventsMilestoneTypeFromJSON(json['type']),
              'user': BaseMinimalUserResponseFromJSON(json['user']),
          'comment': json['comment'],
          'modifyData': json['modifyData'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function EventsMilestoneResponseToJSON(value?: EventsMilestoneResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'type': EventsMilestoneTypeToJSON(value.type),
                'user': BaseMinimalUserResponseToJSON(value.user),
                'comment': value.comment,
                'modifyData': value.modifyData,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function EventsMilestoneResponseCreateEmpty(): EventsMilestoneResponse {
  return {
      'id': 0,
          'type': EventsMilestoneTypeCreateEmpty(),
          'user': BaseMinimalUserResponseCreateEmpty(),
      'comment': '',
      'modifyData': {},
      'createdAt': DateTime.now(),
  };
  }

