/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryItemResponse } from './InventoryItemResponse';
    import {
    InventoryItemResponseFromJSON,
    InventoryItemResponseFromJSONTyped,
    InventoryItemResponseToJSON,
    InventoryItemResponseCreateEmpty,
    } from './InventoryItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryTodayResponse {
  items: Array<InventoryItemResponse>;
}

export const InventoryTodayResponseSchema = {
  'items': {
    name: 'items',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfInventoryTodayResponse(value: object): value is InventoryTodayResponse {
let isInstance = true;
    isInstance = isInstance && "items" in value;

return isInstance;
}

export function InventoryTodayResponseFromJSON(json: any): InventoryTodayResponse {
return InventoryTodayResponseFromJSONTyped(json, false);
}

export function InventoryTodayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryTodayResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'items': ((json['items'] as Array<any>).map(InventoryItemResponseFromJSON)),
    };
  }

  export function InventoryTodayResponseToJSON(value?: InventoryTodayResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'items': ((value.items as Array<any>).map(InventoryItemResponseToJSON)),
    };
  }

  export function InventoryTodayResponseCreateEmpty(): InventoryTodayResponse {
  return {
      'items': [],
  };
  }

