import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import type {InventoryItemUpdate, InventoryUpdateDamage, InventoryUpdateResponse} from "@/api/api";
import {InventoryApi, InventoryQuality, InventoryQualityValues, InventoryUpdateType} from "@/api/api";
import type {FormRef} from '@/vue';

export class Damage extends ItemUpdate {
    type = InventoryUpdateType.DAMAGE;

    quality: InventoryQuality;

    description: string;

    static parseResponse(damage: Damage, data: InventoryUpdateResponse): Damage {
        ItemUpdate.parseResponse(damage, data);

        const specific = data.data as InventoryUpdateDamage;
        damage.quality = specific.quality;
        damage.description = specific.description;

        return damage;
    }

    public store(itemId: number, form: FormRef): Promise<Damage> {
        const data: InventoryItemUpdate = {
            type: InventoryUpdateType.DAMAGE,
            data: {
                type: InventoryUpdateType.DAMAGE,
                quality: this.quality,
                description: this.description,
            },
            comment: this.comment,
        };

        return InventoryApi.itemUpdatesAdd(itemId, data, {form}).then(response => {
            return Damage.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        const quality = InventoryQualityValues[this.quality];

        return [
            {key: 'Sérülés utáni állapot', value: quality},
            {key: 'Leírás', value: this.description},
        ];
    }
}
