import {Base} from "@Models/Base";
import {ReviewerGroup} from "@Models/viko/ReviewerGroup";
import {Application} from "@Models/viko/Application";
import type {VikoSummaryResponse} from "@/api/api";
import {VikoApi} from "@/api/api";
import {Tender} from "@Models/viko/Tender";

export class ReviewSummary extends Base {
    tender: Tender;

    userReviewerGroupId: number | null;

    moderator: boolean;

    reviewerGroups: Record<number, ReviewerGroup>;

    applications: Record<number, Application>;

    canEdit: boolean;

    static get(tenderId: number): Promise<ReviewSummary> {
        return VikoApi.summarySummary(tenderId).then(response => {
            return ReviewSummary.newSingle(response.data, ReviewSummary.parseResponse);
        });
    }

    static parseResponse(summary: ReviewSummary, data: VikoSummaryResponse): ReviewSummary {
        summary.tender = Tender.newSingle(data.tender, Tender.parseExtendedResponse);
        summary.userReviewerGroupId = data.userReviewerGroupId;
        summary.moderator = data.moderator;
        summary.reviewerGroups = ReviewerGroup.newRecords(data.reviewerGroups, ReviewerGroup.parseResponse);
        summary.applications = Application.newRecords(data.applications, Application.parseResponse);
        summary.canEdit = data.canEdit;

        return summary;
    }
}
