/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { MoneySpendingEventType } from './MoneySpendingEventType';
    import {
    MoneySpendingEventTypeFromJSON,
    MoneySpendingEventTypeFromJSONTyped,
    MoneySpendingEventTypeToJSON,
    MoneySpendingEventTypeCreateEmpty,
    } from './MoneySpendingEventType';
    import type { MoneySpendingItemEventResponseData } from './MoneySpendingItemEventResponseData';
    import {
    MoneySpendingItemEventResponseDataFromJSON,
    MoneySpendingItemEventResponseDataFromJSONTyped,
    MoneySpendingItemEventResponseDataToJSON,
    MoneySpendingItemEventResponseDataCreateEmpty,
    } from './MoneySpendingItemEventResponseData';
import {DateTime} from '@Utils/DateTime';
import { MoneySpendingEventTypeValues } from './MoneySpendingEventType';

export interface MoneySpendingItemEventResponse {
  id: number;
  user: BaseMinimalUserResponse;
  type: MoneySpendingEventType;
  data: MoneySpendingItemEventResponseData;
  comment: string;
  createdAt: DateTime;
}

export const MoneySpendingItemEventResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Esemény Típusa",
        enumValues: MoneySpendingEventTypeValues,
  },
  'data': {
    name: 'data',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: 'Időpont',
    type: 'datetime',
  },
}
export function instanceOfMoneySpendingItemEventResponse(value: object): value is MoneySpendingItemEventResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function MoneySpendingItemEventResponseFromJSON(json: any): MoneySpendingItemEventResponse {
return MoneySpendingItemEventResponseFromJSONTyped(json, false);
}

export function MoneySpendingItemEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingItemEventResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'user': BaseMinimalUserResponseFromJSON(json['user']),
              'type': MoneySpendingEventTypeFromJSON(json['type']),
              'data': MoneySpendingItemEventResponseDataFromJSON(json['data']),
          'comment': json['comment'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function MoneySpendingItemEventResponseToJSON(value?: MoneySpendingItemEventResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'user': BaseMinimalUserResponseToJSON(value.user),
                'type': MoneySpendingEventTypeToJSON(value.type),
                'data': MoneySpendingItemEventResponseDataToJSON(value.data),
                'comment': value.comment,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function MoneySpendingItemEventResponseCreateEmpty(): MoneySpendingItemEventResponse {
  return {
      'id': 0,
          'user': BaseMinimalUserResponseCreateEmpty(),
          'type': MoneySpendingEventTypeCreateEmpty(),
          'data': MoneySpendingItemEventResponseDataCreateEmpty(),
      'comment': '',
      'createdAt': DateTime.now(),
  };
  }

