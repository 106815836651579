<template>
    <PegasusForm part="guests">
        <h4 class="text-center">Vendégek</h4>

        <PegasusTable :data="local.guests" :data-key-function="null" :per-page="5" empty-text="Üres táblázat">
            <Column :header="CommonPersonDataSchema.name.label">
                <template #body="{data}: {data: CommonPersonData}">
                    <TextField
                        v-if="service.canEdit"
                        v-model="data.name"
                        :schema="CommonPersonDataSchema.name"
                        wide
                        without-group
                    />
                    <span v-else>{{ data.name }}</span>
                </template>
            </Column>

            <Column :header="CommonPersonDataSchema.id.label">
                <template #body="{data}: {data: CommonPersonData}">
                    <TextField
                        v-if="service.canEdit"
                        v-model="data.id"
                        :schema="CommonPersonDataSchema.id"
                        mask="999999aa"
                        without-group
                    />
                    <span v-else>{{ data.id }}</span>
                </template>
            </Column>

            <Column :header="CommonPersonDataSchema.card.label">
                <template #body="{data, index}: {data: CommonPersonData, index: number}">
                    <div class="d-inline-block">
                        <CheckboxField
                            v-if="service.canEdit"
                            v-model="data.card"
                            :schema="CommonPersonDataSchema.card"
                            inline
                            without-label
                        />
                        <span v-else>{{ data.card }}</span>
                    </div>

                    <PegasusButton v-if="service.canEdit" class="float-end" variant="danger" @click="local.guests.splice(index, 1)">
                        <i class="fa fa-times"></i>
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>

        <div class="row">
            <div class="col-4 text-center">
                <span class="align-middle">{{ local.guests.length }} vendég</span>
            </div>
            <div class="col-4 text-end">
                <PegasusButton v-if="service.canEdit" variant="success" @click="local.guests.push(CommonPersonDataCreateEmpty())">
                    <i class="fa fa-plus"></i>
                </PegasusButton>
            </div>
        </div>
    </PegasusForm>

    <hr>

    <div class="row">
        <div class="col-lg-8">
            <FileField
                v-model="csv"
                :disabled="!service.canEdit"
                :schema="GuestList.fileSchema()"
            ></FileField>
        </div>

        <div class="col-lg-4">
            <PegasusButton v-if="service.canEdit" variant="success" @click="upload">
                <i class="fa fa-file-upload"></i> Importálás
            </PegasusButton>
            <PegasusButton variant="primary" @click="download">
                <i class="fas fa-download"></i> Exportálás
            </PegasusButton>
        </div>
    </div>

    <hr>

    <small>
        <i class="fas fa-exclamation-circle text-danger"></i>
        Nagyon fontos, hogy ellenőrizve legyen, hogy ezen a listán lévők közül senkinek sincsen kollégiumi
        jogviszonya a rendezvény idején!
    </small>

    <br>

    <small>
        <i class="fas fa-info-circle text-primary"></i>
        Ezen a listán lévő nem kollégista vendégek a rendezvény ideje alatt vendégfogadó
        nélkül be tudnak jönni a portán a személyi igazolványuk bemutatásával.
    </small>
    <br>
</template>

<script lang="ts" setup>
import type {Service} from "@Models/events/Service";
import {ref} from "vue";
import FileField from "@Components/base/form/types/FileField.vue";
import toast from "@Utils/toast";
import {
    type CommonPersonData,
    CommonPersonDataCreateEmpty,
    CommonPersonDataSchema,
    type EventsGuestListData
} from "@/api/models";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import TextField from "@Components/base/form/types/TextField.vue";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import {File} from "@Models/File";
import {GuestList} from '@Models/events/services/GuestList';
import PegasusForm from '@Components/base/form/PegasusForm.vue';

const csv = ref<File | null>(null);

const local = defineModel<EventsGuestListData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();

function upload() {
    if (csv.value === null) {
        return;
    }

    const request = GuestList.parseCsv(csv.value).then(guests => {
        local.value.guests = guests;
    });

    toast.loading(request, 'Feldolgozás');
}

function download() {
    GuestList.createCsv(local.value.guests).download();
}
</script>
