/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsClosureTypeResponse } from './EventsClosureTypeResponse';
    import {
    EventsClosureTypeResponseFromJSON,
    EventsClosureTypeResponseFromJSONTyped,
    EventsClosureTypeResponseToJSON,
    EventsClosureTypeResponseCreateEmpty,
    } from './EventsClosureTypeResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsClosureTypeListResponse {
  types: Array<EventsClosureTypeResponse>;
}

export const EventsClosureTypeListResponseSchema = {
  'types': {
    name: 'types',
    required: true,
    nullable: false,
    label: 'Lezáratás típusok',
      type: 'array',
  },
}
export function instanceOfEventsClosureTypeListResponse(value: object): value is EventsClosureTypeListResponse {
let isInstance = true;
    isInstance = isInstance && "types" in value;

return isInstance;
}

export function EventsClosureTypeListResponseFromJSON(json: any): EventsClosureTypeListResponse {
return EventsClosureTypeListResponseFromJSONTyped(json, false);
}

export function EventsClosureTypeListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsClosureTypeListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'types': ((json['types'] as Array<any>).map(EventsClosureTypeResponseFromJSON)),
    };
  }

  export function EventsClosureTypeListResponseToJSON(value?: EventsClosureTypeListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'types': ((value.types as Array<any>).map(EventsClosureTypeResponseToJSON)),
    };
  }

  export function EventsClosureTypeListResponseCreateEmpty(): EventsClosureTypeListResponse {
  return {
      'types': [],
  };
  }

