import type {RouteRecordRaw} from "vue-router";

const kpr: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('@Pages/kpr/Index.vue'),
        name: 'kpr.index',
    },
];

export default kpr;
