import {BaseEntity} from "@Models/BaseEntity";
import {User} from "@Models/base/User";
import {DateTime} from "@Utils/DateTime";
import {Group} from "@Models/base/Group";

export class BaseRequest extends BaseEntity {
    group: Group;

    requester: User;

    requesterPost: string;

    finalized: DateTime | null;

    approved: DateTime | null;

    decision: boolean | null;

    decisionTime: DateTime | null;

    createdAt: DateTime;

    canEdit: boolean;

    canFinalize: boolean;

    canApprove: boolean;

    canDecide: boolean;

    get status(): string {
        if (this.decision !== null) {
            return 'RVT által ' + (this.decision ? 'elfogadva' : 'elutasítva');
        }

        if (this.approved) {
            return 'Reszortvezető jóváhagyta, RVT döntésre vár';
        }

        if (this.finalized) {
            return 'Leadva, reszortvezetői jóváhagyásra vár';
        }

        return 'Szerkesztés alatt';
    }

    get idAsText(): string {
        return '#' + this.id;
    }
}
