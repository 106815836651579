/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetItemStore {
  category: number;
  name: string;
  description: string;
  number: number;
}

export const MoneyBudgetItemStoreSchema = {
  'category': {
    name: 'category',
    required: true,
    nullable: false,
    label: 'Házszintű tétel csoport',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 5,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
      minLength: 0,
      maxLength: 3000,
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Sorszám',
    type: 'number',
      minimum: 1,
      maximum: 100,
  },
}
export function instanceOfMoneyBudgetItemStore(value: object): value is MoneyBudgetItemStore {
let isInstance = true;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "number" in value;

return isInstance;
}

export function MoneyBudgetItemStoreFromJSON(json: any): MoneyBudgetItemStore {
return MoneyBudgetItemStoreFromJSONTyped(json, false);
}

export function MoneyBudgetItemStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetItemStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'category': json['category'],
          'name': json['name'],
          'description': json['description'],
          'number': json['number'],
    };
  }

  export function MoneyBudgetItemStoreToJSON(value?: MoneyBudgetItemStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'category': value.category,
                'name': value.name,
                'description': value.description,
                'number': value.number,
    };
  }

  export function MoneyBudgetItemStoreCreateEmpty(): MoneyBudgetItemStore {
  return {
      'category': 0,
      'name': '',
      'description': '',
      'number': 0,
  };
  }

