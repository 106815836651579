/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsExtendedEventResponse } from './EventsExtendedEventResponse';
    import {
    EventsExtendedEventResponseFromJSON,
    EventsExtendedEventResponseFromJSONTyped,
    EventsExtendedEventResponseToJSON,
    EventsExtendedEventResponseCreateEmpty,
    } from './EventsExtendedEventResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseExtendedGroupSummaryEventsResponse {
  drafting: Array<EventsExtendedEventResponse>;
  waiting: Array<EventsExtendedEventResponse>;
  future: Array<EventsExtendedEventResponse>;
}

export const BaseExtendedGroupSummaryEventsResponseSchema = {
  'drafting': {
    name: 'drafting',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'waiting': {
    name: 'waiting',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'future': {
    name: 'future',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseExtendedGroupSummaryEventsResponse(value: object): value is BaseExtendedGroupSummaryEventsResponse {
let isInstance = true;
    isInstance = isInstance && "drafting" in value;
    isInstance = isInstance && "waiting" in value;
    isInstance = isInstance && "future" in value;

return isInstance;
}

export function BaseExtendedGroupSummaryEventsResponseFromJSON(json: any): BaseExtendedGroupSummaryEventsResponse {
return BaseExtendedGroupSummaryEventsResponseFromJSONTyped(json, false);
}

export function BaseExtendedGroupSummaryEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseExtendedGroupSummaryEventsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'drafting': ((json['drafting'] as Array<any>).map(EventsExtendedEventResponseFromJSON)),
            'waiting': ((json['waiting'] as Array<any>).map(EventsExtendedEventResponseFromJSON)),
            'future': ((json['future'] as Array<any>).map(EventsExtendedEventResponseFromJSON)),
    };
  }

  export function BaseExtendedGroupSummaryEventsResponseToJSON(value?: BaseExtendedGroupSummaryEventsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'drafting': ((value.drafting as Array<any>).map(EventsExtendedEventResponseToJSON)),
              'waiting': ((value.waiting as Array<any>).map(EventsExtendedEventResponseToJSON)),
              'future': ((value.future as Array<any>).map(EventsExtendedEventResponseToJSON)),
    };
  }

  export function BaseExtendedGroupSummaryEventsResponseCreateEmpty(): BaseExtendedGroupSummaryEventsResponse {
  return {
      'drafting': [],
      'waiting': [],
      'future': [],
  };
  }

