/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsCheckResponse {
  title: string;
  content: string | null;
  help: string | null;
  link: string | null;
  event: number | null;
}

export const EventsCheckResponseSchema = {
  'title': {
    name: 'title',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'content': {
    name: 'content',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'help': {
    name: 'help',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'link': {
    name: 'link',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'event': {
    name: 'event',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfEventsCheckResponse(value: object): value is EventsCheckResponse {
let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "help" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "event" in value;

return isInstance;
}

export function EventsCheckResponseFromJSON(json: any): EventsCheckResponse {
return EventsCheckResponseFromJSONTyped(json, false);
}

export function EventsCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsCheckResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'title': json['title'],
          'content': json['content'],
          'help': json['help'],
          'link': json['link'],
          'event': json['event'],
    };
  }

  export function EventsCheckResponseToJSON(value?: EventsCheckResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'title': value.title,
                'content': value.content,
                'help': value.help,
                'link': value.link,
                'event': value.event,
    };
  }

  export function EventsCheckResponseCreateEmpty(): EventsCheckResponse {
  return {
      'title': '',
      'content': '',
      'help': '',
      'link': '',
      'event': 0,
  };
  }

