/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoApplicationResponse } from './VikoApplicationResponse';
    import {
    VikoApplicationResponseFromJSON,
    VikoApplicationResponseFromJSONTyped,
    VikoApplicationResponseToJSON,
    VikoApplicationResponseCreateEmpty,
    } from './VikoApplicationResponse';
    import type { VikoReviewerGroupResponse } from './VikoReviewerGroupResponse';
    import {
    VikoReviewerGroupResponseFromJSON,
    VikoReviewerGroupResponseFromJSONTyped,
    VikoReviewerGroupResponseToJSON,
    VikoReviewerGroupResponseCreateEmpty,
    } from './VikoReviewerGroupResponse';
    import type { VikoTenderResponse } from './VikoTenderResponse';
    import {
    VikoTenderResponseFromJSON,
    VikoTenderResponseFromJSONTyped,
    VikoTenderResponseToJSON,
    VikoTenderResponseCreateEmpty,
    } from './VikoTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoIndexResponse {
  tender: VikoTenderResponse;
  application: VikoApplicationResponse | null;
  reviewerGroup: VikoReviewerGroupResponse | null;
  moderator: boolean;
  reportableGroups: Array<number>;
  ledGroups: Array<number>;
}

export const VikoIndexResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'application': {
    name: 'application',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'reviewerGroup': {
    name: 'reviewerGroup',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'moderator': {
    name: 'moderator',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'reportableGroups': {
    name: 'reportableGroups',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'ledGroups': {
    name: 'ledGroups',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
}
export function instanceOfVikoIndexResponse(value: object): value is VikoIndexResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "application" in value;
    isInstance = isInstance && "reviewerGroup" in value;
    isInstance = isInstance && "moderator" in value;
    isInstance = isInstance && "reportableGroups" in value;
    isInstance = isInstance && "ledGroups" in value;

return isInstance;
}

export function VikoIndexResponseFromJSON(json: any): VikoIndexResponse {
return VikoIndexResponseFromJSONTyped(json, false);
}

export function VikoIndexResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoIndexResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': VikoTenderResponseFromJSON(json['tender']),
              'application': VikoApplicationResponseFromJSON(json['application']),
              'reviewerGroup': VikoReviewerGroupResponseFromJSON(json['reviewerGroup']),
          'moderator': json['moderator'],
          'reportableGroups': json['reportableGroups'],
          'ledGroups': json['ledGroups'],
    };
  }

  export function VikoIndexResponseToJSON(value?: VikoIndexResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': VikoTenderResponseToJSON(value.tender),
                'application': VikoApplicationResponseToJSON(value.application),
                'reviewerGroup': VikoReviewerGroupResponseToJSON(value.reviewerGroup),
                'moderator': value.moderator,
            'reportableGroups': value.reportableGroups,
            'ledGroups': value.ledGroups,
    };
  }

  export function VikoIndexResponseCreateEmpty(): VikoIndexResponse {
  return {
          'tender': VikoTenderResponseCreateEmpty(),
          'application': VikoApplicationResponseCreateEmpty(),
          'reviewerGroup': VikoReviewerGroupResponseCreateEmpty(),
      'moderator': false,
      'reportableGroups': [],
      'ledGroups': [],
  };
  }

