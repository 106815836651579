/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MapRoomResponse } from './MapRoomResponse';
    import {
    MapRoomResponseFromJSON,
    MapRoomResponseFromJSONTyped,
    MapRoomResponseToJSON,
    MapRoomResponseCreateEmpty,
    } from './MapRoomResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseUserResponse {
  pek: string | null;
  room: MapRoomResponse | null;
  phone: string | null;
  phoneLink: string | null;
  phoneShort: string | null;
  id: number;
  name: string;
  nick: string | null;
  email: string;
}

export const BaseUserResponseSchema = {
  'pek': {
    name: 'pek',
    required: true,
    nullable: true,
    label: 'Pék felhasználónév',
    type: 'string',
  },
  'room': {
    name: 'room',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'phone': {
    name: 'phone',
    required: true,
    nullable: true,
    label: 'Telefonszám',
    type: 'string',
  },
  'phoneLink': {
    name: 'phoneLink',
    required: true,
    nullable: true,
    label: 'Telefonszám linkként',
    type: 'string',
  },
  'phoneShort': {
    name: 'phoneShort',
    required: true,
    nullable: true,
    label: 'Telefonszám előhívő nélkül',
    type: 'string',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'nick': {
    name: 'nick',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'email': {
    name: 'email',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfBaseUserResponse(value: object): value is BaseUserResponse {
let isInstance = true;
    isInstance = isInstance && "pek" in value;
    isInstance = isInstance && "room" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "phoneLink" in value;
    isInstance = isInstance && "phoneShort" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "nick" in value;
    isInstance = isInstance && "email" in value;

return isInstance;
}

export function BaseUserResponseFromJSON(json: any): BaseUserResponse {
return BaseUserResponseFromJSONTyped(json, false);
}

export function BaseUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'pek': json['pek'],
              'room': MapRoomResponseFromJSON(json['room']),
          'phone': json['phone'],
          'phoneLink': json['phoneLink'],
          'phoneShort': json['phoneShort'],
          'id': json['id'],
          'name': json['name'],
          'nick': json['nick'],
          'email': json['email'],
    };
  }

  export function BaseUserResponseToJSON(value?: BaseUserResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'pek': value.pek,
                'room': MapRoomResponseToJSON(value.room),
                'phone': value.phone,
                'phoneLink': value.phoneLink,
                'phoneShort': value.phoneShort,
                'id': value.id,
                'name': value.name,
                'nick': value.nick,
                'email': value.email,
    };
  }

  export function BaseUserResponseCreateEmpty(): BaseUserResponse {
  return {
      'pek': '',
          'room': MapRoomResponseCreateEmpty(),
      'phone': '',
      'phoneLink': '',
      'phoneShort': '',
      'id': 0,
      'name': '',
      'nick': '',
      'email': '',
  };
  }

