import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import type {InventoryItemUpdate, InventoryUpdateCheck, InventoryUpdateResponse} from "@/api/api";
import {InventoryApi, InventoryQuality, InventoryQualityValues, InventoryUpdateType} from "@/api/api";
import type {FormRef} from '@/vue';

export class Check extends ItemUpdate {
    type = InventoryUpdateType.CHECK;

    success: boolean;

    quality: InventoryQuality | null;

    static parseResponse(check: Check, data: InventoryUpdateResponse): Check {
        ItemUpdate.parseResponse(check, data);

        const specific = data.data as InventoryUpdateCheck;
        check.success = specific.success;
        check.quality = specific.quality;

        return check;
    }

    public store(itemId: number, form: FormRef): Promise<Check> {
        const data: InventoryItemUpdate = {
            comment: this.comment,
            type: InventoryUpdateType.CHECK,
            data: {
                type: InventoryUpdateType.CHECK,
                success: this.success,
                quality: this.quality
            },
        };

        return InventoryApi.itemUpdatesAdd(itemId, data, {form}).then(response => {
            return Check.parseResponse(this, response.data);
        });
    }

    get text(): { key: string, value: string }[] {
        const result = [
            {key: 'Eredmény', value: this.success ? 'Sikeres' : 'Sikertelen'},
        ];

        if (this.success && this.quality !== null) {
            const level = InventoryQualityValues[this.quality];

            result.push({key: 'Állapot', value: level});
        }

        return result;
    }
}
