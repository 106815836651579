<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div>
                <CascadeSelect
                    v-model="modelValue"
                    :class="{'p-invalid': isValid === false}"
                    :disabled="props.disabled"
                    :input-id="id"
                    :option-group-children="['optionGroupChildren']"
                    :options="resorts"
                    :pt="{input: {'data-input-name': schema.name}}"
                    class="w-100"
                    option-group-label="name"
                    option-label="name"
                ></CascadeSelect>
            </div>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import SmartField from "@Components/base/form/SmartField.vue";
import {type FieldSchema} from "@Components/base/form/FieldProperties";
import type {Group} from "@Models/base/Group";
import CascadeSelect from "primevue/cascadeselect";
import {useConstantsStore} from "@/stores/constants";

const modelValue = defineModel<Group | undefined>({required: true});

const props = defineProps<{
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();

const resorts = useConstantsStore().constants.groups.calculated;
</script>
