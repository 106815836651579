/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsTrainingParticipationResponse } from './EventsTrainingParticipationResponse';
    import {
    EventsTrainingParticipationResponseFromJSON,
    EventsTrainingParticipationResponseFromJSONTyped,
    EventsTrainingParticipationResponseToJSON,
    EventsTrainingParticipationResponseCreateEmpty,
    } from './EventsTrainingParticipationResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsTrainingParticipationListResponse {
  participations: Array<EventsTrainingParticipationResponse>;
}

export const EventsTrainingParticipationListResponseSchema = {
  'participations': {
    name: 'participations',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsTrainingParticipationListResponse(value: object): value is EventsTrainingParticipationListResponse {
let isInstance = true;
    isInstance = isInstance && "participations" in value;

return isInstance;
}

export function EventsTrainingParticipationListResponseFromJSON(json: any): EventsTrainingParticipationListResponse {
return EventsTrainingParticipationListResponseFromJSONTyped(json, false);
}

export function EventsTrainingParticipationListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsTrainingParticipationListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'participations': ((json['participations'] as Array<any>).map(EventsTrainingParticipationResponseFromJSON)),
    };
  }

  export function EventsTrainingParticipationListResponseToJSON(value?: EventsTrainingParticipationListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'participations': ((value.participations as Array<any>).map(EventsTrainingParticipationResponseToJSON)),
    };
  }

  export function EventsTrainingParticipationListResponseCreateEmpty(): EventsTrainingParticipationListResponse {
  return {
      'participations': [],
  };
  }

