import {BaseEntity} from "@Models/BaseEntity";
import {User} from "@Models/base/User";
import {File} from "@Models/File";
import {DateTime} from "@Utils/DateTime";
import type {InventoryDocumentResponse} from "@/api/api";
import {InventoryApi, InventoryDocumentType} from "@/api/api";
import type {FormRef} from '@/vue';

export class Document extends BaseEntity {
    name: string;

    uploader: User;

    type: InventoryDocumentType;

    comment: string;

    createdAt: DateTime;

    static parseResponse(document: Document, data: InventoryDocumentResponse): Document {
        document.id = data.id;
        document.name = data.name;
        document.uploader = User.newSingle(data.uploader, User.parseMinimalResponse);
        document.type = data.type;
        document.comment = data.comment;
        document.createdAt = data.createdAt;

        return document;
    }

    public download(): Promise<File> {
        return InventoryApi.documentsDownload(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public remove(): Promise<void> {
        return InventoryApi.documentsDelete(this.id).then();
    }

    public store(itemId: number, file: File, form: FormRef): Promise<void> {
        return InventoryApi.documentsStore(itemId, this.name, this.type, file.getAsDomFile(), this.comment ?? '', {form}).then(response => {
            Document.parseResponse(this, response.data);
        });
    }

    public get fileType(): 'image' | 'pdf' | 'xls' {
        if (this.name.endsWith('.jpg') || this.name.endsWith('.jpeg') || this.name.endsWith('.png')) {
            return 'image';
        }
        if (this.name.endsWith('.xls') || this.name.endsWith('.xlsx')) {
            return 'xls';
        }
        if (this.name.endsWith('.pdf')) {
            return 'pdf';
        }

        throw new Error('Unrecognised file type: ' + this.name);
    }
}
