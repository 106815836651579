<template>
    <h2 class="text-center mt-2">RVT felület</h2>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel v-for="summary in tenders" :key="summary.tender.id" :header="summary.tender.displayName" class="mb-3">
                <template #header>
                    <PegasusLink :params="{tender: summary.tender}" to="rvt.kpr.tender.show">
                        {{ summary.tender.displayName }}
                    </PegasusLink>
                </template>

                <dl>
                    <dt>KPR időszak</dt>
                    <dd>{{ summary.tender.status }}</dd>

                    <dt>Leadott pályázatok száma</dt>
                    <dd>{{ summary.applicationsCount }} db</dd>

                    <template v-if="summary.tender.amendable">
                        <dt>RVT-re váró módosító kérvények</dt>
                        <dd v-for="amend in amends[summary.tender.id]" :key="amend.id">
                            <PegasusLink :params="{amend}" to="rvt.amends.show">
                                #{{ amend.id }} {{ amend.objective }}
                            </PegasusLink>
                        </dd>
                        <dd v-if="isEmpty(amends[summary.tender.id])">
                            Jelenleg egy KPR módosító sem vár elbírálásra
                        </dd>
                    </template>
                </dl>
            </PegasusPanel>

            <span v-if="isEmpty(tenders)">Egyetlen KPR pályázat sem aktív</span>
        </div>

        <div class="col-lg-6">
            <PegasusPanel v-if="periodSummary">
                <template #header>
                    <PegasusLink :params="{period: periodSummary.period}" to="rvt.spendingPeriod.show">
                        {{ periodSummary.period.name }}
                    </PegasusLink>
                </template>
                <div class="row">
                    <div class="col-md-6">
                        <dl>
                            <dt>RVT-re váró keretigények</dt>
                            <dd v-for="claim in claims" :key="claim.id">
                                <PegasusLink :params="{claim}" to="rvt.claims.show">
                                    #{{ claim.id }} {{ claim.objective }}
                                </PegasusLink>
                            </dd>
                            <dd v-if="isEmpty(claims)">Jelenleg egy keretigény se vár elbírálásra</dd>
                        </dl>
                    </div>
                    <div class="col-md-6">
                        <dl v-if="periodSummary">
                            <dt>RVT keret</dt>
                            <dd>{{ periodSummary.claimsBudget.huf() }}</dd>

                            <dt>KPR módosítások egyenlege</dt>
                            <dd v-if="getTenderSummaryForPeriod(periodSummary.period)">
                                {{ getTenderSummaryForPeriod(periodSummary.period)?.amendsBalance.huf() }}
                            </dd>
                            <dd v-else>nincs KPR pályázat</dd>

                            <dt>Keretigények összege</dt>
                            <dd>{{ periodSummary.claimsSum.huf() }}</dd>

                            <dt>Elköltetlen keret</dt>
                            <dd>{{ periodSummary.claimsBalance.huf() }}</dd>
                        </dl>
                    </div>
                </div>
            </PegasusPanel>
            <span v-else>Jelenleg nincsen aktív költési időszak</span>
        </div>
    </div>
</template>

<title>RVT felület</title>

<script lang="ts" setup>
import {TenderSummary} from "@Models/rvt/kpr/TenderSummary";
import {SpendingPeriodSummary} from "@Models/money/SpendingPeriodSummary";
import {Claim} from "@Models/rvt/requests/Claim";
import {Amend} from "@Models/rvt/requests/Amend";
import {SpendingPeriod} from "@Models/money/SpendingPeriod";
import {each, find, isEmpty} from "lodash-es";
import {useConstantsStore} from "@/stores/constants";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import {useAuthStore} from "@/stores/auth";

useAuthStore().requireUser();

function getTenderSummaryForPeriod(period: SpendingPeriod): TenderSummary | undefined {
    return find(tenders, (tender: TenderSummary) => tender.tender.spendingPeriod.id === period.id);
}

let requests = [];
let data: {
    periodSummary: SpendingPeriodSummary | null,
    claims: Record<number, Claim>,
    tenders: Record<number, TenderSummary>,
    amends: Record<number, Record<number, Amend>>
} = {
    periodSummary: null,
    claims: {},
    tenders: {},
    amends: {},
};

const constants = useConstantsStore().constants;

if (constants.money.currentPeriod) {
    requests.push(
        SpendingPeriodSummary.getById(constants.money.currentPeriod.id).then(summary => {
            data.periodSummary = summary;
        })
    );
    requests.push(
        Claim.getWaitingInPeriod(constants.money.currentPeriod.id).then(claims => {
            data.claims = claims;
        })
    );
}

each(constants.kpr.relevantTenders, tender => {
    requests.push(
        TenderSummary.getById(tender.id).then(summary => {
            data.tenders[tender.id] = summary;
        })
    );
    requests.push(
        Amend.getWaitingInTender(tender.id).then(amends => {
            data.amends[tender.id] = amends;
        })
    );
});

await Promise.all(requests);

const {periodSummary, claims, tenders, amends} = data;
</script>
