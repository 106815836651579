<template>
    <PegasusHeading>{{ group.name }} - leltár</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Tételek cimkék szerint">
                <TagListItem
                    v-for="tagCount in summary.tagCounts"
                    :key="tagCount.tag.id"
                    :count="tagCount.count"
                    :group="group"
                    :tag="tagCount.tag"
                ></TagListItem>
            </PegasusPanel>

            <PegasusPanel class="mt-2" header="Nagyleltár">
                <PegasusButton v-if="user.hasGroupPermission(group, BaseGroupSpecificPermission.MANAGE_INVENTORY)" to="inventory.groups.create.existing" variant="success">
                    <i class="fa fa-cart-plus"></i> Új tétel létrehozása
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Tételcsoportok">
                <Tree :item-group="summary.rootGroup"></Tree>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Leltár kör adminisztráció</title>

<script lang="ts" setup>
import {Group} from "@Models/base/Group";
import {InventoryGroupSummary} from "@Models/inventory/InventoryGroupSummary";
import Tree from "@Components/inventory/itemGroup/Tree.vue";
import {useAuthStore} from "@/stores/auth";
import {BaseGroupSpecificPermission} from "@/api/models";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {ref} from "vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import TagListItem from '@Components/inventory/group/TagListItem.vue';

const {groupId} = defineProps<{
    groupId: number
}>();

const user = useAuthStore().requireUser();
const group = Group.getBySingleId(groupId);

const summary = ref(await InventoryGroupSummary.getByGroup(group.id));
</script>
