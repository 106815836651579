import type {RouteRecordRaw} from "vue-router";
import {getEnumParam, getNumberParam, getStringParam} from "@Models/Utils";
import {EventsServiceType} from "@/api/models";

const events: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'events.index',
        component: () => import('@Pages/events/Index.vue'),
    },
    {
        path: '/groups/:group',
        name: 'events.group',
        component: () => import('@Pages/events/group/Group.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/groups/:group/archive/:year',
        name: 'events.group.archive',
        component: () => import('@Pages/events/group/Archive.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
            year: getNumberParam(route, 'year'),
        }),
    },
    {
        path: '/planning',
        name: 'events.planning.index',
        component: () => import('@Pages/events/planning/Planning.vue'),
    },
    {
        path: '/planning/mine',
        name: 'events.planning.mine',
        component: () => import('@Pages/events/planning/MyReservations.vue'),
    },
    {
        path: '/planning/new',
        name: 'events.planning.new',
        component: () => import('@Pages/events/planning/NewReservation.vue'),
    },
    {
        path: '/planning/:reservation',
        name: 'events.planning.edit',
        component: () => import('@Pages/events/planning/EditReservation.vue'),
        props: route => ({
            reservationId: getNumberParam(route, 'reservation'),
        }),
    },
    {
        path: '/events/own',
        name: 'events.own',
        component: () => import('@Pages/events/Own.vue'),
    },
    {
        path: '/events/new',
        name: 'events.new',
        component: () => import('@Pages/events/New.vue'),
    },
    {
        path: '/events/:event',
        name: 'events.edit',
        component: () => import('@Pages/events/Event.vue'),
        props: route => ({
            eventId: getNumberParam(route, 'event'),
        }),
    },
    {
        path: '/calendar',
        name: 'events.calendar.public',
        component: () => import('@Pages/events/PublicCalendar.vue'),
    },
    {
        path: '/calendar/:location',
        name: 'events.calendar.location',
        component: () => import('@Pages/events/LocationCalendar.vue'),
        props: route => ({
            locationName: getStringParam(route, 'location'),
        }),
    },
    {
        path: '/services/:service',
        name: 'events.services.index',
        component: () => import('@Pages/events/ServiceManagement.vue'),
        props: route => ({
            serviceType: getEnumParam(route, 'service', EventsServiceType),
        }),
    },
    {
        path: '/trainings',
        name: 'events.trainings',
        component: () => import('@Pages/events/Trainings.vue'),
    },
    {
        path: '/trainings/:training',
        name: 'events.trainings.edit',
        component: () => import('@Pages/events/admin/Training.vue'),
        props: route => ({
            trainingId: getNumberParam(route, 'training'),
        }),
    },
    {
        path: '/admin',
        name: 'events.admin.index',
        component: () => import('@Pages/events/admin/Index.vue'),
    },
    {
        path: '/admin/todo',
        name: 'events.admin.todo',
        component: () => import('@Pages/events/admin/Todo.vue'),
    },
    {
        path: '/admin/calendar/:year',
        name: 'events.admin.calendar',
        component: () => import('@Pages/events/admin/Calendar.vue'),
        props: route => ({
            year: getNumberParam(route, 'year'),
        }),
    },
    {
        path: '/admin/managers',
        name: 'events.admin.managers',
        component: () => import('@Pages/events/admin/Managers.vue'),
    },
    {
        path: '/admin/planning',
        name: 'events.admin.planning',
        component: () => import('@Pages/events/admin/Planning.vue'),
    },
    {
        path: '/admin/planning/:period',
        name: 'events.admin.planning.period',
        component: () => import('@Pages/events/admin/ReservationPeriod.vue'),
        props: route => ({
            periodId: getNumberParam(route, 'period'),
        }),
    },
    {
        path: '/admin/emails',
        name: 'events.admin.emails',
        component: () => import('@Pages/events/admin/Emails.vue'),
    },
    {
        path: '/admin/closureTypes',
        name: 'events.admin.closureTypes.index',
        component: () => import('@Pages/events/closureTypes/Index.vue'),
    },
    {
        path: '/admin/closureTypes/:type',
        name: 'events.admin.closureTypes.edit',
        component: () => import('@Pages/events/closureTypes/Edit.vue'),
        props: route => ({
            closureTypeId: getNumberParam(route, 'type'),
        }),
    },
];

export default events;
