/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyItemAssetType } from './MoneyItemAssetType';
    import {
    MoneyItemAssetTypeFromJSON,
    MoneyItemAssetTypeFromJSONTyped,
    MoneyItemAssetTypeToJSON,
    MoneyItemAssetTypeCreateEmpty,
    } from './MoneyItemAssetType';
import {DateTime} from '@Utils/DateTime';
import { MoneyItemAssetTypeValues } from './MoneyItemAssetType';

export interface MoneyAssetStore {
  item: number;
  file: Blob;
  name: string;
  description: string;
  type: MoneyItemAssetType;
}

export const MoneyAssetStoreSchema = {
  'item': {
    name: 'item',
    required: true,
    nullable: false,
    label: 'KPR tétel',
    type: 'number',
      minimum: 0,
  },
  'file': {
    name: 'file',
    required: true,
    nullable: false,
    label: 'Fájl',
      labelHelp: `Támogatott formátumok: jpg, jpeg, png, pdf, xls, xlsx`,
    type: 'file',
        extensions: ["jpg","jpeg","png","pdf","xls","xlsx"]
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Megnevezés',
      labelHelp: `Pl.: Cisco árajánlat`,
    type: 'string',
      minLength: 2,
      maxLength: 250,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
      maxLength: 5000,
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: MoneyItemAssetTypeValues,
  },
}
export function instanceOfMoneyAssetStore(value: object): value is MoneyAssetStore {
let isInstance = true;
    isInstance = isInstance && "item" in value;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;

return isInstance;
}

export function MoneyAssetStoreFromJSON(json: any): MoneyAssetStore {
return MoneyAssetStoreFromJSONTyped(json, false);
}

export function MoneyAssetStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyAssetStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'item': json['item'],
          'file': json['file'],
          'name': json['name'],
          'description': json['description'],
              'type': MoneyItemAssetTypeFromJSON(json['type']),
    };
  }

  export function MoneyAssetStoreToJSON(value?: MoneyAssetStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'item': value.item,
                'file': value.file,
                'name': value.name,
                'description': value.description,
                'type': MoneyItemAssetTypeToJSON(value.type),
    };
  }

  export function MoneyAssetStoreCreateEmpty(): MoneyAssetStore {
  return {
      'item': 0,
      'file': new Blob(),
      'name': '',
      'description': '',
          'type': MoneyItemAssetTypeCreateEmpty(),
  };
  }

