/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoReportResponse {
  id: number;
  text: string;
  user: BaseMinimalUserResponse;
  groupId: number;
  finalized: DateTime | null;
  accepted: boolean | null;
  leaderComment: string | null;
  canEdit: boolean;
}

export const VikoReportResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'text': {
    name: 'text',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'finalized': {
    name: 'finalized',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'accepted': {
    name: 'accepted',
    required: true,
    nullable: true,
    label: '',
    type: 'boolean',
  },
  'leaderComment': {
    name: 'leaderComment',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfVikoReportResponse(value: object): value is VikoReportResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "finalized" in value;
    isInstance = isInstance && "accepted" in value;
    isInstance = isInstance && "leaderComment" in value;
    isInstance = isInstance && "canEdit" in value;

return isInstance;
}

export function VikoReportResponseFromJSON(json: any): VikoReportResponse {
return VikoReportResponseFromJSONTyped(json, false);
}

export function VikoReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReportResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'text': json['text'],
              'user': BaseMinimalUserResponseFromJSON(json['user']),
          'groupId': json['groupId'],
        'finalized': (json['finalized'] === null ? null : DateTime.fromISO(json['finalized'])),
          'accepted': json['accepted'],
          'leaderComment': json['leaderComment'],
          'canEdit': json['canEdit'],
    };
  }

  export function VikoReportResponseToJSON(value?: VikoReportResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'text': value.text,
                'user': BaseMinimalUserResponseToJSON(value.user),
                'groupId': value.groupId,
            'finalized': (
              value.finalized === null ? null : value.finalized.toISO()),
                'accepted': value.accepted,
                'leaderComment': value.leaderComment,
                'canEdit': value.canEdit,
    };
  }

  export function VikoReportResponseCreateEmpty(): VikoReportResponse {
  return {
      'id': 0,
      'text': '',
          'user': BaseMinimalUserResponseCreateEmpty(),
      'groupId': 0,
      'finalized': DateTime.now(),
      'accepted': false,
      'leaderComment': '',
      'canEdit': false,
  };
  }

