/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { RvtAmendResponse } from './RvtAmendResponse';
    import {
    RvtAmendResponseFromJSON,
    RvtAmendResponseFromJSONTyped,
    RvtAmendResponseToJSON,
    RvtAmendResponseCreateEmpty,
    } from './RvtAmendResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtAmendsResponse {
  amends: Array<RvtAmendResponse>;
}

export const RvtAmendsResponseSchema = {
  'amends': {
    name: 'amends',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfRvtAmendsResponse(value: object): value is RvtAmendsResponse {
let isInstance = true;
    isInstance = isInstance && "amends" in value;

return isInstance;
}

export function RvtAmendsResponseFromJSON(json: any): RvtAmendsResponse {
return RvtAmendsResponseFromJSONTyped(json, false);
}

export function RvtAmendsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtAmendsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'amends': ((json['amends'] as Array<any>).map(RvtAmendResponseFromJSON)),
    };
  }

  export function RvtAmendsResponseToJSON(value?: RvtAmendsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'amends': ((value.amends as Array<any>).map(RvtAmendResponseToJSON)),
    };
  }

  export function RvtAmendsResponseCreateEmpty(): RvtAmendsResponse {
  return {
      'amends': [],
  };
  }

