/* tslint:disable */
/* eslint-disable */

export enum BaseSeverity {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark'
}

export const BaseSeverityValues: Record<BaseSeverity, string> = {
    'primary': 'Elsődleges',
    'secondary': 'Másodlagos',
    'success': 'Siker',
    'warning': 'Figyelmeztetés',
    'danger': 'Veszély',
    'info': 'Információ',
    'light': 'Világos',
    'dark': 'Sötét'
}


export function BaseSeverityFromJSON(json: any): BaseSeverity {
    return BaseSeverityFromJSONTyped(json, false);
}

export function BaseSeverityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseSeverity {
    return json as BaseSeverity;
}

export function BaseSeverityToJSON(value?: BaseSeverity | null): any {
    return value as any;
}

export function BaseSeverityCreateEmpty(): BaseSeverity {
    return BaseSeverity.PRIMARY;
}

