/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAssignmentMonthResponse {
  id: number;
  year: number;
  month: number;
  canEditAvailability: boolean;
}

export const MentorsAssignmentMonthResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'year': {
    name: 'year',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 2000,
  },
  'month': {
    name: 'month',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
      maximum: 12,
  },
  'canEditAvailability': {
    name: 'canEditAvailability',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfMentorsAssignmentMonthResponse(value: object): value is MentorsAssignmentMonthResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "canEditAvailability" in value;

return isInstance;
}

export function MentorsAssignmentMonthResponseFromJSON(json: any): MentorsAssignmentMonthResponse {
return MentorsAssignmentMonthResponseFromJSONTyped(json, false);
}

export function MentorsAssignmentMonthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAssignmentMonthResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'year': json['year'],
          'month': json['month'],
          'canEditAvailability': json['canEditAvailability'],
    };
  }

  export function MentorsAssignmentMonthResponseToJSON(value?: MentorsAssignmentMonthResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'year': value.year,
                'month': value.month,
                'canEditAvailability': value.canEditAvailability,
    };
  }

  export function MentorsAssignmentMonthResponseCreateEmpty(): MentorsAssignmentMonthResponse {
  return {
      'id': 0,
      'year': 0,
      'month': 0,
      'canEditAvailability': false,
  };
  }

