<template>
    <PegasusHeading>{{ period.name }}</PegasusHeading>

    <PegasusPanel class="my-3" header="Információk">
        <div class="col-lg-4">
            <dt>RVT keretigények (elfogadott / elutasított)</dt>
            <dd>{{ summary.successfulClaims }} / {{ summary.declinedClaims }}
                (
                <PegasusLink :params="{period: period}" to="rvt.spendingPeriod.claims">
                    Megtekintés
                </PegasusLink>
                )
            </dd>
        </div>
    </PegasusPanel>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Kapcsolódó KPR pályázat">
                <dl v-if="tenderSummary">
                    <dt>
                        <PegasusLink :params="{tender: tenderSummary.tender}" to="rvt.kpr.tender.show">
                            {{ tenderSummary.tender.displayName }}
                        </PegasusLink>
                    </dt>
                    <dt>Leadott pályázatok száma</dt>
                    <dd>{{ tenderSummary.applicationsCount }} db</dd>
                    <dt>KPR módosító kérvények (elfogadott / elutasított)</dt>
                    <dd>{{ tenderSummary.successfulAmends }} / {{ tenderSummary.declinedAmends }}
                        (
                        <PegasusLink :params="{tender: tenderSummary.tender}" to="rvt.kpr.tender.amends">
                            Megtekintés
                        </PegasusLink>
                        )
                    </dd>
                </dl>
                <dl v-else>
                    <dt>Még nincsen pályázat létrehozva</dt>
                </dl>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="RVT keret statisztika">
                <dl>
                    <dt>RVT keret</dt>
                    <dd>{{ summary.claimsBudget.huf() }}</dd>

                    <dt>KPR módosítások egyenlege</dt>
                    <dd v-if="tenderSummary">{{ tenderSummary.amendsBalance.huf() }}</dd>
                    <dd v-else>nincs KPR pályázat</dd>

                    <dt>Keretigények összege</dt>
                    <dd>{{ summary.claimsSum.huf() }}</dd>

                    <dt>Elköltetlen keret</dt>
                    <dd>{{ summary.claimsBalance.huf() }}</dd>
                </dl>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Költési időszak</title>

<script lang="ts" setup>
import {SpendingPeriodSummary} from "@Models/money/SpendingPeriodSummary";
import {TenderSummary} from "@Models/rvt/kpr/TenderSummary";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {SpendingPeriod} from '@Models/money/SpendingPeriod';
import promiseAll from '@Utils/promise';

const {periodId} = defineProps<{
    periodId: number
}>();

useAuthStore().requireUser();

const period = SpendingPeriod.getBySingleId(periodId);

const {summary, tenderSummary} = await promiseAll({
    summary: SpendingPeriodSummary.getById(periodId),
    tenderSummary: period.tender ? TenderSummary.getById(period.tender.id) : new Promise<null>(resolve => resolve(null))
});
</script>
