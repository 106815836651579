/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseExtendedGroupSummaryEventsResponse } from './BaseExtendedGroupSummaryEventsResponse';
    import {
    BaseExtendedGroupSummaryEventsResponseFromJSON,
    BaseExtendedGroupSummaryEventsResponseFromJSONTyped,
    BaseExtendedGroupSummaryEventsResponseToJSON,
    BaseExtendedGroupSummaryEventsResponseCreateEmpty,
    } from './BaseExtendedGroupSummaryEventsResponse';
    import type { BaseExtendedGroupSummaryPeriodResponse } from './BaseExtendedGroupSummaryPeriodResponse';
    import {
    BaseExtendedGroupSummaryPeriodResponseFromJSON,
    BaseExtendedGroupSummaryPeriodResponseFromJSONTyped,
    BaseExtendedGroupSummaryPeriodResponseToJSON,
    BaseExtendedGroupSummaryPeriodResponseCreateEmpty,
    } from './BaseExtendedGroupSummaryPeriodResponse';
    import type { BaseGroupSummaryInventoryResponse } from './BaseGroupSummaryInventoryResponse';
    import {
    BaseGroupSummaryInventoryResponseFromJSON,
    BaseGroupSummaryInventoryResponseFromJSONTyped,
    BaseGroupSummaryInventoryResponseToJSON,
    BaseGroupSummaryInventoryResponseCreateEmpty,
    } from './BaseGroupSummaryInventoryResponse';
    import type { BaseGroupSummaryVikoResponse } from './BaseGroupSummaryVikoResponse';
    import {
    BaseGroupSummaryVikoResponseFromJSON,
    BaseGroupSummaryVikoResponseFromJSONTyped,
    BaseGroupSummaryVikoResponseToJSON,
    BaseGroupSummaryVikoResponseCreateEmpty,
    } from './BaseGroupSummaryVikoResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseExtendedGroupSummaryResponse {
  groupId: number;
  viko: BaseGroupSummaryVikoResponse | null;
  inventory: BaseGroupSummaryInventoryResponse | null;
  events: BaseExtendedGroupSummaryEventsResponse | null;
  periods: Array<BaseExtendedGroupSummaryPeriodResponse>;
}

export const BaseExtendedGroupSummaryResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'viko': {
    name: 'viko',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'inventory': {
    name: 'inventory',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'events': {
    name: 'events',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'periods': {
    name: 'periods',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseExtendedGroupSummaryResponse(value: object): value is BaseExtendedGroupSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "viko" in value;
    isInstance = isInstance && "inventory" in value;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "periods" in value;

return isInstance;
}

export function BaseExtendedGroupSummaryResponseFromJSON(json: any): BaseExtendedGroupSummaryResponse {
return BaseExtendedGroupSummaryResponseFromJSONTyped(json, false);
}

export function BaseExtendedGroupSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseExtendedGroupSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
              'viko': BaseGroupSummaryVikoResponseFromJSON(json['viko']),
              'inventory': BaseGroupSummaryInventoryResponseFromJSON(json['inventory']),
              'events': BaseExtendedGroupSummaryEventsResponseFromJSON(json['events']),
            'periods': ((json['periods'] as Array<any>).map(BaseExtendedGroupSummaryPeriodResponseFromJSON)),
    };
  }

  export function BaseExtendedGroupSummaryResponseToJSON(value?: BaseExtendedGroupSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
                'viko': BaseGroupSummaryVikoResponseToJSON(value.viko),
                'inventory': BaseGroupSummaryInventoryResponseToJSON(value.inventory),
                'events': BaseExtendedGroupSummaryEventsResponseToJSON(value.events),
              'periods': ((value.periods as Array<any>).map(BaseExtendedGroupSummaryPeriodResponseToJSON)),
    };
  }

  export function BaseExtendedGroupSummaryResponseCreateEmpty(): BaseExtendedGroupSummaryResponse {
  return {
      'groupId': 0,
          'viko': BaseGroupSummaryVikoResponseCreateEmpty(),
          'inventory': BaseGroupSummaryInventoryResponseCreateEmpty(),
          'events': BaseExtendedGroupSummaryEventsResponseCreateEmpty(),
      'periods': [],
  };
  }

