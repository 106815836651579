/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyPriority {
  one: string;
  two: string;
  three: string;
}

export const MoneyPrioritySchema = {
  'one': {
    name: 'one',
    required: true,
    nullable: false,
    label: '1. prioritás',
    type: 'string',
      maxLength: 250,
  },
  'two': {
    name: 'two',
    required: true,
    nullable: false,
    label: '2. prioritás',
    type: 'string',
      maxLength: 250,
  },
  'three': {
    name: 'three',
    required: true,
    nullable: false,
    label: '3. prioritás',
    type: 'string',
      maxLength: 250,
  },
}
export function instanceOfMoneyPriority(value: object): value is MoneyPriority {
let isInstance = true;
    isInstance = isInstance && "one" in value;
    isInstance = isInstance && "two" in value;
    isInstance = isInstance && "three" in value;

return isInstance;
}

export function MoneyPriorityFromJSON(json: any): MoneyPriority {
return MoneyPriorityFromJSONTyped(json, false);
}

export function MoneyPriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyPriority {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'one': json['one'],
          'two': json['two'],
          'three': json['three'],
    };
  }

  export function MoneyPriorityToJSON(value?: MoneyPriority | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'one': value.one,
                'two': value.two,
                'three': value.three,
    };
  }

  export function MoneyPriorityCreateEmpty(): MoneyPriority {
  return {
      'one': '',
      'two': '',
      'three': '',
  };
  }

