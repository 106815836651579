/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsTableSetData {
  tables: number;
  chairs: number;
}

export const EventsTableSetDataSchema = {
  'tables': {
    name: 'tables',
    required: true,
    nullable: false,
    label: 'Asztalok száma',
    type: 'number',
      minimum: 0,
      maximum: 15,
  },
  'chairs': {
    name: 'chairs',
    required: true,
    nullable: false,
    label: 'Padok száma',
    type: 'number',
      minimum: 0,
      maximum: 30,
  },
}
export function instanceOfEventsTableSetData(value: object): value is EventsTableSetData {
let isInstance = true;
    isInstance = isInstance && "tables" in value;
    isInstance = isInstance && "chairs" in value;

return isInstance;
}

export function EventsTableSetDataFromJSON(json: any): EventsTableSetData {
return EventsTableSetDataFromJSONTyped(json, false);
}

export function EventsTableSetDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsTableSetData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'tables': json['tables'],
          'chairs': json['chairs'],
    };
  }

  export function EventsTableSetDataToJSON(value?: EventsTableSetData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tables': value.tables,
                'chairs': value.chairs,
    };
  }

  export function EventsTableSetDataCreateEmpty(): EventsTableSetData {
  return {
      'tables': 0,
      'chairs': 0,
  };
  }

