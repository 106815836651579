import {BaseEntity} from "@Models/BaseEntity";
import {Assignment} from "@Models/mentors/Assignment";
import type {MentorsMentorResponse, MentorsMentorStore} from "@/api/api";
import {MentorsApi} from "@/api/api";
import {User} from "@Models/base/User";
import type {FormRef} from '@/vue';

export class Mentor extends BaseEntity {
    user: User;

    active: boolean;

    leader: boolean;

    static getIndex(): Promise<{ current: Assignment, next: Assignment | null, leader: Mentor }> {
        return MentorsApi.indexIndex().then(response => {
            return {
                current: Assignment.newSingle(response.data.current, Assignment.parseResponse),
                next: Assignment.newSingleNullable(response.data.next, Assignment.parseResponse),
                leader: Mentor.newSingle(response.data.leader, Mentor.parseResponse),
            };
        });
    }

    static getInactivated(): Promise<Record<number, Mentor>> {
        return MentorsApi.mentorsInactivated().then(response => {
            return Mentor.newRecords(response.data.mentors, Mentor.parseResponse);
        });
    }

    static getAll(): Promise<Record<number, Mentor>> {
        return MentorsApi.mentorsIndex().then(response => {
            return Mentor.newRecords(response.data.mentors, Mentor.parseResponse);
        });
    }

    static parseResponse(mentor: Mentor, data: MentorsMentorResponse): Mentor {
        mentor.id = data.id;
        mentor.user = User.newSingle(data.user, User.parseBaseResponse);
        mentor.active = data.active;
        mentor.leader = data.leader;

        return mentor;
    }

    public store(form: FormRef): Promise<Mentor> {
        const data: MentorsMentorStore = {
            user: this.user.id,
        };

        return MentorsApi.mentorsStore(data, {form}).then(response => {
            return Mentor.parseResponse(this, response.data);
        });
    }

    public makeLeader(): Promise<void> {
        return MentorsApi.mentorsLeader(this.id).then(response => {
            Mentor.parseResponse(this, response.data);
        });
    }

    public makeActive(): Promise<void> {
        return MentorsApi.mentorsActive(this.id).then(response => {
            Mentor.parseResponse(this, response.data);
        });
    }

    public makeInactive(): Promise<void> {
        return MentorsApi.mentorsInactive(this.id).then(response => {
            Mentor.parseResponse(this, response.data);
        });
    }

    get name(): string {
        return this.user.name;
    }

    get room(): string {
        if (this.user.room === null) {
            throw new Error('Mentor without room');
        }

        return this.user.room.displayNumber;
    }

    get phone(): string {
        if (this.user.phone === null) {
            throw new Error('Mentor without phone');
        }

        return this.user.phone;
    }

    get phoneLink(): string {
        if (this.user.phoneLink === null) {
            throw new Error('Mentor without phone');
        }

        return this.user.phoneLink;
    }
}
