/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryOwnerStore {
  name: string;
  description: string;
}

export const InventoryOwnerStoreSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírása',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfInventoryOwnerStore(value: object): value is InventoryOwnerStore {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function InventoryOwnerStoreFromJSON(json: any): InventoryOwnerStore {
return InventoryOwnerStoreFromJSONTyped(json, false);
}

export function InventoryOwnerStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryOwnerStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
    };
  }

  export function InventoryOwnerStoreToJSON(value?: InventoryOwnerStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
    };
  }

  export function InventoryOwnerStoreCreateEmpty(): InventoryOwnerStore {
  return {
      'name': '',
      'description': '',
  };
  }

