import {Base} from "@Models/Base";
import {Group} from "@Models/base/Group";
import {Membership} from "@Models/base/Membership";
import type {BaseGroupAccessResponse, BaseGroupSpecificPermission} from "@/api/api";

export class GroupAccess extends Base {
    group: Group;

    membership: Membership | null;

    permissions: BaseGroupSpecificPermission[];

    static parseResponse(groupAccess: GroupAccess, data: BaseGroupAccessResponse): GroupAccess {
        groupAccess.group = Group.getBySingleId(data.groupId);
        groupAccess.membership = Membership.newSingleNullable(data.membership, Membership.parseResponse);
        groupAccess.permissions = data.permissions;

        return groupAccess;
    }

    public hasPermission(permissions: BaseGroupSpecificPermission[]): boolean {
        return permissions.some(permission => this.permissions.includes(permission));
    }
}
