/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsAssignmentResponse } from './MentorsAssignmentResponse';
    import {
    MentorsAssignmentResponseFromJSON,
    MentorsAssignmentResponseFromJSONTyped,
    MentorsAssignmentResponseToJSON,
    MentorsAssignmentResponseCreateEmpty,
    } from './MentorsAssignmentResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsMonthResponse {
  days: Array<MentorsAssignmentResponse>;
}

export const MentorsMonthResponseSchema = {
  'days': {
    name: 'days',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfMentorsMonthResponse(value: object): value is MentorsMonthResponse {
let isInstance = true;
    isInstance = isInstance && "days" in value;

return isInstance;
}

export function MentorsMonthResponseFromJSON(json: any): MentorsMonthResponse {
return MentorsMonthResponseFromJSONTyped(json, false);
}

export function MentorsMonthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsMonthResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'days': ((json['days'] as Array<any>).map(MentorsAssignmentResponseFromJSON)),
    };
  }

  export function MentorsMonthResponseToJSON(value?: MentorsMonthResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'days': ((value.days as Array<any>).map(MentorsAssignmentResponseToJSON)),
    };
  }

  export function MentorsMonthResponseCreateEmpty(): MentorsMonthResponse {
  return {
      'days': [],
  };
  }

