/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsHeatingPermitData {
  description: string;
}

export const EventsHeatingPermitDataSchema = {
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
      maxLength: 5000,
  },
}
export function instanceOfEventsHeatingPermitData(value: object): value is EventsHeatingPermitData {
let isInstance = true;
    isInstance = isInstance && "description" in value;

return isInstance;
}

export function EventsHeatingPermitDataFromJSON(json: any): EventsHeatingPermitData {
return EventsHeatingPermitDataFromJSONTyped(json, false);
}

export function EventsHeatingPermitDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsHeatingPermitData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'description': json['description'],
    };
  }

  export function EventsHeatingPermitDataToJSON(value?: EventsHeatingPermitData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'description': value.description,
    };
  }

  export function EventsHeatingPermitDataCreateEmpty(): EventsHeatingPermitData {
  return {
      'description': '',
  };
  }

