/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryItemResponse } from './InventoryItemResponse';
    import {
    InventoryItemResponseFromJSON,
    InventoryItemResponseFromJSONTyped,
    InventoryItemResponseToJSON,
    InventoryItemResponseCreateEmpty,
    } from './InventoryItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryItemGroupWithItemsResponse {
  children: Array<InventoryItemGroupWithItemsResponse>;
  items: Array<InventoryItemResponse>;
  id: number;
  name: string;
  description: string;
  itemCount: number;
  responsibleGroupId: number;
}

export const InventoryItemGroupWithItemsResponseSchema = {
  'children': {
    name: 'children',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'items': {
    name: 'items',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'itemCount': {
    name: 'itemCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'responsibleGroupId': {
    name: 'responsibleGroupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfInventoryItemGroupWithItemsResponse(value: object): value is InventoryItemGroupWithItemsResponse {
let isInstance = true;
    isInstance = isInstance && "children" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "responsibleGroupId" in value;

return isInstance;
}

export function InventoryItemGroupWithItemsResponseFromJSON(json: any): InventoryItemGroupWithItemsResponse {
return InventoryItemGroupWithItemsResponseFromJSONTyped(json, false);
}

export function InventoryItemGroupWithItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemGroupWithItemsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'children': ((json['children'] as Array<any>).map(InventoryItemGroupWithItemsResponseFromJSON)),
            'items': ((json['items'] as Array<any>).map(InventoryItemResponseFromJSON)),
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'itemCount': json['itemCount'],
          'responsibleGroupId': json['responsibleGroupId'],
    };
  }

  export function InventoryItemGroupWithItemsResponseToJSON(value?: InventoryItemGroupWithItemsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'children': ((value.children as Array<any>).map(InventoryItemGroupWithItemsResponseToJSON)),
              'items': ((value.items as Array<any>).map(InventoryItemResponseToJSON)),
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'itemCount': value.itemCount,
                'responsibleGroupId': value.responsibleGroupId,
    };
  }

  export function InventoryItemGroupWithItemsResponseCreateEmpty(): InventoryItemGroupWithItemsResponse {
  return {
      'children': [],
      'items': [],
      'id': 0,
      'name': '',
      'description': '',
      'itemCount': 0,
      'responsibleGroupId': 0,
  };
  }

