import {Constants} from "@Models/Constants";
import {defineStore} from "pinia";
import {filter, find, first} from "lodash-es";
import type {Location} from "@Models/events/Location";
import {useLocalStorage} from '@vueuse/core';
import {type BaseConstantsResponse, BaseConstantsResponseFromJSON, BaseConstantsResponseToJSON} from '@/api/models';
import {MainApi} from '@/api/apis';
import type {Tender} from '@Models/rvt/kpr/Tender';

interface State {
    constants: Constants;
}

export const useConstantsStore = defineStore('constants', {
    state: (): State => <State>({
        constants: {},
    }),
    actions: {
        async init() {
            const stored = useLocalStorage<string | null>('pegasus-constants-v2', null);

            const handlerCallback = (response: {data: BaseConstantsResponse}) => {
                stored.value = JSON.stringify(BaseConstantsResponseToJSON(response.data));

                this.constants = new Constants(response.data);
            };

            if (stored.value) {
                try {
                    handlerCallback({
                        data: BaseConstantsResponseFromJSON(JSON.parse(stored.value))
                    });
                } catch (err) {
                    console.error(err);
                    await MainApi.indexConstants().then(handlerCallback);
                    return;
                }

                setTimeout(() => {
                    MainApi.indexConstants().then(handlerCallback);
                }, 500);
            } else {
                await MainApi.indexConstants().then(handlerCallback);
            }
        },

        getOwner(ownerId: number) {
            const owner = this.constants.inventory.owners[ownerId];
            if (!owner) {
                throw new Error('Invalid ownerId: ' + ownerId);
            }

            return owner;
        },

        getGroup(groupId: number) {
            const group = this.constants.groups.all[groupId];
            if (!group) {
                throw new Error('Invalid groupId: ' + groupId);
            }

            return group;
        },

        getTender(tenderId: number): Tender {
            const period = find(this.constants.money.spendingPeriods, period => period.tender !== null && period.tender.id === tenderId);
            if (!period || !period.tender) {
                throw new Error('No such tender: ' + tenderId);
            }

            return period.tender;
        },

        getEnabledLocations(): Location[] {
            return filter(this.constants.events.locations, location => location.enabled);
        },

        getDefaultLocation(): Location {
            const location = first(this.getEnabledLocations());

            if (location === undefined) {
                throw new Error('Must have at least one location');
            }

            return location;
        },
    },
});
