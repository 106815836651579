<template>
    <div :class="itemGroup.id === 0 ? '' : 'ms-4'">
        <div :class="itemGroup.id === 0 ? 'inventory-itemGroup alert alert-primary' : 'inventory-itemGroup alert alert-info'">
            {{ itemGroup.name }}

            <PegasusBadge v-if="itemGroup.id !== 0" variant="secondary">{{ itemGroup.itemCount }} tétel</PegasusBadge>

            <div style="margin-left: auto">
                <PegasusButton :params="{group: itemGroup.responsibleGroup, itemGroup: itemGroup}" popover="Tételek" size="sm" to="inventory.itemGroups.show" variant="info">
                    <i class="fa fa-list"></i>
                </PegasusButton>

                <PegasusButton v-if="itemGroup.id && canEdit" popover="Szerkesztés" size="sm" variant="primary" @click="edit(itemGroup)">
                    <i class="fa fa-edit"></i>
                </PegasusButton>

                <PegasusButton v-if="canEdit" popover="Új tételcsoport" size="sm" variant="success" @click="create(itemGroup)">
                    <i class="fa fa-plus-circle"></i>
                </PegasusButton>

                <PegasusButton v-if="itemGroup.id && canEdit" popover="Törlés" size="sm" variant="danger" @click="emit('remove')">
                    <i class="fa fa-trash"></i>
                </PegasusButton>
            </div>
        </div>

        <div>
            <div v-for="child in itemGroup.children" :key="child.id" class="my-2">
                <Tree :item-group="child" @remove="remove(child)"></Tree>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {ItemGroup} from "@Models/inventory/ItemGroup";
import {useAuthStore} from "@/stores/auth";
import {BaseGroupSpecificPermission} from "@/api/models";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusBadge from "@Components/base/PegasusBadge.vue";
import {Dialog} from '@Utils/dialog';
import UpdateItemGroup from '@Components/inventory/dialogs/UpdateItemGroup.vue';
import {computed} from 'vue';

defineOptions({
    name: 'Tree',
});

const {itemGroup} = defineProps<{
    itemGroup: ItemGroup
}>();

const emit = defineEmits<{
    remove: []
}>();

const user = useAuthStore().requireUser();

const canEdit = computed(() => user.value.hasGroupPermission(itemGroup.responsibleGroup, BaseGroupSpecificPermission.MANAGE_INVENTORY));

function edit(itemGroup: ItemGroup) {
    Dialog.open(UpdateItemGroup, {itemGroup});
}

function create(parent: ItemGroup) {
    const itemGroup = ItemGroup.create(parent.responsibleGroup);

    Dialog.open(UpdateItemGroup, {itemGroup}).then(result => {
        if (result) {
            parent.children[itemGroup.id] = itemGroup;
        }
    });
}

async function remove(target: ItemGroup) {
    if (!await toast.confirm('Biztosan ki akarod törölni ezt a tételcsoportot?', 'Az összes benne lévő tétel a szülőhöz kerül')) {
        return;
    }

    let request = target.destroy().then(() => {
        delete itemGroup.children[target.id];
        for (let id in target.children) {
            itemGroup.children[id] = target.children[id];
        }

        itemGroup.itemCount += target.itemCount;
    });

    toast.loading(request, 'Törlés');
}
</script>
