/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsAvailabilityResponse } from './MentorsAvailabilityResponse';
    import {
    MentorsAvailabilityResponseFromJSON,
    MentorsAvailabilityResponseFromJSONTyped,
    MentorsAvailabilityResponseToJSON,
    MentorsAvailabilityResponseCreateEmpty,
    } from './MentorsAvailabilityResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAssignmentMonthExtendedResponse {
  availabilities: Array<MentorsAvailabilityResponse>;
  id: number;
  year: number;
  month: number;
  canEditAvailability: boolean;
}

export const MentorsAssignmentMonthExtendedResponseSchema = {
  'availabilities': {
    name: 'availabilities',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'year': {
    name: 'year',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 2000,
  },
  'month': {
    name: 'month',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
      maximum: 12,
  },
  'canEditAvailability': {
    name: 'canEditAvailability',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfMentorsAssignmentMonthExtendedResponse(value: object): value is MentorsAssignmentMonthExtendedResponse {
let isInstance = true;
    isInstance = isInstance && "availabilities" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "canEditAvailability" in value;

return isInstance;
}

export function MentorsAssignmentMonthExtendedResponseFromJSON(json: any): MentorsAssignmentMonthExtendedResponse {
return MentorsAssignmentMonthExtendedResponseFromJSONTyped(json, false);
}

export function MentorsAssignmentMonthExtendedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAssignmentMonthExtendedResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'availabilities': ((json['availabilities'] as Array<any>).map(MentorsAvailabilityResponseFromJSON)),
          'id': json['id'],
          'year': json['year'],
          'month': json['month'],
          'canEditAvailability': json['canEditAvailability'],
    };
  }

  export function MentorsAssignmentMonthExtendedResponseToJSON(value?: MentorsAssignmentMonthExtendedResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'availabilities': ((value.availabilities as Array<any>).map(MentorsAvailabilityResponseToJSON)),
                'id': value.id,
                'year': value.year,
                'month': value.month,
                'canEditAvailability': value.canEditAvailability,
    };
  }

  export function MentorsAssignmentMonthExtendedResponseCreateEmpty(): MentorsAssignmentMonthExtendedResponse {
  return {
      'availabilities': [],
      'id': 0,
      'year': 0,
      'month': 0,
      'canEditAvailability': false,
  };
  }

