import {BaseEntity} from "@Models/BaseEntity";
import {DateTime} from "@Utils/DateTime";
import {sumObject} from "@Models/Utils";
import type {KprCategoryResponse} from "@/api/api";
import {KprCategoryStatus, KprCategoryStatusValues} from "@/api/api";
import {Application} from "@Models/rvt/kpr/Application";

export type Statistics = {
    category: Category,
    priceSum: number,
    priceSumApproved: number,
    priceSumDecided: number,
    balance: number
}

export class Category extends BaseEntity {
    name: string;

    shortName: 'm' | 'k' | 'f';

    status: KprCategoryStatus;

    endDrafting: DateTime;

    endResulting: DateTime;

    budget: number;

    static parseResponse(category: Category, data: KprCategoryResponse): Category {
        category.id = data.id;
        category.name = data.name;
        category.shortName = data.shortName as 'm' | 'k' | 'f';
        category.status = data.status as KprCategoryStatus;
        category.endDrafting = data.endDrafting;
        category.endResulting = data.endResulting;
        category.budget = data.budget;

        return category;
    }

    public isInDrafting(): boolean {
        return this.status === KprCategoryStatus.DRAFTING;
    }

    get statusText(): string {
        return KprCategoryStatusValues[this.status];
    }

    get deadlineText(): string {
        return this.endDrafting.datetime();
    }

    public calculateStatistics(applications: Record<number, Application>): Statistics {
        const priceSum = sumObject(
            applications,
            application => application.getCappByShortName(this.shortName)?.calculateSum() ?? 0
        );

        const priceSumApproved = sumObject(
            applications,
            application => application.getCappByShortName(this.shortName)?.calculateSumApproved() ?? 0
        );

        const priceSumDecided = sumObject(
            applications,
            application => application.getCappByShortName(this.shortName)?.calculateSumDecided() ?? 0
        );

        return {
            category: this,
            priceSum,
            priceSumApproved,
            priceSumDecided,
            balance: priceSumDecided - this.budget,
        };
    }
}
