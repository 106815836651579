/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    BaseValidationBagResponse,
    MentorsAdminResponse,
    MentorsAssignmentMonthExtendedResponse,
    MentorsAssignmentMonthResponse,
    MentorsAssignmentMonthUpdate,
    MentorsAssignmentUpdate,
    MentorsAvailabilityIndexResponse,
    MentorsAvailabilityResponse,
    MentorsAvailabilityUpdate,
    MentorsIndexResponse,
    MentorsMentorListResponse,
    MentorsMentorResponse,
    MentorsMentorStore,
    MentorsMonthResponse,
  } from '../models/index';
    import {
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      MentorsAdminResponseFromJSON,
      MentorsAdminResponseToJSON,
      MentorsAssignmentMonthExtendedResponseFromJSON,
      MentorsAssignmentMonthExtendedResponseToJSON,
      MentorsAssignmentMonthResponseFromJSON,
      MentorsAssignmentMonthResponseToJSON,
      MentorsAssignmentMonthUpdateFromJSON,
      MentorsAssignmentMonthUpdateToJSON,
      MentorsAssignmentUpdateFromJSON,
      MentorsAssignmentUpdateToJSON,
      MentorsAvailabilityIndexResponseFromJSON,
      MentorsAvailabilityIndexResponseToJSON,
      MentorsAvailabilityResponseFromJSON,
      MentorsAvailabilityResponseToJSON,
      MentorsAvailabilityUpdateFromJSON,
      MentorsAvailabilityUpdateToJSON,
      MentorsIndexResponseFromJSON,
      MentorsIndexResponseToJSON,
      MentorsMentorListResponseFromJSON,
      MentorsMentorListResponseToJSON,
      MentorsMentorResponseFromJSON,
      MentorsMentorResponseToJSON,
      MentorsMentorStoreFromJSON,
      MentorsMentorStoreToJSON,
      MentorsMonthResponseFromJSON,
      MentorsMonthResponseToJSON,
    } from '../models/index';

      export interface MentorsAssignmentsExportRequest {
        year: number;
        month: number;
      }

      export interface MentorsAssignmentsIndexRequest {
        year: number;
        month: number;
      }

      export interface MentorsAssignmentsUpdateRequest {
        mentorsAssignmentUpdate: MentorsAssignmentUpdate;
      }

      export interface MentorsAvailabilityAssignmentMonthShowRequest {
        year: number;
        month: number;
      }

      export interface MentorsAvailabilityAssignmentMonthUpdateRequest {
        mentorsAssignmentMonthUpdate: MentorsAssignmentMonthUpdate;
      }

      export interface MentorsAvailabilityUpdateAvailabilityRequest {
        month: number;
        mentorsAvailabilityUpdate: MentorsAvailabilityUpdate;
      }

      export interface MentorsMentorsActiveRequest {
        mentor: number;
      }

      export interface MentorsMentorsInactiveRequest {
        mentor: number;
      }

      export interface MentorsMentorsLeaderRequest {
        mentor: number;
      }

      export interface MentorsMentorsStoreRequest {
        mentorsMentorStore: MentorsMentorStore;
      }

      export class MentorsApi extends runtime.BaseAPI {

      private async assignmentsExportRaw(requestParameters: MentorsAssignmentsExportRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.year === null || requestParameters.year === undefined) {
          throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling assignmentsExport.');
          }

          if (requestParameters.month === null || requestParameters.month === undefined) {
          throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling assignmentsExport.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/assignments/export/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async assignmentsExport(year: number, month: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.assignmentsExportRaw({ year: year, month: month }, options);
          return await response.value();
        }

      private async assignmentsIndexRaw(requestParameters: MentorsAssignmentsIndexRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsMonthResponse>> {
          if (requestParameters.year === null || requestParameters.year === undefined) {
          throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling assignmentsIndex.');
          }

          if (requestParameters.month === null || requestParameters.month === undefined) {
          throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling assignmentsIndex.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/assignments/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsMonthResponseFromJSON(jsonValue));
      }

        static async assignmentsIndex(year: number, month: number, options?: {form?: FormRef}): Promise<{data: MentorsMonthResponse, raw: Response}> {
          const api = new this();
          const response = await api.assignmentsIndexRaw({ year: year, month: month }, options);
          return await response.value();
        }

      private async assignmentsUpdateRaw(requestParameters: MentorsAssignmentsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsMonthResponse>> {
          if (requestParameters.mentorsAssignmentUpdate === null || requestParameters.mentorsAssignmentUpdate === undefined) {
          throw new runtime.RequiredError('mentorsAssignmentUpdate','Required parameter requestParameters.mentorsAssignmentUpdate was null or undefined when calling assignmentsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/mentors/assignments/update`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MentorsAssignmentUpdateToJSON(requestParameters.mentorsAssignmentUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsMonthResponseFromJSON(jsonValue));
      }

        static async assignmentsUpdate(mentorsAssignmentUpdate: MentorsAssignmentUpdate, options?: {form?: FormRef}): Promise<{data: MentorsMonthResponse, raw: Response}> {
          const api = new this();
          const response = await api.assignmentsUpdateRaw({ mentorsAssignmentUpdate: mentorsAssignmentUpdate }, options);
          return await response.value();
        }

      private async availabilityAdminIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsAdminResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/admin`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsAdminResponseFromJSON(jsonValue));
      }

        static async availabilityAdminIndex(options?: {form?: FormRef}): Promise<{data: MentorsAdminResponse, raw: Response}> {
          const api = new this();
          const response = await api.availabilityAdminIndexRaw(options);
          return await response.value();
        }

      private async availabilityAssignmentMonthShowRaw(requestParameters: MentorsAvailabilityAssignmentMonthShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsAssignmentMonthExtendedResponse>> {
          if (requestParameters.year === null || requestParameters.year === undefined) {
          throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling availabilityAssignmentMonthShow.');
          }

          if (requestParameters.month === null || requestParameters.month === undefined) {
          throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling availabilityAssignmentMonthShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/assignmentMonths/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsAssignmentMonthExtendedResponseFromJSON(jsonValue));
      }

        static async availabilityAssignmentMonthShow(year: number, month: number, options?: {form?: FormRef}): Promise<{data: MentorsAssignmentMonthExtendedResponse, raw: Response}> {
          const api = new this();
          const response = await api.availabilityAssignmentMonthShowRaw({ year: year, month: month }, options);
          return await response.value();
        }

      private async availabilityAssignmentMonthUpdateRaw(requestParameters: MentorsAvailabilityAssignmentMonthUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsAssignmentMonthResponse>> {
          if (requestParameters.mentorsAssignmentMonthUpdate === null || requestParameters.mentorsAssignmentMonthUpdate === undefined) {
          throw new runtime.RequiredError('mentorsAssignmentMonthUpdate','Required parameter requestParameters.mentorsAssignmentMonthUpdate was null or undefined when calling availabilityAssignmentMonthUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/mentors/assignmentMonths`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MentorsAssignmentMonthUpdateToJSON(requestParameters.mentorsAssignmentMonthUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsAssignmentMonthResponseFromJSON(jsonValue));
      }

        static async availabilityAssignmentMonthUpdate(mentorsAssignmentMonthUpdate: MentorsAssignmentMonthUpdate, options?: {form?: FormRef}): Promise<{data: MentorsAssignmentMonthResponse, raw: Response}> {
          const api = new this();
          const response = await api.availabilityAssignmentMonthUpdateRaw({ mentorsAssignmentMonthUpdate: mentorsAssignmentMonthUpdate }, options);
          return await response.value();
        }

      private async availabilityMentorIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsAvailabilityIndexResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/availability`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsAvailabilityIndexResponseFromJSON(jsonValue));
      }

        static async availabilityMentorIndex(options?: {form?: FormRef}): Promise<{data: MentorsAvailabilityIndexResponse, raw: Response}> {
          const api = new this();
          const response = await api.availabilityMentorIndexRaw(options);
          return await response.value();
        }

      private async availabilityUpdateAvailabilityRaw(requestParameters: MentorsAvailabilityUpdateAvailabilityRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsAvailabilityResponse>> {
          if (requestParameters.month === null || requestParameters.month === undefined) {
          throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling availabilityUpdateAvailability.');
          }

          if (requestParameters.mentorsAvailabilityUpdate === null || requestParameters.mentorsAvailabilityUpdate === undefined) {
          throw new runtime.RequiredError('mentorsAvailabilityUpdate','Required parameter requestParameters.mentorsAvailabilityUpdate was null or undefined when calling availabilityUpdateAvailability.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/mentors/availability/{month}`.replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MentorsAvailabilityUpdateToJSON(requestParameters.mentorsAvailabilityUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsAvailabilityResponseFromJSON(jsonValue));
      }

        static async availabilityUpdateAvailability(month: number, mentorsAvailabilityUpdate: MentorsAvailabilityUpdate, options?: {form?: FormRef}): Promise<{data: MentorsAvailabilityResponse, raw: Response}> {
          const api = new this();
          const response = await api.availabilityUpdateAvailabilityRaw({ month: month, mentorsAvailabilityUpdate: mentorsAvailabilityUpdate }, options);
          return await response.value();
        }

      private async indexIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsIndexResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/index`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsIndexResponseFromJSON(jsonValue));
      }

        static async indexIndex(options?: {form?: FormRef}): Promise<{data: MentorsIndexResponse, raw: Response}> {
          const api = new this();
          const response = await api.indexIndexRaw(options);
          return await response.value();
        }

      private async mentorsActiveRaw(requestParameters: MentorsMentorsActiveRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsMentorResponse>> {
          if (requestParameters.mentor === null || requestParameters.mentor === undefined) {
          throw new runtime.RequiredError('mentor','Required parameter requestParameters.mentor was null or undefined when calling mentorsActive.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/mentors/{mentor}/active`.replace(`{${"mentor"}}`, encodeURIComponent(String(requestParameters.mentor))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsMentorResponseFromJSON(jsonValue));
      }

        static async mentorsActive(mentor: number, options?: {form?: FormRef}): Promise<{data: MentorsMentorResponse, raw: Response}> {
          const api = new this();
          const response = await api.mentorsActiveRaw({ mentor: mentor }, options);
          return await response.value();
        }

      private async mentorsInactivatedRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsMentorListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/mentors/inactivated`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsMentorListResponseFromJSON(jsonValue));
      }

        static async mentorsInactivated(options?: {form?: FormRef}): Promise<{data: MentorsMentorListResponse, raw: Response}> {
          const api = new this();
          const response = await api.mentorsInactivatedRaw(options);
          return await response.value();
        }

      private async mentorsInactiveRaw(requestParameters: MentorsMentorsInactiveRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsMentorResponse>> {
          if (requestParameters.mentor === null || requestParameters.mentor === undefined) {
          throw new runtime.RequiredError('mentor','Required parameter requestParameters.mentor was null or undefined when calling mentorsInactive.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/mentors/{mentor}/inactive`.replace(`{${"mentor"}}`, encodeURIComponent(String(requestParameters.mentor))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsMentorResponseFromJSON(jsonValue));
      }

        static async mentorsInactive(mentor: number, options?: {form?: FormRef}): Promise<{data: MentorsMentorResponse, raw: Response}> {
          const api = new this();
          const response = await api.mentorsInactiveRaw({ mentor: mentor }, options);
          return await response.value();
        }

      private async mentorsIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsMentorListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/mentors`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsMentorListResponseFromJSON(jsonValue));
      }

        static async mentorsIndex(options?: {form?: FormRef}): Promise<{data: MentorsMentorListResponse, raw: Response}> {
          const api = new this();
          const response = await api.mentorsIndexRaw(options);
          return await response.value();
        }

      private async mentorsLeaderRaw(requestParameters: MentorsMentorsLeaderRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsMentorResponse>> {
          if (requestParameters.mentor === null || requestParameters.mentor === undefined) {
          throw new runtime.RequiredError('mentor','Required parameter requestParameters.mentor was null or undefined when calling mentorsLeader.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/mentors/mentors/{mentor}/leader`.replace(`{${"mentor"}}`, encodeURIComponent(String(requestParameters.mentor))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsMentorResponseFromJSON(jsonValue));
      }

        static async mentorsLeader(mentor: number, options?: {form?: FormRef}): Promise<{data: MentorsMentorResponse, raw: Response}> {
          const api = new this();
          const response = await api.mentorsLeaderRaw({ mentor: mentor }, options);
          return await response.value();
        }

      private async mentorsStoreRaw(requestParameters: MentorsMentorsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MentorsMentorResponse>> {
          if (requestParameters.mentorsMentorStore === null || requestParameters.mentorsMentorStore === undefined) {
          throw new runtime.RequiredError('mentorsMentorStore','Required parameter requestParameters.mentorsMentorStore was null or undefined when calling mentorsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/mentors/mentors`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MentorsMentorStoreToJSON(requestParameters.mentorsMentorStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MentorsMentorResponseFromJSON(jsonValue));
      }

        static async mentorsStore(mentorsMentorStore: MentorsMentorStore, options?: {form?: FormRef}): Promise<{data: MentorsMentorResponse, raw: Response}> {
          const api = new this();
          const response = await api.mentorsStoreRaw({ mentorsMentorStore: mentorsMentorStore }, options);
          return await response.value();
        }

    }
