/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupSummaryEventsResponse {
  drafting: number;
  waiting: number;
  future: number;
}

export const BaseGroupSummaryEventsResponseSchema = {
  'drafting': {
    name: 'drafting',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'waiting': {
    name: 'waiting',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'future': {
    name: 'future',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfBaseGroupSummaryEventsResponse(value: object): value is BaseGroupSummaryEventsResponse {
let isInstance = true;
    isInstance = isInstance && "drafting" in value;
    isInstance = isInstance && "waiting" in value;
    isInstance = isInstance && "future" in value;

return isInstance;
}

export function BaseGroupSummaryEventsResponseFromJSON(json: any): BaseGroupSummaryEventsResponse {
return BaseGroupSummaryEventsResponseFromJSONTyped(json, false);
}

export function BaseGroupSummaryEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupSummaryEventsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'drafting': json['drafting'],
          'waiting': json['waiting'],
          'future': json['future'],
    };
  }

  export function BaseGroupSummaryEventsResponseToJSON(value?: BaseGroupSummaryEventsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'drafting': value.drafting,
                'waiting': value.waiting,
                'future': value.future,
    };
  }

  export function BaseGroupSummaryEventsResponseCreateEmpty(): BaseGroupSummaryEventsResponse {
  return {
      'drafting': 0,
      'waiting': 0,
      'future': 0,
  };
  }

