<template>
    <PegasusModal header="Dokumentum feltöltése">
        <div v-if="permissionGranted">
            <dt>Kamera kiválasztása</dt>

            <Dropdown
                v-model="currentCamera"
                :options="videoInputs"
                option-label="label"
                option-value="deviceId"
            ></Dropdown>
        </div>

        <span v-else>Kamera inicializálás folyamatban, lehetséges hogy a böngésző engedélyt fog kérni.</span>

        <hr>

        <video v-show="!currentImage" ref="videoTag" autoplay class="camera-video"></video>
        <PegasusImage v-if="currentImage" :src="currentImage" image-class="img-fluid" preview></PegasusImage>

        <hr>

        <div class="text-center">
            <PegasusButton v-if="!currentImage" variant="success" @click="snapshot">
                <i class="fa fa-camera"></i> Fénykép készítés
            </PegasusButton>
            <PegasusButton v-else variant="warning" @click="currentImage = null">
                <i class="fa camera-rotate"></i> Új kép
            </PegasusButton>
        </div>

        <hr>

        <template v-if="!currentImage">
            <FileField
                v-model="uploadFile"
                :schema="InventoryDocumentStoreRequestSchema.file"
                drop-placeholder="Ide húzható a fájl"
                placeholder="Fájl kiválasztása"
            ></FileField>

            <hr>
        </template>

        <PegasusForm v-if="currentImage" ref="form">
            <TextField v-model="newDocument.name" :schema="InventoryDocumentStoreRequestSchema.name"></TextField>
            <TextField v-model="newDocument.comment" :schema="InventoryDocumentStoreRequestSchema.comment"></TextField>

            <PegasusButton variant="primary" @click="save">
                Feltöltés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {Document} from "@Models/inventory/Document";
import {File} from "@Models/File";
import TextField from "@Components/base/form/types/TextField.vue";
import {computed, onUnmounted, ref, watch, watchEffect} from "vue";
import type {InventoryDocumentType} from "@/api/models";
import {InventoryDocumentStoreRequestSchema} from '@/api/models';
import {useDevicesList, useUserMedia} from "@vueuse/core";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusImage from "@Components/base/PegasusImage.vue";
import Dropdown from "primevue/dropdown";
import PegasusModal from "@Components/base/PegasusModal.vue";
import toast from "@Utils/toast";
import {first, size} from "lodash-es";
import type {FormRef} from "@/vue";
import FileField from "@Components/base/form/types/FileField.vue";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';

const {item, documentType} = defineProps<{
    item: Item,
    documentType: InventoryDocumentType
}>();

const returnValue = useReturn();

const videoTag = ref<HTMLVideoElement | null>(null);

const newDocument = ref(new Document());

const currentImage = ref<string | null>(null);

const uploadFile = ref<File | null>(null);

const form = ref<FormRef>(null);

const currentCamera = ref<string | null>(null);

const videoTrack = computed(() => media.stream.value?.getVideoTracks().at(0) ?? null);

const {videoInputs: facingInputs, permissionGranted, ensurePermissions} = useDevicesList({
    requestPermissions: true,
    constraints: {
        video: {
            facingMode: {
                ideal: 'user',
            },
        },
        audio: false,
    },
});

ensurePermissions();

const {videoInputs} = useDevicesList({
    requestPermissions: true,
    constraints: {
        video: true,
        audio: false,
    },
});

const media = useUserMedia({
    enabled: true,
    constraints: {
        video: {
            // eslint-disable-next-line vue/no-ref-object-reactivity-loss
            deviceId: currentCamera.value ?? undefined,
        },
    },
    autoSwitch: true,
});

watchEffect(() => {
    const firstInput = first(facingInputs.value);

    if (firstInput) {
        currentCamera.value = firstInput.deviceId;
        return;
    }

    const secondInput = first(videoInputs.value);
    if (secondInput) {
        currentCamera.value = secondInput.deviceId;
        return;
    }

    currentCamera.value = null;
});

watchEffect(() => {
    if (currentCamera.value && media.constraints.value) {
        media.constraints.value.video = {
            deviceId: currentCamera.value,
        };
    } else {
        stop();
    }
});

watch(media.stream, newValue => {
    if (videoTag.value === null) {
        return;
    }

    if (newValue) {
        videoTag.value.srcObject = newValue ?? null;
    }
});

onUnmounted(() => {
    currentCamera.value = null;
    media.stop();
});

function snapshot() {
    if (videoTag.value === null) {
        throw new Error('VideoTag is not populated');
    }

    if (videoTrack.value === null) {
        throw new Error('Trying to snapshot without video');
    }

    let canvas = document.createElement('canvas');

    canvas.width = Math.min(videoTrack.value.getSettings().width ?? 800, 800);
    canvas.height = Math.min(videoTrack.value.getSettings().height ?? 600, 600);

    let ctx = canvas.getContext('2d');

    if (ctx === null) {
        throw new Error('Cannot create canvas context');
    }

    ctx.drawImage(videoTag.value, 0, 0, canvas.width, canvas.height);

    currentImage.value = canvas.toDataURL('image/jpeg');

    newDocument.value.name = 'Kép ' + (size(item.documents) + 1);
}

function save() {
    if (currentImage.value === null) {
        throw new Error('Trying to save while having no picture');
    }

    newDocument.value.type = documentType;

    let file = uploadFile.value ?? File.createFromDataUri(currentImage.value, 'image.jpeg');

    let request = newDocument.value.store(item.id, file, form.value).then(() => {
        item.documents[newDocument.value.id] = newDocument.value;

        returnValue(true);
    });

    toast.loading(request);
}

watch(uploadFile, newValue => {
    if (newValue === null) {
        return;
    }

    newValue.getAsDataUrl().then(data => {
        currentImage.value = data;
    });
});
</script>
