<template>
    <SmartField :inline="inline" :label="label" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div>
                <Dropdown
                    v-model="modelValue"

                    :class="{'p-invalid': isValid === false}"
                    :disabled="disabled"
                    :input-id="id"
                    :options="options"
                    :pt="{input: {'data-input-name': schema.name}}"
                    append-to="self"
                    class="w-100"
                    option-label="label"
                    option-value="value"
                    @change="emit('change')"
                ></Dropdown>
                <span v-if="current && current.warning" class="p-1 text-danger">
                    <i class="fa fa-exclamation-circle"></i>
                    {{ current.warning }}
                </span>
            </div>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>

        <template #description="slotProps">
            <slot name="description" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script generic="T" lang="ts" setup>
import SmartField from "@Components/base/form/SmartField.vue";
import {type FieldSchema} from "@Components/base/form/FieldProperties";
import Dropdown from "primevue/dropdown";
import {computed} from 'vue';
import {find} from 'lodash-es';

const emit = defineEmits<{
    change: []
}>();

const modelValue = defineModel<T>({required: true});

const current = computed(() => {
    return find(options, option => option.value == modelValue.value);
});

const {options, label, disabled, withoutGroup, schema, inline} = defineProps<{
    options: {value: T, label: string, warning?: string | undefined}[];
    label?: string;
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();
</script>
