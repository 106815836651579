/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtClaimApprove {
  result: boolean;
}

export const RvtClaimApproveSchema = {
  'result': {
    name: 'result',
    required: true,
    nullable: false,
    label: 'Jóváhagyás eredménye',
    type: 'boolean',
  },
}
export function instanceOfRvtClaimApprove(value: object): value is RvtClaimApprove {
let isInstance = true;
    isInstance = isInstance && "result" in value;

return isInstance;
}

export function RvtClaimApproveFromJSON(json: any): RvtClaimApprove {
return RvtClaimApproveFromJSONTyped(json, false);
}

export function RvtClaimApproveFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtClaimApprove {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'result': json['result'],
    };
  }

  export function RvtClaimApproveToJSON(value?: RvtClaimApprove | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'result': value.result,
    };
  }

  export function RvtClaimApproveCreateEmpty(): RvtClaimApprove {
  return {
      'result': false,
  };
  }

