<template>
    <PegasusModal :header="statement.displayName" width="700px">
        <PegasusForm ref="form">
            <TextareaField
                v-model="text"
                :disabled="!canEdit"
                :schema="RvtStatementStoreSchema.text"
            ></TextareaField>

            <PegasusButton variant="success" @click="save(statement)">
                Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {RvtStatementStoreSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {GroupStatement} from '@Models/rvt/statements/GroupStatement';
import toast from '@Utils/toast';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import {useReturn} from '@Utils/dialog';

const {statement, canEdit} = defineProps<{
    statement: GroupStatement,
    canEdit: boolean
}>();

const form = ref<FormRef>(null);

const returnValue = useReturn();

const text = ref(statement.text);

function save(statement: GroupStatement) {
    if (!statement.id) {
        statement.text = text.value;
    }

    const request = statement.id
        ? statement.update(form.value, text.value).then(() => returnValue(false))
        : statement.store(form.value).then(() => returnValue(true));

    toast.loading(request);
}
</script>
