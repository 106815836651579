<template>
    <PegasusModal header="Új mentor">
        <PegasusForm ref="form">
            <UserSelectorField v-model="mentor.user" :schema="MentorsMentorStoreSchema.user"></UserSelectorField>

            <PegasusButton variant="success" @click="save">
                <i class="fas fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {MentorsMentorStoreSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import UserSelectorField from '@Components/base/form/types/custom/UserSelectorField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import toast from '@Utils/toast';
import {Mentor} from '@Models/mentors/Mentor';
import {useReturn} from '@Utils/dialog';

const {mentor} = defineProps<{
    mentor: Mentor
}>();

const form = ref<FormRef>(null);

const returnValue = useReturn();

function save() {
    if (!mentor.user) {
        toast.error('Felhasználó kiválasztása kötelező');
        return;
    }

    let request = mentor.store(form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}
</script>
