/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { EventsTrainingResponse } from './EventsTrainingResponse';
    import {
    EventsTrainingResponseFromJSON,
    EventsTrainingResponseFromJSONTyped,
    EventsTrainingResponseToJSON,
    EventsTrainingResponseCreateEmpty,
    } from './EventsTrainingResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsTrainingParticipationResponse {
  id: number;
  training: EventsTrainingResponse;
  user: BaseMinimalUserResponse;
  active: boolean;
  quizResult: number | null;
  createdAt: DateTime;
}

export const EventsTrainingParticipationResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'training': {
    name: 'training',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'quizResult': {
    name: 'quizResult',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfEventsTrainingParticipationResponse(value: object): value is EventsTrainingParticipationResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "training" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "quizResult" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function EventsTrainingParticipationResponseFromJSON(json: any): EventsTrainingParticipationResponse {
return EventsTrainingParticipationResponseFromJSONTyped(json, false);
}

export function EventsTrainingParticipationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsTrainingParticipationResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'training': EventsTrainingResponseFromJSON(json['training']),
              'user': BaseMinimalUserResponseFromJSON(json['user']),
          'active': json['active'],
          'quizResult': json['quizResult'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function EventsTrainingParticipationResponseToJSON(value?: EventsTrainingParticipationResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'training': EventsTrainingResponseToJSON(value.training),
                'user': BaseMinimalUserResponseToJSON(value.user),
                'active': value.active,
                'quizResult': value.quizResult,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function EventsTrainingParticipationResponseCreateEmpty(): EventsTrainingParticipationResponse {
  return {
      'id': 0,
          'training': EventsTrainingResponseCreateEmpty(),
          'user': BaseMinimalUserResponseCreateEmpty(),
      'active': false,
      'quizResult': 0,
      'createdAt': DateTime.now(),
  };
  }

