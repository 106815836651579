/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsExtraTableData {
  tables: number;
  chairs: number;
}

export const EventsExtraTableDataSchema = {
  'tables': {
    name: 'tables',
    required: true,
    nullable: false,
    label: 'Asztalok száma',
    type: 'number',
      minimum: 0,
      maximum: 50,
  },
  'chairs': {
    name: 'chairs',
    required: true,
    nullable: false,
    label: 'Székek száma',
    type: 'number',
      minimum: 0,
      maximum: 100,
  },
}
export function instanceOfEventsExtraTableData(value: object): value is EventsExtraTableData {
let isInstance = true;
    isInstance = isInstance && "tables" in value;
    isInstance = isInstance && "chairs" in value;

return isInstance;
}

export function EventsExtraTableDataFromJSON(json: any): EventsExtraTableData {
return EventsExtraTableDataFromJSONTyped(json, false);
}

export function EventsExtraTableDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsExtraTableData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'tables': json['tables'],
          'chairs': json['chairs'],
    };
  }

  export function EventsExtraTableDataToJSON(value?: EventsExtraTableData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tables': value.tables,
                'chairs': value.chairs,
    };
  }

  export function EventsExtraTableDataCreateEmpty(): EventsExtraTableData {
  return {
      'tables': 0,
      'chairs': 0,
  };
  }

