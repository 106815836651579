<template>
    <Dialog
        v-model:visible="visible"
        :draggable="false"
        :header="header"
        :style="{ width: width, overflow: 'auto' }"
        :dismissable-mask="dismiss"
        modal
        block-scroll
        @show="emit('visible')"
        @after-hide="hidden"
    >
        <slot></slot>
    </Dialog>
</template>

<script lang="ts" setup>
import {inject, nextTick, ref} from "vue";
import Dialog from "primevue/dialog";
import {PegasusDialogPromiseSymbol} from '@Utils/dialog';

const {header, width = '500px', dismiss = true} = defineProps<{
    header: string,
    width?: string,
    dismiss?: boolean
}>();

const pegasusPromise = inject(PegasusDialogPromiseSymbol, null);

const emit = defineEmits<{
    hidden: [],
    visible: []
}>();

const visible = ref(true);

function hidden() {
    emit('hidden');
    nextTick(() => {
        if (pegasusPromise) {
            pegasusPromise(null);
        }
    });
}
</script>
