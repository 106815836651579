/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyExtendedBudgetCategoryResponse } from './MoneyExtendedBudgetCategoryResponse';
    import {
    MoneyExtendedBudgetCategoryResponseFromJSON,
    MoneyExtendedBudgetCategoryResponseFromJSONTyped,
    MoneyExtendedBudgetCategoryResponseToJSON,
    MoneyExtendedBudgetCategoryResponseCreateEmpty,
    } from './MoneyExtendedBudgetCategoryResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetCategoryListResponse {
  categories: Array<MoneyExtendedBudgetCategoryResponse>;
}

export const MoneyBudgetCategoryListResponseSchema = {
  'categories': {
    name: 'categories',
    required: true,
    nullable: false,
    label: 'Leírás',
      type: 'array',
  },
}
export function instanceOfMoneyBudgetCategoryListResponse(value: object): value is MoneyBudgetCategoryListResponse {
let isInstance = true;
    isInstance = isInstance && "categories" in value;

return isInstance;
}

export function MoneyBudgetCategoryListResponseFromJSON(json: any): MoneyBudgetCategoryListResponse {
return MoneyBudgetCategoryListResponseFromJSONTyped(json, false);
}

export function MoneyBudgetCategoryListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetCategoryListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'categories': ((json['categories'] as Array<any>).map(MoneyExtendedBudgetCategoryResponseFromJSON)),
    };
  }

  export function MoneyBudgetCategoryListResponseToJSON(value?: MoneyBudgetCategoryListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'categories': ((value.categories as Array<any>).map(MoneyExtendedBudgetCategoryResponseToJSON)),
    };
  }

  export function MoneyBudgetCategoryListResponseCreateEmpty(): MoneyBudgetCategoryListResponse {
  return {
      'categories': [],
  };
  }

