<template>
    <PegasusHeading>Schönherz Rendezvénynaptár</PegasusHeading>

    <div class="text-center">
        <iframe
            class="mt-4"
            :src="'https://calendar.google.com/calendar/embed?' + params.toString()"
            style="border: 0"
            width="800"
            height="600"
        ></iframe>
    </div>
</template>

<title>Schönherz Rendezvénynaptár</title>

<script lang="ts" setup>
import {useConstantsStore} from "@/stores/constants";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const calendarUrl = useConstantsStore().constants.events.calendar;

const params = new URLSearchParams({
    src: calendarUrl,
    ctz: 'Europe/Budapest',
    wkst: '2',
    hl: 'hu',
    showCalendars: '0',
    showTitle: '0',
    showTabs: '0',
    showPrint: '0',
    bgcolor: '#f4f6f9',
});
</script>
