/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryCreationSource } from './InventoryCreationSource';
    import {
    InventoryCreationSourceFromJSON,
    InventoryCreationSourceFromJSONTyped,
    InventoryCreationSourceToJSON,
    InventoryCreationSourceCreateEmpty,
    } from './InventoryCreationSource';
import {DateTime} from '@Utils/DateTime';
import { InventoryCreationSourceValues } from './InventoryCreationSource';

export interface InventoryUpdateCreation {
  source: InventoryCreationSource;
  sourceItems: Array<number> | null;
}

export const InventoryUpdateCreationSchema = {
  'source': {
    name: 'source',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Forrás",
        enumValues: InventoryCreationSourceValues,
  },
  'sourceItems': {
    name: 'sourceItems',
    required: true,
    nullable: true,
    label: 'Forrás tételek',
    type: 'array',
  },
}
export function instanceOfInventoryUpdateCreation(value: object): value is InventoryUpdateCreation {
let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "sourceItems" in value;

return isInstance;
}

export function InventoryUpdateCreationFromJSON(json: any): InventoryUpdateCreation {
return InventoryUpdateCreationFromJSONTyped(json, false);
}

export function InventoryUpdateCreationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateCreation {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'source': InventoryCreationSourceFromJSON(json['source']),
          'sourceItems': json['sourceItems'],
    };
  }

  export function InventoryUpdateCreationToJSON(value?: InventoryUpdateCreation | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'source': InventoryCreationSourceToJSON(value.source),
            'sourceItems': value.sourceItems,
    };
  }

  export function InventoryUpdateCreationCreateEmpty(): InventoryUpdateCreation {
  return {
          'source': InventoryCreationSourceCreateEmpty(),
      'sourceItems': [],
  };
  }

