<template>
    <PegasusModal header="Rendezvény visszamondása">
        <PegasusForm ref="form">
            <TextareaField
                v-model="comment"
                :schema="EventsMilestoneAddSchema.comment"
            ></TextareaField>

            <PegasusButton variant="danger" @click="doCancel">
                Visszamondás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {EventsMilestoneAddSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import Event from '@Models/events/Event';
import type {FormRef} from '@/vue';
import toast from '@Utils/toast';
import {redirect} from '@/router';
import {ref} from "vue";

const {event} = defineProps<{
    event: Event
}>();

const comment = ref('');

const form = ref<FormRef>(null);

async function doCancel() {
    if (!await toast.confirm('Biztos vagy benne hogy vissza akarod mondani a rendezvényedet?')) {
        return;
    }

    if (!await toast.confirm('Tényleg biztos vagy benne vissza akarod mondani a rendezvényedet?')) {
        return;
    }

    let request = event.cancel(comment.value, form.value).then(() => {
        setTimeout(() => {
            redirect({
                name: 'events.index',
            });
        }, 1000);
    });

    toast.loading(request, 'Törlés');
}
</script>
