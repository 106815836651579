/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupSpecificPermission } from './BaseGroupSpecificPermission';
    import {
    BaseGroupSpecificPermissionFromJSON,
    BaseGroupSpecificPermissionFromJSONTyped,
    BaseGroupSpecificPermissionToJSON,
    BaseGroupSpecificPermissionCreateEmpty,
    } from './BaseGroupSpecificPermission';
import {DateTime} from '@Utils/DateTime';
import { BaseGroupSpecificPermissionValues } from './BaseGroupSpecificPermission';

export interface BaseGroupPermissionStore {
  group: number;
  groupPost: number;
  groupSpecificPermission: BaseGroupSpecificPermission;
  inherit: boolean;
}

export const BaseGroupPermissionStoreSchema = {
  'group': {
    name: 'group',
    required: true,
    nullable: false,
    label: 'Kör',
    type: 'number',
      minimum: 0,
  },
  'groupPost': {
    name: 'groupPost',
    required: true,
    nullable: false,
    label: 'Poszt',
    type: 'number',
      minimum: 0,
  },
  'groupSpecificPermission': {
    name: 'groupSpecificPermission',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: BaseGroupSpecificPermissionValues,
  },
  'inherit': {
    name: 'inherit',
    required: true,
    nullable: false,
    label: 'Öröklődő',
    type: 'boolean',
  },
}
export function instanceOfBaseGroupPermissionStore(value: object): value is BaseGroupPermissionStore {
let isInstance = true;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "groupPost" in value;
    isInstance = isInstance && "groupSpecificPermission" in value;
    isInstance = isInstance && "inherit" in value;

return isInstance;
}

export function BaseGroupPermissionStoreFromJSON(json: any): BaseGroupPermissionStore {
return BaseGroupPermissionStoreFromJSONTyped(json, false);
}

export function BaseGroupPermissionStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupPermissionStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'group': json['group'],
          'groupPost': json['groupPost'],
              'groupSpecificPermission': BaseGroupSpecificPermissionFromJSON(json['groupSpecificPermission']),
          'inherit': json['inherit'],
    };
  }

  export function BaseGroupPermissionStoreToJSON(value?: BaseGroupPermissionStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'group': value.group,
                'groupPost': value.groupPost,
                'groupSpecificPermission': BaseGroupSpecificPermissionToJSON(value.groupSpecificPermission),
                'inherit': value.inherit,
    };
  }

  export function BaseGroupPermissionStoreCreateEmpty(): BaseGroupPermissionStore {
  return {
      'group': 0,
      'groupPost': 0,
          'groupSpecificPermission': BaseGroupSpecificPermissionCreateEmpty(),
      'inherit': false,
  };
  }

