/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseAuthCallback {
  code: string;
  redirectUrl: string;
}

export const BaseAuthCallbackSchema = {
  'code': {
    name: 'code',
    required: true,
    nullable: false,
    label: 'Authsch kód',
    type: 'string',
  },
  'redirectUrl': {
    name: 'redirectUrl',
    required: true,
    nullable: false,
    label: 'Authsch redirect URL',
    type: 'string',
  },
}
export function instanceOfBaseAuthCallback(value: object): value is BaseAuthCallback {
let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "redirectUrl" in value;

return isInstance;
}

export function BaseAuthCallbackFromJSON(json: any): BaseAuthCallback {
return BaseAuthCallbackFromJSONTyped(json, false);
}

export function BaseAuthCallbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAuthCallback {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'code': json['code'],
          'redirectUrl': json['redirectUrl'],
    };
  }

  export function BaseAuthCallbackToJSON(value?: BaseAuthCallback | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'code': value.code,
                'redirectUrl': value.redirectUrl,
    };
  }

  export function BaseAuthCallbackCreateEmpty(): BaseAuthCallback {
  return {
      'code': '',
      'redirectUrl': '',
  };
  }

