<template>
    <PegasusModal header="Elbírálatlan tételek">
        <div v-if="data">
            <div v-if="isEmpty(data)">
                Minden tétel el van bírálva
            </div>

            <div v-for="app in data" :key="app.id">
                <b>{{ app.group.name }}</b>:
                <div v-for="capp in app.categories" :key="capp.id" class="ms-2">
                    <span v-if="!isEmpty(capp.getUnapprovedItems())">
                        <PegasusLink :params="{application: app.id, category: capp.category.shortName}" size="xs" to="rvt.kpr.edit">
                            {{ capp.category.name }}
                        </PegasusLink>
                    </span>
                </div>
            </div>
        </div>

        <div v-else>
            Betöltés folyamatban
        </div>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import {KprApi} from '@/api/apis';
import {ref} from 'vue';
import {Application} from '@Models/rvt/kpr/Application';
import {isEmpty} from "lodash-es";
import PegasusLink from '@Components/base/PegasusLink.vue';

const {tenderId} = defineProps<{
    tenderId: number
}>();

const data = ref<Application[] | null>(null);

KprApi.statisticsUnApprovedItems(tenderId).then(response => {
    data.value = Application.newArray(response.data.applications, Application.parseWithItems);
});
</script>
