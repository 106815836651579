<template>
    <div class="row">
        <div class="col-lg-6">
            <b>FNT igények</b>
        </div>

        <div class="col-lg-6">
            <CheckboxField
                v-model="data.robot"
                :disabled="!service.canEdit"
                :schema="EventsAcDataSchema.robot"
            />

            <CheckboxField
                v-model="data.tv"
                :disabled="!service.canEdit"
                :schema="EventsAcDataSchema.tv"
                label-warning="HDMI-vel ellátott lejátszó eszközt a szervezőknek kell biztosítania!"
            ></CheckboxField>

            <CheckboxField
                v-model="data.projector"
                :disabled="!service.canEdit"
                :schema="EventsAcDataSchema.projector"
                label-warning="HDMI-vel ellátott lejátszó eszközt a szervezőknek kell biztosítania!"
            ></CheckboxField>

            <CheckboxField
                v-model="data.mobileTV"
                :disabled="!service.canEdit"
                :schema="EventsAcDataSchema.mobileTV"
                label-warning="HDMI-vel ellátott lejátszó eszközt a szervezőknek kell biztosítania!"
            ></CheckboxField>
        </div>
    </div>

    <SelectField
        v-model="data.source"
        :disabled="!service.canEdit"
        :options="dictEnum(EventsAcSourceValues)"
        :schema="EventsAcDataSchema.source"
        inline
    ></SelectField>

    <NumberField
        v-model="data.mic"
        :disabled="!service.canEdit"
        :schema="EventsAcDataSchema.mic"
        :step="1"
        inline
        number-suffix="db"
    ></NumberField>

    <NumberField
        v-model="data.micWireless"
        :disabled="!service.canEdit"
        :schema="EventsAcDataSchema.micWireless"
        :step="1"
        inline
        number-suffix="db"
    ></NumberField>

    <small class="d-block">
        <i class="fas fa-exclamation-circle text-danger"></i>
        A fentiektől eltérő igény (élőzene, külső rendezvény, fénytechnika, stb.)
        esetén egyeztetés az AC-val kötelező az alábbi címen:
        <a href="mailto:ac-info@simonyi.bme.hu">ac-info@simonyi.bme.hu</a>
    </small>
</template>

<script lang="ts" setup>
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import NumberField from "@Components/base/form/types/NumberField.vue";
import type {EventsAcData} from "@/api/models";
import {EventsAcDataSchema, EventsAcSourceValues} from "@/api/models";
import {dictEnum} from '@Models/Utils';
import {Service} from '@Models/events/Service';

const data = defineModel<EventsAcData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
