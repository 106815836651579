import {BaseEntity} from "@Models/BaseEntity";
import type {InventoryOwnerResponse} from "@/api/api";
import {useConstantsStore} from "@/stores/constants";

export class Owner extends BaseEntity {
    name: string;

    description: string;

    static getDefault(): Owner {
        const defaultOwner = Owner.getBySingleId(useConstantsStore().constants.inventory.defaults.owner);

        if (defaultOwner === null) {
            throw new Error('Invalid default');
        }

        return defaultOwner;
    }

    static getBySingleId(id: number): Owner | null {
        return useConstantsStore().constants.inventory.owners[id];
    }

    static create(): Owner {
        return new Owner();
    }

    static parseResponse(owner: Owner, data: InventoryOwnerResponse): Owner {
        owner.id = data.id;
        owner.name = data.name;
        owner.description = data.description;

        return owner;
    }
}
