/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprApplicationWithItemsResponse } from './KprApplicationWithItemsResponse';
    import {
    KprApplicationWithItemsResponseFromJSON,
    KprApplicationWithItemsResponseFromJSONTyped,
    KprApplicationWithItemsResponseToJSON,
    KprApplicationWithItemsResponseCreateEmpty,
    } from './KprApplicationWithItemsResponse';
import {DateTime} from '@Utils/DateTime';

export interface KprApplicationsWithItemsResponse {
  applications: Array<KprApplicationWithItemsResponse>;
}

export const KprApplicationsWithItemsResponseSchema = {
  'applications': {
    name: 'applications',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfKprApplicationsWithItemsResponse(value: object): value is KprApplicationsWithItemsResponse {
let isInstance = true;
    isInstance = isInstance && "applications" in value;

return isInstance;
}

export function KprApplicationsWithItemsResponseFromJSON(json: any): KprApplicationsWithItemsResponse {
return KprApplicationsWithItemsResponseFromJSONTyped(json, false);
}

export function KprApplicationsWithItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprApplicationsWithItemsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'applications': ((json['applications'] as Array<any>).map(KprApplicationWithItemsResponseFromJSON)),
    };
  }

  export function KprApplicationsWithItemsResponseToJSON(value?: KprApplicationsWithItemsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'applications': ((value.applications as Array<any>).map(KprApplicationWithItemsResponseToJSON)),
    };
  }

  export function KprApplicationsWithItemsResponseCreateEmpty(): KprApplicationsWithItemsResponse {
  return {
      'applications': [],
  };
  }

