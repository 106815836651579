/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoResultCategoryStore {
  tender: number;
  name: string;
  boundary: number;
  money: number;
}

export const VikoResultCategoryStoreSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
    label: 'Pályázat',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      maxLength: 50,
  },
  'boundary': {
    name: 'boundary',
    required: true,
    nullable: false,
    label: 'Határ',
    type: 'number',
      minimum: 1,
      maximum: 5,
  },
  'money': {
    name: 'money',
    required: true,
    nullable: false,
    label: 'Havi összeg',
    type: 'number',
      minimum: 0,
      maximum: 5000000,
  },
}
export function instanceOfVikoResultCategoryStore(value: object): value is VikoResultCategoryStore {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "boundary" in value;
    isInstance = isInstance && "money" in value;

return isInstance;
}

export function VikoResultCategoryStoreFromJSON(json: any): VikoResultCategoryStore {
return VikoResultCategoryStoreFromJSONTyped(json, false);
}

export function VikoResultCategoryStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoResultCategoryStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'tender': json['tender'],
          'name': json['name'],
          'boundary': json['boundary'],
          'money': json['money'],
    };
  }

  export function VikoResultCategoryStoreToJSON(value?: VikoResultCategoryStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': value.tender,
                'name': value.name,
                'boundary': value.boundary,
                'money': value.money,
    };
  }

  export function VikoResultCategoryStoreCreateEmpty(): VikoResultCategoryStore {
  return {
      'tender': 0,
      'name': '',
      'boundary': 0,
      'money': 0,
  };
  }

