/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryUpdateLending {
  from: DateTime;
  to: DateTime;
}

export const InventoryUpdateLendingSchema = {
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: 'Kölcsönzés kezdete',
    type: 'datetime',
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: 'Kölcsönzés vége',
    type: 'datetime',
  },
}
export function instanceOfInventoryUpdateLending(value: object): value is InventoryUpdateLending {
let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;

return isInstance;
}

export function InventoryUpdateLendingFromJSON(json: any): InventoryUpdateLending {
return InventoryUpdateLendingFromJSONTyped(json, false);
}

export function InventoryUpdateLendingFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateLending {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'from': (DateTime.fromISO(json['from'])),
        'to': (DateTime.fromISO(json['to'])),
    };
  }

  export function InventoryUpdateLendingToJSON(value?: InventoryUpdateLending | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'from': (value.from.toISO()),
            'to': (value.to.toISO()),
    };
  }

  export function InventoryUpdateLendingCreateEmpty(): InventoryUpdateLending {
  return {
      'from': DateTime.now(),
      'to': DateTime.now(),
  };
  }

