<template>
    <div class="row">
        <div class="col-lg-8">
            <h2 class="p-2">Rendezvénybejelentő portál</h2>

            <h4 class="text-center">Hetifőnökök</h4>

            <p class="text-center">Aktuális hetifőnök: {{ summary.currentManager.user.name }}</p>

            <div class="row text-center">
                <div v-for="manager in summary.managers" :key="manager.id" class="col-lg-4 p-4">
                    <div class="card text-xs rounded m-n2">
                        <img :alt="manager.user.name" :src="BASE_PATH + '/api/events/managers/' + manager.id + '/image'" class="card-img-top">
                        <ul class="list-group list-group-flush text-center">
                            <li class="list-group-item">
                                <h6>{{ manager.user.name }}</h6>
                            </li>
                            <li v-if="manager.user.phoneLink" class="list-group-item">
                                <a :href="manager.user.phoneLink">{{ manager.user.phone }}</a>
                            </li>
                            <li class="list-group-item">SCH {{ manager.roomNumber }}</li>
                            <li class="list-group-item">
                                <a :href="'mailto:' + manager.user.email">{{ manager.user.email }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <h3 class="text-center m-3">Tudnivalók</h3>
            <h4>Kapcsolattartás a Hetifőnökökkel</h4>
            <p>A Hetifőnök beosztást megtalálhatjátok az adott rendezvény oldalán valamint a liftközökben
                kinyomtatva is.</p>
            <p>A Hetifőnökök csak olyan embernek biztosítanak rendezvénybejelentési, illetve kulcskérési jogot,
                aki <b>az aktuális, vagy az előző félév elején</b> megrendezett Főrendezői tanfolyamok valamelyikén <b>részt
                    vett</b>
                és a végén <b>sikeresen megírta az apró számonkérést.</b></p>

            <p>Amennyiben nem teszed ezt meg időben, a Hetifőnök bünti feladatot szabhat ki.
                Ezek a feladatok takarítási munkálatokat foglalnak magukba, és nagyságrendileg egy óra alatt
                elvégezhetők.</p>
            <p>Minden egyéb vagy különleges esetben a Hetifőnöki Gárdavezetőt keresd
                ({{ summary.leaderManager.user.name }})!</p>

            <h4>Kapcsolattartás a körökkel</h4>
            <p>A kollégiumi köröket ({{ groups.map(group => group.name).join(', ') }}) felkérni van lehetőség a
                bejelentési folyamat során és ez a preferált módja a megkeresésüknek. Külön nekik írni csak különleges
                vagy nem megszokott kérések esetén kell. A körök a megkeresést akkor kapják meg amikor a felkérés
                "Véglegesítve" állapotba kerül.
            </p>

            <h4>A Főrendezői tanfolyam anyaga</h4>
            <p>
                A Főrendezői tanfolyamon elhangzott infókat szépen összefoglalva
                <a href="https://drive.google.com/file/d/1I18_3egDs4tHS23raZjwrIe5szdneuwl/view">itt találhatjátok
                    meg.</a>
                Ha bármilyen megfigyelés / hiba / fejlesztési ötlet lenne ezzel kapcsolatban, akkor írjatok a
                <a href="mailto:bejelentes@sch.bme.hu">bejelentes@sch.bme.hu</a> e-mail címre.
            </p>
        </div>

        <div class="col-lg-4">
            <template v-if="auth.user">
                <h4 class="mt-5">Saját aktív rendezvényeim:</h4>
                <ul class="list-unstyled ps-2 pe-2">
                    <li v-for="event in userEvents" :key="event.id" class="alert alert-info p-2 mb-2">
                        <PegasusLink :params="{event}" class="fw-bold" to="events.edit">
                            {{ event.name.limit(20) }}
                            <span class="badge bg-warning float-end">{{ event.date }}</span>
                        </PegasusLink>
                        <div>{{ event.status }}
                            <span v-if="checks[event.id]" class="float-end">
                                <EventChecksBar :checks="checks[event.id]"></EventChecksBar>
                            </span>
                            <span v-else class="float-end">
                                <i class="fas fa-spinner fa-spin"></i>
                            </span>
                        </div>
                    </li>
                </ul>
                <div v-if="isEmpty(userEvents)" class="mb-5">Nincsen aktív rendezvényed</div>

                <PegasusButton v-if="auth.hasPermission(BaseGlobalPermission.EVENTS_SUBMIT)" to="events.new" variant="success">
                    Új rendezvény
                </PegasusButton>
                <PegasusOverlay v-else content="Rendezvény bejelentéshez főrendezői tanfolyamon kell részt venni">
                    <PegasusButton disabled variant="success">
                        Új rendezvény
                    </PegasusButton>
                </PegasusOverlay>

                <PegasusButton to="events.own" variant="primary">
                    Összes rendezvényem
                </PegasusButton>

                <template v-if="!auth.hasPermission(BaseGlobalPermission.ADMIN_ACL) && !isEmpty(auth.user.eventsGroups())">
                    <h4 class="mt-5">Körök adminisztrációja:</h4>
                    <ul class="list-unstyled ps-2 pe-2">
                        <li v-for="group in auth.user.eventsGroups()" :key="group.id" class="alert alert-info p-2 mb-2">
                            <PegasusLink :params="{group}" class="fw-bold" to="events.group">
                                {{ group.name }}
                            </PegasusLink>
                        </li>
                    </ul>
                </template>
            </template>

            <p v-else class="mt-5">Rendezvény bejelentéséhez bejelentkezés szükséges</p>
            <h4 class="mt-5">Rendezvénynaptár:</h4>

            <PegasusButton to="events.calendar.public" variant="primary">
                <i class="fab fa-google"></i> Google calendar
            </PegasusButton>

            <PegasusButton href="/api/external/calendars/ical/sch.ics" variant="primary">
                <i class="fas fa-calendar"></i> iCal formátum
            </PegasusButton>

            <h4 class="mt-5">Terem foglaltsági naptárak:</h4>
            <PegasusButton
                v-for="location in locationsWithCalendar"
                :key="location.id"
                :params="{location: location.name.toUpperCase()}"
                block
                class="my-1"
                to="events.calendar.location"
                variant="primary"
            >
                <i class="fab fa-google"></i> {{ location.longName }}
            </PegasusButton>
        </div>
    </div>
</template>

<title>Rendezvénybejelentő portál</title>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import {Checks} from "@Models/events/Checks";
import {ManagerSummary} from "@Models/events/ManagerSummary";
import {useAuthStore} from "@/stores/auth";
import {filter, forEach, isEmpty} from "lodash-es";
import {computed, ref} from "vue";
import {useConstantsStore} from "@/stores/constants";
import {BaseGlobalPermission} from "@/api/models";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import EventChecksBar from "@Components/events/EventChecksBar.vue";
import PegasusOverlay from "@Components/base/PegasusOverlay.vue";
import {BASE_PATH} from "@/api/runtime";

const auth = useAuthStore();
const constants = useConstantsStore().constants;

const data: {summary: ManagerSummary, userEvents: Record<number, Event>} = {
    userEvents: {},
} as {summary: ManagerSummary, userEvents: Record<number, Event>};

let requests = [
    ManagerSummary.get().then(summary => {
        data.summary = summary;
    }),
];

if (auth.user) {
    requests.push(Event.getRelevantUserEvents(auth.user.id).then(events => {
        data.userEvents = events;
    }));
}

await Promise.all(requests);

const {summary, userEvents} = data;

const checks = ref<Record<number, Checks>>({});

const locationsWithCalendar = computed(() => filter(constants.events.locations, location => !!location.calendar));

forEach(userEvents, event => {
    Checks.getForEvent(event.id).then(response => {
        checks.value[event.id] = response;
    });
});

const groups = computed(() => {
    return filter(constants.events.services, service => service.groupId !== null);
});
</script>
