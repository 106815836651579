/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneySpendingMethod } from './MoneySpendingMethod';
    import {
    MoneySpendingMethodFromJSON,
    MoneySpendingMethodFromJSONTyped,
    MoneySpendingMethodToJSON,
    MoneySpendingMethodCreateEmpty,
    } from './MoneySpendingMethod';
import {DateTime} from '@Utils/DateTime';
import { MoneySpendingMethodValues } from './MoneySpendingMethod';

export interface MoneySpendingItemStore {
  name: string;
  location: number;
  item: number;
  money: number;
  method: MoneySpendingMethod;
}

export const MoneySpendingItemStoreSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'location': {
    name: 'location',
    required: true,
    nullable: false,
    label: 'Gazdasági láb',
    type: 'number',
      minimum: 0,
  },
  'item': {
    name: 'item',
    required: true,
    nullable: false,
    label: 'Tétel',
    type: 'number',
      minimum: 0,
  },
  'money': {
    name: 'money',
    required: true,
    nullable: false,
    label: 'Összeg',
    type: 'number',
      minimum: -10000000,
      maximum: 10000000,
  },
  'method': {
    name: 'method',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Típus",
        enumValues: MoneySpendingMethodValues,
  },
}
export function instanceOfMoneySpendingItemStore(value: object): value is MoneySpendingItemStore {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "item" in value;
    isInstance = isInstance && "money" in value;
    isInstance = isInstance && "method" in value;

return isInstance;
}

export function MoneySpendingItemStoreFromJSON(json: any): MoneySpendingItemStore {
return MoneySpendingItemStoreFromJSONTyped(json, false);
}

export function MoneySpendingItemStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingItemStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'location': json['location'],
          'item': json['item'],
          'money': json['money'],
              'method': MoneySpendingMethodFromJSON(json['method']),
    };
  }

  export function MoneySpendingItemStoreToJSON(value?: MoneySpendingItemStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'location': value.location,
                'item': value.item,
                'money': value.money,
                'method': MoneySpendingMethodToJSON(value.method),
    };
  }

  export function MoneySpendingItemStoreCreateEmpty(): MoneySpendingItemStore {
  return {
      'name': '',
      'location': 0,
      'item': 0,
      'money': 0,
          'method': MoneySpendingMethodCreateEmpty(),
  };
  }

