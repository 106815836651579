import {Base} from "@Models/Base";
import {ItemGroup} from "@Models/inventory/ItemGroup";
import {Group} from "@Models/base/Group";
import {type BaseGroupSummaryInventoryResponse, InventoryApi} from "@/api/api";
import {every, filter, flatMapDeep, keyBy, map, toArray} from "lodash-es";
import type {Tag} from '@Models/inventory/Tag';
import {useConstantsStore} from '@/stores/constants';
import type {Variants} from '@/vue';

export type TagCount = {
    tag: Tag;

    count: number;
}

export class InventoryGroupSummary extends Base {
    group: Group;

    itemsWithoutGroup: number;

    tagCounts: Record<number, TagCount>;

    rootGroup: ItemGroup;

    static parseResponse(summary: InventoryGroupSummary, response: BaseGroupSummaryInventoryResponse): InventoryGroupSummary {
        summary.group = Group.getBySingleId(response.groupId);

        const tags = useConstantsStore().constants.inventory.tags;
        const tagCounts = map(response.tagCounts, (count, tagId) => {
            return {
                tag: tags[parseInt(tagId)],
                count: count
            };
        });

        summary.itemsWithoutGroup = response.itemsWithoutGroup;

        summary.tagCounts = keyBy(tagCounts, tagCount => tagCount.tag.id);

        summary.rootGroup = ItemGroup.createDummyRoot(
            summary.group,
            ItemGroup.newRecords(response.itemGroups, ItemGroup.parseHierarchyResponse),
            summary.itemsWithoutGroup
        );

        return summary;
    }

    static getByGroup(groupId: number): Promise<InventoryGroupSummary> {
        return InventoryApi.groupsSummary(groupId).then(response => {
            return InventoryGroupSummary.newSingle(response.data, this.parseResponse);
        });
    }

    public allItemGroups(): Record<number, ItemGroup> {
        return keyBy(
            flatMapDeep(
                this.rootGroup.children,
                itemGroup => toArray(itemGroup.children).concat([itemGroup])
            ),
            itemGroup => itemGroup.id
        );
    }

    public filterTagCounts(severity: Variants): TagCount[] {
        return filter(this.tagCounts, tagCount => tagCount.tag.severity === severity);
    }

    get noWarnings(): boolean {
        return every(this.tagCounts, tagCount => tagCount.tag.severity !== 'danger' && tagCount.tag.severity !== 'warning');
    }
}
