/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { VikoReviewStage } from './VikoReviewStage';
    import {
    VikoReviewStageFromJSON,
    VikoReviewStageFromJSONTyped,
    VikoReviewStageToJSON,
    VikoReviewStageCreateEmpty,
    } from './VikoReviewStage';
import {DateTime} from '@Utils/DateTime';
import { VikoReviewStageValues } from './VikoReviewStage';

export interface VikoReviewerGroupResponse {
  id: number;
  name: string;
  reviewStage: VikoReviewStage;
  reviewers: Array<BaseMinimalUserResponse>;
}

export const VikoReviewerGroupResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'reviewStage': {
    name: 'reviewStage',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: VikoReviewStageValues,
  },
  'reviewers': {
    name: 'reviewers',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfVikoReviewerGroupResponse(value: object): value is VikoReviewerGroupResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "reviewStage" in value;
    isInstance = isInstance && "reviewers" in value;

return isInstance;
}

export function VikoReviewerGroupResponseFromJSON(json: any): VikoReviewerGroupResponse {
return VikoReviewerGroupResponseFromJSONTyped(json, false);
}

export function VikoReviewerGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReviewerGroupResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
              'reviewStage': VikoReviewStageFromJSON(json['reviewStage']),
            'reviewers': ((json['reviewers'] as Array<any>).map(BaseMinimalUserResponseFromJSON)),
    };
  }

  export function VikoReviewerGroupResponseToJSON(value?: VikoReviewerGroupResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'reviewStage': VikoReviewStageToJSON(value.reviewStage),
              'reviewers': ((value.reviewers as Array<any>).map(BaseMinimalUserResponseToJSON)),
    };
  }

  export function VikoReviewerGroupResponseCreateEmpty(): VikoReviewerGroupResponse {
  return {
      'id': 0,
      'name': '',
          'reviewStage': VikoReviewStageCreateEmpty(),
      'reviewers': [],
  };
  }

