/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsAssignmentResponse } from './MentorsAssignmentResponse';
    import {
    MentorsAssignmentResponseFromJSON,
    MentorsAssignmentResponseFromJSONTyped,
    MentorsAssignmentResponseToJSON,
    MentorsAssignmentResponseCreateEmpty,
    } from './MentorsAssignmentResponse';
    import type { MentorsMentorResponse } from './MentorsMentorResponse';
    import {
    MentorsMentorResponseFromJSON,
    MentorsMentorResponseFromJSONTyped,
    MentorsMentorResponseToJSON,
    MentorsMentorResponseCreateEmpty,
    } from './MentorsMentorResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsIndexResponse {
  current: MentorsAssignmentResponse;
  next: MentorsAssignmentResponse | null;
  leader: MentorsMentorResponse;
}

export const MentorsIndexResponseSchema = {
  'current': {
    name: 'current',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'next': {
    name: 'next',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'leader': {
    name: 'leader',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfMentorsIndexResponse(value: object): value is MentorsIndexResponse {
let isInstance = true;
    isInstance = isInstance && "current" in value;
    isInstance = isInstance && "next" in value;
    isInstance = isInstance && "leader" in value;

return isInstance;
}

export function MentorsIndexResponseFromJSON(json: any): MentorsIndexResponse {
return MentorsIndexResponseFromJSONTyped(json, false);
}

export function MentorsIndexResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsIndexResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'current': MentorsAssignmentResponseFromJSON(json['current']),
              'next': MentorsAssignmentResponseFromJSON(json['next']),
              'leader': MentorsMentorResponseFromJSON(json['leader']),
    };
  }

  export function MentorsIndexResponseToJSON(value?: MentorsIndexResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'current': MentorsAssignmentResponseToJSON(value.current),
                'next': MentorsAssignmentResponseToJSON(value.next),
                'leader': MentorsMentorResponseToJSON(value.leader),
    };
  }

  export function MentorsIndexResponseCreateEmpty(): MentorsIndexResponse {
  return {
          'current': MentorsAssignmentResponseCreateEmpty(),
          'next': MentorsAssignmentResponseCreateEmpty(),
          'leader': MentorsMentorResponseCreateEmpty(),
  };
  }

