import {BaseEntity} from "@Models/BaseEntity";
import {Mentor} from "@Models/mentors/Mentor";
import {DateTime} from "@Utils/DateTime";
import {File} from "@Models/File";
import type {MentorsAssignmentResponse} from "@/api/api";
import {MentorsApi} from "@/api/api";
import type {AssignmentMonth} from "@Models/mentors/AssignmentMonth";

export class Assignment extends BaseEntity {
    mentor: Mentor | null;

    date: DateTime;

    from: DateTime;

    to: DateTime;

    static getMonth(year: number, month: number): Promise<Record<number, Assignment>> {
        return MentorsApi.assignmentsIndex(year, month).then(response => {
            return Assignment.newRecords(response.data.days, Assignment.parseResponse);
        });
    }

    static exportMonth(assignmentMonth: AssignmentMonth): Promise<File> {
        return MentorsApi.assignmentsExport(assignmentMonth.year, assignmentMonth.month).then(response => File.createFromResponse(response));
    }

    static parseResponse(assignment: Assignment, data: MentorsAssignmentResponse): Assignment {
        assignment.id = data.id;
        assignment.mentor = Mentor.newSingleNullable(data.mentor, Mentor.parseResponse);
        assignment.date = data.date;
        assignment.from = data.from;
        assignment.to = data.to;

        return assignment;
    }
}
