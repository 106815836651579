/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsAssignmentMonthResponse } from './MentorsAssignmentMonthResponse';
    import {
    MentorsAssignmentMonthResponseFromJSON,
    MentorsAssignmentMonthResponseFromJSONTyped,
    MentorsAssignmentMonthResponseToJSON,
    MentorsAssignmentMonthResponseCreateEmpty,
    } from './MentorsAssignmentMonthResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAvailabilityResponse {
  id: number;
  assignmentMonth: MentorsAssignmentMonthResponse;
  mentorId: number;
  days: Array<number>;
}

export const MentorsAvailabilityResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'assignmentMonth': {
    name: 'assignmentMonth',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'mentorId': {
    name: 'mentorId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'days': {
    name: 'days',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
}
export function instanceOfMentorsAvailabilityResponse(value: object): value is MentorsAvailabilityResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "assignmentMonth" in value;
    isInstance = isInstance && "mentorId" in value;
    isInstance = isInstance && "days" in value;

return isInstance;
}

export function MentorsAvailabilityResponseFromJSON(json: any): MentorsAvailabilityResponse {
return MentorsAvailabilityResponseFromJSONTyped(json, false);
}

export function MentorsAvailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAvailabilityResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'assignmentMonth': MentorsAssignmentMonthResponseFromJSON(json['assignmentMonth']),
          'mentorId': json['mentorId'],
          'days': json['days'],
    };
  }

  export function MentorsAvailabilityResponseToJSON(value?: MentorsAvailabilityResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'assignmentMonth': MentorsAssignmentMonthResponseToJSON(value.assignmentMonth),
                'mentorId': value.mentorId,
            'days': value.days,
    };
  }

  export function MentorsAvailabilityResponseCreateEmpty(): MentorsAvailabilityResponse {
  return {
      'id': 0,
          'assignmentMonth': MentorsAssignmentMonthResponseCreateEmpty(),
      'mentorId': 0,
      'days': [],
  };
  }

