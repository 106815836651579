/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoApplicationAdminResponse } from './VikoApplicationAdminResponse';
    import {
    VikoApplicationAdminResponseFromJSON,
    VikoApplicationAdminResponseFromJSONTyped,
    VikoApplicationAdminResponseToJSON,
    VikoApplicationAdminResponseCreateEmpty,
    } from './VikoApplicationAdminResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoApplicationListResponse {
  applications: Array<VikoApplicationAdminResponse>;
}

export const VikoApplicationListResponseSchema = {
  'applications': {
    name: 'applications',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfVikoApplicationListResponse(value: object): value is VikoApplicationListResponse {
let isInstance = true;
    isInstance = isInstance && "applications" in value;

return isInstance;
}

export function VikoApplicationListResponseFromJSON(json: any): VikoApplicationListResponse {
return VikoApplicationListResponseFromJSONTyped(json, false);
}

export function VikoApplicationListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoApplicationListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'applications': ((json['applications'] as Array<any>).map(VikoApplicationAdminResponseFromJSON)),
    };
  }

  export function VikoApplicationListResponseToJSON(value?: VikoApplicationListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'applications': ((value.applications as Array<any>).map(VikoApplicationAdminResponseToJSON)),
    };
  }

  export function VikoApplicationListResponseCreateEmpty(): VikoApplicationListResponse {
  return {
      'applications': [],
  };
  }

