<template>
    <PegasusModal header="Rendezvény bejelentése">
        <PegasusForm ref="form">
            <TextareaField
                v-model="comment"
                :schema="EventsMilestoneAddSchema.comment"
                label="Megjegyzés a jóváhagyóknak"
            ></TextareaField>

            <PegasusButton variant="success" @click="doSubmit">
                Bejelentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {EventsMilestoneAddSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {FormRef} from '@/vue';
import {useReturn} from '@Utils/dialog';
import toast from '@Utils/toast';
import Event from '@Models/events/Event';
import {ref} from "vue";

const {event} = defineProps<{
    event: Event
}>();

const returnValue = useReturn();

const comment = ref('');

const form = ref<FormRef>(null);

async function doSubmit() {
    if (!await toast.confirm('Biztos vagy benne, hogy be szeretnéd jelenteni a rendezvényedet?')) {
        return;
    }

    let request = event.submit(comment.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request, 'Bejelentés');
}
</script>
