/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtAmendUpdate {
  objective: string;
  reasoning: string;
}

export const RvtAmendUpdateSchema = {
  'objective': {
    name: 'objective',
    required: true,
    nullable: false,
    label: 'A módosító célja',
    type: 'string',
      maxLength: 250,
  },
  'reasoning': {
    name: 'reasoning',
    required: true,
    nullable: false,
    label: 'A módosító indoklása',
    type: 'string',
      maxLength: 5000,
  },
}
export function instanceOfRvtAmendUpdate(value: object): value is RvtAmendUpdate {
let isInstance = true;
    isInstance = isInstance && "objective" in value;
    isInstance = isInstance && "reasoning" in value;

return isInstance;
}

export function RvtAmendUpdateFromJSON(json: any): RvtAmendUpdate {
return RvtAmendUpdateFromJSONTyped(json, false);
}

export function RvtAmendUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtAmendUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'objective': json['objective'],
          'reasoning': json['reasoning'],
    };
  }

  export function RvtAmendUpdateToJSON(value?: RvtAmendUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'objective': value.objective,
                'reasoning': value.reasoning,
    };
  }

  export function RvtAmendUpdateCreateEmpty(): RvtAmendUpdate {
  return {
      'objective': '',
      'reasoning': '',
  };
  }

