<template>
    <dt class="d-inline-block mb-1">Történet</dt>

    <span class="float-end text-white">
        <PegasusOverlay
            v-for="milestoneType in types"
            :key="milestoneType"
            :content="EventsMilestoneTypeValues[milestoneType]">
            <i
                :class="'fas timeline-icon ' + icons[milestoneType]"
                :style="milestoneFilter.includes(milestoneType) ? '' : 'background-color: black !important'"
                @click="filterMilestone(milestoneType)"
            ></i>
        </PegasusOverlay>
    </span>

    <div class="timeline">
        <MilestoneComponent
            v-for="milestone in milestones"
            :key="milestone.id"
            :event-entity="event"
            :milestone="milestone"
        ></MilestoneComponent>
    </div>
</template>

<script lang="ts" setup>
import MilestoneComponent from "./Milestone.vue";
import Event from "@Models/events/Event";
import {includes, uniq, values} from "lodash-es";
import {computed, ref} from "vue";
import {EventsMilestoneType, EventsMilestoneTypeValues} from "@/api/models";
import PegasusOverlay from "@Components/base/PegasusOverlay.vue";
import {Milestone} from "@Models/events/Milestone";

const {event} = defineProps<{
    event: Event
}>();

const milestones = computed(() => {
    return event.milestones.filter(milestone => includes(milestoneFilter.value, milestone.type));
});

const types = computed((): EventsMilestoneType[] => {
    return uniq(event.milestones.map(milestone => milestone.type));
});

function filterMilestone(type: EventsMilestoneType) {
    let index = milestoneFilter.value.indexOf(type);
    if (index === -1) {
        milestoneFilter.value.push(type);
    } else {
        milestoneFilter.value.splice(index, 1);
    }
}

const icons = Milestone.getIcons();

const milestoneFilter = ref(values(EventsMilestoneType));
</script>
