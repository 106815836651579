/* tslint:disable */
/* eslint-disable */

export enum EventsBilling {
    SVIE = 'SVIE',
    MUHASZ = 'MŰHASZ'
}

export const EventsBillingValues: Record<EventsBilling, string> = {
    'SVIE': 'SVIE',
    'MŰHASZ': 'MŰHASZ'
}


export function EventsBillingFromJSON(json: any): EventsBilling {
    return EventsBillingFromJSONTyped(json, false);
}

export function EventsBillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsBilling {
    return json as EventsBilling;
}

export function EventsBillingToJSON(value?: EventsBilling | null): any {
    return value as any;
}

export function EventsBillingCreateEmpty(): EventsBilling {
    return EventsBilling.SVIE;
}

