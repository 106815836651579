<template>
    <div class="row">
        <div class="col-lg-7">
            <PegasusForm ref="form">
                <AmendsBase
                    v-model:dirty="dirty"
                    :amend="amend"
                ></AmendsBase>
            </PegasusForm>

            <ItemGroup
                :group="amend.itemGroup"
                :permissions="{canEdit: amend.canEdit, canFinalize: false, canDecide: false, canConfidential: false}"
                dummy="Cél tételek"
            ></ItemGroup>
        </div>

        <div class="col-lg-5">
            <Status :request="amend" @save="save"></Status>

            <PegasusPanel class="mt-3" header="Műveletek">
                <PegasusButton v-if="amend.canEdit" variant="success" @click="save">
                    Mentés
                </PegasusButton>
                <PegasusButton v-if="amend.canFinalize && !amend.finalized" variant="warning" @click="finalize">
                    Leadás!
                </PegasusButton>
                <PegasusButton v-if="amend.canEdit" variant="danger" @click="deleteAmend">
                    Kérvény törlése
                </PegasusButton>

                <template v-if="amend.canApprove && amend.finalized && !amend.approved">
                    <PegasusButton variant="success" @click="approve(true)">
                        Jóváhagyás
                    </PegasusButton>

                    <PegasusButton variant="danger" @click="approve(false)">
                        Visszadobás szerkesztésre
                    </PegasusButton>
                </template>

                <template v-if="amend.canDecide && amend.approved && !amend.decisionTime">
                    <PegasusButton variant="success" @click="decide(true)">
                        Kérvény támogatva
                    </PegasusButton>

                    <PegasusButton variant="danger" @click="decide(false)">
                        Kérvény elutasítva
                    </PegasusButton>
                </template>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>KPR módosító kérvény</title>

<script lang="ts" setup>
import Status from "@Components/rvt/requests/Status.vue";
import ItemGroup from "@Components/money/ItemGroup.vue";
import {Amend} from "@Models/rvt/requests/Amend";
import AmendsBase from "@Components/rvt/requests/amends/AmendsBase.vue";
import {ref} from "vue";
import toast from "@Utils/toast";
import {useRouter} from "vue-router";
import {isEmpty} from "lodash-es";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {usePreventExit} from "@Utils/composables";
import {useAuthStore} from "@/stores/auth";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';

const {amendId} = defineProps<{
    amendId: number
}>();

useAuthStore().requireUser();

const fetched = await Amend.getById(amendId);

const amend = ref(fetched);

usePreventExit(() => dirty.value);

const dirty = ref(false);

const form = ref<FormRef>(null);

function save() {
    const request = amend.value.save(form.value).then(() => {
        dirty.value = false;
    });

    toast.loading(request);

    return request;
}

async function finalize() {
    if (!await toast.confirm('Biztosan leakarod adni a kérvényt?')) {
        return;
    }

    if (isEmpty(amend.value.oldItems)) {
        toast.error('Leadás nem lehetséges', 'Legalább egy forrás tételt fel kell venni');
        return false;
    }

    if (isEmpty(amend.value.itemGroup.items)) {
        toast.error('Leadás nem lehetséges', 'Legalább egy cél tételt fel kell venni');
        return false;
    }

    const request = save()
        .then(() => dirty.value = false)
        .then(() => amend.value.finalize());

    toast.loading(request, 'Leadás');
}

async function deleteAmend() {
    if (!await toast.confirm('Biztos vagy benne? A művelet visszavonhatatlan!!!')) {
        return;
    }

    let request = amend.value.delete().then(() => {
        useRouter().push({
            name: 'rvt.groups.money',
            params: {
                group: '' + amend.value.group.id,
            },
        });
    });

    toast.loading(request, 'Törlés');
}

async function approve(result: boolean) {
    if (result) {
        if (!await toast.confirm('Ezzel a kérvény beküldésre kerül az RVT elé, biztos vagy benne?')) {
            return;
        }
    } else {
        if (!await toast.confirm('Biztos vagy benne? Mindenképpen mondd el a leadónak, hogy miért dobtad vissza!')) {
            return;
        }
    }

    let request = amend.value.approve(result).then(() => {
        dirty.value = false;
    });

    toast.loading(request, 'Jóváhagyás');
}

async function decide(decision: boolean) {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    let request = save()
        .then(() => dirty.value = false)
        .then(() => amend.value.decide(decision));

    toast.loading(request, 'Döntés mentése');
}
</script>
