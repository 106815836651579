<template>
    <PegasusModal header="Foglalás átadás" width="700px">
        <PegasusForm ref="form">
            <br />
            <SelectField
                v-model="selected"
                :options="dictArray(sortBy(members, user => user.name), user => user.name)"
                :schema="EventsReservationReassignSchema.responsible"
                inline
            >
                <template #description>
                    A listában a főrendezőivel rendelkező körtagok találhatóak
                </template>
            </SelectField>

            <br/>

            <small>
                <i class="fas fa-info-circle text-primary"></i>
                A foglaló módosításával az új körtagnak lesz jogosultsága bejelenteni a rendezvényt a foglalásra.
            </small>

            <br/>
            <br/>

            <PegasusButton @click="save">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
            <br />
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {Reservation} from '@Models/events/Reservation';
import SelectField from '@Components/base/form/types/SelectField.vue';
import type {User} from '@Models/base/User';
import {dictArray} from '@Models/Utils';
import {sortBy} from 'lodash-es';
import {ref} from 'vue';
import {EventsReservationReassignSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import toast from '@Utils/toast';
import {useReturn} from '@Utils/dialog';

const {reservation, members} = defineProps<{
    reservation: Reservation,
    members: User[]
}>();

const selected = ref(reservation.responsible);

const form = ref<FormRef>(null);

const returnValue = useReturn();

async function save() {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    const request = reservation.reassign(selected.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}
</script>
