/* tslint:disable */
/* eslint-disable */

export enum VikoTenderStatus {
    CREATED = 0,
    SUBMITTING = 1,
    FINALIZING = 2,
    REVIEWING = 3,
    CALCULATING = 4,
    RESULTING = 5,
    ARCHIVED = 6
}

export const VikoTenderStatusValues: Record<VikoTenderStatus, string> = {
    0: 'Szerkesztési időszak',
    1: 'Leadási időszak',
    2: 'Véglegesítési időszak',
    3: 'Bírálási időszak',
    4: 'Vonalhúzási időszak',
    5: 'Eredmények időszak',
    6: 'Archivált'
}


export function VikoTenderStatusFromJSON(json: any): VikoTenderStatus {
    return VikoTenderStatusFromJSONTyped(json, false);
}

export function VikoTenderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoTenderStatus {
    return json as VikoTenderStatus;
}

export function VikoTenderStatusToJSON(value?: VikoTenderStatus | null): any {
    return value as any;
}

export function VikoTenderStatusCreateEmpty(): VikoTenderStatus {
    return VikoTenderStatus.CREATED;
}

