/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoExtendedTenderResponse } from './VikoExtendedTenderResponse';
    import {
    VikoExtendedTenderResponseFromJSON,
    VikoExtendedTenderResponseFromJSONTyped,
    VikoExtendedTenderResponseToJSON,
    VikoExtendedTenderResponseCreateEmpty,
    } from './VikoExtendedTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoTenderSummaryResponse {
  tender: VikoExtendedTenderResponse;
  applicationsCount: number;
  reportsCount: number;
  avgCharacterCount: number;
}

export const VikoTenderSummaryResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'applicationsCount': {
    name: 'applicationsCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'reportsCount': {
    name: 'reportsCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'avgCharacterCount': {
    name: 'avgCharacterCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfVikoTenderSummaryResponse(value: object): value is VikoTenderSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "applicationsCount" in value;
    isInstance = isInstance && "reportsCount" in value;
    isInstance = isInstance && "avgCharacterCount" in value;

return isInstance;
}

export function VikoTenderSummaryResponseFromJSON(json: any): VikoTenderSummaryResponse {
return VikoTenderSummaryResponseFromJSONTyped(json, false);
}

export function VikoTenderSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoTenderSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': VikoExtendedTenderResponseFromJSON(json['tender']),
          'applicationsCount': json['applicationsCount'],
          'reportsCount': json['reportsCount'],
          'avgCharacterCount': json['avgCharacterCount'],
    };
  }

  export function VikoTenderSummaryResponseToJSON(value?: VikoTenderSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': VikoExtendedTenderResponseToJSON(value.tender),
                'applicationsCount': value.applicationsCount,
                'reportsCount': value.reportsCount,
                'avgCharacterCount': value.avgCharacterCount,
    };
  }

  export function VikoTenderSummaryResponseCreateEmpty(): VikoTenderSummaryResponse {
  return {
          'tender': VikoExtendedTenderResponseCreateEmpty(),
      'applicationsCount': 0,
      'reportsCount': 0,
      'avgCharacterCount': 0,
  };
  }

