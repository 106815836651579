import {BaseEntity} from "@Models/BaseEntity";
import {Group} from "@Models/base/Group";
import type {RvtGroupStatementResponse, RvtStatementStore, RvtStatementUpdate} from "@/api/api";
import {RvtApi} from "@/api/api";
import {DateTime} from "@Utils/DateTime";
import type {FormRef} from '@/vue';

export class GroupStatement extends BaseEntity {
    group: Group;

    year: number;

    month: number;

    text: string;

    static getByGroup(group: Group, year: number): Promise<Record<number, GroupStatement>> {
        return RvtApi.groupStatementIndex(group.id, '' + year).then(response => {
            return GroupStatement.newRecords(response.data.statements, GroupStatement.parseResponse);
        });
    }

    static parseResponse(statement: GroupStatement, data: RvtGroupStatementResponse): GroupStatement {
        statement.id = data.id;
        statement.group = Group.getBySingleId(data.groupId);
        statement.year = data.year;
        statement.month = data.month;
        statement.text = data.text;

        return statement;
    }

    public store(form: FormRef): Promise<GroupStatement> {
        const data: RvtStatementStore = {
            group: this.group.id,
            year: this.year,
            month: this.month,
            text: this.text,
        };

        return RvtApi.groupStatementStore(data, {form}).then(response => {
            return GroupStatement.parseResponse(this, response.data);
        });
    }

    public update(form: FormRef, text: string): Promise<void> {
        const data: RvtStatementUpdate = {
            text: text
        };

        return RvtApi.groupStatementUpdate(this.id, data, {form}).then(response => {
            GroupStatement.parseResponse(this, response.data);
        });
    }

    get displayName(): string {
        const date = DateTime.from(this.year, this.month);

        return this.group.name + ' - ' + this.year + ' ' + date.monthLong;
    }
}
