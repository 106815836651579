<template>
    <PegasusHeading>Schönherz Leltár</PegasusHeading>

    <template v-if="auth.user">
        <h4>Körök amiknek a leltárához van hozzáférésed:</h4>
        <PegasusButton v-for="group in auth.user.inventoryGroups()" :key="group.id" :params="{group}" to="inventory.groups.index">
            {{ group.name.limit(30) }}
        </PegasusButton>

        <div v-if="auth.user?.hasPermission(BaseGlobalPermission.INVENTORY_PUBLIC)" class="mt-2">
            <h4>Publikus funkciók</h4>

            <PegasusButton severity="success" to="inventory.public.check">Tétel beazonosítás</PegasusButton>
        </div>
    </template>

    <h3 v-else>Az oldal használatához bejelentkezés szükséges</h3>
</template>

<title>Schönherz Leltár</title>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import {BaseGlobalPermission} from "@/api/models";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const auth = useAuthStore();
</script>
