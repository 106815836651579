<template>
    <h3 class="m-2 text-center">Visszaigazolásra váró igények</h3>

    <PegasusTable :data="servicesAck" :row-variant="eventClass">
        <Column header="Név" sortable>
            <template #body="{data}">
                <a href="javascript:void(0)" @click="Dialog.open(ShortEventDialog, {eventData: data.event})">
                    {{ data.event.name }}
                </a>
            </template>
        </Column>

        <Column field="event.date" header="Időpont" body-class="text-nowrap"></Column>
        <Column field="type.name" header="Szolgáltatás" body-class="text-nowrap"></Column>
        <Column header="Adatok">
            <template #body="{data}">
                <PegasusButton v-if="data.type.hasAssets" size="sm" variant="primary" @click="exportServiceAsset(data)">
                    <i class="fas fa-download"></i> Letöltés
                </PegasusButton>

                <span class="whitespace text-wrap">{{ data.content }}</span>
            </template>
        </Column>

        <Column header="Műveletek">
            <template #body="{data}">
                <PegasusButton variant="success" @click="serviceAck(data)">
                    Visszaigazolás
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<script lang="ts" setup>
import type {Service} from "@Models/events/Service";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {Dialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';

const {servicesAck} = defineProps<{
    servicesAck: Record<number, Service>,
}>();

function eventClass(service: Service) {
    if (service.event.decision === false) {
        return 'danger';
    }

    if (service.event.decision === null) {
        return 'warning';
    }

    return null;
}

function serviceAck(service: Service) {
    let request = service.decide(true).then(() => {
        delete servicesAck[service.id];
    });

    toast.loading(request);
}

function exportServiceAsset(service: Service) {
    let request = service.exportAsset().then(file => file.download());

    toast.loading(request, 'Generálás');
}
</script>
