/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsReservationPeriodResponse {
  id: number;
  start: DateTime;
  finish: DateTime;
  responsible: BaseMinimalUserResponse;
  editable: boolean;
  active: boolean;
}

export const EventsReservationPeriodResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'start': {
    name: 'start',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'finish': {
    name: 'finish',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'editable': {
    name: 'editable',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfEventsReservationPeriodResponse(value: object): value is EventsReservationPeriodResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "finish" in value;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "editable" in value;
    isInstance = isInstance && "active" in value;

return isInstance;
}

export function EventsReservationPeriodResponseFromJSON(json: any): EventsReservationPeriodResponse {
return EventsReservationPeriodResponseFromJSONTyped(json, false);
}

export function EventsReservationPeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsReservationPeriodResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
        'start': (DateTime.fromISO(json['start'])),
        'finish': (DateTime.fromISO(json['finish'])),
              'responsible': BaseMinimalUserResponseFromJSON(json['responsible']),
          'editable': json['editable'],
          'active': json['active'],
    };
  }

  export function EventsReservationPeriodResponseToJSON(value?: EventsReservationPeriodResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
            'start': (value.start.toISO()),
            'finish': (value.finish.toISO()),
                'responsible': BaseMinimalUserResponseToJSON(value.responsible),
                'editable': value.editable,
                'active': value.active,
    };
  }

  export function EventsReservationPeriodResponseCreateEmpty(): EventsReservationPeriodResponse {
  return {
      'id': 0,
      'start': DateTime.now(),
      'finish': DateTime.now(),
          'responsible': BaseMinimalUserResponseCreateEmpty(),
      'editable': false,
      'active': false,
  };
  }

