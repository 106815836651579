/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { RvtSpendingPeriodResponse } from './RvtSpendingPeriodResponse';
    import {
    RvtSpendingPeriodResponseFromJSON,
    RvtSpendingPeriodResponseFromJSONTyped,
    RvtSpendingPeriodResponseToJSON,
    RvtSpendingPeriodResponseCreateEmpty,
    } from './RvtSpendingPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtSpendingPeriodSummaryResponse {
  period: RvtSpendingPeriodResponse;
  tenderId: number | null;
  claimsSum: number;
  claimsBudget: number;
  claimsBalance: number;
  declinedClaims: number;
  successfulClaims: number;
}

export const RvtSpendingPeriodSummaryResponseSchema = {
  'period': {
    name: 'period',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'tenderId': {
    name: 'tenderId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'claimsSum': {
    name: 'claimsSum',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'claimsBudget': {
    name: 'claimsBudget',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'claimsBalance': {
    name: 'claimsBalance',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'declinedClaims': {
    name: 'declinedClaims',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'successfulClaims': {
    name: 'successfulClaims',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfRvtSpendingPeriodSummaryResponse(value: object): value is RvtSpendingPeriodSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "tenderId" in value;
    isInstance = isInstance && "claimsSum" in value;
    isInstance = isInstance && "claimsBudget" in value;
    isInstance = isInstance && "claimsBalance" in value;
    isInstance = isInstance && "declinedClaims" in value;
    isInstance = isInstance && "successfulClaims" in value;

return isInstance;
}

export function RvtSpendingPeriodSummaryResponseFromJSON(json: any): RvtSpendingPeriodSummaryResponse {
return RvtSpendingPeriodSummaryResponseFromJSONTyped(json, false);
}

export function RvtSpendingPeriodSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtSpendingPeriodSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'period': RvtSpendingPeriodResponseFromJSON(json['period']),
          'tenderId': json['tenderId'],
          'claimsSum': json['claimsSum'],
          'claimsBudget': json['claimsBudget'],
          'claimsBalance': json['claimsBalance'],
          'declinedClaims': json['declinedClaims'],
          'successfulClaims': json['successfulClaims'],
    };
  }

  export function RvtSpendingPeriodSummaryResponseToJSON(value?: RvtSpendingPeriodSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'period': RvtSpendingPeriodResponseToJSON(value.period),
                'tenderId': value.tenderId,
                'claimsSum': value.claimsSum,
                'claimsBudget': value.claimsBudget,
                'claimsBalance': value.claimsBalance,
                'declinedClaims': value.declinedClaims,
                'successfulClaims': value.successfulClaims,
    };
  }

  export function RvtSpendingPeriodSummaryResponseCreateEmpty(): RvtSpendingPeriodSummaryResponse {
  return {
          'period': RvtSpendingPeriodResponseCreateEmpty(),
      'tenderId': 0,
      'claimsSum': 0,
      'claimsBudget': 0,
      'claimsBalance': 0,
      'declinedClaims': 0,
      'successfulClaims': 0,
  };
  }

