/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoReviewStage } from './VikoReviewStage';
    import {
    VikoReviewStageFromJSON,
    VikoReviewStageFromJSONTyped,
    VikoReviewStageToJSON,
    VikoReviewStageCreateEmpty,
    } from './VikoReviewStage';
import {DateTime} from '@Utils/DateTime';
import { VikoReviewStageValues } from './VikoReviewStage';

export interface VikoReviewerGroupStore {
  tender: number;
  name: string;
  reviewStage: VikoReviewStage;
}

export const VikoReviewerGroupStoreSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
    label: 'Pályázat',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      maxLength: 50,
  },
  'reviewStage': {
    name: 'reviewStage',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: VikoReviewStageValues,
  },
}
export function instanceOfVikoReviewerGroupStore(value: object): value is VikoReviewerGroupStore {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "reviewStage" in value;

return isInstance;
}

export function VikoReviewerGroupStoreFromJSON(json: any): VikoReviewerGroupStore {
return VikoReviewerGroupStoreFromJSONTyped(json, false);
}

export function VikoReviewerGroupStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReviewerGroupStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'tender': json['tender'],
          'name': json['name'],
              'reviewStage': VikoReviewStageFromJSON(json['reviewStage']),
    };
  }

  export function VikoReviewerGroupStoreToJSON(value?: VikoReviewerGroupStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': value.tender,
                'name': value.name,
                'reviewStage': VikoReviewStageToJSON(value.reviewStage),
    };
  }

  export function VikoReviewerGroupStoreCreateEmpty(): VikoReviewerGroupStore {
  return {
      'tender': 0,
      'name': '',
          'reviewStage': VikoReviewStageCreateEmpty(),
  };
  }

