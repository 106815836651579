/* tslint:disable */
/* eslint-disable */

export enum MoneySpendingItemStatus {
    CREATED = 'CREATED',
    OUTSTANDING = 'OUTSTANDING',
    WAITING = 'WAITING',
    CONFIRMED = 'CONFIRMED'
}

export const MoneySpendingItemStatusValues: Record<MoneySpendingItemStatus, string> = {
    'CREATED': 'Létrehozva',
    'OUTSTANDING': 'Kintlévőség',
    'WAITING': 'Elszámolásra vár',
    'CONFIRMED': 'Lezárva'
}


export function MoneySpendingItemStatusFromJSON(json: any): MoneySpendingItemStatus {
    return MoneySpendingItemStatusFromJSONTyped(json, false);
}

export function MoneySpendingItemStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingItemStatus {
    return json as MoneySpendingItemStatus;
}

export function MoneySpendingItemStatusToJSON(value?: MoneySpendingItemStatus | null): any {
    return value as any;
}

export function MoneySpendingItemStatusCreateEmpty(): MoneySpendingItemStatus {
    return MoneySpendingItemStatus.CREATED;
}

