<template>
    <div class="card kpr-group">
        <div class="card-header with-border bg-primary">
            <span class="card-title">
                {{ project.name }}
            </span>
            <div class="float-end">
                <PegasusButton v-if="permissions.canEdit" size="xs" variant="info" @click="addAlternative(project)">
                    <i class="fa fa-plus"></i> Új alternatíva
                </PegasusButton>

                <PegasusButton size="xs" @click="editItemGroup">
                    <i class="fas fa-edit"></i>
                </PegasusButton>

                <PegasusButton
                    v-if="permissions.canEdit"
                    size="xs"
                    @click="emit('deleteProject')">
                    <i class="fa fa-times"></i>
                </PegasusButton>
            </div>
        </div>

        <div class="card-body kpr-project">
            <TabView v-model:active-index="tabIndex" scrollable>
                <TabPanel v-for="group in project.alternativeChildren" :key="group.id">
                    <template #header>
                        {{ group.name }}
                    </template>
                    <ItemGroupComponent
                        :group="group"
                        :permissions="permissions"
                        @delete-group="removeGroup(group, project)"
                    >
                    </ItemGroupComponent>

                    <p>
                        <b>{{ group.name }} bónusz csomagjai</b>
                        <PegasusHelp :title="group.name + ' bónusz csomagjai'" align="top">
                            Ide kerül minden bónusz csomag amelyik csak ez az adott alternatívához érvényes
                        </PegasusHelp>
                        <PegasusButton v-if="permissions.canEdit" variant="success" @click="addBonus(group)">
                            <i class="fa fa-plus"></i> Új csomag
                        </PegasusButton>
                    </p>

                    <span v-if="isEmpty(group.bonusChildren)">Nincsen csomag</span>

                    <ItemGroupComponent
                        v-for="bonus in group.bonusChildren"
                        :key="bonus.id"
                        :group="bonus"
                        :permissions="permissions"
                        @delete-group="removeGroup(bonus, group)"
                    >
                    </ItemGroupComponent>
                </TabPanel>
            </TabView>

            <hr>

            <p>
                <b>Projekt szintű bónusz csomagok</b>
                <PegasusHelp align="top" title="Projekt szintű bónusz csomagok">
                    Ide kerül minden bónusz csomag amelyik bármelyik alternatíva megvalósulása esetén érvényes
                </PegasusHelp>
                <PegasusButton v-if="permissions.canEdit" size="sm" variant="success" @click="addBonus(project)">
                    <i class="fa fa-plus"></i> Új csomag
                </PegasusButton>
            </p>

            <span v-if="isEmpty(project.bonusChildren)">Nincsen csomag</span>

            <ItemGroupComponent
                v-for="group in project.bonusChildren"
                :key="group.id"
                :group="group"
                :permissions="permissions"
                @delete-group="removeGroup(group, project)"
            >
            </ItemGroupComponent>
        </div>
    </div>
</template>

<script lang="ts" setup>
import ItemGroupComponent from "../../money/ItemGroup.vue";
import {ItemGroup} from "@Models/money/ItemGroup";
import type {KprPermissions} from "@Models/rvt/kpr/Application";
import toast from "@Utils/toast";
import {isEmpty} from "lodash-es";
import PegasusHelp from "@Components/base/PegasusHelp.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {ref} from "vue";
import {Dialog} from '@Utils/dialog';
import GroupEditorDialog from '@Components/kpr/GroupEditorDialog.vue';

const {project, permissions} = defineProps<{
    project: ItemGroup,
    permissions: KprPermissions
}>();

const emit = defineEmits<{
    deleteProject: [],
}>();

const tabIndex = ref(0);

function removeGroup(group: ItemGroup, parent: ItemGroup) {
    let request = group.delete().then(() => {
        delete parent.alternativeChildren[group.id];
        delete parent.bonusChildren[group.id];

        tabIndex.value = 0;
    });

    toast.loading(request, 'Törlés');
}

function addAlternative(parent: ItemGroup) {
    const alternative = ItemGroup.createNew('Új alternatív csomag', 'Alternatív');

    let request = alternative.storeForParentAsAlternative(parent.id).then(() => {
        parent.alternativeChildren[alternative.id] = alternative;
    });

    toast.loading(request);
}

function addBonus(parent: ItemGroup) {
    const bonus = ItemGroup.createNew('Új csomag', 'Bónusz csomag');

    let request = bonus.storeForParentAsBonus(parent.id).then(() => {
        parent.bonusChildren[bonus.id] = bonus;
    });

    toast.loading(request);
}

function editItemGroup() {
    Dialog.open(GroupEditorDialog, {
        itemGroup: project,
        canEdit: permissions.canEdit,
    });
}
</script>
