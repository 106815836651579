<template>
    <NumberField
        v-model="data.tables"
        :disabled="!service.canEdit"
        :schema="EventsExtraTableDataSchema.tables"
        inline
    ></NumberField>

    <NumberField
        v-model="data.chairs"
        :disabled="!service.canEdit"
        :schema="EventsExtraTableDataSchema.chairs"
        inline
    ></NumberField>

    <small class="d-block">
        <i class="fas fa-exclamation-circle text-danger"></i>
        Az elvitt asztalokat és székeket ugyanolyan állapotban sértetlenül kell visszavinni a rendezvény végén!
    </small>
</template>

<script lang="ts" setup>
import type {Service} from "@Models/events/Service";
import NumberField from "@Components/base/form/types/NumberField.vue";
import type {EventsExtraTableData} from "@/api/models";
import {EventsExtraTableDataSchema} from "@/api/models";

const data = defineModel<EventsExtraTableData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
