/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsDeadlineResponse } from './EventsDeadlineResponse';
    import {
    EventsDeadlineResponseFromJSON,
    EventsDeadlineResponseFromJSONTyped,
    EventsDeadlineResponseToJSON,
    EventsDeadlineResponseCreateEmpty,
    } from './EventsDeadlineResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsDeadlinesResponse {
  deadlines: Array<EventsDeadlineResponse>;
}

export const EventsDeadlinesResponseSchema = {
  'deadlines': {
    name: 'deadlines',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsDeadlinesResponse(value: object): value is EventsDeadlinesResponse {
let isInstance = true;
    isInstance = isInstance && "deadlines" in value;

return isInstance;
}

export function EventsDeadlinesResponseFromJSON(json: any): EventsDeadlinesResponse {
return EventsDeadlinesResponseFromJSONTyped(json, false);
}

export function EventsDeadlinesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsDeadlinesResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'deadlines': ((json['deadlines'] as Array<any>).map(EventsDeadlineResponseFromJSON)),
    };
  }

  export function EventsDeadlinesResponseToJSON(value?: EventsDeadlinesResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'deadlines': ((value.deadlines as Array<any>).map(EventsDeadlineResponseToJSON)),
    };
  }

  export function EventsDeadlinesResponseCreateEmpty(): EventsDeadlinesResponse {
  return {
      'deadlines': [],
  };
  }

