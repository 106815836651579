/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetProgramStore {
  budgetItem: number;
  name: string;
}

export const MoneyBudgetProgramStoreSchema = {
  'budgetItem': {
    name: 'budgetItem',
    required: true,
    nullable: false,
    label: 'Házszintű tétel',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 5,
      maxLength: 100,
  },
}
export function instanceOfMoneyBudgetProgramStore(value: object): value is MoneyBudgetProgramStore {
let isInstance = true;
    isInstance = isInstance && "budgetItem" in value;
    isInstance = isInstance && "name" in value;

return isInstance;
}

export function MoneyBudgetProgramStoreFromJSON(json: any): MoneyBudgetProgramStore {
return MoneyBudgetProgramStoreFromJSONTyped(json, false);
}

export function MoneyBudgetProgramStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'budgetItem': json['budgetItem'],
          'name': json['name'],
    };
  }

  export function MoneyBudgetProgramStoreToJSON(value?: MoneyBudgetProgramStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'budgetItem': value.budgetItem,
                'name': value.name,
    };
  }

  export function MoneyBudgetProgramStoreCreateEmpty(): MoneyBudgetProgramStore {
  return {
      'budgetItem': 0,
      'name': '',
  };
  }

