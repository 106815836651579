/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsBilling } from './EventsBilling';
    import {
    EventsBillingFromJSON,
    EventsBillingFromJSONTyped,
    EventsBillingToJSON,
    EventsBillingCreateEmpty,
    } from './EventsBilling';
import {DateTime} from '@Utils/DateTime';
import { EventsBillingValues } from './EventsBilling';

export interface EventsEventUpdate {
  name: string;
  description: string;
  locations: Array<number>;
  start: DateTime;
  finish: DateTime;
  roomStart: DateTime;
  roomFinish: DateTime;
  participants: number;
  outsiders: number;
  externalWorkers: number;
  billing: EventsBilling | null;
}

export const EventsEventUpdateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 3,
      maxLength: 200,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Hivatalos leírás',
    type: 'string',
      minLength: 0,
      maxLength: 400,
  },
  'locations': {
    name: 'locations',
    required: true,
    nullable: false,
    label: 'Helyszínek',
    type: 'array',
  },
  'start': {
    name: 'start',
    required: true,
    nullable: false,
    label: 'Rendezvény kezdete',
    type: 'datetime',
  },
  'finish': {
    name: 'finish',
    required: true,
    nullable: false,
    label: 'Rendezvény vége',
    type: 'datetime',
  },
  'roomStart': {
    name: 'roomStart',
    required: true,
    nullable: false,
    label: 'Teremfoglalás kezdete',
    type: 'datetime',
  },
  'roomFinish': {
    name: 'roomFinish',
    required: true,
    nullable: false,
    label: 'Teremfoglalás vége',
    type: 'datetime',
  },
  'participants': {
    name: 'participants',
    required: true,
    nullable: false,
    label: 'Összes résztvevő',
      labelHelp: `A kollégista és nem kollégista résztvevők együttvéve a rendezőket is beleszámolva.`,
    type: 'number',
      minimum: 1,
      maximum: 700,
  },
  'outsiders': {
    name: 'outsiders',
    required: true,
    nullable: false,
    label: 'Nem kolis résztvevők',
      labelHelp: `Azok a résztvevők és rendezők összesen akik nem a kollégium lakói a rendezvény idején.Bele kell számolni a külsős vendég és külsős dolgozói listában leadottakat is!`,
    type: 'number',
      minimum: 0,
      maximum: 700,
  },
  'externalWorkers': {
    name: 'externalWorkers',
    required: true,
    nullable: false,
    label: 'Külsős rendezők',
      labelHelp: `Azok a rendezők akik nem a kollégium lakói a rendezvény idejében, és a részükre dolgozói kártya igény van. Az extra igényként leadott lista a bejelentés után is módosítható.`,
    type: 'number',
      minimum: 0,
      maximum: 700,
  },
  'billing': {
    name: 'billing',
    required: true,
    nullable: true,
      type: 'embedded',
        label: "",
        enumValues: EventsBillingValues,
  },
}
export function instanceOfEventsEventUpdate(value: object): value is EventsEventUpdate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "locations" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "finish" in value;
    isInstance = isInstance && "roomStart" in value;
    isInstance = isInstance && "roomFinish" in value;
    isInstance = isInstance && "participants" in value;
    isInstance = isInstance && "outsiders" in value;
    isInstance = isInstance && "externalWorkers" in value;
    isInstance = isInstance && "billing" in value;

return isInstance;
}

export function EventsEventUpdateFromJSON(json: any): EventsEventUpdate {
return EventsEventUpdateFromJSONTyped(json, false);
}

export function EventsEventUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsEventUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
          'locations': json['locations'],
        'start': (DateTime.fromISO(json['start'])),
        'finish': (DateTime.fromISO(json['finish'])),
        'roomStart': (DateTime.fromISO(json['roomStart'])),
        'roomFinish': (DateTime.fromISO(json['roomFinish'])),
          'participants': json['participants'],
          'outsiders': json['outsiders'],
          'externalWorkers': json['externalWorkers'],
              'billing': EventsBillingFromJSON(json['billing']),
    };
  }

  export function EventsEventUpdateToJSON(value?: EventsEventUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
            'locations': value.locations,
            'start': (value.start.toISO()),
            'finish': (value.finish.toISO()),
            'roomStart': (value.roomStart.toISO()),
            'roomFinish': (value.roomFinish.toISO()),
                'participants': value.participants,
                'outsiders': value.outsiders,
                'externalWorkers': value.externalWorkers,
                'billing': EventsBillingToJSON(value.billing),
    };
  }

  export function EventsEventUpdateCreateEmpty(): EventsEventUpdate {
  return {
      'name': '',
      'description': '',
      'locations': [],
      'start': DateTime.now(),
      'finish': DateTime.now(),
      'roomStart': DateTime.now(),
      'roomFinish': DateTime.now(),
      'participants': 0,
      'outsiders': 0,
      'externalWorkers': 0,
          'billing': EventsBillingCreateEmpty(),
  };
  }

