/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsAvailabilityResponse } from './MentorsAvailabilityResponse';
    import {
    MentorsAvailabilityResponseFromJSON,
    MentorsAvailabilityResponseFromJSONTyped,
    MentorsAvailabilityResponseToJSON,
    MentorsAvailabilityResponseCreateEmpty,
    } from './MentorsAvailabilityResponse';
    import type { MentorsMentorResponse } from './MentorsMentorResponse';
    import {
    MentorsMentorResponseFromJSON,
    MentorsMentorResponseFromJSONTyped,
    MentorsMentorResponseToJSON,
    MentorsMentorResponseCreateEmpty,
    } from './MentorsMentorResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAvailabilityIndexResponse {
  availabilities: Array<MentorsAvailabilityResponse>;
  mentor: MentorsMentorResponse;
}

export const MentorsAvailabilityIndexResponseSchema = {
  'availabilities': {
    name: 'availabilities',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'mentor': {
    name: 'mentor',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfMentorsAvailabilityIndexResponse(value: object): value is MentorsAvailabilityIndexResponse {
let isInstance = true;
    isInstance = isInstance && "availabilities" in value;
    isInstance = isInstance && "mentor" in value;

return isInstance;
}

export function MentorsAvailabilityIndexResponseFromJSON(json: any): MentorsAvailabilityIndexResponse {
return MentorsAvailabilityIndexResponseFromJSONTyped(json, false);
}

export function MentorsAvailabilityIndexResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAvailabilityIndexResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'availabilities': ((json['availabilities'] as Array<any>).map(MentorsAvailabilityResponseFromJSON)),
              'mentor': MentorsMentorResponseFromJSON(json['mentor']),
    };
  }

  export function MentorsAvailabilityIndexResponseToJSON(value?: MentorsAvailabilityIndexResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'availabilities': ((value.availabilities as Array<any>).map(MentorsAvailabilityResponseToJSON)),
                'mentor': MentorsMentorResponseToJSON(value.mentor),
    };
  }

  export function MentorsAvailabilityIndexResponseCreateEmpty(): MentorsAvailabilityIndexResponse {
  return {
      'availabilities': [],
          'mentor': MentorsMentorResponseCreateEmpty(),
  };
  }

