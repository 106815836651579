<template>
    <div class="mt-1 mb-3">
        <template v-if="props.withoutGroup">
            <slot :id="id" :is-valid="isValid"></slot>

            <small>
                <slot name="description">
                    {{ schema.description }}
                </slot>
            </small>

            <small v-for="(error, key) in validationErrors" :key="key" class="p-error text-nowrap">
                {{ error }}
            </small>
        </template>

        <div v-else-if="inline" class="row">
            <div class="col-lg-6 d-flex align-items-center">
                <label :for="id" class="d-block">
                    <slot :label="label" name="label">
                        <span class="fw-bold me-1">{{ label }}</span>
                        <PegasusHelp v-if="schema.labelHelp" :title="label" wide>
                            <span class="whitespace">{{ schema.labelHelp }}</span>
                        </PegasusHelp>
                    </slot>
                </label>
            </div>

            <div class="col-lg-6">
                <slot :id="id" :is-valid="isValid" name="default"></slot>

                <small>
                    <slot name="description">
                        {{ schema.description }}
                    </slot>
                </small>

                <small v-for="(error, key) in validationErrors" :key="key" class="p-error text-nowrap">
                    {{ error }}
                </small>
            </div>
        </div>

        <div v-else class="row">
            <label :for="id" class="d-block">
                <slot :label="label" name="label">
                    <span class="fw-bold  me-1">{{ label }}</span>
                    <PegasusHelp v-if="schema.labelHelp" :title="label" wide>
                        <span class="whitespace">{{ schema.labelHelp }}</span>
                    </PegasusHelp>
                </slot>
            </label>

            <slot :id="id" :is-valid="isValid" name="default"></slot>

            <small>
                <slot name="description">
                    {{ schema.description }}
                </slot>
            </small>

            <small v-for="(error, key) in validationErrors" :key="key" class="p-error text-nowrap">
                {{ error }}
            </small>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type {FieldSchema} from "@Components/base/form/FieldProperties";
import {isEmpty, random} from "lodash-es";
import {computed, ref} from "vue";
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import type {BaseValidationBagResponse} from '@/api/models';

const props = defineProps<{
    noLabel?: boolean;
    label?: string;
    withoutGroup: boolean | undefined;
    schema: FieldSchema;
    inline: boolean | undefined;
}>();

defineOptions({
    name: 'SmartField'
});

const id = ref("field-" + random(0, 10e10));

const isValid = ref<boolean | null>(null);
const validationErrors = ref<string[]>([]);

defineSlots<{
    default(props: {id: string; isValid: boolean | null}): any;
    label?(props: {label: string | undefined}): any;
    description?(props: {}): any;
}>();

const label = computed(() => {
    if (props.noLabel) {
        return '';
    }

    if (props.label) {
        return props.label;
    }

    return props.schema.label;
});

function handleValidation(errors: BaseValidationBagResponse) {
    isValid.value = isEmpty(errors.errors);
    validationErrors.value = errors.errors;
}

function getSchema(): FieldSchema {
    return props.schema;
}

function update() {
    id.value = "field-" + random(0, 10e10);
}

defineExpose({
    handleValidation,
    getSchema,
    update,
});
</script>
