import type {RouteRecordRaw} from "vue-router";
import {getNumberParam} from "@Models/Utils";

const mentors: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'mentors.index',
        component: () => import('@Pages/mentors/Index.vue'),
    },
    {
        path: '/:year(\\d+)/:month(\\d+)',
        name: 'mentors.month',
        component: () => import('@Pages/mentors/Month.vue'),
        props: route => ({
            year: getNumberParam(route, 'year'),
            month: getNumberParam(route, 'month'),
        }),
    },
    {
        path: '/mentor',
        name: 'mentors.mentor.index',
        component: () => import('@Pages/mentors/mentor/Index.vue'),
    },
    {
        path: '/mentor/:year/:month',
        name: 'mentors.mentor.everyone',
        component: () => import('@Pages/mentors/mentor/Everyone.vue'),
        props: route => ({
            year: getNumberParam(route, 'year'),
            month: getNumberParam(route, 'month'),
        }),
    },
    {
        path: '/admin',
        name: 'mentors.admin.index',
        component: () => import('@Pages/mentors/admin/Index.vue'),
    },
    {
        path: '/admin/assignment/:year/:month',
        name: 'mentors.admin.assignment',
        component: () => import('@Pages/mentors/admin/Assignment.vue'),
        props: route => ({
            year: getNumberParam(route, 'year'),
            month: getNumberParam(route, 'month'),
        }),
    },
    {
        path: '/admin/archive',
        name: 'mentors.admin.archive',
        component: () => import('@Pages/mentors/admin/Archive.vue'),
    },
];

export default mentors;
