/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoTenderStatus } from './VikoTenderStatus';
    import {
    VikoTenderStatusFromJSON,
    VikoTenderStatusFromJSONTyped,
    VikoTenderStatusToJSON,
    VikoTenderStatusCreateEmpty,
    } from './VikoTenderStatus';
import {DateTime} from '@Utils/DateTime';
import { VikoTenderStatusValues } from './VikoTenderStatus';

export interface VikoTenderUpdate {
  begin: DateTime;
  end: DateTime;
  beginDrafting: DateTime;
  endDrafting: DateTime;
  endAccepting: DateTime;
  status: VikoTenderStatus;
  isPublic: boolean;
}

export const VikoTenderUpdateSchema = {
  'begin': {
    name: 'begin',
    required: true,
    nullable: false,
    label: 'Pályázati időszak kezdete',
    type: 'date',
  },
  'end': {
    name: 'end',
    required: true,
    nullable: false,
    label: 'Pályázati időszak vége',
    type: 'date',
  },
  'beginDrafting': {
    name: 'beginDrafting',
    required: true,
    nullable: false,
    label: 'Pályázás kezdete',
    type: 'datetime',
  },
  'endDrafting': {
    name: 'endDrafting',
    required: true,
    nullable: false,
    label: 'Pályázás határideje',
    type: 'datetime',
  },
  'endAccepting': {
    name: 'endAccepting',
    required: true,
    nullable: false,
    label: 'Körvezetői elfogadás határideje',
    type: 'datetime',
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: VikoTenderStatusValues,
  },
  'isPublic': {
    name: 'isPublic',
    required: true,
    nullable: false,
    label: 'Publikus pályázatok',
    type: 'boolean',
  },
}
export function instanceOfVikoTenderUpdate(value: object): value is VikoTenderUpdate {
let isInstance = true;
    isInstance = isInstance && "begin" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "beginDrafting" in value;
    isInstance = isInstance && "endDrafting" in value;
    isInstance = isInstance && "endAccepting" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "isPublic" in value;

return isInstance;
}

export function VikoTenderUpdateFromJSON(json: any): VikoTenderUpdate {
return VikoTenderUpdateFromJSONTyped(json, false);
}

export function VikoTenderUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoTenderUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
        'begin': (DateTime.fromISO(json['begin'])),
        'end': (DateTime.fromISO(json['end'])),
        'beginDrafting': (DateTime.fromISO(json['beginDrafting'])),
        'endDrafting': (DateTime.fromISO(json['endDrafting'])),
        'endAccepting': (DateTime.fromISO(json['endAccepting'])),
              'status': VikoTenderStatusFromJSON(json['status']),
          'isPublic': json['isPublic'],
    };
  }

  export function VikoTenderUpdateToJSON(value?: VikoTenderUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
            'begin': (value.begin.toISODate()),
            'end': (value.end.toISODate()),
            'beginDrafting': (value.beginDrafting.toISO()),
            'endDrafting': (value.endDrafting.toISO()),
            'endAccepting': (value.endAccepting.toISO()),
                'status': VikoTenderStatusToJSON(value.status),
                'isPublic': value.isPublic,
    };
  }

  export function VikoTenderUpdateCreateEmpty(): VikoTenderUpdate {
  return {
      'begin': DateTime.now(),
      'end': DateTime.now(),
      'beginDrafting': DateTime.now(),
      'endDrafting': DateTime.now(),
      'endAccepting': DateTime.now(),
          'status': VikoTenderStatusCreateEmpty(),
      'isPublic': false,
  };
  }

