<template>
    <PegasusHeading>Teremfogalási naptár - {{ location.longName }}</PegasusHeading>

    <div class="text-center">
        <iframe
            :src="url"
            class="mt-4"
            height="600"
            style="border: 0"
            width="800"
        ></iframe>
    </div>
</template>

<title>SCH teremfoglalások</title>

<script lang="ts" setup>
import {Location} from "@Models/events/Location";
import {useConstantsStore} from "@/stores/constants";
import {find} from "lodash-es";
import {NotFoundError} from "@Utils/errors/NotFoundError";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {locationName} = defineProps<{
    locationName: string
}>();

const location = find(
    useConstantsStore().constants.events.locations,
    location => location.name.toLowerCase() === locationName.toLowerCase()
) as Location;

if (location.calendar === null) {
    throw new NotFoundError('A helyszínhez nem tartozik naptár');
}

const calendarSettings = {
    src: location.calendar,
    ctz: 'Europe/Budapest',
    wkst: '2',
    hl: 'hu',
    showCalendars: '0',
    showTitle: '0',
    showTabs: '0',
    showPrint: '0',
    bgcolor: '#f4f6f9',
};

const url = 'https://calendar.google.com/calendar/embed?' + new URLSearchParams(calendarSettings).toString();
</script>
