/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { InventoryDocumentType } from './InventoryDocumentType';
    import {
    InventoryDocumentTypeFromJSON,
    InventoryDocumentTypeFromJSONTyped,
    InventoryDocumentTypeToJSON,
    InventoryDocumentTypeCreateEmpty,
    } from './InventoryDocumentType';
import {DateTime} from '@Utils/DateTime';
import { InventoryDocumentTypeValues } from './InventoryDocumentType';

export interface InventoryDocumentResponse {
  id: number;
  name: string;
  uploader: BaseMinimalUserResponse;
  type: InventoryDocumentType;
  comment: string;
  createdAt: DateTime;
}

export const InventoryDocumentResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'uploader': {
    name: 'uploader',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: InventoryDocumentTypeValues,
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfInventoryDocumentResponse(value: object): value is InventoryDocumentResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "uploader" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function InventoryDocumentResponseFromJSON(json: any): InventoryDocumentResponse {
return InventoryDocumentResponseFromJSONTyped(json, false);
}

export function InventoryDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryDocumentResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
              'uploader': BaseMinimalUserResponseFromJSON(json['uploader']),
              'type': InventoryDocumentTypeFromJSON(json['type']),
          'comment': json['comment'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function InventoryDocumentResponseToJSON(value?: InventoryDocumentResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'uploader': BaseMinimalUserResponseToJSON(value.uploader),
                'type': InventoryDocumentTypeToJSON(value.type),
                'comment': value.comment,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function InventoryDocumentResponseCreateEmpty(): InventoryDocumentResponse {
  return {
      'id': 0,
      'name': '',
          'uploader': BaseMinimalUserResponseCreateEmpty(),
          'type': InventoryDocumentTypeCreateEmpty(),
      'comment': '',
      'createdAt': DateTime.now(),
  };
  }

