import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {
    type MoneySpendingEventTransferRequested,
    MoneySpendingEventTransferRequestedSchema,
    MoneySpendingEventType,
    type MoneySpendingItemEvent,
    type MoneySpendingItemEventResponse
} from '@/api/models';
import type {FormRef} from '@/vue';
import {MoneyApi} from '@/api/apis';
import {formatAccountNumber} from '@Models/Utils';

export class TransferRequested extends SpendingItemEvent {
    accountName: string;

    accountNumber: string;

    transferComment: string;

    constructor() {
        super();
        this.accountNumber = '';
        this.accountName = '';
        this.transferComment = '';
    }

    static parseResponse(cashOut: TransferRequested, data: MoneySpendingItemEventResponse): TransferRequested {
        SpendingItemEvent.parseResponse(cashOut, data);

        const specific = data.data as MoneySpendingEventTransferRequested;
        cashOut.accountName = specific.accountName;
        cashOut.accountNumber = specific.accountNumber;
        cashOut.transferComment = specific.transferComment;

        return cashOut;
    }

    public store(itemId: number, form: FormRef): Promise<TransferRequested> {
        const data: MoneySpendingItemEvent = {
            data: {
                type: MoneySpendingEventType.TRANSFER_REQUESTED,
                accountName: this.accountName,
                accountNumber: this.accountNumber,
                transferComment: this.transferComment
            },
            comment: this.comment
        };

        return MoneyApi.spendingEventsTransferRequested(itemId, data, {form}).then(response => {
            return TransferRequested.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        return [
            {
                key: MoneySpendingEventTransferRequestedSchema.accountName.label,
                value: this.accountName
            },
            {
                key: MoneySpendingEventTransferRequestedSchema.accountNumber.label,
                value: formatAccountNumber(this.accountNumber)
            },
            {
                key: MoneySpendingEventTransferRequestedSchema.transferComment.label,
                value: this.transferComment
            },
        ];
    }
}
