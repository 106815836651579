/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MapRoomResponse } from './MapRoomResponse';
    import {
    MapRoomResponseFromJSON,
    MapRoomResponseFromJSONTyped,
    MapRoomResponseToJSON,
    MapRoomResponseCreateEmpty,
    } from './MapRoomResponse';
import {DateTime} from '@Utils/DateTime';

export interface MapFloorResponse {
  id: number;
  name: string;
  level: string;
  number: number;
  description: string | null;
  polygon: Array<Array<number>>;
  decoration: Array<Array<number>>;
  buildingId: number;
  rooms: Array<MapRoomResponse>;
}

export const MapFloorResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'level': {
    name: 'level',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'polygon': {
    name: 'polygon',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'decoration': {
    name: 'decoration',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'buildingId': {
    name: 'buildingId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'rooms': {
    name: 'rooms',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfMapFloorResponse(value: object): value is MapFloorResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "polygon" in value;
    isInstance = isInstance && "decoration" in value;
    isInstance = isInstance && "buildingId" in value;
    isInstance = isInstance && "rooms" in value;

return isInstance;
}

export function MapFloorResponseFromJSON(json: any): MapFloorResponse {
return MapFloorResponseFromJSONTyped(json, false);
}

export function MapFloorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapFloorResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'level': json['level'],
          'number': json['number'],
          'description': json['description'],
          'polygon': json['polygon'],
          'decoration': json['decoration'],
          'buildingId': json['buildingId'],
            'rooms': ((json['rooms'] as Array<any>).map(MapRoomResponseFromJSON)),
    };
  }

  export function MapFloorResponseToJSON(value?: MapFloorResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'level': value.level,
                'number': value.number,
                'description': value.description,
            'polygon': value.polygon,
            'decoration': value.decoration,
                'buildingId': value.buildingId,
              'rooms': ((value.rooms as Array<any>).map(MapRoomResponseToJSON)),
    };
  }

  export function MapFloorResponseCreateEmpty(): MapFloorResponse {
  return {
      'id': 0,
      'name': '',
      'level': '',
      'number': 0,
      'description': '',
      'polygon': [],
      'decoration': [],
      'buildingId': 0,
      'rooms': [],
  };
  }

