<template>
    <PegasusHeading>VIKÖ bíráló felület</PegasusHeading>

    <PegasusPanel header="Státusz">
        <div class="row">
            <div class="col-lg-6">
                <dl>
                    <dt>Pályázat állapota</dt>
                    <dd>{{ summary.tender.displayStatus() }}</dd>

                    <dt>Bírálási fázis</dt>
                    <dd v-if="summary.tender.inDrafting">
                        -
                    </dd>
                    <dd v-else>
                        <div v-for="group in summary.tender.reviewerGroups" :key="group.id">
                            {{ group.name }}: {{ group.reviewStageText }}
                            <template v-if="reviewerGroup?.id === group.id">
                                <PegasusButton v-if="group.reviewStage === VikoReviewStage.STAGE_GROUP" :params="{group}" to="viko.reviewer.groupSummary">
                                    Szervezeti bírálói oldal
                                </PegasusButton>

                                <PegasusButton v-if="group.reviewStage === VikoReviewStage.STAGE_NONE" to="viko.reviewer.individual">
                                    Egyéni bírálói oldal
                                </PegasusButton>
                            </template>
                        </div>
                    </dd>

                    <PegasusButton v-if="summary.tender.status === VikoTenderStatus.REVIEWING && summary.tender.computedReviewStage === VikoReviewStage.STAGE_ALL" to="viko.reviewer.summary" variant="primary">
                        Közös bírálói oldal
                    </PegasusButton>

                    <PegasusButton v-if="summary.tender.status === VikoTenderStatus.CALCULATING" to="viko.reviewer.calc" variant="primary">
                        Keretmeghatározó oldal
                    </PegasusButton>
                </dl>
            </div>

            <div class="col-lg-6">
                <dl>
                    <dt>Bíráló neve</dt>
                    <dd>{{ user.name }}</dd>

                    <dt>Bírálói csoport</dt>
                    <dd v-if="reviewerGroup">
                        {{ reviewerGroup.name }}
                    </dd>
                    <dd v-else-if="isModerator">
                        <i>Moderátor</i>
                    </dd>
                    <dd v-else>
                        <i>ADMIN</i>
                    </dd>
                </dl>
            </div>
        </div>
    </PegasusPanel>

    <PegasusPanel class="mt-2" header="Statisztikák">
        <div class="row">
            <div class="col-lg-4">
                <dl>
                    <dt>Leadott pályázatok száma</dt>
                    <dd>{{ summary.applicationsCount }}</dd>

                    <dt>Leadott beszámolók száma</dt>
                    <dd>{{ summary.reportsCount }}</dd>

                    <dt>Átlagos beszámoló hossz</dt>
                    <dd>{{ summary.avgCharacterCount }}</dd>
                </dl>
            </div>
            <div class="col-lg-4">

            </div>
            <div class="col-lg-4">

            </div>
        </div>
    </PegasusPanel>
</template>

<title>VIKÖ bíráló felület</title>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import {VikoReviewStage, VikoTenderStatus} from "@/api/models";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {TenderSummary} from "@Models/viko/TenderSummary";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const user = useAuthStore().requireUser();

const {tenderId} = defineProps<{
    tenderId: number
}>();

const summary = await TenderSummary.getById(tenderId);

const isModerator = summary.tender.isUserModerator(user.value);
const reviewerGroup = summary.tender.findReviewerGroupForUser(user.value);
</script>
