import {Base} from "@Models/Base";
import type {EventsCheckResponse} from "@/api/api";

export class Check extends Base {
    title: string;

    content: string | null;

    help: string | null;

    link: string | null;

    event: number | null;

    static parseResponse(check: Check, data: EventsCheckResponse): Check {
        check.title = data.title;
        check.content = data.content;
        check.help = data.help;
        check.link = data.link;
        check.event = data.event;

        return check;
    }
}
