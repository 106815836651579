/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { MoneyItemCommentType } from './MoneyItemCommentType';
    import {
    MoneyItemCommentTypeFromJSON,
    MoneyItemCommentTypeFromJSONTyped,
    MoneyItemCommentTypeToJSON,
    MoneyItemCommentTypeCreateEmpty,
    } from './MoneyItemCommentType';
import {DateTime} from '@Utils/DateTime';
import { MoneyItemCommentTypeValues } from './MoneyItemCommentType';

export interface MoneyItemCommentResponse {
  id: number;
  creator: BaseMinimalUserResponse;
  type: MoneyItemCommentType;
  text: string;
  createdAt: DateTime;
}

export const MoneyItemCommentResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'Megjegyzés azonosítója',
    type: 'number',
      minimum: 1,
  },
  'creator': {
    name: 'creator',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Megjegyzés típusa",
        enumValues: MoneyItemCommentTypeValues,
  },
  'text': {
    name: 'text',
    required: true,
    nullable: false,
    label: 'Megjegyzés szövege',
    type: 'string',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: 'Megjegyzés létrehozásának időpontja',
    type: 'datetime',
  },
}
export function instanceOfMoneyItemCommentResponse(value: object): value is MoneyItemCommentResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function MoneyItemCommentResponseFromJSON(json: any): MoneyItemCommentResponse {
return MoneyItemCommentResponseFromJSONTyped(json, false);
}

export function MoneyItemCommentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemCommentResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'creator': BaseMinimalUserResponseFromJSON(json['creator']),
              'type': MoneyItemCommentTypeFromJSON(json['type']),
          'text': json['text'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function MoneyItemCommentResponseToJSON(value?: MoneyItemCommentResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'creator': BaseMinimalUserResponseToJSON(value.creator),
                'type': MoneyItemCommentTypeToJSON(value.type),
                'text': value.text,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function MoneyItemCommentResponseCreateEmpty(): MoneyItemCommentResponse {
  return {
      'id': 0,
          'creator': BaseMinimalUserResponseCreateEmpty(),
          'type': MoneyItemCommentTypeCreateEmpty(),
      'text': '',
      'createdAt': DateTime.now(),
  };
  }

