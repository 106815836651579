/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetItemResponse } from './MoneyBudgetItemResponse';
    import {
    MoneyBudgetItemResponseFromJSON,
    MoneyBudgetItemResponseFromJSONTyped,
    MoneyBudgetItemResponseToJSON,
    MoneyBudgetItemResponseCreateEmpty,
    } from './MoneyBudgetItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyExtendedBudgetCategoryResponse {
  children: Array<MoneyExtendedBudgetCategoryResponse>;
  items: Array<MoneyBudgetItemResponse>;
  id: number;
  name: string;
  description: string;
  number: number;
}

export const MoneyExtendedBudgetCategoryResponseSchema = {
  'children': {
    name: 'children',
    required: true,
    nullable: false,
    label: 'Leszármazott csoportok',
      type: 'array',
  },
  'items': {
    name: 'items',
    required: true,
    nullable: false,
    label: 'Tételek',
      type: 'array',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Sorszám',
    type: 'number',
  },
}
export function instanceOfMoneyExtendedBudgetCategoryResponse(value: object): value is MoneyExtendedBudgetCategoryResponse {
let isInstance = true;
    isInstance = isInstance && "children" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "number" in value;

return isInstance;
}

export function MoneyExtendedBudgetCategoryResponseFromJSON(json: any): MoneyExtendedBudgetCategoryResponse {
return MoneyExtendedBudgetCategoryResponseFromJSONTyped(json, false);
}

export function MoneyExtendedBudgetCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyExtendedBudgetCategoryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'children': ((json['children'] as Array<any>).map(MoneyExtendedBudgetCategoryResponseFromJSON)),
            'items': ((json['items'] as Array<any>).map(MoneyBudgetItemResponseFromJSON)),
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'number': json['number'],
    };
  }

  export function MoneyExtendedBudgetCategoryResponseToJSON(value?: MoneyExtendedBudgetCategoryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'children': ((value.children as Array<any>).map(MoneyExtendedBudgetCategoryResponseToJSON)),
              'items': ((value.items as Array<any>).map(MoneyBudgetItemResponseToJSON)),
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'number': value.number,
    };
  }

  export function MoneyExtendedBudgetCategoryResponseCreateEmpty(): MoneyExtendedBudgetCategoryResponse {
  return {
      'children': [],
      'items': [],
      'id': 0,
      'name': '',
      'description': '',
      'number': 0,
  };
  }

