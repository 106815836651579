<template>
    <PegasusHeading>Körök adminisztrációja</PegasusHeading>

    <PegasusTable :data="groups" search>
        <Column field="name" header="Név" sortable></Column>

        <Column field="active" header="Aktív">
            <template #body="{data}: {data: Group}">
                <i v-if="data.active" class="fa fa-check text-green"></i>
                <i v-else class="fa fa-times text-red"></i>
            </template>
        </Column>

        <Column field="kprEnabled" header="KPR">
            <template #body="{data}: {data: Group}">
                <i v-if="data.kprEnabled" class="fa fa-check text-green"></i>
                <i v-else class="fa fa-times text-red"></i>
            </template>
        </Column>

        <Column field="vikoEnabled" header="VIKÖ">
            <template #body="{data}: {data: Group}">
                <i v-if="data.vikoEnabled" class="fa fa-check text-green"></i>
                <i v-else class="fa fa-times text-red"></i>
            </template>
        </Column>

        <Column field="eventEnabled" header="Rendezvény">
            <template #body="{data}: {data: Group}">
                <i v-if="data.eventEnabled" class="fa fa-check text-green"></i>
                <i v-else class="fa fa-times text-red"></i>
            </template>
        </Column>

        <Column field="inventoryEnabled" header="Leltár">
            <template #body="{data}: {data: Group}">
                <i v-if="data.inventoryEnabled" class="fa fa-check text-green"></i>
                <i v-else class="fa fa-times text-red"></i>
            </template>
        </Column>

        <Column field="resortText" header="Reszort"/>

        <Column header="Műveletek">
            <template #body="{data}: {data: Group}">
                <PegasusButton :params="{group: data}" to="rvt.groups.show">
                    Megtekintés
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<title>Kör adminisztráció</title>

<script lang="ts" setup>
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import type {Group} from "@Models/base/Group";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import {BaseGlobalPermission} from "@/api/models";
import {AccessDeniedError} from "@Utils/errors/AccessDeniedError";
import {useConstantsStore} from "@/stores/constants";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const user = useAuthStore().requireUser();

if (!user.value.hasPermission(BaseGlobalPermission.ADMIN_ACL)) {
    throw new AccessDeniedError();
}

const groups = useConstantsStore().constants.groups.all;
</script>
