<template>
    <Project
        v-for="project in capp.itemGroups"
        :key="project.id"
        :permissions="capp.getPermissions()"
        :project="project"
        @delete-project="deleteProject(project)"
    >
    </Project>

    <PegasusButton v-if="capp.canEdit" variant="success" @click="newProject">
        <i class="fas fa-plus-square-o"></i>
        Új projekt hozzáadása
    </PegasusButton>
</template>

<script lang="ts" setup>
import Project from "./Project.vue";
import {CategoryApplication} from "@Models/rvt/kpr/CategoryApplication";
import {ItemGroup} from "@Models/money/ItemGroup";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";

const {capp} = defineProps<{
    capp: CategoryApplication
}>();

async function deleteProject(project: ItemGroup) {
    if (!await toast.confirm('A művelet visszavonhatatlan! Biztosan ki akarod törölni a projektet és összes benne lévő csomagot és tételt?')) {
        return;
    }

    let request = project.delete().then(() => {
        delete capp.itemGroups[project.id];
    });

    toast.loading(request, 'Törlés');
}

function newProject() {
    const project = ItemGroup.createNew('Névtelen projekt');

    const request = project.storeForApplication(capp.id).then(async() => {
        const base = ItemGroup.createNew(
            'Alap',
            'Alapcsomag ami a pályázat által kitűzött célok eléréséhez minimum szükséges és megbonthatatlan'
        );
        project.alternativeChildren[base.id] = base;

        await base.storeForParentAsAlternative(project.id);

        capp.itemGroups[project.id] = project;
    });

    toast.loading(request);
}
</script>
