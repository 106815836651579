<template>
    <SmartField :inline="inline" :schema="schema" without-group>
        <template #default="{id, isValid}">
            <div :class="withoutLabel ? '' : 'row my-2'">
                <div v-if="!withoutLabel && inline" class="col-lg-6 pb-4">
                    <slot name="label">
                        <b class="me-1">{{ schema.label }}</b>

                        <PegasusHelp v-if="schema.labelHelp" :title="schema.label" wide>
                            <span class="whitespace">{{ schema.labelHelp }}</span>
                        </PegasusHelp>

                        <PegasusHelp v-if="labelWarning" :title="schema.label ?? ''" variant="warning">
                            {{ labelWarning }}
                        </PegasusHelp>
                    </slot>
                </div>

                <div :class="withoutLabel ? '' : 'col-lg-1'">
                    <InputSwitch
                        v-model="modelValue"

                        :class="{'p-invalid': isValid === false}"
                        :disabled="disabled"
                        :input-id="id"
                        :pt="{hiddenInput: {'data-input-name': schema.name}}"
                        :required="schema.required"
                    ></InputSwitch>
                </div>

                <div v-if="!withoutLabel && !inline" class="col-lg-8 p-text-secondary">
                    <slot name="label">
                        <b class="me-1">{{ schema.label }}</b>

                        <PegasusHelp v-if="schema.labelHelp" :title="schema.label" wide>
                            <span class="whitespace">{{ schema.labelHelp }}</span>
                        </PegasusHelp>

                        <PegasusHelp v-if="labelWarning" :title="schema.label ?? ''" variant="warning">
                            {{ labelWarning }}
                        </PegasusHelp>
                    </slot>
                </div>
            </div>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import SmartField from "@Components/base/form/SmartField.vue";
import InputSwitch from "primevue/inputswitch";
import PegasusHelp from '@Components/base/PegasusHelp.vue';

const modelValue = defineModel<boolean>({required: true});

const {schema, disabled, labelWarning, inline, withoutLabel} = defineProps<{
    disabled?: boolean;
    labelWarning?: string
    schema: FieldSchema;
    inline?: boolean;
    withoutLabel?: boolean
}>();
</script>
