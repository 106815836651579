import {BaseEntity} from "@Models/BaseEntity";
import {DateTime} from "@Utils/DateTime";
import {MoneyApi, type RvtSpendingPeriodResponse} from "@/api/api";
import {useConstantsStore} from '@/stores/constants';
import {Tender} from '@Models/rvt/kpr/Tender';
import {find} from 'lodash-es';
import {BudgetCategory} from '@Models/money/BudgetCategory';

export class SpendingPeriod extends BaseEntity {
    from: DateTime;

    to: DateTime;

    claimsBudget: number;

    tender: Tender | null;

    static getByYear(year: number): SpendingPeriod | undefined {
        return find(useConstantsStore().constants.money.spendingPeriods, period => period.year === year);
    }

    static getBySingleId(id: number): SpendingPeriod {
        return useConstantsStore().constants.money.spendingPeriods[id];
    }

    static getAll(): Record<number, SpendingPeriod> {
        return useConstantsStore().constants.money.spendingPeriods;
    }

    static parseResponse(period: SpendingPeriod, data: RvtSpendingPeriodResponse): SpendingPeriod {
        period.id = data.id;
        period.from = data.from;
        period.to = data.to;
        period.claimsBudget = data.claimsBudget;
        period.tender = Tender.newSingleNullable(data.tender, Tender.parseResponse);

        return period;
    }

    get name(): string {
        return 'Gazdasági év ' + this.from.year;
    }

    get year(): number {
        return this.from.year;
    }

    get dates(): string {
        return this.from.datetime() + ' - ' + this.to.datetime();
    }

    public isClaimable(): boolean {
        return this.from.isPast() && this.to.isFuture();
    }

    public getBudget(): Promise<Record<number, BudgetCategory>> {
        return MoneyApi.spendingPeriodsBudget(this.id).then(response => {
            return BudgetCategory.newRecords(response.data.categories, BudgetCategory.parseExtendedResponse);
        });
    }
}
