<template>
    <PegasusHeading>VIKÖ Vonalhúzó</PegasusHeading>

    <div class="row">
        <div class="col-lg-4">
            <PegasusPanel header="Pontszámok" header-bg-variant="primary">
                <div class="row">
                    <div v-for="(pointList, range) in displayPoints" :key="range" class="col-sm-3">
                        <table id="app-counts" class="table table-bordered table-sm">
                            <tr v-for="point in pointList" :key="point.point.toString()">
                                <td class="fw-bold">{{ point.point.toFixed(2) }}</td>

                                <td>{{ point.numberOfApplications }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </PegasusPanel>
        </div>

        <div class="col-lg-4">
            <PegasusPanel :header-bg-variant="dirty ? 'warning' : 'success'" header="Beállítások">
                <NumberField
                    v-model="summary.tender.budget"
                    :disabled="!summary.canEdit"
                    :schema="VikoCalcUpdateSchema.budget"
                    @update:model-value="dirty = true"
                ></NumberField>

                <hr class="my-4">

                <template v-for="category in summary.tender.resultCategories" :key="category.id">
                    <TextField
                        v-model="category.name"
                        :disabled="!summary.canEdit"
                        :schema="VikoResultCategoryUpdateSchema.name"
                    ></TextField>

                    <div class="row">
                        <div class="col-sm-5">
                            <NumberField
                                v-model="category.boundary"
                                :disabled="!summary.canEdit"
                                :schema="VikoResultCategoryUpdateSchema.boundary"
                                :step="0.01"
                                buttons
                                without-group
                                @update:model-value="dirty = true"
                            ></NumberField>
                        </div>

                        <div class="col-sm-5">
                            <NumberField
                                v-model="category.money"
                                :disabled="!summary.canEdit"
                                :schema="VikoResultCategoryUpdateSchema.money"
                                :step="1000"
                                grouping
                                number-suffix="HUF"
                                @update:model-value="dirty = true"
                            ></NumberField>
                        </div>

                        <div class="col-sm-2">
                            <PegasusButton :disabled="!summary.canEdit" class="my-3" size="sm" variant="danger" @click="deleteCategory(category)">
                                <i class="fa fa-times"></i>
                            </PegasusButton>
                        </div>
                    </div>

                    <hr>
                </template>

                <PegasusButton v-if="summary.canEdit" variant="success" @click="save">
                    Mentés
                </PegasusButton>

                <PegasusButton v-if="summary.canEdit" block @click="createCategory">
                    Kategória létrehozása
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-lg-4">
            <PegasusPanel header="Eredmény">
                <label>Összes pályázó: {{ applicationsCount }}</label>
                <hr>
                <div v-for="category in summary.tender.resultCategories" :key="category.id">
                    <label>{{ category.name }} nyertesek: {{ count[category.id] }}</label>
                </div>

                <label>Semmit se kap: {{ remaining }}</label><br/>

                <hr>

                <label>Teljes költés: {{ sumMoney.huf() }}</label><br/>
                <label>Fennmaradó összeg: {{ ((summary.tender.budget ?? 0) - sumMoney).huf() }}</label>

                <Message v-if="(summary.tender.budget ?? 0) - sumMoney < 0" :closable="false" severity="error">
                    Túlköltés!
                </Message>

                <Message v-else :closable="false" severity="success">
                    Megfelelő
                </Message>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>VIKÖ keretszámító</title>

<script lang="ts" setup>
import {computed, ref} from "vue";
import toast from "@Utils/toast";
import {CalcSummary} from "@Models/viko/CalcSummary";
import Message from "primevue/message";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import NumberField from "@Components/base/form/types/NumberField.vue";
import {floor, groupBy, sortBy, sum, sumBy, values} from "lodash-es";
import {ResultCategory} from "@Models/viko/ResultCategory";
import {VikoCalcUpdateSchema, VikoResultCategoryUpdateSchema} from "@/api/models";
import TextField from "@Components/base/form/types/TextField.vue";
import {usePreventExit} from "@Utils/composables";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';

useAuthStore().requireUser();

const {tenderId} = defineProps<{
    tenderId: number
}>();

const summary = ref(await CalcSummary.get(tenderId));

const dirty = ref(false);

usePreventExit(() => summary.value.canEdit && dirty.value);

function save() {
    let request = summary.value.update().then(() => {
        dirty.value = false;
    });

    toast.loading(request);
}

function createCategory() {
    const result = new ResultCategory();
    result.name = 'Új kategória';
    result.boundary = 2;
    result.money = 0;

    const request = result.store(summary.value.tender.id).then(category => {
        summary.value.tender.resultCategories[category.id] = category;
    });

    toast.loading(request);
}

async function deleteCategory(category: ResultCategory) {
    if (!await toast.confirm('Biztosan ki akarod törölni a ' + category.name + ' kategóriát')) {
        return;
    }

    let request = category.delete().then(() => {
        delete summary.value.tender.resultCategories[category.id];
    });

    toast.loading(request, 'Törlés');
}

const displayPoints = computed(() => groupBy(summary.value.points, point => floor(point.point)));

const count = computed((): Record<number, number> => {
    let categories = sortBy(summary.value.tender.resultCategories, category => 1 - category.boundary);

    let result: Record<number, number> = {};

    categories.forEach(category => result[category.id] = 0);

    let category: ResultCategory;

    for (let index in summary.value.points) {
        let {point, numberOfApplications} = summary.value.points[index];

        for (category of categories) {
            if (point >= category.boundary) {
                result[category.id] += numberOfApplications;
                break;
            }
        }
    }

    return result;
});

const applicationsCount = computed(() => sumBy(values(summary.value.points), point => point.numberOfApplications));

const remaining = computed((): number => {
    const gottenCount = sum(values(count.value));

    return applicationsCount.value - gottenCount;
});

const sumMoney = computed((): number => {
    return sumBy(
        values(summary.value.tender.resultCategories),
        category => category.money * count.value[category.id] * 5
    );
});
</script>
