/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsMentorResponse } from './MentorsMentorResponse';
    import {
    MentorsMentorResponseFromJSON,
    MentorsMentorResponseFromJSONTyped,
    MentorsMentorResponseToJSON,
    MentorsMentorResponseCreateEmpty,
    } from './MentorsMentorResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsMentorListResponse {
  mentors: Array<MentorsMentorResponse>;
}

export const MentorsMentorListResponseSchema = {
  'mentors': {
    name: 'mentors',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfMentorsMentorListResponse(value: object): value is MentorsMentorListResponse {
let isInstance = true;
    isInstance = isInstance && "mentors" in value;

return isInstance;
}

export function MentorsMentorListResponseFromJSON(json: any): MentorsMentorListResponse {
return MentorsMentorListResponseFromJSONTyped(json, false);
}

export function MentorsMentorListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsMentorListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'mentors': ((json['mentors'] as Array<any>).map(MentorsMentorResponseFromJSON)),
    };
  }

  export function MentorsMentorListResponseToJSON(value?: MentorsMentorListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'mentors': ((value.mentors as Array<any>).map(MentorsMentorResponseToJSON)),
    };
  }

  export function MentorsMentorListResponseCreateEmpty(): MentorsMentorListResponse {
  return {
      'mentors': [],
  };
  }

