<template>
    <div :id="'group-' + group.id" class="kpr-group-box">
        <div class="kpr-group card card-solid">
            <div class="card-header with-border bg-primary">
                <PegasusOverlay :content="group.description" :header="dummy ? dummy : group.name">
                    <h3 class="card-title">
                        {{ dummy ? dummy : group.name }}
                    </h3>
                    <div class="float-end">
                        <PegasusButton v-if="permissions.canDecide" size="xs" variant="success" @click="approveAll">
                            <i class="fa fa-xs fa-check"></i> Összes elfogadása
                        </PegasusButton>

                        <PegasusButton v-if="permissions.canDecide" size="xs" variant="danger" @click="killAll">
                            <i class="fa fa-xs fa-check"></i> Összes nullázása
                        </PegasusButton>

                        <span class="badge badge-pill bg-info bg-opacity-50 ms-1" style="font-size: 0.7rem;">
                            {{ size(group.items) }} tétel
                        </span>
                        <span class="badge badge-pill bg-info bg-opacity-50 ms-1" style="font-size: 0.7rem;">
                            {{ group.calculateSumDecided().huf() }} / {{ group.calculateSum().huf() }}
                        </span>

                        <button v-if="!dummy" class="btn btn-tool" type="button" @click="edit">
                            <i class="fa fa-sm fa-edit"></i>
                        </button>

                        <button
                            v-if="permissions.canEdit && !dummy"
                            class="btn btn-tool"
                            @click.prevent="removeGroup"
                        >
                            <i class="fa fa-sm fa-times"></i>
                        </button>
                    </div>
                </PegasusOverlay>
            </div>

            <div class="card-body">
                <ItemComponent
                    v-for="item in group.items"
                    :key="item.id"
                    ref="itemRefs"
                    :item="item"
                    :permissions="permissions"
                    @delete="deleteItem(item)"
                >
                </ItemComponent>
            </div>

            <div class="card-footer">
                <PegasusButton v-if="permissions.canEdit" variant="success" @click="addItem">
                    <i class="fa fa-plus"></i> Új tétel hozzáadása
                </PegasusButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {ItemGroup} from "@Models/money/ItemGroup";
import type {KprPermissions} from "@Models/rvt/kpr/Application";
import ItemComponent from "@Components/money/item/Item.vue";
import toast from "@Utils/toast";
import {Item} from "@Models/money/Item";
import {type Ref, ref} from "vue";
import {each, size} from "lodash-es";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {Dialog} from '@Utils/dialog';
import GroupEditorDialog from '@Components/kpr/GroupEditorDialog.vue';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';

const group = defineModel<ItemGroup>('group', {required: true});

const {permissions, dummy} = defineProps<{
    permissions: KprPermissions,
    dummy?: string
}>();

const emit = defineEmits<{
    deleteGroup: [],
}>();

const itemRefs: Ref<InstanceType<typeof ItemComponent>[]> = ref([]);

async function removeGroup() {
    if (!await toast.confirm('A művelet visszavonhatatlan! Biztosan ki akarod törölni ezt a tételcsoportot és a benne lévő összes tételt?')) {
        return;
    }

    emit('deleteGroup');
}

function addItem() {
    const item = Item.create('Új tétel', 0);

    let request = item.store(group.value.id).then(() => {
        group.value.items[item.id] = item;
    });

    toast.loading(request, 'Létrehozás');
}

async function deleteItem(item: Item) {
    if (!await toast.confirm('A művelet visszavonhatatlan! Biztosan ki akarod törölni ezt a tételt?')) {
        return;
    }

    let request = item.delete().then(() => {
        delete group.value.items[item.id];
    });

    toast.loading(request, 'Törlés');
}

function edit() {
    Dialog.open(GroupEditorDialog, {
        itemGroup: group,
        canEdit: permissions.canEdit,
    });
}

async function saveAll() {
    each(itemRefs.value, item => item.saveIfDirty());
}

async function approveAll() {
    if (!await toast.confirm('Biztos elfogadod az összes tételt?')) {
        return;
    }

    each(itemRefs.value, item => item.approveIfNotAlready());
}

async function killAll() {
    if (!await toast.confirm('Biztos kinullázod az összes tételt?')) {
        return;
    }

    each(itemRefs.value, item => item.decide(0));
}

defineExpose({
    saveAll,
});
</script>
