import {Base} from "@Models/Base";
import {OwnerSummary} from "@Models/inventory/OwnerSummary";
import {InventoryGroupSummary} from "@Models/inventory/InventoryGroupSummary";
import type {InventoryAdminSummaryResponse} from "@/api/api";
import {InventoryApi} from "@/api/api";
import type {Tag} from '@Models/inventory/Tag';
import {useConstantsStore} from '@/stores/constants';
import {keyBy, map} from 'lodash-es';

export class AdminSummary extends Base {
    itemCount: number;

    priceTotal: number;

    tagCounts: Record<number, {
        tag: Tag;

        count: number;
    }>;

    owners: Record<number, OwnerSummary>;

    groups: Record<number, InventoryGroupSummary>;

    static get(): Promise<AdminSummary> {
        return InventoryApi.adminSummary().then(response => {
            return AdminSummary.newSingle(response.data, AdminSummary.parseResponse);
        });
    }

    static parseResponse(summary: AdminSummary, data: InventoryAdminSummaryResponse): AdminSummary {
        summary.itemCount = data.itemCount;
        summary.priceTotal = data.priceTotal;

        const tags = useConstantsStore().constants.inventory.tags;
        const tagCounts = map(data.tagCounts, (count, tagId) => {
            return {
                tag: tags[parseInt(tagId)],
                count: count
            };
        });

        summary.tagCounts = keyBy(tagCounts, tagCount => tagCount.tag.id);

        summary.owners = OwnerSummary.newRecordsBy(data.owners, OwnerSummary.parseResponse, (summary: OwnerSummary) => summary.owner.id.toString());
        summary.groups = InventoryGroupSummary.newRecordsBy(data.groups, InventoryGroupSummary.parseResponse, (summary: InventoryGroupSummary) => summary.group.id.toString());

        return summary;
    }
}
