/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprTenderResponse } from './KprTenderResponse';
    import {
    KprTenderResponseFromJSON,
    KprTenderResponseFromJSONTyped,
    KprTenderResponseToJSON,
    KprTenderResponseCreateEmpty,
    } from './KprTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface KprTenderSummaryResponse {
  tender: KprTenderResponse;
  applicationsCount: number;
  declinedAmends: number;
  successfulAmends: number;
  amendsBalance: number;
}

export const KprTenderSummaryResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'applicationsCount': {
    name: 'applicationsCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'declinedAmends': {
    name: 'declinedAmends',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'successfulAmends': {
    name: 'successfulAmends',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'amendsBalance': {
    name: 'amendsBalance',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfKprTenderSummaryResponse(value: object): value is KprTenderSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "applicationsCount" in value;
    isInstance = isInstance && "declinedAmends" in value;
    isInstance = isInstance && "successfulAmends" in value;
    isInstance = isInstance && "amendsBalance" in value;

return isInstance;
}

export function KprTenderSummaryResponseFromJSON(json: any): KprTenderSummaryResponse {
return KprTenderSummaryResponseFromJSONTyped(json, false);
}

export function KprTenderSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprTenderSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': KprTenderResponseFromJSON(json['tender']),
          'applicationsCount': json['applicationsCount'],
          'declinedAmends': json['declinedAmends'],
          'successfulAmends': json['successfulAmends'],
          'amendsBalance': json['amendsBalance'],
    };
  }

  export function KprTenderSummaryResponseToJSON(value?: KprTenderSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': KprTenderResponseToJSON(value.tender),
                'applicationsCount': value.applicationsCount,
                'declinedAmends': value.declinedAmends,
                'successfulAmends': value.successfulAmends,
                'amendsBalance': value.amendsBalance,
    };
  }

  export function KprTenderSummaryResponseCreateEmpty(): KprTenderSummaryResponse {
  return {
          'tender': KprTenderResponseCreateEmpty(),
      'applicationsCount': 0,
      'declinedAmends': 0,
      'successfulAmends': 0,
      'amendsBalance': 0,
  };
  }

