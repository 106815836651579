import {BaseEntity} from "@Models/BaseEntity";
import {Room} from "@Models/map/Room";
import type {MapFloorResponse} from "@/api/api";

export class Floor extends BaseEntity {
    name: string;

    level: string;

    number: number;

    description: string | null;

    polygon: any;

    decoration: any;

    rooms: Record<number, Room>;

    static parseResponse(floor: Floor, data: MapFloorResponse): Floor {
        floor.id = data.id;
        floor.name = data.name;
        floor.level = data.level;
        floor.number = data.number;
        floor.description = data.description;
        floor.polygon = data.polygon;
        floor.decoration = data.decoration;
        floor.rooms = Room.newRecords(data.rooms, Room.parseResponse);

        return floor;
    }
}
