/* tslint:disable */
/* eslint-disable */

export enum MoneySpendingMethod {
    CASH = 'CASH',
    TRANSFER = 'TRANSFER',
    CARD = 'CARD'
}

export const MoneySpendingMethodValues: Record<MoneySpendingMethod, string> = {
    'CASH': 'Készpénz',
    'TRANSFER': 'Utalás',
    'CARD': 'Bankkártyás'
}


export function MoneySpendingMethodFromJSON(json: any): MoneySpendingMethod {
    return MoneySpendingMethodFromJSONTyped(json, false);
}

export function MoneySpendingMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingMethod {
    return json as MoneySpendingMethod;
}

export function MoneySpendingMethodToJSON(value?: MoneySpendingMethod | null): any {
    return value as any;
}

export function MoneySpendingMethodCreateEmpty(): MoneySpendingMethod {
    return MoneySpendingMethod.CASH;
}

