/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyItemResponse } from './MoneyItemResponse';
    import {
    MoneyItemResponseFromJSON,
    MoneyItemResponseFromJSONTyped,
    MoneyItemResponseToJSON,
    MoneyItemResponseCreateEmpty,
    } from './MoneyItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyItemPaginationResponse {
  data: Array<MoneyItemResponse>;
  currentPage: number;
  lastPage: number;
  perPage: number;
  total: number;
}

export const MoneyItemPaginationResponseSchema = {
  'data': {
    name: 'data',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'currentPage': {
    name: 'currentPage',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'lastPage': {
    name: 'lastPage',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'perPage': {
    name: 'perPage',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'total': {
    name: 'total',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfMoneyItemPaginationResponse(value: object): value is MoneyItemPaginationResponse {
let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "currentPage" in value;
    isInstance = isInstance && "lastPage" in value;
    isInstance = isInstance && "perPage" in value;
    isInstance = isInstance && "total" in value;

return isInstance;
}

export function MoneyItemPaginationResponseFromJSON(json: any): MoneyItemPaginationResponse {
return MoneyItemPaginationResponseFromJSONTyped(json, false);
}

export function MoneyItemPaginationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemPaginationResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'data': ((json['data'] as Array<any>).map(MoneyItemResponseFromJSON)),
          'currentPage': json['currentPage'],
          'lastPage': json['lastPage'],
          'perPage': json['perPage'],
          'total': json['total'],
    };
  }

  export function MoneyItemPaginationResponseToJSON(value?: MoneyItemPaginationResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'data': ((value.data as Array<any>).map(MoneyItemResponseToJSON)),
                'currentPage': value.currentPage,
                'lastPage': value.lastPage,
                'perPage': value.perPage,
                'total': value.total,
    };
  }

  export function MoneyItemPaginationResponseCreateEmpty(): MoneyItemPaginationResponse {
  return {
      'data': [],
      'currentPage': 0,
      'lastPage': 0,
      'perPage': 0,
      'total': 0,
  };
  }

