import {Base} from "@Models/Base";
import type {BaseGlobalPermission, BasePermissionResponse} from "@/api/api";

export class Permission extends Base {
    id: BaseGlobalPermission;

    name: string;

    description: string;

    static parseResponse(permission: Permission, data: BasePermissionResponse): Permission {
        permission.id = data.id;
        permission.name = data.name;
        permission.description = data.description;

        return permission;
    }
}
