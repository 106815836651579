/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MentorsAssignmentMonthResponse } from './MentorsAssignmentMonthResponse';
    import {
    MentorsAssignmentMonthResponseFromJSON,
    MentorsAssignmentMonthResponseFromJSONTyped,
    MentorsAssignmentMonthResponseToJSON,
    MentorsAssignmentMonthResponseCreateEmpty,
    } from './MentorsAssignmentMonthResponse';
    import type { MentorsMentorResponse } from './MentorsMentorResponse';
    import {
    MentorsMentorResponseFromJSON,
    MentorsMentorResponseFromJSONTyped,
    MentorsMentorResponseToJSON,
    MentorsMentorResponseCreateEmpty,
    } from './MentorsMentorResponse';
import {DateTime} from '@Utils/DateTime';

export interface MentorsAdminResponse {
  mentors: Array<MentorsMentorResponse>;
  previous: MentorsAssignmentMonthResponse;
  current: MentorsAssignmentMonthResponse;
  next: MentorsAssignmentMonthResponse;
}

export const MentorsAdminResponseSchema = {
  'mentors': {
    name: 'mentors',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'previous': {
    name: 'previous',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'current': {
    name: 'current',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'next': {
    name: 'next',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfMentorsAdminResponse(value: object): value is MentorsAdminResponse {
let isInstance = true;
    isInstance = isInstance && "mentors" in value;
    isInstance = isInstance && "previous" in value;
    isInstance = isInstance && "current" in value;
    isInstance = isInstance && "next" in value;

return isInstance;
}

export function MentorsAdminResponseFromJSON(json: any): MentorsAdminResponse {
return MentorsAdminResponseFromJSONTyped(json, false);
}

export function MentorsAdminResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsAdminResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'mentors': ((json['mentors'] as Array<any>).map(MentorsMentorResponseFromJSON)),
              'previous': MentorsAssignmentMonthResponseFromJSON(json['previous']),
              'current': MentorsAssignmentMonthResponseFromJSON(json['current']),
              'next': MentorsAssignmentMonthResponseFromJSON(json['next']),
    };
  }

  export function MentorsAdminResponseToJSON(value?: MentorsAdminResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'mentors': ((value.mentors as Array<any>).map(MentorsMentorResponseToJSON)),
                'previous': MentorsAssignmentMonthResponseToJSON(value.previous),
                'current': MentorsAssignmentMonthResponseToJSON(value.current),
                'next': MentorsAssignmentMonthResponseToJSON(value.next),
    };
  }

  export function MentorsAdminResponseCreateEmpty(): MentorsAdminResponse {
  return {
      'mentors': [],
          'previous': MentorsAssignmentMonthResponseCreateEmpty(),
          'current': MentorsAssignmentMonthResponseCreateEmpty(),
          'next': MentorsAssignmentMonthResponseCreateEmpty(),
  };
  }

