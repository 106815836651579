/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { MoneyItemGroupWithItemsResponse } from './MoneyItemGroupWithItemsResponse';
    import {
    MoneyItemGroupWithItemsResponseFromJSON,
    MoneyItemGroupWithItemsResponseFromJSONTyped,
    MoneyItemGroupWithItemsResponseToJSON,
    MoneyItemGroupWithItemsResponseCreateEmpty,
    } from './MoneyItemGroupWithItemsResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtClaimResponse {
  groupId: number;
  objective: string | null;
  reasoning: string | null;
  itemGroup: MoneyItemGroupWithItemsResponse;
  id: number;
  requester: BaseMinimalUserResponse;
  requesterPost: string;
  finalized: DateTime | null;
  approved: DateTime | null;
  decision: boolean | null;
  decisionTime: DateTime | null;
  createdAt: DateTime;
  canEdit: boolean;
  canFinalize: boolean;
  canApprove: boolean;
  canDecide: boolean;
}

export const RvtClaimResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'objective': {
    name: 'objective',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'reasoning': {
    name: 'reasoning',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'itemGroup': {
    name: 'itemGroup',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'requester': {
    name: 'requester',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'requesterPost': {
    name: 'requesterPost',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'finalized': {
    name: 'finalized',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'approved': {
    name: 'approved',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'decision': {
    name: 'decision',
    required: true,
    nullable: true,
    label: '',
    type: 'boolean',
  },
  'decisionTime': {
    name: 'decisionTime',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canFinalize': {
    name: 'canFinalize',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canApprove': {
    name: 'canApprove',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canDecide': {
    name: 'canDecide',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfRvtClaimResponse(value: object): value is RvtClaimResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "objective" in value;
    isInstance = isInstance && "reasoning" in value;
    isInstance = isInstance && "itemGroup" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "requester" in value;
    isInstance = isInstance && "requesterPost" in value;
    isInstance = isInstance && "finalized" in value;
    isInstance = isInstance && "approved" in value;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "decisionTime" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "canEdit" in value;
    isInstance = isInstance && "canFinalize" in value;
    isInstance = isInstance && "canApprove" in value;
    isInstance = isInstance && "canDecide" in value;

return isInstance;
}

export function RvtClaimResponseFromJSON(json: any): RvtClaimResponse {
return RvtClaimResponseFromJSONTyped(json, false);
}

export function RvtClaimResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtClaimResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
          'objective': json['objective'],
          'reasoning': json['reasoning'],
              'itemGroup': MoneyItemGroupWithItemsResponseFromJSON(json['itemGroup']),
          'id': json['id'],
              'requester': BaseMinimalUserResponseFromJSON(json['requester']),
          'requesterPost': json['requesterPost'],
        'finalized': (json['finalized'] === null ? null : DateTime.fromISO(json['finalized'])),
        'approved': (json['approved'] === null ? null : DateTime.fromISO(json['approved'])),
          'decision': json['decision'],
        'decisionTime': (json['decisionTime'] === null ? null : DateTime.fromISO(json['decisionTime'])),
        'createdAt': (DateTime.fromISO(json['createdAt'])),
          'canEdit': json['canEdit'],
          'canFinalize': json['canFinalize'],
          'canApprove': json['canApprove'],
          'canDecide': json['canDecide'],
    };
  }

  export function RvtClaimResponseToJSON(value?: RvtClaimResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
                'objective': value.objective,
                'reasoning': value.reasoning,
                'itemGroup': MoneyItemGroupWithItemsResponseToJSON(value.itemGroup),
                'id': value.id,
                'requester': BaseMinimalUserResponseToJSON(value.requester),
                'requesterPost': value.requesterPost,
            'finalized': (
              value.finalized === null ? null : value.finalized.toISO()),
            'approved': (
              value.approved === null ? null : value.approved.toISO()),
                'decision': value.decision,
            'decisionTime': (
              value.decisionTime === null ? null : value.decisionTime.toISO()),
            'createdAt': (value.createdAt.toISO()),
                'canEdit': value.canEdit,
                'canFinalize': value.canFinalize,
                'canApprove': value.canApprove,
                'canDecide': value.canDecide,
    };
  }

  export function RvtClaimResponseCreateEmpty(): RvtClaimResponse {
  return {
      'groupId': 0,
      'objective': '',
      'reasoning': '',
          'itemGroup': MoneyItemGroupWithItemsResponseCreateEmpty(),
      'id': 0,
          'requester': BaseMinimalUserResponseCreateEmpty(),
      'requesterPost': '',
      'finalized': DateTime.now(),
      'approved': DateTime.now(),
      'decision': false,
      'decisionTime': DateTime.now(),
      'createdAt': DateTime.now(),
      'canEdit': false,
      'canFinalize': false,
      'canApprove': false,
      'canDecide': false,
  };
  }

