<template>
    <DataTable
        ref="table"
        v-model:filters="filters"
        v-model:selection="selected"

        :data-key="dataKeyFunction === null ? undefined : dataKey"
        :global-filter-fields="globalFields as string[]"
        :lazy="isFunction(data)"
        :loading="busy"
        :row-class="trClass"
        :rows="perPage"
        :selection-mode="selectionMode"
        :show-gridlines="showGridlines"
        :total-records="totalRows"
        :value="normalizedData"

        filter-display="menu"
        :paginator="totalRows > perPage"
        @page="pagingEvent"
    >
        <slot></slot>

        <template v-if="!isEmpty(globalFields)" #header>
            <div class="d-flex justify-content-end">
                <span class="p-input-icon-left">
                    <i class="fa fa-search"/>
                    <InputText v-model="filters['global'].value" placeholder="Keresés" @change="loadData"/>
                </span>
            </div>
        </template>

        <template v-if="$slots.footer" #footer>
            <slot name="footer"></slot>
        </template>

        <template #empty>
            <span v-if="busy">
                Betöltés folyamatban
            </span>
            <span v-else>
                {{ emptyText }}
            </span>
        </template>
    </DataTable>
</template>

<script generic="T extends object" lang="ts" setup>
import {defineSlots, type Ref, ref, watch} from "vue";
import {filter, forEach, isArray, isEmpty, isFunction, size, toArray} from "lodash-es";
import type {DataTablePageEvent} from "primevue/datatable";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import type {DataTableCustomFilterMeta} from '@/prime';
import {FilterMatchMode} from 'primevue/api';
import type {ComponentInstance, DataFunction, DataKeyFunction, RowFunction} from '@/vue';
import type {ColumnNode} from 'primevue/column';

type Data = T[] | Record<number, T>;

const selected = defineModel<T | undefined>('selected');

const {
    data,
    dataKeyFunction,
    perPage = 15,
    rowVariant,
    noWrap = false,
    emptyText = 'Nem található elem',
    selectionMode,
    showGridlines,
    searchFields,
    search,
} = defineProps<{
    data: Data | DataFunction<Data>,
    dataKeyFunction?: DataKeyFunction<T> | null,
    perPage?: number,
    rowVariant?: RowFunction<T>,
    noWrap?: boolean,
    emptyText?: string,
    loading?: boolean,
    selectionMode?: 'single' | 'multiple',
    showGridlines?: boolean,
    searchFields?: string[],
    search?: boolean
}>();

const page = ref(1);
defineSlots<{
    footer?(props: {}): any,
    default?(props: {}): any
}>();

const filters = ref<DataTableCustomFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS}
});

type Field = string | ((item: any) => string);

const globalFields = ref<Field[]>(searchFields ?? []);

const table = ref<ComponentInstance<typeof DataTable> | null>(null);

if (search) {
    watch(table, newValue => {
        if (!newValue) {
            return;
        }
        if (!('columns' in newValue)) {
            return;
        }
        if (searchFields) {
            return;
        }

        forEach(newValue.columns as ColumnNode[], column => {
            if (column.props.field) {
                globalFields.value.push(column.props.field);
            }
        });
    });
}

const busy = ref(false);
const normalizedData: Ref<T[]> = ref([]);
const totalRows = ref(0);

watch(() => data, newData => {
    if (isFunction(newData)) {
        loadData();
    }

    totalRows.value = size(newData);

    if (isArray(newData)) {
        normalizedData.value = newData;
    } else {
        normalizedData.value = filter(toArray(newData));
    }
}, {immediate: true, deep: true});

function dataKey(item: any): string {
    if (dataKeyFunction) {
        return dataKeyFunction(item);
    }

    if ("id" in item && item.id) {
        return item.id.toString();
    }

    return JSON.stringify(item);
}

function loadData() {
    if (!isFunction(data)) {
        return;
    }

    busy.value = true;

    data(page.value, perPage, filters.value?.global.value).then(response => {
        totalRows.value = response.total;
        normalizedData.value = isArray(response.data) ? response.data : toArray(response.data);
        busy.value = false;
    });
}

function pagingEvent(event: DataTablePageEvent) {
    page.value = event.page + 1;
    loadData();
}

function trClass(item: T) {
    let classes = '';
    if (noWrap) {
        classes += 'text-nowrap';
    }

    if (rowVariant) {
        classes += ' table-' + rowVariant(item);
    }

    return classes;
}
</script>
