/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsTrainingResponse } from './EventsTrainingResponse';
    import {
    EventsTrainingResponseFromJSON,
    EventsTrainingResponseFromJSONTyped,
    EventsTrainingResponseToJSON,
    EventsTrainingResponseCreateEmpty,
    } from './EventsTrainingResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsTrainingListResponse {
  trainings: Array<EventsTrainingResponse>;
}

export const EventsTrainingListResponseSchema = {
  'trainings': {
    name: 'trainings',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsTrainingListResponse(value: object): value is EventsTrainingListResponse {
let isInstance = true;
    isInstance = isInstance && "trainings" in value;

return isInstance;
}

export function EventsTrainingListResponseFromJSON(json: any): EventsTrainingListResponse {
return EventsTrainingListResponseFromJSONTyped(json, false);
}

export function EventsTrainingListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsTrainingListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'trainings': ((json['trainings'] as Array<any>).map(EventsTrainingResponseFromJSON)),
    };
  }

  export function EventsTrainingListResponseToJSON(value?: EventsTrainingListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'trainings': ((value.trainings as Array<any>).map(EventsTrainingResponseToJSON)),
    };
  }

  export function EventsTrainingListResponseCreateEmpty(): EventsTrainingListResponse {
  return {
      'trainings': [],
  };
  }

