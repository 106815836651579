import {Base} from "@Models/Base";
import {MentorsApi} from "@/api/apis";
import type {MentorsAvailabilityIndexResponse} from "@/api/models";
import {Availability} from "@Models/mentors/Availability";
import {Mentor} from '@Models/mentors/Mentor';

export class AvailabilityIndex extends Base {
    availabilities: Record<number, Availability>;

    mentor: Mentor;

    static get(): Promise<AvailabilityIndex> {
        return MentorsApi.availabilityMentorIndex().then(response => {
            return AvailabilityIndex.newSingle(response.data, AvailabilityIndex.parseResponse);
        });
    }

    static parseResponse(index: AvailabilityIndex, data: MentorsAvailabilityIndexResponse): AvailabilityIndex {
        index.availabilities = Availability.newRecords(data.availabilities, Availability.parseResponse);
        index.mentor = Mentor.newSingle(data.mentor, Mentor.parseResponse);

        return index;
    }
}
