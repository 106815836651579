/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyItemSearch {
  perPage: number;
  currentPage: number;
  searchTerm: string;
}

export const MoneyItemSearchSchema = {
  'perPage': {
    name: 'perPage',
    required: true,
    nullable: false,
    label: 'Oldalméret',
    type: 'number',
      minimum: 1,
      maximum: 100,
  },
  'currentPage': {
    name: 'currentPage',
    required: true,
    nullable: false,
    label: 'Oldalszám',
    type: 'number',
      minimum: 1,
      maximum: 1000,
  },
  'searchTerm': {
    name: 'searchTerm',
    required: true,
    nullable: false,
    label: 'Keresés',
    type: 'string',
      minLength: 2,
      maxLength: 30,
  },
}
export function instanceOfMoneyItemSearch(value: object): value is MoneyItemSearch {
let isInstance = true;
    isInstance = isInstance && "perPage" in value;
    isInstance = isInstance && "currentPage" in value;
    isInstance = isInstance && "searchTerm" in value;

return isInstance;
}

export function MoneyItemSearchFromJSON(json: any): MoneyItemSearch {
return MoneyItemSearchFromJSONTyped(json, false);
}

export function MoneyItemSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemSearch {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'perPage': json['perPage'],
          'currentPage': json['currentPage'],
          'searchTerm': json['searchTerm'],
    };
  }

  export function MoneyItemSearchToJSON(value?: MoneyItemSearch | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'perPage': value.perPage,
                'currentPage': value.currentPage,
                'searchTerm': value.searchTerm,
    };
  }

  export function MoneyItemSearchCreateEmpty(): MoneyItemSearch {
  return {
      'perPage': 0,
      'currentPage': 0,
      'searchTerm': '',
  };
  }

