<template>
    <PegasusHeading>Nagyteremosztó</PegasusHeading>

    <div v-if="period">
        <h4>
            Aktuális időszak: {{ period.start.date() }} - {{ period.finish.date() }}
            <span v-if="!period.editable">
                (csak olvasható)
            </span>

            <PegasusButton v-if="period.editable" to="events.planning.new" variant="primary">
                <i class="fa fa-plus"></i> Új foglalás
            </PegasusButton>

            <PegasusButton to="events.planning.mine" variant="primary">
                <i class="fa fa-list"></i> Saját foglalásaim
            </PegasusButton>

            <PegasusButton v-if="period.canEdit(user)" :params="{period}" to="events.admin.planning.period" variant="danger">
                <i class="fa fa-dashboard"></i> Adminisztráció
            </PegasusButton>
        </h4>

        <TabView>
            <TabPanel v-for="(reservation, month) in reservations" :key="month" :header="month">
                <PegasusTable :data="reservation ?? []" :loading="reservation === null">
                    <Column field="dateText" header="Dátum" sortable></Column>

                    <Column field="name" header="Név">
                        <template #body="{data}: {data: Reservation}">
                            <PegasusLink
                                v-if="period.canEdit(user)"
                                :params="{reservation: data.id}"
                                to="events.planning.edit"
                            >{{ data.name }}
                            </PegasusLink>

                            <span v-else>{{ data.name }}</span>

                            <PegasusOverlay v-if="data.priority" content="Kiemelt rendezvény">
                                <i class="fas fa-star"></i>
                            </PegasusOverlay>
                        </template>
                    </Column>

                    <Column field="group.name" header="Kör"></Column>

                    <Column field="locationsText" header="Helyszín"></Column>

                    <Column :field="item => item.responsible.name + ' (' + item.responsible.email + ')'" header="Felelős"></Column>
                </PegasusTable>
            </TabPanel>
        </TabView>
    </div>

    <h4 v-else>Nincsen nagyteremosztó időszak</h4>
</template>

<title>Nagyteremosztó</title>

<script lang="ts" setup>
import {ReservationPeriod} from "@Models/events/ReservationPeriod";
import {Reservation} from "@Models/events/Reservation";
import {ref} from "vue";
import {find} from "lodash-es";
import Column from "primevue/column";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import PegasusOverlay from "@Components/base/PegasusOverlay.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const user = useAuthStore().requireUser();

const periods = await ReservationPeriod.getAll();

const period = find(periods, period => period.active);

const reservations = ref<Record<string, Record<number, Reservation> | null>>({});

if (period) {
    period.interval.splitBy({month: 1}).forEach(interval => {
        const month = interval.start;

        reservations.value[month.year + ' ' + month.monthLong] = null;

        Reservation.getByMonth(month.year, month.month).then(response => {
            reservations.value[month.year + ' ' + month.monthLong] = response;
        });
    });
}
</script>
