/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyAccountCheckResponse {
  bic: string;
  name: string;
}

export const MoneyAccountCheckResponseSchema = {
  'bic': {
    name: 'bic',
    required: true,
    nullable: false,
    label: 'Bank fiók azonosító',
    type: 'string',
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Bank fiók neve',
    type: 'string',
  },
}
export function instanceOfMoneyAccountCheckResponse(value: object): value is MoneyAccountCheckResponse {
let isInstance = true;
    isInstance = isInstance && "bic" in value;
    isInstance = isInstance && "name" in value;

return isInstance;
}

export function MoneyAccountCheckResponseFromJSON(json: any): MoneyAccountCheckResponse {
return MoneyAccountCheckResponseFromJSONTyped(json, false);
}

export function MoneyAccountCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyAccountCheckResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'bic': json['bic'],
          'name': json['name'],
    };
  }

  export function MoneyAccountCheckResponseToJSON(value?: MoneyAccountCheckResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'bic': value.bic,
                'name': value.name,
    };
  }

  export function MoneyAccountCheckResponseCreateEmpty(): MoneyAccountCheckResponse {
  return {
      'bic': '',
      'name': '',
  };
  }

