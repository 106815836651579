import {BaseEntity} from "@Models/BaseEntity";
import {Group} from "@Models/base/Group";
import type {InventoryPublicItemResponse} from "@/api/api";
import {InventoryApi} from "@/api/api";

export class PublicItem extends BaseEntity {
    responsible: Group;

    leaderName: string;

    leaderEmail: string;

    canRead: boolean;

    static getByCode(code: number): Promise<PublicItem> {
        return InventoryApi.itemsCheck(code).then(response => {
            return PublicItem.newSingle(response.data, PublicItem.parseResponse);
        });
    }

    static parseResponse(item: PublicItem, data: InventoryPublicItemResponse): PublicItem {
        item.id = data.id;
        item.responsible = Group.getBySingleId(data.responsibleId);
        item.leaderName = data.leaderName;
        item.leaderEmail = data.leaderEmail;
        item.canRead = data.canRead;


        return item;
    }

    get idAsText(): string {
        return 'SCH' + this.id.toString().padStart(8, '0');
    }
}
