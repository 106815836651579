/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseUserResponse } from './BaseUserResponse';
    import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
    BaseUserResponseCreateEmpty,
    } from './BaseUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface ExternalMentorResponse {
  id: number;
  user: BaseUserResponse;
  schacc: string;
  leader: boolean;
}

export const ExternalMentorResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'Mentor belső azonosító',
    type: 'number',
      minimum: 1,
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'schacc': {
    name: 'schacc',
    required: true,
    nullable: false,
    label: 'Sch Account',
    type: 'string',
  },
  'leader': {
    name: 'leader',
    required: true,
    nullable: false,
    label: 'Mentor gárda vezető vagy nem',
    type: 'boolean',
  },
}
export function instanceOfExternalMentorResponse(value: object): value is ExternalMentorResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "schacc" in value;
    isInstance = isInstance && "leader" in value;

return isInstance;
}

export function ExternalMentorResponseFromJSON(json: any): ExternalMentorResponse {
return ExternalMentorResponseFromJSONTyped(json, false);
}

export function ExternalMentorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalMentorResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'user': BaseUserResponseFromJSON(json['user']),
          'schacc': json['schacc'],
          'leader': json['leader'],
    };
  }

  export function ExternalMentorResponseToJSON(value?: ExternalMentorResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'user': BaseUserResponseToJSON(value.user),
                'schacc': value.schacc,
                'leader': value.leader,
    };
  }

  export function ExternalMentorResponseCreateEmpty(): ExternalMentorResponse {
  return {
      'id': 0,
          'user': BaseUserResponseCreateEmpty(),
      'schacc': '',
      'leader': false,
  };
  }

