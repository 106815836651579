<template>
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <td>Rendszám</td>
                <td></td>
            </tr>
        </thead>

        <tbody>
            <tr v-for="(number, n) in data.numbers" :key="n">
                <td>
                    <TextField
                        v-if="service.canEdit"
                        v-model="data.numbers[n].number"
                        :schema="CommonCarNumberSchema.number"
                        mask="aaa-999"
                        without-group
                    ></TextField>
                    <span v-else>{{ data.numbers[n] }}</span>
                </td>
                <td v-if="service.canEdit">
                    <PegasusButton variant="danger" @click="data.numbers.splice(n, 1)">
                        <i class="fa fa-times"></i>
                    </PegasusButton>
                </td>
            </tr>
        </tbody>
    </table>

    <PegasusButton v-if="service.canEdit" variant="success" @click="data.numbers.length < 10 && data.numbers.push({number: ''})">
        <i class="fa fa-plus"></i>
    </PegasusButton>

    <br>

    <TextareaField
        v-model="data.times"
        :disabled="!service.canEdit"
        :schema="EventsCarPermitDataSchema.times"
    ></TextareaField>

    <TextareaField
        v-model="data.reason"
        :disabled="!service.canEdit"
        :schema="EventsCarPermitDataSchema.reason"
    ></TextareaField>

    <br>

    <small>
        <i class="fas fa-exclamation-circle text-danger"></i>
        Behajtási engedéllyel rendelkező gépkocsi a rendszámát a sorompónál lévő kaputelefonba bemondva
        behajthat,
        viszont parkoló helyet csak akkor foglalhat el, ha az dedikáltan van az autónak.
    </small>
</template>

<script lang="ts" setup>
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import type {Service} from "@Models/events/Service";
import {CommonCarNumberSchema, type EventsCarPermitData, EventsCarPermitDataSchema} from "@/api/models";
import PegasusButton from "@Components/base/PegasusButton.vue";
import TextField from "@Components/base/form/types/TextField.vue";

const data = defineModel<EventsCarPermitData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
