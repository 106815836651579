<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div>
                <Textarea
                    :id="id"

                    v-model="value"
                    :auto-resize="autoresize"
                    :class="{'p-invalid': isValid === false}"
                    :pt="{root: {'data-input-name': schema.name}}"
                    :readonly="disabled"
                    class="w-100"
                ></Textarea>
            </div>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import SmartField from "@Components/base/form/SmartField.vue";
import Textarea from "primevue/textarea";
import {computed} from "vue";

const modelValue = defineModel<string | null>({required: true});

const value = computed({
    get: () => modelValue.value ?? '',
    set: (value: string) => modelValue.value = value,
});

const {autoresize = true, disabled, withoutGroup, schema, inline} = defineProps<{
    autoresize?: boolean;
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();
</script>
