/* tslint:disable */
/* eslint-disable */

export enum EventsAcSource {
    LAPTOP = 'laptop',
    LIVE = 'live',
    DJ = 'dj',
    OTHER = 'other'
}

export const EventsAcSourceValues: Record<EventsAcSource, string> = {
    'laptop': 'Laptop',
    'live': 'Élőzene',
    'dj': 'DJ',
    'other': 'Egyéb'
}


export function EventsAcSourceFromJSON(json: any): EventsAcSource {
    return EventsAcSourceFromJSONTyped(json, false);
}

export function EventsAcSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsAcSource {
    return json as EventsAcSource;
}

export function EventsAcSourceToJSON(value?: EventsAcSource | null): any {
    return value as any;
}

export function EventsAcSourceCreateEmpty(): EventsAcSource {
    return EventsAcSource.LAPTOP;
}

