import {BaseEntity} from "@Models/BaseEntity";
import {User} from "@Models/base/User";
import type {
    VikoReviewerGroupMember,
    VikoReviewerGroupResponse,
    VikoReviewerGroupStore,
    VikoReviewerGroupUpdate,
    VikoReviewStage
} from "@/api/api";
import {VikoApi, VikoReviewStageValues} from "@/api/api";
import type {FormRef} from '@/vue';

export class ReviewerGroup extends BaseEntity {
    name: string;

    reviewStage: VikoReviewStage;

    reviewers: Record<number, User>;

    static parseResponse(group: ReviewerGroup, data: VikoReviewerGroupResponse): ReviewerGroup {
        group.id = data.id;
        group.name = data.name;
        group.reviewStage = data.reviewStage;
        group.reviewers = User.newRecords(data.reviewers, User.parseMinimalResponse);

        return group;
    }

    public store(tenderId: number, form: FormRef): Promise<ReviewerGroup> {
        const data: VikoReviewerGroupStore = {
            name: this.name,
            tender: tenderId,
            reviewStage: this.reviewStage,
        };

        return VikoApi.reviewerGroupsStore(data, {form}).then(response => {
            return ReviewerGroup.newSingle(response.data, ReviewerGroup.parseResponse);
        });
    }

    public update(): Promise<void> {
        const data: VikoReviewerGroupUpdate = {
            name: this.name,
            reviewStage: this.reviewStage,
        };

        return VikoApi.reviewerGroupsUpdate(this.id, data).then(response => {
            ReviewerGroup.parseResponse(this, response.data);
        });
    }

    public addUser(userId: number, form: FormRef): Promise<void> {
        const data: VikoReviewerGroupMember = {
            user: userId,
        };

        return VikoApi.reviewerGroupsAddMember(this.id, data, {form}).then(response => {
            ReviewerGroup.parseResponse(this, response.data);
        });
    }

    public removeUser(userId: number): Promise<void> {
        const data: VikoReviewerGroupMember = {
            user: userId,
        };

        return VikoApi.reviewerGroupsRemoveMember(this.id, data).then(response => {
            ReviewerGroup.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return VikoApi.reviewerGroupsDestroy(this.id).then();
    }

    get reviewStageText(): string {
        return VikoReviewStageValues[this.reviewStage];
    }
}
