/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { InventoryApprovalResponse } from './InventoryApprovalResponse';
    import {
    InventoryApprovalResponseFromJSON,
    InventoryApprovalResponseFromJSONTyped,
    InventoryApprovalResponseToJSON,
    InventoryApprovalResponseCreateEmpty,
    } from './InventoryApprovalResponse';
    import type { InventoryUpdateResponseData } from './InventoryUpdateResponseData';
    import {
    InventoryUpdateResponseDataFromJSON,
    InventoryUpdateResponseDataFromJSONTyped,
    InventoryUpdateResponseDataToJSON,
    InventoryUpdateResponseDataCreateEmpty,
    } from './InventoryUpdateResponseData';
    import type { InventoryUpdateType } from './InventoryUpdateType';
    import {
    InventoryUpdateTypeFromJSON,
    InventoryUpdateTypeFromJSONTyped,
    InventoryUpdateTypeToJSON,
    InventoryUpdateTypeCreateEmpty,
    } from './InventoryUpdateType';
import {DateTime} from '@Utils/DateTime';
import { InventoryUpdateTypeValues } from './InventoryUpdateType';

export interface InventoryUpdateResponse {
  id: number;
  type: InventoryUpdateType;
  creator: BaseMinimalUserResponse;
  comment: string;
  createdAt: DateTime;
  approval: InventoryApprovalResponse | null;
  data: InventoryUpdateResponseData;
}

export const InventoryUpdateResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: InventoryUpdateTypeValues,
  },
  'creator': {
    name: 'creator',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'approval': {
    name: 'approval',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'data': {
    name: 'data',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfInventoryUpdateResponse(value: object): value is InventoryUpdateResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "approval" in value;
    isInstance = isInstance && "data" in value;

return isInstance;
}

export function InventoryUpdateResponseFromJSON(json: any): InventoryUpdateResponse {
return InventoryUpdateResponseFromJSONTyped(json, false);
}

export function InventoryUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUpdateResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'type': InventoryUpdateTypeFromJSON(json['type']),
              'creator': BaseMinimalUserResponseFromJSON(json['creator']),
          'comment': json['comment'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
              'approval': InventoryApprovalResponseFromJSON(json['approval']),
              'data': InventoryUpdateResponseDataFromJSON(json['data']),
    };
  }

  export function InventoryUpdateResponseToJSON(value?: InventoryUpdateResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'type': InventoryUpdateTypeToJSON(value.type),
                'creator': BaseMinimalUserResponseToJSON(value.creator),
                'comment': value.comment,
            'createdAt': (value.createdAt.toISO()),
                'approval': InventoryApprovalResponseToJSON(value.approval),
                'data': InventoryUpdateResponseDataToJSON(value.data),
    };
  }

  export function InventoryUpdateResponseCreateEmpty(): InventoryUpdateResponse {
  return {
      'id': 0,
          'type': InventoryUpdateTypeCreateEmpty(),
          'creator': BaseMinimalUserResponseCreateEmpty(),
      'comment': '',
      'createdAt': DateTime.now(),
          'approval': InventoryApprovalResponseCreateEmpty(),
          'data': InventoryUpdateResponseDataCreateEmpty(),
  };
  }

