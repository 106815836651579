import {BaseEntity} from "@Models/BaseEntity";
import {Flag} from "@Models/money/Flag";
import {
    instanceOfMoneyConfidentialItemResponse, instanceOfMoneyItemMoneyResponse,
    MoneyApi,
    type MoneyConfidentialItemResponse,
    MoneyInventoryType,
    type MoneyItemResponse,
    type MoneyItemStore,
    type MoneyItemUpdate,
    type MoneyMinimalItemResponse
} from "@/api/api";
import {ItemAsset} from "@Models/money/ItemAsset";
import type {FormRef} from "@/vue";
import {ItemComment} from '@Models/money/ItemComment';
import {SpendingItem} from '@Models/money/SpendingItem';

export interface DecidedItem extends Item {
    decidedPrice: number;
}

export class Item extends BaseEntity {
    name: string;

    description: string;

    price: number;

    inventoryType: number | null;

    flags: Record<number, Flag>;

    offer: string;

    approvedPrice: number | null;

    finalPrice: number | null;

    amendable: boolean;

    amended: boolean;

    type: string | null;

    applicationId: number | null;

    comment: string;

    priority: {
        one: string,
        two: string,
        three: string
    };

    contribution: number | null;

    campNumber: number | null;

    assets: Record<number, ItemAsset>;

    comments: Record<number, ItemComment>;

    groupId: number;

    spendingItems: Record<number, SpendingItem>;

    static getById(id: number): Promise<Item> {
        return MoneyApi.itemsShow(id).then(response => {
            return Item.newSingle(response.data, Item.parseResponse);
        });
    }

    static create(name: string, price: number): Item {
        const item = new Item();

        item.name = name;
        item.description = '';
        item.price = price;
        item.inventoryType = MoneyInventoryType.NEW;
        item.flags = {};
        item.priority = {one: '', two: '', three: ''};
        item.comment = '';
        item.offer = '';

        return item;
    }

    static parseMinimalResponse(item: Item, data: MoneyMinimalItemResponse): Item {
        item.id = data.id;
        item.name = data.name;
        item.description = data.description;
        item.price = data.price;
        item.approvedPrice = data.approvedPrice;
        item.finalPrice = data.finalPrice;
        item.type = data.type;
        item.applicationId = data.applicationId;
        item.contribution = data.contribution;

        return item;
    }

    static parseResponse(item: Item, data: MoneyItemResponse | MoneyConfidentialItemResponse): Item {
        Item.parseMinimalResponse(item, data);

        item.inventoryType = data.inventoryType;
        item.flags = Flag.getByMultipleId(data.flags);
        item.offer = data.offer;
        item.amended = data.amended;
        item.campNumber = data.campNumber;
        item.assets = ItemAsset.newRecords(data.assets, ItemAsset.parseResponse);
        item.comments = ItemComment.newRecords(data.comments, ItemComment.parseResponse);

        if (instanceOfMoneyConfidentialItemResponse(data)) {
            item.comment = data.comment;
            item.priority = data.priority;
        }

        if (instanceOfMoneyItemMoneyResponse(data)) {
            item.groupId = data.groupId;
            item.spendingItems = SpendingItem.newRecords(data.spendingItems, SpendingItem.parseResponse);
        }

        return item;
    }

    get displayName(): string {
        return '#' + this.id + ' ' + this.name.limit(40);
    }

    get decidedPrice(): number | null {
        return this.finalPrice ?? this.approvedPrice ?? null;
    }

    get totalPrice(): number {
        return this.price + (this.contribution ?? 0);
    }

    get totalDecided(): number {
        return (this.contribution ?? 0) + (this.decidedPrice ?? 0);
    }

    public store(itemGroupId: number): Promise<void> {
        const data: MoneyItemStore = {
            itemGroup: itemGroupId,
            name: this.name,
            description: this.description,
            price: this.price,
            contribution: this.contribution,
            flags: Item.entitiesToArray(this.flags),
            comment: this.comment,
            inventoryType: this.inventoryType as MoneyInventoryType,
            priority: this.priority,
            campNumber: this.campNumber,
        };

        return MoneyApi.itemsStore(data).then(response => {
            Item.parseResponse(this, response.data);
        });
    }

    public save(form: FormRef): Promise<void> {
        const data: MoneyItemUpdate = {
            name: this.name,
            description: this.description,
            price: this.price,
            contribution: this.contribution,
            flags: Item.entitiesToArray(this.flags),
            comment: this.comment,
            inventoryType: this.inventoryType as MoneyInventoryType,
            priority: this.priority,
            campNumber: this.campNumber,
        };

        return MoneyApi.itemsUpdate(this.id, data, {form}).then(response => {
            Item.parseResponse(this, response.data);
        });
    }

    public decide(price: number): Promise<void> {
        return MoneyApi.itemsDecide(this.id, {price: price}).then(response => {
            Item.parseResponse(this, response.data);
        });
    }

    public finalize(price: number): Promise<void> {
        return MoneyApi.itemsFinalize(this.id, {price: price}).then(response => {
            Item.parseResponse(this, response.data);
        });
    }

    public move(targetGroupId: number): Promise<void> {
        return MoneyApi.itemsMove(this.id, {target: targetGroupId}).then();
    }

    public delete(): Promise<void> {
        return MoneyApi.itemsDestroy(this.id).then();
    }
}
