/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseAuthschAccountResponse } from './BaseAuthschAccountResponse';
    import {
    BaseAuthschAccountResponseFromJSON,
    BaseAuthschAccountResponseFromJSONTyped,
    BaseAuthschAccountResponseToJSON,
    BaseAuthschAccountResponseCreateEmpty,
    } from './BaseAuthschAccountResponse';
    import type { BaseExtendedUserResponse } from './BaseExtendedUserResponse';
    import {
    BaseExtendedUserResponseFromJSON,
    BaseExtendedUserResponseFromJSONTyped,
    BaseExtendedUserResponseToJSON,
    BaseExtendedUserResponseCreateEmpty,
    } from './BaseExtendedUserResponse';
    import type { EventsExtendedEventResponse } from './EventsExtendedEventResponse';
    import {
    EventsExtendedEventResponseFromJSON,
    EventsExtendedEventResponseFromJSONTyped,
    EventsExtendedEventResponseToJSON,
    EventsExtendedEventResponseCreateEmpty,
    } from './EventsExtendedEventResponse';
    import type { EventsManagerResponse } from './EventsManagerResponse';
    import {
    EventsManagerResponseFromJSON,
    EventsManagerResponseFromJSONTyped,
    EventsManagerResponseToJSON,
    EventsManagerResponseCreateEmpty,
    } from './EventsManagerResponse';
    import type { EventsTrainingParticipationResponse } from './EventsTrainingParticipationResponse';
    import {
    EventsTrainingParticipationResponseFromJSON,
    EventsTrainingParticipationResponseFromJSONTyped,
    EventsTrainingParticipationResponseToJSON,
    EventsTrainingParticipationResponseCreateEmpty,
    } from './EventsTrainingParticipationResponse';
    import type { RvtAmendResponse } from './RvtAmendResponse';
    import {
    RvtAmendResponseFromJSON,
    RvtAmendResponseFromJSONTyped,
    RvtAmendResponseToJSON,
    RvtAmendResponseCreateEmpty,
    } from './RvtAmendResponse';
    import type { RvtClaimResponse } from './RvtClaimResponse';
    import {
    RvtClaimResponseFromJSON,
    RvtClaimResponseFromJSONTyped,
    RvtClaimResponseToJSON,
    RvtClaimResponseCreateEmpty,
    } from './RvtClaimResponse';
    import type { VikoApplicationResponse } from './VikoApplicationResponse';
    import {
    VikoApplicationResponseFromJSON,
    VikoApplicationResponseFromJSONTyped,
    VikoApplicationResponseToJSON,
    VikoApplicationResponseCreateEmpty,
    } from './VikoApplicationResponse';
    import type { VikoTenderResponse } from './VikoTenderResponse';
    import {
    VikoTenderResponseFromJSON,
    VikoTenderResponseFromJSONTyped,
    VikoTenderResponseToJSON,
    VikoTenderResponseCreateEmpty,
    } from './VikoTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseUserSummaryResponse {
  user: BaseExtendedUserResponse;
  authsch: BaseAuthschAccountResponse;
  events: Array<EventsExtendedEventResponse>;
  eventManagers: Array<EventsManagerResponse>;
  trainingParticipations: Array<EventsTrainingParticipationResponse>;
  vikoApplications: Array<VikoApplicationResponse>;
  vikoReviewerGroups: Array<VikoTenderResponse>;
  vikoModerators: Array<VikoTenderResponse>;
  claims: Array<RvtClaimResponse>;
  amends: Array<RvtAmendResponse>;
}

export const BaseUserSummaryResponseSchema = {
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'authsch': {
    name: 'authsch',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'events': {
    name: 'events',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'eventManagers': {
    name: 'eventManagers',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'trainingParticipations': {
    name: 'trainingParticipations',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'vikoApplications': {
    name: 'vikoApplications',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'vikoReviewerGroups': {
    name: 'vikoReviewerGroups',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'vikoModerators': {
    name: 'vikoModerators',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'claims': {
    name: 'claims',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'amends': {
    name: 'amends',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseUserSummaryResponse(value: object): value is BaseUserSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "authsch" in value;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "eventManagers" in value;
    isInstance = isInstance && "trainingParticipations" in value;
    isInstance = isInstance && "vikoApplications" in value;
    isInstance = isInstance && "vikoReviewerGroups" in value;
    isInstance = isInstance && "vikoModerators" in value;
    isInstance = isInstance && "claims" in value;
    isInstance = isInstance && "amends" in value;

return isInstance;
}

export function BaseUserSummaryResponseFromJSON(json: any): BaseUserSummaryResponse {
return BaseUserSummaryResponseFromJSONTyped(json, false);
}

export function BaseUserSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'user': BaseExtendedUserResponseFromJSON(json['user']),
              'authsch': BaseAuthschAccountResponseFromJSON(json['authsch']),
            'events': ((json['events'] as Array<any>).map(EventsExtendedEventResponseFromJSON)),
            'eventManagers': ((json['eventManagers'] as Array<any>).map(EventsManagerResponseFromJSON)),
            'trainingParticipations': ((json['trainingParticipations'] as Array<any>).map(EventsTrainingParticipationResponseFromJSON)),
            'vikoApplications': ((json['vikoApplications'] as Array<any>).map(VikoApplicationResponseFromJSON)),
            'vikoReviewerGroups': ((json['vikoReviewerGroups'] as Array<any>).map(VikoTenderResponseFromJSON)),
            'vikoModerators': ((json['vikoModerators'] as Array<any>).map(VikoTenderResponseFromJSON)),
            'claims': ((json['claims'] as Array<any>).map(RvtClaimResponseFromJSON)),
            'amends': ((json['amends'] as Array<any>).map(RvtAmendResponseFromJSON)),
    };
  }

  export function BaseUserSummaryResponseToJSON(value?: BaseUserSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'user': BaseExtendedUserResponseToJSON(value.user),
                'authsch': BaseAuthschAccountResponseToJSON(value.authsch),
              'events': ((value.events as Array<any>).map(EventsExtendedEventResponseToJSON)),
              'eventManagers': ((value.eventManagers as Array<any>).map(EventsManagerResponseToJSON)),
              'trainingParticipations': ((value.trainingParticipations as Array<any>).map(EventsTrainingParticipationResponseToJSON)),
              'vikoApplications': ((value.vikoApplications as Array<any>).map(VikoApplicationResponseToJSON)),
              'vikoReviewerGroups': ((value.vikoReviewerGroups as Array<any>).map(VikoTenderResponseToJSON)),
              'vikoModerators': ((value.vikoModerators as Array<any>).map(VikoTenderResponseToJSON)),
              'claims': ((value.claims as Array<any>).map(RvtClaimResponseToJSON)),
              'amends': ((value.amends as Array<any>).map(RvtAmendResponseToJSON)),
    };
  }

  export function BaseUserSummaryResponseCreateEmpty(): BaseUserSummaryResponse {
  return {
          'user': BaseExtendedUserResponseCreateEmpty(),
          'authsch': BaseAuthschAccountResponseCreateEmpty(),
      'events': [],
      'eventManagers': [],
      'trainingParticipations': [],
      'vikoApplications': [],
      'vikoReviewerGroups': [],
      'vikoModerators': [],
      'claims': [],
      'amends': [],
  };
  }

